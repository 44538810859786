import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/Rx';
import { BatchesService } from '../../../../../services/billing/batches.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-batch',
  templateUrl: './add-batch.component.html',
  styleUrls: ['./add-batch.component.scss']
})
export class AddBatchComponent implements OnInit, OnChanges {

  form: FormGroup;
  busy: Subscription;
  @Output() onClose? = new EventEmitter<any>();
  @Output() onReload ? = new EventEmitter<any>();
  @Input() dataFilter ?: any;

  constructor(private fb: FormBuilder,  private batchesService: BatchesService) {
    this.initForm();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataFilter) {
      if (this.dataFilter) {
        this.form.patchValue({
          client_id: Number(this.dataFilter.client_id)
        });
      }
    }
  }

  initForm() {
    this.form = this.fb.group({
      start_date : ['', Validators.required],
      end_date: ['', Validators.required],
      date_submitted: ['', Validators.required],
      client_id: ['', Validators.required]
      // ids: [[], Validators.required]
    });
  }

  onSave() {
    const start_date: any = moment(this.form.controls['start_date'].value).format('YYYY-MM-DD');
    const end_date: any = moment(this.form.controls['end_date'].value).format('YYYY-MM-DD');
    const date_submitted: any = moment(this.form.controls['date_submitted'].value).format('YYYY-MM-DD');

    this.form.controls['start_date'].setValue(start_date);
    this.form.controls['end_date'].setValue(end_date);
    this.form.controls['date_submitted'].setValue(date_submitted);

    this.busy = this.batchesService.addBatch(this.form.value).subscribe((resp: any) => {
      if (resp.status === 'success') {
        Swal.fire({
          position: 'top-end',
          type: 'success',
          title: 'Your work has been saved',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          this.onReload.emit(this.form.value);
        });
      }
    },(resp) => {
      Swal.fire({
        position: 'top-end',
        type: 'error',
        title: 'Error:',
        footer: resp.message,
        showConfirmButton: false
      })
    });
  }

  onCancel() {
    this.onClose.emit();
  }


  onSubmit() {
    Swal.fire({
      title: 'Are you sure?',
      html: '<h4>Are you sure you want to submit the selected manifests?</h4>',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((response: any) => {
      if (response.value) {
        this.onSave();
      }
    });
  }

}
