import { Subscription } from 'rxjs/Rx';
import { Component, OnInit, ViewChild, ElementRef, Input, SimpleChanges, OnChanges, OnDestroy, Output } from '@angular/core';
import { BsModalComponent } from 'ng2-bs3-modal';
import { GridOptions } from 'ag-grid-community';
import * as moment from 'moment';
import {BatchInvoicesService, IInvoice} from '../../../../../services/billing/batch/batch-invoices.service';
import { BatchCreditsService } from '../../../../../services/billing/batch/batch-credits.service';
import { BatchInvoicesStatusService, InvoiceStatus } from '../../../../../services/billing/batch/batch-invoices-status.service';
import { takeUntil } from 'rxjs/operators';
import { Subject} from 'rxjs';
import Swal from 'sweetalert2';
import {InvoiceReportService} from '../../../workflow/invoice-report/invoice-report.service';
@Component({
  selector: 'app-grid-batch-list',
  templateUrl: './grid-list.component.html',
  styleUrls: ['./grid-list.component.scss']
})
export class GridListComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('modalDetails') modalDetails: BsModalComponent;
  @ViewChild('modalInvoiceStatusGrid') modalInvoiceStatusGrid: BsModalComponent;
  @ViewChild('grid') gridView: ElementRef;
  private destroy: Subject<boolean> = new Subject<boolean>();
  @Input() clearGrid?: any;
  @Input() isActiveInvoiceCredit?: boolean;
  @Input() gridHeight?: string;
  @Output() total_amount: number;
  subscription: Subscription[] = [];
  addStatus: boolean;
  editInvoice: boolean;
  grid: GridOptions;
  busy: Subscription;
  busy_temp: Subscription;
  getContextMenuItems: any;
  batch_id: any;
  report_id: number;
  report_title: string;
  routes: any = [];
  dataShow: any;
  dataParams: any = {};
  gridListInvoice: boolean;
  legendInvoice = '';
  isOpenInInvoiceReportModal: boolean;

  constructor(
    private batchInvoicesService: BatchInvoicesService,
    private batchCreditsService: BatchCreditsService,
    private batchInvoicesStatusService: BatchInvoicesStatusService,
    private invoiceReportService: InvoiceReportService
  ) {
    this.initGrid();
  }

  ngOnInit() {
      this.subscribeObservers();
      this.loadInvoice();

  }

  subscribeObservers() {
    this.batchInvoicesService.save$.pipe(takeUntil(this.destroy)).subscribe((resp: boolean) => {
      this.saveInvoice(resp);
    });

    this.invoiceReportService.isOpenInInvoiceReportAction$.pipe(takeUntil(this.destroy)).subscribe({
      next: (value: boolean) => this.isOpenInInvoiceReportModal = value
    });

    this.invoiceReportService.clearGridInvoiceAction$.pipe(takeUntil(this.destroy)).subscribe({
      next: (clear: boolean): void => {
        if (clear) {
          this.resetGrid();
        }
      }
    });
  }

  loadInvoice() {
      this.busy_temp = this.batchInvoicesService.bacthInvoice$.subscribe((resp: any) => {
        if (resp) {
          if (this.grid.api) {
            this.gridListInvoice = true;
            this.grid.api.setRowData(resp);
          }
          this.batchInvoicesService.getBatchHistoryInfo$.subscribe((batch) => {
            const { client, id, total_amount } = batch;
            if (client && id && total_amount) {
              this.legendInvoice = `Client: ${batch.client} with Batch No ${batch.id}`;
              this.total_amount = total_amount;
            }
          });
        }
      }, error => {
        Swal.fire({
          type: 'error',
          title: 'Error...',
          text: error.message,
        });
      });
      this.batchInvoicesService.getLoadGridData$.subscribe(data => {
        if (data) {
          this.batchInvoicesService.batchRow$.subscribe(batch => {
            if (batch && typeof (batch) !== 'object') {
              this.batch_id = batch;
            } else {
              this.batch_id = batch.batch_id;
            }
            // this.getBatchInvoices(fee_schedule_id);
          });
        }
      }, error => {
        Swal.fire({
          type: 'error',
          title: 'Error...',
          text: error.message,
        });
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['clearGrid']) {
      if (!this.isActiveInvoiceCredit) {
        this.gridListInvoice = false;
      }
      if (this.clearGrid) {
        this.grid.api.setRowData([]);
      }
    }
  }

  clearBatchInvoiceRow(): void {
    const batchInvoice: IInvoice = {
      id: '',
      batch_id: '',
      invoice_num: '',
      invoice_date: '',
      total_amount: 0,
      invoice_status_id: '',
      invoice_status_description: '',
      user_name: ''
    };

    this.batchInvoicesService.batchInvoiceRow$.next(batchInvoice);
  }

  ngOnDestroy(): void {
    this.clearBatchInvoiceRow();
    this.addStatus = false;
    this.busy ? this.busy.unsubscribe() : null;
    this.busy_temp ? this.busy_temp.unsubscribe() : null;
    this.gridListInvoice = false;
  }

  initGrid() {
    this.grid = <GridOptions>{
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['asc', 'desc', null],
      rowSelection: 'single',
      enableRangeSelection: true,
      enableFilter: true,
      onGridReady: () => {
        this.grid.api.setRowData([]);
      },
      onSelectionChanged: () => {
        this.batchInvoicesService.batchInvoiceRow$.next(this.grid.api.getSelectedRows()[0]);
        this.batchCreditsService.batchInvoiceCreditRow$.next(this.grid.api.getSelectedRows()[0]);
        this.batchInvoicesStatusService.getInvoiceStatus().subscribe((resp: InvoiceStatus) => {
          this.batchInvoicesStatusService.loadInvoiceStatus$.next(resp);
        });
      },
      columnDefs: [
        { headerName: 'ID', field: 'id', width: 50 },
        { headerName: 'Invoice Num', field: 'invoice_num', width: 120 },
        { headerName: 'Invoice Date', field: 'invoice_date', width: 100, valueGetter: this.formatInvoiceDate },
        { headerName: 'Total Amount', field: 'total_amount', width: 110, cellRenderer: this.CurrencyCellRenderer, cellStyle: { textAlign: 'right' } },
        { headerName: 'Status', field: 'invoice_status_description', width: 100 },
        { headerName: 'User', field: 'user_name', width: 100, cellRenderer: (params) => params.value ? params.value : 'SYSTEM' }
      ]
    };
  }

  private resetGrid(): void {
    if (!this.isActiveInvoiceCredit) {
      this.gridListInvoice = false;
    }

    if (this.grid.api) {
      this.grid.api.setRowData([]);
    }
  }

  // getBatchInvoices(feeScheduleId): void {
  //   if (!this.isOpenInInvoiceReportModal) {
  //     this.batchInvoicesService.getBatchInvoice(this.batch_id).subscribe({
  //       next: (resp): void => {
  //         if (resp) {
  //           this.batchInvoicesService.loadBatchInvoices$.next(resp);
  //         }
  //       }
  //     });
  //   } else {
  //     forkJoin([
  //       this.invoiceReportService.getOriginalChargeBatch(this.batch_id),
  //       // this.invoiceReportService.getBillingChargeBatch(this.batch_id, feeScheduleId)
  //     ]).pipe(reduce((acc: any[], response: any[]) => acc.concat(...response), []))
  //       .subscribe({
  //         next: (response: any[]) => {
  //           if (response.length) {
  //             this.batchInvoicesService.loadBatchInvoices$.next(response);
  //           }
  //         }
  //       });
  //   }
  // }

  CurrencyCellRenderer(params: any) {
    const usdFormate = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });
    return usdFormate.format(params.value);
  }

  formatInvoiceDate(row: any) {
    if (row.data) {
      return moment(row.data.invoice_date).format('MM-DD-YYYY');
    } else {
      return moment(row).format('MM-DD-YYYY');
    }
  }

  loadData() {
    this.busy = this.batchInvoicesService.bacthInvoice$.subscribe((resp: any) => {
      this.grid.api.setRowData(resp);
    });
  }

  onLoadData(event: any) {
    this.gridListInvoice = false;
    if (event.data) {
      this.editInvoice = true;
      this.batchInvoicesService.batchRow$.subscribe((resp: any) => {
        if (resp) {
          this.batchInvoicesService.editInvoiceData$.next(resp);
        }
      }, error => {
        Swal.fire({
          type: 'error',
          title: 'Error...',
          text: error.message,
        });
      });
      this.modalDetails.open().then();
    }

  }

  get titleModal(): string {
    return this.editInvoice ? 'Edit Invoice' : 'Create Invoice';
  }

  saveInvoice(save: boolean) {
    if (save) {
      this.loadInvoice();
    }
    this.addStatus = false;
  }

  onClickNewBatchInvoice() {
    this.addStatus = true;
    this.editInvoice = false;
    this.batchInvoicesService.editInvoiceData$.next(null);
    this.batchInvoicesStatusService.getInvoiceStatus().subscribe((resp: InvoiceStatus) => {
      if (resp) {
        this.batchInvoicesStatusService.loadInvoiceStatus$.next(resp);
      }
    });
    this.modalDetails.open();
  }

}
