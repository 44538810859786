import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ManifestsMovilService, ScheduleDetailsService } from './../../../../services/services.index';
import Swal from 'sweetalert2';
import { moment } from 'ngx-bootstrap/chronos/test/chain';

@Component({
  selector: 'app-manifest-movil',
  templateUrl: './manifest-movil.component.html',
  styleUrls: ['./manifest-movil.component.scss']
})
export class ManifestMovilComponent implements OnInit, OnDestroy {
  public busy: Subscription;
  public gridManifestMovil: GridOptions;
  endDate: any = [];
  startDate: any = [];
  bsRangeValue: any = [];
  count = 0;
  nameGridLayout = 'gridDriverManifest';

  constructor(
    private toastr: ToastrService,
    private _serviceManifestsMovil: ManifestsMovilService,
    private router: Router,
    private scheduleDetailsService: ScheduleDetailsService
  ) {
    this.gridManifestMovil = <GridOptions>{
      enableSorting: true,
      sortingOrder: ['desc', 'asc', null],
      onGridReady: () => {
        this.gridManifestMovil.api.setRowData([]);
      }
    };

    this.gridManifestMovil.columnDefs = [
      { headerName: 'Date of Service', field: 'dateOfService', width: 150 },
      { headerName: 'Veh', field: 'vehicle.description', width: 90 },
      { headerName: 'Client', field: 'client.client_name', width: 150 },
      { headerName: 'Driver', valueGetter: this.dataDriver, width: 150 },
      { headerName: 'Route', field: 'route_block.route.description', width: 150 },
      { headerName: 'Block', field: 'route_block.description', width: 150 },
      { headerName: 'AMB', field: 'amb', width: 60 },
      { headerName: 'W/C', field: 'wc', width: 60 },
      { headerName: 'BK', field: 'str', width: 60 },
      { headerName: 'LYT', field: 'leave_yard_time', width: 60 },
      { headerName: 'LYO', field: 'leave_yard_odometer', width: 70 },
      { headerName: 'FST', field: 'first_stop_time', width: 60 },
      { headerName: 'FSO', field: 'first_stop_odometer', width: 70 },
      { headerName: 'LST', field: 'last_stop_time', width: 60 },
      { headerName: 'LSO', field: 'last_stop_odometer', width: 70 },
      { headerName: 'RYT', field: 'return_to_yard_time', width: 60 },
      { headerName: 'RYO', field: 'return_to_yard_odometer', width: 70 },
      { headerName: 'Check In', field: 'actual_check_in', width: 90 },
      { headerName: 'Check Out', field: 'actual_drop_off', width: 94 }
    ];

    this.gridManifestMovil.getRowStyle = function(params) {
      if (params.data.transferred == 1) {
        return { background: 'blue', color: '#fff' };
      }
    };
  }

  ngOnInit() {
    this.onLoad();
    this.setCurrentDate();
  }


  setCurrentDate() {
    if (!localStorage.getItem('sw')) {
    const currentDate = new Date();
      this.bsRangeValue = [currentDate, currentDate];
    }
  }

  ngOnDestroy() {
    if (localStorage.getItem('sw')) {
      this.onDeleteDate();
    }
  }

  dataDriver(row) {
    return row.data.driver.last_name + ' ' + row.data.driver.first_name;
  }

  onLoadService() {
    if (this.bsRangeValue.length === 2) {
      const verifyExists = 'exists';
      const startDate = moment(new Date(this.bsRangeValue[0])).format('YYYY-MM-DD');
      const endDate = moment(new Date(this.bsRangeValue[1])).format('YYYY-MM-DD');
      localStorage.setItem('exists', verifyExists);
      localStorage.setItem('startDate', JSON.stringify(startDate));
      localStorage.setItem('endDate', JSON.stringify(endDate));
      this.busy = this._serviceManifestsMovil.getManifestsForDate(startDate, endDate).subscribe(data => {
        this.gridManifestMovil.api.setRowData(data);
        this.count = data.length;
      });
    }
  }

  onLoad() {
    this.onLoadService();
    this.busy = this.scheduleDetailsService.getConfigGrid(this.nameGridLayout).subscribe(data => {
      if (data.data) {
        this.gridManifestMovil.columnApi.setColumnState(JSON.parse(data.data.config));
      }
    });
    this.onSaveDate();
  }

  onSaveDate() {
    const verifyExists = localStorage.getItem('exists');
    if (verifyExists) {
      const startDate = localStorage.getItem('startDate');
      const endDate = localStorage.getItem('endDate');
      const sw = 'true';
      localStorage.setItem('sw', sw);
      this.startDate = new Date(startDate);
      this.endDate = new Date(endDate);
      this.bsRangeValue = [this.startDate, this.endDate];
      this.onLoadService();
    } else {
      this.bsRangeValue = [null, null];
    }
  }

  onRowDoubleClicked($event) {
    this.router.navigate(['/manifests/driver-manifest-details/', $event.data.id]);
    localStorage.removeItem('sw');
  }

  onDeleteDate() {
    localStorage.removeItem('startDate');
    localStorage.removeItem('endDate');
    localStorage.removeItem('exists');
    localStorage.removeItem('sw');
  }

  saveLayout() {
    const model = this.gridManifestMovil.columnApi.getColumnState();
    this.busy = this.scheduleDetailsService
      .setConfigGrid(this.nameGridLayout, JSON.stringify(model))
      .subscribe(data => {
        Swal.fire({
          position: 'top-end',
          type: 'success',
          title: 'Your layut has been saved',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {});
      });
  }
}
