export enum BillingStatusEnum {
  Unverified = 1,
  Verified = 2,
  Billed = 3,
  Closed = 7,
  Denied = 6,
  Rejected = 4,
  Batch = 5
}

export enum StatusBillingByWorkflow {
  Rejected = 'Rejected',
  Verified = 'Verified',
  Unverified = 'Unverified'
}
