import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import * as moment from 'moment';
import { DriverPerDiemService } from '../../../../../services/fleet/driver-per-diem.service';

@Component({
  selector: 'app-drivers-pool',
  templateUrl: './drivers-pool.component.html',
  styleUrls: ['./drivers-pool.component.scss']
})
export class DriversPoolComponent implements OnInit, OnDestroy {

  rowData: any[];
  busy: any;
  nameday: string;
  weekday: number;
  @Input() currentDate?: string;
  totalHours: number;
  availability: string;
  gridDriversPool: GridOptions;
  currentDate_: string;
  count: number;
  start_date: any;
  end_date: any;

  @ViewChild('ShowDriversOff') ShowDriversOff: ElementRef;
  @ViewChild('ShowDriversStandby') ShowDriversStandby: ElementRef;

  constructor(private driverPerDiemService: DriverPerDiemService, private route: ActivatedRoute) {
    this.rowData = [];
    this.availability = 'All';
    this.count = 0;
  }

  ngOnInit() {
    const currentDate = this.route.snapshot.paramMap.get('currentDate');
    this.start_date = this.route.snapshot.paramMap.get('start_date');
    this.end_date = this.route.snapshot.paramMap.get('end_date');

    this.currentDate_ = currentDate;
    this.nameday = moment(currentDate).format('dddd').toLowerCase();
    this.weekday = moment(currentDate).weekday();
    this.agGridInit();
    this.onLoadData();
  }

  ngOnDestroy() {
  }

  agGridInit() {
    this.gridDriversPool = <GridOptions>{
      columnDefs: [
        { headerName: '', checkboxSelection: true, width: 50 },
        { headerName: 'Last Name', field: 'last_name', width: 100 },
        { headerName: 'First Name', field: 'first_name', width: 100 },
        { headerName: 'Phone', field: 'phone', width: 140 },
        { headerName: 'Availability', field: 'availability', width: 100 },
        { headerName: 'Sunday', field: 'sunday', width: 100, hide: this.nameday !== 'sunday' },
        { headerName: 'Monday', field: 'monday', width: 100, hide: this.nameday !== 'monday' },
        { headerName: 'Tuesday', field: 'tuesday', width: 100, hide: this.nameday !== 'tuesday' },
        { headerName: 'Wednesday', field: 'wednesday', width: 100, hide: this.nameday !== 'wednesday' },
        { headerName: 'Thursday', field: 'thursday', width: 100, hide: this.nameday !== 'thursday' },
        { headerName: 'Friday', field: 'friday', width: 100, hide: this.nameday !== 'friday' },
        { headerName: 'Saturday', field: 'saturday', width: 100, hide: this.nameday !== 'saturday' }
      ],
      onFilterModified: () => {
        this.count = this.gridDriversPool.api.getDisplayedRowCount();
      }
    };
  }

  onLoadData(reload?) {
    this.busy = this.driverPerDiemService.getAllPerDiemDate(this.currentDate_).subscribe((resp: any) => {
      if (resp.status === 'success') {
        this.rowData = resp.data;
        this.gridDriversPool.api.setRowData(resp.data);
        this.gridDriversPool.api.setFilterModel(reload);
        this.count = this.gridDriversPool.api.getDisplayedRowCount();
      }
    });
  }

  getPerDiemAvailability(filter: string) {
    this.busy = this.driverPerDiemService.getPerDiemAvailability(filter).subscribe((resp: any) => {
      if (resp.status === 'success') {
        this.rowData = resp.data;
        this.gridDriversPool.api.setRowData(resp.data);
        this.count = this.gridDriversPool.api.getDisplayedRowCount();
      }
    });
  }

  onChangeFilter(event: any) {
    this.availability = event;
    if (event === 'All') {
      this.onLoadData();
    } else {
      this.getPerDiemAvailability(event);
    }
    this.totalHours = 0;
  }
anyanyany
  onCellDoubleClicked(event) {
    this.getHoursWorked(event.data.sundial_time_id);
  }

  getHoursWorked(driver_id: any) {
    this.busy = this.driverPerDiemService.getHoursWorkedByDriverScheduleDetails(driver_id, this.start_date, this.end_date).subscribe((resp: any) => {
      if (resp.status === 'success') {
        if (resp.data.length > 0) {
          this.totalHours = resp.data[0].hours? resp.data[0].hours: 0;
        } else {
          this.totalHours = 0;
        }
      }
    });
  }

  onShowDrivers(value: boolean, option: number) {
    if (value) {

      option === 1 ? this.ShowDriversStandby.nativeElement.checked = false : this.ShowDriversOff.nativeElement.checked = false;

      switch (this.nameday) {
        case 'sunday':
          option === 1 ? this.gridDriversPool.api.setFilterModel({sunday: ['Off']}) : this.gridDriversPool.api.setFilterModel({sunday: ['Standby']});
          break;
        case 'monday':
          option === 1 ? this.gridDriversPool.api.setFilterModel({monday: ['Off']}) : this.gridDriversPool.api.setFilterModel({monday: ['Standby']});
          break;
        case 'tuesday':
          option === 1 ? this.gridDriversPool.api.setFilterModel({tuesday: ['Off']}) : this.gridDriversPool.api.setFilterModel({tuesday: ['Standby']});
          break;
        case 'wednesday':
          option === 1 ? this.gridDriversPool.api.setFilterModel({wednesday: ['Off']}) : this.gridDriversPool.api.setFilterModel({wednesday: ['Standby']});
          break;
        case 'thursday':
          option === 1 ? this.gridDriversPool.api.setFilterModel({thursday: ['Off']}) : this.gridDriversPool.api.setFilterModel({thursday: ['Standby']});
          break;
        case 'friday':
          option === 1 ? this.gridDriversPool.api.setFilterModel({friday: ['Off']}) : this.gridDriversPool.api.setFilterModel({friday: ['Standby']});
          break;
        case 'saturday':
          option === 1 ? this.gridDriversPool.api.setFilterModel({saturday: ['Off']}) : this.gridDriversPool.api.setFilterModel({saturday: ['Standby']});
          break;
      }
    } else {
      this. gridDriversPool.api.setFilterModel(null);
    }
    this.count = this.gridDriversPool.api.getDisplayedRowCount();
    this.gridDriversPool.onFilterChanged = function (params) {};
  }

  onRefresh() {
    const model = this.gridDriversPool.api.getFilterModel();
    this.onLoadData(model);
  }

}
