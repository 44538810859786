import { Component, OnInit, OnDestroy, AfterViewInit, Input, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Subject, Subscription } from 'rxjs';
import { ClientService } from '../../../services/fleet/client.service';
import { InvoiceService } from '../../../services/billing/invoice.service';
import Swal from 'sweetalert2';
import { TabsetComponent } from 'ngx-bootstrap';
import { BsModalComponent } from 'ng2-bs3-modal';
import { AgGridNg2 } from 'ag-grid-angular';
import { ReportsManageService } from '../../../services/reports-manage.service';
import { DomSanitizer } from '@angular/platform-browser';
import { IMyDpOptions } from 'mydatepicker';
import AgGridHelpers from '../../../helpers/ag-grid-helpers';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {WorkflowService} from '../../../services/billing/workflow.service';
import { convertToUppercaseFirstLetter } from '../../../helpers/capitalize';

@Component({
  selector: 'app-unprocessed-runs',
  templateUrl: './unprocessed-runs.component.html',
  styleUrls: ['./unprocessed-runs.component.scss']
})

export class UnprocessedRunsComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  private destroy: Subject<boolean> = new Subject<boolean>();
  @ViewChild('staticTabs') staticTabs: TabsetComponent;
  @ViewChild('modal') modal: BsModalComponent;
  @ViewChild('modalDisruptions') modalDisruptions: BsModalComponent;
  @ViewChild('modalDateInvoiceSubmitted') modalDateInvoiceSubmitted: BsModalComponent;
  @ViewChild('gridRunsAg') gridRunsAg: AgGridNg2;
  dataManifest: any = {};
  tabAddDrivers = false;
  tabHistory = false;
  tabAddVehicles = false;
  tabAddStops = false;
  tabInterruptions = false;
  gridClear = Math.random();
  actualDate = new Date();
  date_submitted: any;

  myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'mm/dd/yyyy'
  };

  @Input() onClearGrid?= false;
  grid: GridOptions;
  gridRuns: GridOptions;
  gridProcessed: GridOptions;
  busy: Subscription[] = [];
  dataParams: any = {};
  routes = [];
  blocks = [];
  dateSearch = null;
  getContextMenuItems;
  getContextMenuItemsRuns;
  manifestBilled = false;

  menuReportList: any[] = [];
  paramsModal: any[] = [];
  reportIdModal = 0;

  freeTextForm: FormGroup;
  freeTextParams: any[] = [];
  freeTextModalTitle: string;

  constructor(
    private clientService: ClientService,
    private invoiceService: InvoiceService,
    private reportsManageService: ReportsManageService,
    public sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private workflowService: WorkflowService
  ) {
    this.initGrid();
    this.initGridRuns();
    this.initGridProcessed();
    this.freeTextForm = this.fb.group({});
  }

  ngOnInit() {
    this.invoiceService.load_invoices$
    .pipe(takeUntil(this.destroy))
    .subscribe(res => {
      this.grid.api.setRowData([]);
      this.gridRuns.api.setRowData([]);
      this.gridProcessed.api.setRowData([]);
      this.dataParams = res;
      this.onSearch();
    });
    this.invoiceService.verified_blocks_runs$
    .pipe(takeUntil(this.destroy))
    .subscribe(res => {
      const select = this.gridProcessed.api.getSelectedNodes();
      if (select.length > 0) {
        Swal.fire({
          title: 'Are you sure?',
          html: '<h4>Are you sure you want to verify the selected blocks?</h4>',
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then((response: any) => {
          if (response.value) {
            const data: any = {
              'ids': select.map(s => s.data.manifest_id),
              'billing_status_id': 2
            };
            this.busy.push(
              this.invoiceService.putBillingStatus(data).subscribe((resp: any) => {
                if (resp.status === 'success') {
                  this.onSelectionBlocksToChange(this.gridRunsAg);
                  this.toastr.success('Blocks verified successfully');
                }
              })
            );
          }
        });
      } else {
        this.toastr.warning('Please select at least one block');
      }
    });
  }

  ngAfterViewInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['onClearGrid']) {
      if (this.onClearGrid && this.grid.api) {
        this.grid.api.setRowData([]);
        this.dateSearch = null;
        this.dataManifest = {};
      }
    }
  }

  ngOnDestroy() {
    this.destroy.next(true);
    this.destroy.unsubscribe();
    return this.busy = [];
  }

  initGrid() {
    this.grid = <GridOptions>{
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      rowSelection: 'multiple',
      onGridReady: () => {
        this.grid.api.setRowData([]);
      },
      columnDefs: [
        {
          headerName: 'Contracted Original Estimated Amount', field: 'exception',
          cellStyle: { 'text-align': 'center' },
          children: [
            {
              headerName: '', field: 'exception', pinned: 'left', width: 40,
              cellStyle: { 'text-align': 'center' },
              cellRenderer: (params) => {
                const icon = params.data.exception === '1' ? `<i style="color: red" class="fa fa-circle"></i>` : '';
                return icon;
              }
            },
            { headerName: 'Date', field: 'date', width: 80, pinned: 'left' },
            { headerName: 'Day', field: 'day', width: 65, pinned: 'left' },
          ]
        },
        {
          headerName: 'TOTAL',
          cellStyle: { 'text-align': 'center' },
          children: [
            { headerName: 'Amb', field: 'amb', width: 70 },
            { headerName: 'WC', field: 'wc', width: 70 },
            { headerName: 'Bike', field: 'str', width: 70 },
            { headerName: 'Pax', field: 'total_pax', width: 70 },
            { headerName: 'Hours', field: 'total_hours', width: 80, cellRenderer: (params) => params.value ? Number(params.value).toFixed(2) : Number(0) },
            { headerName: 'Total Miles', field: 'total_miles', width: 80 },
          ]
        },
        {
          headerName: 'SERVICE',
          cellStyle: { 'text-align': 'center' },
          children: [
            { headerName: 'Hours', field: 'service_hours', width: 80, cellRenderer: (params) => params.value ? Number(params.value).toFixed(2) : Number(0) },
            { headerName: 'Miles', field: 'service_miles', width: 80 },
          ]
        },
        {
          headerName: 'MANIFEST',
          cellStyle: { 'text-align': 'center' },
          children: [
            { headerName: 'Hours', field: 'service_hours_manifest', width: 80, cellRenderer: (params) => params.value ? Number(params.value).toFixed(2) : Number(0) },
            { headerName: 'Difference', field: 'diff_hours', width: 100 },
            {
              headerName: '', width: 40, cellRenderer: (params) => {
                let icon = '';
                if (Number(params.data.diff_manifest) !== 0) {
                  icon = `${icon} <i style="color: #b55400" class="fa fa-exclamation-triangle"></i>`;
                }
                return icon;
              }
            },

          ]
        },
        {
          headerName: 'TOTAL',
          cellStyle: { 'text-align': 'center' },
          children: [
            {
              headerName: 'Cost', field: 'total_cost', width: 85,
              cellRenderer: this.CurrencyCellRenderer
            },
          ]
        }
      ]
    };
    this.grid.getRowClass = params => {
      if (!params.data.day) {
        return 'item_total_grid';
      }
    };

    this.getContextMenuItems = () => {
      const result = [];
      this.menuReportList.forEach(report => {
        if (report) {
          const menu = report;
          const item = AgGridHelpers.itemMenu(menu.description, menu.icon, () => {
            this.validateModal(menu.menu_report_params, menu.url, menu.description);
          });
          result.push(item);
        }
      });
      result.push('separator', 'copy', 'export');
      return result;
    };
  }

  initGridRuns() {
    this.gridRuns = <GridOptions> {
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      rowSelection: 'multiple',
      onGridReady: () => {
        this.gridRuns.api.setRowData([]);
      },
      columnDefs: [
        {
          headerName: 'RUNS',
          cellStyle: { 'text-align': 'center' },
          children: [
            {
              headerName: '', headerCheckboxSelection: true,
              headerCheckboxSelectionFilteredOnly: true,
              checkboxSelection: (params) => params.data.run_id ? true : false, width: 40
            },
            {
              headerName: '', field: 'exception', width: 40,
              cellStyle: { 'text-align': 'center' },
              cellRenderer: (params) => {
                return params.data.exception === '1' ? `<i style="color: red" class="fa fa-circle" aria-hidden="true"></i>` : '';
              }
            },
            { headerName: 'Run', field: 'run', width: 130 },
            { headerName: 'Amb', field: 'amb', width: 70 },
            { headerName: 'WC', field: 'wc', width: 70 },
            { headerName: 'Bike', field: 'str', width: 70 },
          ]
        },
        {
          headerName: 'TOTAL',
          cellStyle: { 'text-align': 'center' },
          children: [
            { headerName: 'Pax', field: 'total_pax', width: 70 },
            { headerName: 'Hours', field: 'total_hours', width: 80, cellRenderer: (params) => params.value ? Number(params.value).toFixed(2) : Number(0) },
            { headerName: 'Miles', field: 'total_miles', width: 80 },
          ]
        },
        {
          headerName: 'SERVICE',
          cellStyle: { 'text-align': 'center' },
          children: [
            { headerName: 'Hours', field: 'service_hours', width: 80, cellRenderer: (params) => params.value ? Number(params.value).toFixed(2) : Number(0) },
            { headerName: 'Miles', field: 'service_miles', width: 80 },
            { headerName: 'Schedule Hours', field: 'scheduled_hours', width: 130, cellRenderer: (params) => params.value ? Number(params.value).toFixed(2) : Number(0) },
          ]
        },
        { headerName: 'Exception', field: 'type_exception', width: 100 }
      ]
    };
    this.gridRuns.getRowClass = params => {
      if (!params.data.run_id) {
        return 'item_total_grid';
      }
    };
    this.getContextMenuItemsRuns = (params) => {
      const x = this.grid;
      const result = [
        AgGridHelpers.itemMenu('Print', 'fa-print', () => {
          this.printRuns();
        }),
        'separator',
        'copy',
        'export',
      ];
      return result;
    };
  }

  initGridProcessed() {
    this.gridProcessed = <GridOptions> {
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      rowSelection: 'multiple',
      onGridReady: () => {
        this.gridProcessed.api.setRowData([]);
      },
      columnDefs: [
        {
          headerName: 'BLOCKS',
          cellStyle: { 'text-align': 'center' },
          children: [
            {
              headerName: '', headerCheckboxSelection: true,
              headerCheckboxSelectionFilteredOnly: true,
              checkboxSelection: (params) => Number(params.data.billing_status_id) === 1, width: 40
            },
            {
              headerName: '', field: 'exception', width: 40,
              cellStyle: { 'text-align': 'center' },
              cellRenderer: (params) => {
                return params.data.exception === '1' ? `<i style="color: red" class="fa fa-circle" aria-hidden="true"></i>` : '';
              }
            },
            {
              headerName: 'Verified', width: 80,
              colId: 'btnVerified',
              cellStyle: { 'text-align': 'center' },
              cellRenderer: (params) => {
                let icon = '';
                if (Number(params.data.billing_status_id) === 1) {
                  icon = `<i style="color: orange" class="fa fa-warning" aria-hidden="true"></i>`;
                } else if (Number(params.data.billing_status_id) === 3) {
                  icon = `<i style="color: blue" class="fa fa-money" aria-hidden="true"></i>`;
                } else if (Number(params.data.billing_status_id) === 2) {
                  icon = '<i style="color: green" class="fa fa-check" aria-hidden="true"></i>';
                }
                return icon;
              }
            },
            { headerName: 'Manifest', field: 'manifest_id', width: 90 },
            { headerName: 'Status', field: 'billing_status', width: 100 },
            { headerName: 'Block', field: 'blocks', width: 130 },
            { headerName: 'Vehicle', field: 'vehicle', width: 130 },
            { headerName: 'Amb', field: 'amb', width: 70 },
            { headerName: 'WC', field: 'wc', width: 70 },
            { headerName: 'Bike', field: 'str', width: 70 },
          ]
        },
        {
          headerName: 'TOTAL',
          cellStyle: { 'text-align': 'center' },
          children: [
            { headerName: 'Pax', field: 'total_pax', width: 70 },
            { headerName: 'Hours', field: 'total_hours', width: 80 },
            { headerName: 'Miles', field: 'total_miles', width: 80 },
          ]
        },
        {
          headerName: 'SERVICE',
          cellStyle: { 'text-align': 'center' },
          children: [
            { headerName: 'Hours', field: 'service_hours', width: 80 },
            { headerName: 'Miles', field: 'service_miles', width: 80 },
            { headerName: 'Schedule Hours', field: 'scheduled_hours', width: 130 },
            { headerName: 'Schedule ID', field: 'schedule_detail_id', width: 130 },
          ]
        }
      ]
    };
  }

  autoSizeAll() {
    const allColumnIds = [];
    this.grid.columnApi.getAllColumns().forEach(function (column: any) {
      allColumnIds.push(column.colId);
    });
    this.grid.columnApi.autoSizeColumns(allColumnIds);

    // const allColumnIdsBlocks = [];
    // this.gridRuns.columnApi.getAllColumns().forEach(function(column: any) {
    //   allColumnIdsBlocks.push(column.colId);
    // });
    // this.gridRuns.columnApi.autoSizeColumns(allColumnIdsBlocks);
  }

  onChangeClient(client_id: number) {
    this.busy.push(this.clientService.getRoutesByClient(client_id).subscribe((resp: any) => {
      this.routes = resp.data;
    }));
  }

  onSearch() {
    const data: any = Object.assign({}, this.dataParams);
    if (data.routes_id && data.client_id && data.start_date && data.end_date) {
      this.onLoadMenuReport(Number(data.client_id));
      this.busy.push(
        this.invoiceService
          .getDataRuns({
            client_id: data.client_id,
            route_id: data.routes_id,
            start_date: data.start_date,
            end_date: data.end_date
          })
          .subscribe(resp => {
            const list = Object.assign([], resp.data);
            this.canOpenInvoiceModal(list);
            list.push({
              exception: null,
              date: null,
              day: null,
              amb: resp.total_amb,
              wc: resp.total_wc,
              str: resp.total_str,
              total_pax: resp.total_pax,
              total_hours: resp.total_hours,
              total_miles: resp.total_miles,
              service_hours: resp.total_service_hours,
              service_miles: resp.total_service_miles,
              total_cost: resp.total_cost,
              diff_manifest: 0,
            });
            if (this.grid.api) {
              this.grid.api.setRowData(list);
              // this.autoSizeAll();
              this.onSelectRuns();
            }
          })
      );
    }
  }

  canOpenInvoiceModal(list: Array<any>): void {
    list.length ? this.workflowService.canOpenInvoiceModalSubject.next(true) : this.workflowService.canOpenInvoiceModalSubject.next(false);
  }

  onLoadMenuReport(client_id: number) {
    this.busy.push(
      this.clientService.getMenuReportClient(client_id).subscribe((resp: any) => {
        this.menuReportList = resp;
      }, error => {
        Swal.fire({
          type: 'error',
          title: 'Error...',
          text: error.message
        });
      })
    );
  }

  OnCellDoubleClickedRuns(event) {
    this.gridProcessed.api.setRowData([]);
    if (event.data.dateOfService !== undefined) {
      const data: any = Object.assign({}, this.dataParams);
      this.dateSearch = event.data.dateOfService;
      this.busy.push(this.invoiceService.getDataBlocksRuns({
        date: event.data.dateOfService,
        route_id: data.routes_id,
        client_id: data.client_id,
      }).subscribe((resp: any) => {
        const list = Object.assign([], resp.data);
        list.push({
          amb: resp.total_amb,
          run: null,
          run_id: null,
          service_hours: resp.total_service_hours,
          service_miles: resp.total_service_miles,
          str: resp.total_str,
          total_hours: resp.total_hours,
          total_miles: resp.total_miles,
          total_pax: resp.total_pax,
          wc: resp.total_wc,
          scheduled_hours: resp.total_scheduled_hours
        });
        this.gridRuns.api.setRowData(list);
        if (this.invoiceService.load_runs_of_unprocessed_block) {
          this.gridRuns.api.selectAll();
        }
      }));
    }
  }

  onSelectionBlocksToChange(event) {
    const data: any = Object.assign({}, this.dataParams);
    const selectedRows = event.api.getSelectedNodes();
    const selecteds = [];
    selectedRows.forEach(element => {
      if (element.data.run_id) selecteds.push(element.data.run_id);
    });
    if (selecteds.length > 0) {
      this.busy.push(this.invoiceService.getDataProcessRuns({
        date: this.dateSearch,
        run_ids: selecteds,
        client_id: data.client_id,
      }).subscribe((resp: any) => {
        this.gridProcessed.api.setRowData(resp);
        this.onSelectRunsBlock();
      }));
    } else {
      this.gridProcessed.api.setRowData([]);
    }
  }

  OnCellDoubleClickedBlock(event) {
    this.staticTabs.tabs[0].active = true;
    this.dataManifest = {
      id: event.data.manifest_id,
      schedule_detail_id: event.data.schedule_detail_id,
      dateOfService: event.data.dateOfService,
    };
    this.modal.open();
  }

  loadDataOne(dataManifest): void {
    this.dataManifest = dataManifest;
  }

  closeForm() {
    this.onSelectionBlocksToChange(this.gridRunsAg);
    this.dataManifest = {};
    this.modal.close();
  }

  printRuns() {
    const parameter: any = {
      'client_id': [
        `${this.dataParams.client_id}`
      ],
      'dateOfService': [
        `${this.dataParams.start_date}`,
        `${this.dataParams.end_date}`
      ],
      'route_id': this.dataParams.routes_id
    };
    this.busy.push(this.reportsManageService.getReportTrip2(2138, parameter).subscribe((resp: string) => {
      this.reportsManageService.loadReportWindows(resp);
    }));
  }

  validateModal(params: any[], report_id: number, title_modal: string) {
    const formParams = {};
    this.freeTextParams = params.filter(item => item.type_free_text);
    if (this.freeTextParams.length) {
      this.freeTextModalTitle = title_modal;
      this.freeTextParams.forEach(param => {
        if (param) {
          formParams[param.param_name] = new FormControl(this.valueTypeFreeText(param.type_free_text), Validators.required);
        }
      });
      this.freeTextForm = this.fb.group(formParams);
      this.paramsModal = params;
      this.reportIdModal = report_id;
      this.modalDateInvoiceSubmitted.open('sm').then();
    } else {
      this.onPrintReport(params, report_id);
    }
  }

  valueTypeFreeText(typeFreeText) {
    switch (typeFreeText) {
      case 'boolean': return false;
      case 'int': return 0;
      default: return null;
    }
  }

  onPrintReport(params: any[], report_id: number) {
    const parameter: any = new Object();
    if (params && params.length) {
      params.forEach(param => {
        if (param) {
          switch (param.code) {
            case 'date':
              parameter[param.param_name] = [`${this.dataParams.start_date}`, `${this.dataParams.end_date}`];
              break;
            case 'start_date':
              parameter[param.param_name] = this.dataParams.start_date;
              break;
            case 'end_date':
              parameter[param.param_name] = this.dataParams.end_date;
              break;
            case 'client_id':
              parameter[param.param_name] = this.typeClient(param.array, parameter);
              break;
            case 'default':
              parameter[param.param_name] = param.default_values.split(',');
              break;
            case 'routes_id':
              parameter[param.param_name] = this.typeRoutes(param.array, parameter);
              break;
            case 'route_id':
              parameter[param.param_name] = this.typeRoute(param.array, parameter);
              break;
            case 'route_group_id':
              parameter[param.param_name] = this.typeGroupRoutes(param.array, parameter);
              break;
            case 'free_text':
              parameter[param.param_name] = this.typeFreeText(param);
              break;
          }
        }
      });
    }
    this.busy.push(
      this.reportsManageService.getReportTrip2(report_id, parameter).subscribe((resp: string) => {
        if (resp) {
          this.reportsManageService.loadReportWindows(resp);
          this.modalDateInvoiceSubmitted.close().then();
          this.onCloseModal();
        }
      })
    );
  }


  typeFreeTextTitle(paramName: any) {
    if (!paramName || typeof paramName !== 'string') {
      return paramName;
    }
    const cadena = paramName.split('_');
    return cadena.map(convertToUppercaseFirstLetter).join(' ');
  }


  typeFreeText(param: any) {
    const getParameter = `${param.param_name}`;
    const dataForm = this.freeTextForm.get(getParameter.toString()).value;

    if (dataForm === null || dataForm === undefined) {
      return null;
    }

    switch (param.type_free_text) {
      case 'date': return dataForm.formatted ? dataForm.formatted : null;
      case 'boolean': return dataForm ? '1' : '0';
      case 'int': return dataForm ? dataForm : 0;
      case 'string': return dataForm ? dataForm : '';
      default: return dataForm;
    }
  }

  typeRoutes(type: any, parameter: any) {
    switch (type) {
      case 'selected':
        parameter = this.dataParams.routes_id;
        break;
      case 'all':
        parameter = this.dataParams.routes;
        break;
      case 'no array':
        parameter = `${this.dataParams.routes_id[0]}`;
        break;
    }
    return parameter;
  }

  typeRoute(type: any, parameter: any) {
    switch (type) {
      case 'selected':
        parameter = this.dataParams.routes_id;
        break;
      case 'all':
        parameter = this.dataParams.routes;
        break;
      case 'no array':
        parameter = `${this.dataParams.routes_id}`;
        break;
      case 'array':
        parameter = [this.dataParams.routes_id];
        break;
    }
    return parameter;
  }

  typeGroupRoutes(type: any, parameter: any) {
    switch (type) {
      case 'selected':
        parameter = this.dataParams.route_group_id;
        break;
      case 'all':
        parameter = this.dataParams.routes_groups_id;
        break;
      case 'no array':
        parameter = `${this.dataParams.route_group_id[0]}`;
        break;
    }
    return parameter;
  }

  typeClient(type: any, parameter: any) {
    switch (type) {
      case 'no array':
        parameter = `${this.dataParams.client_id}`;
        break;
      default:
        parameter = [`${this.dataParams.client_id}`];
    }
    return parameter;
  }

  CurrencyCellRenderer(params: any) {
    const usdFormate = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });
    return usdFormate.format(params.value);
  }

  viewDisruptions(data) {
    const info = {
      start_date: data.dateOfService,
      end_date: data.dateOfService,
      client_id: this.dataParams.client_id,
      routes_id: this.dataParams.routes_id,
    };
    this.modalDisruptions.open();
    this.invoiceService.load_interruptions$.next(info);
  }

  // Cuando se llega el Runs desde Unprocessed block
  onSelectRuns() {
    if (this.invoiceService.load_runs_of_unprocessed_block) {
      const date: any = this.invoiceService.load_runs_of_unprocessed_block.date;
      this.grid.api.forEachNode((node: any) => {
        if (node.data.date === date) {
          // TODO color del nodo cuando sea encontrado...
         /* this.grid.getRowStyle = function(params) {
            return { background: '#ff9998 !important'};
          }; */
          this.grid.api.selectNode(node, true);
          this.OnCellDoubleClickedRuns(node);
        }
      });
    }
  }

  onSelectRunsBlock() {
    if (this.invoiceService.load_runs_of_unprocessed_block) {
      const manifest: any = this.invoiceService.load_runs_of_unprocessed_block.manifest;
      this.gridProcessed.api.forEachNode((node: any) => {
        if (node.data.manifest_id === manifest) {
          this.gridProcessed.api.selectNode(node, true);
        }
      });
    }
  }

  onCellClickedProcessed(event: any) {
    if (event.colDef.colId === 'btnVerified') {
      const data = event.data;
      let msj = '';
      Number(data.exception) !== 1 ? msj = 'Are you sure you want to verify the selected manifests?' : msj = 'This manifest has exceptions do you want to check anyway?';
      if (Number(data.billing_status_id) === 1) {
          Swal.fire({
            title: 'Are you sure?',
            html: `<h4>${msj}</h4>`,
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
          }).then((response: any) => {
            if (response.value) {
              const ids: any = this.gridProcessed.api.getSelectedRows().map(row => row.manifest_id);
              const dataSend: any = {
                'ids': ids,
                'billing_status_id': 2
              };
              this.busy.push(this.invoiceService.putBillingStatus(dataSend).subscribe((resp: any) => {
                if (resp.status === 'success') {
                  Swal.fire({
                    position: 'top-end',
                    type: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500
                  }).then(() => {
                    // Toma los runs seleccionados, limpia la grid y los asigna de nuevo....
                    const select = this.gridRuns.api.getSelectedNodes();
                    this.gridRuns.api.deselectAll();
                    select.forEach((row) => {
                      this.gridRuns.api.forEachNode((node) => {
                          if (row.id === node.id) {
                            this.gridRuns.api.selectNode(node, true);
                          }
                        }
                      );
                    });

                    // this.gridRuns.api.selectAll();
                    // Recargamos la infor del tb de Unprocessed
                    const info = {
                      start_date: this.dataParams.start_date,
                      end_date: this.dataParams.end_date,
                      client_id: this.dataParams.client_id,
                      routes_id: this.dataParams.routes_id,
                    };
                    this.invoiceService.load_unprocessed_block$.next(info);
                  });
                }
              }));
            }
          });
        }

    }
  }

  onCloseModal() {
    this.paramsModal = null;
    this.date_submitted = '';
    this.reportIdModal = null;
    this.removeAllControls();
    this.freeTextParams = null;
    this.freeTextModalTitle = null;
    this.modalDateInvoiceSubmitted.close();
  }

  removeAllControls() {
    const formGroup = this.freeTextForm;
    this.freeTextForm.reset();
    if (formGroup) {
      Object.keys(formGroup.controls).forEach(key => {
        if (key) {
          formGroup.removeControl(key);
        }
      });
    }
  }

}
