import { GridOptions, RowNode } from 'ag-grid-community';

export interface IMenuItem {
  name: string;
  icon: string;
  cssClasses: string[];
  action: (params: any) => void;
}

export default class AgGridHelpers {

  static itemMenu(name: string, icon: string, callback): IMenuItem {
    return {
          name: `${name}`,
          cssClasses: ['redFont', 'bold'],
          icon: icon ? '<i class="fa ' + icon + '"></i>' : '',
          action: (params) => {
            callback(params);
          }
        };
  }

  static statusRecord(active) {
    return active ?
      '<span class="label label-success" style="margin-top: 4px; position: absolute;">Active</span>' :
      '<span class="label label-danger" style="margin-top: 4px; position: absolute;">Disabled</span>';
  }

  static statusBlock(block_status) {
    let label = '';
    let style = '';
    switch (block_status) {
      case 'Unassigned':
        label = 'Unassigned';
        style = 'label-warning';
        break;
      case 'In Service':
        label = 'In Service';
        style = 'label-primary';
        break;
      case 'Out of Service':
        label = 'Out of Service';
        style = 'label-secondary';
        break;
      case 'Assigned':
        label = 'Assigned';
        style = 'label-info';
        break;
      case 'Completed':
        label = 'Completed';
        style = 'label-success';
        break;
      case 'Off':
        label = 'Off';
        style = 'label-danger';
        break;
    }
      return `<span class="label ${style}" style="margin-top: 4px; position: absolute;">${ label }</span>`;
  }

  static statusBilling(status_billing) {
    let label: string;
    let style: string;
    switch (status_billing) {
      case 'Rejected':
        label = 'Rejected';
        style = 'label-warning';
        break;
      case 'Verified':
        label = 'Verified';
        style = 'label-primary';
        break;
      case 'Unverified':
        label = 'Unverified';
        style = 'label-info';
        break;
      default:
        label = 'Unverified';
        style = 'label-info';
        break;
    }
    return `<span class="label ${style}" style="margin-top: 4px; position: absolute;">${ label }</span>`;
  }

  static statusExpired() {
    return '<span class="label label-danger" style="margin-top: 4px; position: absolute;">Expired</span>';
  }

  static iconRecord(field: any, icon: any) {
    return field ?
      `<i class="fa ${icon} " aria-hidden="true"></i>` :
      '';
  }

  static getSelectNodeAfterFilter = (agGrid: GridOptions): any[] => {
    const row = [];
    if (agGrid.api) {
      agGrid.api.forEachNodeAfterFilter((node: RowNode) => {
        if (node.isSelected()) {
          row.push(node.data);
        }
      });
    }
    return row;
  }

  static CurrencyCellRenderer(params): string {
    const { value } = params;

    const usdFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });

    return value ? usdFormatter.format(value) : undefined;
  }
}
