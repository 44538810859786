import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {InvoiceService} from '../../../services/billing/invoice.service';
import {GridOptions} from 'ag-grid-community';
import {BsModalComponent} from 'ng2-bs3-modal';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-missed-service-events',
  templateUrl: './missed-service-events.component.html',
  styleUrls: ['./missed-service-events.component.scss']
})

export class MissedServiceEventsComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('modalMissedService') modalMissedService: BsModalComponent;
  @Input() onClearGrid? = false;
  missedServiceEvent: any = {};
  busy: Subscription[] = [];
  dataParams: any = {};
  getContextMenuItems;
  grid: GridOptions;

  constructor(private invoiceService: InvoiceService) {
    this.onInitGrid();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['onClearGrid']) {
      if (this.onClearGrid && this.grid.api) {
        this.grid.api.setRowData([]);
      }
    }
  }

  ngOnInit() {
    this.invoiceService.load_missed_service_events$.subscribe((resp: any) => {
      this.dataParams = resp;
      this.onSearch();
    });
  }

  ngOnDestroy() {
    return (this.busy = []);
  }

  onInitGrid() {
    this.grid = <GridOptions> {
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      rowSelection: 'single',
      enableRangeSelection: true,
      enableFilter: true,
      columnDefs: [
        {
          headerName: 'Event Date',
          field: 'date',
          cellRenderer: (params: any) => {
            return moment(params.value).format('MM-DD-YYYY');
          },
          width: 110
        },
        { headerName: 'Total Missed Service In Minutes', field: 'total_minutes', width: 200 },
        {
          headerName: 'Billable Missed Service In Minutes',
          field: 'billable_minutes',
          cellRenderer: params => {
            return params.value === null ? 'N/A' : params.value;
          },
          width: 220
        },
        {
          headerName: 'Invoice Reduction',
          field: 'invoice_reduction',
          cellRenderer: params => {
            return params.value === null ? 'N/A' : params.value;
          },
          width: 160
        },
        {
          colId: 'deleteRow',
          cellRenderer: () => {
            return '<span <i class="fa fa-trash-o" style="color: red; cursor: pointer;" aria-hidden="true"></i></span>';
          },
          width: 50
        }
      ],
      onGridReady: () => {
        this.grid.api.setRowData([]);
      }
    };
    this.getContextMenuItems = () => {
      return [
        {
          name: 'Add Missed Service Event',
          icon: '<i class="fa fa-plus"></i>',
          action: () => {
            this.missedServiceEvent = null;
            this.modalMissedService.open('sm').then();
          }
        },
        'separator',
        'copy',
        'export'
      ];
    };
  }

  onSelectButton(params: any) {
    if (params.colDef.colId === 'deleteRow') {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Disable this record?',
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then(result => {
        if (result.value) {
          this.onDeleteRow(params.data);
        }
      });
    }
  }

  onDeleteRow(row: any) {
    if (row) {
      const data = Object.assign({}, row)
      data.active = false;
      this.busy.push(
        this.invoiceService.updateMissedServiceEvent(data).subscribe(
          (resp: any) => {
            {
              Swal.fire('Success!', resp.message, 'success').then(() => {
                this.onSearch();
              });
            }
          },
          error => {
            Swal.fire({
              title: 'Opss!',
              text: error.message,
              type: 'error'
            }).then();
          }
        )
      );
    }
  }

  onReload() {
    this.modalMissedService.close().then();
    this.onSearch();
  }

  onRowDoubleClicked(event: any) {
    this.missedServiceEvent = event;
    this.modalMissedService.open().then();
  }

  onSearch() {
    const info = {
      client_id: this.dataParams.client_id,
      start_date: this.dataParams.start_date,
      end_date: this.dataParams.end_date
    };
    if (info.start_date && info.end_date && info.client_id) {
      this.busy.push(
        this.invoiceService.getMissedServiceEvents(info).subscribe((resp: any) => {
          if (this.grid.api) {
            this.grid.api.setRowData(resp);
          }
        })
      );
    }
  }
}
