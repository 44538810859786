import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderFilterComponent } from './header-filter/header-filter.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SelectWeeksModule } from '../select-weeks/select-weeks.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    SelectWeeksModule,
  ],
  declarations: [HeaderFilterComponent],
  exports: [
    HeaderFilterComponent
  ]
})
export class HeaderFiltersModule { }
