import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CellDoubleClickedEvent, GridOptions } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { Subscription } from 'rxjs-compat';
import { InvoiceReportService } from '../../invoice-report.service';
import { SweetAlertHelper } from '../../../../../../CORE/helpers/sweet-alert-helper.service';
import { BsModalComponent } from 'ng2-bs3-modal';
import { IBatchCharge, ChargeBillingStatus } from '../../../../../../CORE/interfaces/invoice-report.interface';
import { IResponse, ResponseStatus } from '../../../../../../CORE/interfaces/response.interface';
import { takeUntil } from 'rxjs/operators';
import { SweetAlertResult } from 'sweetalert2';
import AgGridHelpers from '../../../../../../helpers/ag-grid-helpers';

@Component({
  selector: 'app-charges-grid',
  templateUrl: './charges-grid.component.html',
  styleUrls: ['./charges-grid.component.scss']
})
export class ChargesGridComponent implements OnInit, OnDestroy {
  @ViewChild('modalAddEditCharge') modalAddEditCharge: BsModalComponent;
  gridCharges: GridOptions;
  destroy: Subject<boolean> = new Subject<boolean>();
  busy: Subscription [] = [];

  batchId: number;
  addOrEditCharge: string;
  showStatusChargeReverse = false;

  constructor(
    private invoiceReportService: InvoiceReportService,
    private sweetAlertHelper: SweetAlertHelper
  ) {
    this.initGrid();
  }

  ngOnInit(): void {
    this.makeSubscriptions();
  }

  private initGrid(): void {
    this.gridCharges = <GridOptions>{
      enableFilter: true,
      enableSorting: true,
      enableColResize: true,
      columnDefs: [
        {headerName: 'Invoice Num', field: 'invoice_num', width: 180 },
        {headerName: 'Route', field: 'route_description', cellRenderer: (params) => params.value ? params.value : 'All Routes' },
        {headerName: 'Charge Type', field: 'batch_charge_type_name'},
        {headerName: 'Invoice Date', field: 'invoice_date'},
        {headerName: 'Status', field: 'invoice_status_description'},
        {
          headerName: 'Total Amount',
          field: 'total_amount',
          cellStyle: {textAlign: 'right'},
          cellRenderer: (params) => AgGridHelpers.CurrencyCellRenderer(params)
        },
        {headerName: 'User', field: 'user_name', cellRenderer: (params) => params.value ? params.value : 'SYSTEM'},
        {
          colId: 'deleteRow',
          cellRenderer: (params): string => this.drawTrash(params),
          width: 70
        }
      ],
      getRowStyle: ({data}) => this.drawStyleOriginalCharge(data),
      onGridReady: (): void => {
        this.gridCharges.api.sizeColumnsToFit();
        this.gridCharges.api.setRowData([]);
      },
      onCellDoubleClicked: (event: CellDoubleClickedEvent) => this.sendDataToEditCharge(event)
    };
  }

  private makeSubscriptions(): void {
    this.invoiceReportService.batchIdAction$
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (batchId: number): void => {
          if (batchId) {
            this.batchId = batchId;
            this.getCharges(this.batchId);
          }
        }
      });

    this.invoiceReportService.reloadChargeGridAction$
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (reload: boolean): void => {
          if (reload) {
            this.modalAddEditCharge.close().then(() => this.getCharges(this.batchId));
          }
        }
      });
  }

  private drawTrash(params): string {
    const { invoice_status_description, is_active }: IBatchCharge = params.data;

    if (invoice_status_description !== ChargeBillingStatus.Original && Number(is_active)) {
      return '<span <i class="fa fa-trash-o" style="color: red; cursor: pointer;" aria-hidden="true"></i></span>';
    }
  }

  private getCharges(batchId: number, active: number = 1): void {
    this.busy.push(
      this.invoiceReportService.getBillingChargeBatch(batchId, active)
        .subscribe({
          next: ({data, status}: IResponse<IBatchCharge[]>): void => {
            if (status === ResponseStatus.SUCCESS) {
              data.length > 0
                ? this.invoiceReportService.haveBatchChargeRevisedSubject.next(true)
                : this.invoiceReportService.haveBatchChargeRevisedSubject.next(false);

              this.gridCharges.api.setRowData(data);
            }
          },
          error: error => this.sweetAlertHelper.captureException(error)
        })
    );
  }

  private drawStyleOriginalCharge(dataRow): {} {
    const { invoice_status_description }: IBatchCharge = dataRow;
    if (invoice_status_description === ChargeBillingStatus.Original) {
      return {
        fontWeight: '700',
        backgroundColor: '#9BC2E6',
        color: '#FFF',
      };
    }
  }

  private sendDataToEditCharge(event: CellDoubleClickedEvent): void {
    const { data } = event;
    const { invoice_status_description, is_active }: IBatchCharge = data;
    if (invoice_status_description !== ChargeBillingStatus.Original && Number(is_active)) {
      this.addOrEditCharge = 'Edit';
      this.modalAddEditCharge.open().then(() => this.invoiceReportService.setChargeDateToEdit(data));
    }
  }

  openModalToAddCharge(): void {
    this.addOrEditCharge = 'Create';
    this.invoiceReportService.setChargeDataToEdit(this.batchId);
    this.modalAddEditCharge.open().then();
  }

  onSelectButton(params: any): void {
    const { colDef, data } = params;
    const { is_active, invoice_status_description }: IBatchCharge = data;

    const isDeleteRowColumn: boolean = colDef.colId === 'deleteRow';
    const notTheOriginalCharge: boolean = invoice_status_description !== ChargeBillingStatus.Original;

    if (isDeleteRowColumn && notTheOriginalCharge && Number(is_active)) {
      this.sweetAlertHelper.createCustomAlert({
        type: 'info',
        title: 'Are you sure?',
        text: 'Disable this charger?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result: SweetAlertResult): void => {
        if (result.value) {
          this.onDeleteRow(params.data);
        }
      });
    }
  }

  private onDeleteRow(rowData: IBatchCharge): void {
    const {id: chargeID} = rowData;

    this.busy.push(
      this.invoiceReportService.reverseChargeBatch(chargeID)
        .subscribe({
          next: ({status, data}: IResponse<number>): void => {
            if (status === ResponseStatus.SUCCESS && data === 1) {
              this.invoiceReportService.reloadChargeGrid(true);
            }
          },
          error: err => this.sweetAlertHelper.captureException(err)
        })
    );
  }

  toggleReverse($event): void {
    $event ? this.getCharges(this.batchId, 0) : this.getCharges(this.batchId);
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
    this.busy.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  get cantAddCharge(): boolean {
    return !this.batchId;
  }
}
