import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-batch-invoice-status',
  templateUrl: './batch-invoice-status.component.html',
  styleUrls: ['./batch-invoice-status.component.scss']
})
export class BatchInvoiceStatusComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
