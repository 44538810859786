import {Component, OnInit} from '@angular/core';
import {TsoService} from '../../../services/services.index';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-map-tso',
  templateUrl: './map-tso.component.html',
  styleUrls: ['./map-tso.component.scss']
})

export class MapTsoComponent implements OnInit {

  link_map = '';

  constructor(public _serviceTso: TsoService, private _serviceDomSanitization: DomSanitizer) {

  }

  ngOnInit() {
    this.link_map = 'http://publictransportation.tsomobile.com/webtracker/webtracker.htm?labels=false&tkn=' + this._serviceTso.getTokenLocal() + '&lan=en';
  }

  getURL() {
    return this._serviceDomSanitization.bypassSecurityTrustResourceUrl(this.link_map);
  }
}
