import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

export interface IData {
  start_date: string;
  end_date: string;
  client_id: number;
  routes_id: any[];
}

@Injectable({
  providedIn: 'root'
})
export class RemplacementLogService {

  constructor(private http: HttpClient) { }

  getData(start_date: string, end_date: string, client_id: number, route_id: number) {
    return this.http.get(`${environment.url_api_v}driverManifest/replacementLog/${start_date}/${end_date}/${client_id}/${route_id}`);
  }
}
