import { BsModalModule } from 'ng2-bs3-modal';
import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { HorizontalMenuComponent } from './components/horizontal-menu/horizontal-menu.component';
import { VerticalMenuComponent } from './components/vertical-menu/vertical-menu.component';
import { PagesSmartComponent } from './components/pages-smart/pages-smart.component';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';
import {RouterModule} from '@angular/router';
import { FooterComponent } from './components/footer/footer.component';
import { T2cardComponent } from './components/t2card/t2card.component';
import { ProfileComponent } from '../shared/layout/components/profile/profile.component';
import { ChangePasswordComponent } from '../shared/layout/components/change-password/change-password.component';
import { ChangePictureComponent } from '../shared/layout/components/change-picture/change-picture.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgBusyModule } from 'ng-busy';
import { DataService } from '../app.data.service';
import { T2CollapseComponent } from './components/t2collapse/t2collapse.component';
import { ResizableDirective } from './directives/resizable/resizable.directive';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CollapseModule } from 'ngx-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ContactSupportComponent } from './components/contact-support/contact-support.component';
import { VerifyPasswordModule } from '../shared/layout/components/verify-password/verify-password.module';
import { ComponentsModule } from '../components/components.module';

@NgModule({
  declarations: [
    SidenavComponent,
    HorizontalMenuComponent,
    VerticalMenuComponent,
    PagesSmartComponent,
    NotFoundPageComponent,
    FooterComponent,
    T2cardComponent,
    ProfileComponent,
    ChangePasswordComponent,
    ChangePictureComponent,
    T2CollapseComponent,
    ResizableDirective,
    ContactSupportComponent
  ],
  exports: [
    T2cardComponent,
    T2CollapseComponent,
    ResizableDirective
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgBusyModule,
    BsModalModule,
    CommonModule,
    RouterModule,
    CollapseModule,
    NgxPermissionsModule.forChild(),
    ImageCropperModule,
    VerifyPasswordModule,
    ComponentsModule
  ],
  providers: [
    DataService
  ]
})
export class ThemeModule {
}
