import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InvoicesRoutingModule } from './invoices-routing.module';
import { InvoicesBatchComponent } from './invoices-batch/invoices-batch.component';
import { SharedModule } from '../../../shared/shared.module';
import { ThemeModule } from '../../../theme/theme.module';
import { AgGridModule } from 'ag-grid-angular/main';
import { NgBusyModule } from 'ng-busy';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap';
import { MyDatePickerModule } from 'mydatepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsModalModule } from 'ng2-bs3-modal';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { InvoicesGridComponent } from './invoices-grid/invoices-grid.component';


@NgModule({
  imports: [
    SharedModule,
    ThemeModule,
    CommonModule,
    InvoicesRoutingModule,
    AgGridModule.withComponents([]),
    NgBusyModule,
    FormsModule,
    ReactiveFormsModule,
    TabsModule.forRoot(),
    MyDatePickerModule,
    BsDatepickerModule.forRoot(),
    BsModalModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  declarations: [InvoicesBatchComponent, InvoicesGridComponent]
})
export class InvoicesModule { }
