import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CONSTANTS } from '../../app.constants';

@Injectable({
  providedIn: 'root'
})
export class BillingCategoriesService {
  resource: string;

  constructor(private http: HttpClient) {
    this.resource = environment.url_api_v + CONSTANTS.CATEGORIES_BILLING;
  }

  /**
   * @description Return all billing categories
   * @returns {Observable<any>}
   */
  getBillingCategories(): Observable<any[]> {
    return this.http.get<any[]>(this.resource);
  }

  /**
   * @description Get a category
   * @param {number} id
   * @returns {Observable<any>}
   */
  getBillingCategory(id: number): Observable<any> {
    return this.http.get(this.resource + '/' + id);
  }

  /**
   * @description Save a billing category
   * @param data
   * @returns {Observable<Response>}
   */
  storeBillingCategory(data: any): Observable<Response> {
    return this.http.post<Response>(this.resource, data);
  }

  /**
   * @description Update a billing category
   * @param data
   * @returns {Observable<Response>}
   */
  updateBillingCategory(data: any, id: number): Observable<Response> {
    return this.http.put<Response>(this.resource + '/' + id, data);
  }

}
