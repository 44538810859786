import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription} from 'rxjs';
import { ClientService } from '../../../services/fleet/client.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InvoiceService } from '../../../services/billing/invoice.service';
import { BatchesService } from '../../../services/billing/batches.service';
import { TabsetComponent } from 'ngx-bootstrap';
import { ClientsId } from '../unprocessed-runs/unprocessed-runs.interface';
import { DriverManifestService } from '../../../services/manifest/driver-manifest.service';
import { generateMonth, generateWeek, GetMonths } from '../../../helpers/moment-helpers';
import { WorkflowService } from '../../../services/billing/workflow.service';

@Component({
  selector: 'app-unprocessed',
  templateUrl: './unprocessed.component.html',
  styleUrls: ['./unprocessed.component.scss']
})
export class UnprocessedBillingComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('staticTabs') staticTabs: TabsetComponent;
  count_row_total = 0;
  showVehicleFueled: boolean;
  showMissedServiceEvents: boolean;

  busy: Subscription;
  workFlowSubscriptions: Subscription[] = [];

  gridClear: any;
  clients: any = [];
  routes: any = [];
  route_id: any = 0;
  form: FormGroup;
  count: any = {};
  activeTab = 8;
  isSummary= true;
  arrayWeek: any[];
  months = GetMonths();

  private thisComponentIsOpenedInWorkFlow = false;

  constructor(
    private clientService: ClientService,
    private fb: FormBuilder,
    private driverManifestService: DriverManifestService,
    private invoiceService: InvoiceService,
    private batchesService: BatchesService,
    private workFlowService: WorkflowService
  ) {
    this.initForm();
  }

  ngOnInit() {
    this.loadWorkFlowSubscriptions();
    this.loadUnprocessedSummary();
    this.loadData();
  }

  ngAfterViewInit() { }

  ngOnDestroy(): void {
    this.workFlowSubscriptions.forEach((workFlowSubscription: Subscription) => workFlowSubscription.unsubscribe());
    return this.busy ? this.busy.unsubscribe() : null;
  }

  initForm() {
    this.form = this.fb.group({
      date: undefined,
      client_id: [undefined, [Validators.required]],
      route_id: [undefined, [Validators.required]],
    });
  }

  loadWorkFlowSubscriptions(): void {
    this.workFlowSubscriptions.push(
      this.workFlowService.unprocessedModuleIsOpenOnWorkFlow$.subscribe({
          next: (isOpen: boolean): void => {
            if (isOpen) {
              this.activeTab = 2;
            }
            this.thisComponentIsOpenedInWorkFlow = isOpen;
          }
        })
    );

    this.workFlowSubscriptions.push(
      this.workFlowService.loadDataToPatchOnUnprocessedTabAction$.subscribe({
        next: (data): void => {
          this.form.patchValue(this.formatLostStartDateAndEndDate(data));
          this.onSearch();
        }
      })
    );

    this.workFlowSubscriptions.push(
      this.workFlowService.clientIdWorkflowBarFormChangingSubject$.subscribe({
        next: clientId => {
          if (clientId) {
            this.form.get('client_id').setValue(clientId);
            this.onChangeClient(clientId);
          }
        }
      })
    );
  }

  loadData() {
    this.busy = this.clientService.getClientsByDriverManifest().subscribe((resp: any) => {
      this.clients = resp.data;
    });
  }

  private formatLostStartDateAndEndDate(dataToPatch) {
    const { date, date_star, date_end } = dataToPatch;
    const { start_date: realStartDate, end_date: realEndDate } = date;

    if (!realStartDate || !realEndDate) {
      const dateToReplace = {...date, start_date: date_star, end_date: date_end};
      return {...dataToPatch, date: {...dateToReplace}};
    }

    return dataToPatch;
  }

  getDateWithWeekOrMonth(dateRange: any[], week: number, type: String){
    const rangeDayWeekMonth = (type == '3' ? generateWeek(dateRange[0], dateRange[1]) : generateMonth(dateRange[0], dateRange[1]));
    if(dateRange[0] < 2022 && type === '3' ){
      return {
        'end_date': rangeDayWeekMonth.dateEnd,
        'start_date': rangeDayWeekMonth.dateStart,
        'year': dateRange[0],
        'month': '',
        "weeks": week ? week : '',
        "type": 1
      }
    }
    return {
      'end_date': rangeDayWeekMonth.dateEnd,
      'start_date': rangeDayWeekMonth.dateStart,
      'year': dateRange[0],
      'month': type == '3' ? '' : rangeDayWeekMonth.mount,
      "weeks": week ? week : '',
      "type": type
    }
  }

  loadUnprocessedSummary(){
    this.batchesService.send_data_unprocessed$.subscribe(resp => {
      if(Object.keys(resp).length = 5){
        let dateSummary: any = { };
        let route_summary: any = [];
        let route = resp.route.split('(');
        let dateSplit = resp.date.split('-');
        let week = dateSplit[0].split(" ");
        if(week.length == 3){
          dateSummary = this.getDateWithWeekOrMonth([dateSplit[1], week[1]],week[1], '3');
        }else {
          dateSummary = this.getDateWithWeekOrMonth(dateSplit,0, '2');
        }
        this.batchesService.changeFormDateHeaderFilter$.next(dateSummary);
        const client = this.clients.filter(client => client.client_name == resp.client_name);
        this.clientService.getRoutesByClient(client[0].id, 1).subscribe((routeClient: any) => {
          this.routes = routeClient.data.map(r => ({...r, description_route: String(r.desc_billing ? r.desc_billing : r.description).toUpperCase() }));
          route_summary = routeClient.data.filter(r => r.description.trim() === route[0].trim());

          this.form.setValue({
            date: dateSummary,
            client_id: client[0].id,
            route_id: route_summary[0].id
          });

          const info = {
            start_date: dateSummary.start_date,
            end_date: dateSummary.end_date,
            client_id: client[0].id,
            routes_id: route_summary[0].id,
            route_group_id: [route_summary[0].route_group_id]
          }
          this.invoiceService.load_unprocessed_block$.next(info);
          this.staticTabs.tabs[1].active = true;
        });
      }
    });
  }

  onChangeClient(client_id: number) {
    this.busy = this.clientService.getRoutesByClient(client_id, 1).subscribe((resp: any) => {
      this.routes = resp.data.map(r => ({...r, description_route: String(r.desc_billing ? r.desc_billing : r.description).toUpperCase() }));
      this.showVehicleFueled = this.clients.filter(c => c.id === Number(client_id)).map(e => e.buses_fueled)[0] ? true : false;
      this.showMissedServiceEvents = this.clients.filter(c => c.id === Number(client_id)).map(e => e.missed_service_events)[0] ? true : false;
      this.form.get('route_id').setValue('');
      this.gridClear = Math.random();
    });
  }

  newInterrruption() {
    const data = Object.assign({}, this.form.getRawValue());
    const dataInterruption = {
      dataForm: {
        start_date: data.date.start_date,
        end_date: data.date.end_date,
        client_id: data.client_id,
        routes_id: data.route_id
      },
    };
    this.invoiceService.new_interruptions$.next(dataInterruption);
  }

  onSearch(): void {
    const data = Object.assign({}, this.form.getRawValue());
    if (data.client_id && data.date && data.route_id) {
      this.driverManifestService.resetFormManifest$.next(true);
      let route_group_id = 0;
      let routes_groups_id: any = [];
      if (data.route_id) {
        route_group_id = this.routes.filter((item: any) => item.id === Number(data.route_id)).map(item => Number(item.route_group_id));
        routes_groups_id = this.routes.map(item => Number(item.route_group_id)).filter((item: any, i, ar) => ar.indexOf(item) === i);
      }
      const info = {
        start_date: data.date.start_date,
        end_date: data.date.end_date,
        client_id: data.client_id,
        block_like_run: data.client_id ? this.getBlockLikeRun(data.client_id).block_like_run : null,
        routes_id: data.route_id,
        routes: this.routes.map((item) => item.id),
        route_group_id: route_group_id,
        routes_groups_id: routes_groups_id,
      };

      switch (this.activeTab) {
        case 0:
          this.invoiceService.load_unprocessed_block$.next(info);
          break;
        case 1:
          this.invoiceService.load_interruptions$.next(info);
          break;
        case 2:
          this.invoiceService.load_invoices$.next(info);
          break;
        case 3:
          this.invoiceService.load_buses$.next(info);
          break;
        case 4:
          this.invoiceService.load_remplacement_log$.next(info);
          break;
        case 5:
          this.invoiceService.load_buses_fueled$.next(info);
          break;
        case 6:
          this.invoiceService.load_liquidated_damage$.next(info);
          break;
        case 7:
          this.invoiceService.load_missed_service_events$.next(info);
          break;
        case 8:
          this.invoiceService.load_summary_billing$.next();
      }
    }
  }

  saveLayout() {
    switch (this.activeTab) {
      case 1:
        this.invoiceService.save_layout_runs$.next();
        break;
      case 2:
        this.invoiceService.save_layou_invoices$.next();
        break;
    }
  }

  changeTab(tab) {
    this.activeTab = tab;
    if (Number(tab) === 8) {
      this.isSummary = true;
    }else if (Number(tab) === 3) {
      this.isSummary = false;
      this.form.get('route_id').clearValidators();
      this.form.get('route_id').setValidators([]);
      this.form.get('route_id').updateValueAndValidity();
    } else {
      this.isSummary = false;
      this.form.get('route_id').setValidators([Validators.required]);
      this.form.get('route_id').updateValueAndValidity();
    }
    this.onSearch();
  }

  changeTabRuns(event: any) {
    this.staticTabs.tabs[1].active = true;
    this.activeTab = 2;
    this.onSearch();
    this.invoiceService.load_runs_of_unprocessed_block = event;
  }

  verified() {
    this.invoiceService.verified_blocks_runs$.next(true);
  }

  getBlockLikeRun(id: number) {
    return this.clients.filter((item: any) => item.id == id).shift();
  }

  returnBlockLikeRun() {
    const data = Object.assign({}, this.form.getRawValue());
    return data.client_id ? this.getBlockLikeRun(data.client_id).block_like_run : null;
  }

  get isMiamiBeach(): boolean {
    return Number(this.form.get('client_id').value) === ClientsId.MiamiBeach;
  }

  get isMdt(): boolean {
    return Number(this.form.get('client_id').value) === ClientsId.Mdt;
  }

  get isDoral(): boolean {
    return Number(this.form.get('client_id').value) === ClientsId.Doral;
  }

  get isHomestead(): boolean {
    return Number(this.form.get('client_id').value) === ClientsId.Homestead;
  }

  get unprocessedIsInWorkFlow(): boolean {
    return this.thisComponentIsOpenedInWorkFlow;
  }

  get unprocessedIsInBilling(): boolean {
    return !this.thisComponentIsOpenedInWorkFlow;
  }
}
