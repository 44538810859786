import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ClientService } from '../../../../../../services/fleet/client.service';
import { CellClickedEvent, GridOptions, GridReadyEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { Subject, Subscription } from 'rxjs-compat';
import { takeUntil } from 'rxjs/operators';
import AgGridHelpers from '../../../../../../helpers/ag-grid-helpers';
import { BsModalComponent } from 'ng2-bs3-modal';
import { SweetAlertHelper } from '../../../../../../CORE/helpers/sweet-alert-helper.service';
import { IFeeSchedule, IRouteGroup } from '../fee-schedule.interface';
import { RouteGroupService } from '../../../../../../services/fleet/route-group.service';
import { FeeScheduleType } from '../../../../../../CORE/enums/FeeScheduleEnum.enum';

@Component({
  selector: 'app-grid-fee-schedule',
  templateUrl: './grid-fee-schedule.component.html',
  styleUrls: ['./grid-fee-schedule.component.scss']
})
export class GridFeeScheduleComponent implements OnInit, OnDestroy {
  @ViewChild('modalAddRouteGroup') modalAddRouteGroup: BsModalComponent;
  @Input() clientId?: number;
  @Input() modalInvoice?: boolean;
  private destroy: Subject<boolean> = new Subject();
  busy: Subscription[] = [];

  gridFeeSchedule: GridOptions;
  gridData: IRouteGroup[] = [];
  scheduleId: number;
  fee_schedule_type: number;

  constructor(
    private clientService: ClientService,
    private sweetAlertHelper: SweetAlertHelper,
    private routeGroupService: RouteGroupService
  ) {
    this.createGrid();
  }

  ngOnInit() {
    this.fee_schedule_type = 0;
    this.subscribeObservables();
  }

  subscribeObservables() {
    this.clientService.editFeeSchedule$.pipe(takeUntil(this.destroy)).subscribe((resp: IFeeSchedule) => {
      if (resp) {
        this.scheduleId = resp.id;
        this.getRoutesGroupByFeeSchedule();
      }
    });

    this.clientService.reloadEditFeeScheduleGrid$.pipe(takeUntil(this.destroy)).subscribe((resp: boolean) => {
      if (resp) {
        this.getRoutesGroupByFeeSchedule();
        this.modalAddRouteGroup.close().then();
      }
    });
  }

  getRoutesGroupByFeeSchedule() {
    this.busy.push(
      this.routeGroupService.getRoutesGroupByFeeSchedule(this.scheduleId).subscribe(
        (resp: any) => {
          if (resp && resp.data && resp.data && resp.data.length) {
            const [first] = resp.data;
            const {fee_schedule_type, route_group } = first;
            this.fee_schedule_type = fee_schedule_type;
            this.gridData = route_group;
          }
        },
        error => {
          this.sweetAlertHelper.captureException(error).then();
        }
      )
    );
  }

  createGrid() {
    this.gridFeeSchedule = <GridOptions> {
      suppressAggFuncInHeader: true,
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      sortable: true,
      resizable: true,
      animateRows: true,
      groupDisplayType: 'multipleColumns',
      rowSelection: 'single',
      enableFilter: true,
      columnDefs: [
        { headerName: 'Route Group', field: 'description', width: 150 },
        { headerName: 'Rate', field: 'pivot.rate', width: 80, cellStyle: {textAlign: 'right'},
          cellRenderer: (params) => AgGridHelpers.CurrencyCellRenderer(params) },
        { headerName: 'Po Number', field: 'pivot.po_number', width: 80 },
        {
          headerName: 'Active',
          field: 'pivot.active',
          width: 100,
          cellRenderer: params => AgGridHelpers.statusRecord(params.data.pivot.active),
        },
        {
          colId: 'deleteRow',
          cellRenderer: () => {
            return '<span <i class="fa fa-trash-o" style="color: red; cursor: pointer;" aria-hidden="true"></i></span>';
          },
          width: 50
        }
      ],
      onCellDoubleClicked: (event: RowDoubleClickedEvent) => {
        if (event && event.data) {
          if (Number(this.fee_schedule_type) === FeeScheduleType.DEFAULT) {
            this.sweetAlertHelper.createCustomAlert({
              title: 'Warning!',
              text: 'You must change the calendar type to routes.',
              type: 'warning',
            }).then();
          } else {
            this.clientService.editRouteGroup(event.data);
            this.modalAddRouteGroup.open().then();
          }
        }
      },
      onCellClicked: (event: CellClickedEvent) => {
        if (event) {
          if (event.colDef.colId === 'deleteRow') {
            this.deleteGroup(event.data);
          }
        }
      },
      onGridReady(event: GridReadyEvent) {
        if (event) {
          this.gridData = [];
        }
      }
    };
  }

  deleteGroup(group: IRouteGroup) {
    if (Number(this.fee_schedule_type) === FeeScheduleType.DEFAULT) {
      this.sweetAlertHelper.createCustomAlert({
        title: 'Warning!',
        text: 'You must change the calendar type to routes.',
        type: 'warning',
      }).then();
    } else {
      this.sweetAlertHelper.createCustomAlert({
        title: 'Are you sure you want to delete this route group?',
        text: 'You will not be able to recover this route group!',
        type: 'warning',
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.busy.push(
            this.routeGroupService.routeGroupFeeScheduleDelete(group.pivot.fee_schedule_id, group.pivot.route_group_id).subscribe(
              (resp) => {
                if (resp) {
                  this.sweetAlertHelper.createCustomAlert({
                    title: 'Deleted!',
                    text: 'Route group has been deleted.',
                    type: 'success',
                  }).then(() => {
                    this.getRoutesGroupByFeeSchedule();
                  });
                }
              },
              error => {
                this.sweetAlertHelper.captureException(error).then();
              }
            )
          );
        }
      });
    }
  }
  get addFeeScheduleRouteGroup(): boolean {
    return Number(this.fee_schedule_type) === FeeScheduleType.DEFAULT;
  }

  get hasmodalInvoice(): string | null {
    return this.modalInvoice ? '2000' : '1200';
  }

  openModalAddRouteGroup() {
    this.clientService.editRouteGroup(null);
    this.modalAddRouteGroup.open().then();
  }

  closeAddModal() {
    this.modalAddRouteGroup.close().then();
  }

  ngOnDestroy(): void {
    this.busy.forEach(subscription => subscription.unsubscribe());
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }
}
