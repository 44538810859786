import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { InspectionsService } from '../../../../../services/fleet/fleet.index';
import { AdminDataService } from '../../../fleet.data.service';

@Component({
    selector: 'app-inspections',
    templateUrl: './inspections.component.html',
    styleUrls: ['./inspections.component.scss']
})
export class InspectionsComponent implements OnInit {
    public busy: Subscription;
    public gridInspections: GridOptions;
    public gridDetails: GridOptions;

    @Output() cancel: EventEmitter<any> = new EventEmitter();
    vehivle: any;
    filter: any = {};

    constructor(private  _dataService: AdminDataService,
                private _serviceInspections: InspectionsService) { }

    ngOnInit() {
        this.loadVehicles();
        this.confiGridInspections()
        this.confiGridDetails();
    }

    onCancel() {
        this.cancel.emit(true);
    }


    loadVehicles() {
        this.busy = this._dataService.getData('vehicles').subscribe(
            data => {
                this.vehivle = data;
            }
        );
    }

    search() {
      this.busy = this._serviceInspections.filterInspection(this.filter).subscribe(
        (data: any) => {
          this.gridInspections.api.setRowData(data);
        }
      );
        // if ( !(this.filter.start_date) && !(this.filter.end_date) && !(this.filter.vehicle )) {
        //     alert('Select the required fields for the filter');
        // }else {
        //    if ( this.filter.start_date && this.filter.end_date && this.filter.vehicle ) {
        //        alert('Filtro con los tres campos');
        //    }else if ( this.filter.start_date && this.filter.end_date && !(this.filter.vehicle)) {
        //        if ( this.filter.start_date >= this.filter.end_date ){
        //            alert('verify the dates please');
        //        }else {
        //            alert('Filtro con Fecha');
        //        }
        //    }else if ( !(this.filter.start_date) && !(this.filter.end_date) && this.filter.vehicle) {
        //        alert('Filtro de Solo Vehiculos');
        //    }else {
        //        alert('wrong combination');
        //    }
        // }
    }


    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        let x = event.keyCode;
        if (x === 27) {
            this.onCancel();
        }
    }

    confiGridInspections() {
        this.gridInspections = <GridOptions>{
            enableSorting: true,
            sortingOrder: ['desc', 'asc', null]
        };

        this.gridInspections.columnDefs = [
            { headerName: 'Date', field: 'dateOfInspection', width: 80 },
            { headerName: 'Inspection', field: 'satisfactory', width: 80,
              cellRenderer: function (params) {
                let img = '';
                if (params.data.InspectionStatus) {
                  img = `<span><i class="fa fa-check-circle-o" style="color: green;" aria-hidden="true"></i></span>`;
                } else {
                  img = `<span><i class="fa fa-times-circle-o" style="color: red;" aria-hidden="true"></i></span>`;
                }
                return img;
              }
            },
            { headerName: 'Driver', field: 'user.name', width: 150 },
            { headerName: 'Fuel', field: 'fuel', width: 70 },
            { headerName: 'Oil', field: 'oil', width: 50,
              valueGetter: this.setOil
            },
            { headerName: 'Coolant', field: 'coolant', width: 70,
              valueGetter: this.setCoolant
            },
            { headerName: '', width: 30, colId: 'printer',
              cellRenderer: (params) => {
                let img = `<span class="label label-info" title="Print" style="cursor: pointer;"><i class="fa fa-print" aria-hidden="true"></i></span>`;
                return img;
              }
            }
        ];
    }

  setCoolant(row) {
      if (row.data.coolant) {
        return 'FULL';
      } else {
        return 'ADD';
      }
  }

  setOil(row) {
    if (row.data.oil) {
      return 'FULL';
    } else {
      return 'ADD';
    }
  }
    confiGridDetails() {
        this.gridDetails = <GridOptions>{
            enableSorting: true,
            sortingOrder: ['desc', 'asc', null]
        };

        this.gridDetails.columnDefs = [
            { headerName: 'Description', field: 'item.description', width: 460},
            { headerName: 'Pass', field: 'pass', width: 80,
              cellRenderer: function (params) {
                let img = '';
                if (params.data.pass) {
                  img = `<span><i class="fa fa-check-circle-o" style="color: green" aria-hidden="true"></i></span>`;
                } else {
                  img = `<span><i class="fa fa-times-circle-o" style="color: red" aria-hidden="true"></i></span>`;
                }
                return img;
              }
            }
        ];
    }

  OnCellDoubleClicked($event) {
      this.gridDetails.api.setRowData($event.data.vehicleinspectionsdetails);
  }

  onPrinter($event: any) {
    if($event.colDef.colId == 'printer') {
      const data = $event.data;
      this._serviceInspections.openInspectionReport(data.id);
    }
  }
}
