import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DataService } from '../../app.data.service';

const noop = () => {};

@Component({
  selector: 'app-driver-routes',
  templateUrl: './driver-routes.component.html',
  styleUrls: ['./driver-routes.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DriverRoutesComponent),
      multi: true
    }
  ]
})
export class DriverRoutesComponent implements OnInit, ControlValueAccessor {
  public busy: Subscription;
  public bntIndividual1: Boolean = true;
  public bntIndividual2: Boolean = true;

  public routes_left: any[] = [];
  public routes_right: any[] = [];
  public routes_left_select: any[] = [];
  public routes_right_select: any[] = [];

  public routes: any = {};

  public onTouchedCallback: () => void = noop;
  public onChangeCallback: (_: any) => void = noop;
  public route_select_id: Array<any> = [];
  weekSelected = false;

  @Input()
  citieSekected: number;

  @Input()
  notViewTitle: boolean;

  @Input() showInactive? = false;

  constructor(private _dataService: DataService) {}

  ngOnInit() {
    this.loadRoutes();
    this.clearList();
  }

  selectedItem(value: any, col: number) {
    if ((value = !0)) {
      if (col == 1) {
        this.bntIndividual1 = false;
        this.bntIndividual2 = true;
      } else {
        this.bntIndividual2 = false;
        this.bntIndividual1 = true;
      }
    }
  }

  moveItem(item, from, to) {
    if (item == undefined) {
    } else {
      for (let x = 0; x <= item.length - 1; x++) {
        const idx = this.getElementoArray(item[x], from);
        if (idx.length > 0) {
          this.deleteElementArray(item[x], from);
          to.push(idx[0]);
        }
      }
    }
    this.bntIndividual1 = true;
    this.bntIndividual2 = true;
    this.onChangeCallback(this.routes_right);
  }

  moveAll(from, to) {
    if (from != null) {
      for (const entry of from) {
        to.push(entry);
      }
      from.length = 0;
    }
    this.onChangeCallback(this.routes_right);
  }

  deleteElementArray(id: any, list: Array<any>) {
    list.forEach(function(data, index) {
      if (data.id == id) {
        list.splice(index, 1);
      }
    });
  }

  getElementoArray(id: any, list: Array<any>) {
    const x = list.filter(function(item) {
      return item.id == id;
    });
    return x;
  }

  loadRoutes() {
    this._dataService.getData(`routes/headers/all/${this.showInactive}`).subscribe((data: any) => {
      this.routes = data;
      this.routes_left = data;
      this.clearList();
    });
  }

  // From ControlValueAccessor interface Este metodo es el que escribe en el objeto de este lado
  writeValue(value: any) {
    if (value !== undefined && value !== null) {
      if (value.length > 0) {
        for (const obj of value) {
          this.routes_right.push(obj.route);
        }
        this.clearList();
      }
    }
  }

  // From ControlValueAccessor interface, Este man es el que se envia datos al mundo exterior.
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  /**
   * @description Este metodo limpia el json es requerido para ser llamado en el hijo.
   * @param routes_right
   * @returns {any}
   * @constructor
   */
  JSONClear(routes_right: any) {
    for (let obj of routes_right) {
      delete obj.client_id;
      delete obj.route_code;
      delete obj.description;
      delete obj.active;
      delete obj.client;
      delete obj.route_blocks;
    }
    return routes_right;
  }

  /**
   * @description este metodo elimina del array recorre el array de elementos de la derecha y con base en estos elimina
   * las coinsidencias del array de la derecha.
   */
  clearList() {
    if (this.routes_right.length > 0) {
      const right = this.routes_right;
      for (let obj of right) {
        this.deleteLef(obj.id);
      }
    }
  }

  /**
   * @description este metoso se encarca de eliminar un elemento del array de la derecha con base en el ID que se
   * pasa por parametro.
   * @param id
   */
  deleteLef(id: any) {
    let list: Array<any>;
    list = this.routes_left;
    list.forEach(function(data, index) {
      if (data.id == id) {
        list.splice(index, 1);
      }
    });
    this.routes_left = list;
  }
}
