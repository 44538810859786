/**
 * Created Septiembre 2017
 * @author Miguel Lopez Ariza
 * @description Componente encargado de gestiona el modal con la informacion de Cities, para la edicion
 * y actualizacion de la misma, cuenta metodos para consumir los servicios respectivos.
 */

import { Component, OnInit, Input, Output, EventEmitter, HostListener, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AdminDataService } from '../../../fleet.data.service';
import { Client } from '../../client.model';
import { ClientService } from '../../../../../services/services.index';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { Mask } from './../../../../../tools/mask';

@Component({
  selector: 'app-create-client',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})

export class CreateClientComponent implements OnInit, OnDestroy {

  busy: Subscription;
  mask = new Mask();

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  @Input() citieSekected: number;
  @Input() companies: Array<any> = [];
  @Input() client: any;

  @Output() onChangeCompany?: EventEmitter<any> = new EventEmitter<any>();

  // public data: any = {};
  public titleModal = '';
  public apiResponse: any;
  public selectUndefinedOptionValue: any;
  formData: FormGroup;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private _dataService: AdminDataService,
    private _serviceClient: ClientService,
  ) {
    this.loadForm();
    this.formData.get('company_id').valueChanges.subscribe(val => {
      if (val) {
        this.onChangeCompany.emit(val);
      }
    });
  }

  ngOnInit() {
    if (this.client) {
      this.formData.patchValue({
        id: this.client.id,
        active: this.client.active,
        company_id: this.client.company_id,
        client_name: this.client.client_name,
        contact: this.client.contact,
        position: this.client.position,
        payrate: this.client.payrate,
        address_one: this.client.address_one,
        address_two: this.client.address_two,
        city: this.client.city,
        state: this.client.state,
        zip: this.client.zip,
        mobile_clock: this.client.mobile_clock,
        url: this.client.url,
        timezone: this.client.timezone,
        phone: this.client.phone,
        lang: this.client.lang,
        address_one_billing: this.client.address_one_billing,
        address_two_billing: this.client.address_two_billing,
        city_billing: this.client.city_billing,
        state_billing: this.client.state_billing,
        zip_billing: this.client.zip_billing,
        desc_billing: this.client.desc_billing,
        block_like_run: this.client.block_like_run,
        billing_departament: this.client.billing_departament,
        long_name: this.client.long_name,
        fein: this.client.fein,
        contract: this.client.contract,
        type_range_date: this.client.type_range_date,
        authority: this.client.authority,
        callback_name: this.client.callback_name,
        callback_phone: this.client.callback_phone,
        avl_playback: this.client.avl_playback,
        avl_reports: this.client.avl_reports,
        insurance_rule: this.client.insurance_rule
      });
      this.titleModal = 'Edit Client - ' + this.client.client_name;
      this.onChangeCompany.emit(this.client.company_id);
    } else {
      this.titleModal = 'New Client';
    }
  }

  ngOnDestroy() {
    return this.busy ? this.busy.unsubscribe() : null;
  }

  loadForm() {
    this.formData = this.fb.group({
      id: [null],
      active: [true],
      company_id: [null, Validators.required],
      client_name: [null, Validators.required],
      contact: [null],
      position: [null],
      payrate: [0],
      address_one: [null],
      address_two: [null],
      city: [null],
      state: [null],
      zip: [null],
      mobile_clock: [false],
      url: [null],
      timezone: [null],
      phone: [null],
      lang: [null],
      address_one_billing: [null],
      address_two_billing: [null],
      city_billing: [null],
      state_billing: [null],
      zip_billing: [null],
      desc_billing: [null],
      block_like_run: [null],
      billing_departament: [null],
      long_name: [null],
      fein: [null],
      contract: [null],
      type_range_date: [null],
      authority: [null],
      callback_name: [null],
      callback_phone: [null],
      avl_playback: [null],
      avl_reports: [null],
      insurance_rule: [false],
    });
  }

  onCancel() {
    this.cancel.emit(true);
  }

  /**
   * @method Este metodo se encarga de Guardar o Actualizar la informacion de una Citie,
   * la variable citiSekected es la encargada de terminar que accion se realizara, si es
   * igual a 0, entonces consumimos el servicio para Guardar, de lo contrario es una actualizacion
   * y consumimos el servicio pertinente
   */
  onSave(validForm) {
    if (validForm) {
      if (!this.formData.get('id').value) {
        this.save();
      } else {
        this.update();
      }
    } else {
      this.toastr.warning('Please complete the required fields');
    }
  }

  /***
   * @description
   * @param event
   * @private
   */
  _keyPress(event: any) {
    const pattern = /[A-Za-z\s]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onChangeDescription(event: any) {
    this.formData.controls['desc_billing'].setValue(event.target.value);
  }

  /***
   * @description
   * @param {KeyboardEvent} event
   */
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const x = event.keyCode;
    if (x === 27) {
      this.onCancel();
    }
  }

  /***
   * @description
   */
  update() {
    const data = Object.assign(this.formData.getRawValue());
    delete data.id;
    delete data.company;
    this.busy = this._serviceClient.updateClient(data, this.citieSekected).subscribe(
      (response: any) => {
        if (response.status === 'success') {
          this.loadForm();
          Swal.fire(
            'Success!',
            'Records updated correctly.',
            'success'
          ).then(() => {
            this.onCancel();
            this.notify.emit();
          });
        } else {
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: JSON.stringify(response)
          });
        }
      }
    );
  }

  /***
   * @description
   */
  save() {
    const data = Object.assign(this.formData.getRawValue());
    this.busy = this._serviceClient.storeClient(data).subscribe(
      (response: any) => {
        if (response.status === 'success') {
          this.loadForm();
          Swal.fire(
            'Success!',
            'Records updated correctly.',
            'success'
          ).then(() => {
            this.onCancel();
            this.notify.emit();
          });
        } else {
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: JSON.stringify(response)
          });
        }
      }
    );
  }

}
