import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { FieldIsRequired } from '../../../../../CORE/helpers/form-group.helper';
import { CompanyService, DriverService, RoleService, UserService, UserTypeService } from '../../../../../services/services.index';
import { ICompany } from '../../../../../CORE/interfaces/company.interface';
import { UserTypeEnum } from '../../../../../CORE/enums/UserTypeEnum.enum';
import { DriverRole } from '../../../../../CORE/enums/DriversEnum.enum';
import { SweetAlertHelper } from '../../../../../CORE/helpers/sweet-alert-helper.service';
import { ICompanies } from '../../../../../CORE/interfaces/user.interface';

@Component({
  selector: 'app-user-driver',
  styleUrls: ['./user-driver.component.scss'],
  templateUrl: './user-driver.component.html'
})
export class UserDriverComponent implements OnInit, OnDestroy {

  @Input() driverSekected: number;

  busy: Subscription[] = [];
  gridCompanies: GridOptions;
  roles: any[];
  showPassword = true;
  companies: Array<any>;
  mask_phone = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  formData: FormGroup;
  companiesList: ICompany[] = [];
  msgAlertDriverNotCreateAsUser = '';

  dropdownCompaniesSettings = {
    singleSelection: false,
    disabled: true,
    text: 'Save to assign company',
    selectAllText: 'Select One',
    unSelectAllText: 'UnSelect One',
    enableSearchFilter: true,
    classes: 'myclass companies-multiselect',
  };

  constructor(
    private fb: FormBuilder,
    private _serviceDriver: DriverService,
    private _serviceCompany: CompanyService,
    private _serviceRole: RoleService,
    private _serviceUser: UserService,
    private serviceTypeUser: UserTypeService,
    private sweetAlertHelper: SweetAlertHelper
  ) {}

  ngOnInit() {
    this.loadForm();
    this.loadInfo();
    this.msgAlertDriverNotCreateAsUser = '';
  }

  loadForm() {
    this.formData = this.fb.group({
      id: [''],
      name: ['', Validators.required],
      email: ['', Validators.required],
      phone: [''],
      pin: ['', Validators.required],
      password: [''],
      userType: undefined,
      roles: [],
      companies: [],
      client: [[]],
      status: true,
    });
  }

  loadCompanies() {
    this.busy.push(this._serviceCompany.getCompanyStatus(false).subscribe(resp => {
          if (resp && resp.data && resp.data.length) {
            this.companiesList = resp.data.map(item => {
              return { id:  Number(item.id), itemName: item.description };
            });
              this.dropdownCompaniesSettings = {
                classes: 'myclass companies-multiselect',
                disabled: true,
                enableSearchFilter: true,
                selectAllText: 'Select One',
                singleSelection: false,
                text: 'Save to assign company',
                unSelectAllText: 'UnSelect One'
              };
            }
        },
        error => {
          this.sweetAlertHelper.captureException(error).then();
        }
      ));
  }


  fieldIsRequired(fieldKey: string) {
    return FieldIsRequired(this.formData, fieldKey);
  }

  loadInfo() {
    this.busy.push(this._serviceDriver.getUser(this.driverSekected).subscribe((data: any) => {
        if (data && data.employee_id) {
          this.formData.patchValue({
            name: data.last_name + ' ' + data.first_name,
            email: data.email && data.email !== '' ? data.email : data.employee_id + '@taschedule.com',
            password: data.employee_id,
            phone: data.phone,
            pin: data.employee_id
          });
          this.msgAlertDriverNotCreateAsUser = 'Important: This driver is not created as a user.';
        } else {
          this.formData.patchValue({
            email: data.email,
            name: data.name,
            phone: data.phone,
            pin: data.pin,
            id: data.id,
            companies:
              data && data.companies
                ? data.companies.map((company: ICompanies) => {
                  if (company) {
                    return {
                      id: Number(company.company_id),
                      itemName: company.company.description
                    };
                  }
                })
                : [],
            status: data.status,
          });
          this.showPassword = false;
          this.msgAlertDriverNotCreateAsUser = 'This driver is created as user';
        }
        this.loadCompanies();
        this.getAllRoles();
        this.loadUserType();
      }
    ));
  }

  /***
   * @description call the service that returns all the roles
   */
  getAllRoles(): void {
    this.busy.push(this._serviceRole.getRoles().subscribe(data => {
        this.roles = data.data;
        this.formData.get('roles').patchValue(data.filter(row => row.name === DriverRole.Driver));
      }
    ));
  }

  loadUserType() {
    this.busy.push(this.serviceTypeUser.getUserType().subscribe(resp => {
        if (resp) {
          this.formData.get('userType').patchValue(resp.find(row => row.description === UserTypeEnum.Admin).id);
        }
      },
      error => {
        this.sweetAlertHelper.captureException(error).then();
      }
    ));
  }

  onSave() {
    const data = Object.assign(this.formData.getRawValue());
    if (this.showPassword) {
      this.store(data);
    } else {
      this.update(data);
    }
  }


  /**
   * @description this method updates the records
   */
  update(data): void {
    this.busy.push(this._serviceUser.updateUserMobile(data, data.id).subscribe((res: any) => {
        if (res.status === 'success') {
          this.sweetAlertHelper
            .createCustomAlert({
              position: 'center',
              title: 'Your layout has been saved',
              type: 'success',
              timer: 1500,
              showConfirmButton: false
            })
            .then(() => this.loadInfo());
        }
      }, error => {
        this.sweetAlertHelper.captureException(error).then();
      }
    ));
  }

  /**
   * @description this method saves the records
   */
  store(data): void {
    this.busy.push(this._serviceUser.storeUser(data).subscribe((res: any) => {
      if (res.status === 'success') {
          this.sweetAlertHelper
            .createCustomAlert({
              position: 'center',
              title: 'Your layout has been saved',
              type: 'success',
              timer: 1500,
              showConfirmButton: false
            })
            .then(() => this.loadInfo());
        }
      }, error => {
        this.sweetAlertHelper.captureException(error).then();
      }
    ));
  }

  get isEdit(): boolean {
    return this.formData.get('id').value;
  }
  get invalidForm(): boolean {
    return this.formData.invalid;
  }
  get alertDriverNotCreateAsUser(): boolean {
    return this.showPassword;
  }

  get pinControl(): AbstractControl {
    return this.formData.get('pin');
  }
  get phoneControl(): AbstractControl {
    return this.formData.get('phone');
  }

  get emailControl(): AbstractControl {
    return this.formData.get('email');
  }

  get nameControl(): AbstractControl {
    return this.formData.get('name');
  }

  ngOnDestroy() {
    this.busy.forEach(subscription => subscription.unsubscribe());
  }

}
