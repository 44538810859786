import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalComponent } from 'ng2-bs3-modal';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { InterruptionService } from '../../../../../services/interruption.service';
import { AdminDataService } from '../../../fleet.data.service';
import { IClientInterruptionTemplate } from '../../../../../CORE/interfaces/client.interface';

@Component({
  selector: 'app-interruption-client',
  templateUrl: './interruption.component.html',
  styleUrls: ['./interruption.component.scss']
})

export class InterruptionComponent implements OnInit, OnDestroy {

  @ViewChild('modal') modal: BsModalComponent;
  @Input() citieSekected: number;
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  busy: Subscription[] = [];
  apiResponse: any;
  endpoint = 'interruptionclientConfigs';
  formData: FormGroup;
  template_interruptions: IClientInterruptionTemplate = null;

  constructor(
    private fb: FormBuilder,
    private _dataService: AdminDataService,
    private serviceInterruption: InterruptionService,
  ) {
    this.loadForm();
  }

  ngOnInit() {
    this.loadData();
  }

  loadForm() {
    this.formData = this.fb.group({
      id: [''],
      to: [[], Validators.required],
      cc: [[], Validators.required],
      bc: [[]],
      subject: ['', Validators.required],
      subjectResolution: ['', Validators.required],
      template_id: ['', Validators.required],
      client_id: ['', Validators.required],
    });
  }

  /***
   * @description
   */
  onSave() {
    const data = Object.assign(this.formData.getRawValue());
    data.to.length > 0 ? data.to = data.to.join(';').toString() : data.to = null;
    data.cc.length > 0 ? data.cc = data.cc.join(';').toString() : data.cc = null;
    data.bc.length > 0 ? data.bc = data.bc.join(';').toString() : data.bc = null;
    if (!this.formData.get('id').value) {
      delete data.id;
      this.save(data);
    } else {
      this.update(data);
    }
  }

  onCancel() {
    this.cancel.emit(true);
  }

  showPreview(template_id) {
    this.busy.push(
        this.serviceInterruption.getTemplateS3(template_id).subscribe(
          template => {
            if (template) {
              this.template_interruptions = template['template'];
            }
            if (this.template_interruptions != null) {
              this.modal.open();
            }
        })
    );
  }

  /***
   * @description
   */
  update(data) {
    this.busy.push(this.serviceInterruption.updateInterruptionclientConfigs(data, data.id).subscribe(
      res => {
        this.apiResponse = res;
        if (this.apiResponse.transaction) {
          Swal.fire(
            'Success!',
            this.apiResponse.message,
            'success'
          ).then(() => {
            this.onCancel();
            this.notify.emit();
          });
        } else {
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: this.apiResponse.message + '.<br> Log : ' + this.apiResponse.master.result
          });
        }
      }
    ));
  }

  /***
   * @description
   */
  save(data) {
    this.busy.push(this.serviceInterruption.storeInterruptionclientConfigs(data).subscribe(
      res => {
        this.apiResponse = res;
        if (this.apiResponse.transaction) {
          Swal.fire(
            'Success!',
            this.apiResponse.message,
            'success'
          ).then(() => {
            this.onCancel();
            this.notify.emit();
          });
        } else {
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: this.apiResponse.message + '.<br> Log : ' + this.apiResponse.master.result
          });
        }
      }
    ));
  }

  /***
   * @description
   */
  loadData() {
    this.loadForm();
    this.formData.patchValue({
      client_id: this.citieSekected,
    });
    if (this.citieSekected !== 0) {
      this.busy.push(this.serviceInterruption.getInterruptionClientConfig(this.citieSekected).subscribe(
        data => {
          if (data) {
            this.formData.patchValue({
              id: data.id,
              to: data.to.split(';'),
              cc: data.cc.split(';'),
              bc: data.bc.split(';'),
              subject: data.subject,
              subjectResolution: data.subjectResolution,
              template_id: data.template_id,
              client_id: this.citieSekected,
            });
          }
        },error2 => {
          if (error2) {
            this.formData.patchValue({
              client_id: this.citieSekected,
            });
          }
        }
      ));
    }
  }

  sendTestEmail() {
    const formData = Object.assign(this.formData.getRawValue());
    const data: any = {
      client_id: Number(formData.client_id),
      template_id: Number(formData.template_id),
    }
    Swal.fire({
      type: 'warning',
      text: 'Are you sure you want to send a test email?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonColor: '#d33',
      confirmButtonColor: '#1464F6',
      customClass: {
        confirmButton: 'btn-confirm',
        cancelButton: 'btn-cancel'
      }
    }).then((result: any) => {
      if (result.value) {
        this.serviceInterruption.postInterruptionTest(data).subscribe((resp: any) => {
          if (resp.status === 'success') {
            Swal.fire(
              'Success!',
              'Emails sent.',
              'success',
            );
          } else {
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: JSON.stringify(resp),
            });
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.busy.forEach(subscription => subscription.unsubscribe());
  }
}
