import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {WorkflowService} from '../../../../services/billing/workflow.service';
import {takeUntil} from 'rxjs/operators';
import {ClientService} from '../../../../services/fleet/client.service';
import {SweetAlertHelper} from '../../../../CORE/helpers/sweet-alert-helper.service';
import {InvoiceReportService} from './invoice-report.service';

@Component({
  selector: 'app-invoice-report',
  templateUrl: './invoice-report.component.html',
  styleUrls: ['./invoice-report.component.scss']
})
export class InvoiceReportComponent implements OnInit, OnDestroy {
  busy: Subscription[] = [];
  destroy: Subject<boolean> = new Subject<boolean>();

  clientId: number;

  constructor(
    private workflowService: WorkflowService,
    private clientService: ClientService,
    private sweetAlertHelper: SweetAlertHelper,
    private invoiceReportService: InvoiceReportService,
  ) {
  }

  ngOnInit(): void {
    this.subscribeToObservers();
    this.callServices();
  }

  private subscribeToObservers(): void {
    this.subscribeToChangeSearchWorkflowBar();
  }

  private callServices(): void {
    this.loadClientInfo();
  }

  private subscribeToChangeSearchWorkflowBar(): void {
    this.workflowService.infoByExternalFormToInvoiceReportAction$.pipe(takeUntil(this.destroy))
      .subscribe({
        next: ({clients_id}): void => this.clientId = clients_id
      });
  }

  private loadClientInfo(): void {
    this.busy.push(
      this.clientService.getClient(this.clientId).subscribe({
        next: ({data}): void => this.invoiceReportService.infoByClientSubject.next(data),
        error: err => this.sweetAlertHelper.captureException(err)
      })
    );
  }

  ngOnDestroy(): void {
    this.invoiceReportService.setBatchId(null);
    this.busy.forEach((subscription: Subscription) => subscription.unsubscribe());
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }
}
