import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SweetAlertHelper } from '../../../../../CORE/helpers/sweet-alert-helper.service';
import { ClientService } from '../../../../../services/fleet/client.service';
import { ReportsManageService } from '../../../../../services/reports-manage.service';
import { BsModalComponent } from 'ng2-bs3-modal';
import { takeUntil } from 'rxjs/operators';
import { PrintManifestService } from './print-manifest.service';
import { IRouteBlockSelect } from './print-manifest.interface';
import { SweetAlertResult } from 'sweetalert2';

@Component({
  selector: 'app-print-manifest',
  templateUrl: './print-manifest.component.html',
  styleUrls: ['./print-manifest.component.scss']
})
export class PrintManifestComponent implements OnInit, OnDestroy {
  @Input() date_schedule?: string;
  @ViewChild('modalPrintCustomPreview') modalPrintCustomPreview: BsModalComponent;
  @ViewChild('modalUploadAttach') modalUploadAttach: BsModalComponent;

  private destroy: Subject<boolean> = new Subject();
  busy: Subscription[] = [];
  formForms: FormGroup;
  clients: any[] = [];
  routes: any[] = [];
  drivers: any[] = [];
  print_block_and_drivers: any[] = [];
  routes_blocks: any[] = [];
  routes_id: any = 0;
  routes_block_id: any = 0;
  drivers_id: any = 0;
  dataPrint = null;
  uploadHeaderDescription: string;

  dropdownRouteSettings = {
    singleSelection: false,
      text: 'Select Routes',
      idField: 'id',
      textField: 'description',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      enableCheckAll: true,
      classes: 'myclass custom-class',
      itemsShowLimit: 1,
      disabled: true
  };

  dropdownRouteBlockSettings = {
    singleSelection: false,
    text: 'Select Blocks',
    idField: 'route_block_id',
    textField: 'route_block',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    classes: 'myclass custom-class',
    badgeShowLimit: 2,
  };

  dropdownDriverSettings = {
      singleSelection: false,
      text: 'Select Drivers',
      idField: 'driver_id',
      textField: 'driver_description',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableCheckAll: true,
      enableSearchFilter: true,
      classes: 'myclass custom-class',
      itemsShowLimit: 1,
      disabled: true
  };

  constructor(
    private fb: FormBuilder,
    private clientService: ClientService,
    private reportsManageService: ReportsManageService,
    private printManifestService: PrintManifestService,
    private sweetAlertHelper: SweetAlertHelper
  ) {
    this.createForm();
  }

  private stopPropagation($event: Event): void {
    $event.stopPropagation();
  }

  ngOnInit(): void {
    this.subscribeObservers();
    this.loadDataSelectedChanges();
  }

  createForm(): void {
    this.formForms = this.fb.group({
      date_schedule: [''],
      client_id: [undefined, [Validators.required]],
      routes_id: [[]],
      routes_block_id: [[]],
      drivers_id: [[]]
    });
  }

  openToUploadAttach($event, selectItem: IRouteBlockSelect): void {
    this.stopPropagation($event);
    const {id, block_code, itemName} = selectItem;
    this.uploadHeaderDescription = `Upload Attach - ${itemName}`;
    this.printManifestService.setInfoRouteBlockAction({block_code, id});
    this.modalUploadAttach.open('lg').then();
  }

  openToViewAttach($event: Event, selectItem: IRouteBlockSelect): void {
    this.stopPropagation($event);
    const {id, itemName} = selectItem;
    this.uploadHeaderDescription = `Attach by ${itemName}`;
    this.printManifestService.setInfoRouteBlockAction({id});
    this.modalUploadAttach.open('lg').then();
  }

  detachDocument($event: Event, selectedItem: IRouteBlockSelect): void {
    this.stopPropagation($event);
    const {id: routeBlockID} = selectedItem;

    this.sweetAlertHelper.createCustomAlert({
      title: 'Are you sure?',
      type: 'warning',
      confirmButtonText: 'Ok',
      showCancelButton: true,
      cancelButtonText: 'Cancel'
    }).then(({value}: SweetAlertResult): void => {
      if (value) {
        this.busy.push(
          this.printManifestService.deleteAttachToRouteBlock(routeBlockID).subscribe({
            next: (): void => this.printManifestService.reloadSelect(true),
            error: err => this.sweetAlertHelper.captureException(err)
          })
        );
      }
    });
  }

  subscribeObservers(): void {
    this.busy.push(
      this.clientService.getClientsByDriverManifest().subscribe({
        next: ({data}) => this.clients = data,
        error: error => this.sweetAlertHelper.captureException(error)
      })
    );
    this.formForms.get('routes_id').reset([]);
    this.formForms.get('routes_block_id').reset([]);
    this.formForms.get('drivers_id').reset([]);

    this.printManifestService.reloadSelectAction$
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (reload: boolean): void => {
          if (reload) {
            const client_id = this.formForms.get('client_id').value;
            const routes_id = this.formForms.get('routes_id').value;
            const routes_block_id = this.formForms.get('routes_block_id').value;
            this.formForms.patchValue({client_id, routes_id, routes_block_id});
            this.getRoutesByClient(client_id);
          }
        }
      });

    this.printManifestService.closeUploadModalAction$
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (close: boolean) => close && this.modalUploadAttach.close().then()
      });
  }
  loadDataSelectedChanges() {
    this.formForms.get('routes_id').valueChanges.pipe(takeUntil(this.destroy)).subscribe({
      next: (routes) => {
        this.routes_blocks = [];
        if (routes && routes.length) {
          this.formForms.get('routes_block_id').reset([]);
          this.formForms.get('drivers_id').reset([]);
          const routes_ids = routes.map(route => route.id);
          this.formatRouteBlockToMultiSelect(routes_ids);
        } else {
          this.formatRouteBlockToMultiSelect();
        }
      }
    });
    this.formForms.get('routes_block_id').valueChanges.pipe(takeUntil(this.destroy)).subscribe({
      next: (routes_blocks) => {
        if (routes_blocks && routes_blocks.length) {
          this.formForms.get('drivers_id').reset([]);
          const routes_blocks_ids = routes_blocks.map(routes_block => routes_block.id);
          this.getMultiSelectedDriver(routes_blocks_ids);
        } else {
          const routes_ids = this.formForms.get('routes_id').value;
          if (routes_ids && routes_ids.length) {
            const routes_id = routes_ids.map(route => route.id);
            this.formatRouteBlockToMultiSelect(routes_id);
          } else {
            this.getMultiSelectedDriver();
          }
        }
      }, error: error => this.sweetAlertHelper.captureException(error)
    });
  }

  onChangeClient($event: Event): void {
    const { value } = $event.target as HTMLInputElement;
    const client_id: number = Number(value);
    this.getRoutesByClient(client_id);
  }

  printCustomManifests(): void {
    const data = this.formForms.value;
    let { routes_id, routes_block_id, drivers_id } = data;

    if (routes_id.length) {
      routes_id = data.routes_id.map(route => route.id);
    }

    if (routes_block_id.length) {
      routes_block_id = data.routes_block_id.map(routes_block => routes_block.id);
    }

    if (drivers_id.length) {
      drivers_id = data.drivers_id.map(driver => driver.driver_id);
    }

    const payload: { date: string, client_id: number, routes_id: number[], routes_block_id: number[], drivers_id: number[] } = {
      date: this.date_schedule,
      client_id: data.client_id,
      routes_id,
      routes_block_id,
      drivers_id
    };

    this.dataPrint = null;

    this.busy.push(
      this.reportsManageService.downloadAllDriverManifestCustom(payload)
        .subscribe({
          next: (response: Blob) => window.open(window.URL.createObjectURL(response)),
          error: () => this.sweetAlertHelper.createCustomAlert({
            title: 'Error',
            text: 'Manifest not found',
            type: 'error',
          })
        })
    );
  }
  private getRoutesByClient(client_id) {
    this.formForms.get('routes_id').reset([]);
    this.formForms.get('routes_block_id').reset([]);
    this.formForms.get('drivers_id').reset([]);
    this.busy.push(this.clientService.getRoutesByClient(client_id).subscribe({
      next: (resp): void => {
        this.routes = resp.data.map(el => ({
          ...el,
          description_route: String(el.desc_billing ? el.desc_billing : el.description).toUpperCase()
        }));
        const info: { dateofservice: string, client_id: number } = {
          dateofservice: this.date_schedule,
          client_id: client_id
        };
        this.getRoutesBlockByRoute(info);
      }
    }));
  }
  private getRoutesBlockByRoute(info) {
    this.busy.push(this.clientService.getRouteBlocksByRoute(info).subscribe({
      next: (resp): void => {
        if (resp) {
          this.print_block_and_drivers = resp.data;
          this.formatRouteBlockToMultiSelect();
        }
      }, error: error => this.sweetAlertHelper.captureException(error)
    }));
  }


  private formatRouteBlockToMultiSelect(routes = []) {
    this.routes_blocks = this.print_block_and_drivers
      .filter(block => {
        if (routes.length > 0) {
          return routes.some((route: number) => route === Number(block.route_id));
        }
        return true;
      })
      .map(block => ({
        id: block.route_block_id,
        itemName: block.route_block,
        block_code: block.block_code,
        attach: Number(block.attach)
      }));
    this.drivers = this.print_block_and_drivers
      .filter(r => {
        if (routes.length > 0) {
          return routes.some((route: number) => route === Number(r.route_id));
        }
        return true;
      })
      .map(driver => ({ ...driver, description_driver: driver.driver_description} )
      );
  }
  private getMultiSelectedDriver(route_block = []) {
    this.drivers = this.print_block_and_drivers
      .filter(block => {
        if (route_block.length > 0) {
          return route_block.some(rb => Number(rb) === Number(block.route_block_id));
        }
        return true;
      })
      .map(driver => ({ ...driver, description_driver: driver.driver_description} )
      );
  }


  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  get formIsInvalid(): boolean {
    return !this.formForms.valid;
  }
}
