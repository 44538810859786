import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'admin', loadChildren: './admin/admin.module#AdminModule' },
  { path: 'companies', loadChildren: './companies/companies.module#CompaniesModule' },
  { path: 'reports', loadChildren: './reports/reports.module#ReportsModule' },
  { path: 'parametrization', loadChildren: './parametrization/parametrization.module#ParametrizationModule' },
  { path: 'payroll', loadChildren: './payroll/payroll.module#PayrollModule' },
  { path: 'tops', loadChildren: './tops/tops.module#TopsModule' },
  { path: 'billing', loadChildren: './billing/billing.module#BillingModule' },
  { path: 'dispatch', loadChildren: './dispatch/dispatch.module#DispatchModule' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule {}
