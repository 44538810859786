import {Component, OnDestroy, OnInit} from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Subject, Subscription } from 'rxjs';
import { AttachDocumentService } from '../../../services/parameters/attach-document.service';
import { SweetAlertHelper } from '../../../CORE/helpers/sweet-alert-helper.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-missing-license',
  templateUrl: './missing-license.component.html',
  styleUrls: ['./missing-license.component.scss']
})
export class MissingLicenseComponent implements OnInit, OnDestroy {
  private destroy: Subject<boolean> = new Subject();
  busy: Subscription[] = [];
  missingLicensesList: any;
  constructor(private attachDocumentService: AttachDocumentService, private sweetAlertHelper: SweetAlertHelper) {

  }

  ngOnInit() {
    this.subscribeObservers();
  }

  subscribeObservers() {
    this.missingLicensesList = [];
    this.attachDocumentService.missingLicense$.pipe(takeUntil(this.destroy)).subscribe(resp => {
        if (resp) {
          this.missingLicensesList = resp;
        }
    });
  }

  ngOnDestroy(): void {
    this.busy.forEach(subscription => subscription.unsubscribe());
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }


}
