import { Component, OnDestroy, OnInit } from '@angular/core';
import { CellDoubleClickedEvent, GridOptions, RowNode } from 'ag-grid-community';
import { Subject, Subscription} from 'rxjs';
import { BatchInvoicesService } from '../../../../../../../services/billing/batch/batch-invoices.service';
import { InvoiceReportService } from '../../../invoice-report.service';
import { takeUntil } from 'rxjs/operators';
import { WorkflowService } from '../../../../../../../services/billing/workflow.service';
import { SweetAlertHelper } from '../../../../../../../CORE/helpers/sweet-alert-helper.service';
import { IResponse } from '../../../../../../../CORE/interfaces/response.interface';
import { IBatchInfoByRate, IBatchTotalInfo } from '../../../../../../../CORE/interfaces/invoice-report.interface';
import { IPayloadBatch } from '../../../../../../../CORE/interfaces/workflow.interface';
import AgGridHelpers from '../../../../../../../helpers/ag-grid-helpers';

@Component({
  selector: 'app-info-batch-by-rate',
  templateUrl: './info-batch-by-rate.component.html',
  styleUrls: ['./info-batch-by-rate.component.scss']
})
export class InfoBatchByRateComponent implements OnInit, OnDestroy {
  busy: Subscription[] = [];
  destroy: Subject<boolean> = new Subject();

  gridBatchRate: GridOptions;
  domLayout = 'autoHeight';

  clientName: string;
  startDate: string;
  endDate: string;
  clientId: number;
  batchId: number;

  constructor(
    private batchInvoicesService: BatchInvoicesService,
    private invoiceReportService: InvoiceReportService,
    private workflowService: WorkflowService,
    private sweetAlertHelper: SweetAlertHelper
  ) {
    this.initGrid();
  }

  ngOnInit(): void {
    this.makeSubscriptions();
  }

  initGrid(): void {
    this.gridBatchRate = <GridOptions>{
      enableFilter: true,
      enableSorting: true,
      enableColResize: true,
      rowStyle: { textAlign: 'right' },
      onGridReady: (): void => {
        this.gridBatchRate.api.sizeColumnsToFit();
        this.gridBatchRate.api.setRowData([]);
        this.getInfoBatchByRate();
      },
      onCellDoubleClicked: (event: CellDoubleClickedEvent): void => {
        const { node: selectedRowNode} = event;
        this.drawSelectedRow(selectedRowNode);
      },
      columnDefs: [
        {
          headerName: 'Manifest',
          colId: 'total_manifest',
          field: 'total_manifest',
          cellRenderer: (params) => this.formatThousandsSeparator(params.value),
        },
        {
          headerName: 'Service Hours',
          colId: 'service_hours',
          field: 'service_hours',
          cellRenderer: (params) => this.formatThousandsSeparator(params.value),
          cellStyle: {textAlign: 'right'}
        },
        {
          headerName: 'Rate',
          colId: 'rate',
          field: 'rate',
          cellRenderer: (params) => AgGridHelpers.CurrencyCellRenderer(params)
        },
        {
          headerName: 'Total',
          colId: 'total_amount',
          field: 'total_amount',
          cellRenderer: (params) => AgGridHelpers.CurrencyCellRenderer(params)
        },
        {
          headerName: 'Interruptions',
          children: [
            {
              headerName: 'Time Interruption (hours)',
              colId: 'time_interruption',
              field: 'time_interruption',
              cellRenderer: (params) => this.formatThousandsSeparator(params.value)
            },
            {
              headerName: 'Total Interruption',
              colId: 'total_interruption',
              field: 'total_interruption',
              cellRenderer: (params) => this.formatThousandsSeparator(params.value)
            }
          ]
        },
        {
          headerName: 'Total Amount',
          colId: 'total_amount_more_interruptions',
          field: 'total_amount_more_interruptions',
          cellRenderer: (params) => AgGridHelpers.CurrencyCellRenderer(params)
        },
      ],
    };
  }

  private makeSubscriptions(): void {
    this.subscribeToInfoByExternalFormToInvoiceReportAction();
    this.subscribeToInfoClient();
    this.subscribeToReloadInfoBatch();
  }

  private subscribeToReloadInfoBatch(): void {
    this.invoiceReportService.reloadInfoBatchAction$.pipe(takeUntil(this.destroy))
      .subscribe({
        next: (reload: boolean): void => {
          if (reload) {
            this.getInfoBatchByRate();
            this.invoiceReportService.clearGridInvoice();
            this.invoiceReportService.clearGridCredit();
          }
        }
      });
  }

  private subscribeToInfoClient(): void {
    this.invoiceReportService.infoByClientAction$.pipe(takeUntil(this.destroy))
      .subscribe({
        next: ({client_name}) => this.clientName = client_name
      });
  }

  private subscribeToInfoByExternalFormToInvoiceReportAction(): void {
    this.workflowService.infoByExternalFormToInvoiceReportAction$.pipe(takeUntil(this.destroy))
      .subscribe({
        next: ({date, clients_id}): void => {
          const {start_date, end_date} = date;
          this.startDate = start_date;
          this.endDate = end_date;
          this.clientId = clients_id;
        }
      });
  }

  private getInfoBatchByRate(): void {
    const payload: IPayloadBatch = this.invoiceReportService
      .generatePayloadToBatch({
        date_end: this.endDate,
        client_id: this.clientId,
        date_start: this.startDate
      });

    this.busy.push(
      this.invoiceReportService.getBillingInfoBatchByRate(payload).subscribe({
        next: ({data}: IResponse<IBatchInfoByRate[]>): void => {
          if (data && data.length) {
            this.gridBatchRate.api.setRowData(data);
            this.setAndSendBatchID(data);
            this.calculateTotals(data);
          }
        },
        error: error => this.sweetAlertHelper.captureException(error)
      })
    );
  }

  private drawSelectedRow(selectedNode: RowNode): void {
    selectedNode.setSelected(true);
  }

  private calculateTotals(data: IBatchInfoByRate []): void {
    const totalAmount: number = data.reduce((acc: number, el: IBatchInfoByRate) => (acc + el.total_amount) , 0);
    const totalManifest: number = data.reduce((acc: number, el: IBatchInfoByRate) => (acc + Number(el.total_manifest)) , 0);
    const totalHours: number = data.reduce((acc: number, el: IBatchInfoByRate) => (acc + Number(el.service_hours)) , 0);

    const totals: IBatchTotalInfo = {
      totalAmount,
      totalManifest,
      totalHours: totalHours.toLocaleString()
    };

    this.invoiceReportService.setInfoToFillTotals(totals);
  }

  private formatThousandsSeparator(number: number): string {
    return number ? number.toLocaleString() : undefined;
  }

  private setAndSendBatchID(data: IBatchInfoByRate[]): void {
    if (data.length) {
      this.batchId = data[0].batch_id;
      this.invoiceReportService.setBatchId(this.batchId);
    }
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
    this.busy.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
