import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DispatchService } from '../../../../../../../services/dispatch/dispatch.service';
import * as L from 'leaflet';
import 'leaflet-draw';
import * as polyUtil from 'polyline-encoded';
import { Subscription } from 'rxjs-compat';

@Component({
  selector: 'app-set-map-segments',
  templateUrl: './set-map-segments.component.html',
  styleUrls: ['./set-map-segments.component.scss']
})
export class SetMapSegmentsComponent implements OnInit, AfterViewInit, OnDestroy {
  busy: Subscription[] = [];
  private map: L.Map;
  drawnItems = new L.FeatureGroup();
  id_route = null;
  show_route = true;
  show_segment1 = true;
  show_segment2 = true;
  dataRoute = null;
  layers = [
    {
      code: null,
      layer: null,
      mpath: null,
      color: '#00b8ff5c',
      show: true
    },
    {
      code: null,
      layer: null,
      mpath: null,
      color: '#c699b8',
      show: true
    },
    {
      code: null,
      layer: null,
      mpath: null,
      color: '#99b699',
      show: true
    }
  ];
  drawing = false;

  @Input() set route_id(route_id) {
    this.id_route = route_id;
    if (route_id) {
      this.busy.push(
        this.dispatchService
          .getRoutesData({
            route_id: [route_id]
          })
          .subscribe(resp => {
            let reupdated = false;
            const dataRoute = resp.data.length > 0 ? resp.data[0] : null;
            if (dataRoute) {
              if (!dataRoute.segment1_path) {
                dataRoute.segment1_path = dataRoute.route_path;
                reupdated = true;
              }
              if (!dataRoute.segment2_path) {
                dataRoute.segment2_path = dataRoute.route_path;
                reupdated = true;
              }
            }
            this.dataRoute = dataRoute;
            this.renderRoutes();
            if (reupdated) {
              this.saveSegments();
            }
          })
      );
    }
  }

  constructor(private dispatchService: DispatchService) {}

  ngOnInit(): void {}
  ngAfterViewInit(): void {
    this.initMap();
  }
  ngOnDestroy() {
    return (this.busy = []);
  }

  private initMap() {
    this.map = L.map('map').setView([25.7742691, -80.1936569], 13);
    L.tileLayer(
      'https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWF0aGl3cyIsImEiOiJja20yNnB4cWMwMXBhMm9wZ2l6M2Zxajk3In0.0SkYKscswdRRKzulnABbtw',
      {
        maxZoom: 20,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
      }
    ).addTo(this.map);
    this.map.zoomControl.setPosition('bottomright');
    this.map.addLayer(this.drawnItems);
    var drawControl = new L.Control.Draw({
      draw: {
        polygon: false,
        rectangle: false,
        circle: false,
        circlemarker: false,
        marker: false
      },
      edit: {
        featureGroup: this.drawnItems
      }
    });
    this.map.addControl(drawControl);
    this.map.on('draw:created', e => {
      const layer = e.layer;
      const mpath = polyUtil.encode(e.layer._latlngs);
      if (!this.layers[0].code) {
        layer.options.color = this.layers[0].color;
        this.drawnItems.addLayer(layer);
        this.layers[0].code = layer._leaflet_id;
        this.layers[0].layer = layer;
        this.layers[0].mpath = mpath;
      } else if (!this.layers[1].code) {
        layer.options.color = this.layers[1].color;
        this.drawnItems.addLayer(layer);
        this.layers[1].code = layer._leaflet_id;
        this.layers[1].layer = layer;
        this.layers[1].mpath = mpath;
      } else if (!this.layers[2].code) {
        layer.options.color = this.layers[2].color;
        this.drawnItems.addLayer(layer);
        this.layers[2].code = layer._leaflet_id;
        this.layers[2].layer = layer;
        this.layers[2].mpath = mpath;
      } else {
        this.map.removeLayer(e.layer);
      }
      this.saveSegments();
    });
    this.map.on('draw:edited', (e: any) => {
      const ids = Object.keys(e.layers._layers);
      for (let i = 0; i < ids.length; i++) {
        const element = ids[i];
        const idx = this.layers.map(l => String(l.code)).indexOf(String(element));
        if (idx >= 0) {
          const newL = e.layers._layers[Number(element)];
          const mpath = polyUtil.encode(newL._latlngs);
          this.layers[idx].layer = newL;
          this.layers[idx].mpath = mpath;
        }
      }
      this.saveSegments();
    });
    this.map.on('draw:deleted', (e: any) => {
      const ids = Object.keys(e.layers._layers);
      for (let i = 0; i < ids.length; i++) {
        const element = ids[i];
        const idx = this.layers.map(l => String(l.code)).indexOf(String(element));
        if (idx >= 0) {
          this.layers[idx].code = null;
          this.layers[idx].layer = null;
          this.layers[idx].mpath = null;
        }
      }
      this.saveSegments();
    });
    this.map.on('draw:drawstart', () => {
      this.drawing = true;
    });
    this.map.on('draw:drawstop', () => {
      this.drawing = false;
    });
    this.map.on('draw:editstart', () => {
      this.drawing = true;
    });
    this.map.on('draw:editstop', () => {
      this.drawing = false;
    });
    this.map.on('draw:deletestart', () => {
      this.drawing = true;
    });
    this.map.on('draw:deletestop', () => {
      this.drawing = false;
    });
    this.onMapReady(this.map);
  }

  onMapReady(map: L.Map) {
    setTimeout(() => {
      map.invalidateSize();
    }, 0);
  }

  renderRoutes() {
    const dataRoute = Object.assign({}, this.dataRoute);
    if (dataRoute) {
      if (dataRoute.route_path) {
        this.setSegment(dataRoute.route_path, 0);
      }
      if (dataRoute.segment1_path) {
        this.setSegment(dataRoute.segment1_path, 1);
      }
      if (dataRoute.segment2_path) {
        this.setSegment(dataRoute.segment2_path, 2);
      }
    }
  }

  setPolylines(path, color) {
    const latlngs = polyUtil.decode(path);
    const poly = L.polyline(latlngs, { color });
    const layer = L.layerGroup([poly]);
    layer.addTo(this.map);
    this.map.fitBounds(poly.getBounds());
  }

  setSegment(path, position) {
    const segment = Object.assign({}, this.layers[position]);
    const color = segment.color;
    if (segment.show && !segment.mpath) {
      const latlngs = polyUtil.decode(path);
      const poly: any = L.polyline(latlngs, { color });
      this.drawnItems.addLayer(poly);
      segment.code = poly._leaflet_id;
      segment.layer = poly;
      segment.mpath = path;
      if (position == 0) {
        this.map.fitBounds(poly.getBounds());
      }
    } else if (!segment.show && segment.layer) {
      this.map.removeLayer(segment.layer);
      segment.code = null;
      segment.layer = null;
    } else if (segment.show && segment.mpath && !segment.layer) {
      const latlngs = polyUtil.decode(path);
      const poly: any = L.polyline(latlngs, { color });
      this.drawnItems.addLayer(poly);
      segment.code = poly._leaflet_id;
      segment.layer = poly;
    }
    this.layers[position] = segment;
  }

  saveSegments() {
    this.busy.push(
      this.dispatchService
        .saveSegmentsPaths({
          id: this.id_route,
          route_path: this.layers[0].mpath ? btoa(this.layers[0].mpath) : null,
          segment1_path: this.layers[1].mpath ? btoa(this.layers[1].mpath) : null,
          segment2_path: this.layers[2].mpath ? btoa(this.layers[2].mpath) : null
        })
        .subscribe(() => {})
    );
  }
}
