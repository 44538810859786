import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { GridOptions } from 'ag-grid-community';

@Component({
  selector: 'app-grid-container',
  templateUrl: './grid-container.component.html',
  styleUrls: ['./grid-container.component.scss']
})

export class GridContainerComponent implements OnInit, OnChanges {
  @Input() manifestData: any[] = [];
  gridManifestForm: GridOptions;
  gridData: any[] = [];

  constructor() {
    this.onInitGrid();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['manifestData'] && this.manifestData && this.manifestData.length) {
      this.gridData = this.manifestData;
    }
  }

  ngOnInit() {}

  onInitGrid() {
    this.gridManifestForm = <GridOptions> {
      sortingOrder: ['desc', 'asc', null],
      enableFilter: true,
      enableSorting: true,
      enableColResize: true,
      columnDefs: [
        { headerName: 'Date', field: 'dateOfService', width: 150 },
        { headerName: 'LYO', field: 'leave_yard_odometer', width: 105 },
        { headerName: 'FSO', field: 'first_stop_odometer', width: 105 },
        { headerName: 'LSO', field: 'last_stop_odometer', width: 105 },
        { headerName: 'RYO', field: 'return_to_yard_odometer', width: 105 }
      ]
    };
  }
}
