import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import {
  AttachDocumentService,
  DocumentsService,
  DriverService,
  TSODriver,
  TSODriverService
} from '../../services/services.index';
import { TsoResponse, TsoService } from '../../services/Tso.service';
import { KeyPress } from '../../tools/key-press';
import { Mask } from '../../tools/mask';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxPermissionsService } from 'ngx-permissions';
import { DriverAttachLicenseEnum } from '../../CORE/enums/DriversEnum.enum';
import { takeUntil } from 'rxjs/operators';
import { IDriverDetails } from '../../CORE/interfaces/driver-details.interface';
import * as moment from 'moment';
import { BsModalComponent } from 'ng2-bs3-modal';
import { EDocumentCode } from '../../CORE/enums/documentCode.enum';

@Component({
  selector: 'app-driver-management',
  templateUrl: './driver-management.component.html',
  styleUrls: ['./driver-management.component.scss']
})
export class DriverManagementComponent implements OnInit, OnChanges, OnDestroy {
  private destroy: Subject<boolean> = new Subject<boolean>();
  @ViewChild('modalAddDocument') modalAddDocument: BsModalComponent;

  @Input() driverSekected: number;
  @Output() onHide?: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onTitle?: EventEmitter<string> = new EventEmitter<string>();
  @Output() notify?: EventEmitter<any> = new EventEmitter<any>();

  busy: Subscription[] = [];
  key_press = new KeyPress();
  mask = new Mask();
  apiResponse: any = {};
  tso_switch = false;
  public maxDate = new Date();
  zones = [];

  formData: FormGroup;
  editSsn = false;
  editDl = false;

  codeEditLicense = {
    driverLicense: EDocumentCode.DriverLicense,
    hackLicense: EDocumentCode.HackLicense
  };

  constructor(
    private serviceDriver: DriverService,
    private serviceTSODriver: TSODriverService,
    private serviceTSOService: TsoService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private ngxPermissionsService: NgxPermissionsService,
    private documentsService: DocumentsService,
    private attachDocumentService: AttachDocumentService
  ) {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['driverSekected'] && changes['driverSekected'].currentValue !== 0) {
      this.loadDriverInfo(this.driverSekected);
    } else {
      this.onSetTitle('New Driver');
    }
  }

  subscribeState() {
    this.serviceDriver.driverInfo$.pipe(takeUntil(this.destroy)).subscribe(driver => {
      if (driver) {
        this.driverSekected = driver.id;
        this.loadDriverInfo(driver.id);
      } else {
        this.createForm();
      }
    });

    this.attachDocumentService.saveAttach$.pipe(takeUntil(this.destroy)).subscribe(resp => {
      if (resp) {
        this.loadDriverInfo(this.driverSekected);
      }
      this.modalAddDocument.close();
    });
  }

  ngOnInit() {
    this.subscribeState();
    this.loadZones();
    this.validatePermissionViewInput();
    this.disabledControls();
  }

  disabledControls() {
    this.formData.get('driver_license').disable();
    this.formData.get('license_expiry').disable();
    this.formData.get('hack_license_number').disable();
    this.formData.get('hack_license_exp_date').disable();
  }

  createForm() {
    this.formData = this.fb.group({
      id: [null],
      active: [true],
      is_supervisor: [false],
      tso: [false],
      tso_id: [null],
      employee_id: [null, [Validators.required]],
      last_name: [null, [Validators.required]],
      first_name: [null, [Validators.required]],
      mi: [null],
      ssn: [null, [Validators.required]],
      date_birth: [null, [Validators.required]],
      driver_license: [null],
      license_expiry: [null],
      email: [null, [Validators.required]],
      street_address: [null],
      street_address_two: [null],
      city: [null],
      state: [null],
      zip: [null],
      phone: [null],
      driver_profiles: [null],
      end_date: [null],
      end_date_reason: [null],
      hack_license_number: [null],
      hack_license_exp_date: [null],
      insurance: [false, [Validators.required]],
      departament: [''],
      work_location: [''],
      gender: [''],
      is_insured: [false]
    });
  }

  get isEdit(): boolean {
    return this.formData.get('id').value;
  }

  onCancel() {
    this.onHide.emit(true);
  }

  onSetTitle(title: string) {
    this.onTitle.emit(title);
  }

  /***
   * @description
   */
  onSave(validForm) {
    if (validForm) {
      if (this.formData.controls['active'].value) {
        this.saveFull();
      } else {
        Swal.fire({
          text: 'This operation, will remove the driver from the master blocks, confirm the action?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes!'
        }).then(result => {
          if (result.value) {
            this.saveFull();
          }
        });
      }
    } else {
      this.toastr.warning('Please complete the required fields');
    }
  }

  saveFull() {
    const data = Object.assign(this.formData.getRawValue());
    if (!this.driverSekected) {
      if (data.tso) {
        this.TSOSaveDriver(data);
      } else {
        delete data.id;
        this.save(data);
      }
    } else {
      this.update(data);
    }
  }

  loadDriverInfo(driverid: number) {
    this.busy.push(
      this.serviceDriver.getDriver(driverid).subscribe((data: IDriverDetails) => {
        if (data) {
          const driverLicense = data.attach_documents.find(
            license => license.document_id == DriverAttachLicenseEnum.DriverLicense
          );
          const driverHarkLicense = data.attach_documents.find(
            harkLicense => harkLicense.document_id == DriverAttachLicenseEnum.DriverHarkLicense
          );
          this.formData.patchValue({
            id: data.id,
            active: data.active,
            is_supervisor: data.is_supervisor,
            tso: !!data.tso_id,
            tso_id: data.tso_id,
            employee_id: data.employee_id,
            last_name: data.last_name,
            first_name: data.first_name,
            mi: data.mi,
            ssn: data.ssn,
            date_birth: data.date_birth,
            driver_license: driverLicense && driverLicense.number ? driverLicense.number : '',
            license_expiry:
              driverLicense && driverLicense.expiration ? moment(driverLicense.expiration).format('MM-DD-YYYY') : '',
            email: data.email,
            street_address: data.street_address,
            street_address_two: data.street_address_two,
            city: data.city,
            state: data.state,
            zip: data.zip,
            phone: data.phone,
            driver_profiles: data.driver_profiles,
            end_date: data.end_date,
            end_date_reason: data.end_date_reason,
            hack_license_number: driverHarkLicense && driverHarkLicense.number ? driverHarkLicense.number : '',
            hack_license_exp_date:
              driverHarkLicense && driverHarkLicense.expiration
                ? moment(driverHarkLicense.expiration).format('MM-DD-YYYY')
                : '',
            insurance: data.insurance,
            departament: data.departament,
            work_location: data.work_location,
            gender: data.gender,
            is_insured: data.is_insured
          });
          this.onSetTitle('Edit Driver - ' + data.last_name + ' ' + data.first_name);
        }
      })
    );
  }

  loadZones() {
    this.busy.push(
      this.serviceDriver.getZones().subscribe(zones => {
        this.zones = zones.data;
      })
    );
  }

  /***
   * @description
   */
  save(data) {
    this.busy.push(
      this.serviceDriver.storeDriver(data).subscribe((resp: any) => {
        if (resp && resp.status === 'success') {
          this.createForm();
          Swal.fire('Success!', resp.data, 'success').then(() => {
            this.onCancel();
            this.serviceDriver.setSaveDriver(true);
            this.notify.emit();
          });
        } else {
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: 'An error has ocurred, please check the log'
          }).then(() => {
            this.deleteDriverTSO();
          });
        }
      }, error =>
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: error.error.message,
        }).then(() => {})
      )
    );
  }

  /***
   * @description
   */
  update(data) {
    this.busy.push(
      this.serviceDriver.updateDriver(data, this.driverSekected).subscribe((resp: any) => {
        if (resp && resp.status === 'success') {
          this.createForm();
          Swal.fire('Success!', resp.data, 'success').then(() => {
            this.onCancel();
            this.serviceDriver.setSaveDriver(true);
            this.notify.emit();
          });
        } else {
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: 'An error has ocurred, please check the log'
          });
        }
      }, error =>
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: error.error.message,
        }).then(() => {})
      )
    );
  }

  TSOSaveDriver(data) {
    const token = this.serviceTSOService.getTokenLocalMain();
    if (!token) {
      alert('Error: The TSO token is required');
    } else if (!data.license_expiry) {
      alert('license expiry is required');
    } else if (!data.driver_license) {
      alert('The License number is being used by another driver in the company');
    } else {
      const tsoDriver = <TSODriver>{
        token: token,
        name: data.first_name,
        lastName: data.last_name,
        licenseNumber: data.driver_license,
        licenseStateCode: 'FL',
        licenseExpiration: data.license_expiry,
        driverStatusID: '',
        state: data.state,
        city: data.city,
        postalCode: data.zip,
        email: data.email,
        phone: data.phone
      };
      this.busy.push(
        this.serviceTSODriver.createDriver(tsoDriver).subscribe((res: TsoResponse) => {
          if (res.Code !== 1) {
            alert('Error : ' + res.Message);
          } else {
            if (res.Data) {
              data.tso_id = res.Data;
              this.save(data);
            }
          }
        })
      );
    }
  }

  deleteDriverTSO(showMenssage?: boolean) {
    const token = this.serviceTSOService.getTokenLocalMain();
    const data = Object.assign(this.formData.getRawValue());
    this.busy.push(
      this.serviceTSODriver.deleteDriver(token, data.tso_id).subscribe((res: TsoResponse) => {
        if (res.Code !== 1) {
          alert('Error : ' + res.Message);
        } else {
          if (showMenssage) {
            alert('Driver removed correctly in TSO!');
            this.onCancel();
          }
        }
      })
    );
  }
  onDeleteInTSO() {
    const data = Object.assign(this.formData.getRawValue());
    if (confirm('Confirm that the elimination of the driver in TSO?')) {
      this.deleteDriverTSO(true);
      this.serviceDriver.removeTSO(data.id).subscribe();
    }
  }

  async validatePermissionViewInput() {
    this.editSsn = await this.ngxPermissionsService.hasPermission('input-ssn-driver');
    //this.editDl = await this.ngxPermissionsService.hasPermission('input-dl-driver');
  }

  openModalLicenses(code: number) {
    this.documentsService.setCodeDocument(code);
    this.attachDocumentService.setAttachData({ model_id: this.formData.get('id').value, model: 'drivers' });
    this.modalAddDocument.open();
  }

  closeModalLicenses() {
    this.modalAddDocument.close();
  }

  get editLicense(): boolean {
    return !!this.formData.get('id').value;
  }

  get licenseExpiry(): boolean {
    return !this.formData.get('license_expiry').value;
  }

  get hackLicenseExpiry(): boolean {
    return !this.formData.get('hack_license_exp_date').value;
  }

  ngOnDestroy() {
    this.destroy.next(true);
    this.destroy.unsubscribe();
    this.busy.forEach(subscription => subscription.unsubscribe());
  }
}
