import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputEmailsComponent } from './components/input-emails/input-emails.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [InputEmailsComponent],
  exports: [InputEmailsComponent]
})
export class EmailsInputModule {}
