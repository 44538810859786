import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgBusyModule } from 'ng-busy';
import { CurrencyMaskModule } from 'ngx-currency-mask';
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from 'ngx-currency-mask/src/currency-mask.config';
import { NgxPermissionsModule } from 'ngx-permissions';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { Configuration } from './app.configuration';
import { AppRoutingModule } from './app.routing';
import { PagesModule } from './pages/pages.module';
import { AuthService } from './services/auth.service';
import { InterceptorService } from './services/interceptor.service';
import { SharedModule } from './shared/shared.module';
import { ThemeModule } from './theme/theme.module';
import { NotificationService } from './components/services/push/notification.service';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ShowErrorsModule } from './tools/showErrosForm.directive';
import { Angular2SignaturepadModule } from 'angular2-signaturepad';
import { GridSwitchComponent } from './components/grid-switch/grid-switch.component';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'right',
  allowNegative: false,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: '$ ',
  suffix: '',
  thousands: ','
};

@NgModule({
  declarations: [
    AppComponent,
    GridSwitchComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    PagesModule,
    AppRoutingModule,
    HttpModule,
    NgBusyModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ServiceWorkerModule.register('service-worker.js', { enabled: true }),
    ThemeModule,
    CurrencyMaskModule,
    NgxPermissionsModule.forRoot(),
    ImageCropperModule,
    ShowErrorsModule,
    Angular2SignaturepadModule
  ],
  providers: [
    HttpModule,
    Configuration,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    }, {
      provide: APP_INITIALIZER,
      useFactory: (ds: AuthService) => () => ds.loadPermissions(null),
      deps: [AuthService],
      multi: true
    },
    // { provide: ErrorHandler, useClass: ErrorHandlerServiceService }
    NotificationService
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
