import { map } from 'rxjs/operators';
import { AfterViewInit, Component, Input, OnInit, OnDestroy } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-rotatedmarker';
import { TsoService } from '../../../../../../services/Tso.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import * as polyUtil from 'polyline-encoded';
import { Location } from '@angular/common';
import { RouteService } from '../../../../../../services/TSO/route.service';
import { SocketTracker } from './../../../../../../sockets/socket-tracker.service';
import * as moment from 'moment';

@Component({
  selector: 'app-map-stops',
  templateUrl: './map-stops.component.html',
  styleUrls: ['./map-stops.component.scss']
})
export class MapStopsComponent implements OnInit, OnDestroy, AfterViewInit {
  private map;
  private LeafIcon;
  private BusIcon;
  busy: Subscription;
  markers: any = [];
  markersBus: any = [];
  @Input() inTab?;
  @Input() tsoId?;
  dataRoute: any = {};
  routesTso: any = [];
  buses: any = [];
  polyline: any;
  constructor(
    private tsoService: TsoService,
    private activatedRoute: ActivatedRoute,
    private _location: Location,
    private socketTracker: SocketTracker,
  ) {
    this.socketTracker.event().subscribe(res => {
      console.log(res);

      this.updateMarkerBus(res);
    });
  }
  ngOnDestroy(): void {
    // console.log('distroing');

    this.socketTracker.unsubscribe();
  }

  ngOnInit() {
    if (!this.inTab) {
      this.activatedRoute.params.subscribe((params: Params) => {
        const route_id_tso = params['id'];
        const route_id = params['id_route'];
        this.tsoId = route_id_tso;
        this.loadRouteData(route_id_tso);
        this.getAllRoutesTSO();
        this.socketTracker.subscribe(route_id);
      });
    } else {
      if (this.tsoId) {
        this.loadRouteData(this.tsoId);
      }
    }
  }

  ngAfterViewInit() {
    this.initMap();
    this.onMapReady(this.map);
  }

  private initMap() {
    this.map = L.map('map').setView([25.7742691, -80.1936569], 13);
    L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
      maxZoom: 20,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    }).addTo(this.map);
    this.map.zoomControl.setPosition('bottomright');
  }

  onMapReady(map: L.Map) {
    setTimeout(() => {
      map.invalidateSize();
    }, 0);
  }

  loadData(route_id) {
    this.busy = this.tsoService.getStopsByRoute(route_id, false).subscribe((resp: any) => {
      this.setIcon();
      this.setMarker(resp);
    });
  }

  loadRouteData(route_id) {
    this.busy = this.tsoService.getRoute(route_id).subscribe((resp: any) => {
      if (resp.status === 'success') {
        this.dataRoute = resp.data;
        this.mapPath();
        this.loadData(route_id);
      }
    });
  }

  setIcon() {
    this.LeafIcon = {
      icon: L.icon({
        iconSize: [50, 50],
        iconUrl: '/assets/img/marker_6.png',
      })
    };
    this.BusIcon = {
      icon: L.icon({
        iconSize: [15, 40],
        iconUrl: '/assets/img/marker_4.png',
      })
    };
  }

  setMarker(data: any[]) {
    data.forEach((row: any) => {
      const marker = L.marker([row.latitude, row.longitude], this.LeafIcon).addTo(this.map);

      marker.bindPopup(this.poputContent(row)).openPopup();
      this.markers.push(marker);
    });
  }

  poputContent(row: any) {
    const html = `
        <table>
            <tr><td>Route:</td><td>${this.dataRoute.description}</td></tr>
            <tr><td>Stop #:</td><td>${row.stop_number}</td></tr>
            <tr><td>Seqe #:</td><td>${row.sequence}</td></tr>
            <tr><td>Name:</td><td>${row.description}</td></tr>
            <tr><td>Address:</td><td>${row.street}</td></tr>
        </table>
      `;
    return html;
  }

  mapPath() {
    const latlngs = polyUtil.decode(this.dataRoute.route_path);
    this.polyline = L.polyline(latlngs, { color: '#284A6E' }).addTo(this.map);
    this.map.fitBounds(this.polyline.getBounds());
  }

  onBack() {
    this._location.back();
  }

  getAllRoutesTSO() {
    this.busy = this.tsoService.getTsoRelations().subscribe(
      (resp: any) => {
        if (resp.status === 'success') {
          this.routesTso = resp.data;
          // console.log(resp.data);

        }
      }
    );
  }

  onChangeRoute(tsoId) {
    this.clearMarkers();
    this.clearPolygons();
    this.markersBus.forEach((row: any) => {
      this.map.removeLayer(row);
    });
    this.buses = [];
    this.markersBus = [];
    this.loadRouteData(tsoId);
    const idx = this.routesTso.map(r => r.tso_id).indexOf(tsoId);
    this.socketTracker.subscribe(this.routesTso[idx].id);
  }

  clearMarkers() {
    this.markers.forEach((row: any) => {
      this.map.removeLayer(row);
    });
  }

  clearPolygons() {
    this.map.removeLayer(this.polyline);
  }

  updateMarkerBus(data) {
    const buses = Object.assign([], this.buses);
    const i = buses.map(b => b.vehicle_id).indexOf(data.vehicle_id);
    if (i >= 0) {
      const actual = Object.assign({}, buses[i]);
      const angle = this.angle(actual.latitude, actual.longitude, data.latitude, data.longitude);
      buses[i].latitude = data.latitude;
      buses[i].longitude = data.longitude;
      buses[i].speed = data.speed;
      buses[i].state = data.state;
      buses[i].angle = angle;
      buses[i].date_at = moment().format('LTS');
    } else {
      data.angle = 0;
      data.date_at = moment().format('LTS');
      buses.push(data);
    }
    this.buses = buses;
    this.setMarkerBuses(buses);
  }
  angle(cx, cy, ex, ey) {
    const dy = ey - cy;
    const dx = ex - cx;
    let theta = Math.atan2(dy, dx);
    theta *= 180 / Math.PI;
    return theta;
  }
  poputContentBus(row: any) {
    const html = `
        <table>
          <tr><td>Driver: </td><td>${row.dataTracker.driver_name}</td></tr>
          <tr><td>Bus: </td><td>${row.vehicle_id}</td></tr>
          <tr><td>Speed: </td><td>${row.speed.toFixed(2)}</td></tr>
          <tr><td>Last Update: </td><td>${row.date_at}</td></tr>
        </table>
      `;
    return html;
  }
  setMarkerBuses(data: any[]) {
    this.markersBus.forEach((row: any) => {
      this.map.removeLayer(row);
    });
    data.forEach((row: any) => {
      const marker = L.marker(
        [row.latitude, row.longitude], {
        rotationAngle: row.angle,
        ...this.BusIcon
      }).addTo(this.map);
      marker.bindPopup(this.poputContentBus(row));
      this.markersBus.push(marker);
    });
  }
}
