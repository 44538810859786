import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {CONSTANT, CONSTANTS} from '../../app.constants';
import { BaseService } from '../../core/BaseService';
import { Driver } from '../../pages/manifests/components/driver-manifest/models';
import { IResponse } from '../../CORE/interfaces/response.interface';

export interface DriverUser {
  id: number;
  name: string;
  email: string;
  password: string;
  phone: string;
  role_id: number;
  status: boolean;
  login: any;
  companies: any[];
  pin: string;
}

export interface IDriver {
  id: number;
  last_name: string;
  first_name: string;
  mi: string;
  ssn: string;
  date_birth: string;
  gender: string;
  driver_license: string;
  license_expiry: string;
  email: string;
  street_address: string;
  street_address_two: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  active: boolean;
  employee_id: string;
  tso_id: string;
}

@Injectable()
export class DriverService extends BaseService {
  protected resource: string;

  driverid$ = new Subject<IDriver>();

  private driverInfoSubject = new BehaviorSubject<IDriver>(null);
  driverInfo$ = this.driverInfoSubject.asObservable();

  private saveDriverSubject = new BehaviorSubject<boolean>(false);
  saveDriver$ = this.saveDriverSubject.asObservable();

  constructor(private http: HttpClient) {
    super();
    this.resource = environment.url_api_v + CONSTANTS.DRIVERS;
  }

  setDriverInfo(data: IDriver) {
    this.driverInfoSubject.next(data);
  }

  setSaveDriver(data: boolean) {
    this.saveDriverSubject.next(data);
  }

  /**
   * @description Return all drivers
   * @returns {Observable<any>}
   */
  getDrivers(): Observable<any> {
    return this.http.get<any>(this.resource).pipe(catchError(this.handleError));
  }

  /**
   * @description Save a driver
   * @param data
   * @returns {Observable<Response>}
   */
  storeDriver(data: any): Observable<Response> {
    return this.http.post<Response>(this.resource, JSON.stringify(data));
  }

  /**
   * @description Update a driver
   * @param data
   * @returns {Observable<Response>}
   */
  updateDriver(data: any, id: number): Observable<Response> {
    return this.http.put<Response>(
      this.resource + '/' + id,
      JSON.stringify(data)
    );
  }

  /**
   * @description Get a driver
   * @param {number} id
   * @returns {Observable<any>}
   */
  getDriver(id: number): Observable<any> {
    return this.http.get(this.resource + '/' + id);
  }

  getDriversWithoutPermission(id: number): Observable<any>{
    return this.http.get(this.resource + 'withoutPermission/' + id);
  }

  /**
   * @description
   * @param {boolean} status
   * @returns {Observable<any>}
   */
  getDriversStatus(status: boolean): Observable<any> {
    if (status) {
      return this.http.get(this.resource);
    } else {
      return this.http.get(this.resource + '/active/1');
    }
  }

  getTotalDocuments(model: string) {
    const url = `${CONSTANT.DOCUMENTS.URL.GET_TOTAL_DOCUMENTS}${model}`;
    return this.http.get<IResponse<any>>(url);
  }

  /**
   * @description
   */
  changeDriverStatus(data: any, id: number): Observable<Response> {
    return this.http.put<Response>(
      environment.url_api_v + 'drivers/active/' + id,
      JSON.stringify(data)
    );
  }

  /***
   * @description
   * @param data
   * @returns {Array<Driver>}
   */
  orderDriver(data: any) {
    let t: Array<Driver> = data;
    t.sort((a, b) => {
      if (a.first_name < b.first_name) return -1;
      else if (a.first_name > b.first_name) return 1;
      else return 0;
    });
    return t;
  }

  getUser(id: number) {
    return this.http.get<Response>(
      environment.url_api_v + 'drivers/getUser/' + id
    );
  }

  removeTSO(id: number) {
    return this.http.get(environment.url_api_v + 'drivers/removeTSO/' + id);
  }

  getDriverIntegration() {
    return this.http.get(this.resource + '/active/1');
  }

  storeDriverIntegration(data: any[]) {
    return this.http.post<Response>(this.resource + '/storeDriverIntegration', JSON.stringify(data));
  }

  deleteIdsTSODriver() {
    return this.http.get(this.resource + '/deleteIdsTSODriver/all');
  }

  deleteIdTSODriver(id: number) {
    return this.http.get(this.resource + '/deleteIdTSODriver/' + id);
  }

  getDriversRoutesRelations(driver_id: number) {
    return this.http.get(this.resource + '/relations/' + driver_id);
  }

  syncTimeCard(code: any): Observable<any> {
    return this.http.put(this.resource + '/sundialTimeId/' + code, {});
  }

  updateRoutesDriver(data: any, id: number): Observable<Response> {
    return this.http.put<Response>(this.resource + '/routes/' + id, data);
  }

  getZones(): Observable<any> {
    return this.http.get(environment.url_api_v + 'zones');
  }

  putTerminateDriver(data: any) {
    return this.http.put<Response>(`${environment.url_api_v}drivers/manageEndDate/update`, data);
  }

  getSignature(idDriver: any) {
    return this.http.get<Response>(`${environment.url_api_v}drivers/signature/${idDriver}`);
  }

  saveSignature(data: any) {
    return this.http.put<Response>(`${environment.url_api_v}drivers/signature/stores`, data);
  }

  getLastTodoMeter(vehicle_id: number) {
    return this.http.get(`${environment.url_api_v}vehicles/lastodometer/${vehicle_id}`)
  }

  getRecordOdometer(vehicle_id: number, month: number, year: number) {
    return this.http.get(`${environment.url_api_v}vehicles/history/odometer/${vehicle_id}/${month}/${year}`);
  }

  getDriverCurrentJob(currentDate: string, id: number) {
    return this.http.get(`${environment.url_api_v}drivers/actualWork/${currentDate}/${id}`);
  }
}
