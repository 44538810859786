import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { InvoiceService } from '../../../../services/billing/invoice.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-fueled-uploated-files',
  templateUrl: 'fueled-uploated-files.component.html',
  styleUrls: ['./fueled-uploated-files.component.scss']
})

export class UploatedFilesFueledComponent implements OnInit {
  busy: Subscription[] = [];
  gridOptions: GridOptions;
  gridData: any[];

  constructor(private invoiceService: InvoiceService) {
    this.onInitGrid();
  }

  ngOnInit() {}

  onInitGrid() {
    this.gridOptions = <GridOptions>{
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      columnDefs: [
        { headerName: 'User', width: 170, field: 'name' },
        { headerName: 'Start date', width: 180, field: 'start_date' },
        { headerName: 'End date', width: 180, field: 'end_date' },
        { headerName: 'File', field: 'file', width: 200 },
        {
          headerName: '',
          width: 60,
          colId: 'btnDownload',
          cellRenderer: () => {
            return `
            <div>
              <button class="btn btn-sm btn-primary" style="width: 30px"><i class="fa fa-download"></i></button>
            </div>`;
          }
        }
      ],
      onGridReady: () => {
        this.invoiceService.getUploadedFueledFiles().subscribe(
          resp => {
            this.gridData = resp.data;
          },
          error => {
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: error.message
            });
          }
        );
      }
    };
    this.gridData = [];
  }

  onCellClicked(event) {
    if (event.colDef.colId === 'btnDownload') {
      window.open(event.data.path, '_blank');
    }
  }
}
