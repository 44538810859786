import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { CompanyService, TsoConfigService } from '../../../../../services/services.index';

interface TsoConfig {
  id?: number;
  user_id?: number;
  user: string;
  password: string;
  company_id: number;
  city: string;
  client_id: number;
}

@Component({
  selector: 'app-tso-config',
  templateUrl: './tso-config.component.html',
  styleUrls: ['./tso-config.component.scss']
})
export class TsoConfigComponent implements OnInit, OnChanges, OnDestroy {
  @Input() citieSekected = 0;
  @Input() company_id: number;
  public busy: Subscription;
  users: any[];
  public apiResponse: any;
  gridUsersTSO: GridOptions;
  formData: FormGroup;

  constructor(
    private fb: FormBuilder,
    private _serviceTsoConfig: TsoConfigService,
    private _serviceCompany: CompanyService,
  ) {
    this.loadForm();
    this.gridUsersTSO = <GridOptions>{
      enableSorting: true,
      sortingOrder: ['desc', 'asc', null],
      onGridReady: () => {
        this.loadData();
      }
    };

    this.gridUsersTSO.columnDefs = [
      {headerName: 'ID', field: 'id', width: 50},
      {headerName: 'Name', field: 'user_ta.name', width: 180},
      {headerName: 'User', field: 'user', width: 140},
      {headerName: 'Password', field: 'password', width: 90},
    ];

  }

  ngOnInit() {
  }

  ngOnDestroy() {
    return this.busy ? this.busy.unsubscribe() : null;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['company_id']) {
      this.getAllUserForCompany();
    }
  }

  loadForm() {
    this.formData = this.fb.group({
      id: [''],
      user_id: ['', Validators.required],
      user: ['', Validators.required],
      password: ['', Validators.required],
      client_id: [''],
    });
  }

  onSave() {
    const data = Object.assign(this.formData.getRawValue());
    data.client_id = this.citieSekected;
    data.company_id = -1;
    data.city = '-1';
    if (!data.id) {
      delete data.id;
      this.save(data);
    } else {
      this.update(data);
    }
  }

  save(data) {
    this.busy = this._serviceTsoConfig.storeTsoConfig(data).subscribe(
      res => {
        this.apiResponse = res;
        if (this.apiResponse.transaction) {
          Swal.fire(
            'Success!',
            this.apiResponse.message,
            'success'
          ).then(() => {
            this.loadData();
          });
        } else {
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: this.apiResponse.message + '.<br> Log : ' + this.apiResponse.master.result
          });
        }
      }, error1 => {
        alert('Error : ' + JSON.stringify(error1));
      }
    );
  }

  update(data) {
    this.busy = this._serviceTsoConfig.updateTsoConfig(data, data.id).subscribe(
      res => {
        this.apiResponse = res;
        if (this.apiResponse.transaction) {
          Swal.fire(
            'Success!',
            this.apiResponse.message,
            'success'
          ).then(() => {
            this.loadData();
          });
        } else {
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: this.apiResponse.message + '.<br> Log : ' + this.apiResponse.master.result
          });
        }
      }, error1 => {
        alert('Error : ' + JSON.stringify(error1));
      }
    );
  }

  /**
   *
   */
  loadData() {
    if (this.citieSekected !== 0) {
      this.busy = this._serviceTsoConfig.getTsoConfigForCity(this.citieSekected).subscribe(
        data => {
          if (this.gridUsersTSO.api) {
            this.gridUsersTSO.api.setRowData(data);
            this.loadForm();
          }
        }
      );
    }
  }

  getAllUserForCompany() {
    this.busy = this._serviceCompany.getAllUsersForCompany(this.company_id).subscribe(
      (data: any) => {
        this.users = data;
      }
    );
  }

  editConfig($event) {
    this.formData.patchValue({
      id: $event.data.id,
      user_id: $event.data.user_id,
      user: $event.data.user,
      password: $event.data.password,
      client_id: $event.data.client_id,
    });
  }

}
