export class Driver {
  id: number;
  last_name: string;
  first_name: string;
  mi?: any;
  ssn?: any;
  date_birth?: any;
  gender?: any;
  driver_license?: any;
  license_expiry?: any;
  email: string;
  street_address?: any;
  street_address_two?: any;
  city?: any;
  state?: any;
  zip?: any;
  phone?: any;
  active: string;
  created_at: string;
  updated_at: string;
}

export class Route {
  id: number;
  client_id: string;
  division_id: string;
  route_code: string;
  description: string;
  active: string;
  created_at: string;
  updated_at: string;
}


export class Vehicle {
  id: number;
  description: string;
  capacity: string;
  active: string;
  vehicle_type_id: string;
  created_at: string;
  updated_at: string;
}

export class DriverManifest {
  id: number;
  driver_id: string;
  vehicle_id: string;
  dateOfService: string;
  client_id: string;
  route_block_id: string;
  actual_check_in: string;
  actual_drop_off: string;
  leave_yard_time: string;
  leave_yard_odometer: string;
  first_stop_time: string;
  first_stop_odometer: string;
  last_stop_time: string;
  last_stop_odometer: string;
  return_to_yard_time: string;
  return_to_yard_odometer: string;
  amb: string;
  wc: string;
  str: string;
  comments?: any;
  driver: any;
  vehicle: any;
  client: any;
  route_block: any;
  changed_blocks: boolean;
  updateReasons: any;
  exclude?: boolean;
  schedule_detail_id?: number;
  interruption_start?: boolean;
  interruption_end?: boolean;
  start_billing_category_id?: number;
  end_billing_category_id?: number;
  interruption?: boolean;
  billing_status: any;
  billing_status_id?: number;
}


export class Client {
  id: number;
  client_name: string;
  contact: string;
  position: string;
  address_one: string;
  address_two?: any;
  city: string;
  state: string;
  zip: string;
  active: string;
  created_at: string;
  updated_at: string;
  company_id: string;
}


export class RouteBlocks {
  id: number;
  route_id: string;
  description: string;
  created_at: string;
  updated_at: string;
  deleted_at?: any;
  block_code: string;
  route_block_details: any[];
  route: Route;
}

export class Stop {
  id: number;
  stop: number;
  amb: number;
  wc: number;
  bk: number;
}
