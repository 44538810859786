import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {CONSTANT, CONSTANTS} from '../../app.constants';
import { BaseService } from '../../core/BaseService';
import {IResponse} from '../../CORE/interfaces/response.interface';

@Injectable()
export class CompanyService extends BaseService {
  protected resource: string;

  constructor(private http: HttpClient) {
    super();
    this.resource = environment.url_api_v + CONSTANTS.COMPANIES;
  }

  /**
   * @description Return all companies
   * @returns {Observable<any>}
   */
  getCompanies(): Observable<any> {
    return this.http.get<any>(this.resource).pipe(catchError(this.handleError));
  }

  /**
   * @description Save a company
   * @param data
   * @returns {Observable<Response>}
   */
  storeCompany(data: any): Observable<Response> {
    return this.http.post<Response>(this.resource, JSON.stringify(data));
  }

  /**
   * @description Update a Company
   * @param data
   * @returns {Observable<Response>}
   */
  updateCompany(data: any, id: number): Observable<Response> {
    return this.http.put<Response>(
      this.resource + '/' + id,
      JSON.stringify(data)
    );
  }

  /**
   * @description Get a company
   * @param {number} id
   * @returns {Observable<any>}
   */
  getCompany(id: number): Observable<any> {
    return this.http.get(this.resource + '/' + id);
  }

  /**
   * @description
   * @param {boolean} status
   * @returns {Observable<any>}
   */
  getCompanyStatus(status: boolean): Observable<any> {
    if (status) {
      return this.http.get(this.resource);
    } else {
      return this.http.get(this.resource + '/active/1');
    }
  }

  /**
   * @description
   */
  changeCompanyStatus(data: any, id: number): Observable<Response> {
    return this.http.put<Response>(
      environment.url_api_v + 'company/active/' + id,
      JSON.stringify(data)
    );
  }

  getCompaniesForUser(): Observable<any> {
    return this.http.get<any>(this.resource + '/user/companies');
  }


  getAllUsersForCompany(company_id: number) {
    return this.http.get(environment.url_api_v + CONSTANTS.COMPANIES + '/' + CONSTANTS.USER + '/'  + company_id);
  }
}
