import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatorComponent } from './paginator/paginator.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [PaginatorComponent],
  exports: [
    PaginatorComponent 
  ]
})
export class paginatorModule { }
