import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { ClientService } from '../../../../../services/fleet/client.service';
import * as moment from 'moment';
import { getDate } from 'ngx-bootstrap/chronos/utils/date-getters';

@Component({
  selector: 'app-po-schedule',
  templateUrl: './po-schedule.component.html',
  styleUrls: ['./po-schedule.component.scss']
})
export class PoScheduleComponent implements OnInit, OnDestroy {
  grid: GridOptions;
  addSchedule = false;
  @Input() clientId?: number;
  busy: Subscription;
  showStatus = true;
  dataEdit: any;

  constructor(private clientService: ClientService) {
    this.initGrid();
  }

  ngOnInit() {
    this.loadData();
  }

  ngOnDestroy() {
    this.busy ? this.busy.unsubscribe() : null;
  }

  initGrid() {
    this.grid = <GridOptions>{
      enableSorting: true,
      sortingOrder: ['desc', 'asc', null],
      onGridReady: () => {},
      columnDefs: [
        { headerName: 'Value', field: 'value', width: 120 },
        {
          headerName: 'Start date',
          field: 'start_date',
          valueGetter: params => {
            return params.data.start_date ? moment(params.data.start_date).format('YYYY-MM-DD') : '';
          },
          width: 100
        },
        {
          headerName: 'End date',
          field: 'end_date',
          width: 100,
          valueGetter: params => {
            return params.data.end_date ? moment(params.data.end_date).format('YYYY-MM-DD') : '';
          }
        },
        {
          headerName: 'Expired',
          field: 'expired',
          width: 90,
          cellRenderer: params => {
            return params.data.expired ? 'Yes' : 'No';
          }
        },
        {
          headerName: 'Status',
          field: 'active',
          width: 90,
          cellRenderer: params => {
            return params.data.active
              ? '<span class="label label-success" style="margin-top: 4px; position: absolute;">Active</span>'
              : '<span class="label label-danger" style="margin-top: 4px; position: absolute;">Disabled</span>';
          }
        }
      ]
    };
  }

  loadData() {
    this.busy = this.clientService.getPoScheduleByClient(this.clientId, this.showStatus).subscribe((resp: any) => {
      this.grid.api.setRowData(resp);
    });
  }

  onReload(event) {
    if (event) {
      this.addSchedule = !this.addSchedule;
      this.loadData();
      this.dataEdit = undefined;
    }
  }

  onChangeStatus() {
    this.showStatus = !this.showStatus;
    this.loadData();
  }

  onRowDoubleClicked(event: any) {
    this.addSchedule = !this.addSchedule;
    this.dataEdit = event.data;
  }
}
