import { Component, OnInit, ViewChild } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { BsModalComponent } from 'ng2-bs3-modal';
import { DashboardLsfDataService } from './../dashboard-lsf.data.service';
import {Subscription} from 'rxjs/Rx';
import {AdminDataService} from '../../manifests/components/driver-manifest/driver.data.service';
import {Client} from '../../manifests/components/driver-manifest/models';
import {DriverService} from '../../../services/fleet/driver.service';
import {VehicleService} from '../../../services/fleet/vehicle.service';
import {SearchTimecardComponent} from '../../payroll/driver-master-schedule/components/search-timecard/search-timecard.component';
import * as moment from 'moment';

@Component({
  selector: 'app-dashboard-schedule',
  templateUrl: './dashboard-schedule.component.html',
  styleUrls: ['./dashboard-schedule.component.scss'],
  providers: [
    AdminDataService,
    DriverService,
    VehicleService
  ],
})

export class DashboardScheduleComponent implements OnInit {
  @ViewChild('modal') modal: BsModalComponent;
  @ViewChild('times') times: SearchTimecardComponent;

  public busy: Subscription;

  public clients: Client;

  gridEmployee: GridOptions;
  driverShedule = '';
  totals = {
    info: {},
    total_hours_regular: '00:00',
    total_hours_o: '00:00',
    total_schedule_time: '00:00',
    total_remaining_time: '00:00',
    total: 0,
  };

  dropdownSettings: any = {
    singleSelection: false,
    idField: 'id',
    textField: 'client_name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true
  };

  listClientsSelected: any[] = [];

  constructor(private  _dataService: AdminDataService,
    private dashboardLsfDataService: DashboardLsfDataService
  ) {
    this.setConfigGrid();
  }

  ngOnInit() {
    this.loadClients();
  }

  setConfigGrid() {
    this.gridEmployee = <GridOptions>{
      enableSorting: true,
      enableColResize : true,
      sortingOrder: ['desc', 'asc', null]
    };
    this.gridEmployee.columnDefs = [
      { headerName: 'ID', field: 'employee_id', width: 70 },
      { headerName: 'Employee', width: 180, field: 'employee_name' },
      { headerName: 'Reg', width: 70, cellStyle: {'text-align': 'center'}, field: 'reg' },
      { headerName: 'OT', width: 70, cellStyle: {'text-align': 'center'}, field: 'ot' },
      { headerName: 'Schedule Time', width: 120, cellStyle: {'text-align': 'center'}, field: 'scheduleTime' },
      { headerName: 'Remaining Sch. Time ', width: 150, cellStyle: {'text-align': 'center'}, field: 'remaining_time' },
      { headerName: 'Weekly Warning  ', width: 140,
        field: 'percentage',
        cellRenderer: params => {
          let color = 'progress-bar-success';
          if (params.data.color === 2) { color = 'progress-bar-warning'; }
          if (params.data.color === 3) { color = 'progress-bar-danger'; }
          return `
            <div class="progress">
              <div class="progress-bar ${color}" role="progressbar" aria-valuenow="2" aria-valuemin="0" aria-valuemax="100" style="width: ${params.data.percentage > 100 ? 100 : params.data.percentage}%;">
                <b class='text-center' style='color: #3d3d3d;'>${params.data.percentage}%</b>
              </div>
            </div>
          `;
        }
      }
    ];
    this.gridEmployee.onRowDoubleClicked = params => {
      this.driverShedule = params.data.employee_name;
      this.modal.open();
      this.dashboardLsfDataService.viewSsheduleSelected$.emit({data: params.data, info: this.totals.info});
    };
  }

  changeDataGrid(data) {
    this.gridEmployee.api.setRowData(data.data);
    this.totals = {
      info: data.info,
      total_hours_regular: data.totals.total_hours_regular,
      total_hours_o: data.totals.total_hours_o,
      total_schedule_time: data.totals.total_schedule_time,
      total_remaining_time: data.totals.total_remaining_time,
      total: data.data.length,
    };
  }

  loadClients() {
    this.busy = this._dataService.getData('clients/active/1').subscribe(
      (response: any) => {
        let list: any;

        const blank = {
          id : '',
          client_name: 'Empty'
        };

        list = response.data;
        list.unshift(blank);

        this.clients = list;
      }
    );
  }

  onItemSelect(event) {
    const me = this;
    setTimeout(function(){
      me.times.loadInfo();
    }, 500);
  }

  onItemSelectAll(event) {
    const me = this;
    me.listClientsSelected = event;

    setTimeout(function(){
      me.times.loadInfo();
    }, 500);
  }

  onItemDeSelect(event) {
    const me = this;
    me.listClientsSelected = [];

    setTimeout(function(){
      me.times.loadInfo();
    }, 500);
  }
}
