import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { TabsetComponent } from 'ngx-bootstrap';
import { forkJoin, Subscription } from 'rxjs';
import { DriverService } from '../../../../../services/services.index';
import { AdminDataService } from './../driver.data.service';
import { Client, Driver, DriverManifest, RouteBlocks, Vehicle } from './../models';
import { NgForm, NgModel } from '@angular/forms';
import { BillingCategoriesService } from '../../../../../services/parameters/billing-categories.service';
import { DriverManifestService } from '../../../../../services/manifest/driver-manifest.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { BsModalComponent } from 'ng2-bs3-modal';
import { SweetAlertHelper } from '../../../../../CORE/helpers/sweet-alert-helper.service';
import { ToastrService } from 'ngx-toastr';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-manifest-form',
  templateUrl: './manifest-form.component.html',
  styleUrls: ['./manifest-form.component.scss']
})
export class ManifestFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() set driver_manifest_id(id) {
    if (id) {
      this.loadDataOne(id);
    }
  }
  @Input() showVerifyBtn?: boolean;
  @Input() reload?: any;
  @Output() onReloadData?= new EventEmitter<any>();
  @Output() onVerifyManifest?= new EventEmitter<any>();
  @Output() onBilled?= new EventEmitter<any>();

  @ViewChild('staticTabs') staticTabs: TabsetComponent;
  @ViewChild('modalForm') modalForm: NgForm;
  @ViewChild('dateOfService') dateOfService: NgModel;
  @ViewChild('inp_client_id') inp_client_id: NgModel;

  @ViewChild('modalHistory') modalHistory: BsModalComponent;
  @ViewChild('modalSummaryWorkday') modalSummaryWorkday: BsModalComponent;

  busy: Subscription;
  busyDetails: Subscription;
  is_execute = false;
  lockClientInput = false;
  skipOdometerDiff = false;
  drivers: Array<Driver> = [];
  routeBlocks: Array<RouteBlocks> = [];
  vehicles: Vehicle[] = [];
  clients: Client[] = [];
  driverManifestEdit = 0;
  driver_manifest: DriverManifest = new DriverManifest();
  apiResponse: any;
  currentDate: any;
  maxDate = new Date();
  driver_id: any = '';
  client_id: any = '';
  route_id: any = '';
  vehicle_id: any = '';
  time_format = [/[0-9]/, /\d/, ':', /\d/, /\d/];
  lock = true;
  errorDate;
  errorGeneral: boolean;
  lastTodoMeter: any = [];

  modalVehicleId: number;
  modalManifestId: number;
  modalDateOfService: any;

  /**
   * Variables para manejas el show de los errores en los campos Times de (Actual Time)
   * @type {boolean}
   */
  leave_yard_time_boolen;
  validation_check_in_leave_yard_boolean;
  validation_check_out_return_yard_boolean;
  first_stop_time_boolen;
  last_stop_time_boolen;
  return_to_yard_time_boolen: boolean;

  /**
   * Variables para majar el show de los errores en los campor Odometer
   * @type {boolean}
   */
  leave_yard_time_odometer_boolen;
  first_stop_time_odometer_boolen;
  last_stop_time_odometer_boolen;
  return_to_yard_time_odometer_boolen: boolean;

  /**
   * @description Esta variable almacena el mensaje que se muestra en la propiedad Title del icono
   * que muestra el error.
   * @type {string}
   */
  title_error = 'Unable to add records, please review and try again.';

  /**
   * @description Variables para manejar el show de los errores en los campos Check In y Check Out
   * @type {boolean}
   */
  check_in_boolen;
  check_out_boolen: boolean;
  isMDT = false;
  route_blocks_select: any = 0;
  odometer_validation_1;
  odometer_validation_2;
  odometer_validation_3: string;

  rangeDate: any;
  driverManifestId: number;
  categoriesBilling = [];

  schDriver: any = {};
  editing = false;

  billed_edit = false;

  constructor(
    private sweetAlertHelper: SweetAlertHelper,
    private _dataService: AdminDataService,
    private _sanitizer: DomSanitizer,
    private _serviceDriver: DriverService,
    private billingCategoriesService: BillingCategoriesService,
    private driverManifestService: DriverManifestService,
    private ngxPermissionsService: NgxPermissionsService,
    private toastr: ToastrService,
  ) {

    this.leave_yard_time_boolen = false;
    this.first_stop_time_boolen = false;
    this.last_stop_time_boolen = false;
    this.return_to_yard_time_boolen = false;
    this.validation_check_in_leave_yard_boolean = false;
    this.validation_check_out_return_yard_boolean = false;

    this.leave_yard_time_odometer_boolen = false;
    this.first_stop_time_odometer_boolen = false;
    this.last_stop_time_odometer_boolen = false;
    this.return_to_yard_time_odometer_boolen = false;

    this.check_in_boolen = false;
    this.check_out_boolen = false;

    this.odometer_validation_1 = 'NaN';
    this.odometer_validation_2 = 'NaN';
    this.odometer_validation_3 = 'NaN';

    this.errorDate = false;
    this.errorGeneral = false;
  }

  ngOnInit() {
    this.loadDataInit();
    this.loadCategoriesBilling();
    this.currentDate = moment().local().format('YYYY-MM-DD');
    this.driver_manifest.dateOfService = this.currentDate;

    this.busyDetails = this.driverManifestService.load_details$.subscribe((resp) => {
      this.loadDataOne(resp.data.driver_manifest_id);
    });
    this.suscribeObservers();
  }

  ngAfterViewInit() {
    (<any>$('#side-menu')).metisMenu();
    (<any>$('#driver_id')).focus();
    (<any>$('#date_filter')).val(this.getFechaActualFormaDos());
  }

  ngOnDestroy(): void {
    this.busyDetails.unsubscribe();
    this.busy.unsubscribe();
    this.resetTimerAndOdometer();
  }

  loadDataInit() {
    this.busy = forkJoin([
      this._dataService.getData('drivers/forManifest/active'),
      this._dataService.getData('vehicles/driverManifest/lists'),
      this._dataService.getData('clients/driverManifest/lists')
    ]).subscribe((resp: any) => {
      this.drivers = resp[0].data;
      this.vehicles = resp[1].data;
      this.clients = resp[2].data;
    });
  }

  suscribeObservers() {
    this.driverManifestService.resetFormManifest$.subscribe((resp) => {
      this.onNoChanges();
    }),
    this._dataService.saveSetPrimary$.subscribe((driver_data: number) => {
      if (driver_data != null) {
        this.loadDataOne(driver_data);
      }
    });
  }

  async loadCategoriesBilling() {
    this.billingCategoriesService.getBillingCategories().subscribe((res: any) => {
      this.categoriesBilling = res.data.filter(m => m.active);
    });
  }

  myValueFormatter(data: any): string {
    const html = data.last_name + ' ' + data.first_name;
    return html;
  }

  autocompleListFormatter = (data: any) => {
    const html = `<span>${data.last_name} ${data.first_name} </span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

  autocompleListFormatterClient = (data: any) => {
    const html = `<span>${data.client_name} </span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

  autocompleListFormatterRoute = (data: any) => {
    const html = `<span>${data.isMDT ? `${data.route.description} - ${data.description}` : `${data.block_code} - ${data.description}`}</span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }
  myValueFormatterRoute(data: any): string {
    const html = data.isMDT ? `${data.route.description} - ${data.description}` : `${data.block_code} - ${data.description}`;
    return html;
  }
  valueChangedRoute(event) {
    this.driver_manifest.route_block_id = event ? event.id : null;
  }

  autocompleListFormatterVehicle = (data: any) => {
    const html = `<span>${data.description}</span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }
  myValueFormatterVehicle(data: any): string {
    const html = `${data.description}`;
    return html;
  }

  valueChangedVehicle(event) {
    this.driver_manifest.vehicle_id = event ? event.id : null;
    this.modalManifestId = null;
    if (event && event.id) {
      this.onLoadLastOdometer(event.id);
      this.modalVehicleId = event.id;
    }
  }

  loadRouteBlocks(client_id: any) {
    this.busy = this._dataService.getData('routeBlocks/byRouteShort/' + client_id).subscribe(
      (data: any) => {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          data[i].isMDT = this.isMDT;
          data[i].full_description = `${element.isMDT ? `${element.route.description} - ${element.description}` : `${element.block_code} - ${element.description}`}`;
        }
        this.routeBlocks = data;
        this.postLoadaSchedule();
      }
    );
  }

  onOutOdometer($event) {
    let r;
    switch ($event.target.id) {
      case 'first_stop_odometer':
        // Validar que la diferencia entre el leave yard odometer y el first stop odometer no puede ser mayor que 100
        r = this.validatedRange(this.driver_manifest.first_stop_odometer, this.driver_manifest.leave_yard_odometer, 100);
        this.odometer_validation_1 = r.difference;
        if (!r.operation || (r.difference < 0)) {
          $('#odometer_validation_1').removeClass('label-default');
          $('#odometer_validation_1').addClass('label-danger');
        } else {
          $('#odometer_validation_1').removeClass('label-danger');
          $('#odometer_validation_1').addClass('label-default');
        }
        break;
      case 'last_stop_odometer':
        // Validar que la diferencia entre el first stop odometer y el last stop odometer no puede ser mas de 400.
        r = this.validatedRange(this.driver_manifest.last_stop_odometer, this.driver_manifest.first_stop_odometer, 400);
        this.odometer_validation_2 = r.difference;
        if (!r.operation || (r.difference < 0)) {
          $('#odometer_validation_2').removeClass('label-default');
          $('#odometer_validation_2').addClass('label-danger');
        } else {
          $('#odometer_validation_2').removeClass('label-danger');
          $('#odometer_validation_2').addClass('label-default');
        }
        break;
      case 'return_to_yard_odometer':
        // Validar que la diferencia entre el last stop odometer y el return to yard odometer no puede ser mayor a 100
        r = this.validatedRange(this.driver_manifest.return_to_yard_odometer, this.driver_manifest.last_stop_odometer, 100);
        this.odometer_validation_3 = r.difference;
        if (!r.operation || (r.difference < 0)) {
          $('#odometer_validation_3').removeClass('label-default');
          $('#odometer_validation_3').addClass('label-danger');
        } else {
          $('#odometer_validation_3').removeClass('label-danger');
          $('#odometer_validation_3').addClass('label-default');
        }
        break;
      default:
    }
  }

  onSave(formValid) {
    if (!this.is_execute) {
      this.is_execute = true;
      if (formValid) {
        this.validateMileage().then((resp: boolean) => {
          if (resp) {
            this.validatePassengers();
          }
        });
      } else {
        this.sweetAlertHelper.createCustomAlert({
          title: 'Warning',
          text: 'Please fill all required fields',
          type: 'warning'
        }).then();
      }
    }
    this.is_execute = false;
  }
  resetTimerAndOdometer() {
    this.leave_yard_time_boolen = false;
    this.first_stop_time_boolen = false;
    this.last_stop_time_boolen = false;
    this.return_to_yard_time_boolen = false;
    this.validation_check_in_leave_yard_boolean = false;
    this.validation_check_out_return_yard_boolean = false;

    this.leave_yard_time_odometer_boolen = false;
    this.first_stop_time_odometer_boolen = false;
    this.last_stop_time_odometer_boolen = false;
    this.return_to_yard_time_odometer_boolen = false;

    this.check_in_boolen = false;
    this.check_out_boolen = false;
    this.changeColorTime();
  }
  /**
   * @description Este metodo se encarga de validar los valores de los campor Times, si se precenta algun
   * error en en la secuencia de menor a mayor en los campor de times retornamos un valor boolena, true en caso
   * de que se precenter un error y false en caso contrario.
   * @returns {boolean}
   */
  verificarTimer() {
    const minimo = this.convertTimeToInt(this.driver_manifest.actual_check_in);
    const maximo = this.convertTimeToInt(this.driver_manifest.actual_drop_off);
    const num1 = this.convertTimeToInt(this.driver_manifest.leave_yard_time);
    const num2 = this.convertTimeToInt(this.driver_manifest.first_stop_time);
    const num3 = this.convertTimeToInt(this.driver_manifest.last_stop_time);
    const num4 = this.convertTimeToInt(this.driver_manifest.return_to_yard_time);

    if (maximo <= minimo) {
      this.check_out_boolen = true;
    } else {
      this.check_out_boolen = false;
    }

    if (num1 >= minimo && num1 < maximo) {
      this.validation_check_in_leave_yard_boolean = false;
      this.leave_yard_time_boolen = false;
    } else {
      if (num1 <= minimo) {
        this.validation_check_in_leave_yard_boolean = true;
        this.sweetAlertHelper.createCustomAlert({
          title: 'Warning',
          text: 'CheckIn time cannot be greater than LeaveYard Time',
          type: 'warning'
        });
      } else {
        this.validation_check_in_leave_yard_boolean = false;
      }
      this.leave_yard_time_boolen = true;
    }

    if (num2 >= minimo && num2 < maximo) {
      if (num2 >= num1) {
        this.first_stop_time_boolen = false;
      } else {
        this.first_stop_time_boolen = true;
      }
    } else {
      this.first_stop_time_boolen = true;
    }

    if (num3 >= minimo && num3 < maximo) {
      if (num3 > num2) {
        this.last_stop_time_boolen = false;
      } else {
        this.last_stop_time_boolen = true;
      }
    } else {
      this.last_stop_time_boolen = true;
    }

    if (num4 >= minimo && num4 < maximo) {
      this.validation_check_out_return_yard_boolean = false;
      if (num4 >= num3) {
        this.return_to_yard_time_boolen = false;
      } else {
        this.return_to_yard_time_boolen = true;
      }
    } else {
      if (maximo <= num4) {
        this.validation_check_out_return_yard_boolean = true;
        this.sweetAlertHelper.createCustomAlert({
          title: 'Warning',
          text: 'ClockOut Time cannot be less than return to Yard Time',
          type: 'warning'
        });
      } else {
        this.validation_check_out_return_yard_boolean = false;
      }
      this.return_to_yard_time_boolen = true;
    }

    this.changeColorTime();

    if (
      this.leave_yard_time_boolen ||
      this.validation_check_in_leave_yard_boolean ||
      this.first_stop_time_boolen ||
      this.last_stop_time_boolen ||
      this.return_to_yard_time_boolen ||
      this.check_out_boolen
    ) {
      return false;
    } else {
      return true;
    }
  }

  /***
   * @description
   */
  changeColorTime() {
    if (this.leave_yard_time_boolen || this.validation_check_in_leave_yard_boolean) {
      $('#leave_yard_time').css('color', 'red');
    } else {
      $('#leave_yard_time').css('color', 'black');
    }
    if (this.first_stop_time_boolen) {
      $('#first_stop_time').css('color', 'red');
    } else {
      $('#first_stop_time').css('color', 'black');
    }
    if (this.last_stop_time_boolen) {
      $('#last_stop_time').css('color', 'red');
    } else {
      $('#last_stop_time').css('color', 'black');
    }
    if (this.return_to_yard_time_boolen || this.validation_check_out_return_yard_boolean) {
      $('#return_to_yard_time').css('color', 'red');
    } else {
      $('#return_to_yard_time').css('color', 'black');
    }
    if (this.check_out_boolen) {
      $('#actual_check_out').css('color', 'red');
    } else {
      $('#actual_check_out').css('color', 'black');
    }
    if (this.leave_yard_time_odometer_boolen) {
      $('#leave_yard_odometer').css('color', 'red');
    } else {
      $('#leave_yard_odometer').css('color', 'black');
    }
    if (this.first_stop_time_odometer_boolen) {
      $('#first_stop_odometer').css('color', 'red');
    } else {
      $('#first_stop_odometer').css('color', 'black');
    }
    if (this.last_stop_time_odometer_boolen) {
      $('#last_stop_odometer').css('color', 'red');
    } else {
      $('#last_stop_odometer').css('color', 'black');
    }
    if (this.return_to_yard_time_odometer_boolen) {
      $('#return_to_yard_odometer').css('color', 'red');
    } else {
      $('#return_to_yard_odometer').css('color', 'black');
    }
  }

  /**
   * @description Este metodo se encarga de convertir un time en numero
   * @param {string} value
   * @returns {string}
   */
  convertTimeToInt(value: string) {
    const t = value.split(':');
    return t[0] + t[1];
  }

  /**
   * @description Este metodo se encarga de validar los valores de los campor Odometer, si se precenta algun
   * error en en la secuencia de menor a mayor en los campor de odometer retornamos un valor boolena, true en caso
   * de que se precenter un error y false en caso contrario.
   * @returns {boolean}
   */
  verificarOdometer() {
    const num1 = this.driver_manifest.leave_yard_odometer;
    const num2 = this.driver_manifest.first_stop_odometer;
    const num3 = this.driver_manifest.last_stop_odometer;
    const num4 = this.driver_manifest.return_to_yard_odometer;

    if (num2 >= num1 || this.skipOdometerDiff) {
      this.first_stop_time_odometer_boolen = false;
    } else {
      this.first_stop_time_odometer_boolen = true;
    }

    if (num3 > num1 || this.skipOdometerDiff) {
      if (num3 > num2 || this.skipOdometerDiff) {
        this.last_stop_time_odometer_boolen = false;
      } else {
        this.last_stop_time_odometer_boolen = true;
      }
    } else {
      this.last_stop_time_odometer_boolen = true;
    }

    if (num4 > num1 || this.skipOdometerDiff) {
      if (num4 < num3 && this.skipOdometerDiff) {
        this.return_to_yard_time_odometer_boolen = true;
      } else {
        this.return_to_yard_time_odometer_boolen = false;
      }
    } else {
      this.return_to_yard_time_odometer_boolen = true;
    }

    if (
      (
        this.first_stop_time_odometer_boolen ||
        this.last_stop_time_odometer_boolen ||
        this.return_to_yard_time_odometer_boolen
      ) && !this.skipOdometerDiff
    ) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * @description Este metodo retorna la fecha actual en e formato requerido en este momento
   * @returns {string}
   */
  getFechaActualFormaDos() {
    const dt = new Date();
    const month = dt.getMonth() + 1;
    const day = dt.getDate();
    const year = dt.getFullYear();
    const cadena = (year + '-' + month + '-' + day);
    return cadena;
  }

  onNoChanges() {
    this.currentDate = this.driver_manifest.dateOfService;
    this.driverManifestEdit = 0;
    this.driver_manifest = new DriverManifest();
    this.currentDate = moment().local().format('YYYY-MM-DD');
    this.driver_manifest.dateOfService = this.currentDate;
    this.driver_id = '';
    this.client_id = '';
    this.route_id = '';
    this.vehicle_id = '';
    this.odometer_validation_1 = 'NaN';
    this.odometer_validation_2 = 'NaN';
    this.odometer_validation_3 = 'NaN';
    this.schDriver = {};
    this.changeClient(0);
    this.driverManifestId = 0;
    this.modalForm.resetForm();
    this.onReloadData.emit();
    this.resetTimerAndOdometer();
  }

  /**
   * @description Este metodo se ejecuta cuando se cambia el valor del campor Cliente, este metodo toma el id y carga la informacion
   * @param {number} value
   */
  changeClient(value: number) {
    this.loadRouteBlocks(value);
  }

  valueChangedDriver(newVal: Driver) {
    this.driver_manifest.driver_id = newVal.id.toString();
  }

  valueChangedClient(newVal: Client, isLoad = false) {
    if (newVal.id == 43 || newVal.client_name == 'MDT') {
      this.isMDT = true;
    } else {
      this.isMDT = false;
    }
    this.driver_manifest.client_id = newVal.id.toString();
    if (!isLoad) {
      this.driver_manifest.route_block_id = '';
      this.route_id = '';
    }
    this.changeClient(newVal.id);
  }

  /**
   * description: Esta funcion, Validar que la diferencia entre el
   * minuendo y el sustraendo no sea mayor a un validador numerico dado
   */

  validatedRange(minuendo: any, sustraendo: any, validador: any) {
    const min = parseInt(minuendo);
    const sus = parseInt(sustraendo);
    const val = parseInt(validador);
    const result = (min - sus);
    const r = (result <= val) ? true : false;
    return { operation: r, difference: result };
  }

  /**
   * description: Este metodo se invoca para validar que los 3 odometer puedan tengan los valores correctos.
   */
  getValidatedRange() {
    if (!this.driver_manifest.exclude) {
      // Validar que la diferencia entre el leave yard odometer y el first stop odometer no puede ser mayor que 100
      const r1 = (this.validatedRange(this.driver_manifest.first_stop_odometer, this.driver_manifest.leave_yard_odometer, 100).operation);

      // Validar que la diferencia entre el first stop odometer y el last stop odometer no puede ser mas de 400.
      const r2 = (this.validatedRange(this.driver_manifest.last_stop_odometer, this.driver_manifest.first_stop_odometer, 400).operation);

      // Validar que la diferencia entre el last stop odometer y el return to yard odometer no puede ser mayor a 100
      const r3 = (this.validatedRange(this.driver_manifest.return_to_yard_odometer, this.driver_manifest.last_stop_odometer, 100).operation);

      return r1 && r2 && r3;
    } else {
      return true;
    }
  }

  /***
   * @description
   */
  lockClient() {
    if (this.client_id !== '') {
      this.lockClientInput = !this.lockClientInput;
    }
  }

  /***
   * @description Este metodo cierra el modal de creacion y edicion al momento de precionar la tecla Esc.
   * @param {KeyboardEvent} event
   */
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const x = event.keyCode;
    if (x === 27) {
      this.driver_manifest = new DriverManifest;
      this.modalForm.resetForm();
    }
  }

  changeLock() {
    this.lock = !this.lock;
  }

  selectTab(tabId: number) {
    this.staticTabs.tabs[tabId].active = true;
  }

  postLoadaSchedule() {
    const driver_id = this.driver_id ? this.driver_id.id : null;
    let dateOfService = this.driver_manifest.dateOfService;
    const vehicle_id = this.driver_manifest.vehicle_id;
    const route_block_id = this.driver_manifest.route_block_id;
    const afterDate = moment.utc(dateOfService, 'YYYY-MM-DD').isAfter(moment.utc());
    if (afterDate) {
      const currentDate = moment().local().format('YYYY-MM-DD');
      this.driver_manifest.dateOfService = currentDate;
      dateOfService = currentDate;
      this.sweetAlertHelper.createCustomAlert({
        title: 'Warning',
        text: 'The date cannot be greater than the current date',
        type: 'warning',
      });
    }

    this.validateExclude(this.driver_manifest.route_block_id);
    if (driver_id && dateOfService && vehicle_id && route_block_id) {
      this.busy = this._dataService.postLoadaSchedule(dateOfService, driver_id, vehicle_id, route_block_id).subscribe((data: any) => {
        if (data.data.length > 0) {
          this.schDriver.leave_yard_time = moment(data.data[0].leave_yard_time).format('HH:mm');
          this.schDriver.start_time = moment(data.data[0].start_time).format('HH:mm');
          this.schDriver.end_time = moment(data.data[0].end_time).format('HH:mm');
          this.schDriver.punch_out_time = moment(data.data[0].punch_out_time).format('HH:mm');
          this.driver_manifest.schedule_detail_id = data.data[0].id;
        } else {
          this.schDriver = {};
          this.driver_manifest.schedule_detail_id = null;
          this.sweetAlertHelper.createCustomAlert({
            title: 'Warning',
            text: 'This combination of data is not on schedule',
            type: 'warning',
          });
        }
      });
    }
  }

  /**
 * @description Este metodo recibe el id de un driver-manifest y carga la informacion del mismo en el ormulario para
 * su posterior edicion y actualizacion.
 * @param {number} id
 */
  loadDataOne(id: number): void {
    this.errorGeneral = false;
    this.resetTimerAndOdometer();
    this.driverManifestId = id;
    this.modalForm.resetForm();
    this.driver_manifest = new DriverManifest();
    this.billed_edit = false;
    this.busy = this._dataService.getData('driverManifest/' + id).subscribe(
      async (data: any) => {
        data.interruption = false;
        data.interruption_start = false;
        data.interruption_end = false;
        this.driver_manifest = data;
        this.driverManifestEdit = 1;
        const permission_edit_billeds = await this.ngxPermissionsService.hasPermission('update-manifest-billed');
        if (data.billing_status_id != 1 && !permission_edit_billeds) {
          this.billed_edit = true;
        }
        this.onBilled.emit(this.billed_edit);

        this.route_blocks_select = this.driver_manifest.route_block_id;

        this.valueChangedClient(this.driver_manifest.client, true);
        this.driver_id = this.driver_manifest.driver;
        this.client_id = this.driver_manifest.client;
        this.vehicle_id = this.driver_manifest.vehicle;
        this.route_id = this.isMDT ? `${this.driver_manifest.route_block.route.description} - ${this.driver_manifest.route_block.description}` : `${this.driver_manifest.route_block.block_code} - ${this.driver_manifest.route_block.description}`;
        this.onLoadLastOdometer(data.vehicle_id);
        this.modalManifestId = id;
        this.modalVehicleId = null;
        this.modalDateOfService = data.dateOfService;
      }
    );

  }

  /**
 * @description Este metodo recibe el vehicle_id de un driver-manifest y carga la informacion del mismo en el formulario.
 * @param {number} vehicle_id
 */

  onLoadLastOdometer(vehicle_id: number) {
    this._serviceDriver.getLastTodoMeter(vehicle_id).subscribe((result: any) => {
      if (result.data) {
        this.lastTodoMeter = result.data.return_to_yard_odometer;
      }
    })
  }

  loadParent() {
    this._dataService.reloadGridRightPanel$.emit({});
  }

  validateExclude(route_block_id: any) {
    if (this.driver_manifest.route_block_id) {
      const data: any = this.routeBlocks.filter((row: any) => {
        return row.id == route_block_id;
      })[0];
      this.driver_manifest.exclude = data.route.exclude;
    }
  }

  validatePassengers() {
    const total: number =
      Number(this.driver_manifest.amb) + Number(this.driver_manifest.wc) + Number(this.driver_manifest.str);
    if (total > 200) {
      this.sweetAlertHelper
        .createCustomAlert({
          title: 'Question',
          text: `The passenger count exceeds 200 passengers.','Do you still want to enter this passenger count?`,
          type: 'question'
        })
        .then(result => {
          if (result.value) {
            this.saveData();
          }
        });
    } else {
      this.saveData();
    }
  }

  changeFirst() {
    if (this.schDriver.start_time && this.driver_manifest.first_stop_time)
      this.validDateToSchedule(this.schDriver.start_time, this.driver_manifest.first_stop_time, 1);
  }

  changeLast() {
    if (this.schDriver.end_time && this.driver_manifest.last_stop_time)
      this.validDateToSchedule(this.schDriver.end_time, this.driver_manifest.last_stop_time, 2);
  }

  async validDateToSchedule(scheduleDate, dateTime, type) {
    const formatDate = 'YYYY-MM-DD HH:mm:ss';
    const time = moment.utc(`${this.driver_manifest.dateOfService} ${dateTime}:00`, formatDate);
    let minTime = moment.utc(`${this.driver_manifest.dateOfService} ${scheduleDate}:00`, formatDate);
    let maxTime = moment.utc(`${this.driver_manifest.dateOfService} ${scheduleDate}:00`, formatDate);
    let valid = true;
    if (type == 1 && time.isAfter(minTime)) {
      minTime = minTime.subtract(1, 'minutes');
      maxTime = maxTime.add(2, 'minutes');
      valid = time.isBetween(minTime, maxTime);
    } else if (type == 2 && time.isBefore(maxTime)) {
      minTime = minTime.subtract(15, 'minutes');
      maxTime = maxTime.add(1, 'minutes');
      valid = time.isBetween(minTime, maxTime);
    }
    if (!valid) {

      await this.sweetAlertHelper.createCustomAlert({
        text: `Are you sure the ${type == 1 ? ' first stop' : 'last stop'} time entered is correct?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
      }).then(async (result) => {
          if (result.value) {
            await this.sweetAlertHelper.createCustomAlert({
              text: `An interruption has not been created, do you want to add an interruption?`,
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes!',
              cancelButtonText: 'No'
            }).then((result2) => {
                if (result2.value) {
                  if (type == 1) {
                    this.driver_manifest.interruption_start = true;
                  } else {
                    this.driver_manifest.interruption_end = true;
                  }
                } else {
                  if (type == 1) {
                    this.driver_manifest.interruption_start = false;
                  } else {
                    this.driver_manifest.interruption_end = false;
                  }
                }
              });
          } else {
            if (type == 1) {
              this.driver_manifest.first_stop_time = '';
              this.driver_manifest.interruption_start = false;
            } else {
              this.driver_manifest.last_stop_time = '';
              this.driver_manifest.interruption_end = false;
            }
          }
        });
    } else {
      if (type == 1) {
        this.driver_manifest.interruption_start = false;
      } else {
        this.driver_manifest.interruption_end = false;
      }
    }
    return valid;
  }

  async saveData() {
    const client = Object.assign({}, this.client_id);
    const fecha = this.driver_manifest.dateOfService;
    const afterDate = moment.utc(fecha, 'YYYY-MM-DD').isAfter(moment.utc());
    if (this.getValidatedRange()) {
      if (!afterDate) {
        let odValidate: boolean;
        let tiValidate: boolean;
        if (this.driver_manifest.exclude) {
          odValidate = true;
          tiValidate = true;
        } else {
          odValidate = this.verificarOdometer();
          tiValidate = this.verificarTimer();
        }
        if (!odValidate || tiValidate) {
          this.errorGeneral = true;
        }

        if (odValidate && tiValidate) {
          let clientTemp = this.driver_manifest.client;
          let id = this.driver_manifest.id;
          delete this.driver_manifest.id;
          delete this.driver_manifest.driver;
          delete this.driver_manifest.vehicle;
          delete this.driver_manifest.client;
          delete this.driver_manifest.route_block;
          delete this.driver_manifest.changed_blocks;
          delete this.driver_manifest.exclude;
          this.driver_manifest.interruption = (this.driver_manifest.interruption_start || this.driver_manifest.interruption_end);
          const timeValid = await this.validateHoursWorked(this.driver_manifest.actual_check_in, this.driver_manifest.actual_drop_off);
          if (timeValid) {
            if (this.driverManifestEdit == 0) {
              this.busy = this._dataService.postData(this.driver_manifest, 'driverManifest').subscribe(
                (data: any) => {
                  this.apiResponse = data;
                  if (this.apiResponse.transaction) {
                    let dateRegistro = this.driver_manifest.dateOfService;
                    this.driver_manifest = new DriverManifest();
                    this.modalForm.resetForm();
                    this.onReloadData.emit();
                    if (!this.lock) {
                      this.dateOfService.reset(dateRegistro);
                      this.driver_manifest.dateOfService = dateRegistro;
                    }
                    (<any>$('#driver_id')).focus();
                    this.sweetAlertHelper.createCustomAlert({
                      type: 'success',
                      title: 'Success',
                      text: this.apiResponse.message
                    });
                    this.driverManifestEdit = 0;
                    this.driver_id = '';
                    if (!this.lockClientInput) {
                      this.client_id = '';
                      this.changeClient(0);
                    } else {
                      this.inp_client_id.reset(client.client_name);
                      this.client_id = client;
                      this.changeClient(client.id);
                      this.valueChangedClient(client);
                    }
                    this.route_id = '';
                    this.vehicle_id = '';
                    this.odometer_validation_1 = 'NaN';
                    this.odometer_validation_2 = 'NaN';
                    this.odometer_validation_3 = 'NaN';
                    this.schDriver = {};
                  } else {
                    this.sweetAlertHelper.createCustomAlert({
                      type: 'error',
                      title: 'Oops...',
                      text: this.apiResponse.message
                    });
                  }
                },
                (error: HttpErrorResponse) => {
                  this.sweetAlertHelper.createCustomAlert({
                    type: 'error',
                    title: 'Oops...',
                    text: error.error.message
                  });
                }
              );
              this.errorGeneral = false;
            } else {
              let changed_blocks = false; // Si esta variable es true es porque cambiaron
              if (this.route_blocks_select == this.driver_manifest.route_block_id) {
                changed_blocks = false;
              } else {
                changed_blocks = true;
              }
              this.driver_manifest.changed_blocks = changed_blocks;

              this.busy = this._dataService.putData(this.driver_manifest, 'driverManifest/' + id).subscribe(
                (data: any) => {
                  this.apiResponse = data;
                  if (this.apiResponse.transaction) {
                    this.driver_manifest = new DriverManifest();
                    this.modalForm.resetForm();
                    (<any>$('#driver_id')).focus();
                    this.toastr.success(this.apiResponse.message);
                    this.driverManifestEdit = 0;
                    this.changeClient(0);
                    this.onReloadData.emit();
                    this.driver_id = '';
                    this.client_id = '';
                    this.route_id = '';
                    this.vehicle_id = '';
                    this.odometer_validation_1 = 'NaN';
                    this.odometer_validation_2 = 'NaN';
                    this.odometer_validation_3 = 'NaN';
                    this.schDriver = {};
                  } else if (!this.apiResponse.transaction && this.apiResponse.message === 'An error has ocurred, please check the log') {
                    this.driver_manifest = new DriverManifest();
                    this.modalForm.resetForm();
                    (<any>$('#driver_id')).focus();
                    this.toastr.success(this.apiResponse.message);
                    this.driverManifestEdit = 0;
                    this.changeClient(0);

                    this.driver_id = '';
                    this.client_id = '';
                    this.route_id = '';
                    this.vehicle_id = '';
                    this.odometer_validation_1 = 'NaN';
                    this.odometer_validation_2 = 'NaN';
                    this.odometer_validation_3 = 'NaN';
                    this.schDriver = {};
                  } else {
                    this.driver_manifest.id = id;
                    this.sweetAlertHelper.createCustomAlert({
                      type: 'error',
                      title: 'Oops...',
                      text: this.apiResponse.message
                    });
                  }
                }
              );
              this.errorGeneral = false;
            }
          }
        }

        $('#date').css('color', 'black');
        this.errorDate = false;
      } else {
        $('#date').css('color', 'red');
        this.errorDate = true;
        this.errorGeneral = true;
      }
    } else {
      this.sweetAlertHelper.createCustomAlert({
        title: 'Warning',
        text: 'please validate the values ​​of the Odometer',
        type: 'warning',
      });
    }
  }

  validateMileage(): Promise<boolean> {
    return new Promise((resolve, rejecte) => {
      const total: number = Number(this.driver_manifest.return_to_yard_odometer) - Number(this.driver_manifest.leave_yard_odometer);
      if (total > 500) {
        this.sweetAlertHelper.createCustomAlert({
          text: 'The mileage entered exceed 500 miles. Do you still want to enter this mileage?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes!'
        }).then((result) => {
            if (result.value) {
              resolve(true);
            } else {
              resolve(false);
            }
          });
      } else {
        resolve(true);
      }
    });
  }

  setDefaultOd() {
    if (this.skipOdometerDiff) {
      this.driver_manifest.leave_yard_odometer = '0';
      this.driver_manifest.first_stop_odometer = '0';
      this.driver_manifest.last_stop_odometer = '0';
      this.driver_manifest.return_to_yard_odometer = '0';
      this.odometer_validation_1 = '0';
      this.odometer_validation_2 = '0';
      this.odometer_validation_3 = '0';
    }
  }
  validateHoursWorked(checkIn, checkOut) {
    return new Promise((resolve, reject) => {
      const start = moment(checkIn, 'HH:mm');
      const end = moment(checkOut, 'HH:mm');
      const timeDifference = end.diff(start, 'minutes');
      if (timeDifference > 720 || timeDifference < 240) {
       let hours = moment().startOf('day').add(timeDifference, 'minutes').format('HH:mm');
        this.sweetAlertHelper.createCustomAlert({
          text: 'Are you sure you want to save this manifest with ' + hours + ' hours worked by the driver?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí',
          cancelButtonText: 'No',
          reverseButtons: true
        }).then((result) => {
          resolve(result.value);
        });
      } else {
        resolve(true);
      }
    });
  }


  onVerify() {
    this.onVerifyManifest.emit(this.driverManifestId);
  }

  openModalRecordOdometer() {
    this.modalHistory.open('lg');
  }

  openModalSummaryWorkday() {
    this.modalSummaryWorkday.open('lg');
  }

}
