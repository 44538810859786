import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-export-report',
  templateUrl: './export-report.component.html',
  styleUrls: ['./export-report.component.scss']
})
export class ExportReportComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  changeDataGrid(data) {
    console.log(data);
  }

}
