import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { BsModalComponent } from 'ng2-bs3-modal';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { PayrollService } from './../../../../services/payroll/payroll.service';
import { ClientService } from './../../../../services/services.index';
import { DataService } from './../../../../app.data.service';
declare var $: any;

/**
  * Componente para gestionar los employees import files
  * Este componente contiene el un listado del ultimo archivo subido en el import
  *
  * Principales funciones
  * * Ver listado de empleados
  * * Ver historial de archivos subidos
  * * Ver historial de cambios realizados entre las diferentes modificaciones en los archivos subidos
  * * Importar archivos
*/
@Component({
  selector: 'app-employee-rules',
  templateUrl: 'employee-rules.component.html',
  styleUrls: ['./employee-rules.component.scss'],
})

export class EmployeeRulesComponent implements OnInit, OnDestroy {
  @ViewChild('modalImport') modalImport: BsModalComponent;
  @ViewChild('modalUploatedFiles') modalUploatedFiles: BsModalComponent;
  @ViewChild('modalHistoryFiles') modalHistoryFiles: BsModalComponent;
  @ViewChild('modalExportReport') modalExportReport: BsModalComponent;

  listCurrent: any[] = [];

  busy: Subscription[] = [];
  gridDataFile: GridOptions;
  fileToUpload: File = null;
  blocksSelected = [];
  titleCountGrid = 'Employees 0 of 0';
  getContextMenuItems;

  constructor(
    private dataService: DataService,
    private payrollService: PayrollService,
    private _serviceClient: ClientService,
  ) {
    this.setConfigGrid();
  }

  ngOnInit() {
    this.getCurrentRules();
  }
  ngOnDestroy(): void {
  }

  setConfigGrid() {
    this.gridDataFile = <GridOptions>{
      enableSorting: true,
      enableColResize : true,
      sortingOrder: ['desc', 'asc', null],
      onFilterModified: () => {
        const cant = this.gridDataFile.api.getDisplayedRowCount();
        this.titleCountGrid = `Employees ${cant} of ${this.listCurrent.length}`;
      }
    };
    this.gridDataFile.columnDefs = [
      { headerName: '', field: '', width: 35, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true, },
      { headerName: 'Payroll Code', width: 110, field: 'dept' },
      { headerName: 'Id', field: 'emp', width: 110 },
      { headerName: 'Employee', width: 250, field: 'employee_name' },
      { headerName: 'Type', field: 'driver.availability', cellRenderer: (params) => params.data.driver ? params.data.driver.availability : '', width: 110 },
      { headerName: 'Job Code', field: 'job_code', width: 110 },
      { headerName: 'Rate', type: '', field: 'rate', width: 80 },
      { headerName: 'Salary', field: 'salary', width: 80 },
      { headerName: 'Reg Hrs', field: 'reghrs', width: 80 },
      { headerName: 'O.T. Hrs', field: 'o_t_hrs', width: 80 },
      { headerName: 'Supervisor', field: 'supervisor', width: 80,
        cellStyle: {'text-align': 'center'},
        cellRenderer : (params) => {
          if (params.data.supervisor) { console.log(params.data.supervisor);
          }
          return params.data.supervisor ? '<span class="badge" style="background: green !important; margin-top: -6px !important;"><i class="fa fa-check-circle-o"></i></span>' : '';
        }
      },
      { headerName: 'Insurance', field: 'is_insured', width: 100,
        cellRenderer : (params) => {
          return params.data.driver && params.data.driver.is_insured == 1 ? '<span class="label label-success" style="margin-top: 4px; position: absolute;">active</span>'
            : '<span class="label label-danger" style="margin-top: 4px; position: absolute;">inactive</span>';
        }
      },
    ];
    this.gridDataFile.onRowDoubleClicked = params => {
      // this.loadDeails(params.data);
    };
    this.getContextMenuItems = (params) => {
      const x = this.gridDataFile;
      const result = [
        {
          name: params.node.data.supervisor ? 'Unassign Supervisor' : 'Assign Supervisor',
          cssClasses: ['redFont', 'bold'],
          icon: params.node.data.supervisor ? '<i class="fa fa-ban"></i>' : '<i class="fa fa-check"></i>',
          action: () => {
            this.blocksSelected.push(params.node.data.id);
            params.node.data.supervisor ? this.unassign() : this.assign();
          }
        },
        'separator',
        'copy',
        'export',
      ];
      return result;
    };
  }

  getCurrentRules() {
    this.busy.push(this.payrollService.getEmployeesCurrentRules().subscribe(
      (response: any) => {
        this.listCurrent = response.data;
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i];
          element.rate = parseFloat(element.rate);
        }
        const filter = this.gridDataFile.api.getFilterModel();
        this.gridDataFile.api.setRowData(response.data);
        this.gridDataFile.api.setFilterModel(filter);
        this.titleCountGrid = `Employees ${response.data.length} of ${response.data.length}`;
        this.gridDataFile.api.setSortModel([
          { colId: 'rate', sort: 'desc' },
          { colId: 'emp', sort: 'asc' }
        ]);
      }
    ));
  }

  viewUploated() {
    this.modalUploatedFiles.open();
  }

  viewHistory() {
    this.modalHistoryFiles.open();
  }

  viewExportReport() {
    this.modalExportReport.open();
  }

  importFile() {
    this.modalImport.open();
    this.fileToUpload = null;
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  uploadFile() {
    const file = this.fileToUpload;
    this.busy.push(this.payrollService.uploadEpmployeesRules(file).subscribe(
      res => {
        if (res.status === 'success') {
          Swal.fire('Successfull', 'Rules upload successfully', 'success');
          this.modalImport.close();
          this.getCurrentRules();
        }
      }, err => {
        console.log(err);
        Swal.fire('Error', 'Rules can´t upload rules', 'error');
      }
    ));
  }

  onSelectionToChange(event) {
    const selectedRows = event.api.getSelectedNodes();
    const data = [];
    selectedRows.forEach(element => {
      data.push(element.data.id);
    });
    this.blocksSelected = data;
  }

  assign() {
    if (this.blocksSelected.length > 0) {
      Swal.fire({
        title: 'Are you sure you want to assign to supervisor these employes?',
        text: ``,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!'
      }).then((result) => {
        if (result.value) {
          this.busy.push(this.payrollService.assignBlocksSupervisor({
            supervisor: 1,
            ids: this.blocksSelected,
          }).subscribe(
            (response: any) => {
              Swal.fire('Successful', 'Employees assign successfully', 'success');
              this.getCurrentRules();
              this.blocksSelected = [];
            }
          ));
        }
      });
    } else {
      Swal.fire('', 'You must select an employee prior to changing employee status.', 'info');
    }
  }
  unassign() {
    if (this.blocksSelected.length > 0) {
      Swal.fire({
        title: 'Are you sure you want to remove supervisor status for this employee?',
        text: ``,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!'
      }).then((result) => {
        if (result.value) {
          this.busy.push(this.payrollService.assignBlocksSupervisor({
            supervisor: 0,
            ids: this.blocksSelected,
          }).subscribe(
            (response: any) => {
              Swal.fire('Successful', 'Employee status updated successfully', 'success');
              this.getCurrentRules();
              this.blocksSelected = [];
            }
          ));
        }
      });
    } else {
      Swal.fire('', 'You must select an employee prior to changing employee status.', 'info');
    }
  }

  onRowSelected(event: any) {
    console.log(event);
  }
}
