import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular/main';
import { TextMaskModule } from 'angular2-text-mask';
import { NgBusyModule } from 'ng-busy';
import { Ng2AutoCompleteModule } from 'ng2-auto-complete';
import { BsModalModule } from 'ng2-bs3-modal';
import { TabsModule } from 'ngx-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { InterruptionService } from '../../services/payroll/interruption.service';
import { SharedModule } from '../../shared/shared.module';
import { DashboardLsfDataService } from '../dashboard-lsf/dashboard-lsf.data.service';
import { HistoryPunchesModule } from './../../components/history-punches/history-punches.module';
import { SelectWeeksModule } from './../../components/select-weeks/select-weeks.module';
import { TimecardsService } from './../../services/payroll/timecards.service';
import { ThemeModule } from './../../theme/theme.module';
import { InfoCardComponent } from './driver-master-schedule/components/info-card/info-card.component';
import { SearchTimecardComponent } from './driver-master-schedule/components/search-timecard/search-timecard.component';
import { TimeCardComponent } from './driver-master-schedule/components/time-card/time-card.component';
import { DriverMasterScheduleComponent } from './driver-master-schedule/driver-master-schedule.component';
import { DetailsComponent } from './interruption-details/components/details/details.component';
import { InterruptionDetailsComponent } from './interruption-details/interruption-details.component';
import { PayrollRoutingModule } from './payroll-routing.module';
import { RulesComponent } from './rules/rules.component';
import { CreateRuleComponent } from './rules/payroll-rules/components/create/create.component';
import { PayrollRulesComponent } from './rules/payroll-rules/payroll-rules.component';
import { CurrencyMaskModule } from 'ngx-currency-mask';
import { BlockRulesComponent } from './rules/block-rules/block-rules.component';
import { PayrollService } from './../../services/payroll/payroll.service';
import { ClientService } from './../../services/fleet/client.service';
import { EmployeeRulesComponent } from './rules/employee-rules/employee-rules.component';
import { UploatedFilesEmployeesRulesComponent } from './rules/employee-rules/components/uploated-files/uploated-files.component';
import { HistoryFilesEmployeesRulesComponent } from './rules/employee-rules/components/history/history-files.component';
import { FinalReportPayrollComponent } from './rules/final-report/final-report.component';
import { ExportReportComponent } from './rules/export-report/export-report.component';
import { DataService } from './../../app.data.service';
import { ClockLogComponent } from './clock-log/clock-log.component';
import { ExceptionsReportModule } from './exceptions-report/exceptions-report.module';
import { ReportClientComponent } from './rules/report-client/report-client.component';
import { CountMonthComponent } from './rules/count-month/count-month.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    BsModalModule,
    BsDatepickerModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    AgGridModule.withComponents([]),
    TabsModule.forRoot(),
    NgBusyModule,
    PayrollRoutingModule,
    ThemeModule,
    Ng2AutoCompleteModule,
    TextMaskModule,
    SelectWeeksModule,
    HistoryPunchesModule,
    CurrencyMaskModule,
    ExceptionsReportModule,
  ],
  declarations: [
    InterruptionDetailsComponent,
    DetailsComponent,
    DriverMasterScheduleComponent,
    TimeCardComponent,
    InfoCardComponent,
    SearchTimecardComponent,
    RulesComponent,
    PayrollRulesComponent,
    CreateRuleComponent,
    BlockRulesComponent,
    EmployeeRulesComponent,
    UploatedFilesEmployeesRulesComponent,
    HistoryFilesEmployeesRulesComponent,
    FinalReportPayrollComponent,
    ExportReportComponent,
    ClockLogComponent,
    ReportClientComponent,
    CountMonthComponent
  ],
  providers: [
    DataService,
    InterruptionService,
    DashboardLsfDataService,
    TimecardsService,
    PayrollService,
    ClientService,
  ],
  exports: [
    SearchTimecardComponent,
  ]
})
export class PayrollModule { }
