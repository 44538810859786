import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { GridOptions } from 'ag-grid-community';
import { InvoiceService } from '../../../../services/billing/invoice.service';
import { takeUntil } from 'rxjs/operators';
import { BsModalComponent } from 'ng2-bs3-modal';
import * as moment from 'moment';

@Component({
  selector: 'app-buses-fueled-grid',
  templateUrl: './buses-fueled-grid.component.html',
  styleUrls: ['./buses-fueled-grid.component.scss']
})

export class BusesFueledGridComponent implements OnInit, OnDestroy, OnChanges {
  private destroy: Subject<boolean> = new Subject<boolean>();
  @ViewChild('modalFueled') modalFueled: BsModalComponent;

  @Input() onClearGrid?= false;
  busy: Subscription[] = [];
  grid: GridOptions;
  grid_columns = [];
  dataParams: any = {};
  getContextMenuItems;
  bus_fueled = null;

  constructor(
    private invoiceService: InvoiceService,
  ) {
    this.initGrid();
  }

  ngOnInit() {
    this.invoiceService.load_buses$
    .pipe(takeUntil(this.destroy))
    .subscribe(res => {
      this.dataParams = res;
      this.onSearch();
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['onClearGrid']) {
      if (this.onClearGrid && this.grid.api) {
        this.grid.api.setRowData([]);
      }
    }
  }

  ngOnDestroy() {
    this.destroy.next(true);
    this.destroy.unsubscribe();
    return this.busy = [];
  }

  initGrid() {
    this.grid = <GridOptions>{
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      rowSelection: 'single',
      enableRangeSelection: true,
      columnDefs: [
        { headerName: 'No.', field: '', cellRenderer: (params) => {
          return params.rowIndex + 1
        }, width: 70 },
        { headerName: 'Day - Date Fueled', field: 'date', cellRenderer: (params) => {
          return moment(params.data.date, 'YYYY-MM-DD hh:mm:ss').format('dddd, DD MMMM YYYY'); ;
        }, width: 300 },
        { headerName: 'Bus#', field: 'vehicle.description', width: 150 },
        { headerName: 'Gallons', field: 'issue_qty', width: 150 },
        { headerName: 'Odometer', field: 'odometer', width: 150 },
      ],
      onGridReady: () => {
        this.grid.api.setRowData([]);
      }
    };
    this.getContextMenuItems = () => {
      const result = [
        {
          name: "Add Bus Fueled",
          icon: '<i class="fa fa-plus"></i>',
          action: () => {
            this.bus_fueled = null;
            this.modalFueled.open('sm');
          },
        },
        'separator',
        'copy',
        'export',
      ];
      return result;
    };
  }

  onRowDoubleClicked($event) {
    this.bus_fueled = $event;
    this.modalFueled.open('sm');
  }

  onSearch() {
    const data: any = Object.assign({}, this.dataParams);
    const info = {
      start_date: data.start_date,
      end_date: data.end_date,
      route_id: data.routes_id[0],
    }
    if(info.route_id && info.start_date && info.end_date) {
      this.busy.push(this.invoiceService.getBusesFueledWeek(info)
      .subscribe((resp) => {
        const list = Object.assign([], resp.data);
        if (this.grid.api) {
          this.grid.api.setRowData(list);
        }
      }));
    }
  }

  onSave(data) {
    this.bus_fueled = null;
    this.modalFueled.close();
    this.onSearch();
  }
}
