import { Component, OnInit, OnDestroy, Input, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { InvoiceService } from '../../../services/billing/invoice.service';
import { GridOptions } from 'ag-grid-community';
import { Subject, Subscription } from 'rxjs-compat';
import AgGridHelpers from '../../../helpers/ag-grid-helpers';
import { takeUntil } from 'rxjs/operators';
import { BsModalComponent } from 'ng2-bs3-modal';
import Swal from 'sweetalert2';
import { ReportsManageService } from '../../../services/reports-manage.service';
import * as moment from 'moment';

@Component({
  selector: 'app-import-buses-fueled',
  templateUrl: './import-buses-fueled.component.html',
  styleUrls: ['./import-buses-fueled.component.scss']
})

export class ImportBusesFueledComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('modalUploatedFiles') modalUploatedFiles: BsModalComponent;
  private destroy: Subject<boolean> = new Subject<boolean>();
  @ViewChild('modalImport') modalImport: BsModalComponent;
  @Input() onClearGrid? = false;
  dataParams: any = {};
  busy: Subscription[] = [];
  grid: GridOptions;
  getContextMenuItems;
  fileToUpload: File = null;


  constructor(private invoiceService: InvoiceService, private reportsManageService: ReportsManageService) {
    this.initGrid();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['onClearGrid']) {
      if (this.onClearGrid && this.grid.api) {
        this.grid.api.setRowData([]);
      }
    }
  }

  ngOnInit() {
    this.onLoadBusesFueled();
  }

  onLoadBusesFueled() {
    this.invoiceService.load_buses_fueled$.pipe(takeUntil(this.destroy)).subscribe(res => {
      this.dataParams = res;
      this.onSearch();
    });
  }

  ngOnDestroy() {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  initGrid() {
    this.grid = <GridOptions> {
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      rowSelection: 'single',
      enableRangeSelection: true,
      enableFilter: true,
      onGridReady: () => {
        this.grid.api.setRowData([]);
      },
      columnDefs: [
        {
          headerName: 'Date',
          field: 'date',
          cellRenderer: (params: any) => {
            return moment(params.value).format('MM-DD-YYYY');
          },
          width: 100
        },
        { headerName: 'Fuel site', field: 'fuel_site', width: 300 },
        { headerName: 'Fuel type', field: 'fuel_type', width: 100 },
        { headerName: 'Issue qty', field: 'issue_qty', width: 100 },
        { headerName: 'Vehicle', field: 'vehicle', width: 100 }
      ]
    };

    this.getContextMenuItems = () => {
      const result = [
        AgGridHelpers.itemMenu('Print', 'fa-print', () => {
          this.printReport();
        }),
        'separator',
        'copy',
        'export'
      ];
      return result;
    };
  }

  onSearch() {
    const info = {
      start_date: this.dataParams.start_date,
      end_date: this.dataParams.end_date
    };
    if (info.start_date && info.end_date) {
      this.busy.push(
        this.invoiceService.getImportBusesFueled(info).subscribe((resp: any) => {
          if (this.grid.api) {
            this.grid.api.setRowData(resp.data);
          }
        })
      );
    };
  }

  importFile() {
    this.modalImport.open();
    this.fileToUpload = null;
  }

  viewUploated() {
    this.modalUploatedFiles.open();
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  uploadFile() {
    if (this.fileToUpload) {
      const data = {
        import_file: this.fileToUpload,
        start_date: moment(this.dataParams.start_date).format('YYYY-MM-DD'),
        end_date: moment(this.dataParams.end_date).format('YYYY-MM-DD'),
      };
     this.busy.push(
        this.invoiceService.postImportBusesFueled(data).subscribe(
          res => {
            if (res) {
              Swal.fire('Successfull', 'File upload successfully', 'success').then(() => {
                this.onLoadBusesFueled();
                this.fileToUpload = null;
                this.modalImport.close();
              });
            }
          },
          err => {
            Swal.fire('Error', 'File can´t upload files', 'error');
          }
        )
      );
    };
  }

  printReport() {
    const parameter: any = {
      client_id: [`${this.dataParams.client_id}`],
      date: [`${this.dataParams.start_date}`, `${this.dataParams.end_date}`]
    };
    this.dataParams.block_like_run
      ? (parameter.route_group_id = this.dataParams.route_group_id)
      : (parameter.route_id = this.dataParams.routes_id);
    this.busy.push(
      this.reportsManageService.getReportTrip2(6163, parameter).subscribe((resp: string) => {
        this.reportsManageService.loadReportWindows(resp);
      })
    );
  }
}
