import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { FormService, IFormType } from '../../../../../../services/billing/form.service';

@Component({
  selector: 'app-form-type-card',
  templateUrl: './form-type-card.component.html',
  styleUrls: ['./form-type-card.component.scss']
})
export class FormTypeCardComponent implements OnInit, OnDestroy {
  busy: Subscription[] = [];
  formFormsType: FormGroup;

  constructor(private fb: FormBuilder, private formService: FormService) {
    this.createForm();
  }

  ngOnInit() {}

  createForm() {
    this.formFormsType = this.fb.group({
      id: [''],
      description: ['', Validators.required],
      active: [true]
    });
  }

  save() {
    const formData: IFormType = Object.assign({}, this.formFormsType.getRawValue());
    this.busy.push(
      this.formService
        .addFormType(formData)
        .subscribe(
          (resp: any) => {
            if (resp) {
              Swal.fire({
                type: 'success',
                title: 'Success',
                text: resp.message
              }).then(() => {
                this.formService.saveFormType$.next(true);
              });
            }
          },
          error => {
            Swal.fire({
              type: 'error',
              title: 'Error...',
              text: error.message
            });
          }
        )
    );
  }

  get isActive(): boolean {
    return (!!this.formFormsType.get('id').value);
  }

  ngOnDestroy(): void {
    this.busy.forEach(subscription => subscription.unsubscribe());
  }
}
