import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { VehicleService } from '../../../../../../services/fleet/vehicle.service';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientService } from '../../../../../../services/fleet/client.service';
import { IMyDpOptions } from 'mydatepicker';
import { takeUntil } from 'rxjs/operators';
import { IFeeSchedule } from '../fee-schedule.interface';
import { SweetAlertHelper } from '../../../../../../CORE/helpers/sweet-alert-helper.service';
import { FeeScheduleType } from '../../../../../../CORE/enums/FeeScheduleEnum.enum';

@Component({
  selector: 'app-add-fee-schedule',
  templateUrl: './add-fee-schedule.component.html',
  styleUrls: ['./add-fee-schedule.component.scss']
})

export class AddFeeScheduleComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('modalAddRouteGroup') modalAddRouteGroup: any;
  private destroy: Subject<boolean> = new Subject();
  busy: Subscription[] = [];
  vehicleType: any = [];
  formAddFeeSchedule: FormGroup;
  @Input() clientId?: number;
  @Input() modalInvoice?: boolean;
  @Output() onReload? = new EventEmitter<any>();

  myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd'
  };

  myDatePickerOptionsEnd: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd'
  };

  constructor(
    private fb: FormBuilder,
    private vehicleService: VehicleService,
    private clientService: ClientService,
    private sweetAlertHelper: SweetAlertHelper
  ) {
    this.createForm();
    this.subscribeObservables();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['clientId'] && this.clientId) {
      this.formAddFeeSchedule.controls['client_id'].setValue(this.clientId);
    }
  }

  ngOnInit() {
    this.loadData();
  }

  subscribeObservables() {
    this.clientService.editFeeSchedule$.pipe(takeUntil(this.destroy)).subscribe((resp: IFeeSchedule) => {
      if (resp) {
        this.formAddFeeSchedule.patchValue({
          id: resp.id,
          client_id: resp.client_id,
          vehicle_type_id: resp.vehicle_type_id,
          unit: resp.unit,
          rate: resp.rate,
          start_date: resp.start_date,
          end_date: resp.end_date,
          active: resp.active,
          startDate: resp.start_date,
          endDate: resp.end_date,
          default: resp.default,
          fee_schedule_type: resp.fee_schedule_type,
          po_number: resp.po_number
        });
        this.setDate();
      }
    });
  }

  createForm() {
    this.formAddFeeSchedule = this.fb.group({
      id: [''],
      client_id: ['', [Validators.required]],
      vehicle_type_id: ['', [Validators.required]],
      unit: ['', [Validators.required]],
      rate: [0, [Validators.required]],
      start_date: ['', [Validators.required]],
      end_date: [''],
      active: [true, [Validators.required]],
      startDate: [''],
      endDate: [''],
      default: [false],
      fee_schedule_type: [FeeScheduleType.DEFAULT],
      po_number: [''],
      route_group: [[]]
    });
  }

  loadData() {
    this.busy.push(
      forkJoin([this.vehicleService.getAllVehicleTypes()]).subscribe((resp: any[]) => {
        if (resp && resp.length) {
          this.vehicleType = resp[0];
        }
      })
    );
  }

  onDateChangedStart(event: any) {
    if (event && event.formatted) {
      this.formAddFeeSchedule.get('start_date').setValue(event.formatted);
      const date = new Date(event.formatted);
      this.myDatePickerOptionsEnd = {
        dateFormat: 'yyyy-mm-dd',
        disableUntil: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
      };
    }
  }

  onDateChangedEnd(event: any) {
    if (event && event.formatted) {
      this.formAddFeeSchedule.get('end_date').setValue(event.formatted);
    }
  }

  onSave() {
    const formData = Object.assign({}, this.formAddFeeSchedule.getRawValue());
    if (formData.id) {
      this.busy.push(
        this.clientService.putFeeSchedule(formData, formData.id).subscribe(
          (resp: any) => {
            if (resp.status === 'success') {
              this.clientService.reloadFeeScheduleGrid(true);
            }
          },
          (error) => {
            this.sweetAlertHelper.captureException(error).then();
          }
        )
      );
    } else {
      delete formData.route_group;
      this.busy.push(
        this.clientService.postFeeSchedule(formData).subscribe(
          (resp: any) => {
            if (resp.status === 'success') {
              this.clientService.reloadFeeScheduleGrid(true);
            }
          },
          (error) => {
            this.sweetAlertHelper.captureException(error).then();
          }
        )
      );
    }
  }

  get isEdit(): boolean {
    return !!this.formAddFeeSchedule.get('id').value;
  }

  setDate() {
    const formData = Object.assign({}, this.formAddFeeSchedule.getRawValue());
    if (formData.start_date) {
      const date = new Date(formData.start_date);
      this.formAddFeeSchedule.get('startDate').setValue({
        date: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
      });
      this.myDatePickerOptionsEnd = {
        dateFormat: 'yyyy-mm-dd',
        disableUntil: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
      };
    }

    if (formData.end_date) {
      const date = new Date(formData.end_date);
      this.formAddFeeSchedule.get('endDate').setValue({
        date: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
      });
    }
  }

  ngOnDestroy() {
    this.busy.forEach((sub: Subscription) => sub.unsubscribe());
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }
}
