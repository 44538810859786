import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UnsubscribeComponent } from './unsubscribe.component';
import { routing } from './unsubscribe.routing';
import { NgBusyModule } from 'ng-busy';
import { UnsubscribeService } from './unsubscribe.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    routing,
    NgBusyModule
  ],
  declarations: [UnsubscribeComponent],
  providers: [UnsubscribeService],
  exports: []
})

export class UnsubscribeModule { }
