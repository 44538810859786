import {Component, OnDestroy, OnInit} from '@angular/core';
import {GridOptions} from 'ag-grid-community';
import {Subscription} from 'rxjs';
import {WorkflowService} from '../../../../services/billing/workflow.service';
import {SweetAlertHelper} from '../../../../CORE/helpers/sweet-alert-helper.service';
import {BillingStatusEnum} from '../../../../CORE/enums/BilingStatusEnum.enum';

@Component({
  selector: 'app-summary-workflow',
  templateUrl: './summary-workflow.component.html',
  styleUrls: ['./summary-workflow.component.scss']
})
export class SummaryWorkflowComponent implements OnInit, OnDestroy {

  subscriptionSummaryWorkFlow: Subscription[] = [];
  gridSummaryWorkflow: GridOptions;
  gridData: any[] = [];
  dataSummaryWorkFlow = [];
  date_bar: any = '';
  routes: any = [];
  billing_status_list: any[] = [];
  block_status_list: any[] = [];
  constructor(private workflowService: WorkflowService, private sweetAlertHelper: SweetAlertHelper) {
    this.createGrid();
  }

  ngOnInit() {
    this.subscribeObservers();
  }

  subscribeObservers() {
    this.workflowService.loadBillingValidateForSummarySubject$.subscribe(data => {
      if (data) {
        if (data && data.date && data.date.type === '1') {
          this.date_bar = {
            'month': null,
            'start_date': data.date.start_date,
            'end_date': data.date.end_date,
            'type': '1',
            'weeks': null,
            'year': null
          };
        } else if (data && data.date && data.date.type === '3') {
          this.date_bar = {
            'month': null,
            'start_date': data.date.start_date,
            'end_date': data.date.end_date,
            'type': '3',
            'weeks': data.date.weeks.week,
            'year': null
          };
        } else {
          this.date_bar = data.date;
        }
          this.dataSummaryWorkFlow = [];
          this.routes = data.routes;
          this.billing_status_list = data.billing_status_list;
          this.block_status_list = data.block_status_list;
          this.subscriptionSummaryWorkFlow.push(this.workflowService.getSummary(data).subscribe(resp => {
              if (resp && resp.data) {
                this.preparedSummaryWorkflow(resp.data);
              }
          }));
      }
    });
  }

  createGrid() {
    this.gridSummaryWorkflow = <GridOptions>{
      cellStyle: {textAlign: 'center'},
      enableSorting: true,
      enableColResize: true,
      enableFilter: true,
      suppressAggFuncInHeader: true,
      onGridReady: () => {
        this.gridSummaryWorkflow.api.setRowData([]);
      },
      sortable: true,
      resizable: true,
      animateRows: true,
      groupDisplayType: 'multipleColumns',
      groupMaintainOrder : true,
      groupDefaultExpanded: 1,
      autoGroupColumnDef: {
        headerName: 'Clients',
        cellRendererParams: {
          suppressCount: true
        }
      },
      columnDefs: [
        {
          headerName: 'Client',
          colId: 'client_name',
          field: 'client_name',
          width: 180,
          rowGroup: true,
          hide: true,
          cellRendererParams: {
            suppressCount: false,
            checkbox: true,
            suppressDoubleClickExpand: true,
            suppressEnterExpand: true,
          },
        },
        { headerName: 'Route', field: 'route_name', cellStyle: {textAlign: 'left'}, sort: 'asc' },
        {
          headerName: 'Completed',
          children: [
            { headerName: 'Unverified', field: 'completed_unverified', colId: 'completed_unverified', aggFunc: 'sum', width: 100,  cellStyle: { textAlign: 'right', cursor: 'pointer' },
              cellRenderer: (params) => {
                if (params && params.data) {
                  return `<a style="color: #001c55; ">${params.data.completed_unverified}</a>`;
                }
              }
            },
            { headerName: 'Rejected', field: 'completed_rejected', colId: 'completed_rejected', width: 100, aggFunc: 'sum', cellStyle: { textAlign: 'right', cursor: 'pointer' },
              cellRenderer: (params) => {
                if (params && params.data) {
                  return `<a style="color: #001c55;">${params.data.completed_rejected}</a>`;
                }
              }
            },
            { headerName: 'Verified', field: 'completed_verified', colId: 'completed_verified', width: 100, aggFunc: 'sum', cellStyle: { textAlign: 'right', cursor: 'pointer' },
              cellRenderer: (params) => {
                if (params && params.data) {
                  return `<a style="color: #001c55;">${params.data.completed_verified}</a>`;
                }
              }
            }
          ]
        },
        { headerName: 'Unsigned / Off', field: 'unsigned_off', colId: 'unsigned_off', width: 120, cellStyle: {textAlign: 'right', cursor: 'pointer'},
          cellRenderer: (params) => {
            if (params && params.data) {
              return `<a style="color: #001c55;">${params.data.unsigned_off}</a>`;
            }
          }
        }
      ],
    };
  }

  preparedSummaryWorkflow(data) {
    this.insertSummaryWorkFlow(data);
    this.gridSummaryWorkflow.api.setRowData(this.dataSummaryWorkFlow);
  }

  getWorkflow(params: any) {
    if (params) {
      const block_status_selected = this.block_status_list.filter(block => Number(block.id) === BillingStatusEnum.Batch);
      if (params.colDef.colId === 'completed_unverified') {
        const billing_status_selected: {id: BillingStatusEnum} [] = [{id: BillingStatusEnum.Unverified}];
        this.sendDataValidation(params.data, billing_status_selected, block_status_selected);
      }
      if (params.colDef.colId === 'completed_rejected') {
        const billing_status_selected = this.billing_status_list.filter(billing => Number(billing.id) === Number(BillingStatusEnum.Rejected));
        this.sendDataValidation(params.data, billing_status_selected, block_status_selected);
      }
      if (params.colDef.colId === 'completed_verified') {
        const billing_status_selected = this.billing_status_list.filter(billing => Number(billing.id) === Number(BillingStatusEnum.Verified));
        this.sendDataValidation(params.data, billing_status_selected, block_status_selected);
      }
    }
  }
  sendDataValidation(data, billing_status, blockStatus) {
    const billing_status_id = Number(billing_status[0].id);
    const block_status_id = Number(blockStatus[0].id);
    const route_selected = this.routes.filter(route => Number(route.id) === Number(data.route_id));
    const info = {
      billing_status_id: billing_status_id,
      route_id: [data.route_id],
      routes: route_selected,
      client_id: data.client_id,
      date: this.date_bar,
      date_star: this.date_bar.start_date ? this.date_bar.start_date : data.start_date,
      date_end: this.date_bar.end_date ? this.date_bar.end_date : data.end_date,
      block_status_id: [block_status_id],
      block_status_selected: blockStatus
    };
      this.subscriptionSummaryWorkFlow.push(this.workflowService.reviewBlockSummary(info).subscribe(resp => {
        if (resp) {
          if (billing_status_id === BillingStatusEnum.Unverified) {
            this.workflowService.workflowBarSubject.next(info);
            this.workflowService.responseReviewBlockUnverifiedSubject.next(resp);
            this.workflowService.openTabWorkflowSubject.next(BillingStatusEnum.Unverified);
          }
          if ((billing_status_id === BillingStatusEnum.Rejected || billing_status_id === BillingStatusEnum.Verified)) {
            this.workflowService.workflowBarSubject.next(info);
            this.workflowService.responseReviewBlockRejectedVerifiedSubject.next(resp);
            this.workflowService.openTabWorkflowSubject.next(BillingStatusEnum.Rejected);
          }
        }
      }, error => {
        this.sweetAlertHelper.captureException(error.message).then();
      }));
  }

  insertSummaryWorkFlow(list: any) {
    if (list  && list.length) {
      const dataGridResult: any[] = [];
      for (let i = 0; i < list.length; i++) {
        const detailsList = list[i];
        const details: any = {
          client_name: detailsList.client_name,
          client_id: detailsList.client_id,
          route_name: detailsList.route_name,
          route_id: detailsList.route_id,
          start_date: detailsList.start_date,
          end_date: detailsList.end_date,
          completed_verified: Number(detailsList.completed_verified),
          completed_rejected: Number(detailsList.completed_rejected  ),
          completed_unverified: Number(detailsList.completed_unverified  ),
          unsigned_off: Number(detailsList.unsigned_off),
        };
        dataGridResult.push(details);
      }
      for (let t = 0; t < dataGridResult.length; t++) {
        this.dataSummaryWorkFlow.push(dataGridResult[t]);
      }
    }
  }

  onLoadDataBlockStatus($event) {
    if ($event && $event.data) {
    }
  }
  ngOnDestroy() {
    this.subscriptionSummaryWorkFlow.forEach(subscription => subscription.unsubscribe());
  }

}
