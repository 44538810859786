import { environment } from '../environments/environment';

export const CONSTANTS = {
  GET_TOKEN: 'oauth/token',
  GET_USER_INFO: 'user',
  PERMISOS: 'permissions',
  ROLES: 'roles',
  TYPE_CLIENT: 'userType',
  USER: 'users',
  COMPANIES: 'companies',
  PERMISSIONS: 'permissions',
  CLIENTS: 'clients',
  INTERRUPTIONCLIENTES: 'interruptionclientConfigs',
  DRIVERS: 'drivers',
  VEHICLES: 'vehicles',
  PARAMETRIZATION_DOCUMENTS: 'parametrizations/documents',
  ATTACHS_DOCUMENTS: 'attachsDocuments',
  ATTACHS_IMAGES: 'attachsImages',
  INSPECTION_CATEGORIES: 'VehicleInspectionCategories',
  INSPECTION_ITEMS: 'VehicleInspectionItems',
  DRIVER_MANIFESTS: 'DriverManifests',
  INSPECTION_VEHICLES: 'VehicleInspections',
  TSO_CONFIG: 'tsoConfig',
  SCHEDULE_DETAILS_CLIENT: 'schedulesDetails/client/',
  TSO_ASSIGN_VEHICLE: 'tsoAssignVehicle',
  VERIFICATION_UNIT: 'tsoAssignVehicle/verificationUnit/',
  MISSING_DRIVER_MANIFESTS: 'MissingDriverManifestsByClient',
  ERRORS_IN_MILES: 'ErrorsInMiles',
  MISSING_DRIVER_MANIFESTS_ALL: 'MissingDriverManifestsAllClient',
  DISRUPTIONS_BY_CLIENT: 'DisruptionsByClient',
  DISRUPTIONS_ALL_CLIENT: 'DisruptionsAllClient',
  DRIVER_MANIFESTS_REPORT: 'DriverManifestReport',
  REPORTS_MANAGE: 'ReportsManage',
  ROUTE_BLOCK: 'RouteBlock',
  GET_REPORT_SSDT: 'getReportSSDT',
  PERDIEM: 'manage/perdiem',
  CATEGORIES_INCIDENTS: 'categoriesIncidents',
  INCIDENTS: 'incidents',
  ROUTES: 'routes',
  LAYOUT_GRID: 'layouts',
  EMPLOYEESTOPS: 'employee',
  ROUTESTOPS: 'externalRoute',
  CATEGORIES_BILLING: 'billing/categories',
  RUNS: 'billing/runs',
  SMTPCONFIG: 'parametrizations/smtpConfig',
  AUTOMANIFEST: 'driverManifest/automatico/',
};

const EndPoint = (resource: string): string => {
  return environment.url_api_v + resource;
};

export const CONSTANT = {
    BILLING: {
      URL: {
        BASE: EndPoint('billing'),
        BILLING_STATUS: EndPoint('billing/billing_status'),
        BILLING_BLOCK_STATUS: EndPoint('billing/block_status'),
        BILLING_SUMMARY: EndPoint('billing/getSummary'),
        BILLING_UNVERIFIED: EndPoint('billing/getData'),
        BILLING_WORKFLOW: EndPoint('billing/getDataWorkFlow'),
        BILLING_DISCREPANCY: EndPoint('billing/getValidateSchedule'),
        BILLING_REVIEW_BLOCK: EndPoint('billing/review_block'),
        BILLING_CHANGE_VERIFIED_STATUS: EndPoint('billing/ChangeVerifiedStatus'),
        BILLING_CHANGE_STATUS: EndPoint('billing/ChangeStatus'),
        BILLING_SUMMARY_TAB_UNPROCESSED: EndPoint('billing/getSummaryClients'),
        BILLING_CREATE_BATCH: EndPoint('billing/createBatch'),
        BILLING_GET_INFO_BATCH_BY_RATE: EndPoint('billing/getInfoBatchByRate'),
        BILLING_GET_INFO_BATCH_BY_ROUTE: EndPoint('billing/getInfoBatchByRoute'),
        BILLING_GET_CREDIT_CHARGE_BATCH: EndPoint('billing/getCreditsCharge'),
        BILLING_PATCH_CHARGE_BATCH_REVERSE: EndPoint('billing/chargesBatch/deactivate'),
        BILLING_PATCH_CREDIT_BATCH_REVERSE: EndPoint('billing/creditsCharge/deactivate'),
        BILLING_GET_CHARGE_BATCH: EndPoint('billing/getChargesBatch'),
        BILLING_CHARGE_REPEAT: EndPoint('billing/chargesBatch/validateChargeRepeat'),
        BILLING_CHARGE_BATCH: EndPoint('billing/chargesBatch'),
        BILLING_CREDIT_CHARGE_BATCH: EndPoint('billing/creditsCharge'),
        BILLING_ORIGINAL_CHARGE_BATCH: EndPoint('billing/getChargesOriginal'),
        BILLING_GET_CHARGE_TYPES: EndPoint('billing/getTypeCharge'),
        BILLING_GET_CREDIT_STATUS: EndPoint('billing/getCreditStatus'),
        BILLING_GET_CONCEPT_VALUE: EndPoint('billing/getConceptValue'),
        BILLING_GET_BALANCE: EndPoint('billing/invoiceBatch/getBalance'),
        BILLING_RECALCULATE: EndPoint('billing/recalculate'),
        BILLING_BATCH_INVOICE_CREDIT: EndPoint('billing/batch-get-invoice-credit'),
        BILLING_BATCH_INVOICE_CREDIT_TYPE: EndPoint('billing/batch-invoice-credit-type'),
        BILLING_FORM: EndPoint('billing/getForm'),
        BILLING_FORM_TYPE: EndPoint('billing/formType'),
      }
    },
    USER_TYE: {
      URL: {
        BASE: EndPoint('userType'),
        USER_TYPE_STATUS: EndPoint('userType/byStatus/')
      },
    },
    ROUTE_GROUP: {
      URL: {
        BASE: EndPoint('routeGroups'),
        ROUTE_BY_CLIENT: EndPoint('routeGroupsFilteredByClient'),
        ADD_ROUTE_GROUP: EndPoint('routeGroupFeeScheduleAdd'),
        UPDATED_ROUTE_GROUP: EndPoint('routeGroupFeeScheduleUpdate'),
        GET_ROUTES_BY_GROUP: EndPoint('feeScheduleRouteGroup'),
        DELETE_ROUTE_GROUP: EndPoint('routeGroupFeeScheduleDelete'),
        ROUTE_IS_ASSIGNED: EndPoint('searchRouteIsAssigned'),
      }
    },
    USERS: {
      URL: {
        BASE: EndPoint('users'),
        UPDATE_USER_MOBILE: EndPoint('users/mobile'),
        GET_USERS_BY_STATUS: EndPoint('users/status'),
        GET_DRIVERS_BY_STATUS: EndPoint('users/drivers/status'),
        LOGOUT_ALL_USERS: EndPoint('me/logoutAll'),
        UPDATED_USER_AVATAR: EndPoint('users/avatar/update'),
      }
    },
    CLIENT_DASHBOARD: {
      URL: {
        BASE: EndPoint(''),
        GET_PASSENGER_COUNT: EndPoint('getSchedulePassengerCount'),
        GET_PASSENGER_DETAIL: EndPoint('getPassengerDetail'),
        GET_STOPS_PASSENGER_DETAIL: EndPoint('getStopsPassengerDetail'),
        GET_ROUTE_BY_ID: EndPoint('dispatchs/routes/path'),
      }
    },
    CLIENT: {
      URL: {
        BASE: EndPoint('clients'),
        CLIENTS_REALTIME_ACTIVE: EndPoint('clients/user/all'),
        CLIENTS_GET_AVL: EndPoint('clients/getAvl/'),
        GET_TEMPLATE_INTERRUPTIONS_S3: EndPoint('getInterruptionsTemplateS3/'),
        GET_BILLING_RULES_BY_CLIENT_ID: EndPoint('clients/getConfigurations')
      }
    },
    COMPANY: {
      URL: {
        BASE: EndPoint('company')
      }
    },
    VEHICLE: {
      URL: {
        BASE: EndPoint('vehicles'),
        GET_ALL_ACTIVE_VEHICLES: EndPoint('vehicles/active/1'),
        GET_VEHICLE_DEVICE: EndPoint('traccar/devices'),
        GET_AVAILABLE_VEHICLES_BY_SCHEDULE: EndPoint('vehicles/available/scheduledetails'),
        POST_VEHICLE_POSITION_GPS: EndPoint('vehicles/gps'),
        POST_VEHICLES_POSITION_TRACCAR: EndPoint('traccar/positions/vehicles')
      }
    },
    OSRM: {
      URL: {
        BASE: 'https://osrm.trip2.com',
        GET_ROUTE: 'https://osrm.trip2.com/route/v1/driving/',
        GET_TRIP_URL: 'https://osrm.trip2.com/trip/v1/driving/',
      }
    },
    USERS_CLIENTS: {
      URL: {
        BASE: EndPoint('clients/user/all')
      }
    },
    DOCUMENTS: {
      URL: {
        BASE: EndPoint('forModel/'),
        ATTACH_DOCUMENT_LICENSE: EndPoint('attachsDocumentsLicenses/forModel/'),
        GET_MISSING_LICENSES: EndPoint('missing_licenses/forModel/'),
        GET_TOTAL_DOCUMENTS: EndPoint('totalDocuments/')
      }
    },
    DRIVERS: {
      URL: {
        BASE: EndPoint('drivers/'),
        GET_ALL_ACTIVE_DRIVERS: EndPoint('drivers/active/1'),
        GET_AVAILABLE_DRIVERS_BY_SCHEDULE_AND_RANGE_DATE: EndPoint('drivers/dashboardScheduleCombo')
      }
    },
    TOPS: {
      URL: {
        GET_URI_S3: EndPoint('getUriS3')
      }
    },
    MANIFEST: {
      URL: {
        SCHEDULE_DETAILS_PRINT_MANIFEST_CUSTOM: EndPoint('scheduleDetail/manifest/custom'),
        SCHEDULE_DETAILS_GET_ROUTE_BLOCK: EndPoint('schedulesDetails/routes/routes_block'),
        ATTACH_DOCUMENT_TO_ROUTE_BLOCK: EndPoint('routeBlocks/attach'),
      }
    }
  }
;
