import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class DriverManifestService {

  load_details$ = new Subject<any>();
  resetFormManifest$ = new Subject<any>();
  constructor() { }

}
