import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { DashboardLsfDataService } from './../../../dashboard-lsf.data.service';
import { Route } from './../../schedule.model';

@Component({
  selector: 'app-edit-schedule',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClose = new EventEmitter<boolean>();
  @Input() week: Number;
  @Input() datestart: String;
  @Input() dateend: String;

  @Input() arrayBlockExist: Array<Route>;
  @Input() schedule_id: number;

  public busy: Subscription;
  public routes_left: Array<Route> = new Array<Route>();
  public routes_right: Array<Route> = new Array<Route>();

  public routes_delete: Array<Route> = new Array<Route>();

  routes: any;
  public bntIndividual1: Boolean = true;
  public bntIndividual2: Boolean = true;

  public schedule: any = {};
  public schedule_routes: Array<any> = new Array<any>();
  public apiResponse: any;

  routes_left_select: any;
  weekSelected = false;
  routes_right_select: any;

  constructor(private  _dataService: DashboardLsfDataService) {
  }

  ngOnInit() {
    this.loadData();
  }

  close() {
    this.onClose.emit(true);
  }

  loadData() {
    this.loadRouteBlocks();
    this.routes_right = this.arrayBlockExist;
  }

  loadRouteBlocks() {
    this.busy = this._dataService.getData('blocksByActive/1').subscribe(
      (data: any) => {
        this.arrayBlockExist.forEach((obj, index) => {
          this.deleteElementArray(obj.id, data);
        });
        this.routes = data;
        this.routes_left = data;
      }
    );
  }

  moveAll(from, to) {
    if (from != null) {
      for (let entry of from) {
        to.push(entry);
      }
      from.length = 0;
    }
  }

  moveItem(item, from, to) {
    if (item === undefined) {
    } else {
      for (var x = 0; x <= item.length - 1; x++) {
        let idx = this.getElementoArray(item[x], from);
        if (idx.length > 0) {
          // from.splice(idx[0], 1);
          this.deleteElementArray(item[x], from);
          to.push(idx[0]);
        }
      }
    }

    this.bntIndividual1 = true;
    this.bntIndividual2 = true;
  }

  getElementoArray(id: any, list: Array<Route>) {
    let x = list.filter(function (item) {
      return item.id == id;
    });
    return x;
  }

  deleteElementArray(id: any, list: Array<Route>) {
    list.forEach(function (data, index) {
      if (data.id == id) {
        list.splice(index, 1);
      }
    });
  }

  selectedItem(value: any, col: number) {
    if (value = !0) {
      if (col === 1) {
        this.bntIndividual1 = false;
        this.bntIndividual2 = true;
      } else {
        this.bntIndividual2 = false;
        this.bntIndividual1 = true;
      }
    }
  }

  onUpdate() {
    let year = new Date();
    const ano = year.getFullYear();
    if (this.routes_right.length >= 1 && (this.week != 0)) {
      this.schedule.week = this.week;
      this.schedule.year = ano;
      this.schedule.datestart = this.datestart;
      this.schedule.dateend = this.dateend;
      this.schedule.active = 1;

      this.clearJSON(this.routes_right);
      this.schedule.schedule_routes = this.schedule_routes;

      this.busy = this._dataService.putData(this.schedule, 'schedules/routes/' + this.schedule_id).subscribe(
        (data: any) => {
          console.log(JSON.stringify(data));
          this.apiResponse = data;
          if (this.apiResponse.transaction) {
            this.notify.emit('update record');
            this.close();
          } else {
            alert(this.apiResponse.message);
          }
        });
    } else {
      alert('select a week, and at least one routes-block');
    }
  }

  clearJSON(route: Array<any>) {
    this.schedule_routes = [];
    for (let entry of route) {
      let t: any = {};
      t.route_block_id = entry.id;
      t.route_id = entry.route_id;

      this.schedule_routes.push(t);
    }
  }

}
