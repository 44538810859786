import { Injectable } from '@angular/core';
import * as PusherPushNotifications from '@pusher/push-notifications-web';
import { environment } from '../../../../environments/environment';
import { NgxPermissionsService } from 'ngx-permissions';

const NOTIFY = [
  {
    event: 'driver_deviate',
    access: 'driverOutRute',
  },
  {
    event: 'driver_late_arrival',
    access: 'driverLateLastStop',
  },
];

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private ngxPermissionsService: NgxPermissionsService
  ) { }

  initService() {
    PusherPushNotifications.init({
      instanceId: environment.pusherBeams,
    }).then(beamsClient =>
      beamsClient
        .start()
        .then(() => beamsClient.clearDeviceInterests())
        .then(async () => {
          const notificaciones = [];
          await NOTIFY.forEach(async element => {
            await this.ngxPermissionsService.hasPermission(element.access).then((value) => {
              if (value) {
                console.log('access to: ', element.event);
                notificaciones.push(beamsClient.addDeviceInterest(element.event));
              }
            });
          });
          return notificaciones;
        })
        .then(() => console.log('Successfully registered and subscribed! Device ID:', beamsClient.deviceId))
        .then(() => beamsClient.getDeviceInterests())
        .then(interests => console.log('Current interests:', interests))
    ).catch(console.error);
  }

  clearInterest() {
    console.log('cleaning.. interests...');

    PusherPushNotifications.init({
      instanceId: environment.pusherBeams,
    }).then(beamsClient =>
      beamsClient
        .start()
        .then(() => beamsClient.clearDeviceInterests())
        .then(() => beamsClient.getDeviceInterests())
        .then(interests => {
          interests.forEach(element => {
            beamsClient.removeDeviceInterest(element);
          });
        })
    ).catch(console.error);
  }

}
