import { DatePipe, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { DriverService, ManifestsMovilService } from '../../../../services/services.index';
import { AdminDataService } from '../driver-manifest/driver.data.service';
import { Driver, Vehicle } from '../driver-manifest/models';
import Swal from 'sweetalert2';

declare let $: any;

@Component({
  selector: 'app-manifest-details',
  templateUrl: './manifest-details.component.html',
  styleUrls: ['./manifest-details.component.scss'],
  providers: [AdminDataService]
})

export class ManifestDetailsComponent implements OnInit {

  public busy: Subscription;
  public gridInterruptionsMovil: GridOptions;
  public gridInterruptions: GridOptions;
  public gridStops: GridOptions;
  jsonObj: any;
  public drivers: Array<Driver> = [];
  public vehicles: Array<Vehicle> = [];

  /**
   * Variables para majar el show de los errores en los campor Odometer
   * @type {boolean}
   */
  leave_yard_time_odometer_boolen = false;
  first_stop_time_odometer_boolen = false;
  last_stop_time_odometer_boolen = false;
  return_to_yard_time_odometer_boolen = false;

  public time_format = [/[0-9]/, /\d/, ':', /\d/, /\d/];
  odometer_validation_1 = '0';
  odometer_validation_2 = '0';
  odometer_validation_3 = '0';

  /**
   * @description Esta variable almacena el mensaje que se muestra en la propiedad Title del icono
   * que muestra el error.
   * @type {string}
   */
  public title_error = 'Unable to add records, please review and try again.';
  driverManifestId = undefined;

  constructor(
    private activatedRoute: ActivatedRoute,
    private _serviceManifestsMovil: ManifestsMovilService,
    public _location: Location,
    private _dataService: AdminDataService,
    private _serviceDriver: DriverService,
  ) {
    this.loadGridInterruptions();
    this.loadGridStops();
  }

  loadGridInterruptions() {
    this.gridInterruptionsMovil = <GridOptions>{
      enableSorting: true,
      sortingOrder: ['desc', 'asc', null]
    };
    this.gridInterruptions = <GridOptions>{
      enableSorting: true,
      sortingOrder: ['desc', 'asc', null]
    };
    this.gridInterruptionsMovil.columnDefs = [
      { headerName: 'Id', field: 'id', width: 100, sort: 'desc', hide: true },
      { headerName: 'Status', valueGetter: this.getBlockStatu, width: 100 },
      { headerName: 'Time', field: 'time', valueGetter: this.getBlockTime, width: 70 },
      { headerName: 'Details/Resolution', field: 'description', valueGetter: this.getDescription, width: 140 },
      { headerName: 'Veh', field: 'veh', valueGetter: this.getVehicle, width: 54 },
      { headerName: 'Odometer', field: 'odometer', width: 90 },
      { headerName: 'Driver', field: 'driver', valueGetter: this.getDriver, width: 80 },
      { headerName: 'Supervisor', field: 'supervisor', valueGetter: this.getSupervisor, width: 80 },
    ];
    this.gridInterruptions.columnDefs = [
      { headerName: 'Id', field: 'id', width: 100, sort: 'desc', hide: true },
      { headerName: 'Status', valueGetter: this.getBlockStatu, width: 100 },
      { headerName: 'Time', field: 'time', valueGetter: this.getBlockTime, width: 70 },
      { headerName: 'Details/Resolution', field: 'description', valueGetter: this.getDescription, width: 140 },
      { headerName: 'Veh', field: 'veh', valueGetter: this.getVehicle, width: 54 },
      { headerName: 'Odometer', field: 'odometer', width: 90 },
      { headerName: 'Driver', field: 'driver', valueGetter: this.getDriver, width: 80 },
      { headerName: 'Supervisor', field: 'supervisor', valueGetter: this.getSupervisor, width: 80 },
    ];
 }

 loadGridStops() {
   this.gridStops = <GridOptions>{
     enableSorting: true,
     sortingOrder: ['desc', 'asc', null]
   };
   this.gridStops.columnDefs = [
     { headerName: 'Stop', field: 'stop', width: 100, sort: 'desc'},
     { headerName: 'AMB', field: 'amb', width: 90 },
     { headerName: 'WC', field: 'wc', width: 90 },
     { headerName: 'BK', field: 'str', width: 90 },
   ];
}
  getDriver(row) {
    if (row.data.driver !== null) {
      return `${row.data.driver.first_name}  ${row.data.driver.last_name}`;
    } else {
      return '';
    }
  }

  getSupervisor(row) {
    if (row.data.supervisor !== null) {
      return `${row.data.supervisor.first_name}  ${row.data.supervisor.last_name}`;
    } else {
      return '';
    }
  }

  getVehicle(row) {
    if (row.data.vehicle !== null) {
      return `${row.data.vehicle.description}`;
    } else {
      return '';
    }
  }

  getDescription(row) {
    if (row.data.incident !== null) {
      return `${row.data.incident.description} - ${row.data.details}`;
    } else {
      return row.data.details;
    }
  }

  getBlockTime(row) {
    const dateNow = new Date();
    const datePipe = new DatePipe('en-USA');
    const date = row.data.created_at;
    const dateRecord = datePipe.transform(date, 'yyyy-MM-dd');
    if (dateRecord === datePipe.transform(dateNow, 'yyyy-MM-dd')) {
      return row.data.time;
    } else {
      return datePipe.transform(row.data.created_at, 'yyyy-MM-dd hh:mm:ss');
    }
  }

  getBlockStatu(row) {
    if (row.data.blockstatu !== null) {
      return `${row.data.blockstatu.description}`;
    } else {
      return '';
    }
  }

  ngOnInit() {
    this.loadDrivers();
    this.loadVehicles();
    this.loadData();
  }

  loadData() {
    this.activatedRoute.params.subscribe((params: Params) => {
      const id = params['id'];
      this.driverManifestId = id;
      this.getDriverManifestMovil(id);
    });
  }

  getDriverManifestMovil(id: number) {
    this.busy = this._serviceManifestsMovil.getManifest(id).subscribe(
      data => {
        this.jsonObj = data;
        this.onOutOdometer('leave_yard_time');
        this.onOutOdometer('first_stop_odometer');
        this.onOutOdometer('last_stop_odometer');
        this.onOutOdometer('return_to_yard_odometer');
        this.gridStops.api.setRowData(data.stops);

        if (data.interruptions && data.interruptions.length > 0) {
          this.gridInterruptionsMovil.api.setRowData(data.interruptions);
          this.loadInfoServiceInterruption(this.jsonObj.schedule_detail_id);
        }
      }
    );
  }

  onUpdateMaster() {
    const odValidate = this.verificarOdometer();
    if (odValidate) {
      const tem = JSON.parse(JSON.stringify(this.jsonObj));
      delete tem.client;
      delete tem.driver;
      delete tem.interruptions;
      delete tem.route_block;
      delete tem.vehicle;
      this.clearStops(tem);
      this.busy = this._dataService.postData(tem, 'driverManifest').subscribe(
        (data: any) => {
          if (data.transaction) {
            this.loadData();
          } else {
            Swal.fire(
              'Error!',
              data.message,
              'error'
            );
          }
        }
      );
    }
  }

  onUpdateManifest() {
    this.verifyStatus(this.jsonObj.schedule_detail_id).then((resp) => {
      if (resp) {
        const odValidate = this.verificarOdometer();
        if (odValidate) {
          const tem = JSON.parse(JSON.stringify(this.jsonObj));
          this.clearStops(tem);
          this.onUpdateMaster();
        }
      } else {
        Swal.fire({
          type: 'warning',
          title: 'The selected block is not finished yet.'
        });
      }
    });
  }

  clearStops(tem: any) {
    tem.stops.forEach(row => {
      delete row.driver_manifest_movil_id;
      delete row.id;
    });
  }

  onOutOdometer(id) {
    let r;
    switch (id) {
      case 'first_stop_odometer':
        // Validar que la diferencia entre el leave yard odometer y el first stop odometer no puede ser mayor que 50
        r = this.validatedRange(this.jsonObj.first_stop_odometer, this.jsonObj.leave_yard_odometer, 50);
        this.odometer_validation_1 = r.difference;
        // console.log(r);

        if (!r.operation || (r.difference < 0)) {
          $('#odometer_validation_1').removeClass('label-default');
          $('#odometer_validation_1').addClass('label-danger');
        } else {
          $('#odometer_validation_1').removeClass('label-danger');
          $('#odometer_validation_1').addClass('label-default');
        }
        break;
      case 'last_stop_odometer':
        // Validar que la diferencia entre el first stop odometer y el last stop odometer no puede ser mas de 250.
        r = this.validatedRange(this.jsonObj.last_stop_odometer, this.jsonObj.first_stop_odometer, 250);
        this.odometer_validation_2 = r.difference;
        if (!r.operation || (r.difference < 0)) {
          $('#odometer_validation_2').removeClass('label-default');
          $('#odometer_validation_2').addClass('label-danger');
        } else {
          $('#odometer_validation_2').removeClass('label-danger');
          $('#odometer_validation_2').addClass('label-default');
        }
        break;
      case 'return_to_yard_odometer':
        // Validar que la diferencia entre el last stop odometer y el return to yard odometer no puede ser mayor a 250
        r = this.validatedRange(this.jsonObj.return_to_yard_odometer, this.jsonObj.leave_yard_odometer, 250);
        this.odometer_validation_3 = r.difference;
        if (!r.operation || (r.difference < 0)) {
          $('#odometer_validation_3').removeClass('label-default');
          $('#odometer_validation_3').addClass('label-danger');
        } else {
          $('#odometer_validation_3').removeClass('label-danger');
          $('#odometer_validation_3').addClass('label-default');
        }
        break;
      default:
    }
  }

  /**
   * description: Esta funcion, Validar que la diferencia entre el
   * minuendo y el sustraendo no sea mayor a un validador numerico dado
   */

  validatedRange(minuendo: any, sustraendo: any, validador: any) {
    const min = parseInt(minuendo);
    const sus = parseInt(sustraendo);
    const val = parseInt(validador);
    const result = (min - sus);
    const r = (result <= val) ? true : false;
    return { operation: r, difference: result };
  }

  /**
   * @description Este metodo se encarga de validar los valores de los campor Odometer, si se precenta algun
   * error en en la secuencia de menor a mayor en los campor de odometer retornamos un valor boolena, true en caso
   * de que se precenter un error y false en caso contrario.
   * @returns {boolean}
   */
  verificarOdometer() {
    const num1 = this.jsonObj.leave_yard_odometer;
    const num2 = this.jsonObj.first_stop_odometer;
    const num3 = this.jsonObj.last_stop_odometer;
    const num4 = this.jsonObj.return_to_yard_odometer;

    if (num2 >= num1) {
      this.first_stop_time_odometer_boolen = false;
    } else {
      this.first_stop_time_odometer_boolen = true;
    }

    if (num3 > num1) {
      if (num3 > num2) {
        this.last_stop_time_odometer_boolen = false;
      } else {
        this.last_stop_time_odometer_boolen = true;
      }
    } else {
      this.last_stop_time_odometer_boolen = true;
    }

    if (num4 > num1) {
      if (num4 < num3) {
        this.return_to_yard_time_odometer_boolen = true;
      } else {
        this.return_to_yard_time_odometer_boolen = false;
      }
    } else {
      this.return_to_yard_time_odometer_boolen = true;
    }

    if (
      this.first_stop_time_odometer_boolen ||
      this.last_stop_time_odometer_boolen ||
      this.return_to_yard_time_odometer_boolen
    ) {
      return false;
    } else {
      return true;
    }
  }

  loadInfoServiceInterruption(schedule_seleccionado: number) {
    const endpointInterruptionService = 'servicesInterruptions';
    this.busy = this._dataService.
      getData(endpointInterruptionService + '/scheduledetails/' + schedule_seleccionado).subscribe(
      (data: any) => {
          if (data !== null) {
            this.gridInterruptions.api.setRowData(data);
          }
        }
      );
  }

  /***
   * @description
   */
  loadDrivers() {
    this.busy = this._serviceDriver.getDriversStatus(false).subscribe(
      data => {
        this.drivers = this._serviceDriver.orderDriver(data);
      }
    );
  }

  /**
   * @description Este metodo se encarga de consumir el servicio que retorna todos los Veiculos
   */
  loadVehicles() {
    this.busy = this._dataService.getData('vehicles/active/1').subscribe(
      (data: any) => {
        this.vehicles = data;
      }
    );
  }

  verifyStatus(schedule_details_id: number) {
    return new Promise((resolve, reject) => {
      this.busy = this._dataService.getData(`schedulesDetails/status/${schedule_details_id}`).subscribe(
        (resp: any) => {
          if (resp.id === 5) {
            resolve(true);
          }else {
            resolve(false);
          }
        }
      );
    });
  }

}
