import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AdminDataService } from '../fleet.data.service';
import { Router } from '@angular/router';
import { BsModalComponent } from 'ng2-bs3-modal';
import AgGridHelpers from '../../../helpers/ag-grid-helpers';

@Component({
  selector: 'app-route-block',
  templateUrl: './route-block.component.html',
  styleUrls: ['./route-block.component.scss']
})
export class RouteBlockComponent implements OnInit, AfterViewInit, OnDestroy {
  public gridRoute: GridOptions;
  public openForm = false;
  public busy: Subscription;
  public routeSekected = 0;
  public showStatus = 1;
  public savedModel: any;
  titleCountGrid: string;
  dataLength: number;

  constructor(private  _dataService: AdminDataService,
              public toastr: ToastrService,
              private route: Router) {
    this.setConfigGrid();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.busy ? this.busy.unsubscribe() : undefined;
  }

  setConfigGrid() {
    this.gridRoute = <GridOptions> {
      enableSorting: true,
      sortingOrder: ['desc', 'asc', null],
      onGridReady: () => {
        this.loadData(this.showStatus);
      },
      columnDefs: [
        { headerName: 'Clients', field: 'client.client_name', width: 150 },
        { headerName: 'Route Description', field: 'description', width: 150 },
        { headerName: 'Status', width: 70,
          cellRenderer: (params) => AgGridHelpers.statusRecord(params.data.active)
        },
        { headerName: '# Blocks', valueGetter: this.countBlock, width: 50,
          cellStyle: {textAlign: 'center'}
        },
        { headerName: 'Stops', field: 'tso_id', width: 68, colId: 'map',
          cellStyle: { textAlign: 'center' },
          cellRenderer: (params) => {
            return params.data.tso_id ?
              `<span class="label label-success" style="margin-top: 4px;">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
            </span>
            ` : '';
          }
        }
      ],
      onFilterModified: () => {
        this.setCounter()
      }
    };
  }

  onCellClicked(event: any) {
    if (event.colDef.colId === 'map' && event.data.tso_id) {
      this.route.navigateByUrl(`fleet/routes-block/tso/map/${event.data.tso_id}/${event.data.id}`);
    } else {
      this.routeSekected = event.data.id;
    }
  }

  OnCellDoubleClicked($event) {
    this.routeSekected = $event.data.id;
    this.aplicarFiltro();
    this.onEditRoute();
  }

  aplicarFiltro() {
    this.savedModel = this.gridRoute.api.getFilterModel();
  }

  restoreFilterModel() {
    this.gridRoute.api.setFilterModel(this.savedModel);
    this.gridRoute.onFilterChanged = function (params) {

    };
  }

  onNewRoute() {
    this.openForm = !this.openForm;
  }

  onClose(event) {
    this.onNewRoute();
    this.routeSekected = 0;
    if (event) {
      this.loadData(this.showStatus);
    }
  }

  onNotify(mensaje: any) {
    this.toastr.success(mensaje);
    this.loadData(this.showStatus);
  }

  countBlock(row) {
    return row.data.route_blocks.length;
  }

  loadData(active: Number) {
    let url: String = '';
    if (active == 0)
      url = 'routes';
    else
      url = 'routes/active/1';

    this.busy = this._dataService.getData('' + url).subscribe(
      (data: any) => {
        this.gridRoute.api.setRowData(data);
        this.dataLength = data.length;
        this.setCounter();
        this.restoreFilterModel();
      }
    );
  }

  private setCounter() {
    const cant = this.gridRoute.api.getDisplayedRowCount();
    this.titleCountGrid = `Routes ${cant} of ${this.dataLength}`;
  }

  onEditRoute() {
    this.onNewRoute();
  }

  changeShowStatus(data?: any) {
    if (this.showStatus == 1) {
      this.showStatus = 0;
    } else {
      this.showStatus = 1;
    }
    this.loadData(this.showStatus);
  }


}
