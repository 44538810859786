import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CONSTANT, CONSTANTS } from '../../app.constants';
import { BaseService } from '../../core/BaseService';
import { IResponse } from '../../CORE/interfaces/response.interface';
import { IDeviceVehicle } from '../../CORE/interfaces/vehicle.interface';

@Injectable()
export class VehicleService extends BaseService {
  protected resource: string;

  constructor(private http: HttpClient) {
    super();
    this.resource = environment.url_api_v + CONSTANTS.VEHICLES;
  }

  /**
   * @description Return all vehicles
   * @returns {Observable<any>}
   */
  getVehicles(): Observable<any> {
    return this.http.get<any>(this.resource).pipe(catchError(this.handleError));
  }

  /**
   * @description Save a vehicle
   * @param data
   * @returns {Observable<Response>}
   */
  storeVehicle(data: any): Observable<Response> {
    return this.http.post<Response>(this.resource, JSON.stringify(data));
  }

  /**
   * @description Update a vehicle
   * @param data
   * @returns {Observable<Response>}
   */
  updateVehicle(data: any, id: number): Observable<Response> {
    return this.http.put<Response>(
      this.resource + '/' + id,
      JSON.stringify(data)
    );
  }

  /**
   * @description Get a vehicle
   * @param {number} id
   * @returns {Observable<any>}
   */
  getVehicle(id: number): Observable<any> {
    return this.http.get(this.resource + '/' + id);

  }

  /**
   * @description
   * @param {boolean} status
   * @returns {Observable<any>}
   */
  getVehiclesStatus(status: boolean): Observable<any> {
    if (status) {
      return this.http.get(this.resource);
    } else {
      return this.http.get(this.resource + '/active/1');
    }
  }

  getDeviceVehicle() {
    const url = CONSTANT.VEHICLE.URL.GET_VEHICLE_DEVICE;
    return this.http.get<IResponse<IDeviceVehicle[]>>(url);
  }

  /**
   * @description
   */
  changeVehicleStatus(data: any, id: number): Observable<Response> {
    return this.http.put<Response>(
      environment.url_api_v + 'vehicles/active/' + id,
      JSON.stringify(data)
    );
  }

  /***
   * @description
   * @returns {Observable<any>}
   */
  getAllVehicleStatus(): Observable<any> {
    return this.http.get(environment.url_api_v + 'vehiclesStatus');
  }

  /***
   * @description
   * @returns {Observable<any>}
   */
  getAllVehicleTypes(): Observable<any> {
    return this.http.get(environment.url_api_v + 'vehiclesTypes');
  }

  getVehicleIntegration() {
    return this.http.get(this.resource + '/active/1');
  }

  storeVehicleIntegration(data: any[]) {
    return this.http.post<Response>(this.resource + '/storeVehicleIntegration', JSON.stringify(data));
  }

  deleteIdsTSOVehicle() {
    return this.http.get(this.resource + '/deleteIdsTSOVehicle/all');
  }

  deleteIdTSOVehicle(id: number) {
    return this.http.get(this.resource + '/deleteIdTSOVehicle/' + id);
  }

  getVehicleManifest(id: any) {
    return this.http.get<Response>(environment.url_api_v + 'driverManifestVehicles/' + id);
  }
  saveVehicleManifest(data: any) {
    return this.http.post<Response>(environment.url_api_v + 'driverManifestVehicles/add', data);
  }
  updateVehicleManifest(id, data: any) {
    return this.http.put<Response>(environment.url_api_v + 'driverManifestVehicles/update/' + id, data);
  }

  setAsLastVehicleManifest(id) {
    return this.http.put<Response>(environment.url_api_v + 'driverManifestVehicles/setaslast/' + id, {});
  }

  syncGPS() {
    return this.http.put(`${environment.url_api_v}vehicles/gps/sync`,{});
  }

  getVehicleGps() {
    return this.http.get(`${environment.url_api_v}vehicles/vehiclesGps/all`);
  }

  getRecordOdometerManifiest(manifest_id: number, month: number, year: number) {
    return this.http.get(`${this.resource}/history/odometer/byManifest/${manifest_id}/${month}/${year}`);
  }

  getRecordOdometerVehicle(vehicle_id: number, month: number, year: number) {
    return this.http.get(`${this.resource}/history/odometer/byVehicle/${vehicle_id}/${month}/${year}`);
  }

}
