import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CONSTANTS } from '../../app.constants';

export interface AttachImage {
  id?: number;
  user_id?: number;
  vehicle_id: number;
  description: string;
  file: any;
  type?: string;
}

@Injectable()
export class AttachImageService {
  protected resource: string;

  constructor(private http: HttpClient) {
    this.resource = environment.url_api_v + CONSTANTS.ATTACHS_IMAGES;
  }

  /**
   * @description Return all drivers
   * @returns {Observable<any>}
   */
  getAttachsImages(): Observable<any> {
    return this.http.get<any>(this.resource);
  }

  /**
   * @description Save a driver
   * @param data
   * @returns {Observable<Response>}
   */
  storeAttachImage(data: any): Observable<Response> {
    return this.http.post<Response>(this.resource, JSON.stringify(data));
  }

  deleteAttachImage(vehicle_id: number) {
    return this.http.put(this.resource + '/destroy/image', vehicle_id);
  }

  /**
   * @description Update a driver
   * @param data
   * @returns {Observable<Response>}
   */
  updateAttachImage(data: any, id: number): Observable<Response> {
    return this.http.put<Response>(
      this.resource + '/' + id,
      JSON.stringify(data)
    );
  }

  /**
   * @description Get a driver
   * @param {number} id
   * @returns {Observable<any>}
   */
  getAttachImage(id: number): Observable<any> {
    return this.http.get(this.resource + '/' + id);
  }

  /**
   * @description
   * @param {boolean} status
   * @returns {Observable<any>}
   */
  getAttachsImagesStatus(status: boolean): Observable<any> {
    if (status) {
      return this.http.get(this.resource);
    } else {
      return this.http.get(this.resource + '/active/1');
    }
  }

  getFile(file: string) {
    return environment.url + 'storage/' + file;
  }

  /**
   * @description Return all drivers
   * @returns {Observable<any>}
   */
  getAttachsImagesForVehicle(vehicle_id: number): Observable<any> {
    return this.http.get<any>(this.resource + '/vehicle/' + vehicle_id);
  }

}
