
import { Injectable } from '@angular/core';
import { throwError as observableThrowError } from 'rxjs';

@Injectable()
export class Configuration {

  public getHeader() {
    return {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'namedb': localStorage.getItem('namedb')
      }
    };
  }

  public handleError(error: Response) {
    return observableThrowError(error);
  }

}
