import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GridOptions, ColDef } from 'ag-grid-community';
import { ClientService } from '../../../../services/fleet/client.service';
import { BatchesService } from '../../../../services/billing/batches.service';
import * as momentHelper from '../../../../helpers/moment-helpers';
import {preparingDataAddBatch } from '../../../../helpers/batch-helpers';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-verified-to-bill',
  templateUrl: './verified-to-bill.component.html',
  styleUrls: ['./verified-to-bill.component.scss']
})
export class VerifiedToBillComponent implements OnInit, OnDestroy {
  @Input() clearGrid ?: any;
  suscriptionSummary: Subscription[] = [];
  gridBatchVerified: GridOptions;
  gridData: any[] = [];
  dataBatchVerified = [];
  btnCollapseStatus = false;
  clients: any[] = [];
  loading:boolean = false;
  reload:string = 'Reload';
  hover_buttonspan:string = 'Expand';
  loading_general:boolean = false;

  constructor(private clientService: ClientService, private batchesService: BatchesService) {
    this.initGrid();
  }

  ngOnInit() {
    this.loading_general = true;
    this.loadData();
  }

  ngOnDestroy() {
    this.suscriptionSummary.forEach(suscription => suscription.unsubscribe());
  }

  loadData() {
    this.suscriptionSummary.push(this.batchesService.getVerifiedToBill().subscribe((resp) => {
      if(resp && resp.query_verified){
        this.gridBatchVerified.api.setRowData([]);
        this.dataBatchVerified = [];
        this.insertOneV2(resp.query_verified);
        this.gridBatchVerified.api.setRowData(this.dataBatchVerified);
        this.suscriptionSummary.forEach(suscription => suscription.unsubscribe());
        this.loading_general = false;
        this.loading = false;
        this.reload = 'Reload';
        this.clientService.getClientsByDriverManifest().subscribe((resp: any) => {
          if(resp && resp.data.length){
            this.clients = resp.data;
          }
        });
      }
    }));
  }

  generateBatch(params: any) {
    if(params.colDef.colId === 'generate_batch'){
      let total_verified: any = params.node.aggData.verified;
      let dataType: any = params.node;
      if(dataType.field === 'date' && total_verified != 0 ) {
        Swal.fire({
          title: 'Are you sure?',
          text: `create batch of ${total_verified} manifests for the ${dataType.key}`,
          type: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then(result => {
          if (result.value) {
            let date = dataType.key;
            let client_name = dataType.childrenAfterFilter[0].data.client_name;
            let generateBatch = preparingDataAddBatch(date, client_name, this.clients);
            if(generateBatch){
              this.suscriptionSummary.push(this.batchesService.addBatch(generateBatch).subscribe((resp: any) => {
                this.loadData();
                if (resp.status === 'success') {
                  Swal.fire({
                    position: 'top-end',
                    type: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500
                  }).then(() => {
                    this.batchesService.load_batches$.next(generateBatch.client_id);
                    this.batchesService.sendClientIdBatch$.next(generateBatch.client_id);
                  });
                }
              },(resp) => {
                Swal.fire({
                  position: 'top-end',
                  type: 'error',
                  title: 'Error:',
                  footer: resp.message,
                  showConfirmButton: false
                })
              }));
            }
          }
        });
      }
    }
  }

  initGrid() {
    const columnDefs2: ColDef[] = [
      {
        headerName: 'Client',
        colId: 'client_name',
        field: 'client_name',
        width: 180,
        rowGroup: true,
        hide: true,
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          suppressCount: true,
          suppressDoubleClickExpand: true,
          suppressEnterExpand: true,
        },
      },
      { headerName: 'Date', field: 'date', width: 110, rowGroup: true, hide: true },
      { headerName: 'Route', field: 'route', width: 220, aggFunc: 'count' },
      { headerName: 'Verified', field: 'verified', width: 100, aggFunc: 'sum', cellStyle: {textAlign: "right"}},
      {
        headerName: 'Actions', width: 120, colId: 'generate_batch', cellRenderer: (params) => {
          let total_verified: any = params.node.aggData ? params.node.aggData.verified : null;
          let field: any = params.node.field;
          if(total_verified && field === 'date'){
            return `<button class="btn btn-sm btn-generate-batch" style="background-color: #002f60; color: white;"> Generate Batch</button>`;
          }
        }
      },
    ];
    this.gridBatchVerified = <GridOptions>{
      columnDefs: columnDefs2,
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      enableFilter: true,
      suppressAggFuncInHeader: true,
      onGridReady: () => {
        this.gridBatchVerified.api.setRowData([]);
      },
      sortable: true,
      resizable: true,
      animateRows: true,
      groupDisplayType: 'singleColumn',
      autoGroupColumnDef: {
        headerName: 'Clients',
        cellRendererParams: {
          suppressCount: true
        }
      }
    };
  }

  evalWeek(range_date: string, year: string){
    const numWeekToDay = momentHelper.numWeekToDay(year);
    return range_date === "53"  && numWeekToDay === 52  ? `1 - ${Number(year)+1}` : `${range_date} - ${year}`;
  }

  insertOneV2(list: any) {
    if(list  && list.length){
      const dataGrillResult: any[] = [];
      for(let i = 0; i < list.length; i++){
        const detailsList = list[i];
        const details: any = {
          client_name: detailsList.client_name,
          date: detailsList.type_range_date === "week" ? `Week ${this.evalWeek(detailsList.range_date, detailsList.YEAR)}` : detailsList.DATE,
          route: detailsList.route,
          verified: Number(detailsList.verified)
        };
        dataGrillResult.push(details);
      }
      for(let t = 0; t < dataGrillResult.length; t++) {
        this.dataBatchVerified.push(dataGrillResult[t]);
      }
    }
  }

  onCollapse() {
    if (this.gridBatchVerified && this.gridBatchVerified.api) {
      this.btnCollapseStatus = !this.btnCollapseStatus;
      return (!this.btnCollapseStatus) ? this.gridBatchVerified.api.collapseAll() : this.gridBatchVerified.api.expandAll();
    }
  }

  onReload() {
    this.loading = true;
    this.reload = 'Loading';
    this.loadData();
  }
}
