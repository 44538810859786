export class Driver {
  id: number;
  last_name: string;
  first_name: string;
  mi?: any;
  ssn: string;
  date_birth?: any;
  gender: string;
  driver_license: string;
  license_expiry?: any;
  email?: any;
  street_address: string;
  street_address_two?: any;
  city?: any;
  state?: any;
  zip?: any;
  phone?: any;
  active: number;
  created_at: string;
  updated_at: string;
}

export class ScheduleDetail {
  id: number;
  schedule_route_id: number;
  driver_id: number;
  week_day_id: number;
  report_time: string;
  start_time: string;
  end_time: string;
  punch_out_time: string;
  created_at: string;
  updated_at: string;
  driver: Driver;
}

export class ScheduleRoute {
  id: number;
  schedule_id: number;
  route_id: number;
  active: number;
  created_at: string;
  updated_at: string;
  schedule_details: ScheduleDetail[];
  route: Route;
}

export class Schedule {
  id: number;
  week: number;
  year: number;
  datestart: string;
  dateend: string;
  active: number;
  created_at: string;
  updated_at: string;
  schedule_routes: ScheduleRoute[];

}


/**
 * Created Septiembre 2017
 * @description Esta clase la utilizo para armar la estructura de arbol en Ag-Grid
 */
export class TempSchedule {
  route_id: number;
  route_code: string;
  description: string;
  monday: string;
  tuesday: string;
  wednesday: string;
  thrusday: string;
  friday: string;
  saturday: string;
  sunday: string;
  schedules_details: Array<TempScheduleDetail> = new Array<TempScheduleDetail>();
}

export interface Route {
  id: number;
  client_id: number;
  division_id: number;
  route_code: string;
  description: string;
  active: number;
  created_at: string;
  updated_at: string;
}

/**
 * Created Septiembre 2017
 * @description Esta clase la utilizo para armar la estructura de arbol en Ag-Grid, depende de la estructura
 * de TempSchedule
 */
export class TempScheduleDetail {
  route_code: string;
  description: string;
  monday: string;
  tuesday: string;
  wednesday: string;
  thrusday: string;
  friday: string;
  saturday: string;
  sunday: string;
}
