import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../_guards/auth.guard';
import { DriversComponent } from '../fleet/drivers/drivers.component';
import { ClientsComponent } from './clients/clients.component';
import { DriversRoutesComponent } from './drivers-routes/drivers-routes.component';
import { IntegrationsComponent } from './drivers/components/integrations/integrations.component';
import { IntegrationComponent } from './routes-block/components/integration/integration.component';
import { RouteBlockComponent } from './routes-block/route-block.component';
import { IntegrationsVehiclesComponent } from './vehicles/components/integrations-vehicles/integrations-vehicles.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { PagesSmartComponent } from './../../theme/components/pages-smart/pages-smart.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { MapStopsComponent } from './routes-block/components/integration/map-stops/map-stops.component';
import { RouteGroupComponent } from './route-group/route-group.component';

const routes: Routes = [
  {
    path: 'fleet',
    component: PagesSmartComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'drivers',
        component: DriversComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          title: 'Drivers',
          permissions: {
            only: ['route-drivers'],
            redirectTo: '/noauthorized'
          }
        }
      },
      {
        path: 'drivers/tso',
        component: IntegrationsComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          title: 'Drivers TSO',
          permissions: {
            only: ['route-drivers-tso'],
            redirectTo: '/noauthorized'
          }
        }
      },
      {
        path: 'clients',
        component: ClientsComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          title: 'Clients',
          permissions: {
            only: ['route-clients'],
            redirectTo: '/noauthorized'
          }
        }
      },
      {
        path: 'routes-block',
        component: RouteBlockComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          title: 'Routes Block',
          permissions: {
            only: ['route-routes-block'],
            redirectTo: '/noauthorized'
          }
        }
      },
      {
        path: 'routes-block/tso',
        component: IntegrationComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          title: 'Routes TSO',
          permissions: {
            only: ['route-routes-block-tso'],
            redirectTo: '/noauthorized'
          }
        }
      },
      {
        path: 'routes-block/tso/map/:id/:id_route',
        component: MapStopsComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          title: 'Map Stops',
          permissions: {
            only: ['route-routes-block-tso'],
            redirectTo: '/noauthorized'
          }
        }
      },
      {
        path: 'vehicles',
        component: VehiclesComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          title: 'Vehicles',
          permissions: {
            only: ['route-vehicles'],
            redirectTo: '/noauthorized'
          }
        }
      },
      {
        path: 'vehicles/tso',
        component: IntegrationsVehiclesComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          title: 'Vehicle TSO',
          permissions: {
            only: ['route-vehicles-tso'],
            redirectTo: '/noauthorized'
          }
        }
      },
      {
        path: 'drivers-routes',
        component: DriversRoutesComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          title: 'Drivers - Routes',
          permissions: {
            only: ['route-drivers-routes'],
            redirectTo: '/noauthorized'
          }
        }
      },
      {
        path: 'route-group',
        component: RouteGroupComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          title: 'Route Group',
          permissions: {
            only: ['route-group'],
            redirectTo: '/noauthorized'
          }
        }
      }
    ]
  }
];

export const routing = RouterModule.forChild(routes);
