import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CONSTANTS } from '../../app.constants';

export interface InspectionItem {
  id: number;
  description: string;
  vehicle_inspection_categorie_id: number;
  isrequired: boolean;
  status: boolean;
}

@Injectable()
export class InspectionItemsService {
  protected resource: string;

  constructor(private http: HttpClient) {
    this.resource = environment.url_api_v + CONSTANTS.INSPECTION_ITEMS;
  }

  /**
   * @description Return all companies
   * @returns {Observable<any>}
   */
  getInspectionItems(): Observable<any> {
    return this.http.get<any>(this.resource);
  }

  /**
   * @description Save a company
   * @param data
   * @returns {Observable<Response>}
   */
  storeInspectionItem(data: any): Observable<Response> {
    return this.http.post<Response>(this.resource, JSON.stringify(data));
  }

  /**
   * @description Update a Company
   * @param data
   * @returns {Observable<Response>}
   */
  updateInspectionItem(data: any, id: number): Observable<Response> {
    return this.http.put<Response>(
      this.resource + '/' + id,
      JSON.stringify(data)
    );
  }

  /**
   * @description Get a company
   * @param {number} id
   * @returns {Observable<any>}
   */
  getInspectionItem(id: number): Observable<any> {
    return this.http.get(this.resource + '/' + id);
  }

  /**
   * @description
   * @param {boolean} status
   * @returns {Observable<any>}
   */
  getInspectionItemStatus(status: boolean): Observable<any> {
    if (status) {
      return this.http.get(this.resource);
    } else {
      return this.http.get(this.resource + '/active/1');
    }
  }

  /**
   * @description
   */
  changeInspectionItemStatus(data: any, id: number): Observable<Response> {
    return this.http.put<Response>(
      environment.url_api_v + 'company/active/' + id,
      JSON.stringify(data)
    );
  }
}
