import { Component, OnInit, OnDestroy, AfterViewInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { IMyDrpOptions } from 'mydaterangepicker';
import { GridOptions } from 'ag-grid-community';
import { forkJoin, Subscription } from 'rxjs';
import * as moment from 'moment';
import { ClientService } from '../../../services/fleet/client.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InvoiceService } from '../../../services/billing/invoice.service';
import { ScheduleDetailsService } from '../../../services/services.index';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-new-invoice',
  templateUrl: './create-new-invoice.component.html',
  styleUrls: ['./create-new-invoice.component.scss']
})
export class CreateNewInvoiceComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @Input() onClearGrid? = false;

  nameGrid = 'gridUnprocessedDetailBilling';
  dataParams = {};
  grid: GridOptions;
  busy: Subscription[] = [];
  date = new Date();
  myDateRangePickerOptions: IMyDrpOptions = {
    dateFormat: 'yyyy-mm-dd',
    disableSince: { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() }
  };
  clients: any = [];
  routes: any = [];
  form: FormGroup;
  count = 0;

  constructor(
    private clientService: ClientService,
    private fb: FormBuilder,
    private invoiceService: InvoiceService,
    private scheduleDetailsService: ScheduleDetailsService,
  ) {
    this.initGrid();
    this.initForm();
  }

  ngOnInit() {
    this.invoiceService.load_invoices$.subscribe(res => {
      this.dataParams = res;
      this.onSearch();
    });
    this.invoiceService.save_layou_invoices$.subscribe(res => {
      this.saveGridLayout();
    });
  }

  ngAfterViewInit() {
    this.loadConfGrid();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['onClearGrid']) {
      if (this.onClearGrid) {
        this.grid.api.setRowData([]);
      }
    }
  }

  loadConfGrid() {
    this.busy.push(this.scheduleDetailsService.getConfigGrid(this.nameGrid).subscribe(
      data => {
        if (data.data) {
          this.grid.columnApi.setColumnState(JSON.parse(data.data.config));
        }
      }
    ));
  }

  ngOnDestroy() {
    return this.busy = [];
  }

  initForm() {
    this.form = this.fb.group({
      client_id: ['', [Validators.required]],
      route_id: ['', [Validators.required]],
      date: ['', [Validators.required]]
    });
  }

  saveGridLayout() {
    const model = this.grid.columnApi.getColumnState();
    this.busy.push(this.scheduleDetailsService.setConfigGrid(this.nameGrid, JSON.stringify(model)).subscribe(
      data => {
        Swal.fire({
          position: 'top-end',
          type: 'success',
          title: 'Your layout has been saved',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {});
      }
    ));
  }


  initGrid() {
    this.grid = <GridOptions> {
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      rowSelection: 'multiple',
      enableFilter: true,
      onGridReady: () => {
        this.grid.api.setRowData([]);
      },
      columnDefs: [
        { headerName: '', headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true, width: 40 },
        { headerName: 'Date of Service', field: 'dateofservice', width: 120 },
        { headerName: 'Route', field: 'route', width: 180 },
        { headerName: 'Block', field: 'block', width: 180 },
        { headerName: 'Rate', field: 'rate', width: 100, cellStyle: {'text-align': 'justified'} },
        { headerName: 'Total Interruptions', field: 'total_interruptions', width: 140, cellStyle: {'text-align': 'center'} },
        { headerName: 'Route Status', field: 'route_status', width: 140, cellStyle: {'text-align': 'center'} },
        { headerName: 'Invoice', field: 'invoice', width: 100, cellStyle: {'text-align': 'center'} },
        { headerName: 'Billing Status', field: 'billing_status', width: 140, cellStyle: {'text-align': 'center'} },
      ]
    };
  }

  autoSizeAll() {
    const allColumnIds = [];
    this.grid.columnApi.getAllColumns().forEach(function(column: any) {
      allColumnIds.push(column.colId);
    });
    this.grid.columnApi.autoSizeColumns(allColumnIds);
  }

  onChangeClient(client_id: number) {
    this.busy.push(this.clientService.getRoutesByClient(client_id).subscribe((resp: any) => {
      this.routes = resp.data;
    }));
  }

  onSearch() {
    const data = Object.assign({}, this.dataParams);
    this.busy.push(this.invoiceService.getData(data).subscribe((resp: any[]) => {
      this.grid.api.setRowData(resp);
      this.count = resp.length;
      this.autoSizeAll();
    }));
  }

}
