import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridControlsComponent } from './grid-controls-component/grid-controls.component';
import { NgBusyModule } from 'ng-busy';

@NgModule({
  imports: [
    CommonModule,
    NgBusyModule
  ],
  declarations: [GridControlsComponent],
  exports: [
    GridControlsComponent
  ]
})
export class gridControlsModule { }
