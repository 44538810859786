import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { SignaturePad } from 'angular2-signaturepad';
import { Subscription } from 'rxjs';
import { DriverService } from './../../../../../services/fleet/driver.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

class ImageSnippet {
  constructor(public src: string, public file: File, public url?: string) { }
}


@Component({
  selector: 'app-signature-driver',
  styleUrls: ['./signature-driver.component.scss'],
  templateUrl: './signature-driver.component.html'
})
export class SignatureDriverComponent implements OnInit, AfterViewInit {

  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  @Input() driverSekected: number;

  busy: Subscription[] = [];
  imageData: ImageSnippet;
  imageChangedEvent: any = null;
  croppedImage: any = null;
  transform: any = {};
  scale = 1;
  canvasRotation = 0;
  rotation = 0;
  type = null;

  signaturePadOptions: Object = {
    canvasWidth: 600,
    canvasHeight: 300
  };

  constructor(
    private _serviceDriver: DriverService,
    private toastr: ToastrService,
  ) {
    this.imageData = null;
  }

  ngOnInit() {
    this.loadSignature();
  }

  ngAfterViewInit() { }

  create() {
    this.type = 1;
  }
  upload() {
    this.type = 2;
    $('#customFile').click();
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  processFile(imageInput: any) {
    this.imageChangedEvent = imageInput;
  }

  cancel() {
    this.clearCrop();
  }
  save() {
    const src = this.type === 1 ? this.signaturePad.toDataURL() : this.croppedImage;
    console.log(src);
    if (src) {
      this.busy.push(
        this._serviceDriver.saveSignature({
          driver_id: this.driverSekected,
          file: src,
          type: 'png',
        }).subscribe(res => {
          this.clearCrop();
          this.loadSignature();
          this.toastr.success('Signatured saved succesfully');
        })
      );
    }
  }

  loadSignature() {
    this.busy.push(
      this._serviceDriver.getSignature(this.driverSekected).subscribe((res: any) => {
        this.imageData = res.data ? new ImageSnippet(res.data, null) : null;
      })
    );
  }

  clearCrop() {
    (<HTMLInputElement>document.getElementById('customFile')).value = '';
    this.imageChangedEvent = null;
    this.croppedImage = null;
    this.scale = 1;
    this.canvasRotation = 0;
    this.rotation = 0;
    this.transform = {};
    this.type = null;
  }


}
