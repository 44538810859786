import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular/main';
import { TextMaskModule } from 'angular2-text-mask';
import { NgBusyModule } from 'ng-busy';
import { Ng2AutoCompleteModule } from 'ng2-auto-complete';
import { BsModalModule } from 'ng2-bs3-modal';
import { BsDatepickerModule, CollapseModule, TabsModule, TypeaheadModule } from 'ngx-bootstrap';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { AddImageComponent } from '../../components/add-image/add-image.component';
import { DriverPerdiemComponent } from '../../components/driver-perdiem/driver-perdiem.component';
import { EmailsInputModule } from '../../components/emails-input/emails-input.module';
import { HourDirective } from '../../components/hour/hour.directive';
import { DriverPerDiemService } from '../../services/fleet/driver-per-diem.service';
import { InspectionsService } from '../../services/fleet/fleet.index';
import { RoutesService } from '../../services/fleet/routes.service';
import { InterruptionService } from '../../services/interruption.service';
import {
  AttachDocumentService,
  AttachImageService,
  ClientService,
  CompanyService,
  DocumentsService,
  DriverService,
  PermissionService,
  RoleService,
  TsoConfigService,
  TSODriverService,
  UserService, UserTypeService,
  VehicleService
} from '../../services/services.index';
import { RouteService } from '../../services/TSO/TSO.index';
import { TSOVehicleService } from '../../services/TSO/vehicle.service';
import { AuthGuard } from '../../_guards/auth.guard';
import { DashboardLsfModule } from '../dashboard-lsf/dashboard-lsf.module';
import { ThemeModule } from './../../theme/theme.module';
import { ClientsComponent } from './clients/clients.component';
import { CreateClientComponent } from './clients/components/create/create.component';
import { InterruptionComponent } from './clients/components/interruption/interruption.component';
import { ManagementComponent } from './clients/components/management/management.component';
import { TsoConfigComponent } from './clients/components/tso-config/tso-config.component';
import { GridDriversComponent } from './drivers-routes/components/grid-drivers/grid-drivers.component';
import { GridRoutesComponent } from './drivers-routes/components/grid-routes/grid-routes.component';
import { DriversRoutesComponent } from './drivers-routes/drivers-routes.component';
import { CreateDriverComponent } from './drivers/components/create/create.component';
import { IntegrationsComponent } from './drivers/components/integrations/integrations.component';
import { UserDriverComponent } from './drivers/components/user-driver/user-driver.component';
import { DriversComponent } from './drivers/drivers.component';
import { AdminDataService } from './fleet.data.service';
import { routing } from './fleet.routing';
import { BlockComponent } from './routes-block/components/block/block.component';
import { CreateRouteBlockComponent } from './routes-block/components/create/create.component';
import { BlockIntegrationComponent } from './routes-block/components/integration/block-integration/block-integration.component';
import { IntegrationComponent } from './routes-block/components/integration/integration.component';
import { TypeTimeDirective } from './routes-block/components/type-time.directive';
import { RouteBlockComponent } from './routes-block/route-block.component';
import { CreateComponent } from './vehicles/components/create/create.component';
import { InspectionsComponent } from './vehicles/components/inspections/inspections.component';
import { IntegrationsVehiclesComponent } from './vehicles/components/integrations-vehicles/integrations-vehicles.component';
import { VehicleImagesComponent } from './vehicles/components/vehicle-images/vehicle-images.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { CurrencyMaskModule } from 'ngx-currency-mask';
import { RouteStopsComponent } from './routes-block/components/integration/route-stops/route-stops.component';
import { MapStopsComponent } from './routes-block/components/integration/map-stops/map-stops.component';
import { FeeScheduleComponent } from './clients/components/fee-schedule/fee-schedule.component';
import { AddFeeScheduleComponent } from './clients/components/fee-schedule/add-fee-schedule/add-fee-schedule.component';
import { MyDatePickerModule } from 'mydatepicker';
import { SocketTracker } from './../../sockets/socket-tracker.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ShowErrorsModule } from './../../tools/showErrosForm.directive';
import { SignatureDriverComponent } from './drivers/components/signature-driver/signature-driver.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { Angular2SignaturepadModule } from 'angular2-signaturepad';
import { AttachDocumentModule } from '../../components/attach-document/attach-document.module';
import { RunsService } from '../../services/fleet/runs.service';
import { RunsComponent } from './routes-block/components/runs/runs.component';
import { GridSwitchComponent } from '../../components/grid-switch/grid-switch.component';
import { SharedModule } from '../../shared/shared.module';
import { StopsModule } from '../stops/stops.module';
import { AddStopToRouteComponent } from './routes-block/components/integration/route-stops/add-stop-to-route/add-stop-to-route.component';
import { MapModule } from '../../components/map/map.module';
import { SetMapSegmentsComponent } from './routes-block/components/integration/route-stops/set-map-segments/set-map-segments.component';
import { DispatchService } from '../../services/dispatch/dispatch.service';
import { AddPoScheduleComponent } from './clients/components/po-schedule/add-po-schedule/add-po-schedule.component';
import { PoScheduleComponent } from './clients/components/po-schedule/po-schedule.component';
import { ContractsComponent } from './routes-block/components/contracts/contracts.component';
import { ContractAddComponent } from './routes-block/components/contracts/contract-add/contract-add.component';
import { RouteGroupComponent } from './route-group/route-group.component';
import { ListRouteGroupComponent } from './route-group/components/list-route-group/list-route-group.component';
import { ModalInModule } from '../../components/modal-in/modal-in.module';
import { CreateGroupComponent } from './route-group/components/create-group/create-group.component';
import { FormBillingComponent } from './clients/components/form-billing/form-billing.component';
import { FormBillingGridComponent } from './clients/components/form-billing/form-billing-grid/form-billing-grid.component';
import { FormBillingCardComponent } from './clients/components/form-billing/form-billing-card/form-billing-card.component';
import { GridFeeScheduleComponent } from './clients/components/fee-schedule/grid-fee-schedule/grid-fee-schedule.component';
import { FormFeeScheduleRouteGroupComponent } from './clients/components/fee-schedule/form-fee-schedule-route-group/form-fee-schedule-route-group.component';
import {AngularMultiSelectModule} from 'angular2-multiselect-dropdown';

@NgModule({
  imports: [
    CommonModule,
    routing,
    AgGridModule.withComponents([]),
    FormsModule,
    ReactiveFormsModule,
    NgBusyModule,
    TextMaskModule,
    CollapseModule.forRoot(),
    Ng2AutoCompleteModule,
    TypeaheadModule.forRoot(),
    TabsModule.forRoot(),
    DashboardLsfModule,
    EmailsInputModule,
    PopoverModule.forRoot(),
    BsModalModule,
    ThemeModule,
    BsDatepickerModule.forRoot(),
    CurrencyMaskModule,
    MyDatePickerModule,
    NgxPermissionsModule,
    ShowErrorsModule,
    ImageCropperModule,
    Angular2SignaturepadModule,
    AttachDocumentModule,
    SharedModule,
    StopsModule,
    MapModule,
    ModalInModule,
    AngularMultiSelectModule
  ],
  declarations: [
    DriversComponent,
    CreateDriverComponent,
    ClientsComponent,
    CreateClientComponent,
    RouteBlockComponent,
    CreateRouteBlockComponent,
    BlockComponent,
    VehiclesComponent,
    CreateComponent,
    InspectionsComponent,
    TypeTimeDirective,
    HourDirective,
    ManagementComponent,
    InterruptionComponent,
    UserDriverComponent,
    VehicleImagesComponent,
    AddImageComponent,
    TsoConfigComponent,
    IntegrationsComponent,
    IntegrationsVehiclesComponent,
    IntegrationComponent,
    BlockIntegrationComponent,
    DriverPerdiemComponent,
    DriversRoutesComponent,
    GridDriversComponent,
    GridRoutesComponent,
    RouteStopsComponent,
    MapStopsComponent,
    FeeScheduleComponent,
    AddFeeScheduleComponent,
    SignatureDriverComponent,
    RunsComponent,
    AddStopToRouteComponent,
    SetMapSegmentsComponent,
    PoScheduleComponent,
    AddPoScheduleComponent,
    ContractsComponent,
    ContractAddComponent,
    RouteGroupComponent,
    ListRouteGroupComponent,
    CreateGroupComponent,
    FormBillingComponent,
    FormBillingGridComponent,
    FormBillingCardComponent,
    GridFeeScheduleComponent,
    FormFeeScheduleRouteGroupComponent,
  ],
  providers: [
    AdminDataService,
    AuthGuard,
    ClientService,
    CompanyService,
    InterruptionService,
    DriverService,
    VehicleService,
    AttachDocumentService,
    AttachImageService,
    InspectionsService,
    TsoConfigService,
    TSODriverService,
    TSOVehicleService,
    RouteService,
    DocumentsService,
    RoleService,
    UserService,
    UserTypeService,
    DriverPerDiemService,
    RoutesService,
    SocketTracker,
    PermissionService,
    RunsService,
    DispatchService
  ],
  exports: [
    ListRouteGroupComponent,
    FeeScheduleComponent
  ]
})
export class FleetModule {
}
