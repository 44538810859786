import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { DashboardLsfDataService } from '../../../../dashboard-lsf/dashboard-lsf.data.service';

/**
  * Detalle de interrupciones
  *
  * Principales funciones
  * * Mostrar detalle de las interrupciones
*/
@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit, AfterViewInit {

  @Input() schedule_detail_id?: number;
  @Input() data?: any = {};
  gridInterruptions: GridOptions;
  busy: any;

  constructor( private dashboardLsfDataService: DashboardLsfDataService) {
    this.setConfigGrid();
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.gridInterruptions.api.setRowData([]);
    this.loadData();
  }

  loadData() {
    this.busy = this.dashboardLsfDataService.getData(`servicesInterruptions/scheduledetails/${this.schedule_detail_id}`).subscribe(
      (resp: any) => {
        if (resp) {
          this.gridInterruptions.api.setRowData(resp);
        }
      }
    );
  }

  setConfigGrid() {
    this.gridInterruptions = <GridOptions>{
      enableSorting: true,
      sortingOrder: ['desc', 'asc', null]
    };

    this.gridInterruptions.columnDefs = [
      { headerName: 'Id', field: 'id', width: 100, sort: 'desc', hide: true },
      { headerName: 'Status', field: 'blockstatu.description', width: 120 },
      { headerName: 'Time', field: 'time', valueGetter: this.getBlockTime, width: 140 },
      { headerName: 'Details/Resolution', field: 'description', valueGetter: this.getDescription, width: 166 },
      { headerName: 'Veh', field: 'vehicle.description', width: 80 },
      { headerName: 'Odometer', field: 'odometer', width: 90 },
      { headerName: 'Driver', field: 'driver', valueGetter: this.getDriver, width: 120 },
      { headerName: 'Supervisor', field: 'supervisor', valueGetter: this.getSupervisor, width: 120 },
    ];
  }

  getDriver(row) {
    if (row.data.driver !== null) {
      return `${row.data.driver.first_name}  ${row.data.driver.last_name}`;
    } else {
      return '';
    }
  }

  getSupervisor(row) {
    if (row.data.supervisor !== null) {
      return `${row.data.supervisor.first_name}  ${row.data.supervisor.last_name}`;
    } else {
      return '';
    }
  }


  getDescription(row) {
    if (row.data.incident !== null) {
      return `${row.data.incident.description} - ${row.data.details}`;
    } else {
      return row.data.details;
    }
  }

  getBlockTime(row) {
    const dateNow = new Date();
    const datePipe = new DatePipe('en-US');
    const date = row.data.created_at;
    const dateRecord = datePipe.transform(date, 'yyyy-MM-dd');
    if (dateRecord == datePipe.transform(dateNow, 'yyyy-MM-dd')) {
      return row.data.time;
    } else {
      return datePipe.transform(row.data.created_at, 'yyyy-MM-dd hh:mm:ss');
    }
  }


}
