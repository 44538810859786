import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeafletComponent } from './leaflet/leaflet.component';
import { GooglePlaceComponent } from './google-place/google-place.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [LeafletComponent, GooglePlaceComponent],
  exports: [
    LeafletComponent
  ]
})
export class MapModule { }
