import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PagesSmartComponent } from '../../../theme/components/pages-smart/pages-smart.component';
import { AuthGuard } from '../../../_guards/auth.guard';
import { BatchViewComponent } from './batch-view/batch-view.component';
import { NgxPermissionsGuard } from 'ngx-permissions';

const routes: Routes = [
  {
    path: 'billing/batch',
    component: PagesSmartComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'history',
        component: BatchViewComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          title: 'Batch History'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BatchRoutingModule { }
