import { CdkPortal, DomPortalHost } from '@angular/cdk/portal';
import { ApplicationRef, Component, ComponentFactoryResolver, EventEmitter, Injector, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-window-modal',
  templateUrl: './window-modal.component.html',
  styleUrls: ['./window-modal.component.scss']
})
export class WindowModalComponent implements OnInit, OnDestroy {

  // STEP 1: get a reference to the portal
  @ViewChild(CdkPortal) portal: CdkPortal;
  @Output() closeModal ? = new EventEmitter<any>();

  // STEP 2: save a reference to the window so we can close it
  private externalWindow = null;

  // STEP 3: Inject all the required dependencies for a PortalHost
  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector) { }

  ngOnInit() {
     // STEP 4: create an external window
     this.externalWindow = window.open('', '', 'width=600,height=400,left=200,top=200');

     // STEP 5: create a PortalHost with the body of the new window document
     const host = new DomPortalHost(
       this.externalWindow.document.body,
       this.componentFactoryResolver,
       this.applicationRef,
       this.injector
       );

       (document.querySelectorAll('link, style') as any as Array<HTMLElement>).forEach(htmlElement => {
        this.externalWindow.document.head.appendChild(htmlElement.cloneNode(true));
       });

     // STEP 6: Attach the portal
     host.attach(this.portal);

     this.externalWindow.document.title = 'Driver\'s Pool';

    this.externalWindow.addEventListener('beforeunload', () => {
      this.ngOnDestroy();
    });
  }

  ngOnDestroy() {
    // STEP 7: close the window when this component destroyed
    this.closeModal.emit(true);
    this.externalWindow.close();
  }

}
