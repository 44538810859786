import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { StopsService } from '../../../../services/stops/stops.service';
import { IPlace } from '../../../../components/map/services/interfaces';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-stop',
  templateUrl: './add-stop.component.html',
  styleUrls: ['./add-stop.component.scss']
})
export class AddStopComponent implements OnInit, OnChanges, OnDestroy {

  @Input() dataEdit?: any;
  @Output() onReload? = new EventEmitter<boolean>();
  originData: any;

  form: FormGroup;
  busy: Subscription;
  busy_form: Subscription;

  time_format = [/[0-9]/, /\d/, ':', /\d/, /\d/];
  pattern_time = '(0[0-9]|1[0-9]|2[0-9]|3[0-6])(:[0-5][0-9]){1}';
  route_blocks: any = [];
  changeEdit = false;

  constructor(private fb: FormBuilder, private stopsService: StopsService) {
    this.initForm();
  }

  ngOnInit() {
      if (this.originData) {
        this.busy_form = this.form.valueChanges.subscribe(() => {
            this.changeEdit = true;
        });
      }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataEdit'] && this.dataEdit) {
      this.form.patchValue(this.dataEdit);
      this.originData = {... this.dataEdit};
    }
  }

  ngOnDestroy(): void {
    this.form.reset();
    if (this.busy) {
      this.busy.unsubscribe();
    }
    if (this.busy_form) {
      this.busy_form.unsubscribe();
    }
  }

  initForm() {
    this.form = this.fb.group({
      id: [''],
      description: ['', Validators.required],
      street: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      postalCode: ['', Validators.required],
      latitude: ['', Validators.required],
      longitude: ['', Validators.required],
      active: [true, Validators.required],
    });
  }

  onSave() {
    if (this.form.valid) {
      this.form.removeControl('id');
      if (this.dataEdit) {
        this.busy = this.stopsService.put(this.form.value, this.dataEdit.id).subscribe((resp: any) => {
          if (resp.status === 'success') {
            this.onReload.emit(true);
          }
        });
      } else {
        this.busy = this.stopsService.post(this.form.value).subscribe((resp: any) => {
          if (resp.status === 'success') {
            this.onReload.emit(true);
          }
        });
      }
    } else {
       Swal.fire('All fields are required, please verify');
    }
  }

  getData(event: IPlace) {
    // console.log(event)
    this.form.patchValue({
      description: event.address.long_name,
      street: event.address.road,
      city: event.address.city,
      state: event.address.state,
      postalCode: event.address.postcode,
      latitude: event.lat,
      longitude: event.lon,
    });
  }

  onReset() {
    this.form.patchValue(this.originData);
    this.changeEdit = false;
  }

}
