import * as momentHelper from '../helpers/moment-helpers';
export function preparingDataAddBatch(range_data: string, client_name: string, clients: any[]){    
    const client_id = clients.find(client => client.client_name === client_name);
    let typeDate = range_data.split('-');
    let rangeDateWeekOrMonth = typeDate[0].split('Week ');
    let rangeDateWeekOrMonthlength = typeDate[0].split('Week ').length ==  1 ? true: false; 
    let rangeDateResult = rangeDateWeekOrMonthlength ? momentHelper.generateMonth(typeDate[0], typeDate[1]) : momentHelper.generateWeek(typeDate[1],rangeDateWeekOrMonth[1]);
    return {
      'start_date': rangeDateResult.dateStart,
      'end_date': rangeDateResult.dateEnd,
      'date_submitted': momentHelper.getDateNow(),
      'client_id': Number(client_id.id)
    }
}