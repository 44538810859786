import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { GetContextMenuItemsParams, GridOptions } from 'ag-grid-community';
import { Subject, Subscription } from 'rxjs/Rx';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BatchesService } from '../../../../services/billing/batches.service';
import * as moment from 'moment';
import { BsModalComponent } from 'ng2-bs3-modal';
import { ClientService } from '../../../../services/fleet/client.service';
import AgGridHelpers from '../../../../helpers/ag-grid-helpers';
import { ReportsManageService } from '../../../../services/reports-manage.service';
import { BatchInvoicesService, IInvoice } from '../../../../services/billing/batch/batch-invoices.service';
import { takeUntil } from 'rxjs/operators';
import { IMyDpOptions } from 'mydatepicker';
import { SweetAlertHelper } from '../../../../CORE/helpers/sweet-alert-helper.service';

@Component({
  selector: 'app-batches',
  templateUrl: './batches.component.html',
  styleUrls: ['./batches.component.scss']
})

export class BatchesComponent implements OnChanges, OnInit, OnDestroy {
  private destroy: Subject<boolean> = new Subject();
  @ViewChild('modal') modal: BsModalComponent;
  @ViewChild('modalDetails') modalDetails: BsModalComponent;
  @ViewChild('modalDateInvoiceSubmitted') modalDateInvoiceSubmitted: BsModalComponent;
  @ViewChild('grid') gridView: ElementRef;
  @Input() clearGrid?: any;
  @Input() clientid: number;

  myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'mm/dd/yyyy'
  };

  batchInvoice!: IInvoice[];
  suscriptionSummary: Subscription[] = [];
  grid: GridOptions;
  freeTextForm: FormGroup;
  getContextMenuItems: any;
  batch_id: number;
  report_id: number;
  report_title: string;
  routes: any = [];
  dataShow: any = {};
  dataParams: any = {};
  freeTextParams: any[] = [];
  freeTextModalTitle: string;
  menuReportList: any[] = [];
  paramsModal: any[] = [];
  reportIdModal = 0;
  blockLikeRun = false;

  constructor(
    private batchesService: BatchesService,
    private clientService: ClientService,
    private reportsManageService: ReportsManageService,
    private batchInvoicesService: BatchInvoicesService,
    private sweetAlertHelper: SweetAlertHelper,
    private fb: FormBuilder
  ) {
    this.initGrid();
    this.freeTextForm = this.fb.group({});
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['clearGrid']) {
      if (this.clearGrid) {
        this.grid.api.setRowData([]);
      }
    }

    if (changes['clientid'] && this.clientid) {
      this.onLoadMenuReport(this.clientid);
    }
  }

  ngOnInit() {
    this.onLoad();
  }

  onLoad() {
    this.batchesService.load_batches_obs.pipe(takeUntil(this.destroy)).subscribe((resp: any) => {
      if (resp) {
        this.loadData(resp);
        this.onLoadRoutes();
      }
    });
  }

  ngOnDestroy() {
    this.suscriptionSummary.forEach(suscription => suscription.unsubscribe());
  }

  initGrid() {
    this.grid = <GridOptions> {
      enableSorting: true,
      enableColResize: true,
      rowSelection: 'single',
      enableRangeSelection: true,
      enableFilter: true,
      onGridReady: () => {
        this.grid.api.setRowData([]);
      },
      onSelectionChanged: () => {
        this.suscriptionSummary.push(
          this.batchInvoicesService.getBatchInvoice(this.grid.api.getSelectedRows()[0].id).subscribe((resp: any) => {
            if (resp) {
              this.batchInvoicesService.loadBatchInvoices$.next(resp);
              this.batchInvoicesService.getBatchHistoryInfo$.next(this.grid.api.getSelectedRows()[0]);
            }
          })
        );
        this.dataParams = this.grid.api.getSelectedRows()[0];
        this.batchInvoicesService.batchInvoiceRow$.next(this.grid.api.getSelectedRows()[0].id);
        this.clearGrid = Math.random();
      },
      columnDefs: [
        { headerName: 'Invoices', field: 'invoices_count', width: 70, cellStyle: { textAlign: 'right' } },
        { headerName: 'Manifests', field: 'manifest_count', width: 70, cellStyle: { textAlign: 'right' } },
        {
          headerName: 'INVOICES',
          cellStyle: { 'text-align': 'center' },
          children: [
            { headerName: 'Charger', field: 'total_amount_invoice', width: 70, cellRenderer: this.CurrencyCellRenderer, cellStyle: { textAlign: 'right' } },
            { headerName: 'Credit', field: 'total_amount_credit', width: 70, cellRenderer: this.CurrencyCellRenderer, cellStyle: { textAlign: 'right' } },

          ]
        },
        { headerName: 'Total Amount', field: 'total_amount', width: 90, cellRenderer: this.CurrencyCellRenderer, cellStyle: { textAlign: 'right' } },
        { headerName: 'Service Hours', field: 'amount.service_hours', width: 90 },
        { headerName: 'Rate', field: 'amount.rate', width: 70 },
        {
          headerName: 'Amount',
          cellStyle: { 'text-align': 'center' },
          children: [
            { headerName: 'Amount', field: 'amount.total', width: 110, cellRenderer: this.CurrencyCellRenderer, cellStyle: { textAlign: 'right' } },
            { headerName: 'Interruptions', field: 'total_interruptions', width: 80, cellRenderer: this.CurrencyCellRenderer, cellStyle: { textAlign: 'right' } },
            { headerName: 'Liquidated Damages', field: 'liquidated_damages', width: 80, cellRenderer: this.CurrencyCellRenderer, cellStyle: { textAlign: 'right' } },
          ]
        },
        { headerName: 'Start Date', field: 'start_date', width: 120, valueGetter: this.formatStartDate },
        { headerName: 'End Date', field: 'end_date', width: 100, valueGetter: this.formatEndDate },
        { headerName: 'Client', field: 'client', width: 150 },
        { headerName: 'Created', field: 'created_at', width: 200 },
        { headerName: 'User', field: 'user', width: 200 },

        // {
        //   width: 50,
        //   colId: 'btnPrintReport',
        //   cellRenderer: () => {
        //     return `<span title='Print Reports'><i class="fa fa-print" aria-hidden="true"></i></span>`;
        //   }
        // }
      ],
      getContextMenuItems: (params: GetContextMenuItemsParams) => {
        if (params && params.node && params.node.data) {
          const gridData = Object.assign({}, params.node.data);
          gridData.start_date = moment(gridData.start_date).format('MM-DD-YYYY');
          gridData.end_date = moment(gridData.end_date).format('MM-DD-YYYY');
          gridData.date_submitted = moment(gridData.date_submitted).format('MM-DD-YYYY');
          this.dataParams = gridData;
          const result = [];
          this.menuReportList.forEach(report => {
            if (report) {
              const menu = report;
              const item = AgGridHelpers.itemMenu(menu.description, menu.icon, () => {
                if (menu.menu_report_params && menu.menu_report_params.length) {
                  this.validateModal(menu.menu_report_params, menu.url, menu.description);
                }
              });
              result.push(item);
            }
          });
          result.push('separator', 'copy', 'export');
          return result;
        }
      }
    };
  }

  CurrencyCellRenderer(params: any) {
    const usdFormate = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });
    return usdFormate.format(parseFloat(params.value));
  }

  loadData(data: any) {
    if (data) {
      this.suscriptionSummary.push(
        this.batchesService.getBatchClient(data).subscribe((resp: any) => {
          if (resp) {
            this.blockLikeRun = resp[0].block_like_run ? false : true;
            this.grid.api.setRowData(resp);
          }

        })
      );
    }
  }

  onLoadData(event: any) {
    if (event) {
      this.batchesService.load_batch$.next(event.data.id);
      this.dataShow = {
        client: event.data.client,
        start_date: moment(event.data.start_date).format('MM-DD-YYYY'),
        end_date: moment(event.data.end_date).format('MM-DD-YYYY'),
        date_submitted: moment(event.data.date_submitted).format('MM-DD-YYYY'),
        created_at: moment(event.data.created_at).format('MM-DD-YYYY HH:MM')
      };
      this.modalDetails.open();
    }
  }

  formatStartDate(row: any) {
    return moment(row.data.start_date).format('MM-DD-YYYY');
  }

  formatEndDate(row: any) {
    return moment(row.data.end_date).format('MM-DD-YYYY');
  }

  formatSubmittedDate(row: any) {
    if (row.data.date_submitted) {
      return moment(row.data.date_submitted).format('MM-DD-YYYY');
    } else {
      return '';
    }
  }

  onSelectButton(params: any) {
    if (params.colDef.colId === 'btnPrintReport') {
      this.batch_id = params.data.id;
      this.modal.open();
    }
  }

  onLoadRoutes() {
    this.suscriptionSummary.push(
      this.clientService.getRoutesByClient(13).subscribe((resp: any) => {
        this.routes = resp.data.map(r => ({
          ...r,
          description_route: String(r.desc_billing ? r.desc_billing : r.description).toUpperCase()
        }));
      })
    );
  }

  validateModal(params: any[], report_id: number, title_modal: string) {
    const formParams = {};
    this.freeTextParams = params.filter(item => item.type_free_text);
    if (this.freeTextParams.length) {
      this.freeTextModalTitle = title_modal;
      this.freeTextParams.forEach(param => {
        if (param) {
          formParams[param.param_name] = new FormControl('', Validators.required);
        }
      });
      this.freeTextForm = this.fb.group(formParams);
      this.paramsModal = params;
      this.reportIdModal = report_id;
      this.modalDateInvoiceSubmitted.open('sm');
    } else {
      this.onPrintReport(params, report_id);
    }
  }

  onPrintReport(params: any[], report_id: number) {
    const parameter: any = new Object();
    if (params && params.length) {
      params.forEach(param => {
        if (param) {
          switch (param.code) {
            case 'date':
              parameter[param.param_name] = [`${this.dataParams.start_date}`, `${this.dataParams.end_date}`];
              break;
            case 'start_date':
              parameter[param.param_name] = this.dataParams.start_date;
              break;
            case 'end_date':
              parameter[param.param_name] = this.dataParams.end_date;
              break;
            case 'client_id':
              parameter[param.param_name] = this.typeClient(param.array, parameter);
              break;
            case 'default':
              parameter[param.param_name] = param.default_values.split(',');
              break;
            case 'route_id':
              parameter[param.param_name] = this.typeRoutes(param.array, parameter);
              break;
            case 'route_group_id':
              parameter[param.param_name] = this.typeGroupRoutes(param.array, parameter);
              break;
            case 'free_text':
              parameter[param.param_name] = this.typeFreeText(param, parameter);
            break;
          }
        }
      });
    }
    this.suscriptionSummary.push(
      this.reportsManageService.getReportTrip2(report_id, parameter).subscribe((resp: string) => {
        if (resp) {
          this.reportsManageService.loadReportWindows(resp);
          this.onCloseModal();
        }
      })
    );
  }

  typeFreeTextTitle(param_name: any) {
    let title = '';
    switch (param_name) {
      case 'date_submitted':
        title = 'Invoice Date Submitted';
        break;
      case 'revised':
        title = 'Invoice Revised';
        break;
    }
    return title;
  }

  typeFreeText(param: any, parameter: any) {
    switch (param.param_name) {
      case 'date_submitted':
        const dateSubmitted = this.freeTextForm.get('date_submitted').value;
        parameter = dateSubmitted.formatted ? dateSubmitted.formatted : null;
        break;
      case 'revised':
        const revised = this.freeTextForm.get('revised').value;
        parameter = revised.formatted ? revised.formatted : null;
        break;
    }
    return parameter;
  }

  typeRoutes(type: any, parameter: any) {
    switch (type) {
      case 'selected':
        parameter = this.dataParams.routes_id;
        break;
      case 'all':
        parameter = this.dataParams.routes;
        break;
      case 'no array':
        parameter = `${this.dataParams.routes_id[0]}`;
        break;
    }
    return parameter;
  }

  typeGroupRoutes(type: any, parameter: any) {
    switch (type) {
      case 'selected':
        parameter = this.dataParams.route_group_id;
        break;
      case 'all':
        parameter = this.dataParams.routes_groups_id;
        break;
      case 'no array':
        parameter = `${this.dataParams.route_group_id[0]}`;
        break;
    }
    return parameter;
  }

  typeClient(type: any, parameter: any) {
    switch (type) {
      case 'no array':
        parameter = `${this.dataParams.client_id}`;
        break;
      default:
        parameter = [`${this.dataParams.client_id}`];
    }
    return parameter;
  }

  onLoadMenuReport(client_id: number) {
    this.suscriptionSummary.push(
      this.clientService.getMenuReportClient(client_id).subscribe((resp: any) => {
        if (resp) {
          this.menuReportList = resp;
        }
      }, error => {
        this.sweetAlertHelper.createCustomAlert({
          type: 'error',
          title: 'Error...',
          text: error.message
        });
      })
    );
  }

  printReportBlockLikeRun(repId: number, batch_id: number) {
    const parameter: any = {
      batch_id: [`${batch_id}`]
    };
    this.suscriptionSummary.push(
      this.reportsManageService.getReportTrip2(repId, parameter).subscribe((resp: string) => {
        this.reportsManageService.loadReportWindows(resp);
      })
    );
  }

  onCloseModal() {
    this.paramsModal = null;
    this.reportIdModal = null;
    this.freeTextParams = null;
    this.freeTextModalTitle = null;
    this.modalDateInvoiceSubmitted.close();
  }
}
