import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { InvoiceReportService } from '../../../invoice-report.service';
import { filter, takeUntil } from 'rxjs/operators';
import { IClient } from '../../../../../../../CORE/interfaces/client.interface';
import {
  IBatchTotalInfo,
  ISubjectFeeScheduleErrorModal
} from '../../../../../../../CORE/interfaces/invoice-report.interface';
import { BsModalComponent } from 'ng2-bs3-modal';

@Component({
  selector: 'app-info-batch-totals',
  templateUrl: './info-batch-totals.component.html',
  styleUrls: ['./info-batch-totals.component.scss']
})
export class InfoBatchTotalsComponent implements OnInit, OnDestroy {
  @ViewChild('editFeeSchedule') editFeeSchedule: BsModalComponent;
  destroy: Subject<boolean> = new Subject<boolean>();

  totalManifest: number;
  totalHours: string;
  totalAmount: number;
  clientName: string;
  clientId: number;

  constructor(
    private invoiceReportService: InvoiceReportService
  ) {
  }

  ngOnInit(): void {
    combineLatest([
      this.invoiceReportService.infoByClientAction$,
      this.invoiceReportService.infoToFillTotalsAction$
    ])
      .pipe(
        filter(([clientInfo, totalInfo]: [IClient, IBatchTotalInfo]) => clientInfo !== null && totalInfo !== null),
        takeUntil(this.destroy)
      )
      .subscribe({
        next: ([clientInfo, totalInfo]: [IClient, IBatchTotalInfo]): void => {
          const {id, client_name} = clientInfo;
          const {totalManifest, totalHours, totalAmount} = totalInfo;

          this.totalAmount = totalAmount;
          this.totalHours = totalHours;
          this.totalManifest = totalManifest;
          this.clientId = id;
          this.clientName = client_name;
        }
      });

    this.invoiceReportService.openFeeScheduleModalByBatchErrorAction$
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: ({open}: ISubjectFeeScheduleErrorModal) => open && this.openFeeScheduleModal()
      });
  }

  openFeeScheduleModal(): void {
    this.editFeeSchedule.open().then();
  }

  closeFeeScheduleModal(): void {
    this.editFeeSchedule.close().then(
      () => this.invoiceReportService.openFeeScheduleModalByBatchError({open: null, listOfCar: null, clientId: null})
    );
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }
}
