import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-noauth',
  templateUrl: './noauth.component.html',
  styleUrls: ['./noauth.component.scss']
})
export class NoauthComponent implements OnInit {

  constructor() { }

  ngOnInit() { }
}
