import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GridOptions, ColDef } from 'ag-grid-community';
import * as momentHelper from '../../../helpers/moment-helpers';
import Swal from 'sweetalert2';

import {preparingDataAddBatch } from '../../../helpers/batch-helpers';

import { BatchesService, ISummary } from '../../../services/billing/batches.service';
import { ClientService } from '../../../services/services.index';





@Component({
  selector: 'summary-billing',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})

export class SummaryComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() clearGrid ?: any;
  suscriptionSummary: Subscription[] = [];
  gridBatchSummary: GridOptions;
  gridData: any[] = [];
  dataBatchSummary = [];
  clients: any[] = [];

  constructor(private clientService: ClientService, private batchesService: BatchesService) {
    this.initGrid();
  }

  ngOnInit() {
    this.loadData();
    this.onReload();
  }

  ngAfterViewInit() {
    this.onCollapse();
    this.verifiedClick();
  }

  ngOnDestroy() {
    this.suscriptionSummary.forEach(suscription => suscription.unsubscribe());
  }

  loadData() {
    this.suscriptionSummary.push(this.batchesService.getSummary().subscribe((resp) => {
      if(resp && resp.query_summary){
        this.dataBatchSummary = [];
        this.insertOneV2(resp.query_summary);
        const _general_summary = {
          clients: resp.clients,
          incomplete: resp.incomplete,
          invoice_unverified: resp.invoice_unverified,
          invoice_verified: resp.invoice_verified
        }
        this.batchesService.loadGridSummary$.next(false);
        this.batchesService.getGeneralSummary$.next(_general_summary);
        this.gridBatchSummary.api.setRowData(this.dataBatchSummary);
        this.suscriptionSummary.forEach(suscription => suscription.unsubscribe());
        this.clientService.getClientsByDriverManifest().subscribe((resp: any) => {
          if(resp && resp.data.length){
            this.clients = resp.data;
          }
        })
        this.batchesService.loadGridSummary$.next(false);
      }
    }));
  }

  initGrid() {
    const columnDefs2: ColDef[] = [
      {
        headerName: 'Client',
        colId: 'client_name',
        field: 'client_name',
        width: 180,
        rowGroup: true,
        hide: true,
        cellRendererParams: {
          suppressCount: false,
          checkbox: true,
          suppressDoubleClickExpand: true,
          suppressEnterExpand: true,
        },
      },
      { headerName: 'Date', field: 'date', width: 110, rowGroup: true, hide: true },
      { headerName: 'Route', field: 'route', width: 220, aggFunc: 'count', cellStyle: {textAlign: "right"} },
      { headerName: 'Unverified', field: 'unverified', width: 120, aggFunc: 'sum', cellStyle: {textAlign: "right"} },
      { headerName: 'Verified', field: 'verified', width: 120, aggFunc: 'sum', cellStyle: {textAlign: "right"} },
      {
        headerName: 'Actions', width: 120, colId: 'generate_batch', cellRenderer: (params) => {
          let total_verified: any = params.node.aggData ? params.node.aggData.verified : null;
          let total_unverified: any = params.node.aggData ? params.node.aggData.unverified : null;
          let field: any = params.node.field;
          if(total_unverified === 0 && total_verified != 0 && field === 'date'){
            return `<button class="btn btn-sm btn-generate-batch" style="background-color: #002f60; color: white;"> Generate Batch</button>`;
          }
        }
      },
    ];
    this.gridBatchSummary = <GridOptions>{
      columnDefs: columnDefs2,
      cellStyle: {textAlign: 'center'},
      enableSorting: true,
      enableColResize: true,
      enableFilter: true,
      suppressAggFuncInHeader: true,
      onGridReady: () => {
        this.gridBatchSummary.api.setRowData([]);
      },
      sortable: true,
      resizable: true,
      animateRows: true,
      groupDisplayType: 'multipleColumns',
      groupMaintainOrder : true,
      autoGroupColumnDef: {
        headerName: 'Clients',
        cellRendererParams: {
          suppressCount: true
        }
      }
    };
  }

  public onLoadDataUnprocessed(event:any){
    if (event.data) {
      console.log('onLoadDataUnprocessed ', event.data);
      this.batchesService.send_data_unprocessed$.next(event.data);
    }
  }

  public autoGroupColumnDef: ColDef = {
    minWidth: 300,
  };

  public groupDisplayType = 'singleColumn';

  evalWeek(range_date: string, year: string){
    const numWeekToDay = momentHelper.numWeekToDay(year);
    return range_date === "53"  && numWeekToDay === 52  ? `1 - ${Number(year)+1}` : `${range_date} - ${year}`;
  }

  insertOneV2(list: any) {
    if(list  && list.length){
      const dataGridResult: any[] = [];
      for(let i = 0; i < list.length; i++){
        const detailsList = list[i];
        const details: any = {
          client_name: detailsList.client_name,
          date: detailsList.type_range_date === "week" ? `Week ${this.evalWeek(detailsList.range_date, detailsList.YEAR)}` : detailsList.DATE,
          route: detailsList.route+ ` ( ${detailsList.unverified} / ${ (Number(detailsList.unverified) + Number(detailsList.verified)) } )`,
          unverified: Number(detailsList.unverified),
          verified: Number(detailsList.verified)
        };
        dataGridResult.push(details);
      }
      for(let t = 0; t < dataGridResult.length; t++) {
        this.dataBatchSummary.push(dataGridResult[t]);
      }
    }
  }

  generateBatch(params: any) {
    if (params.colDef.colId === 'generate_batch') {
      let total_verified: any = params.node.aggData.verified;
      let dataType: any = params.node;
      let client_name = dataType.childrenAfterFilter[0].data.client_name;
      if (dataType.field === 'date' && total_verified) {
        Swal.fire({
          title: 'Are you sure?',
          text: `create batch of ${total_verified} for client ${client_name} manifests for the ${dataType.key}`,
          type: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then(result => {
          if (result.value) {
            let generateBatch = preparingDataAddBatch(dataType.key, client_name, this.clients);
            if (generateBatch) {
              this.suscriptionSummary.push(
                this.batchesService.addBatch(generateBatch).subscribe(
                  (resp: any) => {
                    if (resp.status === 'success') {
                      Swal.fire({
                        position: 'top-end',
                        type: 'success',
                        title: 'Your work has been saved',
                        showConfirmButton: false,
                        timer: 1500
                      }).then(() => {
                        this.loadData();
                      });
                    }
                  },
                  resp => {
                    Swal.fire({
                      position: 'top-end',
                      type: 'error',
                      title: 'Error:',
                      footer: resp.message,
                      showConfirmButton: false
                    });
                  }
                )
              );
            }
          }
        });
      }
    }
  }

  verifiedClick(){
    this.batchesService.setReloadSumary$.subscribe((resp) => {
      if(resp){
        this.loadData();
      }
    });
  }

  onCollapse() {
    if (this.gridBatchSummary.api) {
      this.suscriptionSummary.push(
        this.batchesService.collapseStatusObs.subscribe((action: boolean) => {
          return !action ? this.gridBatchSummary.api.collapseAll() : this.gridBatchSummary.api.expandAll();
        })
      );
    }
  }

  onReload() {
      this.batchesService.loadSummary.subscribe((stateReload) => {
        if(stateReload) {
          this.loadData();
        }
      })
  }

}
