import { Component, OnInit, Input } from '@angular/core';
import { TimecardsService } from './../../services/payroll/timecards.service';
import { GridOptions } from 'ag-grid-community';

@Component({
  selector: 'app-history-punches',
  templateUrl: 'history-punches.component.html'
})

export class HistoryPunchesComponent implements OnInit {

  @Input() driver;
  @Input() date;

  public gridHistory: GridOptions;

  constructor(
    private timecardsService: TimecardsService
  ) {
    this.gridHistory = <GridOptions>{
      enableSorting: true,
      sortingOrder: ['desc', 'asc', null],
      onGridReady: () => {}
    };

    this.gridHistory.columnDefs = [
      { headerName: 'User', field: 'name', width: 120 },
      { headerName: 'Created At', field: 'created_at', width: 150 },
      { headerName: 'Description', field: 'description', width: 400 },
    ];
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    if (this.driver) {
      this.timecardsService.getHistoryDriver(this.driver, this.date).subscribe(
        (res: any) => {
          console.log(res);
          this.gridHistory.api.setRowData(res);
          this.autoSizeAll();
        }
      );
    }
  }

  autoSizeAll() {
    const allColumnIds = [];
    this.gridHistory.columnApi.getAllColumns().forEach(function(column: any) {
      allColumnIds.push(column.colId);
    });
    this.gridHistory.columnApi.autoSizeColumns(allColumnIds);
  }
}
