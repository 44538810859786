import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { DashboardLsfDataService } from '../../../dashboard-lsf.data.service';

@Component({
  selector: 'app-notify-mechanics',
  templateUrl: './notify-mechanics.component.html',
  styleUrls: ['./notify-mechanics.component.scss']
})
export class NotifyMechanicsComponent implements OnInit {

  private endpointInterruptionService = 'servicesInterruptions';
  public busy: Subscription;
  public gridInterruptions: GridOptions;

  @Input() schedule_id?: number;
  @Output() close?: EventEmitter<any> = new EventEmitter<any>();
  toEmails: any;
  reasons: string;

  constructor(private  _dataService: DashboardLsfDataService) {
    this.gridInterruptions = <GridOptions>{
      rowSelection: 'single'
    };
    this.gridInterruptions.columnDefs = [
      { headerName: 'Id', field: 'id', width: 100, sort: 'desc', hide: true },
      { headerName: 'Status', field: 'blockstatu.description', width: 120, checkboxSelection: true },
      { headerName: 'Time', field: 'time', valueGetter: this.getBlockTime, width: 70 },
      { headerName: 'Details/Resolution', field: 'description', valueGetter: this.getDescription, width: 180 },
      { headerName: 'Veh', field: 'vehicle.description', width: 70 },
      { headerName: 'Odometer', field: 'odometer', width: 80 },
      { headerName: 'Driver', field: 'driver', valueGetter: this.getDriver, width: 130 },
      { headerName: 'Supervisor', field: 'supervisor', valueGetter: this.getSupervisor, width: 130 },
    ];
  }

  ngOnInit() {
    this.loadInfoServiceInterruption();
    this.getEmails();
  }

  getBlockTime(row) {
    const dateNow = new Date();
    const datePipe = new DatePipe('en-USA');
    const date = row.data.created_at;
    const dateRecord = datePipe.transform(date, 'yyyy-MM-dd');
    if (dateRecord == datePipe.transform(dateNow, 'yyyy-MM-dd')) {
      return row.data.time;
    } else {
      return datePipe.transform(row.data.created_at, 'yyyy-MM-dd hh:mm:ss');
    }
  }

  getDriver(row) {
    if (row.data.driver !== null) {
      return `${row.data.driver.first_name}  ${row.data.driver.last_name}`;
    } else {
      return '';
    }
  }

  getSupervisor(row) {
    if (row.data.supervisor !== null) {
      return `${row.data.supervisor.first_name}  ${row.data.supervisor.last_name}`;
    } else {
      return '';
    }
  }

  getDescription(row) {
    if (row.data.incident !== null) {
      return `${row.data.incident.description} - ${row.data.details}`;
    } else {
      return row.data.details;
    }
  }

  loadInfoServiceInterruption() {
    this.busy = this._dataService.
    getData(this.endpointInterruptionService + '/scheduledetails/' + this.schedule_id).subscribe(
      (data: any) => {
        this.gridInterruptions.api.setRowData(data);
      }
    );
  }

  getEmails() {
    this.busy = this._dataService.
    getData('mechanicEmail').subscribe(
      (data: any) => {
        // console.log(JSON.stringify(data));
        this.toEmails = data[0].email.split(';');
      }
    );
  }

  onSend() {
    const data = {
      emails: this.toEmails,
      schedule_detail_id: this.schedule_id,
      reasons: this.reasons
    };
    this.busy = this._dataService.postData(data,'mechanicEmail').subscribe(
      (data: any) => {
        alert('Notification sent...');
        this.close.emit(true);
      }
    );
  }

}
