import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClientService } from '../../../../../../services/fleet/client.service';
import { IForm, IFormBilling, IFormIcon } from '../form-billing.infertace';
import { SweetAlertHelper } from '../../../../../../CORE/helpers/sweet-alert-helper.service';

@Component({
  selector: 'app-form-billing-card',
  templateUrl: './form-billing-card.component.html',
  styleUrls: ['./form-billing-card.component.scss']
})

export class FormBillingCardComponent implements OnInit, OnChanges, OnDestroy {
  private destroy: Subject<boolean> = new Subject<boolean>();
  @Input() clientId?: number;
  busy: Subscription[] = [];
  formFormsBilling: FormGroup;
  listForms: IForm[] = [];
  listIcon: IFormIcon[] = [];

  constructor(
    private fb: FormBuilder,
    private clientService: ClientService,
    private sweetAlertHelper: SweetAlertHelper,
  ) {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['clientId'] && this.clientId) {
      this.formFormsBilling.get('client_id').setValue(this.clientId);
    }
  }

  ngOnInit() {
    this.getAllForms();
    this.getAllIcons();
    this.suscribeObservers();
  }

  getAllForms() {
    this.busy.push(
      this.clientService.getForms().subscribe(
        (resp: IForm[]) => {
          if (resp && resp.length) {
            this.listForms = resp;
          }
        },
        error => {
          this.sweetAlertHelper.captureException(error.message);
        }
      )
    );
  }

  getAllIcons() {
    this.busy.push(
      this.clientService.getIcon().subscribe(
        (resp: IFormIcon[]) => {
          if (resp && resp.length) {
            this.listIcon = resp;
          }
        },
        error => {
          this.sweetAlertHelper.captureException(error.message);
        }
      )
    );
  }

  suscribeObservers() {
    this.clientService.editRow$.pipe(takeUntil(this.destroy)).subscribe((resp: IFormBilling) => {
      if (resp) {
        const { id, client_id, small_cities, description, icon, order, form_id, active } = resp;
        this.formFormsBilling.patchValue({
          id: id,
          client_id: client_id,
          small_cities: small_cities,
          description: description,
          icon: icon,
          order: order,
          form_id: form_id,
          active: active
        });
      }
    });
  }

  createForm() {
    this.formFormsBilling = this.fb.group({
      id: [''],
      client_id: ['', Validators.required],
      small_cities: [0, Validators.required],
      description: ['', Validators.required],
      icon: ['', Validators.required],
      order: ['', Validators.required],
      form_id: [0, Validators.required],
      active: [true]
    });
  }

  save() {
    const formData = Object.assign({}, this.formFormsBilling.getRawValue());
    if (formData.id) {
      this.busy.push(
        this.clientService.editFormBilling(formData).subscribe(
          (resp: any) => {
            if (resp) {
              this.sweetAlertHelper.createCustomAlert({
                type: 'success',
                text: resp.message,
                title: 'Your work has been saved',
                showConfirmButton: true
              }).then(() => {
                this.clientService.reloadBillingGrid(true);
                this.clientService.editRowBillingForm(null);
              });
            }
          },
          error => {
            this.sweetAlertHelper.captureException(error.message);
          }
        )
      );
    } else {
      this.busy.push(
        this.clientService.addFormBilling(formData).subscribe(
          (resp: any) => {
            if (resp) {
              this.sweetAlertHelper.createCustomAlert({
                type: 'success',
                text: resp.message,
                title: 'Your work has been saved',
                showConfirmButton: true
              }).then(() => {
                this.clientService.reloadBillingGrid(true);
                this.clientService.editRowBillingForm(null);
              });
            }
          },
          error => {
            this.sweetAlertHelper.captureException(error.message);
          }
        )
      );
    }
  }

  cancel() {
    this.clientService.editRowBillingForm(null);
  }

  get isActive(): boolean {
    return !!this.formFormsBilling.get('id').value;
  }

  get modalTitle(): string {
    return this.formFormsBilling.get('id').value ? 'Edit Billing Form ' : 'Create Billing Form ';
  }

  ngOnDestroy(): void {
    this.busy.forEach(subscription => subscription.unsubscribe());
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }
}
