import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DashboardLsfDataService } from '../../../dashboard-lsf.data.service';

@Component({
  selector: 'app-remplacement',
  templateUrl: './remplacement.component.html',
  styleUrls: ['./remplacement.component.scss']
})
export class RemplacementComponent implements OnInit {
  public busy: Subscription;
  @Input() drivers?: any;
  @Input() vehicles?: any;
  @Input() schedule_seleccionado?: any;
  public data: any = {};
  public used_replacement: boolean;
  public apiResponse: any = {};

  @Output() onClose = new EventEmitter<boolean>();
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  constructor(private  _dataService: DashboardLsfDataService, public toastr: ToastrService) {
    this.used_replacement = true;
  }

  ngOnInit() {
    // console.log(JSON.stringify(this.schedule_seleccionado,null,1));
  }

  save() {
    this.setInservice(this.schedule_seleccionado.id, this.schedule_seleccionado.route_description,
      this.schedule_seleccionado.block_code, this.schedule_seleccionado.block_description);
  }

  close(edit: boolean = false) {
    this.onClose.emit(edit);
  }

  setInservice(schedule_detail_id: number, route_description: string, block_code: string, block_description: string) {
    this.busy = this._dataService.putData(this.data, 'schedules/details/inservice/' + schedule_detail_id).subscribe(
      data => {
        this.apiResponse = data;
        this.apiResponse.master.route_description = route_description;
        this.apiResponse.master.block_code = block_code;
        this.apiResponse.master.block_description = block_description;
        this.apiResponse.process = true;
        if (this.apiResponse.transaction) {
          this.close(true);
          this.notify.emit(this.apiResponse);
        } else {
          this.toastr.error(this.apiResponse.message);
        }
      }
    );
  }

}
