import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CONSTANT } from '../../../../../app.constants';
import { BehaviorSubject, Observable, Subject, Subscriber } from 'rxjs';
import { AuthService } from '../../../../../services/auth.service';
import { IResponse } from '../../../../../CORE/interfaces/response.interface';

@Injectable({
  providedIn: 'root'
})
export class PrintManifestService {
  private infoRouteBlockSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private reloadSelectSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private closeUploadModalSubject: Subject<boolean> = new Subject();

  constructor(
    private http: HttpClient,
    private serviceAuth: AuthService,
  ) {
  }

  get closeUploadModalAction$() {
    return this.closeUploadModalSubject.asObservable();
  }

  get infoRouteBlockAction$(): Observable<any> {
    return this.infoRouteBlockSubject.asObservable();
  }

  get reloadSelectAction$(): Observable<boolean> {
    return this.reloadSelectSubject.asObservable();
  }

  closeUploadModal(close: boolean): void {
    this.closeUploadModalSubject.next(close);
  }

  reloadSelect(reload: boolean): void {
    this.reloadSelectSubject.next(reload);
  }

  setInfoRouteBlockAction(data: { id: number, block_code?: string }): void {
    this.infoRouteBlockSubject.next(data);
  }

  uploadAttachToRouteBlock(routeBlockID: number, payload: FormData) {
    const URL = `${CONSTANT.MANIFEST.URL.ATTACH_DOCUMENT_TO_ROUTE_BLOCK}/${routeBlockID}`;

    return new Observable((subscriber: Subscriber<any>): void => {
      fetch(URL, {
        method: 'POST',
        body: payload,
        headers: {
          'Authorization': `Bearer ${this.serviceAuth.getTokenLocalStorage()}`,
          'namedb': `${this.serviceAuth.getNameDbLocalStorage()}`
        },
      })
          .then((response: Response) => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .then((data): void => {
            subscriber.next(data);
            subscriber.complete();
          })
          .catch(error => {
            subscriber.error(error);
          });
    });
  }

  deleteAttachToRouteBlock(routeBlockID: number) {
    const URL = `${CONSTANT.MANIFEST.URL.ATTACH_DOCUMENT_TO_ROUTE_BLOCK}/${routeBlockID}`;
    return this.http.delete(URL);
  }

  getAttachToRouteBlockIMG(routeBlockID: number): Observable<IResponse<string>> {
    const URL = `${CONSTANT.MANIFEST.URL.ATTACH_DOCUMENT_TO_ROUTE_BLOCK}/${routeBlockID}`;
    return this.http.get<IResponse<string>>(URL);
  }
}
