import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyDateRangePickerModule } from 'mydaterangepicker';
import { AgGridModule } from 'ag-grid-angular/main';
import { NgBusyModule } from 'ng-busy';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './../../shared/shared.module';
import { ThemeModule } from './../../theme/theme.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BsModalModule } from 'ng2-bs3-modal';
import { Ng2AutoCompleteModule } from 'ng2-auto-complete';
import { TextMaskModule } from 'angular2-text-mask';
import { BsDatepickerModule, TabsModule } from 'ngx-bootstrap';
import { DashboardLsfModule } from '../dashboard-lsf/dashboard-lsf.module';
import { MyDatePickerModule } from 'mydatepicker';
import { HeaderFiltersModule } from '../../components/header-filters/header-filters.module';
import { ShowErrorsModule } from './../../tools/showErrosForm.directive';
import { CurrencyMaskModule } from 'ngx-currency-mask';
import { ClientRoutingModule } from './client-routing.module';

@NgModule({
  imports: [
    SharedModule,
    ThemeModule,
    CommonModule,
    ClientRoutingModule,
    BsModalModule,
    MyDateRangePickerModule,
    AgGridModule.withComponents([]),
    BsDatepickerModule.forRoot(),
    NgBusyModule,
    FormsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    Ng2AutoCompleteModule,
    TextMaskModule,
    TabsModule.forRoot(),
    DashboardLsfModule,
    MyDatePickerModule,
    HeaderFiltersModule,
    ShowErrorsModule,
    CurrencyMaskModule
  ],
  declarations: []
})
export class ClientModule { }
