import { Directive, Renderer2, ElementRef, HostListener, Input, AfterViewChecked  } from '@angular/core';

@Directive({
  selector: '[appTypeTime]'
})
export class TypeTimeDirective  implements AfterViewChecked {

  constructor(private rendere: Renderer2, private el: ElementRef) {

  }

  ngAfterViewChecked() {
      this.changeColor(this.el.nativeElement.value);
  }

  @HostListener('change') changeValue() {
      this.changeColor(this.el.nativeElement.value);
  }

  changeColor(value: number) {
      if (value === 5) {
          this.rendere.setStyle(this.el.nativeElement, 'backgroundColor', 'red');
          this.rendere.setStyle(this.el.nativeElement, 'color', '#FFFFFF');
          this.rendere.setStyle(this.el.nativeElement, 'borderLeft', '0px solid #42A948');
          this.rendere.setStyle(this.el.nativeElement, 'textAlign', 'center');
      }else if (this.el.nativeElement.value === 4) {
          this.rendere.setStyle(this.el.nativeElement, 'backgroundColor', '#75a9f9');
          this.rendere.setStyle(this.el.nativeElement, 'color', '#FFFFFF');
          this.rendere.setStyle(this.el.nativeElement, 'borderLeft', '1px solid #42A948');
          this.rendere.setStyle(this.el.nativeElement, 'textAlign', 'center');
      }else {
          this.rendere.setStyle(this.el.nativeElement, 'backgroundColor', '#629c44');
          this.rendere.setStyle(this.el.nativeElement, 'color', '#FFFFFF');
          this.rendere.setStyle(this.el.nativeElement, 'borderLeft', '1px solid #42A948');
          this.rendere.setStyle(this.el.nativeElement, 'textAlign', 'center');
      }
  }

}
