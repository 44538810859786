import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-selecct-weeks',
  templateUrl: 'select-weeks.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectWeeksComponent),
      multi: true
    }
  ]
})

export class SelectWeeksComponent implements OnInit, ControlValueAccessor {
  @Input() noIso? = false;

  arrayWeek: any[];
  value: string;

  counter = 0;
  isDisabled: boolean;
  onChange = (_: any) => { };
  onTouch = () => { };

  constructor() {
    const today = new Date();
    const year = today.getFullYear();
    this.generateWeek(year);
  }

  ngOnInit() { }

  weeksInYear(year: any) {
    return Math.max(
      moment(new Date(year, 11, 31)).isoWeek()
      , moment(new Date(year, 11, 31 - 7)).isoWeek()
    );
  }

  generateWeek(year: any) {
    // const numWeek = this.weeksInYear(year);
    const numWeekToDay = moment().week();
    const arrayWeek = [];
    const format = this.noIso ? 'isoWeek' : 'week';
    for (let i = 1; i <= numWeekToDay; i++) {
      const dateStart = moment().weekYear(year).week(i).startOf(format).format('MM-DD-YYYY');
      const dateEnd = moment().weekYear(year).week(i).endOf(format).format('MM-DD-YYYY');
      arrayWeek.push({
        week: i,
        dateStart,
        dateEnd,
      });
    }

    this.arrayWeek = arrayWeek.sort((a, b) => b.week - a.week);
  }

  onInput(value: string) {
    if (value) {
      this.counter = value.length;
      this.value = value;
      this.onTouch();
      const idx = this.arrayWeek.map(w => Number(w.week)).indexOf(Number(value));
      this.onChange(this.arrayWeek[idx]);
    }
  }
  writeValue(value: any): void {

    this.onInput(value);
    if (value) {
      this.value = value || '';
      this.counter = value.length;
    } else {
      this.value = '';
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
