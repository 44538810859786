import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BsModalComponent } from 'ng2-bs3-modal';

@Component({
  selector: 'app-ta-modal',
  templateUrl: './ta-modal.component.html',
  styleUrls: ['./ta-modal.component.scss']
})
export class TaModalComponent implements OnInit, OnDestroy {

  @Input() title? = 'title modal';

  @ViewChild('modal') modal: BsModalComponent;

  visible  = false;


  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy(): void {

  }

  open(size: string = 'md') {
    this.modal.open(size).then(() => this.visible = true);
  }

  onClose() {
    this.visible = false;
    this.modal.dismiss();
  }

}
