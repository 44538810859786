import { environment } from './../../../environments/environment';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.scss']
})
export class MenusComponent implements OnInit, AfterViewInit {
  infoUser: any;
  permissions = false;
  roles = false;
  users = false;
  companies = false;
  driver_manifest = false;
  clients = false;
  drivers = false;
  routes_block = false;
  lsf = false;
  vehicles = false;
  admin_menu = false;
  fleet_menu = false;
  documents = false;
  resports = false;
  showPayroll: boolean;

  constructor(private router: Router) {}

  ngOnInit() {
    this.createMenu();

    if (this.permissions || this.roles || this.users) {
      this.admin_menu = true;
    }
    if (this.clients || this.drivers || this.routes_block || this.vehicles) {
      this.fleet_menu = true;
    }

    if (environment.production) {
      this.showPayroll = false;
    } else {
      this.showPayroll = true;
    }
  }

  ngAfterViewInit() {}

  createMenu() {
    this.infoUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.infoUser !== null) {
      let permissions;
      permissions = this.infoUser.roles[0].permissions;

      permissions.filter((data, index) => {
        if (data.name === 'permissions') {
          this.permissions = true;
        } else if (data.name === 'roles') {
          this.roles = true;
        } else if (data.name === 'users') {
          this.users = true;
        } else if (data.name === 'companies') {
          this.companies = true;
        } else if (data.name === 'driver-manifest') {
          this.driver_manifest = true;
        } else if (data.name === 'clients') {
          this.clients = true;
        } else if (data.name === 'drivers') {
          this.drivers = true;
        } else if (data.name === 'vehicles') {
          this.vehicles = true;
        } else if (data.name === 'routes-block') {
          this.routes_block = true;
        } else if (data.name === 'lsf') {
          this.lsf = true;
        } else if (data.name === 'resports') {
          this.resports = true;
        }
      });
      this.documents = true;
    } else {
    }
  }
}
