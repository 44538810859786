import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Subject } from 'rxjs/Rx';


export interface ISummary {
  clients: string,
  incomplete: string,
  invoice_unverified: string,
  invoice_verified: string
}
@Injectable({
  providedIn: 'root'
})
export class BatchesService {

  load_batches$ = new BehaviorSubject<number>(null);
  load_batches_obs = this.load_batches$.asObservable();
  load_batch$ = new Subject<any>();
  load_readyToBill$ = new Subject<any>();
  load_batchesInvoices$ = new Subject<any>();
  send_data_unprocessed$ = new Subject<any>();
  changeFormDateHeaderFilter$ = new BehaviorSubject<any>(null);
  changeFormDate = this.changeFormDateHeaderFilter$.asObservable();
  loadGridSummary$ = new BehaviorSubject<boolean>(false);
  loadSummary = this.loadGridSummary$.asObservable();
  loadGridVerifiedToBill$ = new BehaviorSubject<boolean>(false);
  loadVerified = this.loadGridVerifiedToBill$.asObservable();
  sendClientIdBatch$ = new BehaviorSubject<number>(null);
  loadClientIdBatch = this.sendClientIdBatch$.asObservable();
  collapseStatus$ = new BehaviorSubject<boolean>(false);
  collapseStatusObs = this.collapseStatus$.asObservable();
  getGeneralSummary$ = new BehaviorSubject<ISummary>(null);
  generalSummaryObs = this.getGeneralSummary$.asObservable();
  setReloadSumary$ = new Subject<boolean>();

  constructor(private http: HttpClient) { }

  getSummary(){
    return this.http.get<any>(`${environment.url_api_v}billing/summary`);
  }

  getVerifiedToBill(){
    return this.http.get<any>(`${environment.url_api_v}billing/verified_to_bill`);
  }

  getBatch(start_date: any, end_date: any, client_id: number) {
    return this.http.get<any>(`${environment.url_api_v}billing/batch/byDates/${start_date}/${end_date}/${client_id}`);
  }

  getBatchInvoices(client_id: number = 0, invoice_status_id: number = 1) {
    return this.http.get<any>(`${environment.url_api_v}billing/getSummaryCharges/${client_id}/${invoice_status_id}`);
  }

  getBatchClient(client_id: number){
    return this.http.get<any>(`${environment.url_api_v}billing/batch/client/${client_id}`);
  }

  addBatch(data: any) {
    return this.http.post<any>(`${environment.url_api_v}billing/batch`, JSON.stringify(data));
  }

  getManifests(batch_id: number) {
    return this.http.get<any>(`${environment.url_api_v}billing/batch/manifests/${batch_id}`);
  }

  getInterruptions(batch_id: number) {
    return this.http.get<any>(`${environment.url_api_v}billing/batch/interruptions/${batch_id}`);
  }

}
