import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs/Rx';
import { ClientService } from '../../../../../services/fleet/client.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReportsManageService } from '../../../../../services/reports-manage.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../../../../../services/auth.service';
import { CompanyService } from '../../../../../services/companies/company.service';

@Component({
  selector: 'app-get-report',
  templateUrl: './get-report.component.html',
  styleUrls: ['./get-report.component.scss']
})
export class GetReportComponent implements OnInit, OnChanges {
  @Input('batch_id') batch_id?: number;
  @Input('report_id') report_id?: number;
  @Input('routes') routes: any = [];
  @Input('block_like_run') block_like_run?: boolean;
  @Output('title_report') title_report? = new EventEmitter<string>();
  busy: Subscription;

  dropdownSettings: any = {
    idField: 'id',
    textField: 'description_route',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    allowSearchFilter: true
  };

  form: FormGroup;

  reports_options = [
    { id: '5147', description: 'Invoice'},
    { id: '5146', description: 'Consolidate Runs'},
    { id: '5145', description: 'Runs'},
    { id: '5142', description: 'Disruptions'},
    { id: '5143', description: 'Buses Fueled'},
    { id: '5144', description: 'Replacement Log'},
  ];

  reports_block_like_run = [
    { id: '6148', description: 'Cover sheet batch'},
    { id: '6152', description: 'Disruptions RLB Batch'},
    { id: '6147', description: 'Backup Batch'}
  ];

  constructor(private clientService: ClientService,
    private fb: FormBuilder,
              private reportsManageService: ReportsManageService,
    public sanitizer: DomSanitizer,
    private authService: AuthService,
    private companyService: CompanyService) {
    this.initForm();
  }

  ngOnInit() {
    this.form.valueChanges.subscribe((resp: any) => {
      this.report_id = resp.report_id;
      const title = this.reports_options.filter(node => node.id === resp.report_id)[0];
      if (title) {
        this.title_report.emit(title.description);
      }
    });
  }

  initForm() {
    this.form = this.fb.group({
      'route_id': [''],
      'batch_id': ['', [Validators.required]],
      'report_id': ['', [Validators.required]]
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['batch_id']) {
      this.form.get('batch_id').setValue([this.batch_id]);
    }
    if (changes['routes']) {
      if (this.form.get('routes')) {
        this.form.get('routes').setValue([]);
      }
    }
    if (changes['report_id']) {
      this.form.get('report_id').setValue([this.report_id]);
    }
  }

  onPreview() {
    const data = Object.assign({}, this.form.getRawValue());
    const info = {
      batch_id: [`${data.batch_id}`],
      route_id: data.route_id ? data.route_id.map(r => `${r.id}`) : [],
    };
    this.print(info, this.report_id);
  }

   print(parameters: any, report_id: number) {
    this.companyService.getCompany(Number(this.authService.getCompanyId())).subscribe((resp) => {
      if (resp.status === 'success') {
        this.reportsManageService.getTokenTrip2(resp.data.tenant, resp.data.tenant_key).then(() => {
          if (this.reportsManageService.tokenTrip2Report) {
            this.busy = this.reportsManageService.getReportTrip2(report_id, parameters).subscribe((resps: string) => {
              if (resps) {
                const win = window.open(`${resps}&s=${this.reportsManageService.tokenTrip2Report}`, '_blank');
                win.focus();
              }
            });
          }
        });
      }
    });
  }

  get blockLikeRun() {
    return this.block_like_run ? this.reports_block_like_run : this.reports_options;
  }

  get showBlockLikeRun() {
    return !this.block_like_run;
  }

}
