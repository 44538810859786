import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import * as moment from 'moment';
import { IMyDateRangeModel, IMyDrpOptions } from 'mydaterangepicker';
import { AdminDataService } from '../driver.data.service';
import { Subscription } from 'rxjs';
import {EnumDownloadReportOption} from '../../../../../components/grid-controls/EnumDownloadReportOption';

@Component({
  selector: 'app-grid-list',
  templateUrl: './grid-list.component.html',
  styleUrls: ['./grid-list.component.scss']
})

export class GridListComponent implements OnInit, OnDestroy {

  readonly EnumDownloadReportOption = EnumDownloadReportOption;
  @Output() onLoadData? = new EventEmitter<any>();

  gridDM: GridOptions;
  busy: Subscription[] = [];
  myDateRangePickerOptions: IMyDrpOptions = {
    dateFormat: 'yyyy/mm/dd',
    width: '230px',
    disableSince: {
      year: moment().local().year(),
      month: moment().local().month() + 1,
      day: moment().local().date() + 1
    }
  };

  chState = true;
  currentDate: any;
  rangeDate: any;
  countGrid = 0;
  count = 0;
  columnDefs;

  constructor(
    private  _dataService: AdminDataService,
  )
  {
    this.setConfigGrid();
  }

  ngOnInit() {
    this.rangeDate = {
      beginDate: {
        year: moment().local().year(),
        month: moment().local().month() + 1,
        day: moment().local().date()
      },
      endDate: {
        year: moment().local().year(),
        month: moment().local().month() + 1,
        day: moment().local().date()
      }
    };
    this.loadData(this.currentDate);
    this._dataService.reloadGridRightPanel$.subscribe((res) => {
      this.loadData(null);
    });
  }

  setConfigGrid() {
    this.gridDM = <GridOptions> {
      enableSorting: true,
      enableFilter: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      onFilterModified: () => {
        this.countGrid = this.gridDM.api.getDisplayedRowCount();
      },
      columnDefs: [
        { headerName: 'Id', field: 'id', width: 70, sort: 'desc' },
        { headerName: 'Date of Service', field: 'dateOfService', width: 130 },
        { headerName: 'Created On', field: 'created_at', width: 150 },
        { headerName: 'Veh', field: 'vehicle.description', width: 60 },
        { headerName: 'Client', field: 'client.client_name', width: 150 },
        { headerName: 'Employee ID', field: 'driver.employee_id', width: 100 },
        { headerName: 'Driver', valueGetter: this.dataDriver, width: 150 },
        { headerName: 'Route', valueGetter: this.routeBlock, width: 150 },
        { headerName: 'Amb', field: 'amb', width: 60 },
        { headerName: 'W/C', field: 'wc', width: 60 },
        { headerName: 'LYT', field: 'leave_yard_time', width: 60 },
        { headerName: 'LYO', field: 'leave_yard_odometer', width: 70 },
        { headerName: 'FST', field: 'first_stop_time', width: 60 },
        { headerName: 'FSO', field: 'first_stop_odometer', width: 70 },
        { headerName: 'LST', field: 'last_stop_time', width: 60 },
        { headerName: 'LSO', field: 'last_stop_odometer', width: 70 },
        { headerName: 'RYT', field: 'return_to_yard_time', width: 60 },
        { headerName: 'RYO', field: 'return_to_yard_odometer', width: 70 },
        { headerName: 'Check In', field: 'actual_check_in', width: 90 },
        { headerName: 'Check Out', field: 'actual_drop_off', width: 94 },
        { headerName: 'User', field: 'user.name', width: 100 }
      ],
      getRowStyle: this.getRowStyle
    };
  }

  loadData(date: any) {
    const dateStart = this.rangeDate.beginDate.year + '-' + this.rangeDate.beginDate.month + '-' + this.rangeDate.beginDate.day;
    const dateEnd = this.rangeDate.endDate.year + '-' + this.rangeDate.endDate.month + '-' + this.rangeDate.endDate.day;
    if (dateEnd !== '0-0-0') {
      if (this.chState) {
        this.busy.push(this._dataService.getData('driverManifest/byCreatedAt/' + dateStart + '/' + dateEnd).subscribe(
          (data: any) => {
            this.gridDM.api.setRowData(null);
            this.gridDM.api.setRowData(data);
            this.count = data.length;
            this.countGrid = this.count;
          }
        ));
      } else {
        this.gridDM.api.setRowData(null);
        this.busy.push(this._dataService.getData('driverManifest/byDate/' + dateStart + '/' + dateEnd).subscribe(
          (data: any) => {
            this.gridDM.api.setRowData(data);
            this.count = data.length;
          }
        ));
      }
    }
  }

  OnCellDoubleClicked($event) {
    const id = $event.data.id;

    this.onLoadData.emit({
      id: $event.data.id,
      schedule_detail_id: $event.data.schedule_detail_id,
      dateOfService: $event.data.dateOfService,
    });
  }

  onDateRangeChanged(event: IMyDateRangeModel) {
    if(event.beginDate.day !== 0) {
      this.rangeDate = {};
      this.rangeDate.beginDate = event.beginDate;
      this.rangeDate.endDate = event.endDate;
      this.loadData(null);
    }
  }

  getRowStyle(params) {
    if (params.data) {
      const date1 = moment(params.data.created_at);
      const date2 = moment(params.data.updated_at);
      const diff = date2.diff(date1, 'minute');
      if ( diff > 1 ) {
        return {
          'color': 'red',
          'font-weight': '600'
        };
      }
    }
  }

  dataDriver(row: any) {
    return `${row.data.driver.last_name} ${row.data.driver.first_name}`;
  }

  routeBlock(row: any) {
    if (row.data.client.id == 43 || row.data.client.client_name == 'MDT') {
      return `${row.data.route_block.route.description} ${row.data.route_block.description}`;
    } else {
      return row.data.route_block.block_code === null ? `${row.data.route_block.description}` :
        `${row.data.route_block.block_code} ${row.data.route_block.description}`;
    }
  }

  headerTableChange() {
    if (this.chState) {
      this.columnDefs = [
        {headerName: 'Id', field: 'id', width: 70, sort: 'desc'},
        {headerName: 'Date of Service', field: 'dateOfService', width: 130},
        {headerName: 'Created On', field: 'created_at', width: 150},
        {headerName: 'Veh', field: 'vehicle.description', width: 60},
        {headerName: 'Client', field: 'client.client_name', width: 150},
        {headerName: 'Employee ID', field: 'driver.employee_id', width: 100},
        {headerName: 'Driver', valueGetter: this.dataDriver, width: 150},
        {headerName: 'Route', valueGetter: this.routeBlock, width: 150},
        {headerName: 'Amb', field: 'amb', width: 60},
        {headerName: 'W/C', field: 'wc', width: 60},
        {headerName: 'LYT', field: 'leave_yard_time', width: 60},
        {headerName: 'LYO', field: 'leave_yard_odometer', width: 70},
        {headerName: 'FST', field: 'first_stop_time', width: 60},
        {headerName: 'FSO', field: 'first_stop_odometer', width: 70},
        {headerName: 'LST', field: 'last_stop_time', width: 60},
        {headerName: 'LSO', field: 'last_stop_odometer', width: 70},
        {headerName: 'RYT', field: 'return_to_yard_time', width: 60},
        {headerName: 'RYO', field: 'return_to_yard_odometer', width: 70},
        {headerName: 'Check In', field: 'actual_check_in', width: 90},
        {headerName: 'Check Out', field: 'actual_drop_off', width: 94},
        {headerName: 'User', field: 'user.name', width: 100}
      ];
    } else {
      this.columnDefs = [
        {headerName: 'Id', field: 'id', width: 70, sort: 'desc'},
        {headerName: 'Date of Service', field: 'dateOfService', width: 130},
        {headerName: 'Created On', field: 'created_at', width: 150},
        {headerName: 'Veh', field: 'vehicle.description', width: 60},
        {headerName: 'Client', field: 'client.client_name', width: 150},
        {headerName: 'Employee ID', field: 'driver.employee_id', width: 100},
        {headerName: 'Driver', valueGetter: this.dataDriver, width: 150},
        {headerName: 'Route', valueGetter: this.routeBlock, width: 150},
        {headerName: 'Amb', field: 'amb', width: 60},
        {headerName: 'W/C', field: 'wc', width: 60},
        {headerName: 'LYT', field: 'leave_yard_time', width: 60},
        {headerName: 'LYO', field: 'leave_yard_odometer', width: 70},
        {headerName: 'FST', field: 'first_stop_time', width: 60},
        {headerName: 'FSO', field: 'first_stop_odometer', width: 70},
        {headerName: 'LST', field: 'last_stop_time', width: 60},
        {headerName: 'LSO', field: 'last_stop_odometer', width: 70},
        {headerName: 'RYT', field: 'return_to_yard_time', width: 60},
        {headerName: 'RYO', field: 'return_to_yard_odometer', width: 70},
        {headerName: 'Check In', field: 'actual_check_in', width: 90},
        {headerName: 'Check Out', field: 'actual_drop_off', width: 94},
        {headerName: 'User', field: 'user.name', width: 100}
      ];
    }
    this.gridDM.api.setColumnDefs(this.columnDefs);
  }

  onChangeFilter() {
    this.chState = !this.chState;
    this.headerTableChange();
    this.loadData(this.currentDate);
  }

  onReload(event: boolean) {
    if (event) {
      this.loadData(null);
    };
  }

  ngOnDestroy(): void {
    this.busy.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

}
