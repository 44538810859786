import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { VehicleService } from '../../../../../services/services.index';

@Component({
  selector: 'app-vehicle-history',
  templateUrl: './vehicle-odometer-history.component.html',
  styleUrls: ['./vehicle-odometer-history.component.scss']
})

export class VehicleOdometerHistoryComponent implements OnInit, OnChanges, OnDestroy {
  @Input() modalVehicleId: any;
  @Input() modalManifestId: any;
  @Input() modalDateOfService: any;

  busy: Subscription[] = [];
  arrayHistory: any[] = [];
  applyDates: any = {};

  constructor(private _vehicleDriver: VehicleService) {
    this.onInitDate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['modalVehicleId'] && this.modalVehicleId) {
      this.loadHistoryVehicle();
    }

    if (changes['modalManifestId'] && this.modalManifestId) {
      this.loadHistoryManifiest();
    }
  }

  ngOnInit() {}

  onInitDate() {
    const date = new Date();
    const currentYear = new Date().getFullYear();
    this.applyDates = {
      month: date.getMonth() + 1,
      year: currentYear
    };
  }

  loadHistoryVehicle() {
    if (this.modalVehicleId) {
      this.busy.push(
        this._vehicleDriver
          .getRecordOdometerVehicle(this.modalVehicleId, this.applyDates.month, this.applyDates.year)
          .subscribe((resp: any) => {
            if (resp.data) {
              this.arrayHistory = resp.data;
            }
          })
      );
    }
  }

  loadHistoryManifiest() {
    if (this.modalManifestId) {
      const date = moment(this.modalDateOfService);
      this.busy.push(
        this._vehicleDriver
          .getRecordOdometerManifiest(this.modalManifestId, date.month() + 1, date.year())
          .subscribe((resp: any) => {
            if (resp.data) {
              this.arrayHistory = resp.data;
            }
          })
      );
    }
  }

  ngOnDestroy() {
    this.busy.forEach(subscription => subscription.unsubscribe());
  }
}
