import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { LoginDataService } from '../../login.data.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  busy: Subscription;
  formResetPassword: FormGroup;

  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  constructor( private fb: FormBuilder, private  _dataService: LoginDataService ) {
  }

  ngOnInit() {
    this.buildForm();
  }

  private buildForm() {
    this.formResetPassword = this.fb.group({
      email: [ '', [ Validators.required, Validators.minLength(4), Validators.email] ],
    });
  }

  onSend() {
    if (this.formResetPassword.valid) {
      this.busy = this._dataService.postData(this.formResetPassword.value, 'users/passwordReset').subscribe(
        (data: any) => {
          if (data.message) {
            Swal.fire(
              'Success!',
              data.message,
              'success').then(() => {
                this.notify.emit(true);
            });
          } else {
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: data[0]
            }).then();
          }
        },
        error => {
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: error
          }).then();
        }
      );
    }
  }

}
