import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagesSmartComponent } from './../../theme/components/pages-smart/pages-smart.component';
import { DriverMasterScheduleComponent } from './driver-master-schedule/driver-master-schedule.component';
import { InterruptionDetailsComponent } from './interruption-details/interruption-details.component';
import { AuthGuard } from './../../_guards/auth.guard';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { RulesComponent } from './rules/rules.component';
import { ClockLogComponent } from './clock-log/clock-log.component';
import { ExceptionsReportComponent } from './exceptions-report/exceptions-report.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: PagesSmartComponent,
    children: [
      {
        path: 'interruption-details',
        component: InterruptionDetailsComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          title: 'Interruption Details',
          permissions: {
            only: ['route-interruption-details'],
            redirectTo: '/noauthorized',
          }
        }
      },
      {
        path: 'driver-master-schedule',
        component: DriverMasterScheduleComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          title: 'Time Cards',
          permissions: {
            only: ['route-driver-master-schedule'],
            redirectTo: '/noauthorized',
          }
        }
      },
      {
        path: 'rules',
        component: RulesComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          title: 'Payroll Rules',
          permissions: {
            only: ['route-payroll-rules'],
            redirectTo: '/noauthorized',
          }
        }
      },
      {
        path: 'clock-log',
        component: ClockLogComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          title: 'Clock Log',
          permissions: {
            only: ['route-payroll-clock-log'],
            redirectTo: '/noauthorized',
          }
        }
      },
      {
        path: 'exceptions-report/:id',
        component: ExceptionsReportComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          title: 'Exceptions report Ta',
          permissions: {
            only: ['route-exceptions-report-ta'],
            redirectTo: '/noauthorized',
          }
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PayrollRoutingModule { }
