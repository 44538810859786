import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AdminDataService } from '../driver.data.service';
import { Subject, Subscription } from 'rxjs';
import { GridOptions } from 'ag-grid-community';

@Component({
  selector: 'app-workday-summary',
  templateUrl: './workday-summary.component.html',
  styleUrls: ['./workday-summary.component.scss']
})
export class WorkdaySummaryComponent implements OnInit, OnChanges {
  @Input() driverId: any;
  @Input() dateOfService: any;


  busy: Subscription[] = [];
  destroy: Subject<boolean> = new Subject<boolean>();
  gridSummarySchedule: GridOptions;
  gridSummaryInterruption: GridOptions;
  driver: string;
  clock_in: string;
  clock_out: string;
  constructor(private _dataService: AdminDataService) {
    this.initGridSchedules();
    this.initGridInterruptions();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.loadData();
  }

  initGridSchedules(): void {
    this.gridSummarySchedule = <GridOptions>{
      enableFilter: true,
      enableSorting: true,
      enableColResize: true,
      columnDefs: [
        {
          headerName: 'Schedules',
          children: [
            { headerName: 'Route', field: 'route_description', width: 150 },
            { headerName: 'Block Desc', field: 'block_description', width: 110 },
            { headerName: 'Run Block', field: 'block_code', width: 100 },
            { headerName: 'Veh', field: 'vehicle', width: 100 },
            { headerName: 'Sch-In', field: 'report_time', width: 100 },
            { headerName: 'Sch-Out', field: 'punch_out_time', width: 100 },
            { headerName: 'Start', field: 'start_time', width: 100 },
            { headerName: 'End', field: 'end_time', width: 100 }
          ]
        },
      ],
      localeText: {
        noRowsToShow: 'No schedules assigned.'
      },
      onGridReady: () => {
        this.gridSummarySchedule.api.setRowData([]);
      },
    };
  }

  initGridInterruptions(): void {
    this.gridSummaryInterruption = <GridOptions>{
      enableFilter: true,
      enableSorting: true,
      enableColResize: true,
      columnDefs: [
        {
          headerName: 'Interruptions',
          children: [
            { headerName: 'Status', field: 'status', width: 150 },
            { headerName: 'Time', field: 'time', width: 110 },
            { headerName: 'Details/Resolution', field: 'details', valueGetter: this.getDescription, width: 150 },
            { headerName: 'Veh', field: 'vehicle', width: 150 },
            { headerName: 'Odometer', field: 'odometer', width: 150 },
            { headerName: 'Supervisor', field: 'supervisor', width: 150 }
          ]
        }
      ],
      localeText: {
        noRowsToShow: 'No interruptions.'
      },
      onGridReady: () => {
        this.gridSummaryInterruption.api.setRowData([]);
      },
    };
  }
  loadData() {
    if (this.driverId && this.dateOfService) {
      this.busy.push(
        this._dataService
          .getWorkdaySummary(this.driverId, this.dateOfService)
          .subscribe((resp: any) => {
              const { schedules, interruptions, data_principal} = resp;
              this.gridSummarySchedule.api.setRowData(schedules);
              this.gridSummaryInterruption.api.setRowData(interruptions);
              if (data_principal != null) {
                this.driver = data_principal.driver;
                this.clock_in = data_principal.clock_in;
                this.clock_out = data_principal.clock_out;
              }else {
                this.driver = '';
                this.clock_in = '';
                this.clock_out = '';
              }
          })
      );
    }
  }

  getDescription(row) {
    if (row.data.desc_incident !== null) {
      return `${row.data.desc_incident} - ${row.data.details}`;
    } else {
      return row.data.details;
    }
  }
}
