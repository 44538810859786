import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RouterBlockService {

  constructor(private http: HttpClient) { }

  list(active: number) {
    return this.http.get(`${environment.url_api_v}routes/active/${active}`);
  }

  listactiveFilteredByClient(active: number, client_id: number) {
    const client = client_id === null ? 0 : client_id;
    return this.http.get(`${environment.url_api_v}routes/activeFilteredByClient/${active}/${client}`);
  }

}
