import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular/main';
import { LicenseManager } from 'ag-grid-enterprise/main';
import { TextMaskModule } from 'angular2-text-mask';
import { Ng2AutoCompleteModule } from 'ng2-auto-complete';
import { BsModalModule } from 'ng2-bs3-modal';
import { AlertModule, ModalModule } from 'ngx-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { DashboardLsfModule } from './dashboard-lsf/dashboard-lsf.module';
import { FleetModule } from './fleet/fleet.module';
import { ManifestsModule } from './manifests/manifests.module';
import { PagesRoutingModule } from './pages-routing.module';
import { BillingModule } from './billing/billing.module';
import { ShowErrorsModule } from '../tools/showErrosForm.directive';
import { StopsModule } from './stops/stops.module';
import { ClientModule } from './client/client.module';
import { GridSwitchComponent } from '../components/grid-switch/grid-switch.component';


LicenseManager.setLicenseKey('');
LicenseManager['outputMessage'] = function(data1, data2) {
  console.log('outputMessage', data1, data2);
};

@NgModule({
  imports: [
    CommonModule,
    PagesRoutingModule,
    ToastrModule.forRoot(),
    TextMaskModule,
    AlertModule.forRoot(),
    Ng2AutoCompleteModule,
    BsModalModule,
    ModalModule.forRoot(),
    ManifestsModule,
    AgGridModule.withComponents([]),
    FleetModule,
    DashboardLsfModule,
    BillingModule,
    ClientModule,
    ShowErrorsModule,
    StopsModule
  ],
  declarations: [],
  providers: [],
  entryComponents: [
    GridSwitchComponent
  ]
})
export class PagesModule {}
