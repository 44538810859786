import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs/Rx';
import Swal from 'sweetalert2';
import { IMyDpOptions } from 'mydatepicker';
import { BatchCreditsService } from '../../../../../services/billing/batch/batch-credits.service';
import { BatchCreditStateService } from '../../../../../services/billing/batch/batch-credit-state.service';
import { takeUntil } from 'rxjs/operators';
import { InvoiceReportService } from '../../../workflow/invoice-report/invoice-report.service';
import { SweetAlertHelper } from '../../../../../CORE/helpers/sweet-alert-helper.service';

@Component({
  selector: 'app-add-invoice-credit',
  templateUrl: './add-invoice-credit.component.html',
  styleUrls: ['./add-invoice-credit.component.scss']
})
export class AddInvoiceCreditComponent implements OnInit, OnDestroy {
  private destroy: Subject<boolean> = new Subject<boolean>();
  formCredits: FormGroup;
  @Output() onCloseInvoiceCredit? = new EventEmitter<any>();
  @Output() onReload? = new EventEmitter<any>();
  @Input() dataFilter?: any;
  listCreditStatus: any = [];
  busy: Subscription[] = [];
  modalInvoiceReportIsOpen = false;

  constructor(
    private fb: FormBuilder,
    private batchCreditsService: BatchCreditsService,
    private batchCreditStateService: BatchCreditStateService,
    private invoiceReportService: InvoiceReportService,
    private sweetAlertHelper: SweetAlertHelper
  ) {
    this.createForm();
  }

  myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'mm-dd-yyyy'
  };

  ngOnInit() {
    this.subscribeObservables();
  }

  ngOnDestroy() {
    this.destroy.next(true);
    this.destroy.unsubscribe();
    return (this.busy = []);
  }

  subscribeObservables() {
    this.batchCreditStateService.getBatchInvoiceCreditStates().subscribe(
      (resp: any) => {
        if (resp) {
          this.listCreditStatus = resp;
        }
      },
      error => {
        Swal.fire({
          type: 'error',
          title: 'Error...',
          text: error
        });
      }
    );
    this.batchCreditsService.batchCreditRow$.pipe(takeUntil(this.destroy)).subscribe(
      invoice => {
        if (invoice && typeof invoice !== 'object') {
          this.formCredits.patchValue({invoice_id: invoice});
        } else {
          this.formCredits.patchValue({invoice_id: invoice.id});
        }
      }, error => {
        Swal.fire({
          type: 'error',
          title: 'Error...',
          text: error.message,
        });
      }
    );

    this.batchCreditsService.editRowCredit$.subscribe((resp: any) => {
      if (resp) {
        const { id, credit_num, credit_state_id, invoice_id, amount, check_number, check_date, deposit_date , paid_date, is_active } = resp;
        this.formCredits.patchValue({
          id,
          credit_num,
          credit_state_id,
          invoice_id,
          amount,
          check_number,
          check_date,
          deposit_date,
          paid_date,
          is_active
        });
      }
    }, error => {
      Swal.fire({
        type: 'error',
        title: 'Error...',
        text: error.message,
      });
    });

    this.subscribeToInvoiceModalOpen();
  }

  subscribeToInvoiceModalOpen(): void {
    this.invoiceReportService.isOpenInInvoiceReportAction$.pipe(takeUntil(this.destroy)).subscribe({
      next: (isOpen: boolean) => this.modalInvoiceReportIsOpen = isOpen
    });
  }

  createForm() {
    this.formCredits = this.fb.group({
      id: [''],
      invoice_id: [''],
      credit_num: ['', Validators.required],
      credit_state_id: ['', Validators.required],
      amount: ['', Validators.required],
      check_number: [''],
      check_date: [''],
      deposit_date: [''],
      paid_date: [''],
      is_active: [true, Validators.required]
    });
  }

  onSave(): void {
    this.modalInvoiceReportIsOpen ? this.saveOnInvoiceReport() : this.saveOnBatchHistory();
  }

  saveOnInvoiceReport(): void {
    const formData = this.formCredits.value;
    const {id: creditChargeId} = formData;

    if (creditChargeId) {
      this.invoiceReportService.updateCreditChargeBatch(creditChargeId, formData)
        .subscribe({
          next: (response: any): void => {
            if (response.status === 'success') {
              this.sweetAlertHelper.createCustomAlert({
                type: 'success',
                title: 'Your work has been saved',
                showConfirmButton: true
              }).then(() => this.emitEvents(formData));
            }
          },
          error: (error) => this.sweetAlertHelper.captureException(error)
        });
    } else {
      this.invoiceReportService.createCreditChargeBatch(formData)
        .subscribe({
          next: (response): void => {
            if (response.status === 'success') {
              this.sweetAlertHelper.createCustomAlert({
                type: 'success',
                title: 'Your work has been saved',
                showConfirmButton: true
              }).then(() => this.emitEvents(formData));
            }
          },
          error: (error) => this.sweetAlertHelper.captureException(error)
        });
    }
  }

  private emitEvents(formData): void {
    this.onReload.emit(formData);
    this.onCloseInvoiceCredit.emit();
    this.batchCreditsService.save$.next(true);
  }

  saveOnBatchHistory(): void {
    const formData = Object.assign({}, this.formCredits.getRawValue());
    if (formData.id) {
      this.batchCreditsService.editBatchinvoiceCredit(formData).subscribe(
        (resp: any) => {
          if (resp) {
            Swal.fire({
              type: 'success',
              title: 'Your work has been saved',
              showConfirmButton: true
            }).then(() => {
              this.onReload.emit(formData);
              this.onCloseInvoiceCredit.emit();
              this.batchCreditsService.save$.next(true);
            });
          }
        },
        error => {
          Swal.fire({
            type: 'error',
            title: 'Error...',
            text: error.message
          });
        }
      );
    } else {
      this.batchCreditsService.addBatchInvoiceCredit(formData).subscribe(
        (resp: any) => {
          if (resp) {
            Swal.fire({
              type: 'success',
              title: 'Your work has been saved',
              showConfirmButton: true
            }).then(() => {
              this.onReload.emit(formData);
              this.onCloseInvoiceCredit.emit();
              this.batchCreditsService.save$.next(true);
            });
          }
        },
        error => {
          Swal.fire({
            type: 'error',
            title: 'Error...',
            text: error.message
          });
        }
      );
    }
  }

  get isActive(): boolean {
    return !!this.formCredits.get('id').value;
  }

  cancel() {
    this.formCredits.reset();
    this.onCloseInvoiceCredit.emit();
    this.batchCreditsService.save$.next(false);
  }
}
