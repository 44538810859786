import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BatchesService, ISummary } from '../../../../services/billing/batches.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'summary-bar',
  templateUrl: './summary-bar.component.html',
  styleUrls: ['./summary-bar.component.scss']
})

export class SummaryBarComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() general_items:ISummary[];
  suscriptionSummary: Subscription[] = [];
  clients:string;
  invoice_unverified: string;
  incomplete: string;
  invoice_verified: string;
  btnCollapseStatus: boolean = false;
  loading:boolean = false;
  hover_buttonspan:string = 'Expand';
  loading_general:boolean = false;

  constructor(private batchesService: BatchesService) {
    this.loading_general = true;
  }

  ngOnInit() {
    this.loadData();

  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.suscriptionSummary.forEach(suscription => suscription.unsubscribe());
  }

  loadData() {
    this.suscriptionSummary.push(this.batchesService.generalSummaryObs.subscribe((resp: ISummary) => {
      if(resp){
        this.clients = resp.clients;
        this.invoice_unverified = resp.invoice_unverified;
        this.invoice_verified = resp.invoice_verified;
        this.incomplete = resp.incomplete;
      }
      this.loading_general = false;
    }));
  }

  onReload() {
    this.loading = true;
    this.batchesService.loadGridSummary$.next(true);
    this.batchesService.loadSummary.subscribe((stateReload) => {
      if(!stateReload) {
        this.loading = false;
      }
    });
  }

  onCollapse(){
    this.btnCollapseStatus = !this.btnCollapseStatus;
    this.btnCollapseStatus ? this.hover_buttonspan = 'Collapse' : this.hover_buttonspan ='Expand';
    this.batchesService.collapseStatus$.next(this.btnCollapseStatus);
  }

  get reload() {
    return this.loading ? 'Loading': 'Reload';
  }

}
