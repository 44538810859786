import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CONSTANTS } from '../../app.constants';
import { BaseService } from '../../core/BaseService';

@Injectable()
export class RoleService extends BaseService {
  protected resource: string;

  constructor(private http: HttpClient) {
    super();
    this.resource = environment.url_api_v + CONSTANTS.ROLES;
  }

  /**
   * @returns Listado de roles
   */
  getRoles(): Observable<any> {
    return this.http.get<any>(this.resource).pipe(catchError(this.handleError));
  }

  /**
   * @returns Listado de roles habilitados para asignar
   */
  getRolesByRolesManagment(): Observable<any> {
    return this.http.get<any>(this.resource + '/byRole/Management ').pipe(catchError(this.handleError));
  }

  /**
   * @returns Servicio para guardar rol
   */
  storeRole(data: any): Observable<Response> {
    return this.http.post<Response>(this.resource, JSON.stringify(data));
  }

  /**
   * @returns Servicio para actualizar rol
   */
  updateRole(data: any, id: number): Observable<Response> {
    return this.http.put<Response>(
      this.resource + '/' + id,
      JSON.stringify(data)
    );
  }

  /**
   * @returns Servicio para actualizar roles para asignar
   */
  updateRolesManagment(data: any): Observable<Response> {
    return this.http.post<Response>(
      environment.url_api + 'roleManagement/save',
      JSON.stringify(data)
    );
  }

  /**
   * @returns Retorna informacion de un rol
   */
  getRol(id: number): Observable<any> {
    return this.http.get(this.resource + '/' + id);
  }

}
