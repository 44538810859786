import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InvoiceService } from '../../../../services/billing/invoice.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { formatDate } from '@angular/common';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-missed-service-event',
  templateUrl: './add-missed-service-event.component.html',
  styleUrls: ['./add-missed-service-event.component.scss']
})

export class AddMissedServiceEventComponent implements OnInit, OnDestroy, OnChanges {
  @Output() onAddMissingServiceEvent? = new EventEmitter<boolean>();
  @Input() missedServiceEvent?: any = {};
  @Input() dataParams?: any = {};
  busy: Subscription[] = [];
  formData: FormGroup;
  runs: any[] = [];

  constructor(private fb: FormBuilder, private invoiceService: InvoiceService) {
    this.onCreateForm();
    this.onSubscribeForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['missedServiceEvent'] && this.missedServiceEvent) {
      const date = moment(this.missedServiceEvent.date, 'YYYY-MM-DD hh:mm:ss').toISOString();
      this.formData.patchValue({
        id: this.missedServiceEvent.id,
        run_id: this.missedServiceEvent.run_id,
        total_minutes: this.missedServiceEvent.total_minutes,
        billable_minutes: this.missedServiceEvent.billable_minutes,
        invoice_reduction: this.missedServiceEvent.invoice_reduction,
        active: this.missedServiceEvent.active === '1',
        date: formatDate(date, 'yyyy-MM-dd', 'en')
      });
    }
  }

  ngOnInit() {
    this.onLoadRuns();
    this.onDisabledControls();
  }

  onCreateForm() {
    this.formData = this.fb.group({
      id: [''],
      run_id: [''],
      total_minutes: ['', Validators.required],
      date: ['', Validators.required],
      billable_minutes: [''],
      invoice_reduction: [''],
      active: [true, Validators.required]
    });
  }

  onSubscribeForm() {
    this.formData.get('total_minutes').valueChanges.subscribe(val => {
      if (val > 46) {
        const result = ((val * 0.25) * -1);
        this.formData.patchValue({
          billable_minutes: val,
          invoice_reduction: `${result}`
        });
      } else {
        this.formData.patchValue({
          billable_minutes: null,
          invoice_reduction: null
        });
      }
    });
  }

  onDisabledControls() {
    this.formData.get('billable_minutes').disable();
    this.formData.get('invoice_reduction').disable();
  }

  onLoadRuns() {
    this.busy.push(
      this.invoiceService.getRunsByClient(this.dataParams.client_id).subscribe((resp: any) => {
        this.runs = resp;
      })
    );
  }

  ngOnDestroy() {
    return (this.busy = []);
  }

  onSave() {
    const data = Object.assign({}, this.formData.getRawValue());
    if (data.id) {
      this.busy.push(
        this.invoiceService.updateMissedServiceEvent(data).subscribe(
          (resp: any) => {
            {
              Swal.fire('Success!', resp.message, 'success').then(() => {
                this.onAddMissingServiceEvent.emit(true);
              });
            }
          },
          error => {
            Swal.fire({
              title: 'Opss!',
              text: error.message,
              type: 'error'
            }).then();
          }
        )
      );
    } else {
      this.busy.push(
        this.invoiceService.saveMissedServiceEvent(data).subscribe(
          (resp: any) => {
            {
              Swal.fire('Success!', resp.message, 'success').then(() => {
                this.onAddMissingServiceEvent.emit(true);
              });
            }
          },
          error => {
            Swal.fire({
              title: 'Opss!',
              text: error.message,
              type: 'error'
            }).then();
          }
        )
      );
    }
  }
}
