import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TimecardsService } from './../../../../../services/payroll/timecards.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { TabsetComponent } from 'ngx-bootstrap';

/**
  * Componente para agregar/editar un punch.
  * Este componente corresponde al formulario para agregar/editar un punch.
  *
  * Principales funciones
  * * Formulario para agregar/editar un punch.
*/
@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss']
})
export class InfoCardComponent implements OnInit {
  @ViewChild('staticTabs') staticTabs: TabsetComponent;

  form: FormGroup;
  time_format = [/[0-9]/, /\d/, ':', /\d/, /\d/];

  activeTab = 'Punch';
  typeForm = 1;

  listActions: any = [
    {
      id: 'In',
      name: 'Clock-In',
      disabled: false,
    },
    {
      id: 'Out',
      name: 'Clock-Out',
      disabled: false,
    },
    {
      id: 'Working',
      name: 'Working',
      disabled: true,
    },
    {
      id: 'Auto',
      name: 'Auto',
      disabled: false,
    },
    {
      id: 'Hours',
      name: 'Hours',
      disabled: false,
    }
  ];
  listTypesHours = [];

  paramsHistory: any = {
    date: null,
    driver: null,
  };

  busy: Subscription;

  constructor(
    private fb: FormBuilder,
    private timecardsService: TimecardsService,
  ) {
    this.createForm();
    this.getTypesHours();
  }

  getTypesHours() {
    this.timecardsService.getHoursTypes().subscribe((res: any) => {
      this.listTypesHours = res;
    });
  }

  changeType() {
    const type = this.form.get('type').value;
    if (type) {
      if (type === 'Hours') {
        this.form.get('full_date_to').setValidators(Validators.compose([Validators.required]));
        this.form.get('discriminator').setValidators(Validators.compose([Validators.required]));
      } else {
        this.form.get('full_date_to').setValidators([]);
        this.form.get('discriminator').setValidators([]);
        console.log('reset...');
      }
      this.form.get('full_date_to').updateValueAndValidity();
      this.form.get('discriminator').updateValueAndValidity();
    }
  }

  ngOnInit() {
    this.timecardsService.closeModal$.subscribe(res => {
      if (res) {
        this.staticTabs.tabs[0].active = res;
      }
    });
    this.timecardsService.editDetailSelected$.subscribe(
      async res => {
        this.activeTab = 'Punch';
        // this.staticTabs.tabs[0].active = true;
        if (res.params.full_date != null) {
          this.paramsHistory = {
            date: res.params.full_date.split('T')[0],
            driver: res.driverid,
          };
        }
        if (!res.new) {
          const date = await moment(res.params.date_full).format('YYYY-MM-DD');
          this.form.patchValue({
            employee: res.data.employee_name,
            full_date: date,
            type: res.params.type,
            route: res.params.route,
            schedule: res.params.schedule,
            actual: res.params.type === 'Hours' ? res.params.duration : res.params.actual,
            reg: res.params.reg,
            ot: res.params.ot,
            location: res.params.location,
            transaction_id: res.params.transaction_id,
            employee_id: res.data.id,
            note: res.params.note,
            discriminator: res.params.discriminator,
          });
          if (!res.params.transaction_id) {
            this.typeForm = 1;
            this.form.controls['full_date'].disable();
            this.form.controls['type'].disable();
            this.form.controls['actual'].disable();
          } else {
            this.typeForm = 2;
            this.form.controls['full_date'].enable();
            this.form.controls['type'].enable();
            this.form.controls['actual'].enable();
          }
          this.form.controls['schedule'].disable();
          this.form.controls['reg'].disable();
          this.form.controls['ot'].disable();
          if (res.params.type === 'Hours') {
            this.form.controls['type'].disable();
          }
        } else {
          this.typeForm = 3;
          const date = await moment(res.params.date_full).format('YYYY-MM-DD');
          this.form.patchValue({
            employee: res.data.employee_name,
            full_date: date,
            type: res.params.type,
            route: '',
            schedule: '',
            actual: '',
            reg: '',
            ot: '',
            location: '',
            transaction_id: 0,
            employee_id: res.data.id,
            note: '',
            full_date_to: date,
            discriminator: '',
          });
          this.form.controls['full_date'].enable();
          this.form.controls['type'].enable();
          this.form.controls['schedule'].enable();
          this.form.controls['actual'].enable();
          this.form.controls['reg'].enable();
          this.form.controls['ot'].enable();
        }
        this.changeType();
      }, err => {
        console.log(err);
      }
    );

    this.form.controls['actual'].valueChanges.subscribe((data) => {
      const val = this.validHour(data);
      if (val.change) { this.form.get('actual').setValue(val.val); }
    });
    this.form.controls['schedule'].valueChanges.subscribe((data) => {
      const val = this.validHour(data);
      if (val.change) { this.form.get('schedule').setValue(val.val); }
    });
    this.form.controls['reg'].valueChanges.subscribe((data) => {
      const val = this.validHour(data);
      if (val.change) { this.form.get('reg').setValue(val.val); }
    });
    this.form.controls['ot'].valueChanges.subscribe((data) => {
      const val = this.validHour(data);
      if (val.change) { this.form.get('ot').setValue(val.val); }
    });
  }

  createForm() {
    this.form = this.fb.group({
      employee: [{value: ''}],
      full_date: [{value: ''}, [Validators.required]],
      full_date_to: [{value: ''}],
      type: [{value: ''}, [Validators.required]],
      discriminator: [{value: ''}],
      route: [{value: '', disabled: true}],
      schedule: [{value: '', disabled: true}],
      actual: [{value: '', disabled: false}, [Validators.required]],
      reg: [{value: '', disabled: true}],
      ot: [{value: '', disabled: true}],
      location: [{value: ''}],
      transaction_id: [{value: ''}, [Validators.required]],
      employee_id: [{value: ''}],
      note: [{value: ''}],
    });
  }

  validHour(hour) {
    if (hour) {
      let h = hour.split(':')[0];
      let m = hour.split(':')[1];
      h = h.replace(/_/g, '0');
      m = m.replace(/_/g, '0');
      if (Number(`${h}${m}`) > 2359) {
        return {change: true, val: '23:59'};
      } else if (Number(`${m}`) > 59) {
        return {change: true, val: `${h}:${59}`};
      } else {
        return {change: false, val: `${h}:59`};
      }
    } else {
      return {change: false, val: ``};
    }
  }

  updateChanges() {
    const dataForm = this.form.getRawValue();
    if (dataForm.transaction_id !== 0) {
      Swal.fire({
        title: 'Are you sure you want to make these changes?',
        text: ``,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!'
      }).then((result) => {
        if (result.value) {
          const date = `${dataForm.full_date}T${dataForm.actual}:00`;
          const dataSend: any = {
            id: dataForm.transaction_id,
            date: date,
            type: dataForm.type,
            note: dataForm.note,
          }
          if (dataForm.type === 'Hours') {
            dataSend.discriminator = dataForm.discriminator;
            dataSend.duration = dataForm.actual;
            // dataSend.endDate = dataForm.full_date_to;
            dataSend.date = `${dataForm.full_date}`;
          }
          this.busy = this.timecardsService.editDetail(dataSend).subscribe(
            res => {
              this.timecardsService.successDetailChanged$.emit({});
              Swal.fire('Successfull!', 'Changes saved.', 'success');
            }, err => {
              Swal.fire('Error!', 'Please check information and try again.', 'error');
              console.log(err);
            }
          );
        }
      });
    } else {
      this.saveChanges();
    }
  }

  saveChanges() {
    Swal.fire({
      title: 'Are you sure you want to save these punch?',
      text: ``,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        const dataForm = this.form.getRawValue();
        const date = `${dataForm.full_date}T${dataForm.actual}:00`;
        const dataSend: any = {
          employeeId: dataForm.employee_id,
          timestamp: date,
          type: dataForm.type,
          note: dataForm.note
        };
        if (dataForm.type === 'Hours') {
          dataSend.discriminator = dataForm.discriminator;
          dataSend.duration = dataForm.actual;
          dataSend.endDate = dataForm.full_date_to;
          dataSend.date = `${dataForm.full_date}`;
        }
        this.busy = this.timecardsService.saveDetail(dataSend).subscribe(
          res => {
            this.timecardsService.successDetailChanged$.emit({});
            Swal.fire('Successfull!', 'Punch saved.', 'success');
          }, err => {
            Swal.fire('Error!', 'Please check information and try again.', 'error');
            console.log(err);
          }
        );
      }
    });
  }

  deletePunh() {
    Swal.fire({
      title: 'Are you sure you want to remove this punch?',
      text: ``,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        const dataForm = this.form.getRawValue();
        this.busy = this.timecardsService.deleteDetail(dataForm.transaction_id).subscribe(
          res => {
            this.timecardsService.successDetailChanged$.emit({});
            Swal.fire('Successfull!', 'Punch Deleted.', 'success');
          }, err => {
            Swal.fire('Error!', 'Please check information and try again.', 'error');
            console.log(err);
          }
        );
      }
    });
  }
}
