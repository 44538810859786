import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { TsoAssignVehicleService } from '../../../../../../services/tso-assign-vehicle.service';
import { AdminDataService } from '../../../../fleet.data.service';

@Component({
  selector: 'app-block-integration',
  templateUrl: './block-integration.component.html',
  styleUrls: ['./block-integration.component.scss']
})
export class BlockIntegrationComponent implements OnInit {

  @Input() routeId?: number;
  @Output() reloadData? = new EventEmitter<any>();

  busy: Subscription;

  gridBlocksTA: GridOptions;
  gridBlocksTSO: GridOptions;
  gridBlocksIntegration: GridOptions;

  blocksTa: Array<any> = [];
  blocksTso: Array<any> = [];
  blocksIntegration: Array<any> = [];

  countBlockTa = 0;
  countBlockTSO = 0;
  countBlockIntegration = 0;
  tso_id: number;

  constructor( private _tsoAssignVehicleService: TsoAssignVehicleService, private  _dataService: AdminDataService) {
    this.gridBlocksTA = <GridOptions> { rowSelection: 'single', animateRows: true };
    this.gridBlocksTSO = <GridOptions> { rowSelection: 'single', animateRows: true };
    this.gridBlocksIntegration = <GridOptions> { rowSelection: 'single', animateRows: true };

    this.gridBlocksTA.columnDefs = this.createColumnTA();
    this.gridBlocksTSO.columnDefs = this.createColumnTSO();
    this.gridBlocksIntegration.columnDefs = this.createColumnIntegration();
  }

  ngOnInit() {
    this.loadFullData();
  }

  createColumnTA() {
    return [
      { headerName: 'ID', field: 'id', width: 50, checkboxSelection: true },
      { headerName: 'Description', field: 'description', width: 150, tooltipField: 'description', colId: 'description', sort: 'asc' },
      { headerName: 'Block Code', field: 'block_code', width: 70, tooltipField: 'block_code', colId: 'block_code', sort: 'asc' },

    ];
  }

  createColumnTSO() {
    return [
      { headerName: 'ID', field: 'ScheduleID', width: 80, checkboxSelection: true },
      { headerName: 'Description', field: 'TripHeadSign', width: 200, tooltipField: 'TripHeadSign', colId: 'TripHeadSign', sort: 'asc' },
    ];
  }

  createColumnIntegration() {
    return [
      { headerName: 'ID', field: 'id', width: 50, checkboxSelection: true },
      { headerName: 'Description', field: 'description', width: 120, tooltipField: 'description', colId: 'description', sort: 'asc' },
      { headerName: 'ID TSO', field: 'tso_id', width: 70 },
      { headerName: '', width: 40, colId: 'remove', template: `
        <span>
        <i class="fa fa-trash-o" aria-hidden="true"></i>
        </span>
      ` }
    ];
  }

  getAllBlockTSO() {
    this.busy = this._tsoAssignVehicleService.getRouteFixedSchedules(this.tso_id).subscribe(
      (data: any) => {
        if (data.Code == 1) {
          this.blocksTso = data.Data;
          this.gridBlocksTSO.api.setRowData(this.blocksTso);
          this.clearRoutesBlocksTSO();
        } else {
          Swal.fire(
            'Oops...!',
            'The selected route has not schedule created in TSO',
            'warning'
          );
        }
      }
    );
  }

  onAutoMerge() {
    this.blocksTso.forEach((row0, x) => {
      const name0 = row0.TripHeadSign;
      this.blocksTa.forEach((row1, y) => {
        const name1 = row1.description;
        if (name0.toUpperCase() === name1.toUpperCase()) {
          const obj = {
            id: row1.id,
            description: row1.description.toUpperCase(),
            tso_id: row0.ScheduleID
          };
          this.blocksIntegration.push(obj);
          this.blocksTa.splice(y, 1);
          this.blocksTso.splice(x, 1);
        }
      });
      this.setData();
    });
  }

  setData() {
    this.gridBlocksTA.api.setRowData(this.blocksTa);
    this.gridBlocksTSO.api.setRowData(this.blocksTso);
    this.gridBlocksIntegration.api.setRowData(this.blocksIntegration);
    this.getCount();
  }

  getCount() {
    this.countBlockIntegration = this.countElementGrid(this.gridBlocksIntegration);
    this.countBlockTa = this.countElementGrid(this.gridBlocksTA);
    this.countBlockTSO = this.countElementGrid(this.gridBlocksTSO);
  }

  countElementGrid(grid: GridOptions): number {
    let count = 0;
    grid.api.forEachNode((row) => {
      count++;
    });
    return count;
  }

  onMerge() {
    const blockTa = this.gridBlocksTA.api.getSelectedRows();
    const blockTso = this.gridBlocksTSO.api.getSelectedRows();

    const obj = {
      id: blockTa[0].id,
      description: blockTa[0].description.toUpperCase(),
      tso_id: blockTso[0].ScheduleID
    };
    this.blocksIntegration.push(obj);
    this.gridBlocksIntegration.api.updateRowData({ add:  [obj]});

    this.gridBlocksTA.api.updateRowData({ remove: blockTa });
    this.gridBlocksTSO.api.updateRowData({ remove: blockTso });
    this.getCount();
  }

  onCellClicked($event) {
    if ($event.colDef.colId === 'remove') {
      const data = $event.data;
      if (data && confirm('confirms the deletion of the current record?')) {
        const id = $event.data.id;
        this.busy = this._dataService.getData('routeBlocks/deleteIdTSORouteBlock/' + id).subscribe(
          (data: any) => {
            this.loadFullData();
          }
        );
      }
    }
  }

  loadFullData() {
      this.getRutaBlocks();
  }

  onSave() {
    const tem = [];
    this.gridBlocksIntegration.api.forEachNode((row) => {
      tem.push(row.data);
    });
    this.busy = this._dataService.postData(tem,'routeBlocks/storeRouteIntegration').subscribe(
      (data: any) => {
        this.loadFullData();
      }
    );
  }

  getAllRoutesBlockIntegration() {
    this.gridBlocksIntegration.api.setRowData(this.blocksTa.filter((row: any) => row.tso_id !== ''));
    this.clearRoutesBlocksTA();
  }

  clearRoutesBlocksTSO() {
    this.gridBlocksIntegration.api.forEachNode((rowNode) => {
      const tso_id = rowNode.data.tso_id;
      this.blocksTso.forEach((row, x) => {
        console.log(Number(row.ScheduleID) === Number(tso_id));
        if (Number(row.ScheduleID) === Number(tso_id)) {
          this.blocksTso.splice(x, 1);
        }
      });
    });
    this.gridBlocksTSO.api.setRowData(this.blocksTso);
    this.getCount();
  }

  clearRoutesBlocksTA() {
    this.gridBlocksIntegration.api.forEachNode((rowNode) => {
      const tso_id = rowNode.data.tso_id;
      this.blocksTa.forEach((row, x) => {
        if (Number(row.tso_id) === Number(tso_id)) {
          this.blocksTa.splice(x, 1);
        }
      });
    });
    this.gridBlocksTA.api.setRowData(this.blocksTa);
    this.getCount();
  }

  getRutaBlocks() {
      this.busy = this._dataService.getData('routes/' + this.routeId).subscribe(
        (data: any) => {
          this.blocksTa = data.route_blocks;
          this.gridBlocksTA.api.setRowData(this.blocksTa);
          this.tso_id = data.tso_id;
          this.getAllBlockTSO();
          this.getAllRoutesBlockIntegration();
        }
      );
  }
}
