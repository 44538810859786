import { Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ExceptionsService } from './../../../services/exceptions.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';

@Component({
  selector: 'app-exceptions-report',
  styleUrls: ['./exceptions-report.component.scss'],
  templateUrl: 'exceptions-report.component.html'
})

export class ExceptionsReportComponent implements OnInit {

  gridDataFile: GridOptions;
  rangeDate: any;
  listCurrent: any[] = [];
  titleCountGrid = 'Exceptions 0 of 0';
  busy: Subscription[] = [];
  type: string;
  maxDate = new Date();

  constructor(
    private route: ActivatedRoute,
    private exceptionsService: ExceptionsService,
  ) {
    this.setConfigGrid();
    const type = this.route.snapshot.paramMap.get('id');
    this.type = type;
  }

  ngOnInit() { }


  setConfigGrid() {
    this.gridDataFile = <GridOptions>{
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      onFilterModified: () => {
        const cant = this.gridDataFile.api.getDisplayedRowCount();
        this.titleCountGrid = `Exceptions ${cant} of ${this.listCurrent.length}`;
      }
    };
    this.gridDataFile.columnDefs = [
      { headerName: 'Last Name', field: 'last_name', width: 200 },
      { headerName: 'First Name', field: 'first_name', width: 200 },
      { headerName: 'Date', field: 'date', width: 120 },
      { headerName: 'Exception', field: 'exception', width: 250 },
    ];
    this.gridDataFile.onRowDoubleClicked = params => { };
  }

  search() {
    const dates = Object.assign([], this.rangeDate);
    const start: any = moment(dates[0]).format('MM-DD-YYYY');
    const end: any = moment(dates[1]).format('MM-DD-YYYY');
    switch (this.type) {
      case 'tops':
        this.busy.push(
          this.exceptionsService.getExceptionsTOPS(start, end).subscribe(res => {
            console.log(res);
            this.titleCountGrid = `Exceptions ${res.length} of ${res.length}`;
            this.gridDataFile.api.setRowData(res);
          })
        );
        break;
      default:
        this.busy.push(
          this.exceptionsService.getExceptionsTA(start, end).subscribe(res => {
            console.log(res);
            this.titleCountGrid = `Exceptions ${res.length} of ${res.length}`;
            this.gridDataFile.api.setRowData(res);
          })
        );
        break;
    }
  }

  loadDeails() { }

  export() {
    this.gridDataFile.api.exportDataAsExcel();
  }
}
