import { Component, OnInit, Input, Output, EventEmitter, HostListener, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { PayrollService } from './../../../../../../services/payroll/payroll.service';
import Swal from 'sweetalert2';

/**
  * Formulario de payroll rules
  * Este componente contiene el formulario para registrar y actualizar las reglas
  *
  * Principales funciones
  * * Guardar/Editar payroll rules
*/
@Component({
  selector: 'app-create-rule',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateRuleComponent implements OnInit, OnDestroy {
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  @Input() ruleSekected: number;
  @Input() ruleSekectedCountBlocks: number;

  busy: Subscription;
  data: any = {
    code: null,
    pay_rate: 0,
    pay_rate_part_time: 0,
    round_up: false,
    active: true,
  };
  titleModal = '';
  apiResponse: any;
  activeOrg: any;

  constructor(private payrollService: PayrollService) {
  }

  ngOnInit() {
    this.onCheckModal();
  }

  ngOnDestroy() {
    return this.busy ? this.busy.unsubscribe() : null;
  }

  onCancel() {
    this.cancel.emit(true);
  }

  onSave() {
    if (this.ruleSekected === 0) {
      this.save();
    } else {
      this.update();
    }
  }

  /**
   * @description
   */
  onCheckModal() {
    if (this.ruleSekected !== 0) {
      this.busy = this.busy = this.payrollService
        .getPayrollRulesByid(this.ruleSekected)
        .subscribe((resp: any) => {
          this.data = resp.data;
          this.activeOrg = resp.data.active;
          this.titleModal = 'Edit Rule - ' + this.data.code;
        });
    } else {
      this.titleModal = 'New Rule';
    }
  }

  /**
   * @description
   * @param {KeyboardEvent} event
   */
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const x = event.keyCode;
    if (x === 27) {
      this.onCancel();
    }
  }

  /**
   * @description
   */
  save() {
    this.busy = this.busy = this.payrollService.savePayrollRule(this.data).subscribe((resp: any) => {
      if (resp.status === 'success') {
        Swal.fire({
          position: 'top-end',
          type: 'success',
          title: 'Your work has been saved',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          this.data = {};
          this.notify.emit(true);
          this.payrollService.rulesAddedEmiter$.emit();
          this.onCancel();
        });
      }
    });
  }

  /**
   * @description
   */
  update() {
    console.log(this.data.active, this.activeOrg);

    if (Number(this.data.active) === 0 && Number(this.activeOrg) === 1) {
      Swal.fire({
        type: 'warning',
        title: `This rule is applied to ${this.ruleSekectedCountBlocks}  or more blocks. Are you sure you want to deactivate?`,
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        focusCancel: true
      }).then((res) => {
        if (res.value) {
          this.edit();
        }
      });
    } else {
      this.edit();
    }
  }

  edit() {
    delete this.data.id;
    this.busy = this.busy = this.payrollService.updatePayrollRule(this.ruleSekected, this.data).subscribe((resp: any) => {
      if (resp.status === 'success') {
        Swal.fire({
          position: 'top-end',
          type: 'success',
          title: 'Your work has been saved',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          this.data = {};
          this.notify.emit(true);
          this.payrollService.rulesAddedEmiter$.emit();
          this.onCancel();
        });
      }
    });
  }

}
