import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FilterChangedEvent, GridOptions, GridReadyEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subject, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { Attach, AttachDocumentService, DocumentObj, DocumentsService } from '../../services/services.index';
import { BadgeActiveComponent } from '../grid-components/badge-active/badge-active.component';
import AgGridHelpers from '../../helpers/ag-grid-helpers';
import { SweetAlertHelper } from '../../CORE/helpers/sweet-alert-helper.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-attach-document',
  templateUrl: './attach-document.component.html',
  styleUrls: ['./attach-document.component.scss']
})

export class AttachDocumentComponent implements OnInit, OnDestroy {
  private destroy: Subject<boolean> = new Subject<boolean>();
  @ViewChild('modalAddDocument') modalAddDocument: any;
  @ViewChild('modalLicenses') modalLicenses: any;
  @Input() model_id: number;
  @Input() model: string;
  @Input() onlyShow? = false;
  @ViewChild('btnAdd') btnAdd: ElementRef;
  showStatusDocuments: boolean = false;
  showStatusLicenses: boolean = false;
  showExpiredLicenses: boolean = false;
  data: Attach;
  gridDocuments: GridOptions;
  dataDocuments: any[] = [];
  dataLicenses: any[] = [];
  gridDataDocuments: any[] = [];
  totalDocuments: number = 0;
  itemsDocuments: number = 0;
  gridCountDocuments: number = 0;
  gridLicense: GridOptions;
  totalLicenses: number = 0;
  itemsLicense: number = 0;
  gridCountLicenses: number = 0;
  gridDataLicense: any[] = [];
  documentsType: DocumentObj[];
  busy: Subscription[] = [];

  constructor(
    private _serviceAttachDocument: AttachDocumentService,
    private _serviceDocuments: DocumentsService,
    private rendere: Renderer2,
    private ngxPermissionsService: NgxPermissionsService,
    private sweetAlertHelper: SweetAlertHelper,
  ) {
    this.createGrid();
    this.data = <Attach>{};
  }

  ngOnInit() {
    this.getAllDocuments();
    this.getAllAttachsDocuments();
    this.getAllAttachsLicenses(false);
    this.data.model = this.model;
    this.data.model_id = this.model_id;
    this.subscribeObservables();
  }

  subscribeObservables() {
    this._serviceAttachDocument.saveAttach$.pipe(takeUntil(this.destroy)).subscribe(resp => {
      this.reloadDocuments(resp);
    });
  }

  createGrid() {
    this.gridDocuments = <GridOptions>{
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      rowSelection: 'single',
      enableFilter: true,
      frameworkComponents: {
        spanStatus: BadgeActiveComponent
      },
      columnDefs: [
        { headerName: 'Documents', field: 'document.description', width: 250 },
        { headerName: 'Status', width: 100, field: 'status',
          cellRenderer: (params) => {
            if (params && params.data) {
              return AgGridHelpers.statusRecord(params.data.status);
            }
          }
        },
        { headerName: 'File', width: 100, colId: 'file', cellRenderer: ( (params) => {
            if (params && params.data) {
              return params.data.file ? `<div style="cursor: pointer"><i class="fa fa-paperclip" aria-hidden="true"></i> ${params.data.document.description}</div>` : 'N/A';
            }
          })
        },
        { headerName: 'OP', width: 60, colId: 'op', cellRenderer: ((params) => {
          return params && params.data && params.data.status ? '<i style="color: red" class="fa fa-trash-o" aria-hidden="true"></i>' : '';
          })
        }
      ],
      onGridReady: (event: GridReadyEvent) => {
        if (event) {
          this.gridDataLicense = [];
        }
      },
      onRowDoubleClicked: (event: RowDoubleClickedEvent) => {
        if (event && event.data) {
          this.onDoucleClicked(event.data);
        }
      },
      onFilterChanged: (event: FilterChangedEvent) => {
        if (event) {
          this.gridCountDocuments = event.api.getDisplayedRowCount();
        }
      }
    };
    this.gridLicense = <GridOptions> {
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      rowSelection: 'single',
      enableFilter: true,
      frameworkComponents: {
        spanStatus: BadgeActiveComponent
      },
      columnDefs: [
        { headerName: 'Documents', field: 'document.description', width: 200 },
        { headerName: 'Number', field: 'number', width: 150 },
        { headerName: 'Expiration', field: 'expiration', width: 150, cellRenderer: (params) => {
          if (params) {
            return params && params.data && params.data.status_expiration
              ? AgGridHelpers.statusExpired()
              : params.data.expiration;
          }
        } },

        { headerName: 'Status', width: 100, field: 'status',
          cellRenderer: (params) => {
            if (params && params.data) {
              return AgGridHelpers.statusRecord(params.data.status);
            }
          }
        },
        { headerName: 'File', width: 100, colId: 'file', cellRenderer: ( (params) => {
            if (params && params.data) {
              return params.data.file ? `<div style="cursor: pointer"><i class="fa fa-paperclip" aria-hidden="true"></i> ${params.data.document.description}</div>` : 'N/A';
            }
          })
        },
        { headerName: 'OP', width: 40, colId: 'op',  cellRenderer: ((params) => {
            return params && params.data && params.data.status ? '<i style="color: red" class="fa fa-trash-o" aria-hidden="true"></i>' : '';
          })
        }
      ],
      onGridReady: (event: GridReadyEvent) => {
        if (event) {
          this.gridDataLicense = [];
        }
      },
      onRowDoubleClicked: (event: RowDoubleClickedEvent) => {
        if (event && event.data) {
          this.onDoucleClicked(event.data);
        }
      },
      onFilterChanged: (event: FilterChangedEvent) => {
        if (event) {
          this.gridCountLicenses = event.api.getDisplayedRowCount();
        }
      }
    };
  }

  getAllAttachsDocuments() {
    this.busy.push(this._serviceAttachDocument.getAttachsDocumentsForModelStatus('documents', this.model_id, this.model, 1).subscribe(resp => {
      if (resp && resp.data) {
        this.dataDocuments = resp.data;
        this.gridDataDocuments = this.dataDocuments.filter(item => item.status === true);
        this.totalDocuments = this.dataDocuments.length;
        this.itemsDocuments = this.gridDataDocuments.length;
      }
    }, error => {
      this.sweetAlertHelper.captureException(error);
    }));
  }

  getAllAttachsLicenses(expired: boolean) {
      this.getStateExpiredLicenses(expired);
  }

  getStateDocumentLicenses(model: string, status: boolean) {
    if (model === 'documents') {
      this.gridDataDocuments = !status ? this.dataDocuments.filter(item => item.status === !status) : this.dataDocuments;
      this.itemsDocuments = this.gridDataDocuments.length;
    }
    if (model === 'licenses') {
      this.gridDataLicense =  !status ? this.dataLicenses.filter(item => item.status === !status) : this.dataLicenses;
      this.itemsLicense = this.gridDataLicense.length;
    }
  }

  getStateExpiredLicenses(expired: boolean) {
    const showExpired = !expired ? 1 : 0;
    this.showStatusLicenses = false;
    this.busy.push(this._serviceAttachDocument.getAttachsDocumentsForModelStatus('licenses', this.model_id, this.model, showExpired).subscribe(resp => {
      if (resp && resp.data) {
        this.dataLicenses = resp.data;
        this.gridDataLicense = this.dataLicenses.filter(item => item.status === true);
        this.totalLicenses = this.dataLicenses.length;
        this.itemsLicense = this.gridDataLicense.length;
      }
    }, error => {
      this.sweetAlertHelper.captureException(error);
    }));
  }

  getAllDocuments() {
    this.busy.push(this._serviceDocuments.getDocuments().subscribe(resp => {
      if (resp && resp.data) {
        this.documentsType = resp.data.filter(
          row => row.forType === this.model.toUpperCase()
        );
      }
    }, error => {
      this.sweetAlertHelper.captureException(error);
    }));
  }

  async onDoucleClicked(data_: any) {
    if (data_.document) {
      if (data_.document.permission) {
        const view = await this.ngxPermissionsService.hasPermission(data_.document.permission);
        if(data_.file) {
          if (view) {
            this._serviceAttachDocument.getFile(data_.file);
          } else {
            Swal.fire({
              type: 'info',
              title: 'Oops...',
              text: 'You do not have permission to view this document'
            });
          }
        } else {
          Swal.fire({
            type: 'info',
            title: 'Oops...',
            text: 'Not found File to this document'
          });
        }
      } else  {
        if(data_.file) {
          this._serviceAttachDocument.getFile(data_.file);
        } else {
          Swal.fire({
            type: 'info',
            title: 'Oops...',
            text: 'Not found File to this document'
          });
        }
      }
    }
  }

  onReload($event: boolean) {
    this.rendere.selectRootElement(this.btnAdd.nativeElement).click();
    const text = this.rendere.createText('Add Document');
    this.rendere.appendChild(this.btnAdd.nativeElement, text);
    this.getAllAttachsDocuments();
  }

  onCancelBtn($event) {
    this.rendere.selectRootElement(this.btnAdd.nativeElement).click();
    const text = this.rendere.createText('Add Document');
    this.rendere.appendChild(this.btnAdd.nativeElement, text);
  }

  oncellClicked(event) {
    if (event.colDef.colId === 'op') {
      if (!event.data.status) {
        return;
      }
      this.sweetAlertHelper
        .createCustomAlert({
          title: 'Question',
          text: `confirm that you want to delete the document?`,
          type: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        })
        .then(result => {
          if (result.value) {
            this.data = event.data;
            this.data.status = false;
            this.busy.push(this._serviceAttachDocument.updateAttachDocument(this.data, this.data.id).subscribe(
              data => {
                if (data) {
                  this.reloadDocuments(true);
                }
              }, error => {
                this.sweetAlertHelper.captureException(error);
              }
            ));
          }
        });
    }
  }

  onAddDocument() {
    this.modalAddDocument.open().then(() => {
      this._serviceAttachDocument.setAttachData(this.data);
    });
  }

  onMissingLicenses() {
    this.busy.push(this._serviceAttachDocument.getMissingLicenses('documents', this.model_id, this.model).subscribe({
      next: resp => {
        if (resp && resp.data) {
          this.modalLicenses.open().then(() => {
            this._serviceAttachDocument.getMissingLicenseData(resp.data);
          });
        }
      },
      error: error => {
        this.sweetAlertHelper.captureException(error).then();
      }
    }));

  }

  reloadDocuments(reload: boolean) {
    if (reload) {
      this.getAllDocuments();
      this.getAllAttachsDocuments();
      this.getAllAttachsLicenses(false);
    }
    this.modalAddDocument.close();
  }

  ngOnDestroy() {
    this.destroy.next(true);
    this.destroy.unsubscribe();
    this.busy.forEach(subscription => subscription.unsubscribe());
  }
}
