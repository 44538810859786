import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class ExceptionsService {

  resource: string;

  constructor(
    private httpClient: HttpClient,
  ) {
    this.resource = environment.url_api_v;
  }

  /**
   * @description Return all exeptions ta
   * @returns {Observable<any>}
   */
  getExceptionsTA(start: string, end: string): Observable<any> {
    return this.httpClient.get(`${this.resource}drivers/reportExceptions/${start}/${end}`);
  }

  /**
   * @description Return all exeptions tops
   * @returns {Observable<any>}
   */
  getExceptionsTOPS(start: string, end: string): Observable<any> {
    return this.httpClient.get(`${this.resource}employee/reportExceptions/${start}/${end}`);
  }


}
