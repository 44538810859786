import { RouterModule, Routes } from '@angular/router';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgModule } from '@angular/core';


export const routes: Routes = [
  {path: 'resetPassword/:email/:token', component: ResetPasswordComponent}
  ];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule
  ]
})
export class SharedRoutingModule { }
