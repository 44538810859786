export function capitalize(input) {
  if (input) {
    const words = input.split(' ');
    const CapitalizedWords = [];
    words.forEach(element => {
      CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));
    });
    return CapitalizedWords.join(' ');
  } else {
    return '';
  }
}

export function convertToUppercaseFirstLetter(cadena: string): string {
  if (!cadena) {
    return cadena;
  }
  return cadena.charAt(0).toUpperCase() + cadena.slice(1);
}
