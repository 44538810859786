import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CONSTANTS } from '../app.constants';

export interface IdriverManifest {

  master?: {result: IFormDrieverManifest[], status: boolean};
  message: string;
  transaction: boolean;

}

export interface IFormDrieverManifest {
  actual_check_in: string;
  actual_drop_off: string;
  amb: number;
  client_id: number;
  comments: string;
  dateOfService: string;
  driver_id: number;
  first_stop_odometer: number;
  first_stop_time: string;
  interruption: boolean;
  last_stop_odometer: number;
  last_stop_time: string;
  leave_yard_odometer: number;
  leave_yard_time: string;
  return_to_yard_odometer: number;
  return_to_yard_time: string;
  route_block_id: number;
  schedule_detail_id: number;
  str: number;
  vehicle_id: number;
  wc: number;
  user_id: number;
  actual_drop_off_full: string;
  actual_check_in_full: string;
  first_stop_time_full: string;
  last_stop_time_full: string;
  leave_yard_time_full: string;
  return_to_yard_time_full: string;
  updated_at: string;
  created_at: string;
  id: number;
}



@Injectable()
export class ScheduleDetailsService {

  protected resource: string;
  protected resource_layout: string;

  constructor(private http: HttpClient) {
    this.resource_layout = environment.url_api_v + CONSTANTS.LAYOUT_GRID;
    this.resource = environment.url_api_v + CONSTANTS.SCHEDULE_DETAILS_CLIENT;
  }

  getConfigClient(schedule_detail_id: number): Observable<any> {
    return this.http.get(this.resource + schedule_detail_id);
  }

  getConfigGrid(grid: string): Observable<any> {
    return this.http.get(this.resource_layout + `/${grid}`);
  }

  setConfigGrid(grid: string, data): Observable<any> {
    return this.http.post(this.resource_layout + `/save`, {
      config: data,
      route: grid
    });
  }

  setManifestAutomatic(schedule_detail_id: number): Observable<IdriverManifest> {
    const url = `${environment.url_api_v}${CONSTANTS.AUTOMANIFEST}${schedule_detail_id}`;
    return this.http.get<IdriverManifest>(url);
  }


}
