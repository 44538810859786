import { Component, OnInit, ViewChild, ElementRef, Input, SimpleChanges, OnDestroy, OnChanges } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { BatchInvoicesStatusService } from '../../../../../services/billing/batch/batch-invoices-status.service';
import AgGridHelpers from '../../../../../helpers/ag-grid-helpers';
import { BsModalComponent } from 'ng2-bs3-modal';
import { Subscription } from 'rxjs/Rx';

@Component({
  selector: 'app-grid-list-invoice-status',
  templateUrl: './grid-list-invoice-status.component.html',
  styleUrls: ['./grid-list-invoice-status.component.scss']
})

export class GridListInvoiceStatusComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('modalInvoiceStatus') modalInvoiceStatus: BsModalComponent;
  @ViewChild('grid') gridViewInvoiceStatus: ElementRef;
  @Input() clearGrid ?: any;
  grid: GridOptions;
  gridList: boolean;
  busy: Subscription;

  constructor(private batchInvoicesStatusService: BatchInvoicesStatusService) {
    this.initGrid();
  }

  ngOnInit() {
    this.loadData();
    this.gridList = true;
  }

  ngOnDestroy(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['clearGrid']) {
      if (this.clearGrid) {
        this.grid.api.setRowData([]);
      }
    }
  }

  initGrid() {
    this.grid = <GridOptions>{
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      rowSelection: 'single',
      enableRangeSelection: true,
      enableFilter: true,
      onSelectionChanged: () => {
          this.batchInvoicesStatusService.batchInvoiceStatusRows$.next(this.grid.api.getSelectedRows()[0]);
      },
      columnDefs: [
        { headerName: 'ID', field: 'id', width: 50 },
        { headerName: 'Description', field: 'description', width: 100 },
        { headerName: 'State', field: 'is_active', width: 80, cellRenderer: (params) => AgGridHelpers.statusRecord(params.data.is_active), }
      ]
    };
  }

  loadData() {
    this.batchInvoicesStatusService.loadInvoiceStatus$.subscribe((resp:any) => {
      if(this.grid.api) this.grid.api.setRowData(resp.data);
    });
  }

  onLoadData() {
    this.busy = this.batchInvoicesStatusService.InvoiceStatusRows$.subscribe((resp: any) => {
      this.grid.api.setRowData(resp);
    });
    this.modalInvoiceStatus.open();
  }

  onClickNewBatchInvoiceStatus() {
    this.modalInvoiceStatus.open();
  }

}
