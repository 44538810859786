import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { DriverService, IDriver } from './../../../../../services/fleet/driver.service';

@Component({
  selector: 'app-grid-routes',
  templateUrl: './grid-routes.component.html',
  styleUrls: ['./grid-routes.component.scss']
})
export class GridRoutesComponent implements OnInit {

  driver: IDriver;
  dataGrid: any[];
  gridOptions: GridOptions;
  busy: any;
  gridColumnApi: any;

  constructor(private driverService: DriverService) {
    this.setGridHeader();
    this.dataGrid = [];
  }

  ngOnInit() {
    this.driverService.driverid$.subscribe((dataDriver: IDriver) => {
      this.driver = dataDriver;
      this.loadRouteDriver();
    });
  }

  setGridHeader() {
    this.gridOptions = <GridOptions> {
      columnDefs: [
        { headerName: 'Route', field: 'route_description', width: 140, tooltipField: 'route_description' },
        { headerName: 'Block', field: 'block_description', width: 200 },
        { headerName: 'Code', field: 'block_code', width: 80 },
        { headerName: 'Start location', field: 'startLocation', width: 110 },
        { headerName: 'Day Week', field: 'week_day_id', valueGetter: this.getDayofWeek, width: 100 },
      ]
    };
  }

  loadRouteDriver() {
    try {
      this.busy = this.driverService.getDriversRoutesRelations(this.driver.id).subscribe((resp: any) => {
        if (resp.status === 'success' && resp.data.length > 0) {
          this.dataGrid = resp.data;
        } else {
          this.dataGrid = [];
        }
      }, (error: HttpErrorResponse) => {
        console.log(error);
        alert(error);
      });
    } catch (error) {
      alert(`Error: ${error}`);
    }
  }

  getDayofWeek(data: any) {
    switch (Number(data.data.week_day_id)) {
      case 1:
        return 'Sunday';
        break;
      case 2:
        return 'Monday';
        break;
      case 3:
        return 'Tuesday';
        break;
      case 4:
        return 'Wednesday';
        break;
      case 5:
        return 'Thursday';
        break;
      case 6:
        return 'Friday';
        break;
      case 7:
        return 'Saturday';
        break;
    }
  }

}
