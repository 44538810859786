import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { PayrollService } from './../../../../../../services/payroll/payroll.service';
import { GridOptions } from 'ag-grid-community';

/**
  * Listado con los cambios realizados a los datos del payroll
  *
  * Principales funciones
  * * Mostrar historial de cambios realizados entre las diferentes modificaciones en los archivos subidos
*/
@Component({
  selector: 'app-payroll-history-files-employees-rules',
  templateUrl: 'history-files.component.html',
  styleUrls: ['./history-files.component.scss']
})

export class HistoryFilesEmployeesRulesComponent implements OnInit {
  busy: Subscription[] = [];
  gridHistory: GridOptions;

  constructor(
    private payrollService: PayrollService,
  ) {
    this.setConfigGrid();
  }

  ngOnInit() {
    this.getHistory();
  }

  setConfigGrid() {
    this.gridHistory = <GridOptions>{
      enableSorting: true,
      enableColResize : true,
      sortingOrder: ['desc', 'asc', null]
    };
    this.gridHistory.columnDefs = [
      { headerName: 'Updated at', width: 170, field: 'updated_at' },
      { headerName: 'Employee', width: 180, field: 'employee_name' },
      { headerName: 'Field', width: 170, field: 'key' },
      { headerName: 'Old Value', width: 100, field: 'old_value' },
      { headerName: 'New Value', width: 100, field: 'new_value' },
      { headerName: 'User', width: 180, field: 'username' },
    ];
    this.gridHistory.onRowDoubleClicked = params => {
      // this.loadDeails(params.data);
    };
  }

  getHistory() {
    this.busy.push(this.payrollService.getHistoryUploatedEmployees().subscribe(
      (response: any) => {
        this.gridHistory.api.setRowData(response.data);
      }
    ));
  }
}
