import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SocketTracker {

  private url = environment.url_tracker_socket;
  private socket;
  socketId = null;
  routId = null;

  constructor(
    private http: HttpClient,
  ) {
    this.socket = io(this.url + 'tracker/');
    this.socket.on('subcribe', (message) => {
      console.log('your user is: ' + message);
      this.socketId = message;
      this.subscribe(this.routId);
    });
  }

  async subscribe(routeId) {
    this.routId = routeId;
    if (routeId && this.socketId) {
     const res = await this.http.put(`${environment.url_tracker_api}api/socket-current/${this.socketId}`, {route_id: routeId}).toPromise();
     console.log(res);
     return res;
    } else {
      return null;
    }
  }

  unsubscribe() {
    this.socketId = null;
    this.routId = null;
    this.socket.disconnect();
  }

  event() {
    return new Observable(subscriber => {
      this.socket.on('event_tracking', (message) => {
        subscriber.next(message);
      });
    });
  }

}
