import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { PayrollService } from './../../../../../../services/payroll/payroll.service';
import { GridOptions } from 'ag-grid-community';

/**
  * Listado de archivos subidos
  *
  * Principales funciones
  * * Mostrar historial de archivos subidos
  * * Decargar archivos
*/
@Component({
  selector: 'app-payroll-uploated-files-employees-rules',
  templateUrl: 'uploated-files.component.html',
  styleUrls: ['./uploated-files.component.scss']
})

export class UploatedFilesEmployeesRulesComponent implements OnInit {
  busy: Subscription[] = [];
  gridFiles: GridOptions;

  constructor(
    private payrollService: PayrollService,
  ) {
    this.setConfigGrid();
  }

  ngOnInit() {
    this.getFiles();
  }

  setConfigGrid() {
    this.gridFiles = <GridOptions>{
      enableSorting: true,
      enableColResize : true,
      sortingOrder: ['desc', 'asc', null]
    };
    this.gridFiles.columnDefs = [
      { headerName: 'User', width: 170, field: 'user.name' },
      { headerName: 'File', field: 'file', width: 200 },
      { headerName: 'Date', width: 180, field: 'created_at' },
      { headerName: '', width: 60, colId: 'btnDownload',
        cellRenderer: (params) => {
          return `
          <div>
            <button class="btn btn-sm btn-primary" style="width: 30px" (click)="download()"><i class="fa fa-download"></i></button>
          </div>`;
        }
      },
    ];
    this.gridFiles.onRowDoubleClicked = params => {
      // this.loadDeails(params.data);
    };
  }

  getFiles() {
    this.busy.push(this.payrollService.getFilesUploatedEmployees().subscribe(
      (response: any) => {
        this.gridFiles.api.setRowData(response.data);
      }
    ));
  }

  oncellClicked($event) {
    if ($event.colDef.colId === 'btnDownload') {
      window.open($event.data.url, '_blank');
    }
  }
}
