import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { InvoiceReportService } from '../../invoice-report.service';
import {
    ChargeBillingStatus,
    IBatchCharge, IBatchCredit, IBatchCreditType, IStatusOrTypes
} from '../../../../../../CORE/interfaces/invoice-report.interface';
import { takeUntil } from 'rxjs/operators';
import { SweetAlertHelper } from '../../../../../../CORE/helpers/sweet-alert-helper.service';
import { IResponse, ResponseStatus } from '../../../../../../CORE/interfaces/response.interface';
import { BatchCreditStateService } from '../../../../../../services/billing/batch/batch-credit-state.service';
import { isNull, omitBy, Dictionary } from 'lodash';


@Component({
  selector: 'app-add-batch-credit',
  templateUrl: './add-batch-credit.component.html',
  styleUrls: ['./add-batch-credit.component.scss']
})
export class AddBatchCreditComponent implements OnInit, OnDestroy {
  formCredit: FormGroup;
  destroy: Subject<boolean> = new Subject<boolean>();
  busy: Subscription [] = [];
  batchCharges: IBatchCharge [];
  creditTypes: IBatchCreditType[] = [];
  creditStatus: IStatusOrTypes[] = [];

  constructor(
    private fb: FormBuilder,
    private invoiceReportService: InvoiceReportService,
    private batchCreditStateService: BatchCreditStateService,
    private sweetAlertHelper: SweetAlertHelper
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.makeSubscriptions();
    this.fillFormSelect();
  }

  initForm(): void {
    this.formCredit = this.fb.group({
      id: [''],
      batch_id: [''],
      invoice_batch_charge_id: ['', Validators.required],
      credit_num: ['', Validators.required],
      batch_credit_type_id: ['', Validators.required],
      paid: ['', Validators.required],
      check_number: ['', Validators.required],
      check_date: ['', Validators.required],
      deposit_date: ['', Validators.required],
      paid_date: ['', Validators.required],
      is_active: [true, Validators.required]
    });
  }

  private makeSubscriptions(): void {
    this.invoiceReportService.creditDataToAddAction$
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (batch_id: number): void => {
          if (batch_id) {
            this.formCredit.patchValue({batch_id});
          }
        }
      });

    this.invoiceReportService.creditDataToEditAction$
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (creditToEdit: IBatchCredit): void => {
          if (creditToEdit) {
            const cleanCreditToEdit: Dictionary<any> = omitBy(creditToEdit, isNull);
            this.formCredit.patchValue(cleanCreditToEdit);
          }
        }
      });

    this.invoiceReportService.batchIdAction$
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (batchID: number): void => {
          if (batchID) {
            this.getBathCharges(batchID);
          }
        }
      });
    this.formCredit.get('invoice_batch_charge_id').valueChanges
      .subscribe({
        next: (chargeID): void => {
          const charge: IBatchCharge = this.batchCharges.find((el: IBatchCharge): boolean => el.id === chargeID);
          this.formCredit.patchValue({paid: charge ? charge.total_amount : 0});
          this.busy.push(this.batchCreditStateService.getListCreditTypes().subscribe({
              next: (resp) => {
                if (resp && resp.data) {
                  const invoice_status_description = this.batchCharges.filter(
                    t => t.id === chargeID).map(item => item.invoice_status_description)[0];
                  (invoice_status_description === ChargeBillingStatus.Original) ? this.creditTypes = resp.data
                      : this.creditTypes = resp.data.filter(t => t.charge_type === ChargeBillingStatus.All);
                }
              },
              error: error => this.sweetAlertHelper.captureException(error)
          }));
        }
      });
  }

  private getBathCharges(batchID: number): void {
    this.busy.push(
      this.invoiceReportService.getBillingChargeBatch(batchID, 1).subscribe({
        next: ({data, status}: IResponse<IBatchCharge[]>): void => {
          if (status === ResponseStatus.SUCCESS) {
            this.batchCharges = data;
          }
        },
        error: error => this.sweetAlertHelper.captureException(error)
      })
    );
  }

  onSave(): void {
    const { id } = this.formCredit.value;
    const payload = this.formCredit.value;

    const addCreditCharge = (): void => {
      const action = 'created';
      this.busy.push(
        this.invoiceReportService.createCreditChargeBatch(payload)
          .subscribe({
            next: ({status}: IResponse<IBatchCredit>): void => {
              if (status === ResponseStatus.SUCCESS) {
                this.updateGrid(action);
              }
            },
            error: error => this.sweetAlertHelper.captureException(error)
          })
      );
    };

    const editCreditCharge = (): void => {
      const action = 'updated';
      this.busy.push(
        this.invoiceReportService.updateCreditChargeBatch(id, payload)
          .subscribe({
            next: ({status}: IResponse<IBatchCredit>): void => {
              if (status === ResponseStatus.SUCCESS) {
                this.updateGrid(action);
              }
            },
            error: error => this.sweetAlertHelper.captureException(error)
          })
      );
    };

    id ? editCreditCharge() : addCreditCharge();
  }

  private updateGrid(action: string): void {
    this.sweetAlertHelper.createCustomAlert({
      type: 'success',
      title: `Credit has been ${action}.`,
      showConfirmButton: true
    }).then(
      () => this.invoiceReportService.reloadCreditGrid(true)
    );
  }

  private fillFormSelect(): void {
    this.busy.push(
      forkJoin([
        this.batchCreditStateService.getListCreditTypes(),
        this.invoiceReportService.getCreditStatus()
      ]).subscribe({
        next: (response: [IResponse<IBatchCreditType[]>, IResponse<IStatusOrTypes[]>]): void => {
          const [
            {data: creditTypes},
            {data: creditStatus}
          ]: [IResponse<IBatchCreditType[]>, IResponse<IStatusOrTypes[]>] = response;

          this.creditTypes = creditTypes;
          this.creditStatus = creditStatus;
        },
        error: error => this.sweetAlertHelper.captureException(error)
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
    this.busy.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  get isActive(): boolean {
    return !!this.formCredit.get('id').value;
  }

  get formIsInvalid(): boolean {
    return !this.formCredit.valid;
  }

  get formIsEditing(): boolean {
    return !!this.formCredit.get('id').value;
  }
}
