export class IMyDateRange {
  begin: IMyDate;
  end: IMyDate;
}

export class IMyDate {
  year: string;
  month: string;
  day: string;
}


export class Route {
  public id: number;
  public client_id: number;
  public division_id: number;
  public route_code: string;
  public description: string;
  public route_detail: Array<RouteDetail> = [];
}

export class RouteDetail {
  public driver_id: number;
  public week_day_id: number;
  public time_id: number;
}

export class RouteSingle {
  public route_id: number;
}


export class Schedule {
  week: number;
  year: number;
  datestart: string;
  dateend: string;
  active: boolean = true;
  schedule_routes: Array<Schedule_Route> = [];
}

export class Schedule_Route {
  route_id: number;
}


export class Schedule_Details {

}


export class TempSchedule {
  route_code: string;
  description: string;
  monday: string;
  tuesday: string;
  wednesday: string;
  thrusday: string;
  friday: string;
  saturday: string;
  sunday: string;
  schedules_details: Array<Schedule_detail> = new Array<Schedule_detail>();
}

export class Schedule_detail {
  route_code: string;
  description: string;
  monday: string;
  tuesday: string;
  wednesday: string;
  thrusday: string;
  friday: string;
  saturday: string;
  sunday: string;
}


//----------------------------------------------------------------------------------------------

export module ScheduleJSON {

  export interface Driver {
    id: number;
    user_id: number;
    companie_id: number;
    last_name: string;
    first_name: string;
    mi?: any;
    ssn?: any;
    date_birth?: any;
    gender?: any;
    driver_license?: any;
    license_expiry?: any;
    email?: any;
    stret_address?: any;
    stret_address_two?: any;
    city?: any;
    state?: any;
    zip?: any;
    phone?: any;
    status: number;
    created_at: string;
    updated_at: string;
  }

  export interface Routedetail {
    id: number;
    user_id: number;
    companie_id: number;
    route_id: number;
    driver_id: number;
    day_of_week_id: number;
    clock_in: string;
    start_time: string;
    end_time: string;
    clock_out: string;
    created_at: string;
    updated_at: string;
  }

  export interface Route {
    id: number;
    user_id: number;
    companie_id: number;
    citie_id: number;
    division_id: number;
    route_code: string;
    description: string;
    status: number;
    created_at: string;
    updated_at: string;
    routedetail: Routedetail[];
  }

  export interface SchedulesDetail {
    id: number;
    user_id: number;
    companie_id: number;
    schedule_route_id: number;
    route_id: number;
    driver_id: number;
    day_of_week_id: number;
    clock_in: string;
    start_time: string;
    end_time: string;
    clock_out: string;
    created_at: string;
    updated_at: string;
    driver: Driver;
    route: Route;
  }

  export interface RootObject {
    id: number;
    route_code: string;
    description: string;
    status: number;
    route_id: number;
    schedules_details: SchedulesDetail[];
  }

}
