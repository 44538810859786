import { NoauthComponent } from './shared/noauth/noauth.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginModule } from './login/login.module';
import { AuthGuard } from './_guards/auth.guard';
import { HomeComponent } from './shared/home/home.component';
import { PagesSmartComponent } from './theme/components/pages-smart/pages-smart.component';
import { UnsubscribeModule } from './unsubscribe/unsubscribe.module';


export const routes: Routes = [
  {
    path: '',
    component: PagesSmartComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'home', component: HomeComponent, data: { title: 'Home' }, },
      { path: 'noauthorized', component: NoauthComponent, data: { title: '' } },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: false }),
    LoginModule,
    UnsubscribeModule
  ],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {}
