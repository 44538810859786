import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IResponse } from '../../../../CORE/interfaces/response.interface';
import { CONSTANT } from '../../../../app.constants';
import { IClient } from '../../../../CORE/interfaces/client.interface';
import {
  IBatchCharge, IStatusOrTypes, IBatchCredit, IBatchInfoByRate, IBatchInfoByRoutes, IBatchTotalInfo,
  IConceptValuePayload, ISubjectFeeScheduleErrorModal, IBatchBalance
} from '../../../../CORE/interfaces/invoice-report.interface';
import { IPayloadBatch } from '../../../../CORE/interfaces/workflow.interface';

@Injectable({
  providedIn: 'root'
})
export class InvoiceReportService {
  public infoByClientSubject: BehaviorSubject<IClient> = new BehaviorSubject<any>({});
  infoByClientAction$: Observable<IClient> = this.infoByClientSubject.asObservable();

  public isOpenInInvoiceReportSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isOpenInInvoiceReportAction$: Observable<boolean> = this.isOpenInInvoiceReportSubject.asObservable();

  private reloadInfoBatchSubject: Subject<boolean> = new Subject<boolean>();
  reloadInfoBatchAction$: Observable<boolean> = this.reloadInfoBatchSubject.asObservable();

  private clearGridInvoiceSubject: Subject<boolean> = new Subject();
  clearGridInvoiceAction$: Observable<boolean> = this.clearGridInvoiceSubject.asObservable();

  public haveBatchChargeRevisedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  haveBatchChargeRevisedAction$: Observable<boolean> = this.haveBatchChargeRevisedSubject.asObservable();

  private clearGridCreditSubject: Subject<boolean> = new Subject();
  clearGridCreditAction$: Observable<boolean> = this.clearGridCreditSubject.asObservable();

  /** NEW! */
  private chargeDataToEditSubject: BehaviorSubject<IBatchCharge> = new BehaviorSubject(null);
  chargeDataToEditAction$: Observable<IBatchCharge> = this.chargeDataToEditSubject.asObservable();

  private chargeDataToAddSubject: BehaviorSubject<number> = new BehaviorSubject(null);
  chargeDataToAddAction$: Observable<number> = this.chargeDataToAddSubject.asObservable();

  private creditDataToEditSubject: BehaviorSubject<IBatchCredit> = new BehaviorSubject(null);
  creditDataToEditAction$: Observable<IBatchCredit> = this.creditDataToEditSubject.asObservable();

  private creditDataToAddSubject: BehaviorSubject<number> = new BehaviorSubject(null);
  creditDataToAddAction$: Observable<number> = this.creditDataToAddSubject.asObservable();

  private batchIdSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  batchIdAction$: Observable<number> = this.batchIdSubject.asObservable();

  private reloadChargeGridSubject: Subject<boolean> = new Subject<boolean>();
  reloadChargeGridAction$: Observable<boolean> = this.reloadChargeGridSubject.asObservable();

  private reloadCreditGridSubject: Subject<boolean> = new Subject<boolean>();
  reloadCreditGridAction$: Observable<boolean> = this.reloadCreditGridSubject.asObservable();

  private infoToFillTotalsSubject: Subject<IBatchTotalInfo> = new Subject<IBatchTotalInfo>();
  infoToFillTotalsAction$: Observable<IBatchTotalInfo> = this.infoToFillTotalsSubject.asObservable();

  private openFeeScheduleModalByBatchErrorSubject: BehaviorSubject<ISubjectFeeScheduleErrorModal> = new BehaviorSubject<ISubjectFeeScheduleErrorModal>({open: null, listOfCar: null, clientId: null});
  openFeeScheduleModalByBatchErrorAction$: Observable<ISubjectFeeScheduleErrorModal> = this.openFeeScheduleModalByBatchErrorSubject;

  private infoForBatchRecalculate: Subject<any> = new Subject<any>();
  infoForBatchRecalculateAction$: Observable<any> = this.infoForBatchRecalculate.asObservable();

  public reloadRecalculateSubject: Subject<boolean> = new Subject<boolean>();
  reloadRecalculateAction$: Observable<boolean> = this.reloadRecalculateSubject.asObservable();
  constructor(private http: HttpClient) {
  }

  openFeeScheduleModalByBatchError(data: ISubjectFeeScheduleErrorModal): void {
    this.openFeeScheduleModalByBatchErrorSubject.next(data);
  }

  setInfoToFillTotals(data: IBatchTotalInfo): void {
    this.infoToFillTotalsSubject.next(data);
  }

  setBatchId(batchId: number): void {
    this.batchIdSubject.next(batchId);
  }

  reloadChargeGrid(reload: boolean): void {
    this.reloadChargeGridSubject.next(reload);
  }

  reloadCreditGrid(reload: boolean): void {
    this.reloadCreditGridSubject.next(reload);
  }

  setCreditDateToAdd(batchId: number): void {
    this.creditDataToAddSubject.next(batchId);
    this.creditDataToEditSubject.next(null);
  }

  setCreditDateToEdit(data: IBatchCredit): void {
    this.creditDataToEditSubject.next(data);
    this.creditDataToAddSubject.next(null);
  }

  setChargeDataToEdit(batchId: number): void {
    this.chargeDataToAddSubject.next(batchId);
    this.chargeDataToEditSubject.next(null);
  }

  setChargeDateToEdit(data: IBatchCharge): void {
    this.chargeDataToEditSubject.next(data);
    this.chargeDataToAddSubject.next(null);
  }

  setinfoForBatchRecalculate(data: any): void {
    this.infoForBatchRecalculate.next(data);
  }

  clearGridInvoice(): void {
    this.clearGridInvoiceSubject.next(true);
  }

  clearGridCredit(): void {
    this.clearGridCreditSubject.next(true);
  }

  reloadInfoBatch(): void {
    this.reloadInfoBatchSubject.next(true);
  }

  getBillingInfoBatchByRate(payload: IPayloadBatch): Observable<IResponse<IBatchInfoByRate[]>> {
    return this.http.post<IResponse<IBatchInfoByRate[]>>(`${CONSTANT.BILLING.URL.BILLING_GET_INFO_BATCH_BY_RATE}`, payload);
  }

  getBillingInfoBatchByRoute(payload: IPayloadBatch): Observable<IResponse<IBatchInfoByRoutes[]>> {
    return this.http.post<IResponse<IBatchInfoByRoutes[]>>(`${CONSTANT.BILLING.URL.BILLING_GET_INFO_BATCH_BY_ROUTE}`, payload);
  }

  getCreditChargeBatch(batchId, active = 1): Observable<IResponse<IBatchCredit[]>> {
    return this.http.get<IResponse<IBatchCredit[]>>(`${CONSTANT.BILLING.URL.BILLING_GET_CREDIT_CHARGE_BATCH}/${batchId}/${active}`);
  }

  reverseChargeBatch(chargeID: number): Observable<IResponse<number>> {
    const URL = `${CONSTANT.BILLING.URL.BILLING_PATCH_CHARGE_BATCH_REVERSE}/${chargeID}`;
    return this.http.patch<IResponse<number>>(URL, null);
  }

  reverseCreditBatch(creditID: number): Observable<IResponse<number>> {
    const URL = `${CONSTANT.BILLING.URL.BILLING_PATCH_CREDIT_BATCH_REVERSE}/${creditID}`;
    return this.http.patch<IResponse<number>>(URL, null);
  }

  updateCreditChargeBatch(creditChargeId, payload): Observable<any> {
    return this.http.put<any>(`${CONSTANT.BILLING.URL.BILLING_CREDIT_CHARGE_BATCH}/${creditChargeId}`, payload);
  }

  createCreditChargeBatch(payload): Observable<IResponse<IBatchCredit>> {
    return this.http.post<IResponse<IBatchCredit>>(`${CONSTANT.BILLING.URL.BILLING_CREDIT_CHARGE_BATCH}`, payload);
  }

  getBillingChargeBatch(batchId: number, active: number): Observable<IResponse<IBatchCharge[]>> {
    return this.http.get<IResponse<IBatchCharge[]>>(`${CONSTANT.BILLING.URL.BILLING_GET_CHARGE_BATCH}/${batchId}/${active}`);
  }

  updateBillingChargeBatch(chargeBatchId, payload): Observable<IResponse<IBatchCharge>> {
    return this.http.put<IResponse<IBatchCharge>>(`${CONSTANT.BILLING.URL.BILLING_CHARGE_BATCH}/${chargeBatchId}`, payload);
  }

  createBillingChargeBatch(payload): Observable<IResponse<IBatchCharge>> {
    return this.http.post<IResponse<IBatchCharge>>(`${CONSTANT.BILLING.URL.BILLING_CHARGE_BATCH}`, payload);
  }

  getTypeCharges(): Observable<IResponse<IStatusOrTypes []>> {
    return this.http.get<IResponse<IStatusOrTypes[]>>(`${CONSTANT.BILLING.URL.BILLING_GET_CHARGE_TYPES}`);
  }

  getConceptValue(payload: IConceptValuePayload): Observable<IResponse<number>> {
    return this.http.post<IResponse<number>>(`${CONSTANT.BILLING.URL.BILLING_GET_CONCEPT_VALUE}`, payload);
  }

  getCreditStatus(): Observable<IResponse<IStatusOrTypes[]>> {
    return this.http.get<IResponse<IStatusOrTypes[]>>(CONSTANT.BILLING.URL.BILLING_GET_CREDIT_STATUS);
  }

  getInvoiceBatchBalance(batchID: number): Observable<IResponse<IBatchBalance>> {
    return this.http.get<IResponse<IBatchBalance>>(`${CONSTANT.BILLING.URL.BILLING_GET_BALANCE}/${batchID}`);
  }

  generatePayloadToBatch({date_end, date_start, client_id}: IPayloadBatch): IPayloadBatch {
    return {
      date_end,
      date_start,
      client_id
    };
  }

  recalculate(payload: any): Observable<IResponse<any>> {
    return this.http.post<IResponse<any>>(`${CONSTANT.BILLING.URL.BILLING_RECALCULATE}`, payload);
  }

  validateChargeRepeat(batchID: number): Observable<number> {
    return this.http.get<number>(`${CONSTANT.BILLING.URL.BILLING_CHARGE_REPEAT}/${batchID}`);
  }
}
