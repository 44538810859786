import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InterruptionService {

  constructor( private http: HttpClient) { }

  /**
    * @returns Obtener interrupciones dentor de un rango de tiempo
  */
  getInterruptionRangeDate(startDate: string, endDate: string) {
    return this.http.get(`${environment.url_api_v}servicesInterruptions/rangeDate/${startDate}/${endDate}`);
  }

  /**
    * @returns Delete Interruption
  */
  deleteInterruption(interruption_id: string) {
    return this.http.delete(`${environment.url_api_v}servicesInterruptions/${interruption_id}`);
  }

}
