import { Component, EventEmitter, Input, OnChanges, OnInit, Output, OnDestroy, SimpleChange } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BatchCreditStateService } from './../../../../../services/billing/batch/batch-credit-state.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-credit-status',
  templateUrl: './add-credit-status.component.html',
  styleUrls: ['./add-credit-status.component.scss']
})
export class AddCreditStatusComponent implements OnInit {
  form: FormGroup;
  @Output() onClose? = new EventEmitter<any>();
  @Output() onReload ? = new EventEmitter<any>();
  @Input() dataFilter ?: any;
  @Input() clearGrid ?: any;
  selectedCreditType:any = [];

  constructor(private fb: FormBuilder,
              private batchCreditStateService: BatchCreditStateService
    ) { this.initForm();  }



  ngOnInit() {
    this.stateForm();
  }
  initForm() {
    this.form = this.fb.group({
      id: [''],
      credit_type_id: ['', Validators.required],
      description : ['', Validators.required],
      is_active: ['', Validators.required]
    });
  }

  stateForm() {
    this.batchCreditStateService.bacthInvoiceCreditStateRows$.subscribe((resp: any) => {
      if (resp) {
        if(resp.description){
          this.form.setValue({
            id: resp.id,
            credit_type_id: resp.credit_type_id,
            description: resp.description,
            is_active: resp.is_active
          });
        }else{
          this.form.setValue({
            id: '',
            credit_type_id: '',
            description: '',
            is_active: false
          });
        }
      }
    })
  }

  onCancelModalBatchCreditStatus() {

  }

  onSave() {
    this.batchCreditStateService.addBatchInvoiceCreditStates(this.form.value).subscribe((resp: any) => {
      if (resp.status === 'success') {
        Swal.fire({
          position: 'top-end',
          type: 'success',
          title: 'Your work has been saved',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          this.onReload.emit(this.form.value);
        });
      }
    });
  }
  onSubmit() {
    Swal.fire({
      title: 'Are you sure?',
      html: '<h4>Do you want to create the Status Invoice?</h4>',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((response: any) => {
      if (response.value) {
        this.onSave();
      }
    });
  }

}
