import { TimecardsService } from './../../../../../services/payroll/timecards.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { BsModalComponent } from 'ng2-bs3-modal';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';

/**
  * Componente con el listado de punches de un employee.
  * Este componente corresponde a la grilla cocn los punches buscados para un employee.
  *
  * Principales funciones
  * * Ver punches de un emloyee
*/
@Component({
  selector: 'app-time-card',
  templateUrl: './time-card.component.html',
  styleUrls: ['./time-card.component.scss']
})
export class TimeCardComponent implements OnInit {
  @ViewChild('modal') modal: BsModalComponent;

  gridTimeCard: GridOptions;
  dataEmployee: any;
  getContextMenuItems;
  busy: Subscription;

  titleModal = '';

  driverid = null;

  constructor(
    private timecardsService: TimecardsService,
  ) {
    this.setConfigGrid();
  }

  ngOnInit() {
    this.timecardsService.schDriverSelected$.subscribe(
      res => {
        this.dataEmployee = res.data;
        this.driverid = res.details.driver_id;
        this.renderData(res.details);
      }, err => {
        console.log(err);
      }
    );
    this.timecardsService.successDetailChanged$.subscribe(
      res => {
        this.modal.close();
      }
    );
  }

  renderData(data) {
    const rows: any[] = [];
    if (data.weeks) {
      data.weeks.forEach(week => {
        week.days.forEach(day => {
          day.totalType = 0;
          rows.push(day);
        });
        rows.push({
          exception: '',
          date: '',
          action: '',
          route_name: '',
          route: '',
          schedule: '',
          actual: 'Week ' + week.week + ':',
          reg: week.reg,
          ot: week.ot,
          location: '',
          totalType: 1,
        });
      });
      rows.push({
        exception: '',
        date: '',
        action: '',
        route_name: '',
        route: '',
        schedule: '',
        actual: 'Pay Period: ',
        reg: data.totalreg,
        ot: data.totalot,
        location: '',
        totalType: 2,
      });
    }
    this.gridTimeCard.api.setRowData(rows);
  }

  setConfigGrid() {
    this.gridTimeCard = <GridOptions>{
      enableSorting: true,
      enableColResize : true,
      sortingOrder: ['desc', 'asc', null],
    };
    this.gridTimeCard.columnDefs = [
      { headerName: 'Exception', field: 'exception', width: 90 },
      { headerName: 'Date', width: 65, field: 'date' },
      { headerName: 'Action', width: 80, field: 'action' },
      { headerName: 'Route', width: 120, field: 'route_name' },
      { headerName: 'Block', width: 120, field: 'route' },
      { headerName: 'Schedule', width: 70, field: 'schedule' },
      { headerName: 'Actual', width: 90, field: 'actual' },
      { headerName: 'Reg', width: 70, field: 'reg' },
      { headerName: 'OT', width: 70, field: 'ot' },
      { headerName: 'Location', width: 120, field: 'location'},
      { headerName: 'Amount Description', width: 140, field: 'amount_description'}
    ];
    this.gridTimeCard.getRowClass = params => {
      if (Number(params.data.totalType) === 1) {
        return 'timecard_item_total';
      } else if (Number(params.data.totalType) === 2) {
        return 'timecard_item_totalgen';
      } else {
        return 'timecard_item_nototal';
      }
    };
    this.getContextMenuItems = (params) => {
      const x = this.gridTimeCard;
      const result = [
        params.node.data.transaction_id ? {
          name: 'Reviewed',
          cssClasses: ['redFont', 'bold'],
          // tslint:disable-next-line: max-line-length
          icon: '<i class="fa fa-eye"></i>',
          action: () => {
            if (Number(params.node.data.totalType) === 0) {
              this.reviewedPunch(params.node.data);
            }
          }
        } : 'separator',
        {
          name: 'Add',
          action: () => {
            if (Number(params.node.data.totalType) === 0 || Number(params.node.data.totalType) === 1) {
              this.titleModal = 'Add';
              this.modal.open();
              this.timecardsService.editDetailSelected$.emit({data: this.dataEmployee, params: params.node.data, driverid: this.driverid, new: true});
              this.timecardsService.closeModalClear(true);
            }
          },
          cssClasses: ['redFont', 'bold'],
          icon: '<i class="fa fa-plus"></i>',
        },
        {
          name: 'Edit',
          cssClasses: ['redFont', 'bold'],
          // tslint:disable-next-line: max-line-length
          icon: '<i class="fa fa-edit"></i>',
          action: () => {
            if (Number(params.node.data.totalType) === 0) {
              this.titleModal = 'Edit';
              this.modal.open();
              this.timecardsService.editDetailSelected$.emit({data: this.dataEmployee, params: params.node.data, driverid: this.driverid, new: false});
              this.timecardsService.closeModalClear(true);
            }
          }
        },
        params.node.data.transaction_id ? {
          name: 'Delete',
          cssClasses: ['redFont', 'bold'],
          // tslint:disable-next-line: max-line-length
          icon: '<i class="fa fa-remove"></i>',
          action: () => {
            if (Number(params.node.data.totalType) === 0) {
              this.deletePunh(params.node.data);
            }
          }
        } : 'separator',
        'separator',
        'copy',
        'export',
      ];
      return result;
    };
  }

  deletePunh(data) {
    Swal.fire({
      title: 'Are you sure you want to remove this punch?',
      text: ``,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        this.busy = this.timecardsService.deleteDetail(data.transaction_id).subscribe(
          res => {
            this.timecardsService.successDetailChanged$.emit({});
            Swal.fire('Successfull!', 'Punch Deleted.', 'success');
          }, err => {
            Swal.fire('Error!', 'An error has occurred, please contact your system administrator', 'error');
            console.log(err);
          }
        );
      }
    });
  }

  reviewedPunch(data) {
    Swal.fire({
      title: 'Are you sure you want to reviewed this punch?',
      text: ``,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        this.busy = this.timecardsService.reviewedDetail({
          transactionId: data.transaction_id,
          transactionType: data.type,
          transactionDate: data.full_date.split('T')[0],
          reviewed: true,
        }).subscribe(
          res => {
            this.timecardsService.successDetailChanged$.emit({});
            Swal.fire('Successfull!', 'Punch reviewed.', 'success');
          }, err => {
            console.log(err);
          }
        );
      }
    });
  }

}
