import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { AuthService } from './../../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PayrollService {

  private progressObserver: any;
  private progress: any;

  constructor( private http: HttpClient, private _serviceAuth: AuthService) { }

  schDriverSelected$ = new EventEmitter<any>();
  rulesAddedEmiter$ = new EventEmitter<any>();

  /**
    * @returns Servicio para listar reglas
  */
  getPayrollRules() {
    return this.http.get(`${environment.url_api}payrollRules`);
  }

  /**
    * @returns Servicio para traer bloques con sus reglas
  */
  getBlocks(id) {
    return this.http.get(`${environment.url_api}payrollRules/block/all/${id}`);
  }

  /**
    * @returns Servicio para consultar una regla
  */
  getPayrollRulesByid(id) {
    return this.http.get(`${environment.url_api}payrollRules/${id}`);
  }

  /**
    * @returns Servicio para guardar regla
  */
  savePayrollRule(data) {
    return this.http.post(`${environment.url_api}payrollRules`, data);
  }

  /**
    * @returns Servicio para editar una regla
  */
  updatePayrollRule(id, data) {
    return this.http.put(`${environment.url_api}payrollRules/${id}`, data);
  }

  /**
    * @returns Aplicar reglas
  */
  applyRule(data) {
    return this.http.put(`${environment.url_api}payrollRules/blockRule/update`, data);
  }

  /**
    * @returns Servicio para subir nuevo arvhivo de payroll
  */
  uploadEpmployeesRules(data) {
    // const formData = new FormData();
    // const header = new HttpHeaders();
    // formData.append('import_file', data);
    // header.append('content-type', 'multipart/form-data');
    // return this.http.post(`${environment.url_api_v}frankcrum/importFile`, formData, {headers : header});
    return Observable.create(observer => {
      this.progressObserver = observer;

      const formData: FormData = new FormData(),
        xhr: XMLHttpRequest = new XMLHttpRequest();

      formData.append('import_file', data, data.name);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(JSON.parse(xhr.response));
            observer.complete();
          }
        }
      };

      xhr.upload.onprogress = (event) => {
        this.progress = Math.round((event.loaded / event.total) * 100);
        this.progressObserver.next(this.progress);
      };
      xhr.open('POST', `${environment.url_api_v}frankcrum/importFile`, true);
      xhr.setRequestHeader('Authorization', `Bearer ${this._serviceAuth.getTokenLocalStorage()}`);
      xhr.setRequestHeader('namedb', `${this._serviceAuth.getNameDbLocalStorage()}`);
      xhr.send(formData);
    });
  }

  /**
    * @returns Consulta listado de employees con su regla actual
  */
  getEmployeesCurrentRules() {
    return this.http.get(`${environment.url_api_v}frankcrum`);
  }

  /**
    * @returns Consulta listado de archivos subidos
  */
  getFilesUploatedEmployees() {
    return this.http.get(`${environment.url_api_v}frankcrumManager`);
  }

  /**
    * @returns Consultar historial de archivos subidos
  */
  getHistoryUploatedEmployees() {
    return this.http.get(`${environment.url_api_v}frankcrum/employees/history/limit`);
  }

  /**
    * @returns Asignar bloques a un supervisor
  */
  assignBlocksSupervisor(data) {
    return this.http.put(`${environment.url_api}frankCrum/supervisor/update`, data);
  }

  getPayrollMonth() {
    return this.http.get(`${environment.url_api}v1/payrollMonth/list`);
  }

}
