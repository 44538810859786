import { DatePipe } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { Schedule } from '../../schedule.model';
import { DashboardLsfDataService } from './../../../dashboard-lsf.data.service';
import { Route } from './../../schedule.model';

@Component({
  selector: 'app-create-schedule',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})

export class CreateScheduleComponent implements OnInit, OnDestroy {

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClose = new EventEmitter<boolean>();

  @Input() scheduleSekected: number;
  @Input() dateDisable: any;
  @Input() arrayWeek: Array<any>;
  @Input() yearSelected: any;

  schedule: any = {};
  busy: Subscription;
  schedule_routes: Array<any> = new Array<any>();

  routes_left: Array<Route> = new Array<Route>();
  routes_right: Array<Route> = new Array<Route>();
  startDate: any;
  endDate: any;
  week: any;

  apiResponse: any;
  weekSelected = true;
  bntIndividual1 = true;
  bntIndividual2 = true;
  routes: any;

  routes_left_select: any;
  routes_right_select: any;

  constructor( private  _dataService: DashboardLsfDataService, public datepipe: DatePipe ) {
  }

  ngOnInit() {
    this.loadData();
  }

  ngOnDestroy() {
    return this.busy ? this.busy.unsubscribe() : null;
  }

  close() {
    this.onClose.emit(true);
  }

  onSave() {
    if (this.routes_right.length >= 1 && (this.week = !0)) {
      this.schedule.active = 1;
      this.clearJSON(this.routes_right);
      this.schedule.schedule_routes = this.schedule_routes;
      this.busy = this._dataService.postData(this.schedule, 'schedules').subscribe(
        data => {
          this.apiResponse = data;
          if (this.apiResponse.transaction) {
            Swal.fire(
              'Success!',
              this.apiResponse.message,
              'success'
            ).then(() => {
              this.schedule = new Schedule;
              this.notify.emit('saved record');
              this.close();
            });
          } else {
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: this.apiResponse.message,
            });
          }
        });
    } else {
      Swal.fire({
        type: 'warning',
        title: 'Oops...',
        text: 'Select a week, and at least one routes-block !',
      });
    }
  }

  clearJSON(route: Array<any>) {
    this.schedule_routes = [];
    for (const entry of route) {
      const t: any = {};
      t.route_block_id = entry.id;
      t.route_id = entry.route_id;

      this.schedule_routes.push(t);
    }
  }

  loadData() {
    this.loadRouteBlocks();
  }

  moveAll(from: any, to: any) {
    if (from != null) {
      for (const entry of from) {
        to.push(entry);
      }
      from.length = 0;
    }
  }

  moveItem(item: any, from: any, to: any) {
    if (item === undefined) {
    } else {
      for (let x = 0; x <= item.length - 1; x++) {
        const idx = this.getElementoArray(item[x], from);
        if (idx.length > 0) {
          this.deleteElementArray(item[x], from);
          to.push(idx[0]);
        }
      }
    }

    this.bntIndividual1 = true;
    this.bntIndividual2 = true;
  }

  getElementoArray(id: any, list: Array<Route>) {
    const x = list.filter(function (item) {
      return item.id == id;
    });
    return x;
  }

  deleteElementArray(id: any, list: Array<Route>) {
    list.forEach(function (data, index) {
      if (data.id == id) {
        list.splice(index, 1);
      }
    });
  }

  /**
   * @description Este metodo, se toma el valor de un input type Week de HTML y separa la fecha la inicial, fecha final
   * y el numero de la semana
   * @param value
   */
  changeWeek(value: any): void {
    const obt: Array<any> = this.getDateStartAndEnd(this.yearSelected, value);
    const dateStart = obt[0];
    const dateEnd = obt[1];

    this.startDate = dateStart;
    this.endDate = dateEnd;
    this.week = value;

    this.schedule.week = value;
    this.schedule.year = this.yearSelected;
    this.schedule.datestart = dateStart;
    this.schedule.dateend = dateEnd;

    if (value.length > 0) {
      this.weekSelected = false;
    }
  }

  /**
   * @description Este metodo se encarga de verificar si se selecciono en alguno de los dos Select algun
   * item, para poder activar o desactivar los botones de translado de intens individual
   * @param value
   * @param {number} col
   */
  selectedItem(value: any, col: number) {
    if (value = !0) {
      if (col === 1) {
        this.bntIndividual1 = false;
        this.bntIndividual2 = true;
      } else {
        this.bntIndividual2 = false;
        this.bntIndividual1 = true;
      }
    }
  }

  /**
   * @description Esta funcion retorna la fecha inicial y fecha final de una determinada semana, de un determinado año
   * el isoWeek, le indica a la libreria moment que la semana comienza el dia domingo, para que sea el lunes es weeks
   * @param year
   * @param week
   */
  getDateStartAndEnd(year: any, week: any) {
    const dateStart = moment().weekYear(year).week(week).startOf('week').format('YYYY-MM-DD ');
    const dateEnd = moment().weekYear(year).week(week).endOf('week').format('YYYY-MM-DD ');

    const tem: Array<any> = new Array<any>();
    tem.push(dateStart);
    tem.push(dateEnd);

    return tem;
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const x = event.keyCode;
    if (x === 27) {
      this.close();
    }
  }

  /**
   * @description Carga los routeBlocks
   */
  loadRouteBlocks() {
    this.busy = this._dataService.getData('blocksByActive/1').subscribe(
      (response: any) => {
        this.routes = response;
        this.routes_left = response;
      }
    );
  }

  getValueBlock(data: any) {
    if ( data.route.client_id === 37 && data.block_code === '') {
      return data.route.description + ' - ' + data.description;
    } else {
      return (data.block_code ? data.block_code + ' - ' : ' ') + data.description;
    }
  }

  copyLast() {
    this.busy = this._dataService.getLastScheduleUser().subscribe(
      (response: any) => {
        console.log(response);
        response.data.forEach(element => {
          this.moveItem([element.route_block_id], this.routes_left, this.routes_right);
        });
      }
    );
  }

}
