import { Component, OnInit, ViewChild, ElementRef, Input, SimpleChanges, OnDestroy, OnChanges } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { BatchCreditStateService } from './../../../../../services/billing/batch/batch-credit-state.service';
import AgGridHelpers from '../../../../../helpers/ag-grid-helpers';

@Component({
  selector: 'app-grid-list-credit-status',
  templateUrl: './grid-list-credit-status.component.html',
  styleUrls: ['./grid-list-credit-status.component.scss']
})
export class GridListCreditStatusComponent implements OnInit {

  @ViewChild('grid') gridView: ElementRef;
  @Input() clearGrid ?: any;
  grid: GridOptions;
  constructor(private batchCreditStateService:BatchCreditStateService) { }

  ngOnInit() {
  }

  initGrid() {
    this.grid = <GridOptions>{
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      rowSelection: 'single',
      enableRangeSelection: true,
      enableFilter: true,
      onSelectionChanged: () => {
          this.batchCreditStateService.loadBatchInvoiceCreditStatesRow$.next(this.grid.api.getSelectedRows()[0]);
      },
      columnDefs: [
        { headerName: 'ID', field: 'id', width: 50 },
        { headerName: 'Description', field: 'description', width: 120 },
        { headerName: 'State', field: 'is_active', width: 100, cellRenderer: (params) => AgGridHelpers.statusRecord(params.data.is_active), }
      ]
    };
  }

}
