import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AdminDataService } from '../../../fleet.data.service';
import { btnSave, RouteBlockDetail, RouteBlockHead } from '../../route-block.model';
import { Driver, TimeTypes } from '../../../route.model';
import { RunsService } from '../../../../../services/fleet/runs.service';
import { BsModalComponent } from 'ng2-bs3-modal';
import { SweetAlertHelper } from '../../../../../CORE/helpers/sweet-alert-helper.service';

declare let $: any;

@Component({
  selector: 'app-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.scss']
})

export class BlockComponent implements OnInit, OnChanges {
  @ViewChild('modal') modal: BsModalComponent;
  public busy: Subscription;
  @Input() drivers: Driver;
  @Input() vehicles: any;
  @Input() stops?: any[];
  @Input() runs?: any[];

  // Nuevas Clases

  @Input() route_id: number;
  @Input() isCollapsed: boolean;
  @Input() countView: number;

  @Input() routeSekected: number;

  @Input() id = 0;

  @Output() incrementCount: EventEmitter<any> = new EventEmitter<any>();

  @Input() timeTypes: Array<TimeTypes>;

  @Input() route_edit_clear: boolean;

  @Output() reloadRuns?: EventEmitter<any> = new EventEmitter<any>();


  public routeBlockHead: any = {};
  public routeBlockHeadTemp: any;

  public routeBlockDetail_sunday: RouteBlockDetail = new RouteBlockDetail;
  public routeBlockDetail_monday: RouteBlockDetail = new RouteBlockDetail;
  public routeBlockDetail_tuesday: RouteBlockDetail = new RouteBlockDetail;
  public routeBlockDetail_wednesday: RouteBlockDetail = new RouteBlockDetail;
  public routeBlockDetail_thursday: RouteBlockDetail = new RouteBlockDetail;
  public routeBlockDetail_friday: RouteBlockDetail = new RouteBlockDetail;
  public routeBlockDetail_saturday: RouteBlockDetail = new RouteBlockDetail;


  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  public saveObj: btnSave = new btnSave();

  public classColorTimeType = '';

  public time_format = [/[0-9]/, /\d/, ':', /\d/, /\d/];
  public pattern_time = '(0[0-9]|1[0-9]|2[0-9]|3[0-6])(:[0-5][0-9]){1}';

  public placeholder_time = 'hh:mm';

  public driverOpen: any = null;
  public msjDriverSelectSunday = 'Open';
  public msjDriverSelectMonday = 'Open';
  public msjDriverSelectTuesday = 'Open';
  public msjDriverSelectWednesday = 'Open';
  public msjDriverSelectThursday = 'Open';
  public msjDriverSelectFriday = 'Open';
  public msjDriverSelectSaturday = 'Open';


  public defaultValueTimeType = 5;

  // Fin Clase Nueva


  @Output() addComponent: EventEmitter<any> = new EventEmitter<any>();
  @Output() EditComponent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() edit: boolean;

  private apiResponse: any;

  @Input() route_blocks: any;
  @Input() len_route_block: any;

  public msgBlokeDetaild = false;

  public sunday_driver_id: any = '';
  public monday_driver_id: any = '';
  public tuesday_driver_id: any = '';
  public wednesday_driver_id: any = '';
  public thursday_driver_id: any = '';
  public friday_driver_id: any = '';
  public saturday_driver_id: any = '';

  public sunday_vehicle_id: any = '';
  public monday_vehicle_id: any = '';
  public tuesday_vehicle_id: any = '';
  public wednesday_vehicle_id: any = '';
  public thursday_vehicle_id: any = '';
  public friday_vehicle_id: any = '';
  public saturday_vehicle_id: any = '';
  public placeholder_driver = 'Open';

  @Output() reloadData?: EventEmitter<any> = new EventEmitter<any>();

  constructor(private  _dataService: AdminDataService, public toastr: ToastrService,
              private _sanitizer: DomSanitizer, private runsService: RunsService, private sweetAlertHelper: SweetAlertHelper) {
    this.routeBlockDetail_sunday.week_day_id = 1;
    this.routeBlockDetail_monday.week_day_id = 2;
    this.routeBlockDetail_tuesday.week_day_id = 3;
    this.routeBlockDetail_wednesday.week_day_id = 4;
    this.routeBlockDetail_thursday.week_day_id = 5;
    this.routeBlockDetail_friday.week_day_id = 6;
    this.routeBlockDetail_saturday.week_day_id = 7;
  }

  autocompleListFormatter = (data: any) => {
    const html = `<span>${data.first_name} ${data.last_name} </span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

  autocompleListFormatterVehicle = (data: any) => {
    const html = `<span>${data.description}</span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

  myValueFormatter(data: any): string {
    const html = data.last_name + ' ' + data.first_name;
    return html;
  }

  ngOnChanges(changes: any) {
    /*if (changes['route_blocks']) {
     alert(JSON.stringify(this.route_blocks));
     }*/
  }

  ngOnInit() {
    if (this.routeSekected > 0 && !(this.route_edit_clear)) {
      delete this.route_blocks.route;
      this.routeBlockHead = this.route_blocks;
      const first_id = this.routeBlockHead.first_stop_id;
      this.routeBlockHead.first_stop_id = first_id ? first_id : undefined;
      if (this.route_blocks.route_block_details) {
        if (this.route_blocks.route_block_details.length > 0) {

          if (this.route_blocks.route_block_details[0] != null) {
            this.routeBlockDetail_sunday = this.route_blocks.route_block_details[0];
            this.sunday_driver_id = this.routeBlockDetail_sunday.driver;
            this.sunday_vehicle_id = this.routeBlockDetail_sunday.vehicle;
            delete this.routeBlockDetail_sunday.driver;
            delete this.routeBlockDetail_sunday.vehicle;
            delete this.routeBlockDetail_sunday.time_type;
          } else {
            this.routeBlockDetail_sunday = new RouteBlockDetail;
          }

          if (this.route_blocks.route_block_details[1] != null) {
            this.routeBlockDetail_monday = this.route_blocks.route_block_details[1];
            this.monday_driver_id = this.routeBlockDetail_monday.driver;
            this.monday_vehicle_id = this.routeBlockDetail_monday.vehicle;
            delete this.routeBlockDetail_monday.driver;
            delete this.routeBlockDetail_monday.vehicle;
            delete this.routeBlockDetail_sunday.time_type;
          } else {
            this.routeBlockDetail_monday = new RouteBlockDetail;
          }

          /*this.routeBlockDetail_monday = this.route_blocks.route_block_details[0];
           this.monday_driver_id = this.routeBlockDetail_monday.driver;
           delete this.routeBlockDetail_monday.driver;*/


          if (this.route_blocks.route_block_details[2] != null) {
            this.routeBlockDetail_tuesday = this.route_blocks.route_block_details[2];
            this.tuesday_driver_id = this.routeBlockDetail_tuesday.driver;
            this.tuesday_vehicle_id = this.routeBlockDetail_tuesday.vehicle;
            delete this.routeBlockDetail_tuesday.driver;
            delete this.routeBlockDetail_tuesday.vehicle;
            delete this.routeBlockDetail_sunday.time_type;
          } else {
            this.routeBlockDetail_tuesday = new RouteBlockDetail;
          }

          if (this.route_blocks.route_block_details[3] != null) {
            this.routeBlockDetail_wednesday = this.route_blocks.route_block_details[3];
            this.wednesday_driver_id = this.routeBlockDetail_wednesday.driver;
            this.wednesday_vehicle_id = this.routeBlockDetail_wednesday.vehicle;
            delete this.routeBlockDetail_wednesday.driver;
            delete this.routeBlockDetail_wednesday.vehicle;
            delete this.routeBlockDetail_sunday.time_type;
          } else {
            this.routeBlockDetail_wednesday = new RouteBlockDetail;
          }

          if (this.route_blocks.route_block_details[4] != null) {
            this.routeBlockDetail_thursday = this.route_blocks.route_block_details[4];
            this.thursday_driver_id = this.routeBlockDetail_thursday.driver;
            this.thursday_vehicle_id = this.routeBlockDetail_thursday.vehicle;
            delete this.routeBlockDetail_thursday.driver;
            delete this.routeBlockDetail_thursday.vehicle;
            delete this.routeBlockDetail_sunday.time_type;
          } else {
            this.routeBlockDetail_thursday = new RouteBlockDetail;
          }

          if (this.route_blocks.route_block_details[5] != null) {
            this.routeBlockDetail_friday = this.route_blocks.route_block_details[5];
            this.friday_driver_id = this.routeBlockDetail_friday.driver;
            this.friday_vehicle_id = this.routeBlockDetail_friday.vehicle;
            delete this.routeBlockDetail_friday.driver;
            delete this.routeBlockDetail_friday.vehicle;
            delete this.routeBlockDetail_sunday.time_type;
          } else {
            this.routeBlockDetail_friday = new RouteBlockDetail;
          }

          if (this.route_blocks.route_block_details[6] != null) {
            this.routeBlockDetail_saturday = this.route_blocks.route_block_details[6];
            this.saturday_driver_id = this.routeBlockDetail_saturday.driver;
            this.saturday_vehicle_id = this.routeBlockDetail_saturday.vehicle;
            delete this.routeBlockDetail_saturday.driver;
            delete this.routeBlockDetail_saturday.vehicle;
            delete this.routeBlockDetail_sunday.time_type;
          } else {
            this.routeBlockDetail_saturday = new RouteBlockDetail;
          }

          this.setValueDriver(this.routeBlockDetail_monday.driver_id, 'sunday');
          this.setValueDriver(this.routeBlockDetail_monday.driver_id, 'monday');
          this.setValueDriver(this.routeBlockDetail_monday.driver_id, 'tuesday');
          this.setValueDriver(this.routeBlockDetail_monday.driver_id, 'wednesday');
          this.setValueDriver(this.routeBlockDetail_monday.driver_id, 'thursday');
          this.setValueDriver(this.routeBlockDetail_monday.driver_id, 'friday');
          this.setValueDriver(this.routeBlockDetail_monday.driver_id, 'saturday');

          this.setValueVehicle(this.routeBlockDetail_monday.vehicle_id, 'saturday');
          this.setValueVehicle(this.routeBlockDetail_monday.vehicle_id, 'monday');
          this.setValueVehicle(this.routeBlockDetail_monday.vehicle_id, 'tuesday');
          this.setValueVehicle(this.routeBlockDetail_monday.vehicle_id, 'wednesday');
          this.setValueVehicle(this.routeBlockDetail_monday.vehicle_id, 'thursday');
          this.setValueVehicle(this.routeBlockDetail_monday.vehicle_id, 'friday');
          this.setValueVehicle(this.routeBlockDetail_monday.vehicle_id, 'saturday');

        } else {
          this.setTimeTypeDefault(this.defaultValueTimeType);
          this.msgBlokeDetaild = true;
        }
      }

    } else {
      this.routeBlockHead.route_id = this.route_id;
      this.routeBlockHead.active = true;
    }
  }

  ngAfterViewInit() {
    /**
     *@description Setea el en timeType la ocpion off que es la del id 5
     */
    setTimeout(() => {
      if (this.routeSekected == 0) {
        this.setTimeTypeDefault(this.defaultValueTimeType);
      } else {
        if (this.route_edit_clear) {
          this.saveObj.id = this.id;
          this.saveObj.save = false;
          this.saveObj.edit = false;
          // this.statusAllInput(this.id,false);
          this.setTimeTypeDefault(this.defaultValueTimeType);
        } else {
          if (this.edit) {
            this.routeBlockHead = new RouteBlockHead;
            this.routeBlockHead.route_id = this.route_id;
            this.saveObj.id = this.id;
            this.saveObj.save = false;
            this.saveObj.edit = false;
            this.statusAllInput(this.id, true);
            this.setTimeTypeDefault(this.defaultValueTimeType);
            this.routeBlockHead.active = true; // Activamos por defecto el check del block nuevo
          } else {
            this.saveObj.id = this.id;
            setTimeout(() => {
              this.saveObj.save = true;
              this.saveObj.edit = false;
            }, 500);
            this.statusAllInput(this.id, false);
          }
        }

      }
    }, 400)

  }

  verifircarDriverNull() {
    if (this.routeBlockDetail_sunday.driver_id) {
      if (this.routeBlockDetail_sunday.driver_id.toString() == 'null') {
        this.routeBlockDetail_sunday.driver_id = null;
      }
    }
    if (this.routeBlockDetail_monday.driver_id) {
      if (this.routeBlockDetail_monday.driver_id.toString() == 'null') {
        this.routeBlockDetail_monday.driver_id = null;
      }
    }

    if (this.routeBlockDetail_tuesday.driver_id) {
      if (this.routeBlockDetail_tuesday.driver_id.toString() == 'null') {
        this.routeBlockDetail_tuesday.driver_id = null;
      }
    }

    if (this.routeBlockDetail_wednesday.driver_id) {
      if (this.routeBlockDetail_wednesday.driver_id.toString() == 'null') {
        this.routeBlockDetail_wednesday.driver_id = null;
      }
    }

    if (this.routeBlockDetail_thursday.driver_id) {
      if (this.routeBlockDetail_thursday.driver_id.toString() == 'null') {
        this.routeBlockDetail_thursday.driver_id = null;
      }
    }

    if (this.routeBlockDetail_friday.driver_id) {
      if (this.routeBlockDetail_friday.driver_id.toString() == 'null') {
        this.routeBlockDetail_friday.driver_id = null;
      }
    }

    if (this.routeBlockDetail_saturday.driver_id) {
      if (this.routeBlockDetail_saturday.driver_id.toString() == 'null') {
        this.routeBlockDetail_saturday.driver_id = null;
      }
    }

  }

  valueChangedDriverSunday(newVal: Driver) {
    this.routeBlockDetail_sunday.driver_id = newVal.id;
  }

  valueChangedDriverMonday(newVal: Driver) {
    this.routeBlockDetail_monday.driver_id = newVal.id;
  }

  valueChangedDriverTuesday(newVal: Driver) {
    this.routeBlockDetail_tuesday.driver_id = newVal.id;
  }

  valueChangedDriverWednesday(newVal: Driver) {
    this.routeBlockDetail_wednesday.driver_id = newVal.id;
  }

  valueChangedDriverThursday(newVal: Driver) {
    this.routeBlockDetail_thursday.driver_id = newVal.id;
  }

  valueChangedDriverFriday(newVal: Driver) {
    this.routeBlockDetail_friday.driver_id = newVal.id;
  }

  valueChangedDriverSaturday(newVal: Driver) {
    this.routeBlockDetail_saturday.driver_id = newVal.id;
  }

  valueChangedVehicleSunday(newVal: any) {
    this.routeBlockDetail_sunday.vehicle_id = newVal ? newVal.id ? newVal.id : null : null;
  }

  valueChangedVehicleMonday(newVal: any) {
    this.routeBlockDetail_monday.vehicle_id = newVal ? newVal.id ? newVal.id : null : null;
  }

  valueChangedVehicleTuesday(newVal: any) {
    this.routeBlockDetail_tuesday.vehicle_id = newVal ? newVal.id ? newVal.id : null : null;
  }

  valueChangedVehicleWednesday(newVal: any) {
    this.routeBlockDetail_wednesday.vehicle_id = newVal ? newVal.id ? newVal.id : null : null;
  }

  valueChangedVehicleThursday(newVal: any) {
    this.routeBlockDetail_thursday.vehicle_id = newVal ? newVal.id ? newVal.id : null : null;
  }

  valueChangedVehicleFriday(newVal: any) {
    this.routeBlockDetail_friday.vehicle_id = newVal ? newVal.id ? newVal.id : null : null;
  }

  valueChangedVehicleSaturday(newVal: any) {
    this.routeBlockDetail_saturday.vehicle_id = newVal ? newVal.id ? newVal.id : null : null;
  }

  /**
   * @description Esta funcion se encarga de almacenar el bloque actual en el DOM
   */
  saveBlock() {
    if (this.verificarTimer(this.id)) {

      delete this.routeBlockHead.id;

      this.verifircarDriverNull();

      if (this.routeBlockHead.route_block_details && this.routeBlockHead.route_block_details.length > 0) {
        this.routeBlockHead.route_block_details = [];
      }

      if (this.routeBlockDetail_sunday.driver_id == 0) {
        this.routeBlockDetail_sunday.driver_id = null;
      }
      if (this.routeBlockDetail_monday.driver_id == 0) {
        this.routeBlockDetail_monday.driver_id = null;
      }
      if (this.routeBlockDetail_tuesday.driver_id == 0) {
        this.routeBlockDetail_tuesday.driver_id = null;
      }
      if (this.routeBlockDetail_wednesday.driver_id == 0) {
        this.routeBlockDetail_wednesday.driver_id = null;
      }
      if (this.routeBlockDetail_thursday.driver_id == 0) {
        this.routeBlockDetail_thursday.driver_id = null;
      }
      if (this.routeBlockDetail_friday.driver_id == 0) {
        this.routeBlockDetail_friday.driver_id = null;
      }
      if (this.routeBlockDetail_saturday.driver_id == 0) {
        this.routeBlockDetail_saturday.driver_id = null;
      }
      if (this.routeBlockDetail_sunday.vehicle_id == 0) {
        this.routeBlockDetail_sunday.vehicle_id = null;
      }
      if (this.routeBlockDetail_monday.vehicle_id == 0) {
        this.routeBlockDetail_monday.vehicle_id = null;
      }
      if (this.routeBlockDetail_tuesday.vehicle_id == 0) {
        this.routeBlockDetail_tuesday.vehicle_id = null;
      }
      if (this.routeBlockDetail_wednesday.vehicle_id == 0) {
        this.routeBlockDetail_wednesday.vehicle_id = null;
      }
      if (this.routeBlockDetail_thursday.vehicle_id == 0) {
        this.routeBlockDetail_thursday.vehicle_id = null;
      }
      if (this.routeBlockDetail_friday.vehicle_id == 0) {
        this.routeBlockDetail_friday.vehicle_id = null;
      }
      if (this.routeBlockDetail_saturday.vehicle_id == 0) {
        this.routeBlockDetail_saturday.vehicle_id = null;
      }

      delete this.routeBlockDetail_sunday.vehicle;
      delete this.routeBlockDetail_monday.vehicle;
      delete this.routeBlockDetail_tuesday.vehicle;
      delete this.routeBlockDetail_wednesday.vehicle;
      delete this.routeBlockDetail_thursday.vehicle;
      delete this.routeBlockDetail_friday.vehicle;
      delete this.routeBlockDetail_saturday.vehicle;



      delete this.routeBlockDetail_sunday.time_type;
      delete this.routeBlockDetail_monday.time_type;
      delete this.routeBlockDetail_tuesday.time_type;
      delete this.routeBlockDetail_wednesday.time_type;
      delete this.routeBlockDetail_thursday.time_type;
      delete this.routeBlockDetail_friday.time_type;
      delete this.routeBlockDetail_saturday.time_type;

      if (!this.routeBlockHead.route_block_details) {
        this.routeBlockHead.route_block_details = [];
      }
      this.routeBlockHead.route_block_details.push(this.routeBlockDetail_sunday);
      this.routeBlockHead.route_block_details.push(this.routeBlockDetail_monday);
      this.routeBlockHead.route_block_details.push(this.routeBlockDetail_tuesday);
      this.routeBlockHead.route_block_details.push(this.routeBlockDetail_wednesday);
      this.routeBlockHead.route_block_details.push(this.routeBlockDetail_thursday);
      this.routeBlockHead.route_block_details.push(this.routeBlockDetail_friday);
      this.routeBlockHead.route_block_details.push(this.routeBlockDetail_saturday);

      this.busy = this._dataService.postData(this.routeBlockHead, 'routeBlocks').subscribe(
        (data: any) => {
          this.apiResponse = data;
          if (this.apiResponse.transaction) {
            this.notify.emit(this.apiResponse.message);

            this.routeBlockHead = new RouteBlockHead();
            this.routeBlockHead.route_id = data.master.result.route_id;
            this.routeBlockHead.description = data.master.result.description;
            this.routeBlockHead.startLocation = data.master.result.startLocation;
            this.routeBlockHead.block_code = data.master.result.block_code;
            this.routeBlockHead.id = data.master.result.id;
            this.routeBlockHead.active = data.master.result.active;
            const first_id = this.routeBlockHead.first_stop_id;
            this.routeBlockHead.first_stop_id = first_id ? first_id : undefined;
            const run_id = this.routeBlockHead.run_id;
            this.routeBlockHead.run_id = run_id ? run_id : undefined;

            this.statusAllInput(this.id, false);

            this.msgBlokeDetaild = false;

            this.saveObj.id = this.id;
            this.saveObj.save = true;
            this.saveObj.edit = false;

          } else {
            let logInfo = '';
            if (this.apiResponse.master && this.apiResponse.master.result) {
              logInfo = `<br> Log : ${JSON.stringify(this.apiResponse.master.result)}`;
            }
            this.toastr.error(this.apiResponse.message + logInfo, 'Oops!', {
              closeButton: true
            });
          }
        }
      );
    } else {
      this.toastr.error('Please check times');
    }
  }

  /**
   * @description Este metodo lo invoca el boton que agrega un nuevo block al view
   */
  addBlock() {
    this.addComponent.emit(true);
  }

  /**
   * @description setea las propiedades edit y save del objeto saveObj en su valor correspondientes para editar y cambiar los
   * estados de los botonos que se muestran, puede ser guardar o actualizar, adicionamente invoca un metodo para swichar el edit
   * sobre el elemto actual del Dom.
   * @param id
   */
  editBlock(id: number) {
    this.saveObj.edit = true;
    this.saveObj.save = false;
    this.statusAllInput(id, true);
    this.EditComponent.emit(true);
    this.routeBlockHeadTemp = JSON.stringify(this.routeBlockHead);
  }

  cancelBlock(id: number) {
    let temObjH = JSON.parse(this.routeBlockHeadTemp);
    if (temObjH.route_block_details) {
      let temObjD = temObjH.route_block_details;
      delete temObjH.route_block_details;
      this.routeBlockDetail_sunday = temObjD[0];
      this.routeBlockDetail_monday = temObjD[1];
      this.routeBlockDetail_tuesday = temObjD[2];
      this.routeBlockDetail_wednesday = temObjD[3];
      this.routeBlockDetail_thursday = temObjD[4];
      this.routeBlockDetail_friday = temObjD[5];
      this.routeBlockDetail_saturday = temObjD[6];
    }
    this.routeBlockHead = temObjH;
    this.routeBlockHead['route_block_details'] = [];
    this.routeBlockHead.route_block_details.push(this.routeBlockDetail_sunday);
    this.routeBlockHead.route_block_details.push(this.routeBlockDetail_monday);
    this.routeBlockHead.route_block_details.push(this.routeBlockDetail_tuesday);
    this.routeBlockHead.route_block_details.push(this.routeBlockDetail_wednesday);
    this.routeBlockHead.route_block_details.push(this.routeBlockDetail_thursday);
    this.routeBlockHead.route_block_details.push(this.routeBlockDetail_friday);
    this.routeBlockHead.route_block_details.push(this.routeBlockDetail_saturday);

    this.saveObj.edit = false;
    this.saveObj.save = true;
    this.statusAllInput(id, false);
    this.EditComponent.emit(false);

    this.routeBlockHeadTemp = [];
  }

  /**
   * @description esta funcion se encarga de gestiona la actualizacion de un BlockRoute
   */
  updateBlock() {
    if (this.verificarTimer(this.id)) {
      let idUpdate = this.routeBlockHead.id;
      delete this.routeBlockHead.id;

      this.verifircarDriverNull();

      if (this.routeBlockHead.route_block_details.length > 0) {
        this.routeBlockHead.route_block_details = [];
      }

      if (this.routeBlockDetail_sunday.driver_id == 0) {
        this.routeBlockDetail_sunday.driver_id = null;
      }
      if (this.routeBlockDetail_monday.driver_id == 0) {
        this.routeBlockDetail_monday.driver_id = null;
      }
      if (this.routeBlockDetail_tuesday.driver_id == 0) {
        this.routeBlockDetail_tuesday.driver_id = null;
      }
      if (this.routeBlockDetail_wednesday.driver_id == 0) {
        this.routeBlockDetail_wednesday.driver_id = null;
      }
      if (this.routeBlockDetail_thursday.driver_id == 0) {
        this.routeBlockDetail_thursday.driver_id = null;
      }
      if (this.routeBlockDetail_friday.driver_id == 0) {
        this.routeBlockDetail_friday.driver_id = null;
      }
      if (this.routeBlockDetail_saturday.driver_id == 0) {
        this.routeBlockDetail_saturday.driver_id = null;
      }
      if (this.routeBlockDetail_sunday.vehicle_id == 0) {
        this.routeBlockDetail_sunday.vehicle_id = null;
      }
      if (this.routeBlockDetail_monday.vehicle_id == 0) {
        this.routeBlockDetail_monday.vehicle_id = null;
      }
      if (this.routeBlockDetail_tuesday.vehicle_id == 0) {
        this.routeBlockDetail_tuesday.vehicle_id = null;
      }
      if (this.routeBlockDetail_wednesday.vehicle_id == 0) {
        this.routeBlockDetail_wednesday.vehicle_id = null;
      }
      if (this.routeBlockDetail_thursday.vehicle_id == 0) {
        this.routeBlockDetail_thursday.vehicle_id = null;
      }
      if (this.routeBlockDetail_friday.vehicle_id == 0) {
        this.routeBlockDetail_friday.vehicle_id = null;
      }
      if (this.routeBlockDetail_saturday.vehicle_id == 0) {
        this.routeBlockDetail_saturday.vehicle_id = null;
      }

      delete this.routeBlockDetail_sunday.vehicle;
      delete this.routeBlockDetail_monday.vehicle;
      delete this.routeBlockDetail_tuesday.vehicle;
      delete this.routeBlockDetail_wednesday.vehicle;
      delete this.routeBlockDetail_thursday.vehicle;
      delete this.routeBlockDetail_friday.vehicle;
      delete this.routeBlockDetail_saturday.vehicle;

      delete this.routeBlockDetail_sunday.time_type;
      delete this.routeBlockDetail_monday.time_type;
      delete this.routeBlockDetail_tuesday.time_type;
      delete this.routeBlockDetail_wednesday.time_type;
      delete this.routeBlockDetail_thursday.time_type;
      delete this.routeBlockDetail_friday.time_type;
      delete this.routeBlockDetail_saturday.time_type;

      this.routeBlockHead.route_block_details.push(this.routeBlockDetail_sunday);
      this.routeBlockHead.route_block_details.push(this.routeBlockDetail_monday);
      this.routeBlockHead.route_block_details.push(this.routeBlockDetail_tuesday);
      this.routeBlockHead.route_block_details.push(this.routeBlockDetail_wednesday);
      this.routeBlockHead.route_block_details.push(this.routeBlockDetail_thursday);
      this.routeBlockHead.route_block_details.push(this.routeBlockDetail_friday);
      this.routeBlockHead.route_block_details.push(this.routeBlockDetail_saturday);

      if (this.routeBlockHead.run_id === 'null') {
        this.routeBlockHead.run_id = undefined;
      }

      if (idUpdate !== undefined) {
        this.busy = this._dataService.putData(this.routeBlockHead, 'routeBlocks/' + idUpdate).subscribe(
          (data: any) => {
            this.apiResponse = data;
            if (this.apiResponse.transaction) {
              this.notify.emit(this.apiResponse.message);

              this.routeBlockHead = new RouteBlockHead();
              this.routeBlockHead.route_id = data.master.result.route_id;
              this.routeBlockHead.description = data.master.result.description;
              this.routeBlockHead.block_code = data.master.result.block_code;
              this.routeBlockHead.startLocation = data.master.result.startLocation;
              this.routeBlockHead.id = data.master.result.id;
              this.routeBlockHead.active = data.master.result.active;
              const first_id = this.routeBlockHead.first_stop_id;
              this.routeBlockHead.first_stop_id = first_id ? first_id : undefined;

              const run_id = data.master.result.run_id;
              this.routeBlockHead.run_id = run_id ? run_id : undefined;
              this.routeBlockHead.desc_billing = data.master.result.desc_billing;
              this.routeBlockHead.description2 = data.master.result.description2;
              this.routeBlockHead.run_code = data.master.result.run_code;

              this.statusAllInput(this.id, false);

              this.saveObj.id = this.id;
              this.saveObj.save = true;
              this.saveObj.edit = false;

              this.EditComponent.emit(false);
            } else {
              this.sweetAlertHelper
                .createCustomAlert({
                  title: 'Oops!',
                  text: this.apiResponse.message,
                  type: 'warning'
                }).then();
            }
          }
        );
      } else {
        this.sweetAlertHelper
          .createCustomAlert({
            title: 'Oops!',
            text: this.apiResponse.message,
            type: 'warning'
          }).then();
      }
    } else {
      this.toastr.error('Please check times');
    }
  }

  /**
   * @description Este metodo se encarga de asignar un valor por defecto a los campos de TypeTipe, por medio de si ID, el de Off es el 5
   * @param {number} id
   */
  setTimeTypeDefault(id: number) {
    this.routeBlockDetail_sunday.time_type_id = id;
    this.routeBlockDetail_monday.time_type_id = id;
    this.routeBlockDetail_tuesday.time_type_id = id;
    this.routeBlockDetail_wednesday.time_type_id = id;
    this.routeBlockDetail_thursday.time_type_id = id;
    this.routeBlockDetail_friday.time_type_id = id;
    this.routeBlockDetail_saturday.time_type_id = id;

    this.onDayDisable(false, 1, this.id);
    this.onDayDisable(false, 2, this.id);
    this.onDayDisable(false, 3, this.id);
    this.onDayDisable(false, 4, this.id);
    this.onDayDisable(false, 5, this.id);
    this.onDayDisable(false, 6, this.id);
    this.onDayDisable(false, 7, this.id);
  }

  /**
   * @description Este metodo es ejecutado por todos los select de tipo time_type, recibe como
   * parametro el componente, y realiza validaciones pertinentes para determinar sobre cual componente
   * se realiza determina accion.
   * @param $event
   */
  onChangeTime($event) {
    if ($event.target.value == 5) { // Off
      this.onDayDisable(false, $('#' + $event.target.id).data('day'), this.id);
    } else if ($event.target.value == 4) {
      this.onDayDisable(true, $('#' + $event.target.id).data('day'), this.id);
    } else {
      this.onDayDisable(true, $('#' + $event.target.id).data('day'), this.id);
    }
  }

  onCliclCopy() {
    // const day_copy = $('#'+$event.target.id).data('day');
    const day_copy = 2;
    this.copy_day_next(day_copy);
  }

  copy_day_next(day: number) {
    if (day == 2) {
      if (this.routeBlockDetail_sunday.start_time != null && this.routeBlockDetail_sunday.report_time && this.routeBlockDetail_sunday.end_time && this.routeBlockDetail_sunday.punch_out_time) {
        this.routeBlockDetail_monday.report_time = this.routeBlockDetail_sunday.report_time;
        this.routeBlockDetail_monday.start_time = this.routeBlockDetail_sunday.start_time;
        this.routeBlockDetail_monday.end_time = this.routeBlockDetail_sunday.end_time;
        this.routeBlockDetail_monday.punch_out_time = this.routeBlockDetail_sunday.punch_out_time;
        this.routeBlockDetail_monday.leave_yard_time = this.routeBlockDetail_sunday.leave_yard_time;
      }
    } else if (day == 3) {
      if (this.routeBlockDetail_monday.start_time != null && this.routeBlockDetail_monday.report_time && this.routeBlockDetail_monday.end_time && this.routeBlockDetail_monday.punch_out_time) {
        this.routeBlockDetail_tuesday.report_time = this.routeBlockDetail_monday.report_time;
        this.routeBlockDetail_tuesday.start_time = this.routeBlockDetail_monday.start_time;
        this.routeBlockDetail_tuesday.end_time = this.routeBlockDetail_monday.end_time;
        this.routeBlockDetail_tuesday.punch_out_time = this.routeBlockDetail_monday.punch_out_time;
        this.routeBlockDetail_tuesday.leave_yard_time = this.routeBlockDetail_monday.leave_yard_time;
      }
    } else if (day == 4) {
      if (this.routeBlockDetail_tuesday.start_time != null && this.routeBlockDetail_tuesday.report_time && this.routeBlockDetail_tuesday.end_time && this.routeBlockDetail_tuesday.punch_out_time) {
        this.routeBlockDetail_wednesday.report_time = this.routeBlockDetail_tuesday.report_time;
        this.routeBlockDetail_wednesday.start_time = this.routeBlockDetail_tuesday.start_time;
        this.routeBlockDetail_wednesday.end_time = this.routeBlockDetail_tuesday.end_time;
        this.routeBlockDetail_wednesday.punch_out_time = this.routeBlockDetail_tuesday.punch_out_time;
        this.routeBlockDetail_wednesday.leave_yard_time = this.routeBlockDetail_tuesday.leave_yard_time;
      }
    } else if (day == 5) {
      if (this.routeBlockDetail_wednesday.start_time != null && this.routeBlockDetail_wednesday.report_time && this.routeBlockDetail_wednesday.end_time && this.routeBlockDetail_wednesday.punch_out_time) {
        this.routeBlockDetail_thursday.report_time = this.routeBlockDetail_wednesday.report_time;
        this.routeBlockDetail_thursday.start_time = this.routeBlockDetail_wednesday.start_time;
        this.routeBlockDetail_thursday.end_time = this.routeBlockDetail_wednesday.end_time;
        this.routeBlockDetail_thursday.punch_out_time = this.routeBlockDetail_wednesday.punch_out_time;
        this.routeBlockDetail_thursday.leave_yard_time = this.routeBlockDetail_wednesday.leave_yard_time;
      }
    } else if (day == 6) {
      if (this.routeBlockDetail_thursday.start_time != null && this.routeBlockDetail_thursday.report_time && this.routeBlockDetail_thursday.end_time && this.routeBlockDetail_thursday.punch_out_time) {
        this.routeBlockDetail_friday.report_time = this.routeBlockDetail_thursday.report_time;
        this.routeBlockDetail_friday.start_time = this.routeBlockDetail_thursday.start_time;
        this.routeBlockDetail_friday.end_time = this.routeBlockDetail_thursday.end_time;
        this.routeBlockDetail_friday.punch_out_time = this.routeBlockDetail_thursday.punch_out_time;
        this.routeBlockDetail_friday.leave_yard_time = this.routeBlockDetail_thursday.leave_yard_time;
      }
    } else if (day == 7) {
      if (this.routeBlockDetail_friday.start_time != null && this.routeBlockDetail_friday.report_time && this.routeBlockDetail_friday.end_time && this.routeBlockDetail_friday.punch_out_time) {
        this.routeBlockDetail_saturday.report_time = this.routeBlockDetail_friday.report_time;
        this.routeBlockDetail_saturday.start_time = this.routeBlockDetail_friday.start_time;
        this.routeBlockDetail_saturday.end_time = this.routeBlockDetail_friday.end_time;
        this.routeBlockDetail_saturday.punch_out_time = this.routeBlockDetail_friday.punch_out_time;
        this.routeBlockDetail_saturday.leave_yard_time = this.routeBlockDetail_friday.leave_yard_time;
      }
    }

  }


  /**
   * @description setea en null dayname que se le pase como parametro
   * @param value
   * @param dayname
   */
  setValueDriver(value: any, dayname: string) {
    if (value == null || value == -1) {
      if (dayname == 'sunday') {
        this.routeBlockDetail_sunday.driver_id = null;
      } else if (dayname == 'monday') {
        this.routeBlockDetail_monday.driver_id = null;
      } else if (dayname == 'tuesday') {
        this.routeBlockDetail_tuesday.driver_id = null;
      } else if (dayname == 'wednesday') {
        this.routeBlockDetail_wednesday.driver_id = null;
      } else if (dayname == 'thursday') {
        this.routeBlockDetail_thursday.driver_id = null;
      } else if (dayname == 'friday') {
        this.routeBlockDetail_friday.driver_id = null;
      } else if (dayname == 'saturday') {
        this.routeBlockDetail_saturday.driver_id = null;
      }
    }
  }

  setValueVehicle(value: any, dayname: string) {
    if (value == null || value == -1) {
      if (dayname == 'sunday') {
        this.routeBlockDetail_sunday.vehicle_id = null;
      } else if (dayname == 'monday') {
        this.routeBlockDetail_monday.vehicle_id = null;
      } else if (dayname == 'tuesday') {
        this.routeBlockDetail_tuesday.vehicle_id = null;
      } else if (dayname == 'wednesday') {
        this.routeBlockDetail_wednesday.vehicle_id = null;
      } else if (dayname == 'thursday') {
        this.routeBlockDetail_thursday.vehicle_id = null;
      } else if (dayname == 'friday') {
        this.routeBlockDetail_friday.vehicle_id = null;
      } else if (dayname == 'saturday') {
        this.routeBlockDetail_saturday.vehicle_id = null;
      }
    }
  }

  /**
   * @description este metodo recibe un estado, que corresponde al estado de disable para los distintos campo
   * que determinamos a cuales corresponden pormedio del numero del dia, adicionalmente limpiamos los campos
   * @param estado
   * @param {number} day
   */
  onDayDisable(estado: boolean, day: number, id: any) {
    if (day === 1) {
      if (estado == false) {
        this.placeholder_driver = 'Open';
        this.routeBlockDetail_sunday.driver_id = null;
        this.routeBlockDetail_sunday.vehicle_id = null;
        this.routeBlockDetail_sunday.report_time = null;
        this.routeBlockDetail_sunday.start_time = null;
        this.routeBlockDetail_sunday.end_time = null;
        this.routeBlockDetail_sunday.punch_out_time = null;
        this.routeBlockDetail_sunday.leave_yard_time = null;
        this.setEstateComponenteView('sunday', id, estado, true);
      } else {
        this.setEstateComponenteView('sunday', id, estado, false);
      }
    } else if (day === 2) {
      if (estado == false) {
        this.placeholder_driver = 'Open';
        this.routeBlockDetail_monday.driver_id = null;
        this.routeBlockDetail_monday.vehicle_id = null;
        this.routeBlockDetail_monday.report_time = null;
        this.routeBlockDetail_monday.start_time = null;
        this.routeBlockDetail_monday.end_time = null;
        this.routeBlockDetail_monday.punch_out_time = null;
        this.routeBlockDetail_monday.leave_yard_time = null;
        this.copy_day_next(day);
        this.setEstateComponenteView('monday', id, estado, true);
      } else {
        this.copy_day_next(day);
        this.setEstateComponenteView('monday', id, estado, false);
      }
    } else if (day === 3) {
      if (estado == false) {
        this.placeholder_driver = 'Open';
        this.routeBlockDetail_tuesday.driver_id = null;
        this.routeBlockDetail_tuesday.vehicle_id = null;
        this.routeBlockDetail_tuesday.report_time = null;
        this.routeBlockDetail_tuesday.start_time = null;
        this.routeBlockDetail_tuesday.end_time = null;
        this.routeBlockDetail_tuesday.punch_out_time = null;
        this.routeBlockDetail_tuesday.leave_yard_time = null;
        this.copy_day_next(day);
        this.setEstateComponenteView('tuesday', id, estado, true);
      } else {
        this.copy_day_next(day);
        this.setEstateComponenteView('tuesday', id, estado, false);
      }
    } else if (day === 4) {
      if (estado == false) {
        this.placeholder_driver = 'Open';
        this.routeBlockDetail_wednesday.driver_id = null;
        this.routeBlockDetail_wednesday.vehicle_id = null;
        this.routeBlockDetail_wednesday.report_time = null;
        this.routeBlockDetail_wednesday.start_time = null;
        this.routeBlockDetail_wednesday.end_time = null;
        this.routeBlockDetail_wednesday.punch_out_time = null;
        this.routeBlockDetail_wednesday.leave_yard_time = null;
        this.copy_day_next(day);
        this.setEstateComponenteView('wednesday', id, estado, true);
      } else {
        this.copy_day_next(day);
        this.setEstateComponenteView('wednesday', id, estado, false);
      }
    } else if (day === 5) {
      if (estado == false) {
        this.placeholder_driver = 'Open';
        this.routeBlockDetail_thursday.driver_id = null;
        this.routeBlockDetail_thursday.vehicle_id = null;
        this.routeBlockDetail_thursday.report_time = null;
        this.routeBlockDetail_thursday.start_time = null;
        this.routeBlockDetail_thursday.end_time = null;
        this.routeBlockDetail_thursday.punch_out_time = null;
        this.routeBlockDetail_thursday.leave_yard_time = null;
        this.copy_day_next(day);
        this.setEstateComponenteView('thursday', id, estado, true);
      } else {
        this.copy_day_next(day);
        this.setEstateComponenteView('thursday', id, estado, false);
      }
    } else if (day === 6) {
      if (estado == false) {
        this.placeholder_driver = 'Open';
        this.routeBlockDetail_friday.driver_id = null;
        this.routeBlockDetail_friday.vehicle_id = null;
        this.routeBlockDetail_friday.report_time = null;
        this.routeBlockDetail_friday.start_time = null;
        this.routeBlockDetail_friday.end_time = null;
        this.routeBlockDetail_friday.punch_out_time = null;
        this.routeBlockDetail_friday.leave_yard_time = null;
        this.copy_day_next(day);
        this.setEstateComponenteView('friday', id, estado, true);
      } else {
        this.copy_day_next(day);
        this.setEstateComponenteView('friday', id, estado, false);
      }
    } else if (day === 7) {
      if (estado == false) {
        this.placeholder_driver = 'Open';
        this.routeBlockDetail_saturday.driver_id = null;
        this.routeBlockDetail_saturday.vehicle_id = null;
        this.routeBlockDetail_saturday.report_time = null;
        this.routeBlockDetail_saturday.start_time = null;
        this.routeBlockDetail_saturday.end_time = null;
        this.routeBlockDetail_saturday.punch_out_time = null;
        this.routeBlockDetail_saturday.leave_yard_time = null;
        this.copy_day_next(day);
        this.setEstateComponenteView('saturday', id, estado, true);
      } else {
        this.copy_day_next(day);
        this.setEstateComponenteView('saturday', id, estado, false);
      }
    }
  }

  /**
   * @description Este metodo se encarga de retorna un array con los timeType que se encuentran en un estado
   * distinto a  TimeType.
   */
  verificarTimer(id: any) {
    const sunday = $('#routeBlockDetail_sunday_' + id).val();
    const monday = $('#routeBlockDetail_monday_' + id).val();
    const tuesday = $('#routeBlockDetail_tuesday_' + id).val();
    const wednesday = $('#routeBlockDetail_wednesday_' + id).val();
    const thursday = $('#routeBlockDetail_thursday_' + id).val();
    const friday = $('#routeBlockDetail_friday_' + id).val();
    const saturday = $('#routeBlockDetail_saturday_' + id).val();


    let sundayBolean = true;
    let mondayBolean = true;
    let tuesdayBolean = true;
    let wednesdayBolean = true;
    let thursdayBolean = true;
    let fridayBolean = true;
    let saturdayBolean = true;

    if (!(sunday == this.defaultValueTimeType)) {
      sundayBolean = this.validarTimerBlock(id, 'sunday');
    }
    if (!(monday == this.defaultValueTimeType)) {
      mondayBolean = this.validarTimerBlock(id, 'monday');
    }
    if (!(tuesday == this.defaultValueTimeType)) {
      tuesdayBolean = this.validarTimerBlock(id, 'tuesday');
    }
    if (!(wednesday == this.defaultValueTimeType)) {
      wednesdayBolean = this.validarTimerBlock(id, 'wednesday');
    }
    if (!(thursday == this.defaultValueTimeType)) {
      thursdayBolean = this.validarTimerBlock(id, 'thursday');
    }
    if (!(friday == this.defaultValueTimeType)) {
      fridayBolean = this.validarTimerBlock(id, 'friday');
    }
    if (!(saturday == this.defaultValueTimeType)) {
      saturdayBolean = this.validarTimerBlock(id, 'saturday');
    }

    if (sundayBolean && mondayBolean && tuesdayBolean && wednesdayBolean && thursdayBolean && fridayBolean && saturdayBolean) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * @description Este metodo recibe como parametro el id que corresponde al bloque que se este trabajando, adicionalmente
   * el nombre del dia para acceder al elemento en el DOM por medio del ID y del nombre del Dia.
   * @param {number} id
   * @param {string} dayname
   */
  validarTimerBlock(id: number, dayname: string) {
    let driver = $('#routeBlockDetail_' + dayname + '_driver_' + id).val();
    let num1Bollean: boolean = false;
    let num2Bollean: boolean = false;
    let num3Bollean: boolean = false;
    let num4Bollean: boolean = false;
    let num5Bollean: boolean = false;

    let pattern = new RegExp('(0[0-9]|1[0-9]|2[0-9]|3[0-6])(:[0-5][0-9]){1}');

    if (driver != null) {
      let num1 = $('#' + dayname + '_clock_in_' + id).val();
      let num2 = $('#' + dayname + '_start_time_' + id).val();
      let num3 = $('#' + dayname + '_end_time_' + id).val();
      let num4 = $('#' + dayname + '_clock_out_' + id).val();
      let num5 = $('#' + dayname + '_leave_yard_time_' + id).val();

      let valNum1 = pattern.test(num1);
      let valNum2 = pattern.test(num2);
      let valNum3 = pattern.test(num3);
      let valNum4 = pattern.test(num4);
      let valNum5 = pattern.test(num5);

      if (valNum1 && valNum2 && valNum3 && valNum4 && valNum5) {

        if (num5 > num1 && num5 < num2) {
          $('#' + dayname + '_leave_yard_time_' + id).css('color', 'black');
          num5Bollean = false;
        }else {
          $('#' + dayname + '_leave_yard_time_' + id).css('color', 'red');
          num5Bollean = true;
        }

        if (!(num2 > num1)) {
          $('#' + dayname + '_start_time_' + id).css('color', 'red');
          num2Bollean = true;
        } else {
          $('#' + dayname + '_start_time_' + id).css('color', 'black');
          num2Bollean = false;
        }

        if (num3 > num1) {
          if (num3 <= num2) {
            $('#' + dayname + '_end_time_' + id).css('color', 'red');
            num3Bollean = true;
          } else {
            $('#' + dayname + '_end_time_' + id).css('color', 'black');
            num3Bollean = false;
          }
        } else {
          $('#' + dayname + '_end_time_' + id).css('color', 'red');
          num3Bollean = true;
        }

        if (num3 > num1) {
          if (num4 <= num3) {
            $('#' + dayname + '_clock_out_' + id).css('color', 'red');
            num4Bollean = true;
          } else {
            $('#' + dayname + '_clock_out_' + id).css('color', 'black');
            num4Bollean = false;
          }
        } else {
          $('#' + dayname + '_clock_out_' + id).css('color', 'red');
          num4Bollean = true;
        }

        $('#routeBlockDetail_' + dayname + '_driver_' + id).css('color', 'black');
        num1Bollean = false;
      } else {
        alert('Please check the times, the format entered, does not match the expected one!');
        return false;
      }
    } else {
      $('#routeBlockDetail_' + dayname + '_driver_' + id).css('color', 'red');
      num1Bollean = true;
    }

    if (num1Bollean || num2Bollean || num3Bollean || num4Bollean || num5Bollean) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * @description Activa o desactiva un elemento de la UI de Time, recibe el componente, el id de identificacion
   * y el estado a asignar el componente.
   * @param componente
   * @param {number} id
   * @param {boolean} estado
   */
  changeEstateOne(componente: any, id: number, estado: boolean) {
    if (!estado) {
      $(componente + '' + id).attr('disabled', 'true');
    } else {
      $(componente + '' + id).removeAttr('disabled');
    }
  }

  /**
   * @description Esta funcion recibe el id de un componente del DOM junto a un numero asignado al momento de crear el blok
   * para poder acceder al ese elemento en el DOM
   * @param componente
   * @param id
   */
  clearInputOne(componente: any, id: number) {
    $(componente + '' + id).val('');
  }

  /**
   * @description Esta funcion se encarga de asignar un estado para cada uno de los dias de la semana, el nombre del
   * dia se recibe como parametro
   * @param {string} day_name
   * @param {number} id
   * @param {boolean} estado
   */
  setEstateComponenteView(day_name: string, id: number, estado: boolean, ext_time: boolean) {

    if (!ext_time) {
      this.changeEstateOne('#routeBlockDetail_' + day_name + '_', id, estado);
      if ($('#routeBlockDetail_' + day_name + '_' + id).val() != 5) {
        this.changeEstateOne('#routeBlockDetail_' + day_name + '_driver_', id, estado);
        this.changeEstateOne('#routeBlockDetail_' + day_name + '_vehicle_', id, estado);
        this.changeEstateOne('#' + day_name + '_clock_in_', id, estado);
        this.changeEstateOne('#' + day_name + '_start_time_', id, estado);
        this.changeEstateOne('#' + day_name + '_end_time_', id, estado);
        this.changeEstateOne('#' + day_name + '_clock_out_', id, estado);
        this.changeEstateOne('#' + day_name + '_leave_yard_time_', id, estado);
      }
    } else {
      $('#routeBlockDetail_' + day_name + '_driver_' + id).val(null);
      this.changeEstateOne('#routeBlockDetail_' + day_name + '_driver_', id, estado);
      this.changeEstateOne('#routeBlockDetail_' + day_name + '_vehicle_', id, estado);
      this.changeEstateOne('#' + day_name + '_clock_in_', id, estado);
      this.changeEstateOne('#' + day_name + '_start_time_', id, estado);
      this.changeEstateOne('#' + day_name + '_end_time_', id, estado);
      this.changeEstateOne('#' + day_name + '_clock_out_', id, estado);
      this.changeEstateOne('#' + day_name + '_leave_yard_time_', id, estado);


      this.clearInputOne('#' + day_name + '_clock_in_', id);
      this.clearInputOne('#' + day_name + '_start_time_', id);
      this.clearInputOne('#' + day_name + '_end_time_', id);
      this.clearInputOne('#' + day_name + '_clock_out_', id);
      this.clearInputOne('#' + day_name + '_leave_yard_time_', id);
    }
  }

  /**
   * @description Esta funcion invoca los metodos recesarios para cambiar el estado de los input del view
   * @param {number} id
   * @param {boolean} estado
   */
  statusAllInput(id: number, estado: boolean) {

    this.changeEstateOne('#block_code_', id, estado);
    this.changeEstateOne('#description_', id, estado);
    this.changeEstateOne('#startLocation_', id, estado);
    this.changeEstateOne('#first_stop_id_', id, estado);
    this.changeEstateOne('#run_id', id, estado);
    this.changeEstateOne('#btn_run_add', id, estado);

    this.setEstateComponenteView('sunday', id, estado, false);
    this.setEstateComponenteView('monday', id, estado, false);
    this.setEstateComponenteView('tuesday', id, estado, false);
    this.setEstateComponenteView('wednesday', id, estado, false);
    this.setEstateComponenteView('thursday', id, estado, false);
    this.setEstateComponenteView('friday', id, estado, false);
    this.setEstateComponenteView('saturday', id, estado, false);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const x = event.keyCode;
    if (x === 40) {
      $('.move:focus').next().focus();
    }
    if (x === 38) {
      $('.move:focus').prev().focus();
    }
  }

  addRun() {
    this.modal.open();
    /*Swal.fire({
      title: 'Enter name run',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Save',
      showLoaderOnConfirm: true,
      preConfirm: (run) => {
        const obj = {
          'description': run,
          'route_id': this.route_id
        };
        this.busy = this.runsService.storeData(obj).subscribe((resp: any) => {
          if (resp.status === 'success') {
            Swal.fire({
              position: 'top-end',
              type: 'success',
              title: 'Your run has been saved',
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              this.reloadRuns.emit(true);
            });
          }
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
    */
  }
  get disabledInput(): boolean {
    return !this.saveObj.edit && this.saveObj.save;
  }

  onClose() {
    this.modal.close();
  }

}
