import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import { CONSTANT, CONSTANTS } from '../../app.constants';
import { IClient } from '../../CORE/interfaces/client.interface';
import { BaseService } from '../../core/BaseService';
import { IFormBilling } from '../../../app/pages/fleet/clients/components/form-billing/form-billing.infertace';
import { IFeeSchedule, IRuteGroup } from '../../../app/pages/fleet/clients/components/fee-schedule/fee-schedule.interface';
import {IResponse} from '../../CORE/interfaces/response.interface';

@Injectable()
export class ClientService extends BaseService {
    /* BIlling */
  private editRowSubject = new BehaviorSubject<IFormBilling>(null);
  editRow$ = this.editRowSubject.asObservable();
  private reloadBillingGridSubejct = new BehaviorSubject<boolean>(false);
  reloadBillingGrid$ = this.reloadBillingGridSubejct.asObservable();
  private showInactivesSubejct = new BehaviorSubject<boolean>(false);
  showInactives$ = this.showInactivesSubejct.asObservable();

  protected resource: string;
  private addRowFeeScheduleRouteGroupStatus = new BehaviorSubject<boolean>(false);
  addRouteGroupStatus$ = this.addRowFeeScheduleRouteGroupStatus.asObservable();
  private addRowFeeScheduleRouteGroupForm = new BehaviorSubject<any>(null);
  addRouteGroupForm$ = this.addRowFeeScheduleRouteGroupForm.asObservable();

  /* FeeSchedule */
  private editFeeScheduleSubject = new BehaviorSubject<IFeeSchedule>(null);
  editFeeSchedule$ = this.editFeeScheduleSubject.asObservable();
  private reloadFeeScheduleGridSubejct = new BehaviorSubject<boolean>(false);
  reloadFeeScheduleGrid$ = this.reloadFeeScheduleGridSubejct.asObservable();
  private reloadEditFeeScheduleGridSubejct = new BehaviorSubject<boolean>(false);
  reloadEditFeeScheduleGrid$ = this.reloadEditFeeScheduleGridSubejct.asObservable();
  private editRouteGroupSubject = new BehaviorSubject<any>(null);
  editRouteGroup$ = this.editRouteGroupSubject.asObservable();

  constructor(private http: HttpClient) {
    super();
    this.resource = environment.url_api_v + CONSTANTS.CLIENTS;
  }

  /* FeeSchedule */

  editFeeSchedule(data: IFeeSchedule) {
    this.editFeeScheduleSubject.next(data);
  }

  addRouteGroupFeeSchedule(status: boolean) {
    this.addRowFeeScheduleRouteGroupStatus.next(status);
  }

  editRowBillingForm(data: IFormBilling) {
    this.editRowSubject.next(data);
  }

  reloadFeeScheduleGrid(reload: boolean) {
    this.reloadFeeScheduleGridSubejct.next(reload);
  }

  reloadEditFeeScheduleGrid(reload: boolean) {
    this.reloadEditFeeScheduleGridSubejct.next(reload);
  }

  editRouteGroup(data: IRuteGroup) {
    this.editRouteGroupSubject.next(data);
  }

  /* BIlling */

  reloadBillingGrid(reload: boolean) {
    this.reloadBillingGridSubejct.next(reload);
  }

  showInactives(show: boolean) {
    this.showInactivesSubejct.next(show);
  }

  /**
   * @description Return all clients
   * @returns {Observable<any>}
   */
  getClients(): Observable<any> {
    return this.http.get<any>(this.resource).pipe(catchError(this.handleError));
  }

  /**
   * @description Save a client
   * @param data
   * @returns {Observable<Response>}
   */
  storeClient(data: any): Observable<Response> {
    return this.http.post<Response>(this.resource, JSON.stringify(data));
  }

  /**
   * @description Update a Client
   * @param data
   * @returns {Observable<Response>}
   */
  updateClient(data: any, id: number): Observable<Response> {
    return this.http.put<Response>(
      this.resource + '/' + id,
      JSON.stringify(data)
    );
  }

  /**
   * @description Get a client
   * @param {number} id
   * @returns {Observable<any>}
   */
  getClient(id: number): Observable<any> {
    return this.http.get(this.resource + '/' + id);
  }

  /**
   * @description
   * @param {boolean} status
   * @returns {Observable<any>}
   */
  getClientStatus(status: boolean): Observable<any> {
    return status ? this.http.get(this.resource + '/active/1') : this.http.get(this.resource);
  }

  getClientsRealTime() {
    const url = CONSTANT.CLIENT.URL.CLIENTS_REALTIME_ACTIVE;
    return this.http.get(url);
  }

  getClientTenancy(tenancy: number): Observable<any> {
    return this.http.get(`${this.resource}/tenancy/list/${tenancy}`);
  }

  getClientsByDriverManifest() {
    return this.http.get<IResponse<IClient[]>>(`${environment.url_api_v}clients/driverManifest/lists`);
  }

  getUserClient() {
    const url = CONSTANT.USERS_CLIENTS.URL.BASE;
    return this.http.get<IResponse<IClient[]>>(url);
  }

  getClientsBatch() {
    return this.http.get(`${environment.url_api_v}clientsBatch`);
  }

  getMenuReportClient(client_id: number) {
    return this.http.get(`${environment.url_api_v}billing/menuReport/filter/${client_id}`);
  }

  getInfoMenuReportClient(client_id: number) {
    return this.http.get(`${environment.url_api_v}billing/getInfoReport/${client_id}`);
  }

  getForms() {
    return this.http.get<any>(`${environment.url_api_v}billing/getFormsActive`);
  }

  getFormsClient(client_id, status: boolean) {
    return this.http.get<any>(`${environment.url_api_v}billing/formBilling/${client_id}/${status ? 1 : 0}`);
  }

  addFormBilling(data: any) {
    return this.http.post<any>(`${environment.url_api_v}billing/formBillingClient`, JSON.stringify(data));
  }

  editFormBilling(data: any) {
    const id = data.id;
    return this.http.put<any>(`${environment.url_api_v}billing/formBillingClient/${id}`, JSON.stringify(data));
  }

  getIcon() {
    return this.http.get<any>(`${environment.url_api_v}billing/menuReport/getIcon`);
  }

  /**
   * @description
   */
  changeClientStatus(data: any, id: number): Observable<Response> {
    return this.http.put<Response>(
      environment.url_api_v + 'clients/active/' + id,
      JSON.stringify(data)
    );
  }

  postFeeSchedule(data: any) {
    delete data.id;
    return this.http.post<any>(`${environment.url_api_v}feeSchedules/add`, JSON.stringify(data));
  }

  putFeeSchedule(data: any, id: number) {
    return this.http.put<any>(`${environment.url_api_v}feeSchedules/update/${id}`, JSON.stringify(data));
  }

  getFeeScheduleByClient(client_id: number, status: boolean | true) {
    return this.http.get<any>(`${environment.url_api_v}feeSchedules/byClient/${client_id}`)
    .pipe(
      map((resp: any) => resp.filter((item: any) => !status ? item : item.active === status)));
  }
  getRouteBlocksByRoute(data) {
    const url = `${CONSTANT.MANIFEST.URL.SCHEDULE_DETAILS_GET_ROUTE_BLOCK}`;
    return this.http.post<any>(url, data);
  }

  getRoutesByClient(client_id: number, order_billing: number = 0) {
    return this.http.get<any>(`${environment.url_api_v}clients/routes/${client_id}/${order_billing}`);
  }

  getAvlPlaybackReport(client_id: number) {
    const url = `${CONSTANT.CLIENT.URL.CLIENTS_GET_AVL}/${client_id}`;
    return this.http.get<any>(url);
  }
  postPoSchedule(data: any) {
    delete data.id;
    return this.http.post<any>(`${environment.url_api_v}feeSchedulesPo`, JSON.stringify(data));
  }

  putPoSchedule(data: any, id: number) {
    return this.http.put<any>(`${environment.url_api_v}feeSchedulesPo/${id}`, JSON.stringify(data));
  }

  getPoScheduleByClient(client_id: number, status: boolean | true) {
    return this.http.get<any>(`${environment.url_api_v}feeSchedulesPo/byClient/${client_id}`)
    .pipe(
      map((resp: any) => resp.filter((item: any) => !status ? item : item.active === status)));
  }

  getReportByClient(startDate: string, endDate: string, asdate: string, type: any, json: any) {
    let date = type === 'custom' ? `:${startDate},${endDate}` : '';
    date = type === 'asOf' ? `:${asdate}` : date;
    return this.http.get(`${environment.url_api_v}payroll/report/${type}${date}/${json}`);
  }

}
