import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Subject } from 'rxjs/Rx';
import { map } from 'rxjs/operators';
import { CONSTANT } from '../../app.constants';

export interface IForm {
  id: number;
  description: string;
  form_type_id: number;
  forms_type?: IFormType[];
  form_report_id: number;
  form_reports?: IFormReport[];
  active: boolean;
}

export interface IFormType {
  id: number;
  description: string;
  is_active: boolean;
}

export interface IFormReport {
  id: number;
  description: string;
  report_trip2: number;
  is_active: boolean;
}

const iform: IForm = {
  id: 0,
  description: '',
  form_type_id: 0,
  forms_type: [],
  form_report_id: 0,
  form_reports: [],
  active: true
};

@Injectable({
  providedIn: 'root'
})
export class FormService {

  saveFormSubject$ = new BehaviorSubject<boolean>(false);
  saveForm$ = this.saveFormSubject$.asObservable();
  saveFormType$ = new BehaviorSubject<boolean>(false);
  saveFormReport$ = new BehaviorSubject<boolean>(false);

  addFormStatus$ = new BehaviorSubject<boolean>(false);
  addFormObs$ = this.addFormStatus$.asObservable();
  editformStatus$ = new BehaviorSubject<boolean>(false);
  editFormStatusObs$ = this.editformStatus$.asObservable();

  stateGridStatusSubject$ = new BehaviorSubject<boolean>(true);
  gridStatus$ = this.stateGridStatusSubject$.asObservable();

  rowFormSelected$ = new BehaviorSubject<IForm>(iform);
  rowFormSelectedObs$ = this.rowFormSelected$.asObservable();

  closedForm$ = new BehaviorSubject<boolean>(false);
  addModalFormType$ = new Subject<any>();
  closeModalFormType$ = new Subject<any>();
  addModalFormReport$ = new Subject<any>();
  closeModalFormReport$ = new Subject<any>();

  constructor(private http: HttpClient) {}

  getFormStatus(status: boolean) {
    const url = CONSTANT.BILLING.URL.BILLING_FORM;
    return this.http.get<any>(`${url}/${status ? 1 : 0}`);
  }

  getFormType() {
    const url = CONSTANT.BILLING.URL.BILLING_FORM_TYPE;
    return this.http.get<any>(url).pipe(map(resp => resp.data ));
  }

  getFormReport() {
    return this.http.get<any>(`${environment.url_api_v}billing/formReport`).pipe(map(resp => resp.data ));
  }

  addForm(data: IForm) {
    return this.http.post<IForm>(`${environment.url_api_v}billing/form`, JSON.stringify(data));
  }

  editForm(data: IForm) {
    const id = data.id;
    return this.http.put<IForm>(`${environment.url_api_v}billing/form/${id}`, JSON.stringify(data));
  }

  addFormType(data: IFormType) {
    return this.http.post<IFormType>(`${environment.url_api_v}billing/formType`, JSON.stringify(data));
  }

  addFormReport(data: IFormReport) {
    return this.http.post<IFormReport>(`${environment.url_api_v}billing/formReport`, JSON.stringify(data));
  }
}
