import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { CONSTANTS } from './../app.constants';

@Injectable()
export class ReportsService {

  protected resource: string;
  protected resource2: string;
  protected resource3: string;
  protected resource4: string;
  protected resource5: string;
  protected resource6: string;
  protected resource7: string;

  constructor(private http: HttpClient) {
    this.resource = environment.url_api_v + CONSTANTS.MISSING_DRIVER_MANIFESTS;
    this.resource2 = environment.url_api_v + CONSTANTS.ERRORS_IN_MILES;
    this.resource3 = environment.url_api_v + CONSTANTS.MISSING_DRIVER_MANIFESTS_ALL;
    this.resource4 = environment.url_api_v + CONSTANTS.DISRUPTIONS_BY_CLIENT;
    this.resource5 = environment.url_api_v + CONSTANTS.DISRUPTIONS_ALL_CLIENT;
    this.resource6 = environment.url_api_v + CONSTANTS.DRIVER_MANIFESTS_REPORT;
    this.resource7 = environment.url_api_v + CONSTANTS.ROUTE_BLOCK;
  }

  getMissingDriverManifestsByClient(startDate: string, endDate: string, client_id: number) {
    return this.http.post(`${this.resource}/${startDate}/${endDate}/${client_id}`, JSON.stringify({}),  { responseType: 'blob' });
  }

  getErrorsInMiles(startDate: string, endDate: string) {
    return this.http.post(`${this.resource2}/${startDate}/${endDate}`, JSON.stringify({}),  { responseType: 'blob' });
  }

  getMissingDriverManifestsAllClient(startDate: string, endDate: string) {
    return this.http.post(`${this.resource3}/${startDate}/${endDate}`, JSON.stringify({}),  { responseType: 'blob' });
  }

  getDisruptionsByClient(startDate: string, endDate: string, client_id: number) {
    return this.http.post(`${this.resource4}/${startDate}/${endDate}/${client_id}`, JSON.stringify({}),  { responseType: 'blob' });
  }

  getDisruptionsAllClients(startDate: string, endDate: string) {
    return this.http.post(`${this.resource5}/${startDate}/${endDate}`, JSON.stringify({}),  { responseType: 'blob' });
  }


  getMissingDriverManifestsAllClient2(startDate: string, endDate: string) {
    return this.http.post(`${this.resource6}/${startDate}/${endDate}`, JSON.stringify({}),  { responseType: 'blob' });
  }

  getMissingDriverManifestsByClient2(startDate: string, endDate: string, client_id: number) {
    return this.http.post(`${this.resource6}/${startDate}/${endDate}/${client_id}`, JSON.stringify({}),  { responseType: 'blob' });
  }

  getRouteBlock(route_id: any) {
    return this.http.post(`${this.resource7}/${route_id}`, JSON.stringify({}),  { responseType: 'blob' });
  }

  getDriversDocuments() {
    return this.http.get(`${environment.url_api_v}driversDocuments`,  { responseType: 'blob' });
  }

}
