import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'socialSecurityNumber'
})
export class SocialSecurityNumberPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    let text = '';
    for (let i = 0; i < value.length; i++) {
      if (value.charAt(i) === '-') {
        text += '-';
      } else {
        if (i <= args) {
          text += '*';
        } else {
          text += value.charAt(i);
        }
      }
    }
    return text;
  }

}
