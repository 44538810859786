/**
 * Created Septiembre 2017
 * @author Miguel Lopez Ariza
 * @description Este componente es el encargado de obtener el Id del Schedule del url para cargar el Ag-Grid
 * correspondiente en forma de arbol
 */
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import * as moment from 'moment';
import { BsModalComponent } from 'ng2-bs3-modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { DashboardLsfDataService } from './../dashboard-lsf.data.service';
import { Schedule, ScheduleRoute } from './schedule.model';


@Component({
  selector: 'app-schedules',
  templateUrl: './schedules.component.html',
  styleUrls: ['./schedules.component.scss']
})

export class SchedulesComponent implements OnInit {
  @ViewChild('modal') modal: BsModalComponent;

  busy: Subscription;
  gridSchedules: GridOptions;
  schedule: Schedule = new Schedule();
  week: Number = 0;
  datestart: String = '';
  dateend: String = '';

  /**
   * @description Variables que seran utilizadas para enviar al Hijo
   * @type {string}
   */
  nameDaySelected: String = '';
  dayNumberSelected: Number = 0;

  agScheduleView: Boolean = true;
  agScheduleDayView: Boolean = false;

  /**
   *@description La sigueintes variables son para la gestion de los datos del Ag-Grid
   */
  datos: Array<any> = new Array<any>();

  Tempschedule: any = {};
  TempScheduleDetail: any = {};
  TempScheduleDetail1: any = {};
  TempScheduleDetail2: any = {};
  TempScheduleDetail3: any = {};
  TempScheduleDetail4: any = {};

  fromSwich: Boolean = false;
  schedule_id = 0;
  route_id = 0;

  routes_id: Array<any> = new Array<any>();

  datosFull = [];

  gridApi;
  gridColumnApi;

  arrayBlockExist = [];

  btnCollapseStatus = true;
  login = true;
  showScheduleDrivers: boolean;

  constructor(public toastr: ToastrService,
              private  _dataService: DashboardLsfDataService,
              private activatedRoute: ActivatedRoute,
              private _location: Location) {
    this.setConfigGrid();
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      let schedule_id = params['id'];

      let login = params['login'] ? params['login'] : false;
      if (login) {
        const daynumber = moment().day() + 1;
        this.dayNumberSelected = daynumber;
        if (this.dayNumberSelected != 0) {
          this.login = false;
          this.nameDaySelected = this.nameDay(daynumber);
          this.schedule_id = schedule_id;
          this.route_id = 0;
          this.datestart = '';
          this.changeStatusAgSchedule(false);
        }
      } else {
        this.login = true;
        this.schedule_id = schedule_id;
        this.loadSchedule(schedule_id);
      }
    });
  }

  ngAfterViewInit() {

  }

   /**
   * @description Esta funcion se encarga de gestionar la parametrizacion de la Ag-Grid para ScheduleDetails
   */
  setConfigGrid() {
    let columnDefs2 = [
      { headerName: 'Route', colId: 'route_description', field: 'route_description', width: 100, rowGroup: true, hide: true },
      { headerName: 'Block', colId: 'block_code', field: 'block_code', rowGroup: true, hide: true, width: 50 },
      { headerName: 'Block Description', field: 'description', width: 130 },
      { headerName: 'Sunday', field: 'sunday', width: 130 },
      { headerName: 'Monday', field: 'monday', width: 130 },
      { headerName: 'Tuesday', field: 'tuesday', width: 130 },
      { headerName: 'Wednesday', field: 'wednesday', width: 130 },
      { headerName: 'Thrusday', field: 'thrusday', width: 130 },
      { headerName: 'Friday', field: 'friday', width: 130 },
      { headerName: 'Saturday', field: 'saturday', width: 130 },
    ];
    this.gridSchedules = {
      columnDefs: columnDefs2,
      rowData: null,
      debug: false,
      getRowStyle: getRowStyleScheduled,
      groupMultiAutoColumn: true,
      onRowGroupOpened() {
        this.btnCollapseStatus = false;
      },
      autoGroupColumnDef: {
        cellRendererParams: {
          suppressCount: true,
        }
      },
      groupDefaultExpanded: -1
    };

    function getRowStyleScheduled(params) {
      if (params.data) {
        if (params.data.description === 'Report Time') {
          return { 'color': 'red' };
        } else if (params.data.description === 'Punch Out Time') {
          return { 'color': 'red' };
        } else if (params.data.description === 'Start Time') {
          return { 'color': 'black' };
        } else if (params.data.description === 'End Time') {
          return { 'color': 'black'};
        } else {
          return { 'color': '#FFF', 'font-weight': 600, 'background-color': '#85AEFF'};
        }
      }
      return null;
    }
  }

  // View Day
  OnCellDoubleClicked($event) {
    const route_id = $event.data.route_id;
    if (route_id) {
      this.nameDaySelected = $event.column.colId;
      this.dayNumberSelected = this.verifyDay(this.nameDaySelected);
      if (this.dayNumberSelected != 0) {
        this.route_id = route_id;
        this.changeStatusAgSchedule(false);
      }
    }
  }

  /**
   * @description Esta funcion se encarga de verificvar que el pareametro que recibe sea alguno de los dias de la semana,
   * en caso correcto retorna true de lo contrario false
   * @param {String} day_name
   * @returns {boolean}
   */
  verifyDay(day_name: String) {
    let day_number = 0;
    switch (day_name) {
      case 'sunday':
        day_number = 1;
        break;
      case 'monday':
        day_number = 2;
        break;
      case 'tuesday':
        day_number = 3;
        break;
      case 'wednesday':
        day_number = 4;
        break;
      case 'thrusday':
        day_number = 5;
        break;
      case 'friday':
        day_number = 6;
        break;
      case 'saturday':
        day_number = 7;
        break;
    }
    return day_number;
  }

  nameDay(day_number: any) {
    let day_name = '';
    switch (day_number) {
      case 1:
        day_name = 'Sunday';
        break;
      case 2:
        day_name = 'Monday';
        break;
      case 3:
        day_name = 'Tuesday';
        break;
      case 4:
        day_name = 'Wednesday';
        break;
      case 5:
        day_name = 'Thrusday';
        break;
      case 6:
        day_name = 'Friday';
        break;
      case 7:
        day_name = 'Saturday';
        break;
    }
    return day_name;
  }

  /**
   * @description Este metodo es el encargado de consumir el servicio Schedules, el cual retorna un JSON,
   * que procedemos a procesar con la funcion forechScheduleRoutes.
   * @param {number} schedule_id
   */
  loadSchedule(schedule_id: number) {
    this.datosFull = []; // Limpiamos el array que almacena los Schedule Details
    this.busy = this._dataService.getData('schedules/' + schedule_id).subscribe((resp: any) => {
        this.schedule = resp;
        this.week = this.schedule.week;
        this.datestart = this.schedule.datestart;
        this.dateend = this.schedule.dateend;
        this.getInfo(this.schedule.schedule_routes);
        this.gridSchedules.api.setRowData(this.datosFull);
        this.btnCollapseStatus = true;
        this.gridSchedules.api.expandAll();
        this._dataService.dataFull = resp;
        this.autoSizeAll();
      }
    );
  }

  /**
   * @description Este metodo hace la magia para armar la estrutura requerida para que el Ag-Grid muestre la
   * estructura de Arbol de forma correcta, en este caso se recibe un parametro de tipo ScheduleRoute
   * @param {ScheduleRoute} datos
   */
  getInfo(datos: any) {
    this.arrayBlockExist = [];
    this.datosFull = [];
    for (let tem of datos) {
      this.insertOneV2(tem);
    }
  }

  insertOneV2(tem: any) {
    let item: any = {};
    // Begin Este bloque se encarga de crear un array con los bloques del schule
    let block_tem: any = {};
    block_tem.id = tem.routeblock.id;
    block_tem.route_id = tem.routeblock.route_id;
    block_tem.description = tem.routeblock.description;
    block_tem.block_code = tem.routeblock.block_code;
    this.arrayBlockExist.push(block_tem);
    // End

    item.route_id = tem.route_id;
    item.route_description = tem.route.description;
    if (tem.routeblock.block_code != '') {
      item.block_code = tem.routeblock.block_code;
    } else {
      item.block_code = '-';
    }

    item.block_description = tem.routeblock.description;
    item.description = tem.routeblock.description;
    // item.route_code = "";

    if (tem.schedule_details[0]) {
      if ((tem.schedule_details[0].driver.last_name === 'Open') && (tem.schedule_details[0].report_time.length == 0) &&
        (tem.schedule_details[0].start_time.length == 0) && (tem.schedule_details[0].end_time.length == 0) &&
        (tem.schedule_details[0].punch_out_time.length == 0)) {
        item.sunday = 'Off';
      } else {
        item.sunday = (tem.schedule_details[0]) ? tem.schedule_details[0].driver.last_name + ' ' + tem.schedule_details[0].driver.first_name : 'Off';
      }
    }

    if (tem.schedule_details[1]) {
      if ((tem.schedule_details[1].driver.last_name === 'Open') && (tem.schedule_details[1].report_time.length == 0) &&
        (tem.schedule_details[1].start_time.length == 0) && (tem.schedule_details[1].end_time.length == 0)
        && (tem.schedule_details[1].punch_out_time.length == 0)) {
        item.monday = 'Off';
      } else {
        item.monday = (tem.schedule_details[1]) ? tem.schedule_details[1].driver.last_name + ' ' + tem.schedule_details[1].driver.first_name : 'Off';
      }
    }

    if (tem.schedule_details[2]) {
      if ((tem.schedule_details[2].driver.last_name === 'Open') && (tem.schedule_details[2].report_time.length == 0) &&
        (tem.schedule_details[2].start_time.length == 0) && (tem.schedule_details[2].end_time.length == 0) &&
        (tem.schedule_details[2].punch_out_time.length == 0)) {
        item.tuesday = 'Off';
      } else {
        item.tuesday = (tem.schedule_details[2]) ? tem.schedule_details[2].driver.last_name + ' ' + tem.schedule_details[2].driver.first_name : 'Off';
      }
    }

    if (tem.schedule_details[3]) {
      if ((tem.schedule_details[3].driver.last_name === 'Open') && (tem.schedule_details[3].report_time.length == 0) &&
        (tem.schedule_details[3].start_time.length == 0) && (tem.schedule_details[3].end_time.length == 0) &&
        (tem.schedule_details[3].punch_out_time.length == 0)) {
        item.wednesday = 'Off';
      } else {
        item.wednesday = (tem.schedule_details[3]) ? tem.schedule_details[3].driver.last_name + ' ' + tem.schedule_details[3].driver.first_name : 'Off';
      }
    }

    if (tem.schedule_details[4]) {
      if ((tem.schedule_details[4].driver.last_name === 'Open') && (tem.schedule_details[4].report_time.length == 0) &&
        (tem.schedule_details[4].start_time.length == 0) && (tem.schedule_details[4].end_time.length == 0) &&
        (tem.schedule_details[4].punch_out_time.length == 0)) {
        item.thrusday = 'Off';
      } else {
        item.thrusday = (tem.schedule_details[4]) ? tem.schedule_details[4].driver.last_name + ' ' + tem.schedule_details[4].driver.first_name : 'Off';
      }
    }

    if (tem.schedule_details[5]) {
      if ((tem.schedule_details[5].driver.last_name === 'Open') && (tem.schedule_details[5].report_time.length == 0) &&
        (tem.schedule_details[5].start_time.length == 0) && (tem.schedule_details[5].end_time.length == 0) &&
        (tem.schedule_details[5].punch_out_time.length == 0)) {
        item.friday = 'Off';
      } else {
        item.friday = (tem.schedule_details[5]) ? tem.schedule_details[5].driver.last_name + ' ' + tem.schedule_details[5].driver.first_name : 'Off';
      }
    }

    if (tem.schedule_details[6]) {
      if ((tem.schedule_details[6].driver.last_name === 'Open') && (tem.schedule_details[6].report_time.length == 0) &&
        (tem.schedule_details[6].start_time.length == 0) && (tem.schedule_details[6].end_time.length == 0) &&
        (tem.schedule_details[6].punch_out_time.length == 0)) {
        item.saturday = 'Off';
      } else {
        item.saturday = (tem.schedule_details[6]) ? tem.schedule_details[6].driver.last_name + ' ' + tem.schedule_details[6].driver.first_name : 'Off';
      }
    }

    this.datosFull.push(item);

    item = {};
    item.route_id = tem.route_id;
    item.route_description = tem.route.description;
    if (tem.routeblock.block_code != '') {
      item.block_code = tem.routeblock.block_code;
    } else {
      item.block_code = '-';
    }

    item.block_description = tem.routeblock.description;
    item.description = 'Report Time';
    item.route_code = '';
    item.sunday = (tem.schedule_details[0]) ? tem.schedule_details[0].report_time : '';
    item.monday = (tem.schedule_details[1]) ? tem.schedule_details[1].report_time : '';
    item.tuesday = (tem.schedule_details[2]) ? tem.schedule_details[2].report_time : '';
    item.wednesday = (tem.schedule_details[3]) ? tem.schedule_details[3].report_time : '';
    item.thrusday = (tem.schedule_details[4]) ? tem.schedule_details[4].report_time : '';
    item.friday = (tem.schedule_details[5]) ? tem.schedule_details[5].report_time : '';
    item.saturday = (tem.schedule_details[6]) ? tem.schedule_details[6].report_time : '';

    this.datosFull.push(item);

    item = {};
    item.route_id = tem.route_id;
    item.route_description = tem.route.description;
    if (tem.routeblock.block_code != '') {
      item.block_code = tem.routeblock.block_code;
    } else {
      item.block_code = '-';
    }
    item.block_description = tem.routeblock.description;
    item.description = 'Start Time';
    item.route_code = '';
    item.sunday = (tem.schedule_details[0]) ? tem.schedule_details[0].start_time : '';
    item.monday = (tem.schedule_details[1]) ? tem.schedule_details[1].start_time : '';
    item.tuesday = (tem.schedule_details[2]) ? tem.schedule_details[2].start_time : '';
    item.wednesday = (tem.schedule_details[3]) ? tem.schedule_details[3].start_time : '';
    item.thrusday = (tem.schedule_details[4]) ? tem.schedule_details[4].start_time : '';
    item.friday = (tem.schedule_details[5]) ? tem.schedule_details[5].start_time : '';
    item.saturday = (tem.schedule_details[6]) ? tem.schedule_details[6].start_time : '';

    this.datosFull.push(item);

    item = {};
    item.route_id = tem.route_id;
    item.route_description = tem.route.description;
    if (tem.routeblock.block_code != '') {
      item.block_code = tem.routeblock.block_code;
    } else {
      item.block_code = '-';
    }
    item.block_description = tem.routeblock.description;
    item.description = 'End Time';
    item.route_code = '';
    item.sunday = (tem.schedule_details[0]) ? tem.schedule_details[0].end_time : '';
    item.monday = (tem.schedule_details[1]) ? tem.schedule_details[1].end_time : '';
    item.tuesday = (tem.schedule_details[2]) ? tem.schedule_details[2].end_time : '';
    item.wednesday = (tem.schedule_details[3]) ? tem.schedule_details[3].end_time : '';
    item.thrusday = (tem.schedule_details[4]) ? tem.schedule_details[4].end_time : '';
    item.friday = (tem.schedule_details[5]) ? tem.schedule_details[5].end_time : '';
    item.saturday = (tem.schedule_details[6]) ? tem.schedule_details[6].end_time : '';

    this.datosFull.push(item);

    item = {};
    item.route_id = tem.route_id;
    item.route_description = tem.route.description;
    if (tem.routeblock.block_code != '') {
      item.block_code = tem.routeblock.block_code;
    } else {
      item.block_code = '-';
    }
    item.block_description = tem.routeblock.description;
    item.description = 'Punch Out Time';
    item.route_code = '';
    item.sunday = (tem.schedule_details[0]) ? tem.schedule_details[0].punch_out_time : '';
    item.monday = (tem.schedule_details[1]) ? tem.schedule_details[1].punch_out_time : '';
    item.tuesday = (tem.schedule_details[2]) ? tem.schedule_details[2].punch_out_time : '';
    item.wednesday = (tem.schedule_details[3]) ? tem.schedule_details[3].punch_out_time : '';
    item.thrusday = (tem.schedule_details[4]) ? tem.schedule_details[4].punch_out_time : '';
    item.friday = (tem.schedule_details[5]) ? tem.schedule_details[5].punch_out_time : '';
    item.saturday = (tem.schedule_details[6]) ? tem.schedule_details[6].punch_out_time : '';
    this.datosFull.push(item);
  }


  /**
   * @description Esta funcion muestra o oculta, los Ag-Grid, dependiendo del caso.
   */
  changeStatusAgSchedule(edicion: boolean) {
    this.agScheduleView = !this.agScheduleView;
    this.agScheduleDayView = !this.agScheduleDayView;
    if (!this.agScheduleDayView) {
      this.arrayBlockExist = [];
      this.loadSchedule(this.schedule_id);
    }
    if (edicion) {
      this.loadSchedule(this.schedule_id);
    }
  }

  /**
   * @description Con esta funcion si se encuentra la view de Schedule Details activa, retricedemos a la pantalla
   * anterio, si no, si se esta mostrando la ag-grid de details days, entonces cambioamos los estamos de la ag-grid
   */
  backClicked() {
    if (this.agScheduleView) {
      this._location.back();
    } else {
      this.changeStatusAgSchedule(false);
    }
  }

  onEditSchedule() {
    const editSchedule = JSON.parse(localStorage.getItem('currentUser')).roles[0]
    .permissions.filter((row) => row.name === 'editSchedule')[0];
    if (editSchedule) {
      this.fromSwich = true;
      this.modal.open();
    } else {
      Swal.fire({
        type: 'warning',
        title: 'Oops...',
        text: 'You do not have permits for this operation ...',
      });
    }
  }

  onClose() {
    this.fromSwich = false;
    this.arrayBlockExist = [];
    // this.loadSchedule(this.schedule_id);
    this.modal.close();
  }

  onNotify(mensaje: any) {
    this.toastr.success(mensaje);
    this.arrayBlockExist = [];
    this.loadSchedule(this.schedule_id);
  }

  onCollapse() {
    this.btnCollapseStatus = !this.btnCollapseStatus;
    return (!this.btnCollapseStatus) ? this.gridSchedules.api.collapseAll() : this.gridSchedules.api.expandAll();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  autoSizeAll() {
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds);
  }


}
