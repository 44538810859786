import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CONSTANT, CONSTANTS } from '../../app.constants';
import { IResponse } from '../../CORE/interfaces/response.interface';
import { Attach } from '../../services/services.index';

@Injectable()
export class AttachDocumentService {
  protected resource: string;

  private attachDataSubject: BehaviorSubject<Attach> = new BehaviorSubject<Attach>(null);
  public attachData$: Observable<Attach> = this.attachDataSubject.asObservable();

  private missingLicenseSubject: BehaviorSubject<Attach> = new BehaviorSubject<Attach>(null);
  public missingLicense$: Observable<Attach> = this.missingLicenseSubject.asObservable();

  private saveAttachSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public saveAttach$: Observable<boolean> = this.saveAttachSubject.asObservable();

  constructor(private http: HttpClient) {
    this.resource = environment.url_api_v + CONSTANTS.ATTACHS_DOCUMENTS;
  }

  setAttachData(attach: Attach) {
    this.attachDataSubject.next(attach);
  }

  getMissingLicenseData(dataMissingLicenses) {
    this.missingLicenseSubject.next(dataMissingLicenses);
  }

  saveAttach(save: boolean) {
    this.saveAttachSubject.next(save);
  }

  /**
   * @description Return all drivers
   * @returns {Observable<any>}
   */
  getAttachsDocuments(): Observable<any> {
    return this.http.get<any>(this.resource);
  }

  /**
   * @description Save a driver
   * @param data
   * @returns {Observable<Response>}
   */
  storeAttachDocument(data: any): Observable<Response> {
    return this.http.post<Response>(this.resource, JSON.stringify(data));
  }

  /**
   * @description Update a driver
   * @param data
   * @returns {Observable<Response>}
   */
  updateAttachDocument(data: any, id: number): Observable<Response> {
    return this.http.put<Response>(
      this.resource + '/' + id,
      JSON.stringify(data)
    );
  }

  /**
   * @description Get a driver
   * @param {number} id
   * @returns {Observable<any>}
   */
  getAttachDocument(id: number): Observable<any> {
    return this.http.get(this.resource + '/' + id);
  }

  /**
   * @description
   * @param {boolean} status
   * @returns {Observable<any>}
   */
  getAttachsDocumentsStatus(status: boolean): Observable<any> {
    if (status) {
      return this.http.get(this.resource);
    } else {
      return this.http.get(this.resource + '/active/1');
    }
  }

  getFile(file: string) {
    window.open(file);
  }

  getAttachsDocumentsForModelStatus(documentOrlicense: string, model_id: number, model: string, expired: number) {
    const url = `${CONSTANT.DOCUMENTS.URL.ATTACH_DOCUMENT_LICENSE}${documentOrlicense}/${model_id}/${model}/${expired}`;
    return this.http.get<IResponse<any>>(url);
  }

  getMissingLicenses(documentOrlicense: string, model_id: number, model: string) {
    const url = `${CONSTANT.DOCUMENTS.URL.GET_MISSING_LICENSES}${model_id}/${model}`;
    return this.http.get<IResponse<any>>(url);
  }

}
