import { Component, OnInit, ViewChildren } from '@angular/core';
import { GridOptions, GridReadyEvent } from 'ag-grid-community';
import { BsModalComponent } from 'ng2-bs3-modal';
import { Subscription } from 'rxjs';
import { DriverService } from '../../../../../services/services.index';
import { TsoService } from '../../../../../services/Tso.service';
import { RouteService } from '../../../../../services/TSO/TSO.index';
import { AdminDataService } from '../../../fleet.data.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-integration',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.scss']
})
export class IntegrationComponent implements OnInit {
  @ViewChildren('modalBlockIntegration') modalBlockIntegration: BsModalComponent;
  @ViewChildren('modalRouteStop') modalRouteStop: BsModalComponent;
  route_id: any;
  public busy: Subscription;

  gridRoutesTA: GridOptions;
  gridRoutesTSO: GridOptions;
  gridRoutesIntegration: GridOptions;

  routesTa: Array<any> = [];
  routesTso: Array<any> = [];
  routesIntegration: Array<any> = [];

  countRoutesTa = 0;
  countRoutesTso = 0;
  countRoutesIntegration = 0;

  dataBlock: any;

  constructor(
    private _serviceDriver: DriverService,
    private _serviceTSORoute: RouteService,
    private _serviceTSOService: TsoService,
    private _dataService: AdminDataService,
    private route: Router
  ) {
    this.initGridRoutesTA();
    this.initGridRoutesTSO();
    this.initGridRoutesIntegration();
  }

  ngOnInit() {}

  loadFullData() {
    this.getAllRoutes();
    this.getAllRoutesTSO();
    this.getAllRoutesIntegration();
  }

  initGridRoutesTA() {
    this.gridRoutesTA = <GridOptions>{
      enableColResize: true,
      enableSorting: true,
      enableFilter: true,
      columnDefs: [
        {
          headerName: 'Description',
          field: 'description',
          width: 380,
          tooltipField: 'description',
          colId: 'description',
          sort: 'asc',
          checkboxSelection: true
        }
      ],

      onGridReady: (event: GridReadyEvent) => {
        if (event) {
          this.getAllRoutes();
        }
      }
    };
  }

  initGridRoutesTSO() {
    this.gridRoutesTSO = <GridOptions>{
      enableColResize: true,
      enableSorting: true,
      enableFilter: true,
      columnDefs: [
        {
          headerName: 'Descriptions',
          field: 'description',
          width: 320,
          tooltipField: 'description',
          colId: 'description',
          sort: 'asc',
          checkboxSelection: true
        },

        {
          headerName: '',
          width: 40,
          colId: 'map',
          template: `
          <span>
          <i class="fa fa-map-marker" aria-hidden="true"></i>
          </span>
        `
        }
      ],

      onGridReady: (event: GridReadyEvent) => {
        if (event) {
          this.getAllRoutesTSO();
        }
      }
    };
  }

  initGridRoutesIntegration() {
    this.gridRoutesIntegration = <GridOptions>{
      enableColResize: true,
      enableSorting: true,
      enableFilter: true,
      columnDefs: [
        {
          headerName: 'Description',
          field: 'description',
          width: 280,
          tooltipField: 'description',
          colId: 'description',
          sort: 'asc'
        },

        {
          headerName: '',
          width: 40,
          colId: 'stops',
          template: `
          <span>
          <i class="fa fa-train" aria-hidden="true"></i>
          </span>
        `
        },
        {
          headerName: '',
          width: 40,
          colId: 'remove',
          template: `
          <span>
          <i class="fa fa-trash-o" aria-hidden="true"></i>
          </span>
        `
        },
        {
          headerName: '',
          width: 40,
          colId: 'block',
          template: `
          <span>
          <i class="fa fa-lightbulb-o" aria-hidden="true"></i>
          </span>
        `
        }
      ],
      onGridReady: (event: GridReadyEvent) => {
        if (event) {
          this.getAllRoutesIntegration();
        }
      }
    };
  }

  /***
   * @description
   * @param {boolean} status
   */
  getAllRoutes() {
    this.busy = this._dataService.getData('routes/active/1').subscribe((data: any) => {
      this.routesTa = data.filter((row: any) => row.tso_id === '');
      this.gridRoutesTA.api.setRowData(this.routesTa);
      this.countRoutesTa = this.countElementGrid(this.gridRoutesTA);
    });
  }

  /***
   * @description
   * @param {boolean} status
   */
  getAllRoutesTSO() {
    this.busy = this._serviceTSORoute.getRoutesTso().subscribe((resp: any) => {
      if (resp.status === 'success') {
        this.routesTso = resp.data;
        this.gridRoutesTSO.api.setRowData(this.routesTso);
      }
    });
  }

  getAllRoutesIntegration() {
    this.busy = this._dataService.getData('routes/active/1').subscribe((data: any) => {
      // this.orderGrid(data);
      this.gridRoutesIntegration.api.setRowData(data.filter((row: any) => row.tso_id !== ''));
      this.clearRoutesTSO();
      this.getCount();
    });
  }

  onAutoMerge() {
    this.routesTso.forEach((row0, x) => {
      const name0 = row0.description;
      this.routesTa.forEach((row1, y) => {
        const name1 = row1.description;
        if (name0.toUpperCase() === name1.toUpperCase()) {
          const obj = {
            id: row1.id,
            description: row1.description.toUpperCase(),
            tso_id: row0.id
          };
          this.routesIntegration.push(obj);
          this.routesTa.splice(y, 1);
          this.routesTso.splice(x, 1);
        }
      });
    });
    this.setData();
  }

  setData() {
    this.gridRoutesTA.api.setRowData(this.routesTa);
    this.gridRoutesTSO.api.setRowData(this.routesTso);
    this.gridRoutesIntegration.api.setRowData(this.routesIntegration);
    this.getCount();
  }

  getCount() {
    this.countRoutesIntegration = this.countElementGrid(this.gridRoutesIntegration);
    this.countRoutesTa = this.countElementGrid(this.gridRoutesTA);
    this.countRoutesTso = this.countElementGrid(this.gridRoutesTSO);
  }

  countElementGrid(grid: GridOptions): number {
    let count = 0;
    grid.api.forEachNode(row => {
      count++;
    });
    return count;
  }

  onMerge() {
    const routeTa = this.gridRoutesTA.api.getSelectedRows();
    const routeTso = this.gridRoutesTSO.api.getSelectedRows();

    const obj = {
      id: routeTa[0].id,
      description: routeTa[0].description.toUpperCase(),
      tso_id: routeTso[0].id
    };

    this.routesIntegration.push(obj);
    this.gridRoutesIntegration.api.updateRowData({ add: [obj] });

    this.gridRoutesTA.api.updateRowData({ remove: routeTa });
    this.gridRoutesTSO.api.updateRowData({ remove: routeTso });
    this.getCount();
  }

  onSave() {
    const tem = [];
    this.gridRoutesIntegration.api.forEachNode(row => {
      tem.push(row.data);
    });
    this.busy = this._dataService.postData(tem, 'routes/storeRouteIntegration').subscribe(data => {
      this.loadFullData();
    });
  }

  onClearAll() {
    Swal.fire({
      title: 'confirms the elimination of all records of the relationship?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        this.busy = this._dataService.getData('routes/deleteIdsTSORoute/all').subscribe((data: any) => {
          this.loadFullData();
        });
      }
    });
  }

  onReload() {
    this.getAllRoutesTSO();
  }

  clearRoutesTSO() {
    this.gridRoutesIntegration.api.forEachNode(rowNode => {
      const tso_id = rowNode.data.tso_id;
      this.routesTso.forEach((row, x) => {
        if (Number(row.ID) === Number(tso_id)) {
          this.routesTso.splice(x, 1);
        }
      });
    });
    this.gridRoutesTSO.api.setRowData(this.routesTso);
  }

  onCellClicked($event) {
    if ($event.colDef.colId === 'remove') {
      const data = $event.data;
      Swal.fire({
        title: 'confirms the deletion of the current record?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(result => {
        if (result.value) {
          const id = $event.data.id;
          this.busy = this._dataService.getData('routes/deleteIdTSORoute/' + id).subscribe((data: any) => {
            this.loadFullData();
          });
        }
      });
    } else if ($event.colDef.colId === 'block') {
      this.dataBlock = $event.data;
    } else if ($event.colDef.colId === 'stops') {
      // console.log(JSON.stringify($event.data));
      this.route_id = $event.data.tso_id;
      this.dataBlock = $event.data;
      // this.modalRouteStop.open();
    }
  }

  onCellClickedTso(event: any) {
    if (event.colDef.colId === 'stops') {
      this.route_id = event.data.id;
    } else if (event.colDef.colId === 'map') {
      this.route.navigateByUrl(`fleet/routes-block/tso/map/${event.data.id}`);
    }
  }
}
