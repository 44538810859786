import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClientService } from '../../../../../../services/fleet/client.service';
import { RouteGroupService } from '../../../../../../services/fleet/route-group.service';
import { IFeeSchedule, IRuteGroup } from '../fee-schedule.interface';
import { SweetAlertHelper } from '../../../../../../CORE/helpers/sweet-alert-helper.service';

@Component({
  selector: 'app-form-fee-schedule-route-group',
  templateUrl: './form-fee-schedule-route-group.component.html',
  styleUrls: ['./form-fee-schedule-route-group.component.scss']
})

export class FormFeeScheduleRouteGroupComponent implements OnInit, OnChanges, OnDestroy {
  private destroy: Subject<boolean> = new Subject();
  busy: Subscription[] = [];
  formRouteGroupFeeSchedule: FormGroup;
  routes_group: IRuteGroup[] = [];
  @Input() clientId?: number;
  @Input() modalInvoice?: boolean;

  constructor(
    private fb: FormBuilder,
    private clientService: ClientService,
    private routeGroupService: RouteGroupService,
    private sweetAlertHelper: SweetAlertHelper
  ) {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['clientId'] && this.clientId) {
      this.loadRutesByClient(this.clientId);
    }
  }

  loadRutesByClient(clientid: number) {
    this.busy.push(
      this.routeGroupService.getRouteGroupByClient(clientid).subscribe((resp: any) => {
        if (resp && resp.data && resp.data.length) {
          this.routes_group = resp.data;
        }
      })
    );
  }

  ngOnInit() {
    this.subscribeObservables();
  }

  subscribeObservables() {
    this.clientService.editFeeSchedule$.pipe(takeUntil(this.destroy)).subscribe((resp: IFeeSchedule) => {
      if (resp) {
        this.formRouteGroupFeeSchedule.get('fee_schedule_id').setValue(resp.id);
      }
    });

    this.clientService.editRouteGroup$.pipe(takeUntil(this.destroy)).subscribe((resp: IRuteGroup) => {
      if (resp) {
        this.formRouteGroupFeeSchedule.patchValue({
          id: resp.pivot.id,
          route_group_id: resp.pivot.route_group_id,
          rate: resp.pivot.rate,
          po_number: resp.pivot.po_number,
          fee_schedule_id: resp.pivot.fee_schedule_id,
          active: resp.pivot.active
        });
        this.formRouteGroupFeeSchedule.get('route_group_id').disable();
      } else {
        this.formRouteGroupFeeSchedule.patchValue({
          id: null,
          route_group_id: null,
          active: true,
        });
      }
    });
  }

  createForm() {
    this.formRouteGroupFeeSchedule = this.fb.group({
      id: [null],
      route_group_id: [null, Validators.required],
      rate: [0, Validators.required],
      po_number: [null],
      fee_schedule_id: [null, Validators.required],
      active: [true]
    });
  }

  save() {
    const formData = Object.assign({}, this.formRouteGroupFeeSchedule.getRawValue());
    if (formData.id) {
      this.busy.push(
        this.routeGroupService.putRouteGroup(formData).subscribe({
            next: resp => {
              if (resp) {
                this.clientService.reloadEditFeeScheduleGrid(true);
              }
            },
            error: error => {
              this.sweetAlertHelper.captureException(error).then();
            }
          }
        )
      );
    } else {
      this.busy.push(
        this.routeGroupService.postRouteGroup(formData).subscribe({
            next: resp => {
              if (resp) {
                this.clientService.reloadEditFeeScheduleGrid(true);
              }
            },
            error: error => {
              this.sweetAlertHelper.captureException(error).then();
            }
          }
        )
      );
    }
  }

  get isEdit(): boolean {
    return !!this.formRouteGroupFeeSchedule.get('id').value;
  }

  get isActive(): boolean {
    return !!this.formRouteGroupFeeSchedule.get('id').value;
  }

  get buttonText(): string {
    return this.isEdit ? 'Save Changes' : 'Save';
  }

  ngOnDestroy(): void {
    this.busy.forEach(subscription => subscription.unsubscribe());
  }
}
