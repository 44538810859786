import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { CONSTANT } from '../../../app.constants';

export interface ICreditState {
  id: string;
  credit_type_id: number;
  description: string;
  is_active: boolean;
}

@Injectable({
  providedIn: 'root'
})

export class BatchCreditStateService {
  loadBatchInvoiceCreditStates$ = new BehaviorSubject<ICreditState>(null);
  loadBatchInvoiceCreditStatesRow$ = new BehaviorSubject<ICreditState>(null);
  save$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) { }

  get bacthInvoiceCreditState$(): Observable<ICreditState> {
    return this.loadBatchInvoiceCreditStates$.asObservable();
  }

  get bacthInvoiceCreditStateRows$(): Observable<ICreditState>{
    return this.loadBatchInvoiceCreditStatesRow$.asObservable();
  }

  getBatchInvoiceCreditStates() {
    const url = CONSTANT.BILLING.URL.BILLING_BATCH_INVOICE_CREDIT;
    return this.http.get<any>(url);
  }

  addBatchInvoiceCreditStates(creditState: any) {
    const url = CONSTANT.BILLING.URL.BILLING_BATCH_INVOICE_CREDIT;
    return this.http.post<any>(url, creditState);
  }

  editBatchinvoiceCreditStates(creditState: ICreditState) {
    const url = `${CONSTANT.BILLING.URL.BILLING_BATCH_INVOICE_CREDIT}/${creditState.id}`;
    return this.http.put<any>(url, creditState);
  }

  getListCreditTypes() {
    const url = CONSTANT.BILLING.URL.BILLING_BATCH_INVOICE_CREDIT_TYPE;
    return this.http.get<any>(url);
  }

}
