import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild, OnDestroy } from '@angular/core';
import { AttachImage, AttachImageService } from '../../../../../services/services.index';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-vehicle-images',
  templateUrl: './vehicle-images.component.html',
  styleUrls: ['./vehicle-images.component.scss']
})
export class VehicleImagesComponent implements OnInit, OnDestroy {

  @Input() vehicle_id: number;
  @ViewChild('btnAdd')
  btnAdd: ElementRef;
  busy: Subscription;
  attachImages: AttachImage[];

  constructor( private rendere: Renderer2, public _serviceAttachImage: AttachImageService ) {
    this.attachImages = [];
  }

  ngOnInit() {
    this.loadData();
  }

  ngOnDestroy() {
    this.busy? this.busy.unsubscribe():null;
  }

  onCancel($event) {
    this.rendere.selectRootElement(this.btnAdd.nativeElement).click();
    const text = this.rendere.createText('Add Image');
    this.rendere.appendChild(this.btnAdd.nativeElement, text);
  }

  onReload($event) {
    this.rendere.selectRootElement(this.btnAdd.nativeElement).click();
    const text = this.rendere.createText('Add Image');
    this.rendere.appendChild(this.btnAdd.nativeElement, text);
    this.loadData();
  }

  loadData() {
    this.busy = this._serviceAttachImage.getAttachsImagesForVehicle(this.vehicle_id).subscribe(
      (data: AttachImage[]) => {
        this.attachImages = data;
      }
    );
  }

  onDelete(id: number) {
    if (confirm('confirm that you want to delete the current image?')) {
      this.busy = this._serviceAttachImage.deleteAttachImage(id).subscribe(
        data => {
          this.loadData();
        }
      );
    }
  }

  onOpenImage(file: string) {
    const link = this._serviceAttachImage.getFile(file);
    const win = window.open(link, '_blank');
    win.focus();
  }

}
