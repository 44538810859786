import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {WorkflowService} from '../../../../../services/billing/workflow.service';
import {SweetAlertHelper} from '../../../../../CORE/helpers/sweet-alert-helper.service';

@Component({
  selector: 'app-form-billing-status',
  templateUrl: './form-billing-status.component.html',
  styleUrls: ['./form-billing-status.component.scss']
})
export class FormBillingStatusComponent implements OnInit {
  billing_status_list: any[] = [];
  private destroy: Subject<boolean> = new Subject();
  suscriptionWorkFlow: Subscription[] = [];
  formBillingStatusWorkflow: FormGroup;
  ids = '0';
  constructor(private fb: FormBuilder, private workflowService: WorkflowService, private sweetAlertHelper: SweetAlertHelper) {
    this.createForm();
  }

  ngOnInit() {
    this.subscribeObservers();
  }

  subscribeObservers() {
    this.workflowService.sendIdsChangesVerified$.subscribe(ids => {
      if (ids && ids.length) {
        this.ids = ids.length;
        this.formBillingStatusWorkflow.patchValue({
          ids: ids
        });
      }
    });
    this.suscriptionWorkFlow.push(this.workflowService.getBillingStatus().subscribe(resp => {
      if (resp && resp.data) {
        this.billing_status_list = resp.data;
      }
    }, error => {
      this.sweetAlertHelper.captureException(error.message).then();
    }));
  }

  createForm() {
    this.formBillingStatusWorkflow = this.fb.group({
      ids: [''],
      billing_status_id: ['0', Validators.required],
      note: ['', Validators.required]
    });
  }

  save() {
    const formData = Object.assign({}, this.formBillingStatusWorkflow.value);
    if (formData.ids) {
      this.suscriptionWorkFlow.push(this.workflowService.changeStatus(formData).subscribe(resp => {
        if (resp && resp.data) {
          let htmlMessage = '';
          let title = '';
          const schedule_successfully = resp.data.schedule_success ? `
                                              <div style="color: green">Total Schedules Successfully ${ resp.data.number_verified }</div>
                                              <div style="color: red">Total Schedules Errors ${ resp.data.number_rejected }</div>` : '';
          if (resp.data.schedule_failed && resp.data.schedule_failed.length) {
            resp.data.schedule_failed.forEach((schedule) => {
              title = `<div> Billing Status<br><small><p style="color: green"> ${ schedule_successfully }</p></small></div>`;
              htmlMessage += `<hr style="margin-top: 5px; margin-bottom: 5px;">
                       <p style="color: red">Schedules Errors <small>${schedule['schedule_detail_id']}</small></p>
                      ${schedule['block_description'][0]} - ${schedule['block_description'][1]} - ${ schedule['block_description'][2]}<br>
                      <b> ${ schedule.errors.length } Discrepanses</b> <br>
                      ${this.errorsFormatted(schedule['errors'])}<br>`;
            });
            htmlMessage = `<div style="height: 150px; overflow-y: scroll;">${htmlMessage}</div>`;
          } else {
            title = `<div> Billing Status<br><small><p> ${ schedule_successfully }</p></small></div>`;
            htmlMessage += ``;
          }
          this.sweetAlertHelper.createCustomAlert({
            title: title,
            html: `<div>${htmlMessage}</div>`,
            type: 'success',
          }).then(() => {
              this.workflowService.reloadWorkflowSubject.next(true);
              this.formBillingStatusWorkflow.reset();
          });
        }
      }, error => {
        this.sweetAlertHelper.captureException(error).then();
      }));
    }
  }

  private errorsFormatted(str) {
    if (str.length > 0) {
      let errors = '';
      str.map(error => {
        errors += `${ error }<br>`;
      });
      return errors;
    }
    return str;
  }

  get total_schedules() {
    return `${this.ids} schedule selected`;
  }

}
