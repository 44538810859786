import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AdminDataService } from '../../../fleet.data.service';
import { Client, Division } from '../../route-block.model';
import { Driver, TimeTypes } from '../../../route.model';
import { TsoService } from '../../../../../services/Tso.service';
import { RunsService } from '../../../../../services/fleet/runs.service';

@Component({
  selector: 'app-create-route-block',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateRouteBlockComponent implements OnInit {

  public busy: Subscription;
  public clients: Client;
  public divisions: Division;
  public vehicles: Array<any> = [];

  public title: String = 'New Route';
  public btnSave: String = 'Save and Add Blocks';
  public btnCancelar = 'Cancel';

  public saved: Boolean = false;
  public route: any = {};
  public apiResponse: any;

  @Output() onClose = new EventEmitter<boolean>();
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  @Input() routeSekected: number;


  public countView = 1;

  public route_id = 0;

  public components = [];

  public isCollapsed = false;

  public route_blocks = [];

  public timeTypes: Array<TimeTypes>;
  public drivers: Array<Driver> = [];

  public route_edit_clear = false;
  public edit = false;

  public btnSaveEnable = true;

  public BtnshowBlockInactives = false;
  public lengRouteActive = 0;

  showTabMap = false;
  tsoId: any;
  stops: any = [];
  runs: any = [];

  constructor(private  _dataService: AdminDataService, public toastr: ToastrService,
              private tsoService: TsoService, private runsService: RunsService) {
  }

  ngOnInit() {
    this.loadClientes();
    this.loadDataOne(this.routeSekected);
    this.loadTimeTypes();
    this.loadDrivers();
    this.loadVehicles();

    if (this.routeSekected) {
      this.btnSave = 'Update Route';
      this.loadRuns();
    }

  }

  /**
   * @description esta funcion se encarga de consumir el servicio que retorna todos los clients y almacenarlos en la variable
   * clients.
   */
  loadClientes() {
    this.busy = this._dataService.getData('clients/active/1').subscribe(
      (response: any) => {
        this.clients = response.data;
      }
    );
  }

  /**
   * @description Este metodo consume el servicio que retorna todos los timeTypes, y los setea en la variable drivers q
   * esta declara en la parte superior de este programa
   */
  loadTimeTypes() {
    this.busy = this._dataService.getData('timeTypes').subscribe(
      (data: any) => {
        this.timeTypes = data;
        this.timeTypes.splice(2, 1);
      }
    );
  }

  /**
   * @description Este metodo consume el servicio que retorna todos los driver, y los setea en la variable drivers q
   * esta declara en la parte superior de este programa
   */
  loadDrivers() {
    this.busy = this._dataService.getData('drivers/active/1').subscribe(
      (data: any) => {
        let driverT: Driver = new Driver();
        driverT.id = null;
        driverT.first_name = 'OPEN';
        driverT.last_name = '';

        let t: Array<Driver> = data;
        t.push(driverT);
        t.sort((a, b) => {
          if (a.first_name < b.first_name) return -1;
          else if (a.first_name > b.first_name) return 1;
          else return 0;
        });

        this.drivers = t;
      }
    );
  }

  /**
   * @description Esta funcion se encarga de consumir el servicio para almacenar y actualizar la cabecera de la ruta, para
   * determinar la operacion a realizar valido es valor de una varaible llamada routeSekected
   */
  onSave() {
    if (this.routeSekected === 0) {
      this.busy = this._dataService.postData(this.route, 'routes').subscribe(
        (data: any) => {
          this.apiResponse = data;
          if (this.apiResponse.transaction) {
            this.route_id = this.apiResponse.master.result.id;

            this.notify.emit(this.apiResponse.message);

            this.saved = true;
            this.btnSave = 'Save';
            this.title = 'Add Block for ' + this.route.description;
            this.btnCancelar = 'Close';
            this.addComponent(true);
          } else {
            this.toastr.error(this.apiResponse.message + '.<br> Log : ' + this.apiResponse.master.result, 'Oops!', {
              closeButton: true
            });
          }
        }
      );
    } else {
      const id = this.route.id;
      delete this.route.route_blocks;
      delete this.route.client;
      delete this.route.updated_at;
      delete this.route.created_at;
      delete this.route.route_code;
      delete this.route.id;
      this.busy = this._dataService.putData(this.route, 'routes/' + id).subscribe(
        (data: any) => {
          this.apiResponse = data;
          if (this.apiResponse.transaction) {
            this.notify.emit(this.apiResponse.message);
            this.route.id = this.apiResponse.master.result.id;
            this.onCancel();
          } else {
            this.toastr.error(this.apiResponse.message + '.<br> Log : ' + this.apiResponse.master.result, 'Oops!', {
              closeButton: true
            });
          }
        }
      );
    }
  }

  /**
   * @description Esta funcion se encarga de crear un nuevo bloque en la vista, empujando a un array un objeto bacio
   * @param value
   */
  addComponent(value: any) {
    this.route_edit_clear = false;
    this.edit = value;
    this.route_blocks.push({});
  }

  /**
   * @description Este metodo controla los dos estados que tiene el bloque de rutas, expadido o contraido, para lo cual simplemente
   * invertimos el valor boleano de una variable llamada isCollapsed
   */
  changeStatus() {
    this.isCollapsed = !this.isCollapsed;
  }

  /**
   * @description con esta funcion incremento una variable llamada countView que me permite conocer el numero de viewBlock creadas en el dom
   * y este numero lo utilizo para asignar los id a los componentes internos de bloqueView
   */
  incrementCount() {
    this.countView += 1;
  }

  /**
   * @description Esta funcion se encarga de consumir el servicio que retorna una ruta en especifico reciviendo como parametro
   * el id de esta, una ves el servicio retorna una respuesta, procedemos a asignar valores a el array route_block el cual se
   * cargara en el dom insertando datos en el dom con la view RouteBlock
   * @param routeid
   */
  loadDataOne(routeid: number) {
    if (routeid > 0) {
      this.busy = this._dataService.getData('routes/' + routeid).subscribe(
        (data: any) => {
          this.route = data;
          this.saved = true;

          this.title = 'Edit Route ' + this.route.description;
          this.route_id = data.id;
          this.route.exclude = data.exclude ? true : false;
          this.route.exclude_reports = data.exclude_reports ? true : false;
          this.tsoId = data.tso_id;

          if (this.tsoId) {
            this.loadDataStops(this.tsoId);
          }

          /*if(data.active != '1'){
              this.route.active = 0;
          }*/

          if (data.route_blocks.length == 0) {
            this.route_edit_clear = true;
          } else {
            this.route_blocks = data.route_blocks;
          }
        }
      );
    }
  }

  addBlock() {
    this.saved = true;
    this.btnSave = 'Save';
    this.title = 'Add Block for ' + this.route.description;
    this.btnCancelar = 'Close';
    this.addComponent(true);
  }


  EditComponent(value: boolean) {
    this.btnSaveEnable = !value;
  }

  /**
   * @description esta funcion se encarga de escuchar cuando sea pulsada la tecla esc y cierra el modal
   * @param event
   */
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    let x = event.keyCode;
    if (x === 27) {
      this.onCancel();
    }
  }

  /**
   * @description Esta funcion invoca un evento que se transmite al padre para cerrar el modal
   */
  onCancel() {
    this.onClose.emit(false);
  }

  public trackByIndex(index: number, item) {
    return index;
  }

  showBlockInactives() {
    this.lengRouteActive = 0;

    this.BtnshowBlockInactives = !this.BtnshowBlockInactives;
    let index = 0;
    for (let obj of this.route_blocks) {
      if (obj.active) {
        this.lengRouteActive++;
      }
      if (obj.active == !this.BtnshowBlockInactives) {
        $('#bloc_show' + index).show();
      } else {
        $('#bloc_show' + index).hide();
      }
      index++;
    }

  }

  loadVehicles() {
    this.busy = this._dataService.getData('vehicles/active/1').subscribe(
      (data: any) => {
        this.vehicles = data;
      }
    );
  }

  loadDataStops(tso_id) {
    this.busy = this.tsoService.getStopsByRoute(tso_id, false).subscribe((resp: any) => {
      this.stops = resp;
    });
  }

  loadRuns() {
    if (this.routeSekected) {
      this.busy = this.runsService.getRunsByRoute(this.routeSekected).subscribe((resp: any) => {
        this.runs = resp;
      });
    }
  }

  onReload() {
    this.loadDataOne(this.routeSekected);
  }
}
