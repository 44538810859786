import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CONSTANTS } from '../../app.constants';

@Injectable({
  providedIn: 'root'
})
export class DriverPerDiemService {

  protected resource: string;

  options = [
    'None',
    'Morning',
    'Evening',
    'Both',
    'Off',
    'Standby'
  ];

  constructor(private http: HttpClient) {
    this.resource = environment.url_api_v + CONSTANTS.DRIVERS;
  }

  getAllPerDiem() {
    return this.http.get(`${environment.url_api_v}${CONSTANTS.DRIVERS}/${CONSTANTS.PERDIEM}`);
  }

  getAllPerDiemDate(date: string) {
    return this.http.get(`${environment.url_api_v}${CONSTANTS.DRIVERS}/${CONSTANTS.PERDIEM}/getDataForDate/${date}`);
  }

  getPerDiem(id: number) {
    return this.http.get(`${environment.url_api_v}${CONSTANTS.DRIVERS}/${CONSTANTS.PERDIEM}/${id}`);
  }

  postPerDiem(data: any) {
    return this.http.post(`${environment.url_api_v}${CONSTANTS.DRIVERS}/${CONSTANTS.PERDIEM}`, JSON.stringify(data));
  }

  putPerDiem(data: any, id: number) {
    return this.http.put(`${environment.url_api_v}${CONSTANTS.DRIVERS}/${CONSTANTS.PERDIEM}/${id}`, JSON.stringify(data));
  }

  getPerDiemAvailability(filter: string) {
    return this.http.get(`${environment.url_api_v}${CONSTANTS.DRIVERS}/${CONSTANTS.PERDIEM}/filter/${filter}`);
  }

  getHoursWorked(driver_id: number) {
    return this.http.get(`${environment.url_api_v}${CONSTANTS.DRIVERS}/${CONSTANTS.PERDIEM}/gethoursworked/${driver_id}`);
  }

  getHoursWorkedByDriverScheduleDetails(driver_id: any, start_date: any, end_date: any) {
    return this.http.get(`${environment.url_api_v}${CONSTANTS.DRIVERS}/${CONSTANTS.PERDIEM}/getHoursworkedbydriverscheduledetails/${driver_id}/${start_date}/${end_date}`);
  }

}
