import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-grid-switch',
  templateUrl: './grid-switch.component.html',
  styleUrls: ['./grid-switch.component.scss']
})
export class GridSwitchComponent implements ICellRendererAngularComp {
  private params: ICellRendererParams;
  checkedEle: boolean;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.setChecked();
  }

  refresh(params: any): boolean {
    this.params = params;
    this.setChecked();
    return false;
  }

  setChecked() {
    this.checkedEle = this.params.data.active;
  }


}
