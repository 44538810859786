import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CONSTANTS } from '../app.constants';
import { map } from 'rxjs/operators';

export interface Login {
  login: string;
  password: string;
}

export interface TsoResponse {
  Code: number;
  Message: string;
  Data: any;
}


@Injectable()
export class TsoService {

  protected resource: string;

  constructor(private http: HttpClient) {
    this.resource = environment.url_api_v;
  }

  getAllDrivers(): Observable<TsoResponse>  {
    return this.http.get<TsoResponse>(this.resource + environment.tso.drivers_list);
  }

  getAllUnits(): Observable<TsoResponse> {
    return this.http.get<TsoResponse>(this.resource + environment.tso.units_list);
  }

  getAllRoutes(): Observable<TsoResponse> {
    return this.http.get<TsoResponse>(this.resource + environment.tso.routes);
  }

  setTokenLocal(token: string) {
    localStorage.setItem('token_tso', token);
  }

  getTokenLocal() {
    return localStorage.getItem('token_tso');
  }

  getLoginTSO(user_id: number) {
    return this.http.get(environment.url_api_v + 'getLoginTSO/' + user_id);
  }

  setTokenLocalMain(token: string) {
    localStorage.setItem('token_tso_main', token);
  }

  getTokenLocalMain() {
    return localStorage.getItem('token_tso_main');
  }

  getTsoAssign(schedule_detail_id: number) {
    return this.http.get(environment.url_api_v + 'getTsoAssign/' + schedule_detail_id);
  }

  getStopsByRoute(route_id, status) {
    return this.http.get(`${environment.url_api_v}routesTsoStops/byRoute/${route_id}`).pipe(
      map((resp: any) => resp.filter((item: any) => item.active !== status))
    );
  }

  getRoute(route_id) {
    return this.http.get(`${environment.url_api_v}routesTso/${route_id}`);
  }

  postRoutesTsoStops(data: any) {
    return this.http.post(`${environment.url_api_v}routesTsoStops`, JSON.stringify(data));
  }

  putRoutesTsoStops(data: any, id: any) {
    return this.http.put(`${environment.url_api_v}routesTsoStops/${id}`, JSON.stringify(data));
  }

  putRoutesTsoStopsMassive(data: any) {
    return this.http.put(`${environment.url_api_v}routesTsoStops/massive/update`, JSON.stringify(data));
  }

  getTsoRelations() {
    return this.http.get(`${environment.url_api_v}routes/tso/relations`);
  }

  putTsoAssignBlocks(data: any) {
    return this.http.put(`${environment.url_api_v}routesTsoStops/block/assign`, JSON.stringify(data));
  }

  putChangeDirection(data: any) {
    return this.http.put(`${environment.url_api_v}routesTsoStops/direction/update`, JSON.stringify(data));
  }

}
