import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ClientService } from '../../../../../services/services.index';
import * as moment from 'moment';
import { merge, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { TimecardsService } from './../../../../../services/payroll/timecards.service';
declare var $: any;

/**
  * Componente con filtros del timecard.
  * Este componente corresponde a los filtros de busqueda para el timecard; custom, previous... dentro de un periodo de tiempo.
  *
  * Principales funciones
  * * Definir filtros de busqueda para los timecards
*/
@Component({
  selector: 'app-search-timecard',
  templateUrl: './search-timecard.component.html',
  styleUrls: ['./search-timecard.component.scss']
})

export class SearchTimecardComponent implements OnInit {

  form: FormGroup;
  busy: Subscription[] = [];
  showAll = false;
  showExc = false;
  periodSearch: string;
  showDownload = false;
  clients: any = [];

  @Input() type: number;
  @Input() viewFilterExeptions: boolean;
  @Input() onlyFilterService: boolean;
  @Input() reportsClient: boolean;
  @Output() data? = new EventEmitter();
  @Output() dataGrid? = new EventEmitter();
  @Input() listClientsSelected: any;

  export = undefined;

  totals = {};

  typesPeriods = [
    {
      id: 'current',
      name: 'Current',
      text: 'Current Pay Period',
    },
    {
      id: 'previous',
      name: 'Previous',
      text: 'Previous Pay Period',
    },
    {
      id: 'asOf',
      name: 'Pay Period',
      text: 'Pay Period <br>',
    },
    {
      id: 'custom',
      name: 'Custom',
      text: 'Custom <br>',
    }
  ];
  dataDrivers = [];
  dataDriversFilt = [];

  maxDate = new Date();

  constructor(
    private fb: FormBuilder,
    private timecardsService: TimecardsService,
    private clientService: ClientService
  ) {
    this.initForm();
    this.maxDate.setDate(this.maxDate.getDate());
  }

  ngOnInit() {
    const thes = this;
    setTimeout(function(){
      const numWeekToDay = moment().isoWeek();
      thes.form.get('weeks').setValue(numWeekToDay, { emitEvent: true });
    }, 500);
    this.form.get('asdate').valueChanges.subscribe(res => {
      this.loadInfo();
    });
    this.form.get('dateRange').valueChanges.subscribe(res => {
      const start: any = moment(res[0]).format('MM-DD-YYYY');
      const end: any = moment(res[1]).format('MM-DD-YYYY');
      this.form.get('startDate').setValue(start);
      this.form.get('endDate').setValue(end);
      this.loadInfo();
    });
    merge(
      this.form.get('filter').valueChanges,
    ).subscribe(res => {
      this.showDownload = false;
      this.dataGrid.emit({ data: [] });
      this.loadInfo();
      this.renderTextSearch(res);
    });
    $('bs-daterangepicker-container').css({'left': '50px !important'});

    this.timecardsService.successDetailChanged$.subscribe(
      res => {
        this.loadInfo();
      }, err => {
        console.log(err);
      }
    );
    this.form.get('weeks').valueChanges.subscribe(res => {
      if (res && this.type === 2) {
        if (res.dateStart !== this.form.get('startDate').value) {
          this.form.get('filter').setValue('custom', { emitEvent: false });
          this.form.get('startDate').setValue(res.dateStart, { emitEvent: false });
          this.form.get('endDate').setValue(res.dateEnd, { emitEvent: false });
          this.loadInfo();
        }
      }
    });
    if (this.reportsClient) {
      this.onloadClients();
    }
  }

  renderTextSearch(type) {
    const text = this.typesPeriods.map(r => r.id).indexOf(type);
    this.periodSearch = this.typesPeriods[text].text;
  }

  initForm() {
    this.form = this.fb.group({
      filter: '',
      employee: '',
      dateRange: '',
      asdate: '',
      startDate: null,
      endDate: null,
      weeks: null
    });
  }

  renderByEmploye() {
    const dataForm = this.form.getRawValue();
    let list = this.dataDrivers.filter(r => r.employee_name.toLowerCase().indexOf(dataForm.employee.toLowerCase()) > -1);
    list = list.filter(r => this.showAll ? true : r.driver === undefined ? true : r.driver);
    list = list.filter(r => this.showExc ? r.has_exception : true);
    this.dataDriversFilt = list;
    this.data.emit({
      data: list,
      info: this.form.getRawValue(),
      totals: this.totals,
    });
  }

  loadInfo() {

    if ((this.type === 4 && this.export !== undefined) || this.type !== 4) {
      this.dataDrivers = [];
      this.renderByEmploye();
      const dataForm = this.form.getRawValue();
      this.renderTextSearch(dataForm.filter);

      if (
        dataForm.filter && (
          (dataForm.filter === 'custom' && dataForm.startDate && dataForm.endDate)
          || (dataForm.filter === 'asOf' && dataForm.asdate)
          || (dataForm.filter !== 'custom' && dataForm.filter !== 'asOf')
        )
      ) {
        let consulta;
        if (this.type === 1) {
          consulta = this.timecardsService.getListDrivers(
            dataForm.startDate,
            dataForm.endDate,
            dataForm.asdate,
            // dataForm.employee ? dataForm.employee.id : null,
            dataForm.filter
          );
        } else if (this.type === 2) {

          const ids = Object.assign([], this.listClientsSelected).map(r => String(r.id));

          consulta = this.timecardsService.getDashboardSchedule(
            dataForm.startDate,
            dataForm.endDate,
            dataForm.asdate,
            // dataForm.employee ? dataForm.employee.id : null,
            dataForm.filter,
            ids
          );
        } else if (this.type === 4) {
          consulta = this.timecardsService.getReportExcell(
            dataForm.startDate,
            dataForm.endDate,
            dataForm.asdate,
            // dataForm.employee ? dataForm.employee.id : null,
            dataForm.filter,
            this.export
          );
        }
        this.busy.push(consulta.subscribe(
          (res: any) => {
            if (this.type === 4) {
              if (this.export === 0) {
                const name = 'LSF_' + moment().format('YYYYMMDDHHmm');
                this.saveData(res, name);
                this.data.emit({
                  data: res,
                  info: this.form.getRawValue(),
                  totals: {},
                });
              } else {
                if (res.length > 0) {
                  this.dataGrid.emit({
                    data: res
                  });
                  this.showDownload = true;
                } else {
                  this.showDownload = false;
                }
              }
              this.export = undefined;
            } else {
              const start: any = moment(res.start_date).format('MM-DD-YYYY');
              const end: any = moment(res.end_date).format('MM-DD-YYYY');
              this.periodSearch = `${this.periodSearch} From ${start} To ${end}`;
              this.totals = {
                total_hours_regular: res.total_hours_regular,
                total_hours_o: res.total_hours_o,
                total_schedule_time: res.total_schedule_time,
                total_remaining_time: res.total_remaining_time,
              };
              this.dataDrivers = res.time_cards;
              this.renderByEmploye();
            }
          }, err => {
            Swal.fire('Error', err.error.message, 'error');
          }
        ));
      }
    }
  }

  onloadClients() {
    this.busy.push(
      this.clientService.getClientsByDriverManifest().subscribe((resp: any) => {
        this.clients = resp.data;
      })
    );
  }

  export_excel(option: any) {
    this.export = option;
    this.loadInfo();
  }

  saveData (data, fileName) {
    const a: any = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';

    const blob = new Blob([data], { type: 'application/vnd.ms-excel'}),
      url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  reportsClientEmitData() {
    const dataForm = this.form.getRawValue();
    if (
      this.reportsClient &&
      dataForm.filter &&
      ((dataForm.filter === 'custom' && dataForm.startDate && dataForm.endDate) ||
        (dataForm.filter === 'asOf' && dataForm.asdate) ||
        (dataForm.filter !== 'custom' && dataForm.filter !== 'asOf'))
    ) {
      this.busy.push(
        this.clientService
          .getReportByClient(dataForm.startDate, dataForm.endDate, dataForm.asdate, dataForm.filter, 1)
          .subscribe(resp => {
            this.dataGrid.emit({
              data: resp
            });
          })
      );
    }
  }

  get existsFilter() {
    return this.form.get('filter').value;
  }

}
