import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { GridOptions, GridReadyEvent } from 'ag-grid-community';
import { BsModalComponent } from 'ng2-bs3-modal';
import { AdminDataService } from '../driver.data.service';
import { map } from 'rxjs/operators';
import { SweetAlertHelper } from '../../../../../CORE/helpers/sweet-alert-helper.service';

@Component({
  selector: 'app-add-drivers',
  templateUrl: './add-drivers.component.html',
  styleUrls: ['./add-drivers.component.scss']
})
export class AddDriversComponent implements OnInit {
  @Input() date?: any;
  @Input() driver_manifest_id?: any;
  @ViewChild('modalAddDriver') modalAddDriver: BsModalComponent;
  busy: Subscription;
  gridDrivers: GridOptions;
  getContextMenuItems: any;
  drivers: any = [];
  newEditDriver: boolean;
  @Output() onReloadData? = new EventEmitter<any>();
  @Input() manifest_billed?: any;
  is_execute = false;

  constructor(
    private  adminDataService: AdminDataService, private sweetAlertHelper: SweetAlertHelper) {
    this.setConfigGrid();
  }

  ngOnInit() {
  }

  setConfigGrid() {
    this.gridDrivers = <GridOptions>{
      columnDefs: [
        { headerName: 'Seq', field: 'seq', width: 60, cellStyle: {
          textAlign: 'center'
          } },
        { headerName: 'Driver', field: 'driver_name', width: 100 },
        { headerName: 'Clock-in', field: 'clock_in', width: 90 },
        { headerName: 'Clock-out', field: 'clock_out', width: 90 },
        {
          headerName: '', field: 'principal', width: 50,
          cellRenderer: (params) => {
            return params.data.principal ?
              '<span class="label label-success" style="margin-top: 4px; position: absolute;"><i class="fa fa-check-circle" aria-hidden="true"></i></span>':
              '';
          }
        },
      ], onGridReady: (event: GridReadyEvent) => {
        this.loadData();
      },
      rowSelection: 'single'
    };

    this.getContextMenuItems = (params) => {
      const result = [
        {
          name: 'Edit',
          cssClasses: ['redFont', 'bold'],
          icon: '<i class="fa fa-edit"></i>',
          action: () => {
            this.newEditDriver = false;
            this.adminDataService.addDriver.next(false);
            this.adminDataService.setDataDriver.next({
              driver_name: params.node.data.driver,
              driver_id : params.node.data.driver_id,
              sundial_time_id: params.node.data.driver.sundial_time_id,
              actual_check_in :params.node.data.clock_in,
              actual_drop_off : params.node.data.clock_out,
          });
            this.modalAddDriver.open('sm');
          }
        },
      ];
      if (!params.node.data.principal) {
        result.push({
          name: 'Remove',
          cssClasses: ['redFont', 'bold'],
          icon: '<i class="fa fa-trash"></i>',
          action: () => {
            if (params.node.data.id) {
              this.sweetAlertHelper
                .createCustomAlert({
                  title: 'Are you sure?',
                  text: `You won't be able to revert this!`,
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                  if (result.value) {
                    this.busy = this.adminDataService.delete('driverManifestDetails/erase', params.node.data.id).subscribe((resp: any) => {
                      //validarlo
                      this.loadData();
                    });
                  }
                });
            } else {
              this.sweetAlertHelper.createCustomAlert({
                type: 'error',
                title: 'Error...',
                text: 'The main conductor cannot be removed.'
              });
            }

          }
        });

        result.push({
          name: 'Set Primary',
          cssClasses: ['redFont', 'bold'],
          icon: '<i class="fa fa-check-square-o"></i>',
          action: () => {
            const selectRow: any[] = this.gridDrivers.api.getSelectedNodes();
            if (selectRow.length === 1) {
              this.setPrimary(selectRow[0].data.id);
            } else {
              this.sweetAlertHelper.createCustomAlert({
                type: 'error',
                title: 'Error...',
                text: 'Please select a row'
              });
            }
          }
        });
      }
      return result;
    };
  }

  loadData() {
    if (this.driver_manifest_id) {
      this.busy = this.adminDataService.getData(`driverManifestDetails/byManifest/${this.driver_manifest_id}`)
        .pipe(map((item: any) => {
          let index = 1;
          item.forEach((data: any) => {
            data['seq'] = index;
            data['driver_name'] = `${data.driver.last_name} ${data.driver.first_name}`;
            index++;
          });
          return item;
        }))
        .subscribe((resp: any) => {
          if(resp) {
            this.gridDrivers.api.setRowData(resp);
            this.drivers = resp;
          }
        });
    } else {
      this.sweetAlertHelper.createCustomAlert({
        type: 'error',
        title: 'Oops...',
        text: 'Please reload the page!'
      });
    }
  }

  onAdd() {
    this.newEditDriver = true;
    this.adminDataService.addDriver.next(true);
    this.modalAddDriver.open('sm');
  }

  onSave (driverManifest: any) {
    if (!this.is_execute) {
      this.is_execute = true;
      this.saveData(driverManifest);
    }
  }
  saveData(driverManifest: any) {
      if (this.newEditDriver) {
        if (this.date && driverManifest.sundial_time_id && this.driver_manifest_id && driverManifest.driver_id) {
          const data = {
            'date': this.date,
            'sundial_time_id': driverManifest.sundial_time_id,
            'driver_manifest_id': this.driver_manifest_id,
            'driver_id': driverManifest.driver_id,
            'actual_check_in': driverManifest.actual_check_in,
            'actual_drop_off': driverManifest.actual_drop_off
          };
          this.busy = this.adminDataService.postDataNoResponse(data, 'driverManifestDetails/add').subscribe((resp: any) => {
            if (resp) {
              this.modalAddDriver.close();
              this.loadData();
              this.onReloadData.emit();
              this.adminDataService.saveSetPrimary.next(this.driver_manifest_id);
              this.is_execute = false;
            }
          }, error => {
            this.sweetAlertHelper.captureException(error.message);
            this.is_execute = false;
          });
        }
      } else {
        if (driverManifest.driver_id && driverManifest.actual_check_in && driverManifest.actual_drop_off && !this.newEditDriver) {
          const data = {
            'driver_manifest_id': this.driver_manifest_id,
            'driver_id': driverManifest.driver_id,
            'actual_check_in': driverManifest.actual_check_in,
            'actual_drop_off': driverManifest.actual_drop_off
          };
          this.busy = this.adminDataService.editDriverManifestDetails(data).subscribe((editDriverManifestDetailResp: any) => {
            if (editDriverManifestDetailResp) {
              this.modalAddDriver.close();
              this.loadData();
              this.onReloadData.emit();
              this.adminDataService.saveSetPrimary.next(this.driver_manifest_id);
            }
            this.is_execute = false;
          }, error => {
            this.sweetAlertHelper.captureException(error.message);
            this.is_execute = false;
          });
        } else {
          this.sweetAlertHelper.createCustomAlert({
            type: 'error',
            title: 'Oops...',
            text: 'Please reload the page!'
          });
          this.is_execute = false;
        }
      }
  }

  setPrimary(id: number) {
    this.busy = this.adminDataService.putDataNoResponse({id}, 'driverManifestDetails/principal/update')
      .subscribe((resp: any) => {
        if(resp === null) {
          this.loadData();
          this.onReloadData.emit();
          this.adminDataService.saveSetPrimary.next(this.driver_manifest_id);
        }
      });
  }

}
