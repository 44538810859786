export class Route {
  public id: number;
  public client_id: number;
  public division_id: number;
  public route_code: string;
  public description: string;
  public route_detail: Array<RouteDetail> = [];
}

export class RouteDetail {
  public driver_id: number;
  public week_day_id: number;
  public time_id: number;
}


/*export class RouteJSON {
    public id: number;
    public citie_id: number;
    public division_id: number;
    public route_code: string;
    public description: string;
    public status: number = 1;
    public routedetail: Array<Routedetail> = [];
}*/

/*export class Routedetail {
    id: number;
    route_id: number;
    driver_id: number;
    day_of_week_id: number;
    clock_in: string;
    start_time: string;
    end_time: string;
    clock_out: string;
    created_at: string;
    updated_at: string;
    driver: Driver;
}*/

export class Driver {
  id: number;
  last_name: string;
  first_name: string;
  mi?: any;
  ssn?: any;
  date_birth?: any;
  gender?: any;
  driver_license?: any;
  license_expiry?: any;
  email: string;
  stret_address: string;
  stret_address_two?: any;
  city: string;
  state: string;
  zip: string;
  phone: string;
  status: number;
  created_at: string;
  updated_at: string;
}


export class Day {
  day_numbre: number;
  driver_id: number;
  clock_in: string = '';
  start_time: string = '';
  end_time: string = '';
  clock_out: string = '';
}


export class TempRoutes {
  route_code: string;
  description: string;
  monday: string;
  tuesday: string;
  wednesday: string;
  thrusday: string;
  friday: string;
  saturday: string;
  sunday: string;
  routedetails: Array<TempRoutedetail> = new Array<TempRoutedetail>();
}

export class TempRoutedetail {
  route_code: string;
  description: string;
  monday: string;
  tuesday: string;
  wednesday: string;
  thrusday: string;
  friday: string;
  saturday: string;
  sunday: string;
}


/**
 * @class En esta clase se encuentran todos los parametros que usara el Objeto Client
 */
export class Client {
  id: number;
  client_name: string = '';
  contact: string = '';
  position: string = '';
  address_one: string = '';
  address_two: string = '';
  city: string = '';
  state: string = '';
  zip: string = '';
  active: number;
}

/**
 * @class En esta clase se encuentran todos los parametros que usara el Objeto Division
 */
export class Division {
  id: number;
  city_id: number;
  code: string;
  division_name: string;
  status: number;
  city: Array<any>;
}

/**
 * @class Clase para el manejo de Objetos de Tipo Time
 */
export class Time {
  id: number;
  time_type_id: number;
  section: string = '';
  report_time: string = '';
  start_time: string = '';
  end_time: string = '';
  punch_out_time: string = '';
  time_type: Array<any>;
}


/**
 * @class Clase para el manejo de Objetos de TimeType
 */
export class TimeTypes {
  id: number;
  description: string;
}
