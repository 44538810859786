import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, Subject, Observable } from 'rxjs/Rx';

export interface IInvoice {
  id: string;
  batch_id: string;
  invoice_num: string;
  invoice_date: Date | String;
  total_amount: number;
  invoice_status_id: string;
  invoice_status_description: string;
  user_name: string;
  fee_schedule_id?: number;
}

const batchInvoice: IInvoice = {
  id: '',
  batch_id: '',
  invoice_num: '',
  invoice_date: '',
  total_amount: 0,
  invoice_status_id: '',
  invoice_status_description: '',
  user_name: ''
}

@Injectable({
  providedIn: 'root'
})
export class BatchInvoicesService {
  loadGridData$ = new BehaviorSubject<boolean>(false);
  loadBatchInvoices$ = new BehaviorSubject<IInvoice | any>(batchInvoice);
  batchInvoiceRow$ = new BehaviorSubject<IInvoice>(batchInvoice);
  editInvoiceData$ = new BehaviorSubject<IInvoice>(null);
  batchId$ = new Subject<any>()
  getBatchHistoryInfo$ = new Subject<any>();
  save$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) { }

  get bacthInvoice$(): Observable<IInvoice> {
    return this.loadBatchInvoices$.asObservable();
  }

  get batchRow$(): Observable<IInvoice> {
    return this.batchInvoiceRow$.asObservable();
  }

  get getLoadGridData$(): Observable<boolean> {
    return this.loadGridData$.asObservable();
  }

  getBatchInvoice(batch_id: any) {
    return this.http.get<any>(`${environment.url_api_v}billing/batch-invoices/${batch_id}`)
  }

  addBatchInvoice(invoice: any) {
    return this.http.post<any>(`${environment.url_api_v}billing/batch-invoices`, JSON.stringify(invoice));
  }

  editBatchinvoice(invoice: IInvoice) {
    const id = invoice.id;
    return this.http.put<any>(`${environment.url_api_v}billing/batch-invoices/${id}`, JSON.stringify(invoice));
  }


}
