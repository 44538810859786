/**
 * Created Diciembre 2017
 * @author Miguel Lopez Ariza
 * @description Componente para controlar la View de Vehicles, cuenta con metodos que son llamados cuando los botones de
 * accion son activados, y carga la informacion requerida del servicio encargado de gestionar los Vehicles
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { PermissionService, VehicleService } from '../../../services/services.index';
import AgGridHelpers from '../../../helpers/ag-grid-helpers';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss']
})
export class VehiclesComponent implements OnInit, OnDestroy {

  busy: Subscription[] = [];

  addVehicle = false;
  inspectios = false;
  enabledBtn = false;
  showStatus = false;
  vehicleSekected = 0;
  dataLength: number;
  titleCountGrid: string;
  savedModel: any;
  gridVehicles: GridOptions;
  vehiclesGps = [];

  constructor(private serviceVehicle: VehicleService, private permissionService: PermissionService) {
    this.gridVehicles = <GridOptions>{
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      rowSelection: 'single',
      enableFilter: true,
      onGridReady: () => {
        this.getAllVehicles(this.showStatus);
      },
      onFilterModified: () => {
        this.setCounter();
      }
    };

    this.gridVehicles.columnDefs = [
      { headerName: 'Description', field: 'description', width: 80 },
      { headerName: 'RTA', field: 'rta', width: 70 },
      { headerName: 'Tag', field: 'tag', width: 70 },
      { headerName: 'Billing Desc.', field: 'desc_billing', width: 100 },
      { headerName: 'Type', valueGetter: this.vehicleType, width: 80 },
      { headerName: 'GPS', field: 'gps_id', width: 60, cellStyle: {'text-align': 'center'},
        cellRenderer: (params) => params.data.gps_id !== '' ? AgGridHelpers.iconRecord(params.data.gps_id, 'fa-globe') : '' },
      { headerName: 'GPS APC', field: 'gps_apc_id', width: 88, cellStyle: {'text-align': 'center'},
        cellRenderer: (params) => params.data.gps_apc_id !== '' ? AgGridHelpers.iconRecord(params.data.gps_apc_id, 'fa-users') : ''
      },
      { headerName: 'TRACCAR DEVICE', field: 'traccar_device', width: 140, cellStyle: {'text-align': 'center'},
        cellRenderer: (params) => params.data.gps_apc_id !== '' ? AgGridHelpers.iconRecord(params.data.traccar_device, 'fa-location-arrow') : ''
      },
      { headerName: 'Active', width: 80, cellRenderer: (params) => AgGridHelpers.statusRecord(params.data.active) },
      { headerName: 'Status', valueGetter: this.vehicleStatus, width: 100 }
    ];
  }

  ngOnInit() {
    this.getVehicleGps();
  }

  ngOnDestroy() {
    this.busy.forEach(subscription => subscription.unsubscribe());
  }

  vehicleType(row: any) {
    return row.data.vehicletypes.description;
  }

  vehicleStatus(row: any) {
    return row.data.vehiclestatus ? row.data.vehiclestatus.description : '';
  }

  onCellClicked(event: any) {
    this.vehicleSekected = event.data.id;
  }

  OnCellDoubleClicked(event: any) {
    this.permissionService.validatePermission('btns-vehicles').then((resp: boolean) => {
      if (resp) {
        this.vehicleSekected = event.data.id;
        this.onEditVehicle();
        this.aplicarFiltro();
      }
    });
  }

  /***
   * @description
   * @param data
   */
  onNotify(data: any) {
    this.getAllVehicles(this.showStatus);
  }

  /***
   * @description
   */
  onNewVehicle() {
    this.vehicleSekected = 0;
    this.changeStateModal();
  }

  /***
   * @description
   */
  onEditVehicle() {
    this.vehicleSekected === 0 ? Swal.fire('Please, check a row') : this.changeStateModal();
  }

  /***
   * @description
   */
  changeStateModal() {
    this.addVehicle = !this.addVehicle;
    this.enabledBtn = !this.enabledBtn;
  }

  /***
   * @description
   */
  changeStateModalInspector() {
    this.inspectios = !this.inspectios;
    this.enabledBtn = !this.enabledBtn;
  }

  /***
   * @description
   */
  onInspectionsVehicle() {
    this.vehicleSekected = 0;
    this.changeStateModalInspector();
  }

  /***
   * @description
   */
  aplicarFiltro() {
    this.savedModel = this.gridVehicles.api.getFilterModel();
  }

  /***
   * @description
   */
  restoreFilterModel() {
    this.gridVehicles.api.setFilterModel(this.savedModel);
    this.gridVehicles.onFilterChanged = function (params) {
    };
  }

  /***
   * @description
   * @param {boolean} status
   */
  changeShowStatus(status: boolean) {
    this.getAllVehicles(status);
    this.showStatus = status;
  }

  /***
   * @description
   * @param {boolean} status
   */
  getAllVehicles(status: boolean) {
    this.busy.push(this.serviceVehicle.getVehiclesStatus(status).subscribe(
      (response: any) => {
        this.gridVehicles.api.setRowData(response);
        this.dataLength = this.gridVehicles.api.getDisplayedRowCount();
        this.setCounter();
        this.restoreFilterModel();
      }
    ));
  }

  private setCounter() {
    const cant = this.gridVehicles.api.getDisplayedRowCount();
    this.titleCountGrid = `Vehicles ${cant} of ${this.dataLength}`;
  }

  onSyncGPS(): void {
    this.busy.push(this.serviceVehicle.syncGPS().subscribe((resp: any) => {
      this.getAllVehicles(this.showStatus);
    },
      (error: any) => {
        Swal.fire({
          type: 'warning',
          title: 'Oops...',
          text: error.error.message
        }).then();
      }
    ));
  }

  getVehicleGps() {
    this.busy.push(this.serviceVehicle.getVehicleGps().subscribe((resp: any) => {
      this.vehiclesGps = resp.data;
    },
    (error: any) => {
      Swal.fire({
        type: 'warning',
        title: 'Oops...',
        text: error.error.message
      }).then();
    }
    ));
  }


}
