import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StopsRoutingModule } from './stops-routing.module';
import { StopsComponent } from './stops/stops.component';
import { SharedModule } from '../../shared/shared.module';
import { ThemeModule } from '../../theme/theme.module';
import { BsModalModule } from 'ng2-bs3-modal';
import { AgGridModule } from 'ag-grid-angular/main';
import { NgBusyModule } from 'ng-busy';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddStopComponent } from './stops/add-stop/add-stop.component';
import { MapModule } from '../../components/map/map.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    StopsRoutingModule,
    SharedModule,
    ThemeModule,
    BsModalModule,
    AgGridModule.withComponents([]),
    NgBusyModule,
    MapModule
  ],
  declarations: [
    StopsComponent,
    AddStopComponent
  ],
  exports: [
    StopsComponent,
    AddStopComponent
  ]
})
export class StopsModule { }
