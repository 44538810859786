import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CONSTANTS } from '../../app.constants';

export interface IIncident {
  id: number;
  description: string;
  categorie_incident_id: number;
  detail: string;
}

export interface ICategorie {
  id: number;
  description: string;
  incidents: IIncident[];
}


@Injectable({
  providedIn: 'root'
})
export class InterruptionCategoriesService {

  protected resource: string;

  listCategories: ICategorie[];

  dataEdit = new Subject<ICategorie>();

  constructor(private http: HttpClient) {
    this.resource = environment.url_api_v + CONSTANTS.CATEGORIES_INCIDENTS;
  }

  getInterruptionCategories(): Observable<ICategorie[]> {
    return this.http.get<ICategorie[]>(this.resource);
  }

  getInterruptionSubCategories(): Observable<any[]> {
    return this.http.get<any>(`${environment.url_api_v}categoriesIncidents/subcategories/list`);
  }

  postInterruptionCategories(data: ICategorie): Observable<any> {
    return this.http.post<any>(this.resource, JSON.stringify(data));
  }

  putInterruptionCategories(data: ICategorie): Observable<any> {
    return this.http.put<any>(`${this.resource}/${data.id}`, JSON.stringify(data));
  }

  getListSubcategories(category_id: number) {
    return this.http.get<any>(`${environment.url_api_v}subcategories/listForCategory/${category_id}`);
  }

  postSubcategories(data: any) {
    return this.http.post<any>(`${environment.url_api_v}subcategories`, JSON.stringify(data));
  }

  putSubcategories(data: any) {
    return this.http.put<any>(`${environment.url_api_v}subcategories/${data.id}`, JSON.stringify(data));
  }

  getItemForSubcategory(subcategory_id: number) {
    return this.http.get<any>(`${environment.url_api_v}subcategories/itemForSubcategory/${subcategory_id}`);
  }

  postItemForSubcategory(data: any) {
    return this.http.post<any>(`${environment.url_api_v}subcategories/itemSubcategory`, JSON.stringify(data));
  }

  putItemForSubcategory(data: any) {
    return this.http.put<any>(`${environment.url_api_v}subcategories/itemSubcategory/${data.id}`, JSON.stringify(data));
  }

}
