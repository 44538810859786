import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PagesSmartComponent} from '../../../theme/components/pages-smart/pages-smart.component';
import {AuthGuard} from '../../../_guards/auth.guard';
import {NgxPermissionsGuard} from 'ngx-permissions';
import { WorkflowTabComponent } from './workflow-tab.component';
const routes: Routes = [
  {
    path: 'billing',
    component: PagesSmartComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'workflow',
        component: WorkflowTabComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          title: 'Workflow'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkflowRoutingModule { }
