import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CellDoubleClickedEvent, GridOptions } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { Subscription } from 'rxjs-compat';
import { InvoiceReportService } from '../../invoice-report.service';
import { SweetAlertHelper } from '../../../../../../CORE/helpers/sweet-alert-helper.service';
import { BsModalComponent } from 'ng2-bs3-modal';
import { takeUntil } from 'rxjs/operators';
import { IResponse, ResponseStatus } from '../../../../../../CORE/interfaces/response.interface';
import { IBatchCredit } from '../../../../../../CORE/interfaces/invoice-report.interface';
import { SweetAlertResult } from 'sweetalert2';
import AgGridHelpers from '../../../../../../helpers/ag-grid-helpers';

@Component({
  selector: 'app-credits-grid',
  templateUrl: './credits-grid.component.html',
  styleUrls: ['./credits-grid.component.scss']
})
export class CreditsGridComponent implements OnInit, OnDestroy {
  @ViewChild('modalAddEditCredit') modalAddEditCredit: BsModalComponent;

  gridCredits: GridOptions;
  destroy: Subject<boolean> = new Subject<boolean>();
  busy: Subscription [] = [];

  batchId: number;
  addOrEditCredit: string;
  showStatusCreditReverse = false;
  haveRevisedCharge: boolean;

  constructor(
    private invoiceReportService: InvoiceReportService,
    private sweetAlertHelper: SweetAlertHelper
  ) {
    this.initGrid();
  }

  ngOnInit(): void {
    this.makeSubscriptions();
  }

  private initGrid(): void {
    this.gridCredits = <GridOptions>{
      enableFilter: true,
      enableSorting: true,
      enableColResize: true,
      columnDefs: [
        {headerName: 'Charge Num', field: 'invoice_num', width: 140 },
        {headerName: 'Credit Num', field: 'credit_num'},
        {headerName: 'Type Credit', field: 'batch_credit_type_description'},
        {headerName: 'Date Paid', field: 'paid_date'},
        {
          headerName: 'Paid',
          field: 'paid',
          cellRenderer: params => AgGridHelpers.CurrencyCellRenderer(params),
          cellStyle: {textAlign: 'right'},
        },
        {headerName: 'User', field: 'user_name'},
        {
          colId: 'deleteRow',
          cellRenderer: (params) => this.drawTrash(params),
          width: 70
        }
      ],
      onGridReady: (): void => {
        this.gridCredits.api.sizeColumnsToFit();
        this.gridCredits.api.setRowData([]);
      },
      onCellDoubleClicked: (event: CellDoubleClickedEvent) => this.sendDataToEditCredit(event)
    };
  }

  private makeSubscriptions(): void {
    this.invoiceReportService.batchIdAction$
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (batchId: number): void => {
          if (batchId) {
            this.batchId = batchId;
            this.getCredits(this.batchId);
          }
        }
      });

    this.invoiceReportService.reloadCreditGridAction$
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (reload: boolean): void => {
          if (reload) {
            this.modalAddEditCredit.close().then(() => this.getCredits(this.batchId));
          }
        }
      });

    this.invoiceReportService.haveBatchChargeRevisedAction$
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (haveRevisedCredit: boolean) => this.haveRevisedCharge = haveRevisedCredit
      });
  }

  private drawTrash(params): string {
    const { is_active }: IBatchCredit = params.data;

    return Number(is_active)
      ? '<span <i class="fa fa-trash-o" style="color: red; cursor: pointer;" aria-hidden="true"></i></span>'
      : null;
  }

  private getCredits(batchID: number, active: number = 1): void {
    this.busy.push(
      this.invoiceReportService.getCreditChargeBatch(batchID, active)
        .subscribe({
          next: ({data, status}: IResponse<IBatchCredit[]>): void => {
            if (status === ResponseStatus.SUCCESS) {
              this.gridCredits.api.setRowData(data);
            }
          },
          error: error => this.sweetAlertHelper.captureException(error)
        })
    );
  }

  private sendDataToEditCredit(event: CellDoubleClickedEvent): void {
    const { data } = event;
    const { is_active }: IBatchCredit = data;

    if (Number(is_active)) {
      this.addOrEditCredit = 'Edit';
      this.modalAddEditCredit.open().then(() => this.invoiceReportService.setCreditDateToEdit(data));
    }
  }

  openModalAddEditCredit(): void {
    if (!this.haveRevisedCharge) {
      this.sweetAlertHelper.createCustomAlert({
        type: 'warning',
        title: `There must be a charge created as revised`,
        showConfirmButton: true
      }).then();

      return;
    }

    this.addOrEditCredit = 'Create';
    this.invoiceReportService.setCreditDateToAdd(this.batchId);
    this.modalAddEditCredit.open().then();
  }

  onSelectButton(params: any): void {
    const { colDef, data } = params;
    const { is_active }: IBatchCredit = data;

    if (colDef.colId === 'deleteRow' && Number(is_active)) {
      this.sweetAlertHelper.createCustomAlert({
        type: 'info',
        title: 'Are you sure?',
        text: 'Disable this credit?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result: SweetAlertResult): void => {
        if (result.value) {
          this.onDeleteRow(params.data);
        }
      });
    }
  }

  private onDeleteRow(rowData: IBatchCredit): void {
    const {id: creditID} = rowData;

    this.busy.push(
      this.invoiceReportService.reverseCreditBatch(creditID)
        .subscribe({
          next: ({status, data}: IResponse<number>): void => {
            if (status === ResponseStatus.SUCCESS && data === 1) {
              this.invoiceReportService.reloadCreditGrid(true);
            }
          },
          error: err => this.sweetAlertHelper.captureException(err)
        })
    );
  }

  toggleReverse($event): void {
    $event ? this.getCredits(this.batchId, 0) : this.getCredits(this.batchId);
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
    this.busy.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  get cantAddCredits(): boolean {
    return !this.batchId;
  }
}
