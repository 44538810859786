import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { GridOptions } from 'ag-grid-community';
import { InvoiceService } from '../../../../services/billing/invoice.service';
import { takeUntil } from 'rxjs/operators';
import { BsModalComponent } from 'ng2-bs3-modal';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { ReportsManageService } from '../../../../services/reports-manage.service';

@Component({
  selector: 'app-buses-fueled-week',
  templateUrl: './buses-fueled-week.component.html',
  styleUrls: ['./buses-fueled-week.component.scss']
})

export class BusesFueledWeekComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  private destroy: Subject<boolean> = new Subject<boolean>();
  @ViewChild('modalFueled') modalFueled: BsModalComponent;

  @Input() onClearGrid? = false;
  busy: Subscription[] = [];
  gridLeft: GridOptions;
  gridRight: GridOptions;
  dataParams: any = {};
  getContextMenuItems;
  bus_fueled = null;

  constructor(private invoiceService: InvoiceService, private reportsManageService: ReportsManageService) {
    this.initGridLeft();
    this.initGridRight();
  }

  ngOnInit() {
    this.invoiceService.load_buses$.pipe(takeUntil(this.destroy)).subscribe(res => {
      this.dataParams = res;
      this.onSearch();
    });
  }

  ngAfterViewInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['onClearGrid']) {
      if (this.onClearGrid && this.gridLeft.api && this.gridRight) {
        this.gridLeft.api.setRowData([]);
        this.gridRight.api.setRowData([]);
      }
    }
  }

  ngOnDestroy() {
    this.destroy.next(true);
    this.destroy.unsubscribe();
    return (this.busy = []);
  }

  getRowStyle(params: any): any {
    if (params.data.count_import === params.data.count_saved) {
      return {
        'background-color': '#4ea93b;',
      };
    } else if (params.data.count_import < params.data.count_saved) {
      return {
        'background-color': '#ff4040;',
      };
    }
  }

  initGridLeft() {
    this.gridLeft = <GridOptions> {
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      rowSelection: 'single',
      enableRangeSelection: true,
      enableFilter: true,
      columnDefs: [
        {
          headerName: 'No.',
          field: '',
          cellRenderer: params => {
            return params.rowIndex + 1;
          },
          width: 50
        },
        {
          headerName: 'Day - Date Fueled',
          field: 'date',
          sort: 'asc',
          cellRenderer: params => {
            return moment(params.data.date, 'YYYY-MM-DD hh:mm:ss').format('dddd, DD MMMM YYYY');
          },
          width: 220
        },
        { headerName: 'Vehicle', field: 'vehicle.description', width: 100 },
        {
          colId: 'deleteRow',
          cellRenderer: () => {
            return '<span <i class="fa fa-trash-o" style="color: red; cursor: pointer;" aria-hidden="true"></i></span>';
          },
          width: 50
        }
      ],
      onGridReady: () => {
        this.gridLeft.api.setRowData([]);
      }
    };
    this.getContextMenuItems = () => {
      return [
        {
          name: 'Add Bus Fueled',
          icon: '<i class="fa fa-plus"></i>',
          action: () => {
            this.bus_fueled = null;
            this.modalFueled.open('sm');
          }
        },
        {
          name: `Bus Didn't Fuel`,
          icon: '<i class="fa fa-bus" aria-hidden="true"></i>',
          action: () => {
            this.printFueledReport();
          }
        },
        'separator',
        'copy',
        'export'
      ];
    };
  }

  initGridRight() {
    this.gridRight = <GridOptions> {
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      rowSelection: 'multiple',
      enableFilter: true,
      columnDefs: [
        {
          colId: 'id',
          width: 40,
          checkboxSelection: true,
          headerCheckboxSelection: true
        },
        {
          headerName: 'No.',
          field: '',
          cellRenderer: params => {
            return params.rowIndex + 1;
          },
          width: 50
        },
        {
          headerName: 'Day - Date Fueled',
          sort: 'asc',
          field: 'date',
          cellRenderer: params => {
            return moment(params.data.date, 'YYYY-MM-DD hh:mm:ss').format('dddd, DD MMMM YYYY');
          },
          width: 220
        },
        { headerName: 'Vehicle', field: 'vehicle', width: 110 },
        { headerName: 'Route', field: 'route', width: 110 },
        { headerName: 'Block', field: 'block', width: 110 },
        { headerName: 'Fuel site', field: 'fuel_site', width: 110 },
        { headerName: 'Issue qty', field: 'issue_qty', width: 90 },
        { headerName: 'Fuel type', field: 'fuel_type', width: 90 },
        { headerName: 'Import count', field: 'count_import', width: 110 },
        { headerName: 'Saved count', field: 'count_saved', width: 110 },
      ],
      onGridReady: () => {
        this.gridLeft.api.setRowData([]);
      }
    };
  }

  onRowDoubleClicked($event) {
    this.bus_fueled = $event;
    this.modalFueled.open('sm');
  }

  onSelectButton(params: any) {
    if (params.colDef.colId === 'deleteRow') {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Disable this record?',
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then(result => {
        if (result.value) {
          this.onDeleteRow(params.data);
        }
      });
    }
  }

  onRowDoubleClickedRightGrid() {
    const data = this.getSelectedRows();
    Swal.fire({
      title: 'Are you sure?',
      type: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then(result => {
      if (result.value) {
        if (data) {
          this.onSendLeftGrig(data);
        }
      }
    });
  }

  onSearch() {
    const data: any = Object.assign({}, this.dataParams);
    const info = {
      start_date: data.start_date,
      end_date: data.end_date,
      route_id: data.routes_id
    };
    if (info.route_id && info.start_date && info.end_date) {
      this.onLoadBusesFueledWeek(info);
      this.onLoadBusesFueledblocksAndImport(info);
    }
  }

  onSave() {
    this.bus_fueled = null;
    this.modalFueled.close();
    this.onSearch();
  }

  getSelectedRows() {
    const rows = [];
    this.gridRight.api.forEachNodeAfterFilter(function(data) {
      if (data['selected']) {
        const row = {
          vehicle_id: data.data.vehicle_id,
          route_id: data.data.route_id,
          date: data.data.date
        };
        rows.push(row);
      }
    });
    return rows;
  }

  addSendLeftGrig() {
    const data = this.getSelectedRows();
    Swal.fire({
      title: 'Are you sure?',
      type: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then(result => {
      if (result.value) {
        if (data) {
          this.onSendLeftGrig(data);
        }
      }
    });
  }

  onSendLeftGrig(data: any[]) {
    this.busy.push(
      this.invoiceService.saveBusesBusesFueledArray(data).subscribe(
        () => {
          Swal.fire('Successfull', 'Vehicle fueled shipped successfully', 'success');
          this.gridRight.api.deselectAll();
          this.onSearch();
        },
        error => {
          Swal.fire('Error', error.message, 'error');
        }
      )
    );
  }

  onLoadBusesFueledWeek(data: any) {
    this.busy.push(
      this.invoiceService.getBusesFueledWeek(data).subscribe(
        resp => {
          const list = Object.assign([], resp.data);
          if (this.gridLeft.api) {
            this.gridLeft.api.setRowData(list);
          }
        },
        error => {
          Swal.fire('Error', error.message, 'error');
        }
      )
    );
  }

  onLoadBusesFueledblocksAndImport(data: any) {
    this.busy.push(
      this.invoiceService.getBusesFueledblocksAndImport(data).subscribe(
        resp => {
          const list = Object.assign([], resp.data);
          if (this.gridLeft.api) {
            this.gridRight.api.setRowData(list);
          }
        },
        error => {
          Swal.fire('Error', error.message, 'error');
        }
      )
    );
  }

  onDeleteRow(data: any) {
    this.busy.push(
      this.invoiceService
        .updateBusesFueledWeek({
          id: data.id,
          vehicle_id: data.vehicle_id,
          route_id: data.route_id,
          date: data.date,
          comments: data.comments,
          active: false
        })
        .subscribe(
          () => {
            Swal.fire('Successfull', 'Vehicle fueled disabled successfully', 'success');
            this.onSearch();
          },
          error => {
            Swal.fire('Error', error.message, 'error');
          }
        )
    );
  }

  get disabledBtn(): boolean {
    if (this.gridRight.api) {
      return !!this.gridRight.api.getSelectedNodes().length;
    }
  }

  printFueledReport() {
    const parameter: any = {
      client_id: [`${this.dataParams.client_id}`],
      date: [`${this.dataParams.start_date}`, `${this.dataParams.end_date}`]
    };
    this.busy.push(
      this.reportsManageService.getReportTrip2(6172, parameter).subscribe((resp: string) => {
        this.reportsManageService.loadReportWindows(resp);
      })
    );
  }
}
