import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as L from 'leaflet';
import { ReverseService } from '../services/reverse.service';
import { IPlace } from '../services/interfaces';
declare const google: any;

@Component({
  selector: 'app-leaflet',
  templateUrl: './leaflet.component.html',
  styleUrls: ['./leaflet.component.scss']
})
export class LeafletComponent implements OnInit, AfterViewInit, OnChanges {

  private map: L.Map;
  private marker: any;
  @Output('getData') getData? = new EventEmitter<IPlace>();
  @Input('setData') setData?: any = new EventEmitter<any>();

  myIcon = L.icon({
    iconUrl: 'assets/img/marker_1.png',
    iconSize: [38, 38],
  });

  constructor(private reverseService: ReverseService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.initMap();
    this.onMapReady(this.map);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['setData'] && this.setData) {
      setTimeout(() => {
        const latlng = L.latLng({
          lat: this.setData.latitude, lng: this.setData.longitude
        });
        this.onClick({latlng}, true);
      }, 800);
    }
  }

  private initMap() {
    this.map = L.map('map').setView([25.7742691, -80.1936569], 13);
    L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWF0aGl3cyIsImEiOiJja20yNnB4cWMwMXBhMm9wZ2l6M2Zxajk3In0.0SkYKscswdRRKzulnABbtw', {
      maxZoom: 20,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    }).addTo(this.map);
    this.map.zoomControl.setPosition('bottomright');
    this.map.on('click', (e: any) => this.onClick(e));
  }

  onMapReady(map: L.Map) {
    setTimeout(() => {
      map.invalidateSize();
    }, 0);
  }

  onClick(e: any, update = false) {
    if (this.marker) {
      this.map.removeLayer(this.marker);
    }
    this.marker = L.marker(e.latlng, {
      icon: this.myIcon,
      draggable: true
    });
    this.marker.on('dragend', (env: any) => this.setValuesForm(env.target._latlng));
    this.marker.addTo(this.map);
    if (!update) {
      this.setValuesForm(e.latlng);
    }

    this.map.setView(e.latlng, 16);
  }

  setValuesForm(latlng: any) {
    /*this.reverseService.reverse(latlng).subscribe((resp: IPlace) => {
      this.getData.emit(resp);
    });*/
    this.getReverseGeocodingData(latlng);
  }

getDataPlace(event) {
    // console.log('place : ',event);
    const lat = event.geometry.location.lat();
    const lng = event.geometry.location.lng();
    this.setData = {
      latitude: lat,
      longitude: lng
    };
    const latlng = L.latLng({
      lat: this.setData.latitude,
      lng: this.setData.longitude
    });
    this.onClick({latlng}, false);
}

getReverseGeocodingData(latlngIn: any) {
    const latlng = new google.maps.LatLng(latlngIn.lat, latlngIn.lng);
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'latLng': latlng },  (results, status) =>{
        if (status !== google.maps.GeocoderStatus.OK) {
            alert(status);
        }
        if (status === google.maps.GeocoderStatus.OK) {
            // console.log('Geocodin', results);
            const address = (results[0].formatted_address);
            const place_id = (results[0].place_id);
            const long_name = (results[0].address_components[0].long_name);
            // console.log(results)
            geocoder.geocode({'placeId': place_id}, (result) => {
              this.reverseService.getProperties(result[0]).then((data: any) => {
                const location: any = {
                  address: {
                    long_name: long_name,
                    road: address,
                    city: data.city,
                    state: data.state,
                    postcode: data.zip
                  },
                  'lat': latlngIn.lat,
                  'lon': latlngIn.lng
                };
                this.getData.emit(location);
              });
            });
        }
    });
}


}
