import { Injectable, EventEmitter } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Configuration } from '../../app.configuration';
import { AuthService } from '../../services/auth.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DashboardLsfDataService {

  private _serviceAuth: AuthService;
  dataFull: any[];

  viewSsheduleSelected$ = new EventEmitter<any>();

  constructor( protected consant: Configuration, protected http: HttpClient ) {
  }

  getData(resource: string) {
    return this.http.get(environment.url_api_v + resource);
  }

  postData(data: any, resource: string) {
    const bodyString = JSON.stringify(data);
    return this.http.post(environment.url_api_v + resource, bodyString);
  }

  putData(data: any, resource: string) {
    const bodyString = JSON.stringify(data);
    return this.http.put(environment.url_api_v + resource, bodyString);
  }

  putDataEdit(id: string, data: any, resource: string) {
    return this.http.put(environment.url_api_v + resource + '/' + id, data);
  }

  putDataNoBody(resource: string) {
    const bodyString = JSON.stringify({});
    return this.http.put(environment.url_api_v + resource, bodyString);
  }

  getScheduleDrivers(scheduleId: number, clientId: number) {
    return this.http.get(`${environment.url_api_v}schedules/scheduleDrivers/${scheduleId}/${clientId}`);
  }

  getScheduleDriver(driver: any, datei: any, datef: any) {
    return this.http.get(`${environment.url_api_v}drivers/schedule/${driver}/${datei}/${datef}`);
  }

  getLastScheduleUser() {
    return this.http.get(`${environment.url_api_v}schedules/lastSchedule/byUser`);
  }
}
