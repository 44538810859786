export class KeyPress {
  /**
   * @description esta funcion permite que un input text solo se pueda ingresar texto
   * @param event
   * @private
   */
  _TextOnly(event: any) {
    const pattern = /[A-Za-z\s]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
