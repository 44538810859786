import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { GridOptions, GridReadyEvent } from 'ag-grid-community';
import { InterruptionService } from '../../services/interruption.service';
import { TaModalComponent } from '../ta-modal/ta-modal.component';

@Component({
  selector: 'app-interruptions-grid',
  templateUrl: './interruptions-grid.component.html',
  styleUrls: ['./interruptions-grid.component.scss']
})
export class InterruptionsGridComponent implements OnInit {

  @ViewChild('details') details: TaModalComponent;

  busy: Subscription;
  gridInterruptions: GridOptions;
  schedule_detail_id: number;

  constructor(private interruptionService: InterruptionService) {
    this.setGrid();
  }

  ngOnInit() {
  }

  setGrid() {
    this.gridInterruptions = <GridOptions>{
      enableSorting: true,
      sortingOrder: ['desc', 'asc', null],
      onGridReady: (event: GridReadyEvent) => {
        this.getInterruptions();
      }
    };
    this.gridInterruptions.columnDefs = [
      { headerName: 'Date of Service', field: 'dateofservice', width: 130 },
      { headerName: 'Time', field: 'time', width: 90 },
      { headerName: 'Details/Resolution', field: 'details', width: 260 },
      { headerName: 'Odometer', field: 'odometer', width: 90 },
      { headerName: 'Driver', field: 'driver', width: 140 },
      { headerName: 'Vehicle', field: 'vehicle', width: 100 }
    ];
  }

  onCellDoubleClicked(event: any) {
    this.schedule_detail_id = event.data.id;
    this.details.open();
  }

  getInterruptions() {
    this.busy = this.interruptionService.getListInterruption(3).subscribe((resp: any) => {
      this.gridInterruptions.api.setRowData(resp.data);
    });
  }

}
