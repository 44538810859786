import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class LoginDataService {

  constructor( protected http: HttpClient ) {
  }

  getData(resource: string) {
    return this.http.get(`${environment.url_api_v}${resource}`);
  }

  postData(data: any, resource: string) {
    const bodyString = JSON.stringify(data);
    return this.http.post(`${environment.url_api_v}${resource}`, bodyString);
  }

  putData(data: any, resource: string) {
    const bodyString = JSON.stringify(data);
    return this.http.put(`${environment.url_api_v}${resource}`, bodyString);
  }

}
