import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Rx';
import { FilterChangedEvent, GridOptions, RowSelectedEvent } from 'ag-grid-community';
import { InvoiceService } from '../../../services/billing/invoice.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { BsModalComponent } from 'ng2-bs3-modal';
import AgGridHelpers from '../../../helpers/ag-grid-helpers';
import { BatchesService } from '../../../services/billing/batches.service';
import { PermissionService } from '../../../services/admin/permission.service';
import { SweetAlertHelper } from '../../../CORE/helpers/sweet-alert-helper.service';

@Component({
  selector: 'app-unprocessed-block',
  templateUrl: './unprocessed-block.component.html',
  styleUrls: ['./unprocessed-block.component.scss']
})
export class UnprocessedBlockComponent implements OnInit, OnDestroy {

  @ViewChild('modalManifest') modalManifest: BsModalComponent;
  @Output() changeTabRuns? = new EventEmitter<any>();
  @Output() count_row_total? = new EventEmitter<number>();

  busy: Subscription;
  grid: GridOptions;
  billing_status: any = [];
  count_row = 0;
  form: FormGroup;
  dataFilter: any;
  disableButton = true;
  columnDefs: any = [
    { headerName: 'Date', field: 'date', width: 90 },
    { headerName: 'Block', field: 'block', width: 80 },
    { headerName: 'Block Description', field: 'block_desc', width: 160 },
    { headerName: 'Manifest', field: 'manifest', width: 100 }
  ];
  manifest_id: number;
  dateOfService: any;
  schedule_detail_id: number;
  getContextMenuItems: any;
  btnVerifyShow = false;
  manifestBilled = false;

  constructor(private fb: FormBuilder, private invoiceService: InvoiceService,
              private permissionService: PermissionService,
              private sweetAlertHelper: SweetAlertHelper,
              private batchesService: BatchesService) {
    this.initGrid();
    this.initForm();
  }

  ngOnInit() {
    this.getBillingStatus();
    this.invoiceService.load_unprocessed_block$.subscribe(resp => {
      this.dataFilter = resp;
      this.getUnproccesed();
    });

    this.form.valueChanges.subscribe((resp: any) => {
      if (this.dataFilter) {
        this.getUnproccesed();
      } else {
        this.sweetAlertHelper.createCustomAlert({ title: 'Select a filter from the header' });
      }
    });

  }

  ngOnDestroy(): void {
    this.busy ? this.busy.unsubscribe() : null;
  }

  initGrid() {
    this.grid = <GridOptions>{
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      rowSelection: 'multiple',
      enableFilter: true,
      onGridReady: () => {
        this.grid.api.setRowData([]);
      },
      onFilterChanged: (event: FilterChangedEvent) => {
        this.count_row = this.grid.api.getDisplayedRowCount();
        this.count_row_total.emit(this.count_row);
      },
      onRowSelected: (event: RowSelectedEvent) => {
        if (Number(this.form.controls['billing_status_id'].value) === 1) {
          this.disableButton = this.grid.api.getSelectedNodes().length > 0 ? false : true;
          this.manifest_id = undefined;
        } else {
          this.disableButton = true;
        }
      }
    };
    this.getContextMenuItems = (params) => {
      const x = this.grid;
      const result = [
        AgGridHelpers.itemMenu('Manifest', 'fa-file-text', () => {
          this.onRowDobuleClicked(params.node);
        }),
        AgGridHelpers.itemMenu('Runs', 'fa-info-circle', () => {
          this.changeTabRuns.emit(params.node.data);
        }),
        'separator',
        'copy',
        'export',
      ];
      return result;
    };
  }

  formatDate(row: any) {
    return row.data.verified_on ? moment(row.data.verified_on).format('MM-DD-YYYY h:mm:ss a') :
      '';
  }

  onDefsColumnsGid() {
    this.onVerifyPermission().then((resp: boolean) => {
      this.btnVerifyShow = resp;
      if (Number(this.form.controls['billing_status_id'].value) !== 1) {
      this.columnDefs = [
        { headerName: 'Date', field: 'date', width: 90, checkboxSelection: resp },
        { headerName: 'Verified On', field: 'verified_on', width: 120, valueGetter: this.formatDate },
        { headerName: 'Verified By', field: 'verified_by', width: 120 },
        { headerName: 'Block', field: 'block', width: 80 },
        { headerName: 'Block Description', field: 'block_desc', width: 160 },
        { headerName: 'Manifest', field: 'manifest', width: 100 },
      ];
    } else {
      this.columnDefs = [
        { headerName: 'Date', field: 'date', width: 90, checkboxSelection: resp, headerCheckboxSelection: resp },
        { headerName: 'Block', field: 'block', width: 80 },
        { headerName: 'Block Description', field: 'block_desc', width: 160 },
        { headerName: 'Manifest', field: 'manifest', width: 100 },
      ];
    }
    if (this.grid.api) {
      this.grid.api.setColumnDefs(this.columnDefs);
    }
    });
  }

  initForm() {
    this.form = this.fb.group({
      'billing_status_id': [1]
    });
  }

  getBillingStatus() {
    this.busy = this.invoiceService.getBillingStatus().subscribe((resp: any) => {
      if(resp){
        this.billing_status = resp;
      }
    });
  }

  getUnproccesed() {
    const data: any = {
      start_date: this.dataFilter.start_date,
      end_date: this.dataFilter.end_date,
      client_id: this.dataFilter.client_id,
      route_id: this.dataFilter.routes_id,
      billing_status_id: this.form.controls['billing_status_id'].value
    };
    if (
      data.start_date
      && data.end_date
      && data.client_id
      && data.route_id
      && data.billing_status_id
    ) {
      this.busy = this.invoiceService.getUnproccesed(data).subscribe((res: any) => {
        this.count_row = res.total;
        this.count_row_total.emit(this.count_row);
        this.onDefsColumnsGid();
        if (this.grid.api) {
          this.grid.api.setRowData(res.data);
        }
        this.disableButton = true;
      });
    }
  }

  onVerify(external = false) {
    this.sweetAlertHelper
      .createCustomAlert({
        title: 'Are you sure?',
        html: '<h4>Are you sure you want to verify the selected manifests?</h4>',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      })
      .then((response: any) => {
        if (response.value) {
          const data: any = {
            ids: external ? [this.manifest_id] : this.grid.api.getSelectedRows().map(row => row.manifest),
            billing_status_id: 2
          };
          this.busy = this.invoiceService.putBillingStatus(data).subscribe(
            (resp: any) => {
              if (resp.status === 'success') {
                this.sweetAlertHelper
                  .createCustomAlert({
                    position: 'center',
                    type: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500
                  })
                  .then(() => {
                    this.getUnproccesed();
                    external ? (this.manifest_id = undefined) : null;
                    this.batchesService.loadGridSummary$.next(true);
                  });
              }
            },
            error => {
              this.sweetAlertHelper.captureException(error.message);
            }
          );
        }
      });
  }

  onRowDobuleClicked(event: any) {
    this.manifest_id = event.data.manifest;
    this.dateOfService = event.data.dateOfService;
    this.schedule_detail_id = event.data.schedule_detail_id;
  }

  onVerifyManifest(data?: any) {
    this.onVerify(true);
  }

  onVerifyPermission() {
     return new Promise((resolve, reject) => {
       this.permissionService.validatePermission('unprocessed-block-developer').then((resp: boolean) => {
        resolve(resp);
      });
     });
  }

}
