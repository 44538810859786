import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { PayrollService } from './../../../../services/payroll/payroll.service';
import { ClientService } from './../../../../services/services.index';
import { GridOptions } from 'ag-grid-community';
import Swal from 'sweetalert2';

/**
  * Modulo para gestionar la asignacion de reglas a los distintos bloques de los clientes
  *
  * Principales funciones
  * * Asignar reglas
  * * Ver listado de bloques con su regla
*/
@Component({
  selector: 'app-block-rules',
  templateUrl: 'block-rules.component.html',
  styleUrls: ['./block-rules.component.scss'],
})

export class BlockRulesComponent implements OnInit {

  busy: Subscription[] = [];
  gridBlocks: GridOptions;

  listClients = [];
  listRules = [];
  listBlocks = [];
  blocksSelected = [];
  companies = [];
  companiesStore = null;

  idRuleAssign: any = 'null';
  allBlocks = false;
  titleCountGrid = 'Blocks 0 of 0';

  constructor(
    private payrollService: PayrollService,
    private _serviceClient: ClientService,
  ) {
    this.setConfigGrid();
  }

  ngOnInit() {
    this.getClients();
    this.getPayrollRules();
    this.payrollService.rulesAddedEmiter$.subscribe(
      () => {
        this.getPayrollRules();
        this.getBlocks();
      }
    );
    this.companies = JSON.parse(localStorage.getItem('companies'));
  }

  getClients() {
    this.busy.push(this._serviceClient.getClientStatus(true).subscribe(
      (response: any) => {
        this.listClients = response.data;
      }
    ));
  }

  getBlocks() {
    if (this.companiesStore) {
      this.busy.push(this.payrollService.getBlocks(this.companiesStore).subscribe(
        (response: any) => {
          this.listBlocks = response;
          this.filtBlocks();
        }
      ));
    }
  }

  changeCompanie() {
    this.getBlocks();
  }

  filtBlocks() {
    let data = Object.assign([], this.listBlocks);
    data = data.filter(r => this.allBlocks ? true : r.payroll_code ? false : true);
    this.titleCountGrid = `Blocks ${data.length} of ${this.listBlocks.length}`;
    const filters = this.gridBlocks.api.getFilterModel();
    this.gridBlocks.api.setRowData(data);
    this.gridBlocks.api.setFilterModel(filters);
  }

  getPayrollRules() {
    this.busy.push(this.payrollService.getPayrollRules().subscribe(
      (response: any) => {
        this.listRules = response.data.filter(r => Number(r.active) === 1);
      }
    ));
  }

  setConfigGrid() {
    this.gridBlocks = <GridOptions>{
      enableSorting: true,
      enableColResize : true,
      sortingOrder: ['desc', 'asc', null],
      onFilterModified: () => {
        const cant = this.gridBlocks.api.getDisplayedRowCount();
        this.titleCountGrid = `Blocks ${cant} of ${this.listBlocks.length}`;
        this.clearSelections();
      }
    };
    this.gridBlocks.columnDefs = [
      { headerName: '', field: '', width: 35, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true, },
      { headerName: 'Client', field: 'client', width: 220 },
      { headerName: 'Route', field: 'route', width: 220 },
      { headerName: 'Block', width: 250, field: 'block' },
      { headerName: 'Payroll Code', width: 110, field: 'payroll_code' },
    ];
    this.gridBlocks.onRowDoubleClicked = params => {
      // this.loadDeails(params.data);
    };
  }
  clearFilter() {
    this.gridBlocks.api.setFilterModel(null);
    this.filtBlocks();
    this.clearSelections();
  }
  clearSort() {
    this.gridBlocks.api.setSortModel(null);
  }
  clearSelections() {
    this.gridBlocks.api.forEachNode((node) => {
      node.setSelected(false);
    });
    this.onSelectionToChange(this.gridBlocks);
  }

  onSelectionToChange(event) {
    const selectedRows = event.api.getSelectedNodes();
    const data = [];
    selectedRows.forEach(element => {
      data.push(element.data.id);
    });
    this.blocksSelected = data;
  }

  applyRule() {
    Swal.fire({
      title: 'Are you sure you want to apply this rule?',
      text: ``,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        if (this.companiesStore) {
          this.busy.push(this.payrollService.applyRule({
            payroll_rule_id: this.idRuleAssign === 'null' ? null : this.idRuleAssign,
            block_ids: this.blocksSelected,
            company: this.companiesStore
          }).subscribe(
            (response: any) => {
              Swal.fire('Successfull', 'Rules apply successfully', 'success');
              this.idRuleAssign = 'null';
              this.blocksSelected = [];
              this.getBlocks();
            }
          ));
        }
      }
    });
  }
}
