import * as moment from 'moment';
export const GetMonths = () => {
  return [
      { id: '1', name: 'January' },
      { id: '2', name: 'February' },
      { id: '3', name: 'March' },
      { id: '4', name: 'April' },
      { id: '5', name: 'May' },
      { id: '6', name: 'June' },
      { id: '7', name: 'July' },
      { id: '8', name: 'August' },
      { id: '9', name: 'September' },
      { id: '10', name: 'October' },
      { id: '11', name: 'November' },
      { id: '12', name: 'December' },
    ];
}
export function getDateNow() {
  return moment().format('YYYY/MM/DD');
}

export function numWeekToDay(year: string) {
  return moment(moment(year).toDate(), "MM-DD-YYYY").weeksInYear();
}

export function generateMonth(year: any, month: any) {
  const monthNumber = GetMonths().filter(mount => mount.name === month);
  console.log('monthNumber ', monthNumber);
  const dateStart: any = moment(`${monthNumber[0].id}-01-${year}`, 'MM-DD-YYYY').startOf('month').format('MM-DD-YYYY');
  const dateEnd: any = moment(`${monthNumber[0].id}-01-${year}`, 'MM-DD-YYYY').endOf('month').format('MM-DD-YYYY');
  return {
    'dateStart': dateStart,
    'dateEnd': dateEnd,
    'mount': monthNumber[0].id
  };
}
export function generateWeek(year: any, week: any) {
  console.log('generateWeek ', year, week);
  const dateStart = moment().weekYear(year).week(week).startOf('week').format('MM-DD-YYYY');
  const dateEnd = moment().weekYear(year).week(week).endOf('week').format('MM-DD-YYYY');
  return {
    "dateStart": dateStart,
    "dateEnd": dateEnd,
    "mount": '',
  };
}
