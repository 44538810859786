import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-t2card',
  templateUrl: './t2card.component.html',
  styleUrls: ['./t2card.component.scss']
})
export class T2cardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
