import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

declare const Pusher: any;

@Injectable()
export class PusherService {
  pusher: any;
  channel: any;

  constructor() {
    this.pusher = new Pusher('ebdde93aae61cf5df643', {
      cluster: 'us2',
      encrypted: true
    });
    this.channel = this.pusher.subscribe('channelSchedule');
  }

  unsubscribe() {
    this.channel = this.pusher.unsubscribe('channelSchedule');
  }

  loadChanelNotify(idUser) {
    return new Observable(
      (sub) => {
        this.pusher.subscribe(`ta-notification-${idUser}`).bind('App\\Events\\Notifications', data => {
          if (data != null) {
            sub.next(data);
          }
        });
      }
    );
  }
}
