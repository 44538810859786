import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-in',
  templateUrl: './modal-in.component.html',
  styleUrls: ['./modal-in.component.scss']
})
export class ModalInComponent implements OnInit {

  @Input() title = '';
  @Input() show = true;
  @Output() close = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  onClose() {
    this.show = ! this.show;
    this.close.emit(!this.show);
  }

}
