import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  CellClickedEvent,
  GridOptions,
  GridReadyEvent,
  RowDoubleClickedEvent,
  ValueGetterParams
} from 'ag-grid-community';
import AgGridHelpers from '../../../../helpers/ag-grid-helpers';
import {Subject, Subscription} from 'rxjs';
import {WorkflowService} from '../../../../services/billing/workflow.service';
import {SweetAlertHelper} from '../../../../CORE/helpers/sweet-alert-helper.service';
import {takeUntil} from 'rxjs/operators';
import {BsModalComponent} from 'ng2-bs3-modal';
import {EnumDownloadReportOption} from '../../../../components/grid-controls/EnumDownloadReportOption';

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss']
})
export class WorkflowComponent implements OnInit, OnDestroy {
  @ViewChild('modalChangeBillingStatus') modalChangeBillingStatus: BsModalComponent;
  @ViewChild('modalDriverManifest') modalDriverManifest: BsModalComponent;
  @ViewChild('modalSchedule') modalSchedule: BsModalComponent;
  protected readonly EnumDownloadReportOption = EnumDownloadReportOption;
  subscriptionWorkFlow: Subscription[] = [];
  private destroy: Subject<boolean> = new Subject();
  gridWorkFlow: GridOptions;
  gridName = 'grid-workflow';
  gridData: any[] = [];
  schedule_id_list: any[] = [];
  filterData: any = [];
  total_rows = 0;
  filtered_rows = 0;
  not_validate = 0;
  validated = 0;
  with_errors = 0;
  btnCollapseStatus = false;
  loading = false;
  loading_general = false;
  rangeDate: any;
  count = 0;
  manifest_id = undefined;
  scheduleSelected = undefined;
  dateStart = undefined;
  activeTab = 'Details';
  vehicles: any[] = [];
  allDrivers: any[] = [];
  drivers: any[] = [];

  constructor(private workflowService: WorkflowService, private sweetAlertHelper: SweetAlertHelper) {
    this.createGrid();
  }

  ngOnInit() {
      this.loadBillingValidate();
      this.subscribeObservers();
  }

  createGrid() {
    this.gridWorkFlow = <GridOptions>{
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      rowSelection: 'multiple',
      enableFilter: true,
      onGridReady: (event: GridReadyEvent) => {
        if (event) {
          this.gridData = [];
        }
      },
      onFilterChanged: (event) => this.filtered_rows = event.api.getDisplayedRowCount(),
      onRowDoubleClicked: (event: RowDoubleClickedEvent) => this.openScheduleModal(event),
      onSelectionChanged: (event) => {
        if (event && event.api) {
          const nodes = event.api.getSelectedNodes();
          this.schedule_id_list = [];
          nodes.forEach(node => {
            if (node) {
              this.schedule_id_list.push(node.data.schedule_details_id);
            }
          });
          this.verifyStatus();
        }
      },
      columnDefs: [
        {
          headerName: '#',
          width: 80,
          valueGetter: (params: ValueGetterParams) => params.node ? (params.node.rowIndex + 1) : null,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true
        },
        { headerName: 'Schedule', field: 'schedule_details_id', width: 100 },
        { headerName: 'Status Billing', field: 'status_billing', width: 100, cellRenderer: params => AgGridHelpers.statusBilling(String(params.data.status_billing)) },
        { headerName: 'MNF', sortable: true, field: 'manifest', colId: 'manifest', width: 80, cellClass: 'grid-cell-centered',
          cellRenderer: (params) => {
            return Number(params.data.manifest) > 0 ?
              `<i title="It has manifest" style="color: #1d1b1b !important" class="fa fa-check" aria-hidden="true"></i>`
              : '<i title="It has no manifest" style="color: red !important" class="fa fa-exclamation-triangle" aria-hidden="true"></i>';
          },
          onCellClicked: (event: CellClickedEvent) => this.openDriverManifestModal(event)
        },
        { headerName: 'Validation', sortable: true, colId: 'discrepancy', width: 60, cellClass: 'grid-cell-centered',
          cellRenderer: (params) => {
            return params.data.validations.length > 0 ?
              `<i title="It has descrepancies" style="color:red !important" class="fa fa-exclamation" aria-hidden="true"><a href="" ></a></i>`
              : '<i title="It has no descrepancies" style="color: #1d1b1b !important" class="fa fa-check" aria-hidden="true"></i>';
          }
        },
        { headerName: 'Date Of Service', field: 'dateofservice', width: 100 },
        { headerName: 'Client', field: 'client_name', width: 140 },
        { headerName: 'Route', field: 'route', width: 120 },
        { headerName: 'Run Block', field: 'block_code', width: 80 },
        { headerName: 'Block Desc', field: 'block_description', width: 140 },
        { headerName: 'Driver Schedule', field: 'driver_name_schedule', width: 200 },
        { headerName: 'Driver Manifest', field: 'driver_name_manifest', width: 200 },
        { headerName: 'Vehicle Schedule', field: 'vehicle_schedule', width: 80 },
        { headerName: 'Vehicle Manifest', field: 'vehicle_manifest', width: 80 },
        { headerName: 'STime', field: 'start_time', width: 70 },
        { headerName: 'ETime', field: 'end_time', width: 70 },
        { headerName: 'PTime', field: 'punch_out_time', width: 70 },
        { headerName: 'LYT', field: 'leave_yard_time', width: 70 },
        { headerName: 'Block Status', field: 'block_status', width: 100, cellRenderer: params => AgGridHelpers.statusBlock(String(params.data.block_status)) },
        { headerName: 'Comments', field: 'comments', width: 120 },
        { headerName: 'Last Billing', width: 120, children: [
            { headerName: 'User', field: 'user_name', width: 120 },
            { headerName: 'Date', field: 'updated_at', width: 120 },
          ]
        }
      ],
    };
  }

  subscribeObservers() {
    this.workflowService.responseReviewBlockRejectedVerifiedSubject$.subscribe(info => {
      if (info && info.status === 'success') {
        this.gridData = [];
        this.gridData = info.data;
        this.total_rows = info.data.length;
        this.filtered_rows = info.data.length;
        this.loading = false;
      }
    }, error => {
      this.sweetAlertHelper.captureException(error.message).then();
    });
    this.workflowService.reloadWorkflowSubject$.subscribe(resp => {
      if (resp) {
        this.loadBillingValidate();
      }
    });
  }
  loadverifiedRejected(data) {
    this.loading = true;
    this.subscriptionWorkFlow.push(this.workflowService.findScheduleManifestWorkflow(data).subscribe(resp => {
        if (resp && resp.data) {
          this.not_validate = 0;
          this.validated = 0;
          this.with_errors = 0;
          this.gridData = resp.data;
          this.total_rows = resp.data.length;
          this.filtered_rows = resp.data.length;
          resp.data.filter(item => item.validations.length ? this.not_validate : this.not_validate++);
          this.loading = false;
        }
      },
      error => {
        this.sweetAlertHelper.captureException(error).then();
      }));
  }

  loadBillingValidate() {
    this.workflowService.loadBillingValidateForWorkflowSubject$.pipe(takeUntil(this.destroy)).subscribe((data: any) => {
      if (data) {
        this.filterData = data;
        this.loadverifiedRejected(this.filterData);
      }
    }, error => {
      this.sweetAlertHelper.captureException(error).then();
    });
  }

  openDriverManifestModal(event: CellClickedEvent): void {
    const { driver_manifest_id, status_billing } = event.data;
    this.manifest_id = driver_manifest_id;
    this.workflowService.sendStatusBillingByWorkflow(status_billing);
    if (this.modalDriverManifest && this.manifest_id) {
      this.modalDriverManifest.open().then();
    }
  }

  closeScheduleDetailModal(): void {
    this.modalSchedule.close().then(
      () => this.workflowService.modalDetailIsOpen(false)
    );
  }

  openScheduleModal(event: RowDoubleClickedEvent): void {
    const dataFormatted = this.workflowService.formatDataToScheduleModal(event.data);
    this.scheduleSelected = dataFormatted;
    this.dateStart = dataFormatted.dateStart;
    this.callServices(dataFormatted);
    this.modalSchedule.open().then(
      () => this.workflowService.modalDetailIsOpen(true)
    );
  }

  callServices(data): void {
    const { schedule_details_id, dateStart, dateEnd  } = data;
    this.loadAllDriversForScheduleModal();
    this.loadVehiclesAvailableForScheduleModal(schedule_details_id);
    this.loadsDriversAvailableForScheduleModal(schedule_details_id, dateStart, dateEnd);
  }

  loadsDriversAvailableForScheduleModal(scheduleDetailId, startDate, endDate): void {
    this.subscriptionWorkFlow.push(
      this.workflowService.getAvailableDriversByRangeAndSchedule(scheduleDetailId, startDate, endDate).subscribe({
        next: ({time_cards}: any) => this.drivers = time_cards,
        error: err => this.sweetAlertHelper.captureException((err))
      })
    );
  }

  loadVehiclesAvailableForScheduleModal(scheduleDetailId): void {
    this.subscriptionWorkFlow.push(
      this.workflowService.getAvailableVehiclesBySchedule(scheduleDetailId).subscribe({
        next: (response: any[]) => this.vehicles = response,
        error: err => this.sweetAlertHelper.captureException((err))
      })
    );
  }

  loadAllDriversForScheduleModal(): void {
    this.subscriptionWorkFlow.push(
      this.workflowService.getAllDriversActive().subscribe({
        next: (response: any[]) => this.allDrivers = response,
        error: err => this.sweetAlertHelper.captureException((err))
      })
    );
  }

  onCloseEmitByAppEditDaily(event: boolean): void {
    this.onReload(event);
    this.closeScheduleDetailModal();
  }

  onReload(event: boolean): void {
    if (event) {
      this.loadverifiedRejected(this.filterData);
    }
  }

  discrepancy(params: any) {
    if (params.colDef.colId === 'discrepancy') {
      this.workflowService.responseBillingStatusSubject.next(params.data);
    }
  }
  verifyStatus() {
    this.workflowService.verifyStatusSubject.next(this.schedule_id_list);
  }
  ngOnDestroy() {
    this.subscriptionWorkFlow.forEach(subscription => subscription.unsubscribe());
  }
}
