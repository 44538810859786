import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';
import { dataMenu } from './../../../shared/menus/menu.dto';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {

  optionsMenu: any = [];
  subsRouting: Subscription;
  actualRoute: string;
  idSupport = 0;
  screenB64: string;

  constructor(
    private route: Router,
    private ngxPermissionsService: NgxPermissionsService,
  ) {
    this.optionsMenu = Object.assign([], JSON.parse(JSON.stringify([...dataMenu])));
    this.actualRoute = null;
    this.subsRouting = this.route.events.subscribe( res => {
      if (res instanceof NavigationEnd) {
        this.actualRoute = res.url;
        this.makerActive(res.url);
      }
    });
  }

  ngOnInit() {}

  goTo(route) {
    if (route) {
      this.route.navigateByUrl(route);
      this.collapse(null, 0);
    }
  }

  makerActive(route) {
    const options  = Object.assign([], this.optionsMenu);
    options.forEach(element => {
      element.active = element.route === route ? true : false;
      if (element.childrens) {
        element.childrens.forEach(element2 => {
          element2.active = element2.route === route ? true : false;
          element.active = !element.active ? element2.active : true;
          if (element2.childrens) {
            element2.childrens.forEach(element3 => {
              element3.active = element3.route === route ? true : false;
              element2.active = !element2.active ? element3.active : true;
              element.active = !element.active ? element3.active : true;
            });
          }
        });
      }
    });
    this.optionsMenu = options;
    this.collapse(null, 0);
  }

  async collapse(parent, level) {
    // this.makerActive(null);
    let i = 0;
    const options: any[]  = Object.assign([], this.optionsMenu);
    while (options.length > 0 && i < options.length) {
      const element = options[i];
      // console.log(element.name);
      element.permitted = false;
      element.collapse =
        element.collapse === undefined ?
        true : (
          level === 1 ? (
            element.name === parent ? !(element.collapse && element.childrens ? element.childrens.length > 0 : false) : true
          ) : level === 0 ? true : element.collapse
        );
      if (element.childrens) {
        let j = 0;
        while (element.childrens.length > 0 && j < element.childrens.length) {
          const element2 = element.childrens[j];
          // console.log(element2.name);
          element2.permitted = false;
          element2.collapse =
            element2.collapse === undefined ?
            true : (
              level === 2 ? (
                element2.name === parent ? !(element2.collapse && element2.childrens ? element2.childrens.length > 0 : false) : true
              ) : level === 0 ? true : element2.collapse
            );

          if (element2.childrens) {
            let k = 0;
            while (element2.childrens.length > 0 && k < element2.childrens.length) {
              const element3 = element2.childrens[k];
              // console.log(element3.name);
              if (element3.route) {
                element3.permitted = false;
                const permission = await this.ngxPermissionsService.getPermission(element3.permission);
                if (permission) {
                  element3.permitted = true;
                  element2.permitted = true;
                  element.permitted = true;
                }
              }
              if (!element3.permitted) {
                element2.childrens.splice(k, 1);
              } else {
                k++;
              }
            }
          }

          if (element2.route) {
            element2.permitted = false;
            const permission = await this.ngxPermissionsService.getPermission(element2.permission);
            if (permission) {
              element2.permitted = true;
              element.permitted = true;
            }
          }

          if (!element2.permitted) {
            element.childrens.splice(j, 1);
          } else {
            j++;
          }
        }
      }

      if (element.route) {
        const permission = await this.ngxPermissionsService.getPermission(element.permission);
        if (permission) {
          element.permitted = true;
        }
      }
      if (!element.permitted) {
        options.splice(i, 1);
      } else {
        i++;
      }
    }
    // console.log(options);
    this.optionsMenu = options;
  }

  clearPermitted() {

  }

  close() {
    // console.log('closed');
  }

  ngOnDestroy() {
    this.subsRouting.unsubscribe();
  }

  sendSupport() {
    this.idSupport++;
    html2canvas(document.getElementById('contentPage')).then(canvas => {
      const base64Image = canvas.toDataURL('image/jpeg', 1);
      this.screenB64 = base64Image;
    });
  }
}
