import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy, EventEmitter, Output } from '@angular/core';
import { VehicleService } from '../../../../../../services/fleet/vehicle.service';
import { Subscription, forkJoin } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClientService } from '../../../../../../services/fleet/client.service';
import { IMyDpOptions } from 'mydatepicker';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-po-schedule',
  templateUrl: './add-po-schedule.component.html',
  styleUrls: ['./add-po-schedule.component.scss']
})
export class AddPoScheduleComponent implements OnInit, OnChanges, OnDestroy {

  busy: Subscription;
  form: FormGroup;
  @Input() clientId?: number;
  @Input() dataEdit?: any;
  @Output() onReload? = new EventEmitter<any>();

  myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd'
  };

  myDatePickerOptionsEnd: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd'
  };

  constructor(private fb: FormBuilder,
    private clientService: ClientService) {
    this.initForm();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['clientId'] && this.clientId) {
      this.form.controls['client_id'].setValue(this.clientId);
    }
    if (changes['dataEdit'] && this.dataEdit) {
      this.form.patchValue(this.dataEdit);
      this.setDate();
    }
  }

  ngOnDestroy() {
    this.busy ? this.busy.unsubscribe() : null;
  }

  initForm() {
    this.form = this.fb.group({
      id: [''],
      client_id: ['', [Validators.required]],
      value: ['', [Validators.required]],
      start_date: ['', [Validators.required]],
      end_date: [''],
      active: [true, [Validators.required]],
      startDate: [''],
      endDate: ['']
    });
  }

  onDateChangedStart(event: any) {
    this.form.controls['start_date'].setValue(event.formatted);
    const date = new Date(event.formatted);
    this.myDatePickerOptionsEnd = {
      dateFormat: 'yyyy-mm-dd',
      disableUntil: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
    }
  }

  onDateChangedEnd(event: any) {
    this.form.controls['end_date'].setValue(event.formatted);
  }

  onSave() {
    if (this.dataEdit) {
      this.busy = this.clientService.putPoSchedule(this.form.value, this.dataEdit.id).subscribe((resp: any) => {
        if (resp.status === 'success') {
          this.onReload.emit(true);
        }
      }, (error: HttpErrorResponse) => {
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: error.error.message
        });
      });
    } else {
      this.busy = this.clientService.postPoSchedule(this.form.value).subscribe((resp: any) => {
        if (resp.status === 'success') {
          this.onReload.emit(true);
        }
      }, (error: HttpErrorResponse) => {
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: error.error.message
        });
      });
    }
  }

  setDate() {
    if (this.dataEdit.start_date) {
      const date = new Date(this.dataEdit.start_date);
      this.form.controls['startDate'].setValue({
        date: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
      });
      this.myDatePickerOptionsEnd = {
        dateFormat: 'yyyy-mm-dd',
        disableUntil: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
      };
    }

    if (this.dataEdit.end_date) {
      const date = new Date(this.dataEdit.end_date);
      this.form.controls['endDate'].setValue({
        date: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
      });
    }
  }

}
