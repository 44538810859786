import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-badge-active',
  templateUrl: './badge-active.component.html',
  styleUrls: ['./badge-active.component.scss']
})
export class BadgeActiveComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;

  constructor() {}

  agInit(params: ICellRendererParams) {
    this.params = params;
  }

  refresh(): boolean {
    return true;
  }
}
