import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DriverService } from '../../../../../services/fleet/driver.service';
import { GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs-compat';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-drivers-current-job',
  templateUrl: './drivers-current-job.component.html',
  styleUrls: ['./drivers-current-job.component.scss']
})

export class DriverCurrentJobComponent implements OnInit, OnDestroy, OnChanges {
  @Input() datestart: any;
  @Input() scheduleSeleccionado: any;

  busy: Subscription[] = [];
  gridOptions: GridOptions;
  gridData: any[];

  constructor(private driverService: DriverService) {
    this.onInitGrid();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dateStart'] && this.datestart) {
      this.onInitGrid();
    }
  }

  ngOnInit() {}

  onInitGrid() {
    this.gridOptions = <GridOptions> {
      enableSorting: true,
      enableColResize: true,
      enableFilter: true,
      sortingOrder: ['desc', 'asc', null],
      columnDefs: [
        { headerName: 'Driver', width: 170, field: 'driver' },
        { headerName: 'Block', width: 180, field: 'block' },
        { headerName: 'Block Code', width: 140, field: 'block_code' },
        { headerName: 'Route', width: 180, field: 'route' },
        { headerName: 'Type', width: 180, field: 'type' }
      ],
      onGridReady: () => {
        if (this.datestart) {
          this.onLoadCurrentDriverJob(this.datestart);
        }
      }
    };
  }

  onLoadCurrentDriverJob(date: string) {
    const id = this.scheduleSeleccionado.id;
    if (id && date) {
      this.busy.push(
        this.driverService.getDriverCurrentJob(date, id).subscribe((resp: any) => {
          this.gridData = resp;
        }, error => {
          Swal.fire({
            title: 'Opss!',
            text: error.message,
            type: 'error'
          });
        })
      )
    }
  }

  ngOnDestroy(): void {
    this.busy = [];
  }
}
