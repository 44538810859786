import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { InvoiceReportService } from '../../invoice-report.service';
import { takeUntil } from 'rxjs/operators';
import { IClient } from '../../../../../../CORE/interfaces/client.interface';
import { WorkflowService } from '../../../../../../services/billing/workflow.service';
import { ClientService } from '../../../../../../services/fleet/client.service';
import { SweetAlertHelper } from '../../../../../../CORE/helpers/sweet-alert-helper.service';
import { BsModalComponent } from 'ng2-bs3-modal';
import { ReportsManageService } from '../../../../../../services/reports-manage.service';
import { IMyDpOptions } from 'mydatepicker';
import { IResponse } from '../../../../../../CORE/interfaces/response.interface';
import { IRoute } from '../../../../../../CORE/interfaces/driver-details.interface';
import { IMenuReport } from '../../../../../../CORE/interfaces/invoice-report.interface';

interface IDataParams {
  client_id: number;
  start_date: string;
  end_date: string;
  routes_id: number;
  routes?: number [];
  route_group_id?: any;
  routes_groups_id?: any;
}

@Component({
  selector: 'app-info-report',
  templateUrl: './info-report.component.html',
  styleUrls: ['./info-report.component.scss']
})
export class InfoReportComponent implements OnInit, OnDestroy {
  readonly PRINT_ROUTE = 'Print Route';
  @ViewChild('modalDateInvoiceSubmitted') modalDateInvoiceSubmitted: BsModalComponent;

  busy: Subscription [] = [];
  destroy: Subject<boolean> = new Subject<boolean>();

  routes: IRoute[] = [];
  startDate: string;
  endDate: string;
  clientId: number;
  routeId: FormControl = new FormControl(undefined, Validators.required);
  clientInfo: IClient;
  menuReport: IMenuReport [];
  dataParams: IDataParams;

  freeTextParams: any[] = [];
  freeTextModalTitle: string;
  freeTextForm: FormGroup;
  paramsModal: any[] = [];
  reportIdModal = 0;
  dateSubmitted: any;

  myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'mm/dd/yyyy'
  };

  constructor(
    private invoiceReportService: InvoiceReportService,
    private workflowService: WorkflowService,
    private clientService: ClientService,
    private sweetAlertHelper: SweetAlertHelper,
    private reportsManageService: ReportsManageService,
    private fb: FormBuilder
  ) {
    this.freeTextForm = this.fb.group({});
  }

  ngOnInit(): void {
    this.doSubscriptions();
    this.getMenuReport();
  }

  ngOnDestroy(): void {
    this.busy.forEach((subscription: Subscription) => subscription.unsubscribe());
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  private doSubscriptions(): void {
    this.subscribeToClientInfo();
    this.subscribeToChangeSearchWorkflowBar();
    this.subscribeToControlRouteIdChanges();
  }

  private subscribeToClientInfo(): void {
    this.invoiceReportService.infoByClientAction$.pipe(takeUntil(this.destroy))
      .subscribe({
        next: (clientInfo: IClient) => this.clientInfo = clientInfo
      });
  }

  private subscribeToChangeSearchWorkflowBar(): void {
    this.workflowService.infoByExternalFormToInvoiceReportAction$.pipe(takeUntil(this.destroy))
      .subscribe({
        next: ({date, clients_id: client_id, routes_id}): void => {
          const {start_date, end_date} = date;
          this.startDate = start_date;
          this.endDate = end_date;
          this.clientId = client_id;
          this.routeId.setValue(routes_id);
          this.dataParams = {client_id, routes_id, start_date, end_date};
          this.invoiceReportService.setinfoForBatchRecalculate(this.dataParams);
          this.getRoutesByClient();
        }
      });
  }

  private subscribeToControlRouteIdChanges(): void {
    this.routeId.valueChanges.subscribe({
      next: (routeIdValue) => this.dataParams.routes_id = routeIdValue
    });
  }

  private getMenuReport(): void {
    this.busy.push(
      this.clientService.getInfoMenuReportClient(this.clientId).subscribe({
        next: ({ data }: IResponse<IMenuReport[]>): void => {
          if (data.length) {
            this.menuReport = data;
          }
        },
        error: error => this.sweetAlertHelper.captureException(error)
      })
    );
  }

  private getRoutesByClient(): void {
    this.busy.push(
      this.clientService.getRoutesByClient(this.clientId).subscribe({
        next: ({ data: routes }: IResponse<IRoute[]>): void => {
          this.routes = routes;
          this.dataParams.routes = routes.map((route: IRoute) => route.id);
        },
        error: error => this.sweetAlertHelper.captureException(error)
      })
    );
  }

  openReport(report: IMenuReport): void {
    const { form_params, url, description }: IMenuReport = report;
    console.log('openReport ', form_params.length, report);
    if (form_params.length) {
      this.validateModal(form_params, url, description);
    }
  }

  validateModal(params: any[], reportId: number, titleModal: string): void {
    const formParams: {} = {};
    this.freeTextParams = params.filter(item => item.type_free_text);
    if (this.freeTextParams.length) {
      this.freeTextModalTitle = titleModal;
      this.freeTextParams.forEach(param => {
        if (param) {
          formParams[param.param_name] = new FormControl('', Validators.required);
        }
      });
      this.freeTextForm = this.fb.group(formParams);
      this.paramsModal = params;
      this.reportIdModal = reportId;
      this.modalDateInvoiceSubmitted.open('sm').then();
    } else {
      this.onPrintReport(params, reportId);
    }
  }

  onPrintReport(params: any[], reportId: number): void {
    const parameter: any = {};
    if (params.length) {
      params.forEach(param => {
        if (param) {
          switch (param.code) {
            case 'date':
              parameter[param.param_name] = [`${this.dataParams.start_date}`, `${this.dataParams.end_date}`];
              break;
            case 'start_date':
              parameter[param.param_name] = this.dataParams.start_date;
              break;
            case 'end_date':
              parameter[param.param_name] = this.dataParams.end_date;
              break;
            case 'client_id':
              parameter[param.param_name] = this.typeClient(param.array, parameter);
              break;
            case 'default':
              parameter[param.param_name] = param.default_values.split(',');
              break;
            case 'route_id':
              parameter[param.param_name] = this.typeRoutes(param.array, parameter);
              break;
            case 'route_group_id':
              parameter[param.param_name] = this.typeGroupRoutes(param.array, parameter);
              break;
            case 'free_text':
              parameter[param.param_name] = this.typeFreeText(param, parameter);
              break;
          }
        }
      });
    }
    this.busy.push(
      this.reportsManageService.getReportTrip2(reportId, parameter).subscribe((resp: string): void => {
        if (resp) {
          this.reportsManageService.loadReportWindows(resp);
          this.modalDateInvoiceSubmitted.close().then();
          this.onCloseModal();
        }
      })
    );
  }

  typeRoutes(type: any, parameter: any) {
    switch (type) {
      case 'selected':
        parameter = this.dataParams.routes_id;
        break;
      case 'all':
        parameter = this.dataParams.routes;
        break;
      case 'no array':
        parameter = `${this.dataParams.routes_id[0]}`;
        break;
    }
    return parameter;
  }

  typeFreeTextTitle(param_name: any): string {
    let title = '';
    switch (param_name) {
      case 'date_submitted':
        title = 'Invoice Date Submitted';
        break;
      case 'revised':
        title = 'Invoice Revised';
        break;
    }
    return title;
  }

  typeFreeText(param: any, parameter: any) {
    switch (param.param_name) {
      case 'date_submitted':
        const dateSubmitted = this.freeTextForm.get('date_submitted').value;
        parameter = dateSubmitted.formatted ? dateSubmitted.formatted : null;
        break;
      case 'revised':
        const revised = this.freeTextForm.get('revised').value;
        parameter = revised.formatted ? revised.formatted : null;
        break;
    }
    return parameter;
  }

  typeGroupRoutes(type: any, parameter: any) {
    switch (type) {
      case 'selected':
        parameter = this.dataParams.route_group_id;
        break;
      case 'all':
        parameter = this.dataParams.routes_groups_id;
        break;
      case 'no array':
        parameter = `${this.dataParams.route_group_id[0]}`;
        break;
    }
    return parameter;
  }

  typeClient(type: any, parameter: any) {
    switch (type) {
      case 'no array':
        parameter = `${this.dataParams.client_id}`;
        break;
      default:
        parameter = [`${this.dataParams.client_id}`];
    }
    return parameter;
  }

  onCloseModal(): void {
    this.paramsModal = null;
    this.dateSubmitted = '';
    this.reportIdModal = null;
    this.removeAllControls();
    this.freeTextParams = null;
    this.freeTextModalTitle = null;
    this.modalDateInvoiceSubmitted.close().then();
  }

  removeAllControls(): void {
    const formGroup: FormGroup = this.freeTextForm;
    this.freeTextForm.reset();
    if (formGroup) {
      Object.keys(formGroup.controls).forEach((key: string): void => {
        if (key) {
          formGroup.removeControl(key);
        }
      });
    }
  }

  isPrintOneRoute(description: string): boolean {
    return description === this.PRINT_ROUTE;
  }
}
