import { Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { DriverService, TSODriverService } from '../../../../../services/services.index';
import { TsoResponse, TsoService } from '../../../../../services/Tso.service';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit {
  public busy: Subscription;

  gridDriversTA: GridOptions;
  gridDriversTSO: GridOptions;
  gridDriversIntegration: GridOptions;

  driversTa: Array<any> = [];
  driversTso: Array<any> = [];
  driversIntegration: Array<any> = [];

  countDriversTa = 0;
  countDriversTso = 0;
  countDriversIntegration = 0;

  constructor(private _serviceDriver: DriverService,
              private _serviceTSODriver: TSODriverService,
              private _serviceTSOService: TsoService) {

    this.gridDriversTA = <GridOptions>{
      rowSelection: 'single',
      animateRows: true
    };

    this.gridDriversTSO = <GridOptions>{
      rowSelection: 'single',
      animateRows: true
    };
    this.gridDriversIntegration = <GridOptions>{
      rowSelection: 'single',
      animateRows: true
    };

    this.gridDriversTA.columnDefs = this.createColumn();
    this.gridDriversTSO.columnDefs = this.createColumnTSO();
    this.gridDriversIntegration.columnDefs = this.createColumnIntegration();

  }

  ngOnInit() {
    this.loadFullData();
  }

  loadFullData() {
    this.getAllDrivers();
    this.getAllDriversTSO();
    this.getAllDriverIntegration();
  }

  createColumn() {
    return [
      {headerName: 'ID', field: 'id', width: 100, checkboxSelection: true},
      {headerName: 'Last Name', field: 'last_name', width: 122, tooltipField: 'last_name', colId: 'LastName', sort: 'asc'},
      {headerName: 'First Name', field: 'first_name', width: 130}
    ];
  }

  createColumnIntegration() {
    return [
      {headerName: 'ID', field: 'id', width: 70 },
      {headerName: 'Last Name', field: 'last_name', width: 100, tooltipField: 'last_name', colId: 'LastName', sort: 'asc'},
      {headerName: 'First Name', field: 'first_name', width: 100},
      {headerName: 'ID TSO', field: 'tso_id', width: 70},
      {headerName: '', width: 50, colId: 'remove', template: `
        <span>
        <i class="fa fa-trash-o" aria-hidden="true"></i>
        </span>
      ` },
    ];
  }

  createColumnTSO() {
    return [
      {headerName: 'ID', field: 'ID', width: 100, checkboxSelection: true},
      {headerName: 'Last Name', field: 'LastName', width: 122, tooltipField: 'LastName', colId: 'LastName', sort: 'asc'},
      {headerName: 'First Name', field: 'Name', width: 130}
    ];
  }


  /***
   * @description
   * @param {boolean} status
   */
  getAllDrivers() {
    this.busy = this._serviceDriver.getDriversStatus(true).subscribe(
      data => {
        this.driversTa = data.filter((row: any) => row.tso_id === '');
        this.gridDriversTA.api.setRowData(this.driversTa);
        this.countDriversTa = this.countElementGrid(this.gridDriversTA);
      }
    );
  }

  /***
   * @description
   * @param {boolean} status
   */
  getAllDriversTSO() {
    this.busy = this._serviceTSODriver.listDrivers().subscribe(
      (data: TsoResponse) => {
        if (data.Code !== 1) {
          alert('Error : ' + data.Message);
        } else {
          this.driversTso = data.Data;
          this.gridDriversTSO.api.setRowData(this.driversTso);
        }
      }
    );
  }

  getAllDriverIntegration() {
    this.busy = this._serviceDriver.getDriverIntegration().subscribe(
      (data: any) => {
        // this.orderGrid(data);
        this.gridDriversIntegration.api.setRowData(data.filter((row: any) => row.tso_id !== ''));
        this.clearDriverTSO();
        this.getCount();
      }
    );
  }

  onAutoMerge() {
    this.driversTso.forEach((row0, x) => {
      const name0 = row0.LastName + ' ' + row0.Name;
      this.driversTa.forEach((row1, y) => {
        const name1 = row1.last_name + ' ' + row1.first_name;
        if (name0.toUpperCase() === name1.toUpperCase()) {
          const obj = {
            id: row1.id,
            last_name: row1.last_name.toUpperCase(),
            first_name: row1.first_name.toUpperCase(),
            tso_id: row0.ID
          };
          this.driversIntegration.push(obj);
          this.driversTa.splice(y, 1);
          this.driversTso.splice(x, 1);
        }
      });
    });
    this.setData();
  }

  setData() {
    this.gridDriversTA.api.setRowData(this.driversTa);
    this.gridDriversTSO.api.setRowData(this.driversTso);
    this.gridDriversIntegration.api.setRowData(this.driversIntegration);
    this.getCount();
  }

  getCount() {
    this.countDriversIntegration = this.countElementGrid(this.gridDriversIntegration);
    this.countDriversTa = this.countElementGrid(this.gridDriversTA);
    this.countDriversTso = this.countElementGrid(this.gridDriversTSO);
  }

  countElementGrid(grid: GridOptions): number {
    let count = 0;
    grid.api.forEachNode((row) => {
      count++;
    });
    return count;
  }

  onMerge() {
    const driverTa = this.gridDriversTA.api.getSelectedRows();
    const driverTso = this.gridDriversTSO.api.getSelectedRows();

    const obj = {
      id: driverTa[0].id,
      last_name: driverTa[0].last_name.toUpperCase(),
      first_name: driverTa[0].first_name.toUpperCase(),
      tso_id: driverTso[0].ID
    };
    this.driversIntegration.push(obj);
    this.gridDriversIntegration.api.updateRowData({ add:  [obj]});

    this.gridDriversTA.api.updateRowData({ remove: driverTa });
    this.gridDriversTSO.api.updateRowData({ remove: driverTso });
    this.getCount();
  }

  onSave() {
    const tem = [];
    this.gridDriversIntegration.api.forEachNode((row) => {
      tem.push(row.data);
    });
    this.busy = this._serviceDriver.storeDriverIntegration(tem).subscribe(
      data => {
        this.loadFullData();
      }
    );
  }

  onClearAll() {
    if (confirm('confirms the elimination of all records of the relationship?')) {
      this.busy = this._serviceDriver.deleteIdsTSODriver().subscribe(
        data => {
          this.loadFullData();
        }
      );
    }
  }

  onReload() {
    this.getAllDriversTSO();
  }



  clearDriverTSO() {
    this.gridDriversIntegration.api.forEachNode((rowNode) => {
      const tso_id = rowNode.data.tso_id;
      this.driversTso.forEach((row, x) => {
        if (Number(row.ID) === Number(tso_id)) {
          this.driversTso.splice(x, 1);
        }
      });
    });
    this.gridDriversTSO.api.setRowData(this.driversTso);
  }

  onCellClicked($event) {
    if ($event.colDef.colId === 'remove') {
      const data = $event.data;
      if (data && confirm('confirms the deletion of the current record?')) {
        const id = $event.data.id;
        this.busy = this._serviceDriver.deleteIdTSODriver(id).subscribe(
          (data: any) => {
            this.loadFullData();
          }
        );
      }
    }
  }

}
