import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { InvoiceService } from '../../../services/billing/invoice.service';
import { GridOptions, ColDef } from 'ag-grid-community';
import { BsModalComponent } from 'ng2-bs3-modal';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-liquidated-damage',
  templateUrl: './liquidated-damage.component.html',
  styleUrls: ['./liquidated-damage.component.scss']
})

export class LiquidatedDamageComponent implements OnInit, OnDestroy, OnChanges {
  @Input() onClearGrid? = false;
  @ViewChild('modalLiquidated') modalLiquidated: BsModalComponent;
  gridLiquidatedDamage: GridOptions;
  busy: Subscription[] = [];
  dataParams: any = {};
  getContextMenuItems;
  liquidatedDamage = null;

  constructor(private invoiceService: InvoiceService) {
    this.onInitGrid();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['onClearGrid']) {
      if (this.onClearGrid && this.gridLiquidatedDamage.api) {
        this.gridLiquidatedDamage.api.setRowData([]);
      }
    }
  }

  ngOnInit() {
    this.invoiceService.load_liquidated_damage$.subscribe(resp => {
      if (resp) {
        this.dataParams = resp;
        this.onSearch();
      }
    });
  }

  ngOnDestroy() {
    return (this.busy = []);
  }

  onInitGrid() {
    this.gridLiquidatedDamage = <GridOptions> {
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      rowSelection: 'single',
      enableRangeSelection: true,
      enableFilter: true,
      columnDefs: [
        {
          headerName: 'Date',
          field: 'date',
          cellRenderer: (params: any) => {
            return moment(params.value).format('MM-DD-YYYY');
          },
          width: 110
        },
        { headerName: 'Description', field: 'description', width: 160 },
        { headerName: 'Route', field: 'route', width: 160 },
        { headerName: 'Value', field: 'value', width: 100, colId: 'value', cellRenderer: this.CurrencyCellRenderer, cellStyle: {'text-align':'right'}},
        { headerName: 'Credit', field: 'credit_value', width: 100, colId: 'credit_value', cellRenderer: this.CurrencyCellRenderer, cellStyle: {'text-align':'right'} },
        { headerName: 'Total', field: 'total_value', width: 100, colId: 'total_value', cellRenderer: this.CurrencyCellRenderer, cellStyle: {'text-align':'right'} },
        {
          colId: 'deleteRow',
          cellRenderer: () => {
            return '<span <i class="fa fa-trash-o" style="color: red; cursor: pointer;" aria-hidden="true"></i></span>';
          },
          width: 50
        }
      ],
      onGridReady: () => {
        this.gridLiquidatedDamage.api.setRowData([]);
      }
    };
    this.getContextMenuItems = () => {
      return [
        {
          name: 'Add Liquidated Damage',
          icon: '<i class="fa fa-plus"></i>',
          action: () => {
            this.liquidatedDamage = null;
            this.modalLiquidated.open('sm').then();
          }
        },
        'separator',
        'copy',
        'export'
      ];
    };
  }

  CurrencyCellRenderer(params: any) {
    const usdFormate = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    });
    var x = usdFormate.format(params.value);
    if (params['colDef']['colId'] !== 'credit_value') {
      return (params.value > 0 ? `-${x}` : x );
    }
    return x;
  }

  onRowDoubleClicked(event: any) {
    this.liquidatedDamage = event;
    this.modalLiquidated.open('sm').then();
  }

  onSave() {
    this.liquidatedDamage = null;
    this.modalLiquidated.close().then();
    this.onSearch();
  }

  onSelectButton(params: any) {
    if (params.colDef.colId === 'deleteRow') {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Disable this record?',
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then(result => {
        if (result.value) {
          this.onDeleteRow(params.data);
        }
      });
    }
  }

  onDeleteRow(data: any) {
    this.busy.push(
      this.invoiceService
        .updateLiquidatedDamage({
          id: data.id,
          run_id: data.run_id,
          date: data.date,
          description: data.description,
          value: data.value,
          active: false,
        })
        .subscribe(
          resp => {
            if (resp) {
              Swal.fire('Success!', 'Liquidated damage updated successfully', 'success').then(() => {
                this.onSearch();
              });
            }
          },
          error => {
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: error.message
            }).then();
          }
        )
    );
  }

  onSearch() {
    const info = {
      client_id: this.dataParams.client_id,
      start_date: this.dataParams.start_date,
      end_date: this.dataParams.end_date
    };
    if (info.start_date && info.end_date && info.client_id) {
      this.busy.push(
        this.invoiceService.getLiquidatedDamage(info).subscribe((resp: any) => {
          if (this.gridLiquidatedDamage.api) {
            this.gridLiquidatedDamage.api.setRowData(resp);
          }
        })
      );
    }
  }
}
