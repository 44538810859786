import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DriverPerDiemService } from '../../services/fleet/driver-per-diem.service';
import * as moment from 'moment';

@Component({
  selector: 'app-driver-perdiem',
  templateUrl: './driver-perdiem.component.html',
  styleUrls: ['./driver-perdiem.component.scss']
})
export class DriverPerdiemComponent implements OnInit {

  formPerdiem: FormGroup;
  @Input() driver_id?: number;
  busy: any;
  perdiem_id: number;
  @Output() onReload ?: EventEmitter<any> = new EventEmitter();
  options = [];

  constructor( private fb: FormBuilder, private driverPerDiemService: DriverPerDiemService,
    private toastr: ToastrService) {
    this.formBuild();
    this.options = this.driverPerDiemService.options;
  }

  ngOnInit() {
    this.onLoadData();
  }

  formBuild() {
    this.formPerdiem = this.fb.group({
      availability: ['', Validators.required],
      sunday: ['', Validators.required],
      monday: ['', Validators.required],
      tuesday: ['', Validators.required],
      wednesday: ['', Validators.required],
      thursday: ['', Validators.required],
      friday: ['', Validators.required],
      saturday: ['', Validators.required],
      driver_id: ['', Validators.required],
      start_date: [null, Validators.required]
    });
  }

  onSubmit() {
    const dataForm = Object.assign({}, this.formPerdiem.getRawValue());
    dataForm.start_date = moment(dataForm.start_date).format('YYYY-MM-DD');
    if (this.perdiem_id) {
      this.busy = this.driverPerDiemService.putPerDiem(dataForm, this.perdiem_id).subscribe((resp: any) => {
        if (resp.status === 'success') {
          this.toastr.success(resp.status);
          this.onLoadData();
          this.onReload.emit(true);
        } else {
          this.toastr.error(
            JSON.stringify(resp),
            'Oops!',
            { closeButton: true }
          );
        }
      });
    } else {
      this.busy = this.driverPerDiemService.postPerDiem(dataForm).subscribe((resp: any) => {
        if (resp.status === 'success') {
          this.toastr.success(resp.status);
          this.onLoadData();
          this.onReload.emit(true);
        } else {
          this.toastr.error(
            JSON.stringify(resp),
            'Oops!',
            { closeButton: true }
          );
        }
      });
    }
  }

  onLoadData() {
    this.busy = this.driverPerDiemService.getPerDiem(this.driver_id).subscribe((resp: any) => {
      if (resp.status === 'success') {
        if (Array.isArray(resp.data)) {
          this.formPerdiem.controls['driver_id'].setValue(this.driver_id);
        } else {
          this.perdiem_id = resp.data.id;
          this.formPerdiem.patchValue({
            availability: resp.data.availability,
            sunday: resp.data.sunday,
            monday: resp.data.monday,
            tuesday: resp.data.tuesday,
            wednesday: resp.data.wednesday,
            thursday: resp.data.thursday,
            friday: resp.data.friday,
            saturday: resp.data.saturday,
            driver_id: resp.data.driver_id,
            start_date: resp.data.start_date
          });
        }
      }
    });
  }

}
