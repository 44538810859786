import { Component, OnDestroy, OnInit } from '@angular/core';
import { CellClickedEvent, CellDoubleClickedEvent, FilterModifiedEvent, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { Subject, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { DriverService, PermissionService } from '../../../services/services.index';
import AgGridHelpers from '../../../helpers/ag-grid-helpers';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.scss']
})

export class DriversComponent implements OnInit, OnDestroy {
  private destroy: Subject<boolean> = new Subject<boolean>();
  busy: Subscription;
  addDriver = false;
  showStatus = false;
  driverSekected = 0;
  btnStatusText = 'Status';
  gridDrivers: GridOptions;
  apiResponse: any;
  titleCountGrid: string;
  dataLength: number;
  totalDocuments = 10;

  constructor(
    private driverService: DriverService,
    private permissionService: PermissionService
  ) {
    this.createGrid();
  }

  ngOnInit() {
    this.subscribeState();
  }

  subscribeState() {
    this.driverService.saveDriver$.pipe(takeUntil(this.destroy)).subscribe(reload => {
      this.reloadGrid(reload);
    });
  }

  reloadGrid(reload: boolean) {
    if (reload) {
      this.getAllDrivers(this.showStatus);
    }
    this.addDriver = false;
  }

  createGrid() {
    this.gridDrivers = <GridOptions> {
      enableSorting: true,
      sortingOrder: ['desc', 'asc', null],
      enableFilter: true,
      columnDefs: [
        { headerName: 'Employee', field: 'employee_id', width: 100 },
        { headerName: 'Last Name', field: 'last_name', width: 140, tooltipField: 'last_name' },
        { headerName: 'First Name', field: 'first_name', width: 140 },
        {
          headerName: 'Status',
          width: 100,
          field: 'active',
          cellRenderer: params => {
            if (params && params.data) {
              return AgGridHelpers.statusRecord(params.data.active);
            }
          }
        },
        {
          headerName: 'SunDial',
          width: 100,
          field: 'sundial_exist',
          cellRenderer: params => {
            if (params && params.data) {
              return params.data.sundial_time_id
                ? '<span class="label label-success" style="margin-top: 4px; position: absolute;">Registered</span>'
                : '';
            }
          }
        },
        {
          headerName: 'Type',
          width: 100,
          field: 'per_diem.availability',
          cellRenderer: params => {
            if (params && params.data) {
              return params.data.per_diem ? params.data.per_diem.availability : '';
            }
          }
        },
        {
          headerName: 'Lic Status',
          field: 'documents_count',
          width: 100,
          cellStyle: { 'text-align': 'center' },
          cellRenderer: params => {
            if (params && params.data) {
              if (params.data.attach_documents_count) {
                if (Number(params.data.attach_documents_count) <= Number(this.totalDocuments) && Number(params.data.attach_documents_count) >= 0) {
                  return '<span class="label label-danger" style="margin-top: 4px; position: absolute;">Exp</span>';
                }
                return '<span class="label label-success" style="margin-top: 4px; position: absolute;">OK</span>';
              } else {
                return '<span class="label label-danger" style="margin-top: 4px; position: absolute;">Exp</span>';
              }
            }
          }
        },
        {
          headerName: 'Insurance',
          width: 100,
          field: 'is_insured',
          cellRenderer: params => {
            if (params && params.data) {
              return (params.data.is_insured)
                ? '<span class="label label-success" style="margin-top: 4px; position: absolute;">active</span>'
                : '<span class="label label-danger" style="margin-top: 4px; position: absolute;">inactive</span>';
            }
          }
        },
      ],
      onGridReady: (event: GridReadyEvent) => {
        if (event) {
          this.getAllDrivers(this.showStatus);
        }
      },
      onFilterModified: (event: FilterModifiedEvent) => {
        if (event) {
          this.setCounter();
        }
      },
      onCellClicked: (event: CellClickedEvent) => {
        if (event) {
          this.driverSekected = event.data.id;
          event.data.active ? (this.btnStatusText = 'Disable') : (this.btnStatusText = 'Enable');
        }
      },
      onCellDoubleClicked: (event: CellDoubleClickedEvent) => {
        if (event) {
          this.permissionService.validatePermission('btns-drivers').then((resp: boolean) => {
            if (resp) {
              this.driverService.setDriverInfo(event.data);
              this.driverSekected = event.data.id;
              this.onEditDriver();
            }
          });
        }
      }
    };
  }

  onNotify(event: any) {
    this.getAllDrivers(this.showStatus);
  }

  onNewDriver() {
    this.driverSekected = 0;
    this.driverService.setDriverInfo(null);
    this.changeStateModal();
  }

  /***
   * @description
   */
  onEditDriver() {
    if (this.driverSekected === 0) {
      Swal.fire('Please, check a row');
    } else {
      this.changeStateModal();
    }
  }

  /***
   * @description
   */
  changeStateModal() {
    this.addDriver = !this.addDriver;
  }

  /***
   * @description
   * @param {boolean} status
   */
  changeShowStatus(status: boolean) {
    this.getAllDrivers(status);
  }

  /***
   * @description
   */
  restoreFilterModel() {
    this.gridDrivers.onFilterChanged = function() {};
  }

  /***
   * @description
   * @param {boolean} status
   */
  getAllDrivers(status: boolean) {
    this.busy = this.driverService.getDriversStatus(status).subscribe(data => {
      if (data) {
        const info = data;
        this.driverService.getTotalDocuments('DRIVERS').pipe(takeUntil(this.destroy)).subscribe(resp => {
            if (resp && resp.data) {
              this.totalDocuments = resp.data;
            }
        });
        info.forEach(element => {
          if (element) {
            element.sundial_exist = element.sundial_time_id ? 'Registered' : 'Not registered';
          }
        });
        info.forEach( doc => {
          if (doc && doc.attach_documents_count) {
            doc.documents_count = Number(doc.attach_documents_count) <= Number(this.totalDocuments) && Number(doc.attach_documents_count) >= 0 ? 'EXP' : 'OK';
          }
        });
        const filter = this.gridDrivers.api.getFilterModel();
        this.gridDrivers.api.setRowData(info);
        this.gridDrivers.api.setFilterModel(filter);
        this.dataLength = info.length;
        this.setCounter();
        this.restoreFilterModel();
      }
    });
  }

  private setCounter() {
    const cant = this.gridDrivers.api.getDisplayedRowCount();
    this.titleCountGrid = `Drivers ${cant} of ${this.dataLength}`;
  }

  syncUp() {
    this.busy = this.driverService.syncTimeCard('0').subscribe(data => {
      Swal.fire('Synchronized information');
    });
  }

  /***
   * @description
   */
  onDisableDriver() {
    if (this.driverSekected === 0) {
      Swal.fire('Please, check a row');
    } else {
      const objTem = { active: this.showStatus ? 0 : 1 };
      this.busy = this.driverService.changeDriverStatus(objTem, this.driverSekected).subscribe(data => {
        this.apiResponse = data;
        if (this.apiResponse.status) {
          this.onNotify('applied change');
        } else {
          Swal.fire('Unapplied change');
        }
      });
    }
  }

  exportExcel() {
    this.gridDrivers.api.exportDataAsExcel();
  }

  ngOnDestroy() {
    return this.busy ? this.busy.unsubscribe() : null;
  }
}
