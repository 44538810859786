import { Subscription } from 'rxjs/Rx';
import { Component, OnInit, ViewChild, ElementRef, Input, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { BsModalComponent } from 'ng2-bs3-modal';
import { ColDef, GridReadyEvent, SideBarDef, GridOptions } from 'ag-grid-community';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { BatchesService } from '../../../../services/billing/batches.service';
import AgGridHelpers from '../../../../helpers/ag-grid-helpers';

@Component({
  selector: 'invoices-grid-batch',
  templateUrl: './invoices-grid.component.html',
  styleUrls: ['./invoices-grid.component.scss']
})
export class InvoicesGridComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('modalDetails') modalDetails: BsModalComponent;
  @ViewChild('gridBatchInvoices') gridView: ElementRef;
  @Input() clearGrid?: any;
  gridBatchInvoices: GridOptions;
  busy: Subscription;
  btnCollapseStatus = true;
  dataGrillInvoices = [];

  constructor(private batchesService: BatchesService) {
    this.initGrid();
    this.loadData(2);
   }

  ngOnInit() {
    this.busy = this.batchesService.load_batchesInvoices$.subscribe((params: any) => {
      this.loadData(params);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['clearGrid']) {
      if (this.clearGrid) {
        this.gridBatchInvoices.api.setRowData([]);
        this.dataGrillInvoices = [];
      }
    }
  }

  ngOnDestroy(): void {
    this.busy ? this.busy.unsubscribe() : null;
    this.dataGrillInvoices = [];
  }

  loadData(data: any) {
    const client_id = data && data.client_id ? data.client_id : null;
    const invoice_status = data && data.invoice_status_id ? data.invoice_status_id : 2;
    this.busy = this.batchesService.getBatchInvoices(client_id, invoice_status).subscribe((resp) => {
      if (resp && resp.data.length) {
        this.insertOneV1(resp.data);
        this.gridBatchInvoices.api.setRowData(this.dataGrillInvoices);
      }
    });
  }

  initGrid() {
    const columnDefs2: ColDef[] = [
      {
        headerName: 'Client',
        field: 'client_name',
        minWidth: 240,
        rowGroup: true,
        hide: true,
        enableRowGroup: true,
        cellRendererParams: {
          suppressCount: true,
          suppressDoubleClickExpand: true,
          suppressEnterExpand: true
        }
      },
      { headerName: 'Date', field: 'range_date', width: 100, rowGroup: true, hide: true, cellStyle: {textAlign: 'left'}},
      { headerName: 'Invoice Date', field: 'invoice_date', width: 110, cellStyle: {textAlign: 'left'}},
      { headerName: 'Invoice Num', field: 'invoice_num', width: 110, cellStyle: {textAlign: 'left'}},
      { headerName: 'Charge', field: 'charges', width: 110, cellRenderer: (params) => AgGridHelpers.CurrencyCellRenderer(params), cellStyle: {textAlign: 'right'}},
      { headerName: 'Total Credits', field: 'total_credit', width: 110, cellRenderer: (params) => AgGridHelpers.CurrencyCellRenderer(params), cellStyle: {textAlign: 'right'}},
      { headerName: 'Due', field: 'total_due', width: 110, cellRenderer: (params) => AgGridHelpers.CurrencyCellRenderer(params), cellStyle: {textAlign: 'right'}},
      { headerName: '# Schedules', field: 'num_schedule', width: 110, cellStyle: {textAlign: 'left'}},
      { headerName: 'User', field: 'user_name', width: 150, cellStyle: {textAlign: 'left'}}
    ];
    this.gridBatchInvoices = <GridOptions>{
      columnDefs: columnDefs2,
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['asc', 'desc', null],
      enableFilter: true,
      groupDisplayType: 'multipleColumns',
      enableRangeSelection: true,
      onGridReady: () => {
        this.gridBatchInvoices.api.setRowData([]);
      },
    };
  }

  public autoGroupColumnDef: ColDef = {
    minWidth: 300,
    cellRendererParams: {
      footerValueGetter: (params: any) => {
        const isRootLevel = params.node.level === -1;
        if (isRootLevel) {
          return 'Grand Total';
        }
        return `Sub Total (${params.value})`;
      },
    },
  };

  insertOneV1(list: any) {
    if (list && list.length) {
      const dataGrillResult: any[] = [];
      for (let i = 0; i < list.length; i++) {
        const detailsList = list[i];
          const details: any = {
            client_name: detailsList.client_name,
            num_batch: detailsList.num_batch,
            charges: detailsList.charges,
            invoice_num: detailsList.invoice_num,
            invoice_date: detailsList.invoice_date,
            total_credit: detailsList.total_credit,
            total_due: detailsList.total_due,
            range_date: detailsList.DATE,
            num_schedule: detailsList.num_schedule,
            user_name: detailsList.user_name
          };
          dataGrillResult.push(details);
        }
     for (let t = 0; t < dataGrillResult.length; t++) {
        this.dataGrillInvoices.push(dataGrillResult[t]);
      }
    }
    }

  onCollapse() {
    this.btnCollapseStatus = !this.btnCollapseStatus;
    return (!this.btnCollapseStatus) ? this.gridBatchInvoices.api.collapseAll() : this.gridBatchInvoices.api.expandAll();
  }

}
