import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CONSTANT } from '../../app.constants';
import { BaseService } from '../../core/BaseService';
import { IResponse } from '../../CORE/interfaces/response.interface';
import { IUsers } from '../../CORE/interfaces/user.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class UserService extends BaseService {
  /* User Form */
  private editUserSubject = new BehaviorSubject<number>(null);
  editUser$ = this.editUserSubject.asObservable();

  private saveUserFormSubject = new BehaviorSubject<boolean>(false);
  saveForm$ = this.saveUserFormSubject.asObservable();


  /*Change Password Form*/
  private changePasswordSubject = new BehaviorSubject<boolean>(false);
  changePassword$ = this.changePasswordSubject.asObservable();

  constructor(private httpClient: HttpClient) {
    super();
  }

  setEditUser(user: number) {
    this.editUserSubject.next(user);
  }

  saveForm(save: boolean) {
    this.saveUserFormSubject.next(save);
  }

  changePassword(change: boolean) {
    this.changePasswordSubject.next(change);
  }
  getUsersByStatus(status: number, user_type: string) {
    const url = `${CONSTANT.USERS.URL.GET_USERS_BY_STATUS}/${status}/${user_type}`;
    return this.httpClient.get<IResponse<IUsers[]>>(url);
  }

  getDriversByStatus(status: number) {
    const url = `${CONSTANT.USERS.URL.GET_DRIVERS_BY_STATUS}/${status}`;
    return this.httpClient.get<IResponse<any>>(url);
  }

  storeUser(data: IUsers) {
    const url = CONSTANT.USERS.URL.BASE;
    return this.httpClient.post<IResponse<any>>(url, data);
  }

  updateUser(data: IUsers, id: number) {
    const url = `${CONSTANT.USERS.URL.BASE}/${id}`;
    return this.httpClient.put<IResponse<any>>(url, data);
  }
  updateUserMobile(data: IUsers, id: number) {
    const url = `${CONSTANT.USERS.URL.UPDATE_USER_MOBILE}/${id}`;
    return this.httpClient.put<IResponse<any>>(url, data);
  }

  getUser(id: number) {
    const url = `${CONSTANT.USERS.URL.BASE}/${id}`;
    return this.httpClient.get<any>(url);
  }

  logoutAll() {
    const url = CONSTANT.USERS.URL.LOGOUT_ALL_USERS;
    return this.httpClient.get<IResponse<any>>(url);
  }

  updateAvatar(data: any) {
    const url = CONSTANT.USERS.URL.UPDATED_USER_AVATAR;
    return this.httpClient.put(url, data);
  }

}
