import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs/Rx';

export interface InvoiceStatus {
  id: string;
  description: string;
  is_active: boolean;
}

const InvoiceStatus: InvoiceStatus = {
  id: '',
  description: '',
  is_active: false,
}

@Injectable({
  providedIn: 'root'
})
export class BatchInvoicesStatusService {
  loadGridData$ = new BehaviorSubject<boolean>(false);
  loadInvoiceStatus$ = new BehaviorSubject<InvoiceStatus>(InvoiceStatus);
  batchInvoiceStatusRows$ = new BehaviorSubject<InvoiceStatus>(InvoiceStatus);

  save$ = new BehaviorSubject<boolean>(false);

  constructor(private http:HttpClient) { }

  get InvoiceStatus$(): Observable<InvoiceStatus> {
    return this.loadInvoiceStatus$.asObservable();
  }

  get InvoiceStatusRows$(): Observable<InvoiceStatus>{
    return this.batchInvoiceStatusRows$.asObservable();
  }

  get getLoadGridData$(): Observable<boolean> {
    return this.loadGridData$.asObservable();
  }

  getInvoiceStatus() {
    return this.http.get<any>(`${environment.url_api_v}billing/batch-invoice-status`);
  }

  addBatchInvoiceStatus(invoiceStatus: any) {
    return this.http.post<any>(`${environment.url_api_v}billing/batch-invoice-status`,invoiceStatus);
  }

  editBatchInvoiceStatus(invoiceStatus: any) {
    const id = invoiceStatus.id;
    return this.http.put<any>(`${environment.url_api_v}billing/batch-invoice-status/${id}`,invoiceStatus);
  }

}
