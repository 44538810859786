import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({providedIn: 'root'})
export class NotifyAppService {
  constructor(private httpClient: HttpClient) { }

  getNotifications(qty) {
    return this.httpClient.get(`${environment.url_api_v}notifications/user/list/${qty}`);
  }
  setViewNotify(notificacion_id) {
    return this.httpClient.put(`${environment.url_api_v}notifications/user/readed/${notificacion_id}`,{});
  }
}
