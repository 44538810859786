import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { DecimalPipe } from '@angular/common';

export interface ICredit {
  id: string;
  invoice_id: string;
  credit_num: string;
  credit_state_id: number;
  amount: number;
  paid_date: Date | null;
  is_active: boolean;
}

const batchInvoiceCredit: ICredit = {
  id: '',
  invoice_id: '',
  credit_num: '',
  credit_state_id: 0,
  amount: 0,
  paid_date: null,
  is_active: false
};
@Injectable({
  providedIn: 'root'
})

export class BatchCreditsService {
  loadGridData$ = new BehaviorSubject<boolean>(false);
  loadBatchInvoiceCredit$ = new BehaviorSubject<ICredit>(batchInvoiceCredit);
  batchInvoiceCreditRow$ = new BehaviorSubject<ICredit>(batchInvoiceCredit);

  editRowCredit$ = new BehaviorSubject<any>(null);
  save$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) { }

  get batchInvoiceCredit$(): Observable<ICredit> {
    return this.loadBatchInvoiceCredit$.asObservable();
  }

  get batchCreditRow$(): Observable<ICredit> {
    return this.batchInvoiceCreditRow$.asObservable();
  }

  get getLoadGridData$(): Observable<boolean> {
    return this.loadGridData$.asObservable();
  }

  getBatchInvoiceCredits(invoice_id: any) {
    return this.http.get<any>(`${environment.url_api_v}billing/batch-credit/${invoice_id}`);
  }

  addBatchInvoiceCredit(credit: ICredit) {
    return this.http.post<any>(`${environment.url_api_v}billing/batch-credit`,JSON.stringify(credit));
  }

  editBatchinvoiceCredit(credit: ICredit) {
    const id = credit.id;
    return this.http.put<any>(`${environment.url_api_v}billing/batch-credit/${id}`,credit);
  }


}
