import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReverseService {

  constructor(private http: HttpClient) { }

  reverse(latlng: any) {
    return this.http.get(`https://nominatim.openstreetmap.org/reverse.php?lat=${latlng.lat}&lon=${latlng.lng}&zoom=18&format=jsonv2`);
  }

  getProperties(place: any): Promise<any> {
    let properties: any = {};
    return new Promise((resolve,reject) => {

      const componentForm = {
          locality: 'short_name',
          administrative_area_level_1: 'short_name',
          postal_code: 'short_name',
      };

      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];
        if (componentForm[addressType]) {
          const val = place.address_components[i][componentForm[addressType]];
          switch(addressType) {
            case 'locality':
              properties.city = val;
              break;
            case 'postal_code':
              properties.zip = val;
              break;
            case 'administrative_area_level_1':
              properties.state = val;
              break;
          }
        }
        resolve(properties);
      }
      reject(null);
    });
  }

}
