import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';


export interface TSORoute {
  RouteID: number;
  ShortName: string;
  LongName: string;
  RouteType: string;
  StartDate: string;
  EndDate: string;
  Mon: string;
  Tue: string;
  Wed: string;
  Thu: string;
  Fri: string;
  Sat: string;
  Sun: string;
  DailyStartTime: string;
  DailyEndTime: string;
}

interface TsoResponse {
  Code: number;
  Message: string;
  Data: any;
}


@Injectable()
export class RouteService {

  protected resource: string;

  constructor(private http: HttpClient) {
    this.resource = environment.url_api_v;
  }

  /**
   *
   * @param {string} token
   * @returns {Observable<TsoResponse>}
   */
  listRoutes() {
    return this.http.get<TsoResponse>(environment.url_api_v + environment.tso.routes);
  }

  getUserTSO() {
    return this.http.get<TsoResponse>(environment.url_api_v + environment.tso.getUserTSO);
  }

  getRoutesTso() {
    return this.http.get<any>(`${environment.url_api_v}routesTso`);
  }

}
