import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BsModalComponent } from 'ng2-bs3-modal';
import { FilterChangedEvent, GridOptions, RowSelectedEvent } from 'ag-grid-community';
import { FormBuilder, FormGroup } from '@angular/forms';
import { InvoiceService } from '../../../../services/billing/invoice.service';
import * as moment from 'moment';
import { BatchesService } from '../../../../services/billing/batches.service';
import { Subject, Subscription } from 'rxjs/Rx';
import { AddBatchComponent } from '../batch-view/add-batch/add-batch.component';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ready-to-bill',
  templateUrl: './ready-to-bill.component.html',
  styleUrls: ['./ready-to-bill.component.scss']
})
export class ReadyToBillComponent implements OnInit, OnDestroy {
  private destroy: Subject<boolean> = new Subject<boolean>();

  @ViewChild('addBatch') addBatch: AddBatchComponent;
  @ViewChild('modalManifest') modalManifest: BsModalComponent;
  @ViewChild('modal') modal: BsModalComponent;
  loading = false;

  grid: GridOptions;
  busy: Subscription;
  count_row: number = 0;
  manifest_id: number;
  disableButton = true;
  form: FormGroup;
  dataFilter: any;
  count_unverify: number;

  columnDefs: any = [
    { headerName: 'Date', field: 'date', width: 90 /*, checkboxSelection: true*/ },
    { headerName: 'Verified On', field: 'verified_on', width: 160, valueGetter: this.formatDate },
    { headerName: 'Verified By', field: 'verified_by', width: 120 },
    { headerName: 'Status', field: 'billing_status', width: 100 },
    { headerName: 'Block', field: 'block', width: 120 },
    { headerName: 'Block Description', field: 'block_desc', width: 160 },
    { headerName: 'Vehicle', field: 'vehicle', width: 100 },
    { headerName: 'Driver', field: 'driver', width: 140 },
    { headerName: 'Manifest', field: 'manifest', width: 100 }
  ];

  constructor(private fb: FormBuilder, private invoiceService: InvoiceService,
              private batchesService: BatchesService) {
    this.initGrid();
  }
  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  ngOnInit() {
    this.batchesService.load_readyToBill$.subscribe((resp: any) => {
      this.dataFilter = resp;
      this.getProccesed(2);
      this.getProccesed(1);
    });
  }

  initGrid() {
    this.grid = <GridOptions>{
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      rowSelection: 'multiple',
      enableFilter: true,
      onGridReady: () => {
        this.grid.api.setRowData([]);
      },
      onFilterChanged: (event: FilterChangedEvent) => {
        this.count_row = this.grid.api.getDisplayedRowCount();
      },
      onRowSelected: (event: RowSelectedEvent) => {
        /* if (Number(event.node.data.billing_status_id) === 2) {
          this.disableButton = this.grid.api.getSelectedNodes().length > 0 ? false : true;
        } else {
          this.disableButton = true;
        } */
      }
    };
  }

  formatDate(row: any) {
    return row.data.verified_on ? moment(row.data.verified_on).format('MM-DD-YYYY h:mm:ss a') :
      '';
  }

  onRowDobuleClicked(event: any) {
    this.manifest_id = event.data.manifest;
    this.modalManifest.open();
  }

  getProccesed(billing_status_id: number) {
    const data: any = {
      start_date: this.dataFilter.start_date,
      end_date: this.dataFilter.end_date,
      client_id: Number(this.dataFilter.client_id),
      route_id: null, // this.dataFilter.routes_id[0],
      billing_status_id: billing_status_id
    };
    this.busy = this.invoiceService.getUnproccesed(data).pipe(takeUntil(this.destroy)).subscribe((res: any) => {
      if (billing_status_id === 2) {
        this.count_row = res.total;
        if (this.grid.api) {
          this.grid.api.setRowData(res.data);
        }
        this.disableButton = true;
        this.loading = false;
      } else if (billing_status_id === 1) {
        this.count_unverify = res.total;
      }
    });
  }

  onAddBatch() {
    // const ids: any = this.grid.api.getSelectedRows().map(row => row.manifest);
    this.addBatch.form.controls['start_date'].setValue(this.dataFilter.start_date);
    this.addBatch.form.controls['end_date'].setValue(this.dataFilter.end_date);
    // this.addBatch.form.controls['ids'].setValue(ids);
    if (!this.dataFilter.block_like_run) {
      this.modal.open();
    } else {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Do you confirm the batch creation?',
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          this.addBatch.form.controls['date_submitted'].setValue(this.dataFilter.start_date);
          this.addBatch.onSave();
        }
      });
    }
  }

   onSetData(event: any) {
     this.getProccesed(2);
     this.getProccesed(1);
     this.batchesService.load_batches$.next(Number(this.dataFilter.client_id));
  }
}
