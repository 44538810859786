import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { forkJoin, Subscription } from 'rxjs';
import { IRoute, RoutesService } from '../../../../../services/fleet/routes.service';
import { ICategorie, IIncident, InterruptionCategoriesService } from '../../../../../services/parameters/interruption-categories.service';
import { DashboardLsfDataService } from '../../../dashboard-lsf.data.service';
import { DriverService } from './../../../../../services/fleet/driver.service';

@Component({
  selector: 'app-routes-interruption',
  templateUrl: './routes-interruption.component.html',
  styleUrls: ['./routes-interruption.component.scss']
})
export class RoutesInterruptionComponent implements OnInit, OnDestroy {

  @Output() onLoad? = new EventEmitter<any>();
  busy: Subscription;
  routes: IRoute[];
  supervisor: any[];
  categories: ICategorie[];
  subcategories: any = [];
  incidents: IIncident[];
  gridRoute: GridOptions;
  form: FormGroup;
  pattern_time = '(0[0-9]|1[0-9]|2[0-9]|3[0-6])(:[0-5][0-9]){1}';
  time_format = [/[0-9]/, /\d/, ':', /\d/, /\d/];

  constructor( private routesService: RoutesService, private driverService: DriverService,
    private interruptionCategoriesService: InterruptionCategoriesService, private fb: FormBuilder,
    private  _dataService: DashboardLsfDataService) {
    this.gridRoute = <GridOptions> {
      rowSelection: 'multiple',
      columnDefs: [
        { headerName: 'ID', field: 'id', width: 80, checkboxSelection: true, headerCheckboxSelection: true },
        { headerName: 'Description', field: 'description', width: 220 },
        { headerName: 'Block Code', field: 'block_code', width: 100 },
        { headerName: 'Start Location', field: 'startLocation', width: 140 }
      ]
    };
    this.createForm();
  }

  ngOnInit() {
    setTimeout(() => {
      this.loadData();
      this.gridRoute.api.setRowData([]);
    }, 800);
  }

  ngOnDestroy() {
    return this.busy ? this.busy.unsubscribe() : null;
  }

  createForm() {
    this.form = this.fb.group({
      route_id: ['', Validators.required],
      driver_id: ['', Validators.required],
      time: ['', Validators.required],
      category_id: ['undefined', Validators.required],
      incident_id: ['undefined', Validators.required],
      subcategories_incident_id: ['undefined', Validators.required],
      details: [''],
      route_blocks: ['']
    });
  }

  loadData() {
    this.busy = forkJoin([
      this.routesService.getRoutesStatus(true),
      this.driverService.getDriversStatus(true),
      this.interruptionCategoriesService.getInterruptionCategories()
    ]).subscribe((resp: any[]) => {
      this.routes = resp[0];
      this.supervisor = resp[1];
      this.categories = resp[2];
    });
  }

  onSelectRoute(value: number) {
    const result = this.routes.filter((row: IRoute) => {
      return row.id === Number(value);
    })[0];
    this.gridRoute.api.setRowData(result.route_blocks);
  }

  onChangeCategory(_value: any) {
    const result: any = _.find(this.categories, {'id': parseInt(_value)});
    this.subcategories = result.subcategories;
    if (this.subcategories.length === 1) {
      this.form.controls['subcategories_incident_id'].setValue(this.subcategories[0].id);
      this.changeSubcategorie(this.subcategories[0].id);
    } else {
      this.incidents = [];
      this.form.controls['subcategories_incident_id'].setValue(undefined);
    }
    this.form.controls['incident_id'].setValue(undefined);
  }

  changeSubcategorie(_value: any) {
    let result: any = _.find(this.subcategories, {'id': parseInt(_value)});
    this.incidents = result.incidents;
    this.form.controls['incident_id'].setValue(undefined);
  }

  onSend() {
    if (this.form.valid) {
      if (this.gridRoute.api.getSelectedRows().length > 0 ) {
        this.form.controls['route_blocks'].setValue(this.getGridSelected());
        this.busy = this._dataService.postData( this.form.value, 'routeInterruptions').subscribe((resp: any) => {
          if (resp.status === 'success') {
            this.onLoad.emit('Successful operation');
          } else {
            alert('Error: please try again.');
          }
        });
      } else {
        alert('Please select the blocks of the route to be interrupted');
      }
    } else {
      alert('Please verify the form');
    }
  }

  getGridSelected() {
    return this.gridRoute.api.getSelectedRows().map((row) => { return row['id'] });
  }

}
