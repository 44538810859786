import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ClientService } from '../../../../../services/fleet/client.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-management-client',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.scss']
})
export class ManagementComponent implements OnInit {

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  @Input() citieSekected: number;
  @Input() companies: Array<any> = [];
  @Input() client: any;

  busy: Subscription;
  data: any = {};
  titleModal = '';
  apiResponse: any;
  company_id: number;
  activeTab = 'Details';

  constructor(private clientService: ClientService) {
  }

  ngOnInit() {
    if (this.client) {
      this.data = this.client;
      this.titleModal = 'Edit Client - ' + this.data.client_name;
    } else {
      this.titleModal = 'New Client';
    }
  }

  onCancel() {
    this.clientService.editRowBillingForm(null);
    this.cancel.emit(true);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const x = event.keyCode;
    if (x === 27) {
      this.onCancel();
    }
  }

  onNotify(data: any) {
    this.notify.emit(data);
  }

  changeStateModal(value?: any) {
    this.onCancel();
  }

  get notCitieSekected(): boolean {
    return this.citieSekected !== 0;
  }

}
