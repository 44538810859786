import { Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';

/**
  * Reporte final de payroll
  * Este componente contiene el un listado con los empleados y sus respectivos payroll codes y tiempos trabajados filtrados por un rango de fechas
  *
  * Principales funciones
  * * Ver listado de empleados
  * * Exportar listado a excel
*/
@Component({
  selector: 'app-payroll-final-report',
  templateUrl: 'final-report.component.html',
  styleUrls: ['./final-report.component.scss']
})

export class FinalReportPayrollComponent implements OnInit {

  busy: Subscription[] = [];
  gridDataFile: GridOptions;
  titleCountGrid = 'Employees 0 of 0';
  listCurrent = 0;

  constructor() {
    this.setConfigGrid();
  }

  ngOnInit() { }

  setConfigGrid() {
    this.gridDataFile = <GridOptions>{
      enableSorting: true,
      enableColResize : true,
      sortingOrder: ['desc', 'asc', null],
      onFilterModified: () => {
        this.changeTitleCountGrid();
      }
    };
    this.gridDataFile.columnDefs = [
      { headerName: 'Payroll Code', width: 110, field: 'dept' },
      { headerName: 'PC Dept', width: 110, field: 'pc_dept' },
      { headerName: 'Id', field: 'emp', width: 110 },
      { headerName: 'Employee', width: 350, field: 'employee_name' },
      { headerName: 'Type', field: 'type', cellRenderer: (params) => params.data.type ? params.data.type : '', width: 110 },
      { headerName: 'Rate', field: 'rate', width: 100 },
      { headerName: 'Salary', field: 'salary', width: 100 },
      { headerName: 'Reg Hrs', field: 'reghrs', width: 100 },
      { headerName: 'O.T. Hrs', field: 'o_t_hrs', width: 100 },
      { headerName: 'Insurance', field: 'is_insured', width: 100,
        cellRenderer : (params) => {
          return params.data.is_insured && Number(params.data.is_insured) === 1 ? '<span class="label label-success" style="margin-top: 4px; position: absolute;">active</span>'
            : '<span class="label label-danger" style="margin-top: 4px; position: absolute;">inactive</span>';
        }
      },
    ];
    this.gridDataFile.onRowDoubleClicked = params => {
      // this.loadDeails(params.data);
    };
  }

  changeDataGrid(data) {
    this.gridDataFile.api.setRowData(data.data);
    this.listCurrent = data.data.length;
    this.changeTitleCountGrid();
  }

  changeTitleCountGrid() {
    const cant = this.gridDataFile.api.getDisplayedRowCount();
    this.titleCountGrid = `Employees ${cant} of ${this.listCurrent}`;
  }

}
