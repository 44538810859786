import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Password } from './../change-password/password';

@Component({
  selector: 'app-verify-password',
  templateUrl: './verify-password.component.html',
  styleUrls: ['./verify-password.component.scss']
})
export class VerifyPasswordComponent implements OnInit {
  public password: Password = new Password();

  @Input() set newPassword(data) {
    this.password.newPassword = data ? data : '';
    this.validPassword();
  }
  @Input() set oldPassword(data) {
    this.password.oldPassword = data ? data : '';
    this.validPassword();
  }
  @Input() set newPaswordConfirm(data) {
    this.password.newPaswordConfirm = data ? data : '';
    this.validPassword();
  }
  @Output()
  validated: EventEmitter<any> = new EventEmitter<any>();

  viewPassCurrent = false;
  viewPassNew = false;
  viewPassConfirm = false;
  validregex = false;

  validates = [
    {
      regex: /.{8,15}/,
      name: 'Have 8 to 15 characters',
      valid: false,
    },
    {
      regex: /(?=\D*\d)/,
      name: 'Have at least one number',
      valid: false,
    },
    {
      regex: /(?=.*?[A-Z])/,
      name: 'Have at least one uppercase letter',
      valid: false,
    },
    {
      regex: /(?=.*?[a-z])/,
      name: 'Have at least one lowercase letter',
      valid: false,
    },
    {
      regex: /(?=.*[$@$!%*?&])/,
      name: 'Have at leat one special character',
      valid: false,
    },
    {
      type: 'id',
      name: 'Not contain your current password',
      valid: false,
    },
    {
      regex: /^\s*\S+\s*$/,
      name: 'Contain no spaces',
      valid: false,
    },
    {
      type: 'match',
      regex: null,
      name: 'Match in both entry fields',
      valid: false,
    }
  ];

  constructor() {}

  ngOnInit() {}

  validPassword() {
    const password = Object.assign({}, this.password).newPassword;
    const current = Object.assign({}, this.password).oldPassword;
    let valid = true;
    for (let i = 0; i < this.validates.length; i++) {
      const element = Object.assign({}, this.validates[i]);
      switch (element.type) {
        case 'match':
            this.validates[i].valid = this.password.newPassword ? this.password.newPassword === this.password.newPaswordConfirm : false;
          break;
        case 'id':
          this.validates[i].valid = !new RegExp(`^\\b.*${current}.*\\b$`).test(password);
          break;
        default:
          if (element.regex) {
            this.validates[i].valid = element.regex.test(password);
          }
          break;
      }
      valid = (valid && !this.validates[i].valid) ? false : valid;
    }
    this.validregex = valid;
    this.validated.emit(valid);
  }
}
