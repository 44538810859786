import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../_guards/auth.guard';
import { DashboardLsfComponent } from '../dashboard-lsf/dashboard-lsf/dashboard-lsf.component';
import { DriversPoolComponent } from './daily/component/drivers-pool/drivers-pool.component';
import { SchedulesComponent } from './schedules/schedules.component';
import { PagesSmartComponent } from './../../theme/components/pages-smart/pages-smart.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { DashboardScheduleComponent } from './dashboard-schedule/dashboard-schedule.component';

const routes: Routes = [
  {
    path: 'schedules/modal/1/driverspool/:currentDate/:start_date/:end_date',
    component: DriversPoolComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      title: 'Driver\'s Pool',
      permissions: {
        only: ['route-driverspool'],
        redirectTo: '/noauthorized',
      }
    }
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    component: PagesSmartComponent,
    children: [
      {
        path: 'lsf',
        component: DashboardLsfComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          title: 'Dashboard LSF',
          permissions: {
            only: ['route-lsf'],
            redirectTo: '/noauthorized',
          }
        }
      },
      {
        path: 'schedules/:id',
        component: SchedulesComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          title: 'Schedules',
          permissions: {
            only: ['route-Schedules'],
            redirectTo: '/noauthorized',
          }
        }
      },
      {
        path: 'schedules/:id/:login',
        component: SchedulesComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          title: 'Schedules',
          permissions: {
            only: ['route-schedules-view'],
            redirectTo: '/noauthorized',
          }
        }
      },
      {
        path: 'schedule',
        component: DashboardScheduleComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          title: 'Dashboard Schedules',
          permissions: {
            only: ['route-dashboard-schedule'],
            redirectTo: '/noauthorized',
          }
        }
      }
    ]
  }
];

export const routing = RouterModule.forChild(routes);
