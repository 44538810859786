import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Configuration } from '../../../../app.configuration';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

export interface IDriverManifest {
  driver_id: number;
  driver_name: string;
  sundial_time_id: string;
  actual_drop_off: string;
  actual_check_in: string;
}
@Injectable()
export class AdminDataService {
  addDriver = new BehaviorSubject<boolean>(false);
  addDriver$ = this.addDriver.asObservable();
  saveSetPrimary = new BehaviorSubject<number>(null);
  saveSetPrimary$ = this.saveSetPrimary.asObservable();
  setDataDriver = new BehaviorSubject<IDriverManifest>(null);
  setDataDriver$ = this.setDataDriver.asObservable();
  reloadGridRightPanel$ = new EventEmitter<any>();

  constructor(protected consant: Configuration, protected http: HttpClient) {
  }

  getData(resource: string) {
    return this.http.get(environment.url_api_v + resource);
  }

  postData(data: any, resource: string) {
    let bodyString;
    bodyString = JSON.stringify(data);
    return this.http.post(environment.url_api_v + resource, bodyString);
  }

  postDataNoResponse(data: any, resource: string) {
    let bodyString;
    bodyString = JSON.stringify(data);
    return this.http.post(environment.url_api_v + resource, bodyString);
  }

  editDriverManifestDetails(data) {
    return this.http.put(environment.url_api_v +`driverManifestDetails/update/${data.driver_manifest_id}`, data);
  }

  putData(data: any, resource: string) {
    let bodyString;
    bodyString = JSON.stringify(data);
    return this.http.put(environment.url_api_v + resource, bodyString);
  }

  putDataNoResponse(data: any, resource: string) {
    let bodyString;
    bodyString = JSON.stringify(data);
    return this.http.put(environment.url_api_v + resource, bodyString);
  }

  postLoadaSchedule(dateofservice: any, driver_id: any, vehicle_id: any, route_block_id: any) {
    const bodyString = JSON.stringify({
      dateofservice,
      driver_id,
      vehicle_id,
      route_block_id
    });
    return this.http.post(environment.url_api_v + 'driverManifest/loadaSchedule', bodyString);
  }

  delete(resource, id) {
    return this.http.delete(`${environment.url_api_v}${resource}/${id}`);
  }

  saveStops(data) {
    return this.http.post(`${environment.url_api_v}driverManifest/stops/add`, data);
  }

  getStops(id) {
    return this.http.get(`${environment.url_api_v}driverManifest/stops/get/${id}`);
  }

  getWorkdaySummary(driver, date) {
    return this.http.get(`${environment.url_api_v}schedulesDetails/summary/${driver}/${date}`);
  }

}
