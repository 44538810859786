import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClientService } from '../../../../../services/fleet/client.service';
import { IFormBilling } from './form-billing.infertace';

@Component({
  selector: 'app-form-billing',
  templateUrl: './form-billing.component.html',
  styleUrls: ['./form-billing.component.scss']
})

export class FormBillingComponent implements OnInit, OnDestroy {
  private destroy: Subject<boolean> = new Subject<boolean>();
  @Input() clientId?: number;
  isEdit: boolean = false;
  showForm: boolean = false;

  constructor(private clientService: ClientService) {}

  ngOnInit() {
    this.suscribeObservers();
  }

  suscribeObservers() {
    this.clientService.editRow$.pipe(takeUntil(this.destroy)).subscribe((resp: IFormBilling) => {
      this.isEdit = resp && resp.id ? true : false;
      this.showForm = resp && resp.id ? true : false;
    });
  }

  showInactives(show: boolean) {
    this.clientService.showInactives(show);
  }

  addBillingForm() {
    this.isEdit = false;
  }

  openBillingForm(action: string) {
    switch (action) {
      case 'Add':
        this.showForm = true;
        break;
      case 'Cancel':
        this.clientService.editRowBillingForm(null);
        break;
    }
  }

  get LabelActionButton(): string {
    return this.showForm ? 'Cancel' : 'Add';
  }

  get styleButton() {
    return this.showForm ? 'btn-warning' : 'btn-form-action';
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }
}
