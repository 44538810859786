import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { DriverService } from '../../../../../services/services.index';
import { takeUntil } from 'rxjs/operators';
import { EDriverTabs } from '../../../../../CORE/enums/DriversEnum.enum';

@Component({
  selector: 'app-create-driver',
  styleUrls: ['./create.component.scss'],
  templateUrl: './create.component.html'
})
export class CreateDriverComponent implements OnInit, OnDestroy {
  private destroy: Subject<boolean> = new Subject<boolean>();

  titleModal = 'Create Driver';
  activeTab = EDriverTabs.Details;
  existsDriver = false;
  driverId: number;

  tabs = {
    details: EDriverTabs.Details,
    routes: EDriverTabs.Routes,
    schedule: EDriverTabs.Schedule,
    mobileUser: EDriverTabs.MobileUser,
    history: EDriverTabs.History,
    signature: EDriverTabs.Signature,
    documents: EDriverTabs.Documents
  };

  constructor(private driverService: DriverService) {}

  ngOnInit() {
    this.subscribeState();
  }

  subscribeState() {
    this.driverService.driverInfo$.pipe(takeUntil(this.destroy)).subscribe(driver => {
      if (driver && driver.id) {
        this.driverId = driver.id;
        this.titleModal = `Edit Driver ${driver.first_name} - ${driver.last_name}`;
        this.existsDriver = true;
      }
    });
  }

  changeTab(tab: number) {
    this.activeTab = tab;
  }

  close() {
    this.driverService.setSaveDriver(false);
  }

  get tabDetails(): boolean {
    return this.activeTab === EDriverTabs.Details;
  }

  get tabRoutes(): boolean {
    return this.activeTab === EDriverTabs.Routes;
  }

  get tabSchedule(): boolean {
    return this.activeTab === EDriverTabs.Schedule;
  }

  get tabMobileUser(): boolean {
    return this.activeTab === EDriverTabs.MobileUser;
  }

  get tabHistory(): boolean {
    return this.activeTab === EDriverTabs.History;
  }

  get tabSignature(): boolean {
    return this.activeTab === EDriverTabs.Signature;
  }

  get tabDocuments(): boolean {
    return this.activeTab === EDriverTabs.Documents;
  }

  ngOnDestroy() {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }
}
