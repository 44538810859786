import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CONSTANTS } from '../../app.constants';

@Injectable({
  providedIn: 'root'
})
export class RunsService {
  protected resource: string;

  constructor(private http: HttpClient) {
    this.resource = environment.url_api_v + CONSTANTS.RUNS;
  }

  storeData(data: any): Observable<Response> {
    return this.http.post<Response>(this.resource, JSON.stringify(data));
  }

  updateData(data: any): Observable<Response> {
    return this.http.put<Response>(`${this.resource}/${data.id}`, JSON.stringify(data));
  }

  getRunsByRoute(id: number): Observable<any> {
    return this.http.get(this.resource + '/byRoute/' + id);
  }

}
