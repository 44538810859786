import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ClientService} from '../../../../services/fleet/client.service';
import { WorkflowService } from '../../../../services/billing/workflow.service';
import {takeUntil} from 'rxjs/operators';
import {SweetAlertHelper} from '../../../../CORE/helpers/sweet-alert-helper.service';
import {BsModalComponent} from 'ng2-bs3-modal';
import {IResponse} from '../../../../CORE/interfaces/response.interface';
import {InvoiceReportService} from '../invoice-report/invoice-report.service';
@Component({
  selector: 'app-workflow-bar',
  templateUrl: './workflow-bar.component.html',
  styleUrls: ['./workflow-bar.component.scss']
})
export class WorkflowBarComponent implements OnInit, OnDestroy {
  @ViewChild('modalNextBillingStatus') modalNextBillingStatus: any;
  @ViewChild('modalInvoiceReport') modalInvoiceReport: BsModalComponent;
  @Input() staticTabs? = 0;
  subscriptionWorkFlow: Subscription[] = [];
  private destroy: Subject<boolean> = new Subject();
  loading = false;
  verifyActive = false;
  ids_verify: any[] = [];
  clients: any[] = [];
  routes: any[] = [];
  billing_status_list: any[] = [];
  block_status: any[] = [];
  formSearchWorkflow: FormGroup;
  formBillingStatusNext: FormGroup;
  dateSelected: any = [];
  typeRangeDate: string;
  canOpenModalInvoiceReport: boolean;
  clientName: string;

  dropdownRouteSettings = {
    singleSelection: false,
    text: 'Select Routes',
    idField: 'id',
    textField: 'description',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    classes: 'myclass custom-class',
    itemsShowLimit: 1,
    disabled: true
  };
  dropdownBlockStatusSettings = {
    singleSelection: false,
    text: 'Select Block Status',
    idField: 'id',
    textField: 'description',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    classes: 'myclass custom-class',
    itemsShowLimit: 1,
    disabled: true
  };

  constructor(
    private clientService: ClientService,
    private workflow: WorkflowService,
    private fb: FormBuilder,
    private sweetAlertHelper: SweetAlertHelper,
    private invoiceReportService: InvoiceReportService
  ) {
    this.initForm();
    this.initFormBlockStatus();
  }

  ngOnInit() {
    this.subscribeObservers();
  }

  subscribeObservers() {
     this.workflow.workflowBarSubject$.subscribe(resp => {
       if (resp && resp.client_id) {
         this.formSearchWorkflow.reset();
         this.workflow.changeFormDateHeaderFilterSubject.next(resp.date);
         this.formSearchWorkflow.patchValue({
           date: resp.date,
           date_star: resp.date.start_date ? resp.date.start_date : resp.date_star,
           date_end: resp.date.end_date ? resp.date.end_date : resp.date_end,
           clients_id: resp.client_id,
           routes_id: this.isInTabUnprocessed ? resp.routes[0].id : resp.routes,
           billing_status: resp.billing_status_id,
           block_status_id: resp.block_status_selected,
         });
       }
     });
     this.subscriptionWorkFlow.push(this.clientService.getClientsByDriverManifest().subscribe((resp: any) => {
       if (resp) {
           this.clients = resp.data;
       }
     }, error => {
       this.sweetAlertHelper.captureException(error.message).then();
     }));
      this.subscriptionWorkFlow.push(this.clientService.getClientsByDriverManifest().subscribe((resp: any) => {
          if (resp) {
              this.clients = resp.data;
          }
      }, error => {
          this.sweetAlertHelper.captureException(error.message).then();
      }));
    this.formSearchWorkflow.get('clients_id').valueChanges.pipe(takeUntil(this.destroy)).subscribe({
          next: (client) => {
              if (client) {
                this.routes = [];
                this.subscriptionWorkFlow.push(this.clientService.getRoutesByClient(client, 1).subscribe((resp: any) => {
                  if (resp) {
                    this.routes = resp.data.map(r => ({...r, description_route: String(r.desc_billing ? r.desc_billing : r.description).toUpperCase() }));
                  }
                }, error => {
                  this.sweetAlertHelper.captureException(error.message).then();
                }));
                this.formSearchWorkflow.get('routes_id').setValue([]);
              } else {
                this.formSearchWorkflow.get('routes_id').setValue([]);
              }
          },
          error: error => {
              this.sweetAlertHelper.captureException(error.message).then();
          }
      });
    this.subscriptionWorkFlow.push(this.workflow.getBlockStatus().subscribe({
      next: resp => {
        if (resp && resp.data) {
          this.block_status = resp.data;
        }
      },
      error: error => {
        this.sweetAlertHelper.captureException(error.message).then();
      }
    }));
    this.subscriptionWorkFlow.push(this.workflow.getBillingStatus().subscribe({
      next: resp => {
        if (resp && resp.data) {
          this.billing_status_list = resp.data;
        }
      },
      error: error => {
        this.sweetAlertHelper.captureException(error.message).then();
      }
    }));
    this.workflow.reloadWorkflowSubject$.subscribe(resp => {
      if (resp) {
        this.modalNextBillingStatus.close();
      }
    });
    this.workflow.verifyStatusSubject$.subscribe(ids => {
      if (ids.length) {
        this.verifyActive = true;
        this.ids_verify = ids;
      } else {
        this.verifyActive = false;
        this.ids_verify = [];
      }
    });
    this.workflow.canOpenInvoiceModalAction$.pipe(takeUntil(this.destroy)).subscribe({
      next: (canOpen: boolean): boolean => canOpen ? (this.canOpenModalInvoiceReport = true) : (this.canOpenModalInvoiceReport = false)
    });
    this.subscribeToChangesOfFormSearchWorkflow();
  }
  initForm() {
    this.formSearchWorkflow = this.fb.group({
      date: [undefined, Validators.required],
      date_star: [''],
      date_end: [''],
      clients_id: ['0', Validators.required],
      routes_id: [[], Validators.required],
      block_status_id: [[]],
      billing_status: [0]
    });
  }
  initFormBlockStatus() {
    this.formBillingStatusNext = this.fb.group({
      ids: [],
      billing_status_id: undefined,
      note: ''
    });
  }

  subscribeToChangesOfFormSearchWorkflow(): void {
    this.formSearchWorkflow.get('clients_id').valueChanges.pipe(takeUntil(this.destroy)).subscribe({
      next: (clientId): void => {
        if (clientId !== '0' && clientId) {
          this.workflow.canOpenInvoiceModalSubject.next(false);
          this.workflow.clientIdIsChangingInWorkflowBarForm(clientId);
          this.setTypeRangeDateByClient(clientId);
          this.setClientName(clientId);
        }
      }
    });
  }

  private setClientName(clientId: number): void {
    const clientName = this.clients.find(client => client.id === Number(clientId)).client_name;
    this.clientName = clientName.toUpperCase();
  }

  ngOnDestroy() {
    this.subscriptionWorkFlow.forEach(subscription => subscription.unsubscribe());
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  onSearch() {
    const formData = Object.assign({}, this.formSearchWorkflow.getRawValue());
    this.dateSelected = [];
    if (formData && formData.routes_id && formData.routes_id.length && formData.routes_id instanceof Array) {
      formData.routes_id = formData.routes_id.map((route: any) => route.id);
    }
    if (formData && formData.block_status_id && formData.block_status_id.length) {
      formData.block_status_id = formData.block_status_id.map((block: any) => block.id);
    }
    const rou = this.routes.map(r => ({
        'id': r.id,
        'description': r.description.toUpperCase()
    }));

    const tabUnprocessedCondition: boolean = this.isInTabUnprocessed && Array.isArray(formData.routes_id);

    const info = {
      date: formData.date,
      date_star: formData.date.start_date ? formData.date.start_date : formData.date_star,
      date_end: formData.date.end_date ? formData.date.end_date : formData.date_end,
      client_id: formData.clients_id,
      route_id: tabUnprocessedCondition ? formData.routes_id[0] : formData.routes_id,
      routes: rou,
      status_block_id: formData.block_status_id,
      block_status_list: this.block_status,
      billing_status: formData.billing_status,
      billing_status_list: this.billing_status_list
    };
    if (this.staticTabs === 0) {
      this.workflow.loadScheduleManifestSummary(info);
    }
    if (this.staticTabs === 1) {
        this.workflow.loadScheduleManifestUnverified(info);
    }
    if (this.staticTabs === 2) {
        this.workflow.loadScheduleManifestWorkflow(info);
    }
    if (this.staticTabs === 3) {
      this.workflow.loadDataToPatchInUnprocessedTabOnWorkflow(info);
    }
  }

  openModalInvoiceReport(): void {
    const filterRangeIsEqualToBillingRuleClient: boolean = this.filterDateIsEqualToRangeDate();

    if (!filterRangeIsEqualToBillingRuleClient) {
      this.sweetAlertHelper.createCustomAlert({
        type: 'error',
        title: 'Error...',
        text: 'The configuration of this client does not correspond to this type of range.'
      }).then(
        () => this.workflow.canOpenInvoiceModalSubject.next(false)
      );

      return;
    }

    this.modalInvoiceReport.open().then(
      (): void => {
        this.workflow.setInfoByExternalFormToInvoiceReport(this.retrieveLostDateRange());
        this.invoiceReportService.isOpenInInvoiceReportSubject.next(true);
      }
    );
  }

  private retrieveLostDateRange() {
    const formData = this.formSearchWorkflow.value;
    const {date, date_star, date_end} = formData;
    const {start_date: realStartDate, end_date: realEndDate} = date;

    if (!realStartDate || !realEndDate) {
      const dateToReplace = {...date, start_date: date_star, end_date: date_end};
      return {...formData, date: {...dateToReplace}};
    }

    return formData;
  }

  closeModalInvoiceReport(): void {
    this.modalInvoiceReport.close().then(
      (): void => {
        this.invoiceReportService.isOpenInInvoiceReportSubject.next(false);
        this.workflow.canOpenInvoiceModalSubject.next(false);
      }
    );
  }

  filterDateIsEqualToRangeDate(): boolean {
    const TYPE_OF_RANGE: { week: string, month: string } = {
      'week': '3',
      'month': '2'
    };

    const typeDateFilterSelected = this.formSearchWorkflow.get('date').value;
    const {type} = typeDateFilterSelected;
    return TYPE_OF_RANGE[this.typeRangeDate] === type;
  }

  setTypeRangeDateByClient(clientId): void {
    this.typeRangeDate = null;
    this.subscriptionWorkFlow.push(
      this.workflow.getBillingRulesByClientId(clientId).subscribe({
        next: ({data}: IResponse<any>) => this.typeRangeDate = data[0].rate.type_range_date,
        error: error => this.sweetAlertHelper.captureException(error)
      })
    );
  }

  verify(): void {
    this.subscriptionWorkFlow.push(
      this.workflow.changeVerifiedStatus(this.ids_verify).subscribe({
          next: (resp: IResponse<any>): void => {
            if (resp) {
              this.workflow.responseBillingStatusSubject.next(resp.data);
            }
          }
        }
      )
    );
  }

  changeStatus() {
    if (this.ids_verify && this.ids_verify.length) {
      this.workflow.sendIdsChangesVerifiedSubject.next(this.ids_verify);
      this.modalNextBillingStatus.open('sm').then();
    }
  }
  get withoutVerify(): boolean {
    return this.staticTabs === 0 || this.staticTabs === 3;
  }
  get onlyWorkFlow(): boolean {
    return this.staticTabs === 2;
  }

  get isInTabUnprocessed(): boolean {
    return this.staticTabs === 3;
  }
}
