import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BaseService } from '../../core/BaseService';
import { CONSTANT } from '../../app.constants';

@Injectable()
export class DispatchService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  getVehicles(data: any) {
    return this.http.post<any>(`${environment.url_api_v}dispatchs/routes/vehicles`, data);
  }

  getRoutesData(data: any) {
    return this.http.post<any>(`${environment.url_api_v}dispatchs/routes/path`, data);
  }

  getVehiclesPositionsGps(ids: number[]) {
    const url = CONSTANT.VEHICLE.URL.POST_VEHICLE_POSITION_GPS;
    return this.http.post<any>(url, ids);
  }

  getVehiclesPositionsTraccar(ids: number[]) {
    const url = CONSTANT.VEHICLE.URL.POST_VEHICLES_POSITION_TRACCAR;
    return this.http.post<any>(url, ids);
  }

  saveSegmentsPaths(data: any) {
    return this.http.put<any>(`${environment.url_api_v}routesTso/segmentsPath/update`, data);
  }

  getDetailsVehicle(vehicle_id) {
    return this.http.get<any>(`${environment.url_api_v}vehicles/block/current/${vehicle_id}`);
  }

  getVehiclesRunToday(date) {
    return this.http.get<any>(`${environment.url_api_v}vehicles/scheduledByDate/${date}`);
  }

  getBlocksRunsToVehicleForDate(date, vehicle_id) {
    return this.http.get<any>(`${environment.url_api_v}vehicles/routeBlocks/${date}/${vehicle_id}`);
  }

  getHistoryRun(vehicle_id, start, end) {
    return this.http.get<any>(`${environment.url_api_v}vehicles/trackDetail/${start}/${end}/${vehicle_id}`);
  }

}
