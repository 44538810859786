import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { RunsService } from '../../../../../services/fleet/runs.service';
import { GridSwitchComponent } from '../../../../../components/grid-switch/grid-switch.component';

@Component({
  selector: 'app-runs',
  templateUrl: './runs.component.html',
  styleUrls: ['./runs.component.scss']
})
export class RunsComponent implements OnInit {
  public busy: Subscription;
  gridRuns: GridOptions;
  public getRowNodeId;
  @Input() runs = [];
  @Input() route_id: number;
  form: FormGroup;
  @Output() reloadRuns?: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeModal?: EventEmitter<any> = new EventEmitter<any>();
  @Output() reloadData?: EventEmitter<any> = new EventEmitter<any>();
  clickSwitch = 0;

  constructor(private fb: FormBuilder, private runsService: RunsService) {
    this.setGrid();
    this.form = this.fb.group({
      description: '',
      route_id: '',
      id: '',
      active: true
    });
  }

  ngOnInit() {
    this.form.controls['route_id'].setValue(this.route_id);
  }

  setGrid() {
    this.gridRuns = <GridOptions>{
      enableSorting: true,
      sortingOrder: ['desc', 'asc', null],
      onGridReady: () => {
        this.gridRuns.api.setRowData(this.runs);
      },
      rowClass: 'fondo'
    };

    this.gridRuns.columnDefs = [
      { headerName: 'Description', field: 'description', width: 170 },
      { headerName: 'Status', width: 80, colId: 'btnDelete',
        cellStyle: { textAlign: 'center', cursor: 'pointer' },
        cellRendererFramework: GridSwitchComponent
      }
    ];

    this.getRowNodeId = (data) => {
      return data.id;
    };
  }

  onSave(closeModal = true) {
    if (this.form.controls['id'].value) {
      if (!this.form.controls['active'].value) {
        Swal.fire(
          {
            type: 'warning',
            text: `If you disable this run, it will be removed from all related blocks, do you want to continue?`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            focusCancel: true,
            cancelButtonColor: '#d33',
            confirmButtonColor: '#1464F6',
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn-confirm',
              cancelButton: 'btn-cancel'
            }
          }).then((result: any) => {
          if (result.value) {
            this.update(true, closeModal);
          }
        });
      } else {
        this.update(false, closeModal);
      }
    } else {
      this.busy = this.runsService.storeData(this.form.value).subscribe((resp: any) => {
        if (resp.status === 'success') {
          Swal.fire({
            position: 'top-end',
            type: 'success',
            title: 'Your run has been saved',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.reloadRuns.emit(true);
            this.closeModal.emit(true);
          });
        }
      });
    }
  }

  update(option: boolean, closeModal: boolean) {
    this.busy = this.runsService.updateData(this.form.value).subscribe((resp: any) => {
      if (resp.status === 'success') {
        Swal.fire({
          position: 'top-end',
          type: 'success',
          title: 'Your run has been saved',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          this.reloadRuns.emit(true);
          if (closeModal) {
            this.closeModal.emit(true);
          }
          if (option) {
            this.reloadData.emit(true);
          }
          this.gridRuns.api.refreshView();
          this.form.reset();
          this.form.controls['route_id'].setValue(this.route_id);
        });
      }
    });
  }

  onCellDoubleClicked(event: any) {
    this.form.patchValue({
      'id': event.data.id,
      'description': event.data.description,
      'route_id': event.data.route_id,
      'active': event.data.active
    });
  }

  oncellClicked(event) {
      if (event.colDef.colId === 'btnDelete') {
        event.data.active = ! event.data.active;
        this.onCellDoubleClicked(event);
        this.onSave(false);
      }
  }

}
