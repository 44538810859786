import { Component, OnInit } from '@angular/core';

/**
  * Componente estructural para el modulo de reglas del payroll
*/
@Component({
  selector: 'app-rules-component',
  templateUrl: 'rules.component.html',
  styleUrls: ['./rules.component.scss']
})

export class RulesComponent implements OnInit {
  constructor(

  ) { }

  ngOnInit() { }
}
