import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pages-smart',
  templateUrl: './pages-smart.component.html',
  styleUrls: ['./pages-smart.component.scss']
})
export class PagesSmartComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
