import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { GridSwitchComponent } from '../../../../../components/grid-switch/grid-switch.component';
import { RoutesService } from './../../../../../services/fleet/routes.service';
import { BsModalComponent } from 'ng2-bs3-modal';
import AgGridHelpers from './../../../../../helpers/ag-grid-helpers';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent implements OnInit {
  @Input() route_id: number;
  @ViewChild('modalAddContract') modalAddContract: BsModalComponent;

  public busy: Subscription[] = [];
  gridContracts: GridOptions;
  getContextMenuItems: any;
  contracts = [];
  contracts_filt = [];
  contract_id = null;
  showInactives = false;

  constructor(private fb: FormBuilder, private routesService: RoutesService) {
    this.loadGrid();
  }

  ngOnInit() {
    this.loadContracts();
  }

  loadContracts() {
    this.busy.push(
      this.routesService.getContractsRoute(this.route_id).subscribe((res: any) => {
        this.contracts = res;
        this.changeShowStatus(this.showInactives);
      })
    );
  }

  loadGrid() {
    this.gridContracts = <GridOptions>{
      enableSorting: true,
      sortingOrder: ['desc', 'asc', null],
      rowClass: 'fondo'
    };

    this.gridContracts.columnDefs = [
      { headerName: 'Day', field: 'day_data.day_name', width: 170 },
      { headerName: 'Hours', field: 'hours', width: 100 },
      { headerName: 'Minutes', field: 'minutes', width: 100 },
      { headerName: 'Status', width: 100, field: 'active',
        cellRenderer: (params) => AgGridHelpers.statusRecord(params.data.active)
      },
    ];

    this.getContextMenuItems = (params) => {
      const result = [
        {
          name: 'Edit',
          cssClasses: ['redFont', 'bold'],
          icon: '<i class="fa fa-edit"></i>',
          action: () => {
            this.contract_id = params.node.data.id;
            this.modalAddContract.open('sm');
          }
        },
      ];
      return result;
    };
  }

  changeShowStatus(status) {
    this.contracts_filt = this.contracts.filter(c => status ? true : (c.active));
  }

  add() {
    this.modalAddContract.open('sm');
    this.contract_id = null;
  }

  onSave(data) {
    this.loadContracts();
    this.modalAddContract.close();
  }
}
