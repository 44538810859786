import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BatchRoutingModule } from './batch-routing.module';
import { BatchViewComponent } from './batch-view/batch-view.component';
import { SharedModule } from '../../../shared/shared.module';
import { ThemeModule } from '../../../theme/theme.module';
import { AgGridModule } from 'ag-grid-angular/main';
import { NgBusyModule } from 'ng-busy';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap';
import { BatchesComponent } from './batches/batches.component';
import { ManifestsComponent } from './manifests/manifests.component';
import { InterruptionsComponent } from './interruptions/interruptions.component';
import { MyDatePickerModule } from 'mydatepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsModalModule } from 'ng2-bs3-modal';
import { AddBatchComponent } from './batch-view/add-batch/add-batch.component';
import { GetReportComponent } from './batches/get-report/get-report.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ReadyToBillComponent } from './ready-to-bill/ready-to-bill.component';
import { ManifestsModule } from '../../manifests/manifests.module';
import { HeaderFiltersModule } from '../../../components/header-filters/header-filters.module';
import { GridListComponent } from './batch-invoice/grid-list/grid-list.component';
import { GridListCreditsComponent } from './batch-credits/grid-list-credits/grid-list-credits.component';
import { AddInvoiceComponent } from './batch-invoice/add-invoice/add-invoice.component';
import { BatchInvoiceStatusComponent } from './batch-invoice-status/batch-invoice-status.component';
import { GridListInvoiceStatusComponent } from './batch-invoice-status/grid-list-invoice-status/grid-list-invoice-status.component';
import { AddInvoiceStatusComponent } from './batch-invoice-status/add-invoice-status/add-invoice-status.component';
import { AddInvoiceCreditComponent } from './batch-credits/add-invoice-credit/add-invoice-credit.component';
import { GridListCreditStatusComponent } from './batch-credits-status/grid-list-credit-status/grid-list-credit-status.component';
import { AddCreditStatusComponent } from './batch-credits-status/add-credit-status/add-credit-status.component';
import { CurrencyMaskModule } from 'ngx-currency-mask';
import { VerifiedToBillComponent } from './verified-to-bill/verified-to-bill.component';
@NgModule({
  imports: [
    SharedModule,
    ThemeModule,
    CommonModule,
    BatchRoutingModule,
    AgGridModule.withComponents([]),
    NgBusyModule,
    FormsModule,
    ReactiveFormsModule,
    TabsModule.forRoot(),
    MyDatePickerModule,
    BsDatepickerModule.forRoot(),
    BsModalModule,
    NgMultiSelectDropDownModule.forRoot(),
    ManifestsModule,
    HeaderFiltersModule,
    CurrencyMaskModule
  ],
  exports: [
    GridListComponent,
    GridListCreditsComponent
  ],
  declarations: [
    BatchViewComponent,
    BatchesComponent,
    ManifestsComponent,
    InterruptionsComponent,
    AddBatchComponent,
    GetReportComponent,
    ReadyToBillComponent,
    GridListComponent,
    GridListCreditsComponent,
    AddInvoiceComponent,
    BatchInvoiceStatusComponent,
    GridListInvoiceStatusComponent,
    AddInvoiceStatusComponent,
    AddInvoiceCreditComponent,
    GridListCreditStatusComponent,
    AddCreditStatusComponent,
    VerifiedToBillComponent
  ]
})
export class BatchModule { }
