import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {GridOptions} from 'ag-grid-community';
import {SweetAlertHelper} from '../../../../../CORE/helpers/sweet-alert-helper.service';
import {WorkflowService} from '../../../../../services/billing/workflow.service';
import {IResponse, ResponseStatus} from '../../../../../CORE/interfaces/response.interface';
import {InvoiceReportService} from '../invoice-report.service';
import {takeUntil} from 'rxjs/operators';
import {ISweetAlertFeeScheduleError} from '../../../../../CORE/interfaces/invoice-report.interface';
import {
  IPayloadBatch,
  ISummaryInvoiceReport,
  ISummaryInvoiceTotals
} from '../../../../../CORE/interfaces/workflow.interface';
import { inRange } from 'lodash';

@Component({
  selector: 'app-batch-summary',
  templateUrl: './batch-summary.component.html',
  styleUrls: ['./batch-summary.component.scss']
})
export class BatchSummaryComponent implements OnInit, OnDestroy {
  busy: Subscription[] = [];
  destroy: Subject<boolean> = new Subject<boolean>();

  gridSummary: GridOptions;
  progressVerification = 0;
  progressBatch = 0;

  startDate: string;
  endDate: string;
  typeDate: string;
  clientId: number;

  progressVerificationInfo = 'This represent the percentage between unverified and rejected schedules.';
  progressBatchInfo = 'This represent the percentage between batched and verified schedules.';

  constructor(
    private sweetAlertHelper: SweetAlertHelper,
    private workflowService: WorkflowService,
    private invoiceReportService: InvoiceReportService
  ) {
    this.initGridSummary();
  }

  ngOnInit(): void {
    this.subscribeToObservers();
  }

  ngOnDestroy(): void {
    this.busy.forEach((subscription: Subscription) => subscription.unsubscribe());
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  private subscribeToObservers(): void {
    this.subscribeToChangeSearchWorkflowBar();
    this.subscribeToInfoClient();
    this.subscribeRecalculate();
  }

  private initGridSummary(): void {
    this.gridSummary = <GridOptions>{
      cellStyle: {textAlign: 'center'},
      enableSorting: true,
      enableColResize: true,
      enableFilter: true,
      suppressAggFuncInHeader: true,
      sortable: true,
      resizable: true,
      animateRows: true,
      groupDisplayType: 'multipleColumns',
      groupMaintainOrder : true,
      groupDefaultExpanded: 1,
      groupIncludeFooter: true,
      groupIncludeTotalFooter: true,
      onGridReady: (): void => {
        this.gridSummary.api.sizeColumnsToFit();
        this.getSummary();
      },
      autoGroupColumnDef: {
        headerName: 'Clients',
        cellRendererParams: {
          suppressCount: true
        }
      },
      getRowStyle: (params): {} => {
        if (params.node.footer) {
          return {
            fontWeight: '700',
            backgroundColor: '#9BC2E6',
            color: '#FFF'
          };
        }
      },
      columnDefs: [
        { headerName: 'Route', field: 'route_name', cellStyle: {textAlign: 'left'}, sort: 'asc' },
        {
          headerName: 'Completed',
          children: [
            {
              headerName: 'Unverified',
              field: 'unverified',
              colId: 'completed_unverified',
              aggFunc: 'sum',
              width: 100,
              cellStyle: {textAlign: 'right'}
            },
            {
              headerName: 'Rejected',
              field: 'rejected',
              colId: 'completed_rejected',
              width: 100,
              aggFunc: 'sum',
              cellStyle: {textAlign: 'right'}
            },
            {
              headerName: 'Verified',
              field: 'verified',
              colId: 'completed_verified',
              width: 100,
              aggFunc: 'sum',
              cellStyle: {textAlign: 'right'}
            },
            {
              headerName: 'Batch',
              field: 'batch',
              colId: 'completed_batch',
              width: 100,
              aggFunc: 'sum',
              cellStyle: {textAlign: 'right'}
            }
          ]
        },
        {
          headerName: 'Batch',
          children: [
            {
              headerName: 'Total',
              field: 'total',
              colId: 'completed_total',
              width: 100,
              aggFunc: 'sum',
              cellStyle: {textAlign: 'right'}
            }
          ]
        }
      ],
    };
  }

  private subscribeToChangeSearchWorkflowBar(): void {
    this.workflowService.infoByExternalFormToInvoiceReportAction$.pipe(takeUntil(this.destroy))
      .subscribe({
        next: ({date, clients_id}): void => {
          const {start_date, end_date} = date;
          this.startDate = start_date;
          this.endDate = end_date;
          this.clientId = clients_id;
        }
      });
  }

  private subscribeToInfoClient(): void {
    this.invoiceReportService.infoByClientAction$.pipe(takeUntil(this.destroy))
      .subscribe({
        next: (data): void => {
          const {type_range_date} = data;
          this.typeDate = type_range_date;
        }
      });
  }
  private subscribeRecalculate(): void {
    this.invoiceReportService.reloadRecalculateAction$.subscribe(resp => {
        this.reload();
    });
  }

  private getSummary(): void {
    const payload: IPayloadBatch = this.createPayloadToBatch();
    this.busy.push(
      this.workflowService.getSummaryUnprocessedTab(payload).subscribe({
        next: ({data}: IResponse<ISummaryInvoiceReport[]>): void => {
          this.gridSummary.api.setRowData(data);
          this.calculatePercentages(data);
        },
        error: error => this.sweetAlertHelper.captureException(error)
      })
    );
  }

  public generateBatch(): void {
    const payload: IPayloadBatch = this.createPayloadToBatch();
    this.busy.push(
      this.workflowService.generateBatch(payload).subscribe({
        next: ({status, data: message}): void => {
          if (status === 'success') {
            this.sweetAlertHelper.createCustomAlert({
              title: message,
              type: 'success',
              showConfirmButton: true
            }).then((): void => {
              this.invoiceReportService.reloadInfoBatch();
              this.getSummary();
            });
          }
        },
        error: (httpResponseError): void => {
          const { body } = httpResponseError.error.data;
          body
            ? this.sweetAlertFeeScheduleError(httpResponseError)
            : this.captureException(httpResponseError);
        }
      })
    );
  }

  private captureException(httpResponseError): void {
    const { status , data: message } = httpResponseError.error;
    if (ResponseStatus.FAIL !== status) {
      this.sweetAlertHelper.captureException(httpResponseError).then();
    } else {
      this.sweetAlertHelper.createCustomAlert({
        type: 'warning',
        title: '',
        text: message
      }).then();
    }
  }

  public reload(): void {
    this.getSummary();
    this.invoiceReportService.reloadInfoBatch();
    const payload: IPayloadBatch = this.createPayloadToBatch();
    this.invoiceReportService.recalculate(payload);
  }

  private createPayloadToBatch(): IPayloadBatch {
    return {
      'date_start': this.startDate,
      'date_end': this.endDate,
      'client_id': this.clientId
    };
  }

  private calculatePercentages(data: ISummaryInvoiceReport []): void {
    const initTotal: ISummaryInvoiceTotals = {
      total: 0,
      verified: 0,
      batch: 0,
      unverified: 0,
      rejected: 0
    };

    const allTotals: ISummaryInvoiceTotals = data
      .reduce((acc: ISummaryInvoiceTotals, el: ISummaryInvoiceTotals): ISummaryInvoiceTotals => (
        {
          total: acc.total + el.total,
          verified: acc.verified + el.verified,
          batch: acc.batch + el.batch,
          unverified: acc.unverified + el.unverified,
          rejected: acc.rejected + el.rejected,
        }
      ), initTotal);

    const {unverified, rejected, batch, verified, total} = allTotals;
    const inProgressOrVerification: number = unverified + rejected + verified;
    const inProgressOrInBatch: number = batch;

    this.progressVerification = Number(((verified * 100) / inProgressOrVerification).toFixed(2));
    this.progressBatch = Number(((inProgressOrInBatch * 100) / total).toFixed(2));
  }

  private sweetAlertFeeScheduleError (httpResponseError): void {
    const type = 'error';
    const clientId = this.clientId;
    const { error } = httpResponseError;
    const { body, title }: ISweetAlertFeeScheduleError = error.data;
    const html: string = body.reduce((acc: string, label: string, currentIndex: number): string => {
      return (`<p style="font-size: 1.5em; text-transform: uppercase"> ${(currentIndex + 1)}. ${label} </p>`);
    }, '');

    this.sweetAlertHelper.createCustomAlert({
      type,
      title,
      html,
      confirmButtonText: 'Apply Rate'
    }).then((): void => {
      this.invoiceReportService.openFeeScheduleModalByBatchError({open: true, listOfCar: body, clientId: clientId});
    });
  }

  get colorProgressVerification(): string {
    if (inRange(this.progressVerification, 61, 101) ) {
      return 'progress-bar progress-bar-striped active';
    } else if (inRange(this.progressVerification, 31, 60)) {
      return 'progress-bar progress-bar-warning progress-bar-striped active';
    } else if (inRange(this.progressVerification, 0, 30)) {
      return 'progress-bar progress-bar-danger progress-bar-striped active';
    } else {
      return 'progress-bar progress-bar-danger progress-bar-striped active';
    }
  }
  get colorProgressBatch(): string {
    if (inRange(this.progressBatch, 61, 101) ) {
      return 'progress-bar progress-bar-striped active';
    } else if (inRange(this.progressBatch, 31, 60)) {
      return 'progress-bar progress-bar-striped active';
    } else if (inRange(this.progressBatch, 0, 30)) {
      return 'progress-bar progress-bar-danger progress-bar-striped active';
    } else {
      return 'progress-bar progress-bar-danger progress-bar-striped active';
    }
  }
}
