import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StopsService {
  protected resource: string;

  constructor(private http: HttpClient) {
    this.resource = environment.url_api_v;
  }

  list() {
    return this.http.get(`${environment.url_api_v}stops`);
  }

  post(data: any) {
    return this.http.post(`${environment.url_api_v}stops`, JSON.stringify(data));
  }

  put(data: any, id: any) {
    return this.http.put(`${environment.url_api_v}stops/${id}`, JSON.stringify(data));
  }

}
