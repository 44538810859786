import { AgGridModule } from 'ag-grid-angular/main';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HistoryPunchesComponent } from './history-punches.component';
import { TimecardsService } from './../../services/payroll/timecards.service';

@NgModule({
  imports: [
    CommonModule,
    AgGridModule,
  ],
  exports: [HistoryPunchesComponent],
  declarations: [HistoryPunchesComponent],
  providers: [TimecardsService],
})
export class HistoryPunchesModule { }
