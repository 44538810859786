import { Component, OnInit, ViewChild } from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';
import { AdminDataService } from './driver.data.service';
import { DriverManifest } from './models';


@Component({
  selector: 'app-driver-manifest',
  templateUrl: './driver-manifest.component.html',
  styleUrls: ['./driver-manifest.component.scss'],
  providers: [AdminDataService]
})
export class DriverManifestComponent implements OnInit {

  @ViewChild('staticTabs') staticTabs: TabsetComponent;

  busy: Subscription;
  dataManifest: any = {};
  tabAddDrivers = false;
  tabHistory = false;
  tabAddVehicles = false;
  tabAddStops = false;
  tabInterruptions = false;
  manifestBilled = false;

  constructor(
    private _dataService: AdminDataService,
  ){

  }
  ngOnInit() {

  }


  loadDataOne(dataManifest): void {
    this.dataManifest = dataManifest;
  }

  selectTab(tabId: number) {
    this.staticTabs.tabs[tabId].active = true;
  }

  loadParent() {
    this._dataService.reloadGridRightPanel$.emit({});
  }

  resetParent() {
    this.dataManifest = {};
  }

}
