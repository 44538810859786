import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StopsService } from '../../../../../../../services/stops/stops.service';
import * as L from 'leaflet';
import { TsoService } from '../../../../../../../services/Tso.service';
import { Subscription } from 'rxjs/Rx';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-add-stop-to-route',
  templateUrl: './add-stop-to-route.component.html',
  styleUrls: ['./add-stop-to-route.component.scss']
})
export class AddStopToRouteComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('modalAddStop') modalAddStop: any;

  busy: Subscription;
  @Input('dataBlock') dataBlock?: any;
  @Input('dataEdit') dataEdit?: any;
  @Output('onClose') onClose? = new EventEmitter<any>();
  route_blocks: any = [];
  list_stops: any = [];
  form: FormGroup;
  private map: L.Map;
  private marker: any;
  myIcon = L.icon({
    iconUrl: 'assets/img/marker_1.png',
    iconSize: [38, 38]
  });
  public time_format = [/[0-9]/, /\d/, ':', /\d/, /\d/];
  public pattern_time = '(0[0-9]|1[0-9]|2[0-9]|3[0-6])(:[0-5][0-9]){1}';

  constructor(
    private fb: FormBuilder,
    private stopsService: StopsService,
    private tsoService: TsoService,
    private _sanitizer: DomSanitizer
  ) {
    this.initForm();
  }

  ngOnInit() {
    this.loadStops();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataBlock']) {
      this.route_blocks = this.dataBlock.route_blocks;
      this.form.controls['route_tso_id'].setValue(this.dataBlock.tso_id);
    }

    if (changes['dataEdit'] && this.dataEdit) {
      this.form.patchValue({
        id: this.dataEdit.id,
        sequence: this.dataEdit.sequence,
        stop_number: this.dataEdit.stop_number,
        route_tso_id: this.dataEdit.route_tso_id,
        active: this.dataEdit.active,
        route_block_id: this.dataEdit.route_block_id,
        arrival_time: this.dataEdit.arrival_time,
        departure_time: this.dataEdit.departure_time,
        stop_id: {
          id: this.dataEdit.stop.id,
          description: `${this.dataEdit.stop.description}`
        }
      });
      const latlng = L.latLng({
        lat: this.dataEdit.stop.latitude,
        lng: this.dataEdit.stop.longitude
      });
      setTimeout(() => {
        this.addMarker(latlng);
      }, 800);
    }
  }

  ngAfterViewInit() {
    this.initMap();
    this.onMapReady(this.map);
  }

  initForm() {
    this.form = this.fb.group({
      id: [''],
      sequence: ['', Validators.required],
      stop_number: ['', Validators.required],
      route_tso_id: [''],
      active: [true],
      route_block_id: ['', Validators.required],
      arrival_time: ['', Validators.required],
      departure_time: ['', Validators.required],
      stop_id: ['', Validators.required]
    });
  }

  loadStops() {
    this.busy = this.stopsService.list().subscribe((resp: any) => {
      this.list_stops = resp.data;
    });
  }

  onchangeStop(event: any) {
    const data = this.list_stops.filter(row => row.id === event);
    const latlng = L.latLng({
      lat: data[0].latitude,
      lng: data[0].longitude
    });
    this.addMarker(latlng);
  }

  addMarker(latlng: any) {
    if (this.marker) {
      this.map.removeLayer(this.marker);
    }
    this.marker = L.marker(latlng, {
      icon: this.myIcon
    });
    this.marker.addTo(this.map);
    this.map.setView(latlng, 16);
  }

  private initMap() {
    this.map = L.map('mapadd').setView([25.7742691, -80.1936569], 13);
    L.tileLayer(
      'https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWF0aGl3cyIsImEiOiJja20yNnB4cWMwMXBhMm9wZ2l6M2Zxajk3In0.0SkYKscswdRRKzulnABbtw',
      {
        maxZoom: 20,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
      }
    ).addTo(this.map);
  }

  onMapReady(map: L.Map) {
    setTimeout(() => {
      map.invalidateSize();
    }, 0);
  }

  onSave() {
    const dataForm = Object.assign({}, this.form.getRawValue());
    if (dataForm.stop_id) {
      dataForm.stop_id = dataForm.stop_id.id;
      if (dataForm.id) {
        this.busy = this.tsoService.putRoutesTsoStops(dataForm, dataForm.id).subscribe((resp: any) => {
          if (resp.status === 'success') {
            this.onClose.emit(true);
          }
        });
      } else {
        delete dataForm.id;
        this.form.removeControl('id');
        this.busy = this.tsoService.postRoutesTsoStops(dataForm).subscribe((resp: any) => {
          if (resp.status === 'success') {
            this.onClose.emit(true);
          }
        });
      }
    }
  }

  myValueFormatter(data: any): string {
    const html = data.description;
    return html;
  }
  autocompleListFormatter = (data: any) => {
    const html = `<span>${data.description} </span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  };
}
