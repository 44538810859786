/**
 * Created sebtiembre 2017
 * @author Miguel Lopez Ariza
 * @description Este componente es el encargado de la gestion de los Shcedules
 */
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import * as moment from 'moment';
import { BsModalComponent } from 'ng2-bs3-modal';
import { Subscription } from 'rxjs';
import { DashboardLsfDataService } from './../dashboard-lsf.data.service';
import { Schedule } from './schedule.model';



@Component({
  selector: 'app-dashboard-lsf',
  templateUrl: './dashboard-lsf.component.html',
  styleUrls: ['./dashboard-lsf.component.scss']
})

export class DashboardLsfComponent implements OnInit {

  @ViewChild('modal') modal: BsModalComponent;

  busy: Subscription;
  gridLsf: GridOptions;

  years;
  yearActual = new Date().getFullYear();
  dateDisable: Array<number>;
  arrayWeek: Array<number>;
  yearSelected: any;

  gridApi;
  gridColumnApi;

  constructor(private  _dataService: DashboardLsfDataService, private router: Router ) {
    this.gridLsf = <GridOptions>{
      enableSorting: true,
      sortingOrder: ['desc', 'asc', null],
      onGridReady: () => {
        this.loadData(this.yearActual);
      }
    };

    this.gridLsf.columnDefs = [
      { headerName: 'Schedule ID', field: 'id', width: 110 },
      { headerName: 'Week Nbr', field: 'week', width: 100 },
      { headerName: 'From', field: 'datestart', width: 90 },
      { headerName: 'To', field: 'dateend', width: 90 },
      { headerName: '# of Routes', field: 'routes', width: 100 },
      { headerName: 'Created By', field: 'name', width: 300 },
    ];

  }

  ngOnInit() {
    this.getYear();
    this.generateWeek(this.yearActual);
    this.yearSelected = this.yearActual;
  }

  OnCellDoubleClicked($event) {
    this.router.navigate(['/dashboard/schedules/', $event.data.id]);
  }

  onNewSchedule() {
    this.modal.open();
  }

  onClose() {
    this.modal.close();
  }

  loadData(year: any) {
    this.busy = this._dataService.getData('schedules/year/' + year).subscribe(
      (data: any) => {
        this.gridLsf.api.setRowData(data);
        this.autoSizeAll();
        this.getDateRangesDisable(data);
      }
    );
  }

  getYear() {
    let range = [];
    for (var i = 2017; i < 2099; i++) {
      range.push(i);
    }
    this.years = range;
  }

  loadDataYear(year: number) {
    this.loadData(year);
    this.generateWeek(year);
    this.yearSelected = year;
  }


  /**
   * @description Este metodo se encarga de armar el json que desactivara las semanas que estan seleccionadas
   * ya en los schedule, para lo cual utilizaremos las fecha iniciales y finales de cada schedule
   */
  getDateRangesDisable(row: Schedule[]) {
    let tem: Array<number> = new Array<number>();
    row.forEach(function (data, index) {
      tem.push(data.week);
    });
    this.dateDisable = tem;
    this.generateWeek(this.yearActual);
    this.clearArrayWeeks(tem);
  }

  /**
   * @description Esta funcion recibe como parametro el año y retorna el numero de semanas del mismo para armas
   * el array de semanas
   * @param year
   */
  weeksInYear(year: any) {
    const weeksNum = moment(new Date(year, 11, 31)).isoWeek();
    return Math.max(
      weeksNum,
      moment(new Date(year, 11, 31 - 7)).isoWeek(),
      weeksNum + 1
    );
  }

  // Esta funcion arma un array con el numero de semanas.
  generateWeek(year: any) {
    const numWeek = this.weeksInYear(year);
    this.arrayWeek = new Array<any>();
    for (var i = 1; i <= numWeek; i++) {
      this.arrayWeek.push(i);
    }
  }

  clearArrayWeeks(tem: Array<any>) {
    for (var i = 0; i <= tem.length - 1; i++) {
      var index = this.arrayWeek.indexOf(Number.parseInt(tem[i]));
      if(index >= 0) {
        this.arrayWeek.splice(index, 1);
      }
    }

  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  autoSizeAll() {
    let allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds);
  }

}
