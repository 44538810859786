import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { ColDef, GridOptions } from 'ag-grid-community';
import { InvoiceService } from '../../../services/billing/invoice.service';
import { ReportsManageService } from '../../../services/reports-manage.service';
import { CompanyService } from '../../../services/companies/company.service';
import { AuthService } from '../../../services/auth.service';
import AgGridHelpers from '../../../helpers/ag-grid-helpers';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-buses-fueled',
  templateUrl: './buses-fueled.component.html',
  styleUrls: ['./buses-fueled.component.scss']
})
export class BusesFueledComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  private destroy: Subject<boolean> = new Subject<boolean>();

  @Input() onClearGrid?= false;
  busy: Subscription[] = [];
  grid: GridOptions;
  grid_columns = [];
  dataParams: any = {};
  getContextMenuItems;

  constructor(
    private invoiceService: InvoiceService,
    private companyService: CompanyService,
    private reportsManageService: ReportsManageService,
    private authService: AuthService
  ) {
    this.initGrid();
  }

  ngOnInit() {
    this.invoiceService.load_buses$
    .pipe(takeUntil(this.destroy))
    .subscribe(res => {
      this.dataParams = res;
      this.onSearch();
    })
  }

  ngAfterViewInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['onClearGrid']) {
      if (this.onClearGrid && this.grid.api) {
        this.grid.api.setRowData([]);
      }
    }
  }

  ngOnDestroy() {
    this.destroy.next(true);
    this.destroy.unsubscribe();
    return this.busy = [];
  }

  initGrid() {
    this.grid = <GridOptions>{
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      rowSelection: 'single',
      enableRangeSelection: true,
      onGridReady: () => {
        this.grid.api.setRowData([]);
      }
    };
    this.grid.getRowClass = params => {
      if (params.data) {
        if (params.data.total_for_day === 'Number of buses - Grand Total') {
          return 'item_total_grid';
        }
      }
    };
    this.getContextMenuItems = (params) => {
      const x = this.grid;
      const result = [
        AgGridHelpers.itemMenu('Print', 'fa-print', () => {
          this.printbusesFueled();
        }),
        'separator',
        'copy',
        'export',
      ];
      return result;
    };
  }

  onSearch() {
    const data: any = Object.assign({}, this.dataParams);
    const params = {
      client_id: data.client_id,
      start_date: data.start_date,
      end_date: data.end_date
    }
    if(params.client_id && params.start_date && params.end_date) {
      this.busy.push(this.invoiceService.getBusesFueled(params).subscribe((resp) => {
        const list = Object.assign([], resp);
        const columns: ColDef[] = [
          { headerName: 'Date', field: 'date', rowGroup: true, hide: true },
          { headerName: 'Total For Day', field: 'total_for_day', width: 300 },
          { headerName: 'Total', field: 'total', width: 100 },
        ];
        const rows = [];
        for (let i = 0; i < list.length; i++) {
          const element = list[i];
          for (let j = 0; j < element.totals.length; j++) {
            const subelement = element.totals[j];
            const row = {
              date: element.date,
            };
            Object.keys(subelement).forEach(key => {
              row[key] = subelement[key];
            });
            rows.push(row);
            Object.keys(subelement).filter(k => (k !== 'total_for_day' && k !== 'total')).forEach(key => {
              const idx = columns.map(c => c.field).indexOf(key);
              if (idx === -1) {
                columns.push({
                  headerName: key,
                  field: key,
                  width: 140, rowGroup: false,
                  cellRenderer: (params) => {
                    return params.data ? params.data[key] ? params.data[key] : '0' : '';
                  },
                });
              }
            });
          }
          const row = {
            date: element.date,
            total_for_day: 'Number of buses - Grand Total',
            total: element.total,
          };
          Object.keys(element).filter(k => (k !== 'total_for_day' && k !== 'total' && k !== 'totals')).forEach(key => {
            row[key] = element[key];
          });
          rows.push(row);
        }
        this.grid_columns = columns;
        if (this.grid.api) {
          this.grid.api.setRowData(rows);
          this.autoSizeAll();
        }
      }));
    }
  }

  autoSizeAll() {
    const allColumnIds = [];
    this.grid.columnApi.getAllColumns().forEach(function (column: any) {
      allColumnIds.push(column.colId);
    });
    this.grid.columnApi.autoSizeColumns(allColumnIds);
  }

  printbusesFueled() {
    const parameter: any = {
      'client_id': [
        `${this.dataParams.client_id}`
      ],
      'dateOfService': [
        `${this.dataParams.start_date}`,
        `${this.dataParams.end_date}`
      ]
    };
    this.companyService.getCompany(Number(this.authService.getCompanyId())).subscribe((resp) => {
      if (resp.status === 'success') {
        this.reportsManageService.getTokenTrip2(resp.data.tenant, resp.data.tenant_key).then(() => {
          if (this.reportsManageService.tokenTrip2Report) {
            this.busy.push(this.reportsManageService.getReportTrip2(2152, parameter).subscribe((resp: string) => {
              if (resp) {
                const win = window.open(`${resp}&s=${this.reportsManageService.tokenTrip2Report}`, '_blank');
                win.focus();
              }
            }));
          }
        });
      }
    });
  }

}
