import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CONSTANTS } from '../../app.constants';
import { BaseService } from '../../core/BaseService';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable()
export class PermissionService extends BaseService {
  protected resource: string;

  constructor(private http: HttpClient, private ngxPermissionsService: NgxPermissionsService) {
    super();
    this.resource = environment.url_api_v + CONSTANTS.PERMISSIONS;
  }

  /**
   * @returns Listado de permisos
   */
  getPermissions(): Observable<any> {
    return this.http.get<any>(this.resource).pipe(catchError(this.handleError));
  }

  /**
   * @returns Servicio para registrar permisos
   */
  storePermissions(data: any): Observable<Response> {
    return this.http.post<Response>(this.resource, JSON.stringify(data));
  }

  /**
   * @returns Servicio para actualizar permisos
   */
  updatePermission(data: any, id: number): Observable<Response> {
    return this.http.put<Response>(
      this.resource + '/' + id,
      JSON.stringify(data)
    );
  }

  /**
   * @returns Consultar informacion de un permiso
   */
  getPermission(id: number): Observable<any> {
    return this.http.get(this.resource + '/' + id);
  }

  /**
   * @returns Funcion para validar permiso
   */
  validatePermission(permission: string) {
    return new Promise((resolve, reject) => {
      this.ngxPermissionsService.hasPermission(permission).then((value) => {
        resolve(value);
      });
    });
  }
}
