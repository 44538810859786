import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../app.data.service';

declare let $: any;

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  providers: [DataService]
})

export class LayoutComponent implements OnInit, AfterViewInit {
  /*showDialog = false;

  busy: Subscription;

  @ViewChild('modal_profile')
  modalProfile: BsModalComponent;

  @ViewChild('modal_change_password')
  modalChangePassword: BsModalComponent;

  @ViewChild('modal_change_picture')
  modalChangePicture: BsModalComponent;

  infoUser: User = new User();

  public companies: any = {};
  public companiesStore: any = {};
  public showSelectorCompanies = false;

  enviromenCurrent: string;
  enviromenShow: boolean; */

  constructor( private router: Router, public toastr: ToastrService, private _dataService: DataService ) {
    /*this.enviromenCurrent = environment.envName;
    environment.production ? this.enviromenShow = false : this.enviromenShow = true; */
  }

  ngOnInit() {
    /*if (localStorage.getItem('lastLogin')) {
      $('.sidebar-toggler').click();
      this.verfyLoginDay();
    } else {
      this.infoUser = JSON.parse(localStorage.getItem('currentUser'));
      $('.sidebar-toggler').click();
    }

    if (localStorage.getItem('companies')) {
      this.companies = JSON.parse(localStorage.getItem('companies'));
      if (this.companies.length > 1) {
        this.selectecCompanies(this.companies);
      } else {
      }
    }

    if (!localStorage.getItem('currentUser')) {
      this.router.navigate(['/login']);
      location.reload();
    } */
  }

  ngAfterViewInit() {}

  /*selectecCompanies(companies_: any) {
    const dbname = localStorage.getItem('namedb');
    for (let comp of companies_) {
      if (dbname === comp.company.namedb) {
        this.companiesStore = comp.company.namedb;
      }
    }
  }

  changeCompanie(event: any) {
    localStorage.setItem('namedb', event.target.value);
    location.reload();
  }

  onLogout() {
    this._dataService.getData('me/logout').subscribe(
      datos => {
        this.onDestroyer();
      }, () => {
        this.onDestroyer();
      }
    );
  }

  onDestroyer() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    localStorage.removeItem('lastLogin');
    localStorage.removeItem('namedb');
    localStorage.removeItem('companies');
    localStorage.removeItem('token_tso_main');
    localStorage.removeItem('token_tso');
    localStorage.removeItem('companyid');
    this.router.navigate(['/login']);
  }

  onClose(num: number) {
    switch (num) {
      case 1:
        this.modalProfile.close();
        break;
      case 2:
        this.modalChangePassword.close();
        break;
      case 3:
        this.modalChangePicture.close();
        break;
    }
  }

  onNotify(mensaje: any) {
    this.toastr.success(mensaje);
  }

  changeMenu() {
    alert('Oculta menu');
  }

  verfyLoginDay() {
    const latlogin = localStorage.getItem('lastLogin');
    const date = new Date().toDateString();

    if (latlogin !== date) {
      this.onLogout();
    } else {
      this.infoUser = JSON.parse(localStorage.getItem('currentUser'));
    }
  }

  openViewDay() {
    this.getScheduleID();
  }

  getScheduleID() {
    const year = moment().weekYear();
    const week = moment().week();
    this.busy = this._dataService
      .getData('schedules/year/' + year + '/week/' + week + '')
      .subscribe(data => {
        if (data.length > 0) {
          this.router.navigate([
            '/dashboard/schedules/' + data[0].id + '/' + true
          ]);
        } else {
          this.router.navigate(['/dashboard/lsf']);
        }
      });
  } */
}
