import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs-compat';
import Swal from 'sweetalert2';
import { AuthService } from '../services/auth.service';
import { UnsubscribeService } from './unsubscribe.service';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})

export class UnsubscribeComponent implements OnInit {
  busy: Subscription;
  form: FormGroup;
  constructor(
    public _serviceAuth: AuthService,
    private fb: FormBuilder,
    private unsubscribeService: UnsubscribeService
  ) {
    this.initForm();
  }

  ngOnInit() {}

  initForm() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(new RegExp('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$'))]]
    });
  }

  onUnsubscribe() {
    if (this.form.valid) {
      this.unsubscribeService.putUnsubscribe(this.form.value).subscribe(() => {
        Swal.fire({
          title: 'Unsubscribed',
          text: 'You have been unsubscribed from all TA Scheduler lists.',
          type: 'info'
        }).then(() => {
          this.form.reset();
        });
      });
    }
  }
}
