

import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { filter, map, mergeMap } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthService } from './services/auth.service';
import { LoggingService } from './services/log/logging.service';
import { NotificationService } from './components/services/push/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    public _serviceAuth: AuthService,
    updates: SwUpdate,
    private meta: Meta,
    private ngxPermissionsService: NgxPermissionsService
    // private loggingService: LoggingService
  ) {
      this.setMeta();
      updates.available.subscribe( event => {
        updates.activateUpdate().then(() => document.location.reload());
      });
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data))
      .subscribe(event => this.titleService.setTitle(event['title'])
    );
  }

  setMeta() {
    this.meta.addTag({ name: 'description', content: 'TA Schedule.'});
    this.meta.addTag({ name: 'author', content: 'Transportation America'});
    this.meta.addTag({ name: 'Drivers, Vehicles, Schedule'});
  }

}
