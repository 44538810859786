import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {AddMissedServiceEventComponent} from '../missed-service-events/add-missed-service-event/add-missed-service-event.component';
import {CommonModule} from '@angular/common';
import {SummaryComponent} from '../summary/summary.component';
import {UnprocessedBlockComponent} from '../unprocessed-block/unprocessed-block.component';
import {InterruptionDetailsBillingComponent} from '../interruption-details/interruption-details.component';
import {BusesFueledComponent} from '../buses-fueled/buses-fueled.component';
import {BusesFueledGridComponent} from '../buses-fueled/buses-fueled-grid/buses-fueled-grid.component';
import {BusesFueledWeekComponent} from '../buses-fueled/buses-fueled-week/buses-fueled-week.component';
import {ImportBusesFueledComponent} from '../import-buses-fueled/import-buses-fueled.component';
import {ReplacementLogComponent} from '../replacement-log/replacement-log.component';
import {LiquidatedDamageComponent} from '../liquidated-damage/liquidated-damage.component';
import {MissedServiceEventsComponent} from '../missed-service-events/missed-service-events.component';
import {SharedModule} from '../../../shared/shared.module';
import {ThemeModule} from '../../../theme/theme.module';
import {BsModalModule} from 'ng2-bs3-modal';
import {MyDateRangePickerModule} from 'mydaterangepicker';
import {AgGridModule} from 'ag-grid-angular';
import {BsDatepickerModule, TabsModule} from 'ngx-bootstrap';
import {NgBusyModule} from 'ng-busy';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {Ng2AutoCompleteModule} from 'ng2-auto-complete';
import {TextMaskModule} from 'angular2-text-mask';
import {MyDatePickerModule} from 'mydatepicker';
import {BatchModule} from '../batch/batch.module';
import {HeaderFiltersModule} from '../../../components/header-filters/header-filters.module';
import {ShowErrorsModule} from '../../../tools/showErrosForm.directive';
import {CurrencyMaskModule} from 'ngx-currency-mask';
import {UnprocessedRunsComponent} from '../unprocessed-runs/unprocessed-runs.component';
import {ManifestsModule} from '../../manifests/manifests.module';
import {DashboardLsfModule} from '../../dashboard-lsf/dashboard-lsf.module';
import {AddLiquidatedDamageComponent} from '../liquidated-damage/add-liquidated-damage/add-liquidated-damage.component';
import {InterruptionManualFormComponent} from '../interruption-details/interruption-manual-form/interruption-manual-form.component';
import {AddFueledComponent} from '../buses-fueled/buses-fueled-week/add-fueled/add-fueled.component';
import {UploatedFilesFueledComponent} from '../import-buses-fueled/fueled-uploated-files/fueled-uploated-files.component';

@NgModule({
  imports: [
    SharedModule,
    ThemeModule,
    CommonModule,
    BsModalModule,
    MyDateRangePickerModule,
    AgGridModule.withComponents([]),
    BsDatepickerModule.forRoot(),
    NgBusyModule,
    FormsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    Ng2AutoCompleteModule,
    TextMaskModule,
    TabsModule.forRoot(),
    MyDatePickerModule,
    BatchModule,
    HeaderFiltersModule,
    ShowErrorsModule,
    CurrencyMaskModule,
    ManifestsModule,
    DashboardLsfModule
  ],
  exports: [
    SummaryComponent,
    UnprocessedBlockComponent,
    InterruptionDetailsBillingComponent,
    BusesFueledComponent,
    BusesFueledGridComponent,
    BusesFueledWeekComponent,
    ImportBusesFueledComponent,
    ReplacementLogComponent,
    LiquidatedDamageComponent,
    MissedServiceEventsComponent,
    UnprocessedRunsComponent,
    AddMissedServiceEventComponent,
    AddLiquidatedDamageComponent,
    InterruptionManualFormComponent
  ],
  declarations: [
    SummaryComponent,
    UnprocessedBlockComponent,
    InterruptionDetailsBillingComponent,
    BusesFueledComponent,
    BusesFueledGridComponent,
    BusesFueledWeekComponent,
    ImportBusesFueledComponent,
    ReplacementLogComponent,
    LiquidatedDamageComponent,
    MissedServiceEventsComponent,
    UnprocessedRunsComponent,
    AddMissedServiceEventComponent,
    AddLiquidatedDamageComponent,
    InterruptionManualFormComponent,
    AddFueledComponent,
    UploatedFilesFueledComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UnprocessedModule { }
