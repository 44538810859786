import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScheduleDetailsService } from '../../../services/scheduleDetails.service';
import { GridOptions } from 'ag-grid-community';
import * as moment from 'moment';
import { Subscription } from 'rxjs-compat';
import { SweetAlertHelper } from '../../../CORE/helpers/sweet-alert-helper.service';
import { ReportsManageService } from '../../../services/reports-manage.service';
import { AfterViewInit } from '@angular/core';
import { EnumDownloadReportOption } from '../EnumDownloadReportOption';

@Component({
  selector: 'app-grid-controls',
  templateUrl: './grid-controls.component.html',
  styleUrls: ['./grid-controls.component.scss']
})
export class GridControlsComponent implements OnInit, AfterViewInit {
  @Input() ApiGrid?: GridOptions;
  @Input() gridName? = 'grid-driver-manifest';
  @Input() rangeDate?: any;
  @Input() downloadOrViewReportOption?: string;
  @Output() reloadData?: EventEmitter<boolean> = new EventEmitter<boolean>();
  busy: Subscription[] = [];

  constructor(
    private reportsManageService: ReportsManageService,
    private sweetAlertHelper: SweetAlertHelper,
    private scheduleDetailsService: ScheduleDetailsService,
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.loadConfGrid();
  }

  clearSort() {
    this.ApiGrid.api.setSortModel(null);
  }

  clearFilters() {
    this.ApiGrid.api.setFilterModel(null);
  }

  downloadOrViewReport(): void {
    switch (this.downloadOrViewReportOption) {
      case EnumDownloadReportOption.Excel:
        this.exportExcel();
        break;
      case EnumDownloadReportOption.ViewReportErrorInMilesService:
        this.getErrorInMilesService();
        break;
    }
  }

  getErrorInMilesService() {
    if (this.rangeDate) {
      const date: any = {
        DateofService: [
          `${moment(this.rangeDate.beginJsDate).format('MM-DD-YYYY')}`,
          `${moment(this.rangeDate.endJsDate).format('MM-DD-YYYY')}`
        ]
      };
      this.busy.push(
        this.reportsManageService.getReportTrip2(2129, date, true).subscribe((resp: string) => {
          if (resp) {
            this.reportsManageService.loadReportWindows(resp);
          }
        })
      );
    }
  }

  exportExcel(): void {
    this.ApiGrid.api.exportDataAsExcel();
  }

  loadConfGrid() {
    this.busy.push(this.scheduleDetailsService.getConfigGrid(this.gridName).subscribe(
      data => {
        if (data.data && this.ApiGrid.columnApi) {
          this.ApiGrid.columnApi.setColumnState(JSON.parse(data.data.config));
        }
      }, error => {
        this.sweetAlertHelper.captureException(error).then();
      }
    ));
  }

  saveGridLayout() {
    const model = this.ApiGrid.columnApi.getColumnState();
    this.busy.push(
      this.scheduleDetailsService.setConfigGrid(this.gridName, JSON.stringify(model)).subscribe(
        () => {
          this.sweetAlertHelper
            .createCustomAlert({
              position: 'top-end',
              title: 'Your layout has been saved',
              type: 'success',
              timer: 1500,
              showConfirmButton: false
            })
            .then(() => {});
        },
        error => {
          this.sweetAlertHelper.captureException(error).then();
        }
      )
    );
  }

  onReloadData() {
    this.reloadData.emit(true);
  }
}
