import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CellClickedEvent, GridOptions, RowDoubleClickedEvent } from 'ag-grid-community';
import { Subject, Subscription } from 'rxjs';
import { ClientService } from '../../../../../services/fleet/client.service';
import { BsModalComponent } from 'ng2-bs3-modal';
import { IFeeSchedule } from './fee-schedule.interface';
import { takeUntil } from 'rxjs/operators';
import { InvoiceReportService } from '../../../../billing/workflow/invoice-report/invoice-report.service';
import { ISubjectFeeScheduleErrorModal } from '../../../../../CORE/interfaces/invoice-report.interface';
import { WorkflowService } from '../../../../../services/billing/workflow.service';
import AgGridHelpers from '../../../../../helpers/ag-grid-helpers';
import * as moment from 'moment';

@Component({
  selector: 'app-fee-schedule',
  templateUrl: './fee-schedule.component.html',
  styleUrls: ['./fee-schedule.component.scss']
})
export class FeeScheduleComponent implements OnInit, OnDestroy {
  private destroy: Subject<boolean> = new Subject();
  @ViewChild('modalFeeSchedule') modalFeeSchedule: BsModalComponent;

  gridFeeSchedule: GridOptions;
  isEdit = false;
  @Input() clientId?: number;
  @Input() modalInvoice?: boolean;
  busy: Subscription[] = [];
  showStatus = true;

  feeSheduleSelect: IFeeSchedule;
  openByInvoiceBatchError: boolean;
  messageByInvoiceBatchError: string;

  startInvoiceBatchDate: string;
  endInvoiceBatchDate: string;

  constructor(
    private clientService: ClientService,
    private invoiceReportService: InvoiceReportService,
    private workflowService: WorkflowService
  ) {
    this.initGrid();
  }

  ngOnInit() {
    this.subscribeObservables();
    this.loadFeeScheduleData();
  }

  subscribeObservables(): void {

    this.invoiceReportService.openFeeScheduleModalByBatchErrorAction$
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: ({open, listOfCar, clientId}: ISubjectFeeScheduleErrorModal): void => {
          if (open) {
            this.openByInvoiceBatchError = open;
            this.messageByInvoiceBatchError = listOfCar.join(', ');
            this.clientId = clientId;
          }
        }
      });

    this.workflowService.infoByExternalFormToInvoiceReportAction$
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (date): void => {
          if (date) {
            const {start_date, end_date} = date;
            this.startInvoiceBatchDate = start_date;
            this.endInvoiceBatchDate = end_date;
          }
        }
      });

    this.clientService.reloadFeeScheduleGrid$.pipe(takeUntil(this.destroy)).subscribe((reload: boolean) => {
      this.reload(reload);
    });
  }

  initGrid() {
    this.gridFeeSchedule = <GridOptions>{
      enableSorting: true,
      enableFilter: true,
      rowSelection: 'single',
      sortingOrder: ['desc', 'asc', null],
      columnDefs: [
        { headerName: 'Type', field: 'vehicle_types.description', width: 80 },
        { headerName: 'Unit', field: 'unit', width: 100 },
        { headerName: 'Rate', field: 'rate', width: 80, cellStyle: {textAlign: 'right'},
          cellRenderer: (params) => AgGridHelpers.CurrencyCellRenderer(params) },
        {
          headerName: 'Start date',
          field: 'start_date',
          valueGetter: params => {
            return params.data.start_date ? moment(params.data.start_date).format('YYYY-MM-DD') : '';
          },
          width: 100
        },
        {
          headerName: 'End date',
          field: 'end_date',
          width: 100,
          valueGetter: params => {
            return params.data.end_date ? moment(params.data.end_date).format('YYYY-MM-DD') : '';
          }
        },
        {
          headerName: 'FeeSchedule Type',
          field: 'active',
          width: 80,
          cellRenderer: params => {
            return Number(params.data.fee_schedule_type) === 1
              ? '<span class="label label-success" style="margin-top: 4px; position: absolute;">Default</span>'
              : '<span class="label label-info" style="margin-top: 4px; position: absolute;">By Routes</span>';
          }
        },
        {
          headerName: 'Status',
          field: 'active',
          width: 80,
          cellRenderer: params => {
            return params.data.active
              ? '<span class="label label-success" style="margin-top: 4px; position: absolute;">Active</span>'
              : '<span class="label label-danger" style="margin-top: 4px; position: absolute;">Disabled</span>';
          }
        },
        {
          headerName: 'R G',
          field: 'route_group',
          width: 50,
          cellStyle: { 'text-align': 'center' },
          suppressFilter: true,
          cellRenderer: params => {
            if (params && params.data && params.data.route_group && params.data.route_group.length) {
              return params.data.route_group.length;
            }
          }
        },
        { headerName: 'Po Number', field: 'po_number', width: 80, cellStyle: { 'text-align': 'center' } }
      ],
      onCellClicked: (event: CellClickedEvent) => {
        if (event && event.data) {
          this.feeSheduleSelect = event.data;
        }
      },
      onRowDoubleClicked: (event: RowDoubleClickedEvent) => {
        if (event && event.data) {
          if (typeof this.modalInvoice === 'undefined') {
            this.modalInvoice = false;
          }
          this.isEdit = true;
          this.clientService.editFeeSchedule(event.data);
          this.modalFeeSchedule.open();
        }
      }
    };
  }

  loadFeeScheduleData() {
    this.busy.push(
      this.clientService.getFeeScheduleByClient(this.clientId, this.showStatus).subscribe((resp: any) => {
        if (resp) {
          this.gridFeeSchedule.api.setRowData(resp);
        }
      })
    );
  }

  reload(reload: boolean) {
    if (reload) {
      this.loadFeeScheduleData();
    }
    this.closeModalFeeSchedule();
  }

  onChangeStatus() {
    this.showStatus = !this.showStatus;
    this.loadFeeScheduleData();
  }

  add() {
    this.isEdit = false;
    this.clientService.editFeeSchedule(null);
    this.modalFeeSchedule.open();
  }

  edit() {
    const selectedRows = AgGridHelpers.getSelectNodeAfterFilter(this.gridFeeSchedule);
    if (selectedRows && selectedRows.length) {
      this.isEdit = true;
      this.clientService.editFeeSchedule(selectedRows[0]);
      if (!this.modalFeeSchedule.visible) {
        this.modalFeeSchedule.open();
      }
    }
  }

  closeModalFeeSchedule() {
    this.isEdit = false;
    this.modalFeeSchedule.close().then();
  }

  get hasmodalInvoice(): string | null {
    return this.modalInvoice ? '2000' : '1200';
  }

  ngOnDestroy() {
    this.busy.forEach(subscription => subscription.unsubscribe());
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }
}
