import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import * as moment from 'moment';
import { BsModalComponent } from 'ng2-bs3-modal';
import Swal from 'sweetalert2';
import { InterruptionService } from '../../../services/payroll/interruption.service';

/**
  * Listado de interrupciones de las rutas
  *
  * Principales funciones
  * * Listar interrupciones filtradas por un rango de fechas
  * * Ver detalle de las interrupciones
*/
@Component({
  selector: 'app-interruption-details',
  templateUrl: './interruption-details.component.html',
  styleUrls: ['./interruption-details.component.scss']
})
export class InterruptionDetailsComponent implements OnInit, AfterViewInit  {

  @ViewChild('modalInterruption') modalInterruption: BsModalComponent;

  maxDate: Date;
  bsRangeValue: any = [];
  gridInterruptions: GridOptions;
  busy: any;
  countRecord: number;
  schedule_detail_id: number;
  data: any;

  constructor( private interruptionService: InterruptionService) {
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate());
    this.setConfigGrid();
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.gridInterruptions.api.setRowData([]);
  }

  setConfigGrid() {
    this.gridInterruptions = <GridOptions>{
      enableSorting: true,
      sortingOrder: ['desc', 'asc', null],
      onFilterChanged: () => {
        this.countRecord = this.gridInterruptions.api.getDisplayedRowCount();
      }
    };

    this.gridInterruptions.columnDefs = [
      { headerName: 'ID', field: 'id', width: 80, checkboxSelection: true },
      { headerName: 'Supervisor', width: 120, field: 'supervisor' },
      { headerName: 'Time', width: 70, field: 'time_interruption' },
      { headerName: 'Category', width: 100, field: 'category_incident' },
      { headerName: 'Reason', width: 200, field: 'incident' },
      { headerName: 'Odometer', width: 100, field: 'odometer' },
      { headerName: 'Vehicle', width: 100, field: 'vehicle'},
      { headerName: 'Driver', width: 120, field: 'driver' },
      { headerName: 'Route', width: 150, field: 'router' },
      { headerName: 'Block', width: 150, field: 'block' },
      { headerName: 'Date of Service', width: 120, field: 'dateofservice' }
    ];
  }

  onFilterTextBoxChanged($value) {
    this.gridInterruptions.api.setQuickFilter($value);
  }

  onSearch() {
    if (this.bsRangeValue.length === 2) {
      const startDate = moment(new Date(this.bsRangeValue[0])).format('YYYY-MM-DD');
      const endDate = moment(new Date(this.bsRangeValue[1])).format('YYYY-MM-DD');
      this.busy = this.interruptionService.getInterruptionRangeDate(startDate, endDate).subscribe((resp: any) => {
        if (resp.status === 'success') {
          this.gridInterruptions.api.setRowData(resp.data);
          this.countRecord = resp.data.length;
        }
      });
    } else {
      Swal.fire('Please select a date range.');
    }
  }

  OnCellDoubleClicked(event: any) {
    this.schedule_detail_id = event.data.schedule_detail_id;
    this.data = event.data;
    this.modalInterruption.open();
  }

  onClose() {
    this.modalInterruption.close();
  }

}
