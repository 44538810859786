import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DashboardLsfDataService } from './../../../dashboard-lsf/dashboard-lsf.data.service';
import { ClientService } from '../../../../services/fleet/client.service';
import { InterruptionService } from '../../../../services/interruption.service';
import Swal from 'sweetalert2';
import { BillingCategoriesService } from '../../../../services/parameters/billing-categories.service';

@Component({
  selector: 'app-interruption-manual-form',
  templateUrl: 'interruption-manual-form.component.html',
  styleUrls: ['./interruption-manual-form.component.scss']
})

export class InterruptionManualFormComponent implements OnInit {

  previousData: any = {};
  schedule_seleccionado: any = {};
  public time_format = [/[0-9]/, /\d/, ':', /\d/, /\d/];
  @Input() set data(data) {
    this.schedule_seleccionado = {};
    // console.log(data);

    if (data.dataForm) {
      if (data.dataForm.client_id) {
        const date = new Date();
        // console.log(data.dataForm);

        this.previousData = {
          ...data.dataForm,
          min_time: moment(`${data.dataForm.start_date} 00:00`, 'MM-DD-YYYY hh:mm').format('YYYY-MM-DD hh:mm'),
          max_time: moment(`${data.dataForm.end_date} 23:59`, 'MM-DD-YYYY hh:mm').format('YYYY-MM-DD hh:mm'),
        };
      }
    }

    if (data.id) {
      this.busy.push(
        this.interruptionService.getInterruptionId(data.id).subscribe(async res => {
          this.formData.patchValue({
            id: res.data.id,
            dateofservice: res.data.dateofservice,
            route_id: res.data.route_id,
            schedule_detail_id: res.data.schedule_detail_id,
            categorie_incident_id: res.data.categorie_incident_id,
            subcategories_incident_id: res.data.subcategories_incident_id,
            incident_id: res.data.incident_id,
            billing_category_id: res.data.billing_category_id,
            start_time: res.data.start_time,
            end_time: res.data.end_time,
            details: res.data.details,
          });
          await this.loadRoutes(true);
          await this.loadCategories();
          this.onLoadSupervisor(res.data.supervisor);
          this.changeCategorie(res.data.categorie_incident_id, true);
          this.changeSubcategorie(res.data.subcategories_incident_id, true);
          this.changeRoute(res.data.route_id, true);
          this.changeBlock(res.data.schedule_detail_id);
        })
      );
    } else {
      this.formData.setValue({
        id: '',
        dateofservice: '',
        route_id: '',
        schedule_detail_id: '',
        categorie_incident_id: '',
        subcategories_incident_id: '',
        incident_id: '',
        billing_category_id: '',
        start_time: '',
        end_time: '',
        details: '',
        supervisor: '',
      });
      this.loadRoutes();
    }
  }
  @Output() onClose = new EventEmitter<any>();
  dropdownSettings: any = {
    singleSelection: true,
    idField: 'route_id',
    textField: 'description_route',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true
  };
  dropdownBlockSettings: any = {
    singleSelection: true,
    idField: 'schedule_detail_id',
    textField: 'details',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true
  };
  formData: FormGroup;
  public busy: Subscription[] = [];
  private endpoint = 'categoriesIncidents';
  private endpointInterruptionService = 'servicesInterruptions';

  supervisors: any = [];
  categoriesIncidents: any = [];
  subcategories: any = [];
  incidents: any = [];
  routes: any = [];
  routes_block: any = [];
  categoriesBilling: any = [];
  supervisorId: number;

  constructor(
    private fb: FormBuilder,
    private _dataService: DashboardLsfDataService,
    private interruptionService: InterruptionService,
    private billingCategoriesService: BillingCategoriesService,
    private _sanitizer: DomSanitizer,
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.loadCategories();
    this.loadCategoriesBilling();
    this.onLoadSupervisor();
  }

  createForm() {
    this.formData = this.fb.group({
      id: [''],
      dateofservice: ['', Validators.required],
      route_id: ['', Validators.required],
      schedule_detail_id: ['', Validators.required],
      categorie_incident_id: ['', Validators.required],
      subcategories_incident_id: ['', Validators.required],
      incident_id: ['', Validators.required],
      billing_category_id: [''],
      start_time: ['', Validators.required],
      end_time: ['', Validators.required],
      details: ['', Validators.required],
      supervisor: ['', Validators.required],
    });
  }

  async loadCategories() {
    const sub = this._dataService.getData(this.endpoint);
    this.busy.push(sub.subscribe());
    await sub.toPromise().then(data => {
      this.categoriesIncidents = data;
    });
  }

  async loadCategoriesBilling() {
    this.billingCategoriesService.getBillingCategories().subscribe((res: any) => {
      this.categoriesBilling = res.data.filter(m => m.active);
    });
  }


  async loadRoutes(del?: boolean) {
    const dataForm = Object.assign({}, this.formData.getRawValue());
    if (this.previousData.client_id) {
      if (dataForm.dateofservice) {

        const prom = await this.interruptionService.getRoutesForInterruptionManual({
          dateofservice: dataForm.dateofservice,
          client_id: this.previousData.client_id,
          routes: this.previousData.routes_id,
        });
        this.busy.push(prom.subscribe());
        await prom.toPromise().then((resp: any) => {
          this.routes = resp.data.map(r => { return {...r, description_route: r.desc_billing ? r.desc_billing: r.route_description } });
          if (!del) {
            this.formData.get('route_id').setValue('');
            this.changeRoute(null);
          }
        });
      } else {
        this.routes = [];
        this.changeRoute(null);
      }
    }
  }

  changeCategorie(value: any, del?: boolean) {
    const result: any = _.find(this.categoriesIncidents, { 'id': Number(value) });
    if (result) {
      this.subcategories = result.subcategories;
      if (this.subcategories.length === 1) {
        this.formData.get('subcategories_incident_id').setValue(this.subcategories[0].id);
        this.changeSubcategorie(this.subcategories[0].id, del);
      } else {
        this.incidents = [];
        if (!del) {
          this.formData.get('subcategories_incident_id').setValue('');
        }
      }
      if (!del) {
        this.formData.get('incident_id').setValue('');
      }
    }
  }

  changeSubcategorie(value: any, del?: boolean) {
    const result: any = _.find(this.subcategories, { 'id': Number(value) });
    if (result) {
      this.incidents = result.incidents;
      this.formData.patchValue({subcategories_incident_id: Number(value)});
      if (!del) {
        this.formData.get('incident_id').setValue('');
      }
    }
  }

  changeRoute(value: any, del?: boolean) {
    const result: any = _.find(this.routes, { 'route_id': String(value) });
    if (result) {
      this.routes_block = result.routes.map(r => {
        return { ...r, details: `${r.block_code ?  `${r.block_code}-` : ''}${r.block_description}` };
      });
    } else {
      this.routes_block = [];
    }
    if (!del) {
      this.formData.get('schedule_detail_id').setValue('');
    }
  }

  changeBlock(value: any) {
    const result: any = _.find(this.routes_block, { 'schedule_detail_id': String(value) });
    // console.log(result);

    if (result) {
      this.schedule_seleccionado = result;
    }
  }

  validDate() {
    const dataForm = Object.assign({}, this.formData.getRawValue());
    const startTime = moment(`${this.previousData.min_time} 00:00`, 'YYYY-MM-DD hh:mm a').format('YYYY-MM-DD');
    const endTime = moment(`${this.previousData.max_time} 23:59`, 'YYYY-MM-DD hh:mm a').format('YYYY-MM-DD');
    const hour = moment(dataForm.dateofservice, 'YYYY-MM-DD');
    if (dataForm.dateofservice) {
      const valid = hour.isBetween(startTime, endTime, undefined, '[]');
      if (!valid) {
        this.formData.get('dateofservice').setValue('');
      } else {
        this.loadRoutes();
      }
    }
  }

  verifyDate() {
    const dataForm = Object.assign({}, this.formData.getRawValue());
    if (dataForm.dateofservice && dataForm.start_time && dataForm.end_time) {
      const actualTime = moment.utc(`${dataForm.dateofservice} ${dataForm.start_time}:00`, 'YYYY-MM-DD HH:mm:ss');
      const startTime = moment.utc(`${this.schedule_seleccionado.start_time}`, 'YYYY-MM-DD HH:mm:ss.000');
      const endTime = moment.utc(`${this.schedule_seleccionado.end_time}`, 'YYYY-MM-DD HH:mm:ss.000');
      const between = actualTime.isBetween(startTime, endTime);
      if (!between) {
        // this.formData.get('start_time').setValue('');
        // this.formData.get('end_time').setValue('');
        Swal.fire({
          title: 'Invalid time',
          text: `The date of the interruption is outside the schedule of the route`,
          type: 'warning',
          showCancelButton: false,
        }).then((result) => {});
      }
    }
  }

  delete() {
    const dataForm = Object.assign({}, this.formData.getRawValue());
    if (dataForm.id) {
      Swal.fire({
        text: 'This operation, will remove the interruptions from the scheduler, confirm the action?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'})
        .then((result) => {
          if (result.value) {
            this.busy.push(
              this.interruptionService.deleteInterruptionManual(dataForm.id).subscribe(res => {
                Swal.fire('Successfull!', 'Interruption Deleted.', 'success');
                this.onClose.emit({ success: true });
              })
            );
          }
        });
    }
  }
  save() {
    const dataForm = Object.assign({}, this.formData.getRawValue());
    if (dataForm.id && this.formData.valid) {
      this.busy.push(
        this.interruptionService.updateInterruptionManual(dataForm.id, {
          dateofservice: dataForm.dateofservice,
          schedule_detail_id: dataForm.schedule_detail_id,
          categorie_incident_id: dataForm.categorie_incident_id,
          subcategories_incident_id: dataForm.subcategories_incident_id,
          incident_id: dataForm.incident_id,
          billing_category_id: dataForm.billing_category_id,
          start_time: dataForm.start_time,
          end_time: dataForm.end_time,
          details: dataForm.details,
          supervisor: this.supervisorId,
        }).subscribe(res => {
          Swal.fire('Successfull!', 'Interruption Updated.', 'success');
          this.onClose.emit({ success: true });
        })
      );
    } else {
      this.busy.push(
        this.interruptionService.createInterruptionManual({
          dateofservice: dataForm.dateofservice,
          schedule_detail_id: dataForm.schedule_detail_id,
          categorie_incident_id: dataForm.categorie_incident_id,
          subcategories_incident_id: dataForm.subcategories_incident_id,
          incident_id: dataForm.incident_id,
          billing_category_id: dataForm.billing_category_id,
          start_time: dataForm.start_time,
          end_time: dataForm.end_time,
          details: dataForm.details,
          supervisor: this.supervisorId,
        }).subscribe(res => {
          Swal.fire('Successfull!', 'Interruption Saved.', 'success');
          this.onClose.emit({ success: true });
        })
      );
    }
  }

  onLoadSupervisor(supervisorId?: number) {
    this.busy.push(
      this._dataService.getData('drivers/active/1').subscribe(data => {
        this.supervisors = data;
        if (supervisorId) {
          const supervisor = this.supervisors.filter(s => s.id === supervisorId);
          if (supervisorId && supervisor.length > 0) {
            this.supervisorId = supervisorId;
            this.formData.patchValue({ supervisor: `${supervisor[0].first_name} ${supervisor[0].last_name}` });
          }
        }
      })
    );
  }

  autocompleListFormatterSupervisor = (data: any) => {
    const html = `<span>${data.last_name} ${data.first_name}</span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

  myValueFormatterSupervisor(data: any): string {
    const html = `${data.last_name} ${data.first_name}`;
    return html;
  }

  supervisorChange(event: any) {
    if (event.id) {
      this.supervisorId = event.id;
    }
  }

}
