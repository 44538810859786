import { Component, OnDestroy, OnInit } from '@angular/core';
import { CellClickedEvent, CellDoubleClickedEvent, FilterModifiedEvent, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { ClientService, CompanyService } from '../../../services/services.index';
import AgGridHelpers from '../../../helpers/ag-grid-helpers';
import { SweetAlertHelper } from '../../../CORE/helpers/sweet-alert-helper.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})

export class ClientsComponent implements OnInit, OnDestroy {
  busy: Subscription[] = [];
  gridCities: GridOptions;

  showStatus = true;
  addCiti = false;
  enabledBtn = false;
  citieSekected = 0;
  titleCountGrid: string;
  dataLength: number;

  companies: Array<any> = [];
  client: any = null;
  getRowNodeId;

  constructor(
    private clientService: ClientService,
    private companyService: CompanyService,
    private sweetAlertHelper: SweetAlertHelper,
  ) {
    this.setGrid();
  }

  ngOnInit() {
    this.getAllCompanies();
  }

  setGrid() {
    this.gridCities = <GridOptions> {
      enableSorting: true,
      enablefilter: true,
      sortingOrder: ['desc', 'asc', null],
      columnDefs: [
        { headerName: 'Client Name', field: 'client_name', width: 180 },
        { headerName: 'Contact', field: 'contact', width: 110 },
        { headerName: 'Company', field: 'company.description', width: 100 },
        {
          headerName: 'Status',
          field: 'active',
          width: 100,
          cellRenderer: params => {
            if (params && params.data) {
              AgGridHelpers.statusRecord(params.data.active);
            }
          }
        },
        { headerName: 'Insurance', field: 'insurance_rule', width: 100,
          cellStyle: {'text-align': 'center'},
          cellRenderer : (params) => {
            return (params.data.insurance_rule) ? '<span class="label label-success" style="margin-top: 4px; position: absolute;">active</span>'
              : '<span class="label label-danger" style="margin-top: 4px; position: absolute;">inactive</span>';
          }
        },
      ],
      onGridReady: (event: GridReadyEvent) => {
        if (event) {
          this.getAllClients(this.showStatus);
        }
      },
      onFilterModified: (event: FilterModifiedEvent) => {
        if (event) {
          this.setCounter();
        }
      },
      onCellClicked: (event: CellClickedEvent) => {
        if (event && event.data) {
          this.client = event.data;
          this.citieSekected = event.data.id;
        }
      },
      onCellDoubleClicked: (event: CellDoubleClickedEvent) => {
        if (event && event.data) {
          this.clientService.editRowBillingForm(null);
          this.client = event.data;
          this.onEditCitie();
        }
      }
    };

    this.getRowNodeId = data => {
      return data.id;
    };
  }

  onNotify(event: any) {
    this.getAllClients(this.showStatus);
  }

  onNewCitie() {
    this.citieSekected = 0;
    this.client = null;
    this.changeStateModal();
  }

  onEditCitie() {
    if (this.citieSekected) {
      this.changeStateModal();
    } else {
      this.sweetAlertHelper.createCustomAlert({
        title: 'Select a client',
        text: 'Please select a client to edit',
        type: 'warning'
      });
    }
  }

  changeStateModal() {
    this.addCiti = !this.addCiti;
    this.enabledBtn = !this.enabledBtn;
  }

  changeShowStatus(status: boolean) {
    this.getAllClients(!status);
  }

  getAllClients(status: boolean) {
    this.busy.push(
      this.clientService.getClientStatus(status).subscribe(
        resp => {
          if (resp && resp.data.length) {
            const filter = this.gridCities.api.getFilterModel();
            this.gridCities.api.setRowData(resp.data);
            this.gridCities.api.setFilterModel(filter);
            this.dataLength = this.gridCities.api.getDisplayedRowCount();
            this.setCounter();
          }
        },
        error => {
          this.sweetAlertHelper.captureException(error.message);
        }
      )
    );
  }

  private setCounter() {
    const cant = this.gridCities.api.getDisplayedRowCount();
    this.titleCountGrid = `Clients ${cant} of ${this.dataLength}`;
  }

  getAllCompanies() {
    this.busy.push(
      this.companyService.getCompanyStatus(false).subscribe(
        resp => {
          if (resp && resp.data.length) {
            this.companies = resp.data;
          }
        },
        error => {
          this.sweetAlertHelper.captureException(error.message);
        }
      )
    );
  }

  ngOnDestroy() {
    this.busy.forEach(subscription => subscription.unsubscribe());
  }
}
