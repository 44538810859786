import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '../../../../user.model';
import { Subscription } from 'rxjs';
import { LayoutDataService } from './../../layout.data.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  busy: Subscription;
  private apiResponse: any;

  @Input()
  infoUser: User;
  @Output()
  onClose = new EventEmitter<Number>();
  @Output()
  notify: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _dataService: LayoutDataService) {}

  ngOnInit() {}

  close() {
    this.onClose.emit(1);
  }

  onSave() {
    delete this.infoUser.id;
    delete this.infoUser.phone;
    delete this.infoUser.status;
    delete this.infoUser.created_at;
    delete this.infoUser.updated_at;
    delete this.infoUser.roles;
    this.busy = this._dataService
      .putData(this.infoUser, 'users/profileUpdate')
      .subscribe(data => {
        this.apiResponse = data;
        if (this.apiResponse.status) {
          this.notify.emit(this.apiResponse.result);
          this.close();
        } else {
          alert(JSON.stringify(data));
        }
      });
  }
}
