import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-create-vehicle',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() notify2: EventEmitter<any> = new EventEmitter<any>();
  @Input() vehicleSekected?: number;
  @Input() vehiclesGps?: any[];
  public busy: Subscription;

  public titleModal: string;
  activeTab = 'Details';

  constructor() {
    this.titleModal = '';
  }

  ngOnInit() {
  }

  /***
   * @description
   */
  onHide() {
    this.cancel.emit(true);
  }

  /***
   * @description
   * @param {string} title
   */
  onTitle(title: string) {
    this.titleModal = title;
  }

  /***
   * @description
   * @param data
   */
  notify(data: any) {
    this.notify2.emit(data);
  }

}
