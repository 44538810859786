import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeModule } from '../../../../theme/theme.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgBusyModule } from 'ng-busy';
import { SharedModule } from '../../../../shared/shared.module';

import { FormTypeRoutingModule } from './form-type-routing.module';
import { FormTypeCardComponent } from './components/form-type-card/form-type-card.component';

@NgModule({
  imports: [
    CommonModule,
    ThemeModule,
    ReactiveFormsModule,
    FormsModule,
    NgBusyModule,
    SharedModule,
    FormTypeRoutingModule
  ],
  exports: [
    FormTypeCardComponent
  ],
  declarations: [FormTypeCardComponent]
})
export class FormTypeModule { }
