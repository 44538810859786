import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { VehicleService } from '../../../../../services/fleet/vehicle.service';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { InvoiceService } from '../../../../../services/billing/invoice.service';
import * as moment from 'moment';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-add-fueled',
  templateUrl: './add-fueled.component.html',
  styleUrls: ['./add-fueled.component.scss']
})
export class AddFueledComponent implements OnInit, OnChanges {
  form: FormGroup;
  vehicle_fueled_id = null;
  vehicle_select_id = null;
  skipOdometerDiff = false;
  @Input() route_id;
  @Input() max_date;
  @Input() min_date;
  @Input() isHomestead;
  @Input() set bus_fueled(data) {
    if (data) {
      this.vehicle_fueled_id = data.id;
      this.vehicle_select_id = data.vehicle_id;
      const date = moment(data.date, 'YYYY-MM-DD hh:mm:ss').toISOString();
      this.form.patchValue({
        id: data.id,
        vehicle: data.vehicle.description,
        date: formatDate(date, 'yyyy-MM-dd', 'en'),
        comments: data.comments,
        issue_qty: data.issue_qty,
        active: data.active,
      });
    }
  }
  @Output() onAddFueled? = new EventEmitter<any>();
  vehicles: any = [];

  public busy: Subscription;
  constructor(
    private fb: FormBuilder,
    private vehicleService: VehicleService,
    private domSanitizer: DomSanitizer,
    private toastr: ToastrService,
    private invoiceService: InvoiceService
  ) {
    this.initForm();
  }

  ngOnInit() {
    this.form.get('date').setValidators([Validators.min(this.min_date), Validators.max(this.max_date), Validators.required]);
    this.form.updateValueAndValidity();
    this.loadVehicles();
    if (this.isHomestead) {
      this.form.get('issue_qty').setValidators(Validators.required);
      this.form.updateValueAndValidity();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {}

  initForm() {
    this.form = this.fb.group({
      id: [''],
      vehicle: ['', Validators.required],
      date: ['', Validators.required],
      comments: [''],
      issue_qty: [''],
      active: [true]
    });
  }

  loadVehicles() {
    this.busy = this.vehicleService.getVehiclesStatus(false).subscribe((data: any) => {
      this.vehicles = data;
    });
  }

  myValueFormatter(data: any): string {
    const html = data.description;
    return html;
  }
  autocompleListFormatter = (data: any) => {
    const html = `<span>${data.description} </span>`;
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  };

  valueChangedVehicle(event: any) {
    if (event) {
      this.vehicle_select_id = event.id;
    }
  }

  verifyDate() {
    const date = Object.assign({}, this.form.getRawValue()).date;
    if(date) {
      if(!moment(date).isBetween(this.min_date, this.max_date, undefined, '[]')) {
      this.form.get('date').setValue('');
      }
    }
  }

  save() {
    const data = Object.assign({}, this.form.getRawValue());
    if (!this.vehicle_fueled_id) {
      this.busy = this.invoiceService
        .saveBusesFueledWeek({
          vehicle_id: this.vehicle_select_id,
          route_id: this.route_id,
          date: data.date,
          issue_qty: data.issue_qty,
          comments: data.comments,
        })
        .subscribe(
          (res: any) => {
            this.onAddFueled.emit(res);
            this.toastr.success('Vehicle fueled registered successfully');
          },
          err => {
            this.toastr.error('Error! Please try again!');
          }
        );
    } else {
      this.busy = this.invoiceService
      .updateBusesFueledWeek({
        id: data.id,
        vehicle_id: this.vehicle_select_id,
        route_id: this.route_id,
        date: data.date,
        comments: data.comments,
        issue_qty: data.issue_qty,
        active: data.active,
      }).subscribe(res => {
        this.onAddFueled.emit(res);
        this.toastr.success('Vehicle fueled updated successfully');
      }, err => {
        this.toastr.error('Error! Please try again!');
      });
    }
  }
}
