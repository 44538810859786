import { Component, OnDestroy, OnInit } from '@angular/core';
import { InvoiceReportService } from '../../invoice-report.service';
import { merge, Subject, Subscription } from 'rxjs';
import { SweetAlertHelper } from '../../../../../../CORE/helpers/sweet-alert-helper.service';
import { takeUntil } from 'rxjs/operators';
import { IResponse, ResponseStatus } from '../../../../../../CORE/interfaces/response.interface';
import { IBatchBalance } from '../../../../../../CORE/interfaces/invoice-report.interface';

@Component({
  selector: 'app-batch-balance-info',
  templateUrl: './batch-balance-info.component.html',
  styleUrls: ['./batch-balance-info.component.scss']
})
export class BatchBalanceInfoComponent implements OnInit, OnDestroy {
  busy: Subscription [] = [];
  destroy: Subject<boolean> = new Subject();

  batchID: number;
  totalCharges = 0;
  totalCredits = 0;
  totalAll = 0;
  payloadRecalculate= [];

  constructor(
    private invoiceReportService: InvoiceReportService,
    private sweetAlertHelper: SweetAlertHelper
  ) { }

  ngOnInit(): void {
    this.subscribeToObservers();
    this.invoiceReportService.infoForBatchRecalculateAction$.subscribe((payload) => {
      if (payload) {
        this.payloadRecalculate = payload;
      }
    });
  }

  subscribeToObservers(): void {
    this.invoiceReportService.batchIdAction$
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (batchID: number): void => {
          if (batchID) {
            this.batchID = batchID;
            this.getInvoiceBatchBalance(batchID);
          }
        }
      });

    merge(
      this.invoiceReportService.reloadChargeGridAction$,
      this.invoiceReportService.reloadCreditGridAction$
    ).subscribe({
      next: (reload: boolean): void => {
        if (reload) {
          this.getInvoiceBatchBalance(this.batchID);
        }
      },
      error: error => this.sweetAlertHelper.captureException(error)
    });
  }

  getInvoiceBatchBalance(batchID: number): void {
    this.busy.push(
      this.invoiceReportService.getInvoiceBatchBalance(batchID).subscribe({
        next: ({status, data}: IResponse<IBatchBalance>): void => {
          if (ResponseStatus.SUCCESS === status) {
            const {credits, charges, total}: IBatchBalance = data;
            this.totalCredits = credits;
            this.totalCharges = charges;
            this.totalAll = total;
          }
        },
        error: error => this.sweetAlertHelper.captureException(error)
      })
    );
  }

  recalculate(): void {
    this.busy.push(this.invoiceReportService.recalculate(this.payloadRecalculate).subscribe({
      next: ({status, data}: IResponse<IBatchBalance>): void => {
        if (ResponseStatus.SUCCESS === status) {
          const { credits, charges, total }: IBatchBalance = data;
          this.totalCredits = credits;
          this.totalCharges = charges;
          this.totalAll = total;
        }
        this.invoiceReportService.reloadRecalculateSubject.next(true);
      },
      error: error => this.sweetAlertHelper.captureException(error)
    }));
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
  }
}
