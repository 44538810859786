import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { LayoutDataService } from './../layout/layout.data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  busy: Subscription;
  public form: FormGroup;
  public password1: AbstractControl;
  public password2: AbstractControl;

  error: string;

  email: string;
  token: string;
  newPassword: string;
  validregex = false;

  constructor(
    private route: ActivatedRoute,
    fb: FormBuilder,
    private _dataService: LayoutDataService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.email = this.route.snapshot.params['email'];
    this.token = this.route.snapshot.params['token'];

    this.form = fb.group({
      password1: [
        '',
        Validators.compose([Validators.required, Validators.minLength(4)])
      ],
      password2: [
        '',
        Validators.compose([Validators.required, Validators.minLength(4)])
      ]
    });
    this.password1 = this.form.controls['password1'];
    this.password2 = this.form.controls['password2'];
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.email = params['email'];
      this.token = params['token'];
    });
  }

  validatedPassword(event) {
    this.validregex = event;
  }

  public onSubmit(values: any): void {
    if (this.password1.value === this.password2.value) {
      if (this.form.valid) {
        const obj = {
          'email': this.email,
          'token': this.token,
          'newPassword': this.password1.value
        };
        this.busy = this._dataService
          .postData(obj, 'users/newPasswordReset')
          .subscribe(
            (data: any) => {
              alert(data.message);
              this.router.navigate(['/']);
            },
            error => {
              alert(error);
            },
            () => {}
          );
      }
    }
  }
}
