import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CONSTANTS } from '../../app.constants';

@Injectable()
export class ManifestsMovilService {
  protected resource: string;

  constructor(private http: HttpClient) {
    this.resource = environment.url_api_v + CONSTANTS.DRIVER_MANIFESTS;
  }

  getManifests(): Observable<any> {
    return this.http.get<any>(this.resource);
  }

  storeManifest(data: any): Observable<Response> {
    return this.http.post<Response>(this.resource, JSON.stringify(data));
  }

  updateManifests(data: any, id: number): Observable<Response> {
    return this.http.put<Response>(
      this.resource + '/' + id,
      JSON.stringify(data)
    );
  }

  getManifest(id: number): Observable<any> {
    return this.http.get(this.resource + '/' + id);
  }

  getManifestsForDate(start_date: string, end_date: string): Observable<any> {
    return this.http.get<any>(this.resource + `/date/${start_date}/${end_date}`);
  }
}
