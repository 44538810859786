import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { ScheduleDetailsService, TsoAssignVehicleService, TsoService } from '../../../../../services/services.index';
import { TsoResponse } from '../../../../../services/Tso.service';

interface TsoConfig {
  id: number;
  user_id: number;
  client_id: number;
  user: string;
  password: string;
  company_id: number;
  city: string;
}

interface TsoUser {
  companyId: number;
  userId: number;
  token: string;
  lang: string;
  FullName: string;
  mileskms: string;
  gasunit: string;
  temperatureunit: string;
}

interface Unit {
  ID: number;
  UnitID: string;
  ShortName: string;
  Description: string;
  CompanyID: number;
  DriverID: number;
}

interface Driver {
  ID: number;
  CompanyID: number;
  Name: string;
  LastName: string;
}

interface Route {
  RouteID: number;
  LongName: string;
}

interface AssignVehicle {
  token: string;
  routeID: number;
  unitID: number;
  driverID: number;
  scheduleID: number;
  lan: string;
  fixedSchedule: boolean;
  schedule_detail_id: number;
  schedule_id_assigned: number;
  active: boolean;
  details?: string;
}

@Component({
  selector: 'app-tso-assign-vehicle',
  templateUrl: './tso-assign-vehicle.component.html',
  styleUrls: ['./tso-assign-vehicle.component.scss']
})
export class TsoAssignVehicleComponent implements OnInit {

  @Input() schedule_detail_id: number;
  @Output() onClose = new EventEmitter<boolean>();
  public busy: Subscription;
  public apiResponse: any;
  tsoConfig: TsoConfig = <TsoConfig>{};
  assignVehicle: AssignVehicle = <AssignVehicle>{};
  units: Unit[] = [];
  driver: Driver[] = [];
  route: Route[] = [];
  fixedSchedule: boolean;

  constructor(private _serviceTso: TsoService,
              private _serviceScheduleDetails: ScheduleDetailsService,
              private _serviceTsoAssignVehicle: TsoAssignVehicleService,
              public toastr: ToastrService) {
    this.assignVehicle.lan = 'English';
  }

  ngOnInit() {
    this.loadDadata();
  }

  loadDadata() {
    this.getAllUnits();
    this.getAllRoute();
    this.getAssignVehicle();
  }

  getAllUnits() {
    this._serviceTso.getAllUnits().subscribe(
      (data: TsoResponse) => {
        if (data.Code !== 1) {
          alert(data.Message);
          this.toastr.error(data.Message);
        } else {
          this.units = data.Data;
        }
      }
    );
  }

  getAllRoute() {
    this._serviceTso.getAllRoutes().subscribe(
      (data: TsoResponse) => {
        if (data.Code !== 1) {
          alert(data.Message);
          this.toastr.error(data.Message);
        } else {
          this.route = data.Data;
        }
      }
    );
  }

  getAssignVehicle() {
    this.busy = this._serviceTsoAssignVehicle.getAssignVehicle(this.schedule_detail_id).subscribe(
      data => {
        if (data) {
          this.assignVehicle = data;
          this.fixedSchedule = this.assignVehicle.fixedSchedule;
        }
      }
    );
  }

  onSave() {
    this.assignVehicle.schedule_detail_id = this.schedule_detail_id;
    this.busy = this._serviceTsoAssignVehicle.tsoAssignVehicle(this.assignVehicle).subscribe(
      (data: any) => {
        if(data.Code == 1) {
          Swal.fire(
            'Assigned!',
            'Vehicle assigned in TSO. ID: ' + data.Data,
            'success'
          )
          this.getAssignVehicle();
        } else {
          Swal.fire({
            title: data.message,
            text: "Please verify that the unit is not assigned in TSO.\nDo you want to try to unassign this vehicle from TA?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
          }).then((result) => {
            if (result.value) {
              this.getAssignVehicle();
              this.onUnassignVehicle();
            }
          })
        }
      }, error => {
        alert("Error " + error);
      }
    );
  }

  onUnassignVehicle() {
    this.busy = this._serviceTsoAssignVehicle.unassignVehicle(this.assignVehicle.schedule_id_assigned, this.schedule_detail_id).subscribe(
      data => {
        if(data.Code == 1) {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
          this.assignVehicle = <AssignVehicle>{};
        } else {
          Swal.fire(
            'Oops...!',
            data.message,
            'error'
          )
        }
      }
    );
  }

}
