export { PermissionService, RoleService, UserService, UserTypeService } from './admin/admin.index';
export { CompanyService } from './companies/companies.index';
export { ClientService, DriverService, DriverUser, TsoConfigService, VehicleService } from './fleet/fleet.index';
export { ManifestsMovilService } from './manifest/manifest.index';
export { Attach, AttachDocumentService, AttachImage, AttachImageService, DocumentObj, DocumentsService, InspectionCategoriesService, InspectionCategory, InspectionItem, InspectionItemsService } from './parameters/parameters.index';
export { ScheduleDetailsService } from './scheduleDetails.service';
export { TsoAssignVehicleService } from './tso-assign-vehicle.service';
export { TsoService } from './Tso.service';
export { DriverService as TSODriverService, TSODriver } from './TSO/TSO.index';


