export class Pivot {
  user_id: string;
  role_id: string;
}

export class Pivot2 {
  role_id: string;
  permission_id: string;
}

export class Permission {
  id: number;
  name: string;
  display_name: string;
  description?: any;
  created_at: string;
  updated_at: string;
  pivot: Pivot2;
}

export class Role {
  id: number;
  name: string;
  display_name: string;
  description?: any;
  created_at: string;
  updated_at: string;
  pivot: Pivot;
  permissions: Permission[];
}

export class User {
  id: number;
  avatar: string;
  name: string;
  email: string;
  phone: string;
  status: string;
  created_at: string;
  updated_at: string;
  roles: Role[];
}
