function hitoryValue(value: string) {
  switch (value) {
    case 'last_name':
      return 'Last Name';
    case 'first_name':
      return 'First Name';
    case 'active':
      return 'Active';
    case 'description':
      return 'Description';
    case 'comments':
      return 'Comments';
    case 'punch_out_time':
      return 'Punch Out Time';
    case 'report_time':
      return 'Report Time';
    case 'start_time':
      return 'Start Time';
    case 'end_time':
      return 'End Time';
    case 'vehicle_id':
      return 'Vehicle';
    case 'user_id':
      return 'User';
    case 'block_statu_id':
      return 'Block Status';
    case 'vehicle_type_id':
      return 'Vehicle Type';
    case 'route_id':
      return 'Route';
    case 'driver_id':
      return 'Driver';
    case 'leave_yard_time':
      return 'Leave Yard Time';
    case 'vehicle_status_id':
      return 'Vehicle Status';
    case 'driver_license':
      return 'Driver License';
    case 'employee_id':
      return 'Employee';
    case 'emails':
      return 'Emails';
    case 'desc_billing':
      return 'Desc Billing';
    case 'startLocation':
      return 'Start Location';
    case 'signature':
      return 'Signature';
    case 'street_address':
      return 'Street Address';
    case 'dateOfService':
      return 'Date Of Service';
    case 'wednesday':
      return 'Wednesday';
    case 'saturday':
      return 'Saturday';
    case 'last_stop_odometer':
      return 'Last Stop Odometer';
    case 'trainee':
      return 'Trainee';
    case 'bc':
      return 'Bc';
    case 'amb':
      return 'Amb';
    case 'updateReasons':
      return 'Update Reasons';
    case 'mi':
      return 'Mi';
    case 'insurance':
      return 'Insurance';
    case 'end':
      return 'End';
    case 'subjectResolution':
      return 'Subject Resolution';
    case 'state':
      return 'State';
    case 'actual_drop_off_full':
      return 'Actual Drop Off Full';
    case 'phone':
      return 'Phone';
    case 'pay_rate':
      return 'Pay Rate';
    case 'emp':
      return 'Emp';
    case 'is_supervisor':
      return 'Is Supervisor';
    case 'trainee_id':
      return 'Trainee';
    case 'minutes':
      return 'Minutes';
    case 'monday':
      return 'Monday';
    case 'status':
      return 'Status';
    case 'last':
      return 'Last';
    case 'contact':
      return 'Contact';
    case 'revenue_miles':
      return 'Revenue Miles';
    case 'not_revenue_miles':
      return 'Not Revenue Miles';
    case 'return_to_yard_odometer':
      return 'Return To Yard Odometer';
    case 'availability':
      return 'Availability';
    case 'actual_check_in_full':
      return 'Actual Check In Full';
    case 'date_birth':
      return 'Date Birth';
    case 'vin':
      return 'Vin';
    case 'pay_rate_part_time':
      return 'Pay Rate Part Time';
    case 'subject':
      return 'Subject';
    case 'check_every':
      return 'Check Every';
    case 'value':
      return 'Value';
    case 'run_id':
      return 'Run';
    case 'to':
      return 'To';
    case 'email':
      return 'Email';
    case 'actual_drop_off':
      return 'Actual Drop Off';
    case 'city':
      return 'City';
    case 'template_id':
      return 'Template';
    case 'log':
      return 'Log';
    case 'wc':
      return 'Wc';
    case 'client_id':
      return 'Client';
    case 'schedule_detail_id':
      return 'Schedule Detail';
    case 'sheet_name':
      return 'Sheet Name';
    case 'route_block_id':
      return 'Route Block';
    case 'billing_status_id':
      return 'Billing Status';
    case 'license_expiry':
      return 'License Expiry';
    case 'latitude':
      return 'Latitude';
    case 'first_stop_time':
      return 'First Stop Time';
    case 'sundial_time_id':
      return 'Sundial Time';
    case 'driver_manifest_id':
      return 'Driver Manifest';
    case 'cc':
      return 'Cc';
    case 'date':
      return 'Date';
    case 'zip':
      return 'Zip';
    case 'rta':
      return 'Rta';
    case 'sunday':
      return 'Sunday';
    case 'end_date':
      return 'End Date';
    case 'adjustment':
      return 'Adjustment';
    case 'tag':
      return 'Tag';
    case 'hours':
      return 'Hours';
    case 'block_code':
      return 'Block Code';
    case 'code':
      return 'Code';
    case 'ssn':
      return 'Ssn';
    case 'principal':
      return 'Principal';
    case 'leave_yard_odometer':
      return 'Leave Yard Odometer';
    case 'return_to_yard_time':
      return 'Return To Yard Time';
    case 'first_stop_odometer':
      return 'First Stop Odometer';
    case 'gender':
      return 'Gender';
    case 'friday':
      return 'Friday';
    case 'actual_check_in':
      return 'Actual Check In';
    case 'year':
      return 'Year';
    case 'street_address_two':
      return 'Street Address Two';
    case 'end_date_reason':
      return 'End Date Reason';
    case 'first_stop_id':
      return 'First Stop';
    case 'done':
      return 'Done';
    case 'signature_public':
      return 'Signature Public';
    case 'str':
      return 'Str';
    case 'zone_id':
      return 'Zone';
    case 'longitude':
      return 'Longitude';
    case 'thursday':
      return 'Thursday';
    case 'gps_id':
      return 'Gps';
    case 'last_stop_time':
      return 'Last Stop Time';
    case 'tuesday':
      return 'Tuesday';
    case 'start_date':
      return 'Start Date';
    case 'updated_at':
      return 'Updated At';
    case 'client_name':
      return 'Client Name';
    case 'name':
      return 'Name';
    case 'interruption_driver_id':
      return 'interruption driver';
    case 'interruption_vehicle_id':
      return 'interruption vehicle';

    default:
      return value;
  }
}

function historyOldValue(value: any) {
  if (value.key === 'active') {
    return value.old_value === '1' ? 'Yes' : 'No';
  } else {
    return value.old_value;
  }
}

function historyNewValue(value: any) {
  if (value.key === 'active') {
    return value.new_value === '1' ? 'Yes' : 'No';
  } else {
    return value.new_value;
  }
}

export { hitoryValue, historyOldValue, historyNewValue };
