import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';

import { WorkflowRoutingModule } from './workflow-routing.module';
import { WorkflowTabComponent } from './workflow-tab.component';
import { WorkflowComponent} from './workflow/workflow.component';
import { WorkflowBarComponent } from './workflow-bar/workflow-bar.component';
import {AgGridModule} from 'ag-grid-angular';
import {NgBusyModule} from 'ng-busy';
import {MyDateRangePickerModule} from 'mydaterangepicker';
import {gridControlsModule} from '../../../components/grid-controls/grid-controls.module';
import {TabsModule} from 'ngx-bootstrap';
import {HeaderFiltersModule} from '../../../components/header-filters/header-filters.module';
import {ReactiveFormsModule} from '@angular/forms';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {BsModalModule} from 'ng2-bs3-modal';
import {FormTypeModule} from '../../admin/billing/form-type/form-type.module';
import { UnverifiedComponent } from './unverified/unverified.component';
import { SummaryWorkflowComponent } from './summary-workflow/summary-workflow.component';
import { FormBillingStatusComponent } from './workflow/form-billing-status/form-billing-status.component';
import {DashboardLsfModule} from '../../dashboard-lsf/dashboard-lsf.module';
import {ManifestsModule} from '../../manifests/manifests.module';
import {NgxPermissionsModule} from 'ngx-permissions';
import {UnprocessedModule} from '../unprocessed/unprocessed.module';
import {UnprocessedBillingComponent} from '../unprocessed/unprocessed.component';
import {ThemeModule} from '../../../theme/theme.module';
import {InvoiceReportModule} from './invoice-report/invoice-report.module';

@NgModule({
  imports: [
    CommonModule,
    AgGridModule.withComponents([]),
    NgBusyModule,
    MyDateRangePickerModule,
    gridControlsModule,
    TabsModule,
    HeaderFiltersModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule,
    BsModalModule,
    FormTypeModule,
    NgxPermissionsModule,
    DashboardLsfModule,
    ManifestsModule,
    UnprocessedModule,
    WorkflowRoutingModule,
    ThemeModule,

    InvoiceReportModule
  ],
  declarations: [
    WorkflowComponent,
    WorkflowTabComponent,
    WorkflowBarComponent,
    UnverifiedComponent,
    SummaryWorkflowComponent,
    FormBillingStatusComponent,
    UnprocessedBillingComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WorkflowModule { }
