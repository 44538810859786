import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CONSTANT, CONSTANTS } from '../app.constants';
import { IResponse } from '../CORE/interfaces/response.interface';
import { IClientInterruptionTemplate} from '../CORE/interfaces/client.interface';
@Injectable()
export class InterruptionService {
  protected resource: string;

  constructor(private http: HttpClient) {
    this.resource = environment.url_api_v + CONSTANTS.INTERRUPTIONCLIENTES;
  }

  /**
   * @description Return all interruption  client Configs
   * @returns {Observable<any>}
   */
  getInterruptionclientConfigs(): Observable<any> {
    return this.http.get<any>(this.resource);
  }

  /**
   * @description Return interruption for id
   * @returns {Observable<any>}
   */
  getInterruptionId(id): Observable<any> {
    return this.http.get<any>(`${environment.url_api_v}servicesInterruptions/billing/${id}`);
  }

  /**
   * @description Save a interruption client Configs
   * @param data
   * @returns {Observable<Response>}
   */
  storeInterruptionclientConfigs(data: any): Observable<Response> {
    return this.http.post<Response>(this.resource, JSON.stringify(data));
  }

  /**
   * @description Update a interruption client Configs
   * @param data
   * @returns {Observable<Response>}
   */
  updateInterruptionclientConfigs(data: any, id: number): Observable<Response> {
    return this.http.put<Response>(
      this.resource + '/' + id,
      JSON.stringify(data)
    );
  }

  /**
   * @description Get a interruption client Configs
   * @param {number} clienteId
   * @returns {Observable<any>}
   */
  getInterruptionClientConfig(clienteId: number): Observable<any> {
    return this.http.get(this.resource + '/' + clienteId);
  }

  /**
   * @description Get routes for interruption manual
   * @returns {Observable<any>}
   */
  getRoutesForInterruptionManual(data: any): Observable<any> {
    return this.http.post(`${environment.url_api_v}schedulesDetails/routes/byDate`, data);
  }

  /**
   * @description Create interruption manual
   * @returns {Observable<any>}
   */
  createInterruptionManual(data): Observable<any> {
    return this.http.post<any>(`${environment.url_api_v}servicesInterruptions`, data);
  }

  /**
   * @description Update interruption manual
   * @returns {Observable<any>}
   */
  updateInterruptionManual(id, data): Observable<any> {
    return this.http.put<any>(`${environment.url_api_v}servicesInterruptions/${id}`, data);
  }

  /**
   * @description Delete interruption manual
   * @returns {Observable<any>}
   */
  deleteInterruptionManual(id): Observable<any> {
    return this.http.delete<any>(`${environment.url_api_v}servicesInterruptions/${id}`);
  }

  /**
   * Description: Retorna una lista de todas las interrupciones que esten abiertas.
   */
  getListInterruption(block_status: number) {
    return this.http.get<any>(`${environment.url_api_v}servicesInterruptions/list/${block_status}`);
  }

  postInterruptionTest(data: {client_id: number, template_id: number}) {
    return this.http.post(`${environment.url_api_v + CONSTANTS.CLIENTS}/interruptionTest`, data);
  }

  getTemplateS3(template_id: number) {
    const url = CONSTANT.CLIENT.URL.GET_TEMPLATE_INTERRUPTIONS_S3;
    return this.http.get<IResponse<IClientInterruptionTemplate>>(url+template_id);
  }

}
