import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DriverClockLogService {

  constructor(private http: HttpClient) { }

  getClockLog(status: boolean) {
    return this.http.get(`${environment.url_api_v}driverClockLog/byFilter/${status}`);
  }

  syncClockLog(id) {
    return this.http.put(`${environment.url_api_v}driverClockLog/clockLog/update`, { id });
  }

}
