import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { BsModalComponent } from 'ng2-bs3-modal';
import { SweetAlertHelper } from '../../../CORE/helpers/sweet-alert-helper.service';
import { WorkflowService } from '../../../services/billing/workflow.service';
import { TabsetComponent } from 'ngx-bootstrap';
import { IResponse } from '../../../CORE/interfaces/response.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BillingStatusEnum } from '../../../CORE/enums/BilingStatusEnum.enum';
@Component({
  selector: 'app-workflow-tab',
  templateUrl: './workflow-tab.component.html',
  styleUrls: ['./workflow-tab.component.scss']
})
export class WorkflowTabComponent implements OnInit, OnDestroy {
  @ViewChild('modalManifest') modalManifest: BsModalComponent;
  @ViewChild('staticWorkflowTabs') staticWorkflowTabs: TabsetComponent;
  suscriptionWorkFlow: Subscription[] = [];
  ids: any[] = [];
  formBillingStatusWorkflow: FormGroup;
  constructor(private fb: FormBuilder, private workflowService: WorkflowService, private sweetAlertHelper: SweetAlertHelper) { }
  ngOnInit() {
    this.loadMessagesSweetAlert();
    this.subscribeObservers();
    this.setUnprocessedModuleAsOpenInWorkflow();
    this.createForm();
  }

  ngOnDestroy() {
    this.workflowService.unprocessedModuleIsOpenedOnWorkFlow(false);
    this.workflowService.clearSchedulesIdForSweetAlertToValidateDiscrepancies();
    this.suscriptionWorkFlow.forEach(subscription => subscription.unsubscribe());
  }
  loadMessagesSweetAlert() {
    this.workflowService.responseBillingStatusSubject$.subscribe({
      next: resp => {
        if (resp) {
          this.alertResponseChangeStatus(resp);
        }
      },
      error: error => {
      this.sweetAlertHelper.captureException(error.message).then();
      }
    });
  }

  createForm() {
    this.formBillingStatusWorkflow = this.fb.group({
      ids: [null],
      billing_status_id: [null, Validators.required],
      note: ['force by user system', Validators.required]
    });
  }

  subscribeObservers() {
    this.workflowService.openTabWorkflowSubject$.subscribe(tab => {
      if (tab) {
        switch (Number(tab)) {
          case 0:
            this.staticWorkflowTabs.tabs[0].active = true;
            break;
          case BillingStatusEnum.Unverified:
            this.staticWorkflowTabs.tabs[1].active = true;
            break;
          case BillingStatusEnum.Rejected || BillingStatusEnum.Verified:
            this.staticWorkflowTabs.tabs[2].active = true;
            break;
          case 3:
            this.staticWorkflowTabs.tabs[3].active = true;
            break;
        }
      }
    });
  }

  changeTab(tab) {
  }

  setUnprocessedModuleAsOpenInWorkflow(): void {
    this.workflowService.unprocessedModuleIsOpenedOnWorkFlow(true);
  }

  private alertResponseChangeStatus(data) {
    if (data && data['schedule_failed'] || data['schedule_success']) {
      this.ids.pop();
      let htmlMessage = '';
      let title = '';
      const schedule_successfully = data['schedule_success'] ? `
      <p style="color: green">Total Schedules Successfully ${ data['number_verified'] }</p>
      <p style="color: red">Total Schedules Errors ${ data['number_rejected'] }</p>` : '';
      if (data['schedule_failed'] && data['schedule_failed'].length) {
        data['schedule_failed'].forEach((schedule) => {
          this.ids.push(schedule['schedule_detail_id']);
          title = `<div> Schedules Validation<br><small><p style="color: green"> ${ schedule_successfully }</p></small></div>`;
          htmlMessage += `<hr style="margin-top: 5px; margin-bottom: 5px;">
                       <p style="color: red">Schedules Errors <small>${schedule['schedule_detail_id']}</small></p>
                      ${schedule['block_description'][0]} - ${schedule['block_description'][1]} - ${ schedule['block_description'][2] }<br>
                      <b> ${ schedule.errors.length } Discrepanses</b> <br>
                      ${this.errorsFormatted(schedule['errors'])}<br>`;
        });
        htmlMessage = `<div style="height: 150px; overflow-y: scroll;">${htmlMessage}</div>`;
      } else {
        title = `<div> Schedules Validation<br><small><p style="color: green"> ${ schedule_successfully }</p></small></div>`;
        htmlMessage += ``;
      }
      this.sweetAlertHelper
          .createCustomAlert({
            title: title,
            html: htmlMessage,
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Force Change Status',
            type: 'error',
          }).then((result) => {
            if (result.dismiss) {
              this.setChangeStatus(htmlMessage);
            }
          });
    }
    if (data && data.validations && data.validations.length) {
      let htmlMessage = '';
      htmlMessage += `<div>
                        <p style="color: red">Schedules Errors </p>
                         ${data.route} <br> ${ data.block_code } - ${data.block_description} <br>
                         <b> ${data.validations.length} Discrepanses</b> <br><br>
                      ${this.errorsFormatted(data.validations)}</div><br>`;

      this.sweetAlertHelper
          .createCustomAlert({
            title: 'Errors',
            html: `<div>${htmlMessage}</div>`,
            type: 'error'
          }).then();
    }
  }

  private errorsFormatted(str) {
    if (str.length > 0) {
      let errors = '';
      str.map(error => {
        errors += `${ error }<br>`;
      });
      return errors;
    }
    return str;
  }

  setChangeStatus(htmlMessage) {
    this.sweetAlertHelper
      .createCustomAlert({
        title: 'Force Change Status ',
        html: `<div>${htmlMessage}</div>`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, I want to change the statuses to verified',
        cancelButtonText: 'No, cancel!',
      }).then((result) => {
      if (result.value) {
        this.formBillingStatusWorkflow.patchValue({
          billing_status_id: 2,
          ids: this.ids,
          note: 'force by user system'
        });
        const formData = Object.assign({}, this.formBillingStatusWorkflow.value);
        this.suscriptionWorkFlow.push(
          this.workflowService.changeStatus(formData).subscribe({
              next: (resp: IResponse<any>): void => {
                if (resp && resp.status) {
                  this.sweetAlertHelper
                    .createCustomAlert({
                    type: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: true
                  }).then();
                  this.workflowService.reloadWorkflowSubject.next(true);
                  this.formBillingStatusWorkflow.reset();
                }
              }, error: error => {
                this.sweetAlertHelper.captureException(error).then();
              }
            }
          )
        );
      }
    });
  }

}
