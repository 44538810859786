import { Injectable } from '@angular/core';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';

interface IHttpResponseError {
  status: number;
  statusText: string;
  url: string;
  ok: boolean;
  name: string;
  message: string;
  error: IError | IValidationError;
}

interface IError {
  status: string;
  message: string;
}

interface IValidationError {
  status: string;
  data: string [];
}

@Injectable({
  providedIn: 'root'
})

export class SweetAlertHelper {
  constructor() {
  }

  createCustomAlert(options: SweetAlertOptions): Promise<SweetAlertResult> {
    return Swal.fire(options);
  }

  captureException(httpError: IHttpResponseError): Promise<SweetAlertResult> {
    const { error } = httpError;

    if ('data' in error && error.data.length) {
      return this.createCustomAlert({
        title: 'Warning',
        html: this.mapValidationErrors(error.data),
        type: 'warning'
      });
    }

    if ('message' in error && error.message) {
      return this.createCustomAlert({
        title: 'Error',
        text: error.message,
        type: 'error'
      });
    }

    return this.createCustomAlert({
      title: 'Error',
      text: 'An error has occurred!',
      type: 'error'
    });
  }

  private mapValidationErrors(validations: string[]): string {
    let htmlMessage = '';
    validations.forEach((el: string): void => {
      htmlMessage += `<div>${this.capitalizeFirstLetter(el)}</div><hr style="margin-top: 5px; margin-bottom: 5px;">`;
    });
    return htmlMessage;
  }

  private capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
