import { Component, OnDestroy, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { PayrollService } from './../../../../services/payroll/payroll.service';

/**
  * Componente maestro de reglas para el payroll
  * Este componente corresponde a la vista donde se gestionara el maestro de reglas
  *
  * Principales funciones
  * * Cargar listado de payroll rules.
*/
@Component({
  selector: 'app-payroll-rules',
  templateUrl: './payroll-rules.component.html',
  styleUrls: ['./payroll-rules.component.scss']
})
export class PayrollRulesComponent implements OnInit, OnDestroy {
  busy: Subscription;
  addRule = false;
  enabledBtn = false;
  ruleSekected = 0;
  ruleSekectedCountBlocks = 0;
  showStatus = 1;
  gridPayrollRules: GridOptions;
  dataRules = [];
  titleCountGrid = 'Rules 0 of 0';

  /**
   * @description
   * @param payrollService
   */
  constructor(private payrollService: PayrollService) {
    this.gridPayrollRules = <GridOptions>{
      onGridReady: () => {
        this.getAllPayrollRules();
      },
      onFilterModified: () => {
        const cant = this.gridPayrollRules.api.getDisplayedRowCount();
        this.titleCountGrid = `Rules ${cant} of ${this.dataRules.length}`;
      }
    };

    this.gridPayrollRules.columnDefs = [
      { headerName: 'Code', field: 'code', width: 100 },
      { headerName: 'Round Up', field: 'round_up', width: 100 },
      { headerName: 'Pay Rate REG', field: 'pay_rate', width: 120 },
      { headerName: 'Pay Rate PRT', field: 'pay_rate_part_time', width: 120 },
      {
        headerName: 'Status', field: 'active', width: 100,
        cellRenderer: (params) => {
          return params.data.active ?
            '<span class="label label-success" style="margin-top: 4px; position: absolute;">Active</span>' :
            '<span class="label label-danger" style="margin-top: 4px; position: absolute;">Disabled</span>'
            ;
        }
      }
    ];
  }

  /**
   * @description
   */
  ngOnInit() {
  }

  /**
   * @description
   */
  ngOnDestroy() {
    return this.busy ? this.busy.unsubscribe() : null;
  }

  /**
   * @description
   * @param $event
   */
  onCellClicked($event) {
    this.ruleSekected = $event.data.id;
    this.ruleSekectedCountBlocks = $event.data.total;
  }

  /**
   * @description
   * @param $event
   * @constructor
   */
  OnCellDoubleClicked($event) {
    this.ruleSekected = $event.data.id;
    this.ruleSekectedCountBlocks = $event.data.total;
    this.onEditRule();
  }

  /**
   * @description
   * @param mensaje
   */
  onNotify(mensaje: any) {
    this.getAllPayrollRules();
  }

  /**
   * @description
   */
  onNewRule() {
    this.ruleSekected = 0;
    this.changeStateModal();
  }

  /**
   * @description
   */
  onEditRule() {
    if (this.ruleSekected === 0) {
      Swal.fire({
        position: 'top-end',
        type: 'warning',
        title: 'Please, check a row',
        showConfirmButton: false,
        timer: 1500
      });
    } else {
      this.changeStateModal();
    }
  }

  /**
   * @description
   */
  changeStateModal() {
    this.addRule = !this.addRule;
    this.enabledBtn = !this.enabledBtn;
  }

  /**
   * @description
   */
  getAllPayrollRules() {
    this.busy = this.busy = this.payrollService.getPayrollRules().subscribe((resp: any) => {
      const datos = resp.data;
      this.dataRules = resp.data;
      const dataGrid = datos.filter(r => Number(r.active) === (this.showStatus === 0 ? Number(r.active) : 1));
      const filter = this.gridPayrollRules.api.getFilterModel();
      this.gridPayrollRules.api.setRowData(dataGrid);
      this.gridPayrollRules.api.setFilterModel(filter);
      this.titleCountGrid = `Rules ${dataGrid.length} of ${datos.length}`;
    });
  }

  changeShowStatus() {
    if (this.showStatus === 1) {
      this.showStatus = 0;
    } else {
      this.showStatus = 1;
    }
    const datos = Object.assign([], this.dataRules);
    const dataGrid = datos.filter(r => Number(r.active) === (this.showStatus === 0 ? Number(r.active) : 1));
    const filter = this.gridPayrollRules.api.getFilterModel();
    this.gridPayrollRules.api.setRowData(dataGrid);
    this.gridPayrollRules.api.setFilterModel(filter);
    this.titleCountGrid = `Rules ${dataGrid.length} of ${datos.length}`;
  }
}
