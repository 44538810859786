import { Component, OnInit, ViewChild } from '@angular/core';
import { FilterModifiedEvent, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { Subscription } from 'rxjs/Rx';
import { StopsService } from '../../../services/stops/stops.service';
import AgGridHelpers from '../../../helpers/ag-grid-helpers';

@Component({
  selector: 'app-stops',
  templateUrl: './stops.component.html',
  styleUrls: ['./stops.component.scss']
})
export class StopsComponent implements OnInit {

  busy: Subscription;
  @ViewChild('modalAddStop') modalAddStop: any;
  countGrid = 0;
  gridStops: GridOptions;
  dataEdit: any;

  constructor(private stopsService: StopsService) {
    this.setConfigGrid();
  }

  ngOnInit() {
  }

  setConfigGrid() {
    this.gridStops = <GridOptions> {
      enableSorting: true,
      onGridReady: (event: GridReadyEvent) => {
        this.loadData();
      },
      onFilterModified: (event: FilterModifiedEvent) => {
        this.countGrid = this.gridStops.api.getDisplayedRowCount();
      },
      columnDefs : [
        { headerName: 'Description', field: 'description', width: 170 },
        { headerName: 'Street', field: 'street', width: 200 },
        { headerName: 'City', field: 'city', width: 70 },
        { headerName: 'Zip', field: 'postalCode', width: 70 },
        { headerName: 'Status', width: 100, field: 'active',
          cellRenderer: (params) => AgGridHelpers.statusRecord(params.data.active)
        }
      ]
    };
  }

  loadData() {
    this.busy = this.stopsService.list().subscribe((resp: any) => {
      this.gridStops.api.setRowData(resp.data);
      this.countGrid = this.gridStops.api.getDisplayedRowCount();
      this.changeShowStatus(false);
    });
  }

  changeShowStatus(show: boolean) {
    show ? this.gridStops.api.setFilterModel({ active: ['true', 'false'] }) : this.gridStops.api.setFilterModel({ active: ['true'] });
    this.gridStops.onFilterChanged = function (params) {};
    this.countGrid = this.gridStops.api.getDisplayedRowCount();
  }

  onCellClicked(event: any) {

  }

  onCellDoubleClicked(event: any) {
    this.dataEdit = event.data;
    this.modalAddStop.open();
  }

  onReload(event: any) {
    this.loadData();
  }

}
