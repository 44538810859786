import { Component, Input, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { ClientService } from '../../../../../services/fleet/client.service';
import { DashboardLsfDataService } from '../../../dashboard-lsf.data.service';
import { capitalize } from '../../../../../helpers/capitalize';

export interface ISchedule {
  route: string;
  blocks: string;
  driver: string;
  first_name: string;
  last_name: string;
  employee_id: string;
  sunday: string;
  monday: string;
  tuesday: string;
  wednesday: string;
  thrusday: string;
  friday: string;
  saturday: string;
}

@Component({
  selector: 'app-schedule-drivers',
  templateUrl: './schedule-drivers.component.html',
  styleUrls: ['./schedule-drivers.component.scss']
})
export class ScheduleDriversComponent implements OnInit {

  @Input() schedule_id?: number;
  busy: Subscription;
  gridSchedules2: GridOptions;

  gridApi;
  gridColumnApi;

  clients: any[];
  dataSchedule: ISchedule[] = [];
  btnCollapseStatus = true;
  countRow: number;

  constructor(private clientService: ClientService, private dashboardLsfDataService: DashboardLsfDataService) {
    this.setConfigGrid();
  }

  ngOnInit() {
    this.getClients();
  }

  setConfigGrid() {
    let columnDefs2 = [
      { headerName: 'Drivers', field: 'driver', width: 130, hide: true },
      { headerName: 'Last Name', field: 'last_name', width: 130 },
      { headerName: 'First Name', field: 'first_name', width: 130 },
      { headerName: 'Routes', colId: 'route', field: 'route', width: 140 },
      { headerName: 'Blocks', colId: 'blocks', field: 'blocks', width: 120 },
      { headerName: 'Employee', field: 'employee_id', width: 100, rowGroup: true },
      { headerName: 'Sunday', field: 'sunday', width: 130 },
      { headerName: 'Monday', field: 'monday', width: 130 },
      { headerName: 'Tuesday', field: 'tuesday', width: 130 },
      { headerName: 'Wednesday', field: 'wednesday', width: 130 },
      { headerName: 'Thrusday', field: 'thrusday', width: 130 },
      { headerName: 'Friday', field: 'friday', width: 130 },
      { headerName: 'Saturday', field: 'saturday', width: 130 },
    ];
    this.gridSchedules2 = {
      columnDefs: columnDefs2,
      rowData: null,
      debug: false,
      getRowStyle: getRowStyleScheduled,
      groupMultiAutoColumn: true,
      enableFilter: true,
      onRowGroupOpened() {
        this.btnCollapseStatus = false;
      },
      autoGroupColumnDef: {
        cellRendererParams: {
          suppressCount: true,
        }
      },
      groupDefaultExpanded: -1
    };

    function getRowStyleScheduled(params) {
      if (params.data) {
        if (params.data.route === 'Report Time') {
          return { 'color': 'red' };
        } else if (params.data.route === 'Punch Out Time') {
          return { 'color': 'red' };
        } else if (params.data.route === 'Start Time') {
          return { 'color': 'black' };
        } else if (params.data.route === 'End Time') {
          return { 'color': 'black'};
        } else {
          return { 'color': '#FFF', 'font-weight': 600, 'background-color': '#85AEFF'};
        }
      }
      return null;
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridSchedules2.api.setRowData([]);
  }

  getClients() {
    this.busy = this.clientService.getClientStatus(true).subscribe((resp: any) => {
      if (resp.status === 'success') {
        this.clients = resp.data;
      }
    });
  }

  onChangeClient(clientId: number) {
    this.onProcessDataSchedule(clientId);
  }

  onProcessDataSchedule(clientId: number) {
    this.busy = this.dashboardLsfDataService.getScheduleDrivers(this.schedule_id, clientId).subscribe((resp: any) => {
      this.dataSchedule = [];
      if (resp.status === 'success') {
        const data = resp.data;
        if (data.length > 0) {
          data.forEach((row: any) => {
            const blocks: any[] = row.blocks;
            blocks.forEach((block: any) => {
              this.dataSchedule.push({
                driver: `${row.first_name} ${row.last_name}`,
                first_name: capitalize(`${row.first_name}`),
                last_name:  capitalize(`${row.last_name}`),
                employee_id: `${row.employee_id}`,
                route: block.route,
                blocks: `${block.block} - ${block.block_code}`,
                sunday: `${block.startLocation}`,
                monday: `${block.startLocation}`,
                tuesday: `${block.startLocation}`,
                wednesday: `${block.startLocation}`,
                thrusday: `${block.startLocation}`,
                friday: `${block.startLocation}`,
                saturday: `${block.startLocation}`
              });

              this.dataSchedule.push({
                driver: `${row.first_name} ${row.last_name}`,
                first_name: capitalize(`${row.first_name}`),
                last_name:  capitalize(`${row.last_name}`),
                employee_id: `${row.employee_id}`,
                route: `Report Time`,
                blocks: ``,
                sunday: `${block.days[0].report_time}`,
                monday: `${block.days[1].report_time}`,
                tuesday: `${block.days[2].report_time}`,
                wednesday: `${block.days[3].report_time}`,
                thrusday: `${block.days[4].report_time}`,
                friday: `${block.days[5].report_time}`,
                saturday: `${block.days[6].report_time}`
              });

              this.dataSchedule.push({
                driver: `${row.first_name} ${row.last_name}`,
                first_name: capitalize(`${row.first_name}`),
                last_name:  capitalize(`${row.last_name}`),
                employee_id: `${row.employee_id}`,
                route: `Start Time`,
                blocks: ``,
                sunday: `${block.days[0].start_time}`,
                monday: `${block.days[1].start_time}`,
                tuesday: `${block.days[2].start_time}`,
                wednesday: `${block.days[3].start_time}`,
                thrusday: `${block.days[4].start_time}`,
                friday: `${block.days[5].start_time}`,
                saturday: `${block.days[6].start_time}`
              });

              this.dataSchedule.push({
                driver: `${row.first_name} ${row.last_name}`,
                first_name: capitalize(`${row.first_name}`),
                last_name:  capitalize(`${row.last_name}`),
                employee_id: `${row.employee_id}`,
                route: `End Time`,
                blocks: ``,
                sunday: `${block.days[0].end_time}`,
                monday: `${block.days[1].end_time}`,
                tuesday: `${block.days[2].end_time}`,
                wednesday: `${block.days[3].end_time}`,
                thrusday: `${block.days[4].end_time}`,
                friday: `${block.days[5].end_time}`,
                saturday: `${block.days[6].end_time}`
              });

              this.dataSchedule.push({
                driver: `${row.first_name} ${row.last_name}`,
                first_name: capitalize(`${row.first_name}`),
                last_name:  capitalize(`${row.last_name}`),
                employee_id: `${row.employee_id}`,
                route: `Punch Out Time`,
                blocks: ``,
                sunday: `${block.days[0].punch_out_time}`,
                monday: `${block.days[1].punch_out_time}`,
                tuesday: `${block.days[2].punch_out_time}`,
                wednesday: `${block.days[3].punch_out_time}`,
                thrusday: `${block.days[4].punch_out_time}`,
                friday: `${block.days[5].punch_out_time}`,
                saturday: `${block.days[6].punch_out_time}`
              });

            });
          });
          this.countRow = this.dataSchedule.length;
          this.gridSchedules2.api.setRowData(this.dataSchedule);
          this.gridSchedules2.api.collapseAll();
          this.btnCollapseStatus = false;
        } else {
          this.gridSchedules2.api.setRowData([]);
          this.countRow = 0;
        }
      } else {
        this.gridSchedules2.api.setRowData([]);
        Swal.fire({
          type: 'warning',
          title: 'Oops...',
          text: 'Error loading the drivers, please retry',
        });
      }
    });
  }

  onCollapse() {
    this.btnCollapseStatus = !this.btnCollapseStatus;
    return (!this.btnCollapseStatus) ? this.gridSchedules2.api.collapseAll() : this.gridSchedules2.api.expandAll();
  }

}
