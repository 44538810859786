import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CONSTANTS } from '../app.constants';
import { NgxPermissionsService } from 'ngx-permissions';
import { ReportsManageService } from './reports-manage.service';

export interface User {
  username: string;
  password: string;
  grant_type?: string;
  client_id?: string;
  client_secret?: string;
  scope?: string;
}

export interface Token {
  token_type: string;
  expires_in: number;
  access_token: string;
  refresh_token: string;
}

export interface UserInformation {
  id: number;
  name: string;
  email: string;
  roles: any;
  reenterpassword: boolean;
  companies: any;
  users_client_id:string;
}

@Injectable()
export class AuthService {
  constructor(
    private http: HttpClient,
    private ngxPermissionsService: NgxPermissionsService,
    private reportsManageService: ReportsManageService
  ) {}

  getToken(user: User): Observable<Token> {
    user.grant_type = environment.grant_type;
    user.client_id = environment.client_id;
    user.client_secret = environment.client_secret;
    user.scope = environment.scope;
    const body = JSON.stringify(user);

    return this.http.post<Token>(environment.url + CONSTANTS.GET_TOKEN, body);
  }

  getUserInformation(): Observable<UserInformation> {
    return this.http.get<UserInformation>(
      environment.url_api + CONSTANTS.GET_USER_INFO
    );
  }

  setTokenLocalStorage(token: string): void {
    localStorage.setItem('token', token);
  }

  getTokenLocalStorage(): string {
    return localStorage.getItem('token');
  }

  deleteTokenLocalStorage(): void {
    localStorage.removeItem('token');
  }

  setUserInformationLocalStorage(info: UserInformation) {
    localStorage.setItem('currentUser', JSON.stringify(info));
  }

  setlastLoginLocalStorage(): void {
    localStorage.setItem('lastLogin', new Date().toDateString());
  }

  setCompaniesLocalStorage(companies: any): void {
    localStorage.setItem('companies', JSON.stringify(companies));
  }

  setNameDbLocalStorage(namedb: string) {
    localStorage.setItem('namedb', namedb);
  }

  getNameDbLocalStorage(): string {
    return (localStorage.getItem('namedb') ? localStorage.getItem('namedb') : '');
  }

  setCompanyId(id: any) {
    localStorage.setItem('companyid', id);
  }

  setTenantTrip2(tenant: string, key: string) {
    localStorage.setItem('repo_tenant', tenant);
    localStorage.setItem('repo_key', key);
    this.reportsManageService.getTokenTrip2(tenant, key);
  }

  getTenant() {
    return localStorage.getItem('repo_tenant');
  }

  getTenantKey() {
    return localStorage.getItem('repo_key');
  }

  getCompanyId() {
    return localStorage.getItem('companyid');
  }

  getYear() {
    return new Date().getFullYear();
  }

  async loadPermissions(roles) {
    if (roles == null) {
      const info: any = await this.getUserInformation().toPromise().then(res => res).catch(() => ({ roles : [] }));
      roles = info.roles;
    }
    const permisos: any[] = [];
    roles.forEach(rol => {
      if (rol.permissions) {
        rol.permissions.forEach(permiso => {
          permisos.push(permiso.name);
        });
      }
    });
    this.ngxPermissionsService.loadPermissions(permisos);
  }

  clearLocalStore() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    localStorage.removeItem('lastLogin');
    localStorage.removeItem('namedb');
    localStorage.removeItem('companies');
    localStorage.removeItem('token_tso_main');
    localStorage.removeItem('token_tso');
    localStorage.removeItem('companyid');
    localStorage.removeItem('repo_tenant');
    localStorage.removeItem('repo_key');
    localStorage.removeItem('repo_tenant_token');
  }

}
