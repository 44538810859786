export class Client {
  id: number;
  client_name: string;
  contact: string;
  position: string;
  address_one: string;
  address_two?: any;
  city?: any;
  state?: any;
  zip?: any;
  active: string;
  created_at: string;
  updated_at: string;
}

export class Division {
  id: number;
  client_id: string;
  code: string;
  division_name: string;
  active: string;
  created_at: string;
  updated_at: string;
  client: Client;
}

export class Route {
  route_code: string;
  client_id: number;
  description: string;
}


export class TimeTypes {
  id: number;
  description: string;
  deleted_at?: any;
  created_at?: any;
  updated_at?: any;
}


//--------- Nuevas clases ---------

export class RouteBlockHead {
  id: number;
  route_id: number;
  description: string;
  block_code: string;
  startLocation?: string;
  route_block_details: Array<RouteBlockDetail> = [];
  active: boolean;
}

export class RouteBlockDetail {
  driver_id: number;
  vehicle_id: number;
  week_day_id: number;
  time_type_id: number;
  report_time: string;
  start_time: string;
  end_time: string;
  punch_out_time: string;
  driver: any;
  vehicle: any;
  leave_yard_time: any;
  time_type: any;
}

export class btnSave {
  id: number;
  save: boolean;
  edit: boolean;
}
