import { HttpErrorResponse } from '@angular/common/http';
import { Compiler, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BsModalComponent } from 'ng2-bs3-modal';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { environment } from '../../environments/environment';
import { NotificationService } from '../components/services/push/notification.service';
import { AuthService, Token, UserInformation } from '../services/auth.service';
import { Password } from '../shared/layout/components/change-password/password';
import { LoginDataService } from './login.data.service';
import {UserTypeEnum} from '../CORE/enums/UserTypeEnum.enum';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('modal') modal: BsModalComponent;
  password: Password = new Password();
  busy: Subscription;
  formLogin: FormGroup;
  companies: any = [];
  selectCompanies = false;
  companiesStore: any;
  remember_me = false;
  reenterpassword = false;
  passWord;
  show = false;

  constructor(
    private fb: FormBuilder,
    private _dataService: LoginDataService,
    private router: Router,
    private _compiler: Compiler,
    public _serviceAuth: AuthService,
    private notificationService: NotificationService
  ) {
    _compiler.clearCache();
  }

  ngOnInit() {
    this.buildForm();
    if (localStorage.getItem('username') && localStorage.getItem('password')) {
      this.decrypt();
      this.remember_me = true;
    } else {
      this.remember_me = false;
    }
    this.passWord = 'password';
  }

  private buildForm() {
    this.formLogin = this.fb.group({
      username: ['', [Validators.required, Validators.minLength(4), Validators.email]],
      password: ['', [Validators.required, Validators.minLength(4)]],
      newPassword: [''],
      newPaswordConfirm: [''],
    });
  }

  encrypt() {
    localStorage.setItem('username', btoa(this.formLogin.value.username));
    localStorage.setItem('password', btoa(this.formLogin.value.password));
  }

  decrypt() {
    this.formLogin.controls['username'].setValue(atob(localStorage.getItem('username')));
    this.formLogin.controls['password'].setValue(atob(localStorage.getItem('password')));
  }

  public onSubmit(values: any): void {
    if (this.reenterpassword) {
      this.changePass();
    } else {
      if (this.formLogin.valid) {
        this.busy = this._serviceAuth.getToken(values).subscribe(
          (res: Token) => {
            this._serviceAuth.setTokenLocalStorage(res.access_token);
            if (this.remember_me) {
              this.encrypt();
            } else {
              localStorage.removeItem('username');
              localStorage.removeItem('password');
            }
            this.getInfoUser();
          },
          (error1: HttpErrorResponse) => {
            if (error1.status === 401) {
              Swal.fire({
                type: 'warning',
                title: 'Oops...',
                text: 'The user credentials were incorrect. !'
              }).then();
            } else {
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: 'This is not good!\n' + error1.status + '\n' + error1.message
              }).then();
            }
          },
          () => {
            Swal.fire({
              position: 'top-end',
              type: 'success',
              title: 'Correct access data! Entering...',
              showConfirmButton: false,
              timer: 1500
            }).then();
          }
        );
      }
    }
  }

  getInfoUser() {
    this.busy = this._serviceAuth.getUserInformation().subscribe(
      (res: UserInformation) => {
        this._serviceAuth.setUserInformationLocalStorage(res);
        this._serviceAuth.setlastLoginLocalStorage();
        if (res.reenterpassword) {
          this.reenterpassword = true;
        } else {
          this.reenterpassword = false;
          if (res.companies.length > 0) {
            this._serviceAuth.setCompaniesLocalStorage(res.companies);
            if (res.companies.length === 1) {
              this.companies = res.companies[0];
              this._serviceAuth.setCompanyId(this.companies.company_id);
              this._serviceAuth.setNameDbLocalStorage(this.companies.company.namedb);
              this._serviceAuth.setTenantTrip2(this.companies.company.tenant, this.companies.company.tenant_key);
              this.getScheduleID();
            } else {
              this.companies = res.companies;
              this.selectCompanies = true;
            }
            this._serviceAuth.loadPermissions(res.roles).then(() => {
              if (environment.production) {
                this.notificationService.initService();
              }
            });
          } else {
            Swal.fire({
              type: 'warning',
              title: 'Oops...',
              text: 'The user does not have an assigned account, please consult with the administrator'
            }).then();
          }
        }
      },
      (error1: HttpErrorResponse) => {
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'This is not good!\n' + error1.status + '\n' + error1.message
        }).then();
      }
    );
  }

  getScheduleID() {
    const year = moment().weekYear();
    const week = moment().week();
    // const year = moment().isoWeekYear();
    // const week = moment().isoWeek();
    const clientUser = JSON.parse(localStorage.getItem('currentUser'));
    if (clientUser && clientUser.user_types && clientUser.user_types.description &&  clientUser.user_types.description === UserTypeEnum.Admin) {
    this.busy = this._dataService.getData('schedules/year/' + year + '/week/' + week + '').subscribe((data: any) => {
      if (data.length > 0) {
        this.router.navigate(['/dashboard/schedules/' + data[0].id + '/' + true]);
      } else {
        this.router.navigate(['/dashboard/lsf']);
      }
    });
    } else {
      this.router.navigate(['/dispatch/real-time']);
    }
  }

  onNotify() {
    this.modal.close();
  }

  onEnter() {
    localStorage.setItem('namedb', this.companiesStore);
    const company = this.companies.filter(row => row.company.namedb === this.companiesStore).shift();
    this._serviceAuth.setCompanyId(company.company_id);
    this._serviceAuth.setTenantTrip2(company.company.tenant, company.company.tenant_key);
    this.getScheduleID();
  }

  onRememberMe() {
    this.remember_me = !this.remember_me;
  }

  changePass() {
    this.password.oldPassword = this.formLogin.get('password').value;
    if (this.password.newPassword === this.password.newPaswordConfirm) {
      delete this.password.newPaswordConfirm;
      this.busy = this._dataService
        .putData(this.password, 'users/changePassword')
        .subscribe((data: any) => {
          alert(data.message);
          this.getInfoUser();
        }, err => {
          alert(`Don't Change Password, please verify information and try again.`);
        });
    } else {
      alert('The confirmation of the password is not correct');
    }
  }

  showPassword() {
    if (this.passWord === 'password') {
      this.passWord = 'text';
      this.show = true;
    } else {
      this.passWord = 'password';
      this.show = false;
    }
  }
}
