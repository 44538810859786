import { AbstractControl, FormGroup } from '@angular/forms';

const IsRequired = (control): boolean => {
  if (!control) {
    return false;
  }
  if (control.validator) {
    const validator = control.validator({} as AbstractControl);
    if (validator && validator.required && control.errors) {
      return true;
    }
  }
  return false;
};

export const FieldIsRequired = (formGroup: FormGroup, fieldKey: string) => {
  return IsRequired(formGroup.get(fieldKey));
};



