import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalInComponent } from './modal-in.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ModalInComponent
  ],
  exports: [
    ModalInComponent
  ]
})
export class ModalInModule { }
