import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GridOptions , GridReadyEvent} from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { DataService } from '../../app.data.service';
import { historyNewValue, historyOldValue, hitoryValue } from '../../helpers/history';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit, OnDestroy {
  @Input()
  model_id?: number;
  @Input()
  model?: string;

  public busy: Subscription[] = [];
  public gridHistory: GridOptions;
  public apiResponse: any = {};
  private endpoint = '';
  public data: any = {};

  constructor(private _dataService: DataService) {
   this.initGrid();
  }

  ngOnInit() {
    this.endpoint = this.model;
  }


  initGrid() {
    this.gridHistory = <GridOptions>{
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      rowSelection: 'single',
      enableFilter: true,
      columnDefs: [
        { headerName: 'User', valueGetter: this.userName, width: 120 },
        { headerName: 'Field', field: 'key', width: 90, valueGetter: this.hitoryValue },
        { headerName: 'Old Value', field: 'old_value', width: 100, valueGetter: this.historyOldValue },
        { headerName: 'New Value', field: 'new_value', width: 100, valueGetter: this.historyNewValue },
        { headerName: 'Created At', field: 'created_at', width: 150 }
      ],
      onGridReady: (event: GridReadyEvent) => {
        if (event) {
          if (this.model_id) {
            this.loadInfo(this.model_id);
          }
        }
      }
    };
  }

  ngOnDestroy() {
    this.busy.forEach(subscription => subscription.unsubscribe());
  }

  hitoryValue(row: any) {
    return hitoryValue(row.data.key);
  }

  historyOldValue(row: any) {
    return historyOldValue(row.data);
  }

  historyNewValue(row: any) {
    return historyNewValue(row.data);
  }

  userName(row) {
    if (typeof row.data.user === 'object') {
      return row.data.user.name;
    } else {
      return row.data.user;
    }
  }

  loadInfo(model_id: number) {
    if (model_id) {
      this.busy.push(this._dataService.getData(this.endpoint + '/history/' + this.model_id).subscribe((resp: any) => {
        if (resp && resp.length) {
          this.gridHistory.api.setRowData(resp);
        } else {
          this.gridHistory.api.setRowData([]);
        }
      }));
    }
  }
}
