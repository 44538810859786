import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CellClickedEvent, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { Subject, Subscription } from 'rxjs';
import AgGridHelpers from '../../../../../../helpers/ag-grid-helpers';
import { takeUntil } from 'rxjs/operators';
import { ClientService } from '../../../../../../services/fleet/client.service';
import { IFormBilling } from '../form-billing.infertace';
import { SweetAlertHelper } from '../../../../../../CORE/helpers/sweet-alert-helper.service';

@Component({
  selector: 'app-form-billing-grid',
  templateUrl: './form-billing-grid.component.html',
  styleUrls: ['./form-billing-grid.component.scss']
})

export class FormBillingGridComponent implements OnInit, OnDestroy {
  @Input() clientId?: number;
  private destroy: Subject<boolean> = new Subject<boolean>();
  busy: Subscription[] = [];
  gridFormBilling: GridOptions;
  gridData: IFormBilling[] = [];
  showInactives = false;

  constructor(private clientService: ClientService, private sweetAlertHelper: SweetAlertHelper) {
    this.createGrid();
  }

  ngOnInit() {
    this.loadGridState(false);
    this.suscribeObservers();
  }

  suscribeObservers() {
    this.clientService.showInactives$.pipe(takeUntil(this.destroy)).subscribe((show: boolean) => {
      this.showInactives = show;
      this.loadGridState(show);
    });

    this.clientService.reloadBillingGrid$.pipe(takeUntil(this.destroy)).subscribe((reload: boolean) => {
      if (reload) {
        this.loadGridState(this.showInactives);
      }
    });
  }

  loadGridState(inactives: boolean) {
    this.busy.push(
      this.clientService.getFormsClient(this.clientId, inactives).subscribe(
        (resp: IFormBilling[]) => {
          if (resp) {
            this.gridData = resp;
          }
        },
        error => {
          this.sweetAlertHelper.captureException(error.message);
        }
      )
    );
  }

  createGrid() {
    this.gridFormBilling = <GridOptions> {
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      rowSelection: 'single',
      enableFilter: true,
      columnDefs: [
        { headerName: 'Id', field: 'id', width: 50 },
        { headerName: 'Description', field: 'description', width: 150 },
        { headerName: 'Icon', field: 'icon', width: 120 },
        { headerName: 'Order', field: 'order', width: 80 },
        { headerName: 'Form', field: 'form.description', width: 140 },
        {
          headerName: 'Small Cities',
          field: 'small_cities',
          width: 110,
          cellRenderer: params => {
            if (params && params.data) {
              return AgGridHelpers.statusRecord(params.data.small_cities);
            }
          }
        },
        {
          headerName: 'Active',
          field: 'active',
          width: 80,
          cellRenderer: params => {
            if (params && params.data) {
              return AgGridHelpers.statusRecord(params.data.active);
            }
          }
        }
      ],
      onGridReady: (event: GridReadyEvent) => {
        if (event) {
          this.gridFormBilling.api.setRowData([]);
        }
      },
      onCellClicked: (event: CellClickedEvent) => {
        if (event) {
          this.clientService.editRowBillingForm(event.data);
        }
      }
    };
  }

  ngOnDestroy(): void {
    this.busy.forEach(subscription => subscription.unsubscribe());
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }
}
