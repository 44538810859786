import { Component, OnInit } from '@angular/core';
import { GetContextMenuItemsParams, GridOptions, ExcelStyle, GridReadyEvent  } from 'ag-grid-community';
import { Subscription } from 'rxjs-compat';

@Component({
  selector: 'app-report-client-component',
  templateUrl: 'report-client.component.html',
  styleUrls: ['./report-client.component.scss']
})

export class ReportClientComponent implements OnInit {
  busy: Subscription;
  gridDataFile: GridOptions;
  titleCountGrid = 'Employees 0 of 0';
  listCurrent = 0;
  excelStyles: { borders: { borderBottom: { color: string; lineStyle: string; weight: number } }; id: string; alignment: { vertical: string }; interior: { patternColor: undefined; color: string; pattern: string } }[] = [
    {
      id: 'RedBackground',
      alignment: {
        vertical: 'Center',
      },
      interior: {
        color: '#a46d6d',
        pattern: 'Solid',
        patternColor: undefined,
      },
      borders: null,
    }
  ];
  constructor() {
    this.setConfigGrid();
  }

  ngOnInit() {}

  getRowStyle(params: any): any {
    if (params.data.rate_diff != null) {
      return {
        'background-color': '#F87F7F',
      };
    }
  }
  setConfigGrid() {
    this.gridDataFile = <GridOptions> {
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      onFilterModified: () => {
        this.changeTitleCountGrid();
      },
      onGridReady(event: GridReadyEvent) {
        event.api.sizeColumnsToFit();
        event.api.setRowData([]);
      }
    };
    const cellClassRules = {
      RedBackground: (params) => {
        return params.data.rate_diff != null;
      },
    };
    this.gridDataFile.columnDefs = [
      { headerName: 'Date', width: 110, field: 'date', cellClassRules: cellClassRules },
      { headerName: 'Payroll Code', field: 'payroll_code', width: 120, cellClassRules: cellClassRules },
      { headerName: 'Dept', width: 110, field: 'dept', cellClassRules: cellClassRules },
      { headerName: 'Client', width: 110, field: 'client_name', cellClassRules: cellClassRules },
      { headerName: 'Route', width: 110, field: 'route', cellClassRules: cellClassRules },
      { headerName: 'Block', width: 110, field: 'route_block', cellClassRules: cellClassRules },
      { headerName: 'Employee Id', width: 110, field: 'emp', cellClassRules: cellClassRules },
      { headerName: 'Employee', field: 'employee_name', width: 160, cellClassRules: cellClassRules },
      { headerName: 'Reg Hrs', width: 100, field: 'reghrs', cellClassRules: cellClassRules },
      { headerName: 'O.T Hrs', field: 'o_t_hrs', width: 100, cellClassRules: cellClassRules },
      { headerName: 'Rate', field: 'rate', width: 100, cellClassRules: cellClassRules },
      { headerName: 'Type', field: 'type', cellRenderer: (params) => params.data.type ? params.data.type : '', width: 110, cellClassRules: cellClassRules },
      { headerName: 'Location', field: 'location', width: 100, cellClassRules: cellClassRules },
      {
        headerName: 'Insurance',
        width: 100,
        field: 'is_insured',
        cellRenderer: params => {
          if (params && params.data) {
            return (params.data.is_insured && params.data.is_insured == 1)
              ? '<span class="label label-success" style="margin-top: 4px; position: absolute;">active</span>'
              : '<span class="label label-danger" style="margin-top: 4px; position: absolute;">inactive</span>';
          }
        },
        cellClassRules: cellClassRules
      },
    ];
  }

  changeDataGrid(event) {
    event.data.forEach(element => {
      if (element) {
        element.rate = element.pay_rate !== null ? element.pay_rate : element.rate;
      }
    });
    this.gridDataFile.api.setRowData(event.data);
    this.listCurrent = event.data.length;
    this.changeTitleCountGrid();
  }

  changeTitleCountGrid() {
    const cant = this.gridDataFile.api.getDisplayedRowCount();
    this.titleCountGrid = `Employees ${cant} of ${this.listCurrent}`;
  }
}
