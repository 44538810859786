import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { DriverService, TSODriver, TSODriverService } from '../../services/services.index';
import { TsoService } from '../../services/Tso.service';

@Component({
  selector: 'app-driver-management-routes',
  templateUrl: './driver-management-routes.component.html',
  styleUrls: ['./driver-management-routes.component.scss']
})
export class DriverManagementRoutesComponent implements OnInit, OnDestroy {
  driver_profiles: any;
  busy: Subscription;

  @Input() driver_id: number;

  constructor(
    private _serviceDriver: DriverService,
    private _serviceTSODriver: TSODriverService,
    private _serviceTSOService: TsoService,
  ) {
    this.driver_profiles = [];
  }

  ngOnInit() {
    this.loadInfo();
  }

  loadInfo() {
    this.busy = this._serviceDriver
      .getDriver(this.driver_id)
      .subscribe(data => {
        this.driver_profiles = data.driver_profiles;
      });
  }

  saveRoutes() {
    const driver_profiles = Object.assign([], this.driver_profiles);
    const routes = [];
    driver_profiles.forEach(element => {
      const route_id = element.route_id ? element.route_id : element.id;
      routes.push({ route_id: route_id});
    });
    this.busy = this._serviceDriver.updateRoutesDriver({ driver_profiles: routes}, this.driver_id).subscribe((data: any) => {
      this.driver_profiles = {};
      if (data.transaction) {
        this.driver_profiles = [];
        Swal.fire(
          'Success!',
          data.message,
          'success'
        );
      } else {
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: data.message + '.<br> Log : ' + data.master.result
        });
        this.driver_profiles = [];
      }
    }, err => {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: err.message
      });
    });
  }

  ngOnDestroy() { }

}
