import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CONSTANTS } from '../app.constants';
import {TsoResponse} from './Tso.service';


@Injectable()
export class TsoAssignVehicleService {

  protected resource: string;

  constructor(private http: HttpClient) {
    this.resource = environment.url_api_v + CONSTANTS.TSO_ASSIGN_VEHICLE;
  }

  tsoAssignVehicle(data: any): Observable<any> {
    return this.http.post<any>(environment.url_api_v + environment.tso.assignVehicle, JSON.stringify(data));
  }

  getAssignVehicle(schedule_detail_id: number): Observable<any> {
    return this.http.get<any>(this.resource + '/' + schedule_detail_id);
  }

  unassignVehicle(scheduleID: number, schedule_detail_id: number): Observable<any> {
    return this.http.get<any>(environment.url_api_v + environment.tso.unassignSchedule + '/' + scheduleID + '/' + schedule_detail_id);
  }

  verificationUnit(unitID: number, routeID: number) {
    return this.http.get(environment.url_api_v + CONSTANTS.VERIFICATION_UNIT + unitID + '/' + routeID);
  }

  getRouteFixedSchedules(routeID): Observable<TsoResponse> {
    return this.http.get<TsoResponse>(environment.url_api_v + environment.tso.routeFixedSchedules + '/' + routeID);
  }

}
