import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { BsModalComponent } from 'ng2-bs3-modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SupportService } from './../../../services/support.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact-support',
  templateUrl: 'contact-support.component.html'
})

export class ContactSupportComponent implements OnInit {
  @ViewChild('importFile') importFile: ElementRef;
  @ViewChild('modal') modal: BsModalComponent;

  @Input() set in(ins) {
    if (ins > 0) {
      this.modal.open();
      this.createForm();
    }
  }
  @Input() screenShot: string;

  formData: FormGroup;
  screenB64: string;
  busy: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private supportService: SupportService,
  ) {
    this.createForm();
  }

  ngOnInit() { }

  async startCapture() {
    this.screenB64 = this.screenShot;
    this.formData.get('screen_shot').setValue(this.screenShot);
  }

  createForm() {
    this.screenB64 = null;
    this.formData = this.fb.group({
      priority: ['', Validators.required],
      description: ['', Validators.required],
      import_file: [''],
      type: [''],
      screen_shot: [''],
    });
  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const exten = imageInput.files[0].name.split('.');
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.formData.get('import_file').setValue(event.target.result ? event.target.result : '');
      this.formData.get('type').setValue(exten[exten.length - 1]);
    });
    reader.readAsDataURL(file);
  }

  onClose() {
    this.modal.close();
    this.importFile.nativeElement.value = '';
  }

  sendEmail() {
    const dataForm = Object.assign({}, this.formData.getRawValue());
    window.location.href = 'mailto:helpdesk@taschedule.com?subject=Support%20to%20TA%20SCHEDULE&body=' + dataForm.description;
    this.onClose();
  }
  save() {
    const dataForm = Object.assign({}, this.formData.getRawValue());
    this.busy.push(this.supportService.sendSupport(dataForm).subscribe(res => {
      Swal.fire('Success', res.data, 'success');
      this.onClose();
    }, err => {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: JSON.stringify(err),
      });
    }));
  }
}
