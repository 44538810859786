import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AdminDataService } from '../driver.data.service';
import { Subscription } from 'rxjs';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-add-stops',
  templateUrl: './add-stops.component.html',
  styleUrls: ['./add-stops.component.scss']
})
export class AddStopsComponent implements OnInit {

  @Input() driver_manifest_id?: any;
  @Input() manifest_billed?: any;
  @Output() onReloadData?= new EventEmitter<any>();
  @ViewChild('formRef') myForm;
  busy: Subscription[] = [];
  formData: FormGroup;
  time_format = [/[0-9]/, /\d/, ':', /\d/, /\d/];
  totals = {
    total_amb: 0,
    total_wc: 0,
    total_bk: 0,
  };

  constructor(
    private fb: FormBuilder,
    private adminDataService: AdminDataService,
  ) {
    this.createForm();
  }
  ngOnInit() {
    // this.formData.get('stops').valueChanges.subscribe(val => {

    // });
    this.loadData();
  }

  validChanges() {
    const val = Object.assign([], this.formData.getRawValue().stops);
    if (this.formData.valid) {
      const totals = {
        total_amb: 0,
        total_wc: 0,
        total_bk: 0,
      };
      for (let i = 0; i < val.length; i++) {
        const element = val[i];
        const date = moment.utc(`${element.date} ${element.hour}:00`, 'YYYY-MM-DD  HH:mm:ss');

        if (i > 0) {
          const date_ant = moment.utc(`${val[i - 1].date} ${val[i - 1].hour}:00`, 'YYYY-MM-DD  HH:mm:ss');
          const isBefore = date.isBefore(date_ant);
          if (date.isValid() && date_ant.isValid() && isBefore) {
            const stops = Object.assign([], this.stops);
            const stopA: FormControl = Object.assign({}, stops[i]);
            element.date = null;
            element.hour = null;
            this.stops.controls[i].patchValue(element);
          }
        }

        totals.total_amb += Number(element.amb);
        totals.total_wc += Number(element.wc);
        totals.total_bk += Number(element.bk);
      }
      this.totals = totals;
    }
  }

  async loadData() {
    this.busy.push(
      this.adminDataService.getStops(this.driver_manifest_id).subscribe((res: any) => {
        for (let i = 0; i < res.data.length; i++) {
          const element = res.data[i];
          this.stops.push(
            this.fb.group({
              stop: [element.stop, Validators.required],
              date: [element.created_at.split(' ')[0], Validators.required],
              hour: [element.created_at.split(' ')[1].substring(0, 5), Validators.required],
              amb: [element.amb, Validators.required],
              wc: [element.wc, Validators.required],
              bk: [element.str, Validators.required],
            })
          );
        }
        this.validChanges();
      })
    );
  }

  createForm() {
    this.totals = {
      total_amb: 0,
      total_wc: 0,
      total_bk: 0,
    };
    this.formData = this.fb.group({
      stops: this.fb.array([]),
    });
  }

  get stops(): FormArray {
    return this.formData.get('stops') as FormArray;
  }
  onAdd() {
    const num = this.stops.length + 1;
    this.stops.push(
      this.fb.group({
        stop: [num, Validators.required],
        date: [num > 1 ? this.stops.value[num - 2].date : moment().local().format('YYYY-MM-DD'), Validators.required],
        hour: [num > 1 ? this.stops.value[num - 2].hour : '00:00', Validators.required],
        amb: [0, Validators.required],
        wc: [0, Validators.required],
        bk: [0, Validators.required],
      })
    );
  }

  onSave() {
    const dataForm = Object.assign({}, this.formData.getRawValue());
    const stops = dataForm.stops.map(r => {
      return {
        stop: r.stop,
        amb: r.amb,
        wc: r.wc,
        str: r.bk,
        created_at: `${r.date} ${r.hour}:00.000`,
        latitude: 0,
        longitude: 0,
      };
    });
    const payload = {
      driver_manifest_id: this.driver_manifest_id,
      stops,
    };
    this.busy.push(
      this.adminDataService.saveStops(payload).subscribe(res => {
        // this.loadData();
        this.onReloadData.emit();
      })
    );
  }
}
