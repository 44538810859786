import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SupportService {

  resource: string;

  constructor(
    private http: HttpClient,
  ) {
    this.resource = environment.url_api_v;
  }


  sendSupport(data: any): Observable<any> {
    return this.http.post(`${this.resource}support/contact`, data);
  }

}
