import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CONSTANTS } from '../../app.constants';
import { BaseService } from '../../core/BaseService';

@Injectable()
export class InspectionsService extends BaseService {
  protected resource: string;

  constructor(private http: HttpClient) {
    super();
    this.resource = environment.url_api_v + CONSTANTS.INSPECTION_VEHICLES;
  }

  /**
   * @description Return all clients
   * @returns {Observable<any>}
   */
  getInspections(): Observable<any> {
    return this.http.get<any>(this.resource).pipe(catchError(this.handleError));
  }

  /**
   * @description Save a client
   * @param data
   * @returns {Observable<Response>}
   */
  storeInspection(data: any): Observable<Response> {
    return this.http.post<Response>(this.resource, JSON.stringify(data));
  }

  filterInspection(data: any): Observable<Response> {
    return this.http.post<Response>(this.resource + '/filter', JSON.stringify(data));
  }

  /**
   * @description Update a Client
   * @param data
   * @returns {Observable<Response>}
   */
  updateInspection(data: any, id: number): Observable<Response> {
    return this.http.put<Response>(
      this.resource + '/' + id,
      JSON.stringify(data)
    );
  }

  /**
   * @description Get a client
   * @param {number} id
   * @returns {Observable<any>}
   */
  getInspection(id: number): Observable<any> {
    return this.http.get(this.resource + '/' + id);
  }

  /**
   * @description
   * @param {boolean} status
   * @returns {Observable<any>}
   */
  getInspectionsStatus(status: boolean): Observable<any> {
    if (status) {
      return this.http.get(this.resource);
    } else {
      return this.http.get(this.resource + '/active/1');
    }
  }

  /**
   * @description
   */
  changeInspectionStatus(data: any, id: number): Observable<Response> {
    return this.http.put<Response>(
      environment.url_api_v + 'clients/active/' + id,
      JSON.stringify(data)
    );
  }

  openInspectionReport(id: number) {
    window.open( 'http://127.0.0.1:8000/VehicleInspections/report/' + id);
  }

}
