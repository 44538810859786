import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AdminDataService } from '../driver.data.service';
import { Subscription } from 'rxjs';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { GridOptions } from 'ag-grid-community';
import { DatePipe } from '@angular/common';
import { DashboardLsfDataService } from './../../../../../pages/dashboard-lsf/dashboard-lsf.data.service';

@Component({
  selector: 'app-interruptions-list',
  templateUrl: './interruptions-list.component.html',
  styleUrls: ['./interruptions-list.component.scss']
})
export class InterruptionsListComponent implements OnInit {
  @Input() scheduleId;
  public gridInterruptions: GridOptions;
  public busy: Subscription[] = [];
  private endpointInterruptionService = 'servicesInterruptions';

  constructor(
    private _dataService: DashboardLsfDataService,
  ) {

    this.gridInterruptions = <GridOptions>{
    };

    this.gridInterruptions.columnDefs = [
      { headerName: 'Id', field: 'id', width: 100, sort: 'desc', hide: true },
      { headerName: 'Status', valueGetter: this.getBlockStatu, width: 100 },
      { headerName: 'Time', field: 'time', valueGetter: this.getBlockTime, width: 132 },
      { headerName: 'Details/Resolution', field: 'description', valueGetter: this.getDescription, width: 140 },
      { headerName: 'Billing Category', field: 'billing_category.description', width: 140 },
      { headerName: 'Veh', field: 'veh', valueGetter: this.getVehicle, width: 70 },
      { headerName: 'Odometer', field: 'odometer', width: 100 },
      { headerName: 'Driver', field: 'driver', valueGetter: this.getDriver, width: 130 },
      { headerName: 'Supervisor', field: 'supervisor', valueGetter: this.getSupervisor, width: 130 },
    ];
  }
  ngOnInit() {
    this.busy.push(this._dataService.
      getData(this.endpointInterruptionService + '/scheduledetails/' + this.scheduleId).subscribe(
      (data: any) => {
          if (data !== null) {
            if (this.gridInterruptions.api) {
              this.gridInterruptions.api.setRowData(data);
            }
          }
        }
      )
    );
  }

  getDriver(row) {
    if (row.data.driver !== null) {
      return `${row.data.driver.first_name}  ${row.data.driver.last_name}`;
    } else {
      return '';
    }
  }
  getSupervisor(row) {
    if (row.data.supervisor !== null) {
      return `${row.data.supervisor.first_name}  ${row.data.supervisor.last_name}`;
    } else {
      return '';
    }
  }
  getVehicle(row) {
    if (row.data.vehicle !== null) {
      return `${row.data.vehicle.description}`;
    } else {
      return '';
    }
  }
  getDescription(row) {
    if (row.data.incident !== null) {
      return `${row.data.incident.description} - ${row.data.details}`;
    } else {
      return row.data.details;
    }
  }
  getBlockTime(row) {
    const dateNow = new Date();
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(row.data.dateofservice, 'yyyy-MM-dd HH:mm:ss');
  }
  getBlockStatu(row) {
    if (row.data.blockstatu !== null) {
      return `${row.data.blockstatu.description}`;
    } else {
      return '';
    }
  }



}
