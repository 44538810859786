import { throwError as observableThrowError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

export abstract class BaseService {
  /**
   * @description This method handles errors in HTTP requests
   * @param error
   */
  protected handleError(error: HttpErrorResponse | any) {
    // if (error.status === 401) {
    //   alert('Authentication error, return to login');
    //   localStorage.removeItem('userInformation');
    //   localStorage.removeItem('currentUser');
    //   localStorage.removeItem('token');
    //   localStorage.removeItem('lastLogin');
    //   localStorage.removeItem('namedb');
    //   localStorage.removeItem('companies');
    //   location.reload();
    // }
    return observableThrowError(error.message);
  }
}
