import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class UnsubscribeService {

  constructor(private http: HttpClient) { }

  putUnsubscribe(data: { email: string }) {
    return this.http.put(`${environment.url_api}v1/users/unsubscribe`, data);
  }

}
