import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgBusyModule } from 'ng-busy';
import { BsModalModule } from 'ng2-bs3-modal';
import { AuthService } from '../services/auth.service';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { LoginComponent } from './login.component';
import { LoginDataService } from './login.data.service';
import { routing } from './login.routing';

@NgModule({
  imports: [
    CommonModule,
    routing,
    FormsModule,
    ReactiveFormsModule,
    NgBusyModule,
    BsModalModule
  ],
  declarations: [
    LoginComponent,
    ResetPasswordComponent
  ],
  providers: [
    AuthService,
    LoginDataService
  ],
  exports: []
})

export class LoginModule {
}
