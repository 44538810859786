import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RouteGroupService } from '../../../../../services/fleet/route-group.service';
import Swal from 'sweetalert2';
import { ClientService } from '../../../../../services/fleet/client.service';
import { RouterBlockService } from '../../../../../services/fleet/router-block.service';

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss']
})

export class CreateGroupComponent implements OnInit, OnChanges, OnDestroy {

  @Input() listRouteGroup: any[] = [];
  @Input() listRoutes: any[] = [];
  @Input() gourpSelected: any = {};
  @Output() onCloseModal = new EventEmitter<any>();

  listData: any[] = [];
  listClients: any = [];
  grid: GridOptions;
  busy: Subscription[] = [];
  form: FormGroup;

  constructor(private fb: FormBuilder,
              private routeGroupService: RouteGroupService, private clientService: ClientService, private routerBlockService: RouterBlockService) {
    this.onInitGrid();
    this.onInitForm();

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['gourpSelected'] && this.gourpSelected.id) {
        this.setValidatorForm('route', null);
        this.onLoadGridRoutes();
      this.form.patchValue({
        description: this.gourpSelected.description,
        sheet_name: this.gourpSelected.sheet_name,
        adjustment: this.gourpSelected.adjustment,
        active: this.gourpSelected.active,
      });
      setTimeout(() => {
        this.processData();
      }, 800);
    }else{
      this.setValidatorForm('route', Validators.required)
      this.onLoadGridRoutes();
    }
  }

  ngOnDestroy(): void {
    this.busy.forEach(subscription => subscription.unsubscribe());
  }

  onChangeClient(client_id: number) {
    this.busy.push(this.routerBlockService.listactiveFilteredByClient(1,client_id).subscribe((resp: any) => {
      if (resp) {
        this.listRoutes = resp;
      }
    }));
  }

  onClientsRoutes() {
    this.clientService.getClientsByDriverManifest().subscribe((resp) => {
      if(resp) {
        this.listClients = resp.data;
      }
    });
  }

  ngOnInit() {
    this.onClientsRoutes();
  }

  onInitGrid() {
    this.grid = <GridOptions> {
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      rowSelection: 'single',
      enableFilter: true,
      onGridReady: () => {
        this.grid.api.setRowData([]);
      },
      columnDefs: [
        { headerName: 'Description', field: 'description', width: 390 },
        { headerName: '# Block', width: 100,
          cellStyle: { textAlign: 'center'},
          valueGetter: (params) => {
          return params.data.route_blocks.length;
          }},
        { headerName: 'Delete', colId: 'trash', width: 70,
          cellStyle: { textAlign: 'center'},
          cellRenderer: (params) => {
            return `<i class="fa fa-trash-o" aria-hidden="true"></i>`;
          }
        }
      ]
    };
  }

  onInitForm() {
    this.form = this.fb.group({
      description: ['', Validators.required],
      client_id: [''],
      routes: [''],
      route: [''],
      sheet_name:[''],
      adjustment: [''],
      active: [true]
    });
  }

  onLoadGridRoutes() {
    const route = this.listRoutes.filter(item => item.id === Number(this.form.controls['route'].value)).shift();
    if (route) {
      this.listData.push({
        id: route.id,
        description: route.description,
        route_blocks: route.route_blocks
      });
    }
    if (this.grid.api) {
      this.grid.api.setRowData(this.listData);
    }
    if (route) {
      this.onDeleteRoute(route.id);
    }
    this.form.patchValue({
      route: ''
    });
  }

  onAdd() {
    const route = this.listRoutes.filter(item => item.id === Number(this.form.controls['route'].value)).shift();
    if (route) {
    this.routeGroupService.searchRouteIsAssigned(route.id).subscribe((resp: any) => {
        if (resp.status === 'success'){
          if (resp.data.length > 0){
            Swal.fire({
              position: 'center',
              type: 'warning',
              title: 'Sorry, this route is already assigned to another route group.',
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              //
            });
          }else{
            this.listData.push({
              id: route.id,
              description: route.description,
              route_blocks: route.route_blocks
            });
            if (this.grid.api) {
              this.grid.api.setRowData(this.listData);
              if (!this.listData.length) {
                this.setValidatorForm('route', Validators.required)
              } else {
                this.setValidatorForm('route', null)
              }
            }
            if (route) {
              this.onDeleteRoute(route.id);
            }
            this.form.patchValue({
              route: ''
            });
          }
        }
      },
      resp => {
        Swal.fire({
          position: 'top-end',
          type: 'error',
          title: 'Error:',
          footer: resp.message,
          showConfirmButton: false
        });
      })
    }
  }

  setValidatorForm(property: string, validator) {
      this.form.get(property).setValidators(validator);
      this.form.get(property).updateValueAndValidity();
  }

  onDeleteRoute(id: any) {
    this.listRoutes = this.listRoutes.filter(item => item.id !== Number(id));
  }

  onDeleteRouteAdd(id: any) {
    this.listData = this.listData.filter(item => item.id !== Number(id));
    this.grid.api.setRowData(this.listData);
  }

  onCellClicked(event: any) {
    if (event.colDef.colId === 'trash' && event.data.id) {
      const itemDelete = this.listData.filter(item => item.id === Number(event.data.id)).shift();
      this.listRoutes.push(itemDelete);
      this.onDeleteRouteAdd(event.data.id);
      if (!this.listData.length) {
        this.setValidatorForm('route', Validators.required)
        this.onLoadGridRoutes();
      } else {
        this.setValidatorForm('route', null);
        this.onLoadGridRoutes();
      }
    }
  }

  onSave() {
    const select = this.listData.map(item => item.id);
    if (select.length) {
      this.form.patchValue({
        'routes': select
      });
      if (this.gourpSelected.id) {
        this.busy.push(this.routeGroupService.update(this.form.value, this.gourpSelected.id).subscribe((resp: any) => {
          if (resp) {
            this.processReponse(resp);
            this.routeGroupService.reloadListRouteGroup.next(true);
          }
        }));
      } else {
        this.busy.push(this.routeGroupService.store(this.form.value).subscribe((resp: any) => {
          if (resp) {
            this.processReponse(resp);
            this.routeGroupService.reloadListRouteGroup.next(true);
          }
        }));
      }
    }
  }

  processData() {
    this.listData = [];
    this.grid.api.setRowData([]);
    this.gourpSelected.routes.forEach((item: any) => {
      this.form.patchValue({
        route: item
      });
      this.onLoadGridRoutes();
    });
  }

  processReponse(resp: any) {
    if (resp.status === 'success') {
      Swal.fire(
        'Success!',
        'Records saved.',
        'success'
      ).then(() => this.routeGroupService.openModal.next(false));
    } else {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: JSON.stringify(resp)
      });
    }
  }

  get isActive(): boolean {
    return !!this.gourpSelected.id;
  }

  get title() {
    return this.gourpSelected.id ? `Edita - ${this.gourpSelected.description}` : `New routes group`;
  }

  get btnAction() {
    return this.gourpSelected.id ? 'Update' : 'Save';
  }

  onClose() {
    this.routeGroupService.openModal.next(false);
    this.gourpSelected = {};
    this.form.reset();
    this.onCloseModal.emit(false);
  }

  get disabledSelect() {
    return !!this.form.controls['route'].value;
  }

  get disabledForm(): boolean {
    return !this.listData.length || this.form.invalid;
  }

  get formValid(): string {
    return !this.listData.length ? 'btn-danger' : 'btn-primary';
  }
}
