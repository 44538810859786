import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '../../../../user.model';
import { UserService } from '../../../../services/services.index';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Subscription } from 'rxjs';

declare let $: any;

@Component({
  selector: 'app-change-picture',
  templateUrl: './change-picture.component.html',
  styleUrls: ['./change-picture.component.scss'],
  providers: [UserService]
})
export class ChangePictureComponent implements OnInit {
  @Input() infoUser: User;
  @Output() onClose = new EventEmitter<Number>();
  @Output() changeImg = new EventEmitter<Number>();

  busy: Subscription[] = [];

  imageChangedEvent: any = '';
  croppedImage: any = '';
  scale = 1;
  canvasRotation = 0;
  rotation = 0;
  transform: any = {};

  constructor(
    private _serviceUser: UserService
  ) { }

  ngOnInit() { }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
      const flippedH = this.transform.flipH;
      const flippedV = this.transform.flipV;
      this.transform = {
          ...this.transform,
          flipH: flippedV,
          flipV: flippedH
      };
  }

  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  close() {
    this.onClose.emit(3);
  }
  onChoose() {
    this.busy.push(
      this._serviceUser.updateAvatar({
        file: this.croppedImage,
        type: this.imageChangedEvent.target.value.split('.').pop(),
      }).subscribe((res: any) => {
        console.log(res);
        const user = JSON.parse(localStorage.getItem('currentUser'));
        user.avatar = res.data;
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.changeImg.emit(res.data);
        this.close();
      })
    );
  }
}
