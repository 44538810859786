import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgBusyModule } from 'ng-busy';
import { BsModalModule } from 'ng2-bs3-modal';
import { HomeComponent } from './home/home.component';
import { LayoutComponent } from './layout/layout.component';
import { LayoutDataService } from './layout/layout.data.service';
import { MenusComponent } from './menus/menus.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SharedRoutingModule } from './shared-routing.module';
import { NoauthComponent } from './noauth/noauth.component';
import { SafePipePipe } from '../helpers/pipes/safe-pipe.pipe';
import { VerifyPasswordModule } from './layout/components/verify-password/verify-password.module';
import { SocialSecurityNumberPipe } from '../helpers/pipes/social-security-number.pipe';
import { ComponentsModule } from '../components/components.module';

@NgModule({
  imports: [
    CommonModule,
    NgBusyModule,
    FormsModule,
    SharedRoutingModule,
    ReactiveFormsModule,
    BsModalModule,
    VerifyPasswordModule,
    ComponentsModule
  ],
  declarations: [
    LayoutComponent,
    HomeComponent,
    NoauthComponent,
    MenusComponent,
    ResetPasswordComponent,
    SafePipePipe,
    SocialSecurityNumberPipe
  ],
  providers: [
    LayoutDataService
  ],
  exports: [
    SafePipePipe,
    SocialSecurityNumberPipe
  ]
})
export class SharedModule {
}
