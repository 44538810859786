import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CONSTANT } from '../../app.constants';

@Injectable()
export class UserTypeService {

  constructor(private http: HttpClient) {
  }

  getUserType(): Observable<any> {
    return this.http.get<any>(CONSTANT.USER_TYE.URL.BASE);
  }

  getUserTypeStatus(active) {
    return this.http.get<any>(CONSTANT.USER_TYE.URL.USER_TYPE_STATUS + active);
  }

  addUserType(data: any) {
    return this.http.post<any>(CONSTANT.USER_TYE.URL.BASE, data);
  }

  editUserType(data: any) {
    return this.http.put<any>(`${CONSTANT.USER_TYE.URL.BASE}/${data.id}`, data);
  }

}
