import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { VehicleService } from '../../../../../services/fleet/vehicle.service';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DriverManifestService } from '../../../../../services/manifest/driver-manifest.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-select-vehicle',
  templateUrl: './select-vehicle.component.html',
  styleUrls: ['./select-vehicle.component.scss']
})
export class SelectVehicleComponent implements OnInit, OnChanges {

  form: FormGroup;
  vehicle_manifest_id = null;
  vehicle_select_id: number;
  skipOdometerDiff = false;
  is_execute = false;
  @Input() driver_manifest_id?: any[];
  @Input() vehicle_list ?: any[];
  @Input() set vehicle_id(id) {
    this.vehicle_manifest_id = id;
    if (id) {
      this.vehicleService.getVehicleManifest(id).subscribe((res: any) => {
        if (res) {
          this.vehicle_select_id = res.data.vehicle ? res.data.vehicle.id : 0;
          this.form.setValue({
            vehicle: res.data.vehicle ? res.data.vehicle.description : '',
            leave_yard_odometer: res.data.leave_yard_odometer,
            first_stop_odometer: res.data.first_stop_odometer,
            last_stop_odometer: res.data.last_stop_odometer,
            return_to_yard_odometer: res.data.return_to_yard_odometer,
            not_revenue_miles: res.data.not_revenue_miles,
            revenue_miles: res.data.revenue_miles,
            last: res.data.last
          });
        }
      });
    }
  }
  @Output() onSelectVehicle?= new EventEmitter<any>();
  vehicles: any = [];

  public busy: Subscription;
  constructor(
    private fb: FormBuilder,
    private vehicleService: VehicleService,
    private domSanitizer: DomSanitizer,
    private toastr: ToastrService,
    private driverManifestService: DriverManifestService
  ) {
    this.initForm();
  }

  ngOnInit() {
    this.loadVehicles();
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  initForm() {
    this.form = this.fb.group({
      vehicle: ['', Validators.required],
      leave_yard_odometer: ['', [ Validators.required, Validators.min(1)]],
      first_stop_odometer: ['', [ Validators.required, Validators.min(1)]],
      last_stop_odometer: ['', [ Validators.required, Validators.min(1)]],
      return_to_yard_odometer: ['', [ Validators.required, Validators.min(1)]],
      not_revenue_miles: ['', [ Validators.required, Validators.min(1)]],
      revenue_miles: ['', [ Validators.required, Validators.min(1)]],
      last: ['']
    });
  }

  async calcMiles() {
    const data = Object.assign({}, this.form.getRawValue());
    const leave_yard_odometer = data.leave_yard_odometer ? data.leave_yard_odometer : 0;
    const first_stop_odometer = data.first_stop_odometer ? data.first_stop_odometer : 0;
    const last_stop_odometer = data.last_stop_odometer ? data.last_stop_odometer : 0;
    const return_to_yard_odometer = data.return_to_yard_odometer ? data.return_to_yard_odometer : 0;
    const nrev = (data.first_stop_odometer - data.leave_yard_odometer) + (data.return_to_yard_odometer - data.last_stop_odometer);
    const rev = data.last_stop_odometer - data.first_stop_odometer;
    this.form.get('not_revenue_miles').setValue(nrev > 0 ? nrev : 0);
    this.form.get('revenue_miles').setValue(rev > 0 ? rev : 0);

    if (first_stop_odometer < leave_yard_odometer && (first_stop_odometer > 0 && leave_yard_odometer > 0)) {
      this.toastr.warning('The first Stop odometer must be greater than leave yard odometer');
      await this.form.get('first_stop_odometer').setValue(leave_yard_odometer);
      this.calcMiles();
    }
    if (last_stop_odometer < first_stop_odometer && (last_stop_odometer > 0 && first_stop_odometer > 0)) {
      this.toastr.warning('The last stop odometer must be greater than first stop odometer');
      await this.form.get('last_stop_odometer').setValue(first_stop_odometer);
      this.calcMiles();
    }
    if (return_to_yard_odometer < last_stop_odometer && (return_to_yard_odometer > 0 && last_stop_odometer > 0)) {
      this.toastr.warning('The return yard odometer must be greater than last stop odometer');
      await this.form.get('return_to_yard_odometer').setValue(last_stop_odometer);
      this.calcMiles();
    }
  }

  loadVehicles() {
    this.busy = this.vehicleService.getVehiclesStatus(false).subscribe(
      (data: any) => {
        this.vehicle_list.forEach((item: any, index) => {
            data.forEach((row: any, index2) => {
              if (Number(item.vehicle_id) === Number(row.id) && item.active) {
                if (Number(item.vehicle_id) !== this.vehicle_select_id) {
                  data.splice(index2, 1);
                }
              }
            });
          });
        this.vehicles = data;
      });
  }

  myValueFormatter(data: any): string {
    const html = data.description;
    return html;
  }

  autocompleListFormatter = (data: any) => {
    const html = `<span>${data.description} </span>`;
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }


  valueChangedDriver(event: any) {
    if (event && event.id && event.description) {
      this.vehicle_select_id = event.id;
      this.form.controls['vehicle'].setValue(event.description);
    }
  }

  onSave () {
    if (!this.is_execute) {
      this.is_execute = true;
      this.save();
    }
  }

  save() {
    const data = Object.assign({}, this.form.getRawValue());
    if (!this.vehicle_manifest_id) {
      this.busy = this.vehicleService.saveVehicleManifest({
        leave_yard_odometer: data.leave_yard_odometer,
        first_stop_odometer: data.first_stop_odometer,
        last_stop_odometer: data.last_stop_odometer,
        return_to_yard_odometer: data.return_to_yard_odometer,
        not_revenue_miles: data.not_revenue_miles,
        revenue_miles: data.revenue_miles,
        driver_manifest_id: this.driver_manifest_id,
        vehicle_id: this.vehicle_select_id,
        last: data.last
      }).subscribe((res: any) => {
        this.onSelectVehicle.emit(res);
        this.toastr.success('Vehicle succesfully saved');
        this.driverManifestService.load_details$.next(res);
        this.is_execute = false;
      }, err => {
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Please try again!'+err
        });
        this.is_execute = false;
      });
    } else {
      this.busy = this.vehicleService.updateVehicleManifest(this.vehicle_manifest_id, {
        leave_yard_odometer: data.leave_yard_odometer,
        first_stop_odometer: data.first_stop_odometer,
        last_stop_odometer: data.last_stop_odometer,
        return_to_yard_odometer: data.return_to_yard_odometer,
        not_revenue_miles: data.not_revenue_miles,
        revenue_miles: data.revenue_miles,
        driver_manifest_id: this.driver_manifest_id,
        vehicle_id: this.vehicle_select_id,
        active : 1,
        last: data.last
      }).subscribe(res => {
        this.onSelectVehicle.emit(res);
        this.toastr.success('Vehicle succesfully saved');
        this.driverManifestService.load_details$.next(res);
        this.is_execute = false;
      }, err => {
        Swal.fire({
          type: 'error',
          title: 'Error',
          text: 'Please try again!'+err
        });
        this.is_execute = false;
      });
    }
  }

  setDefaultOd() {
    if (this.skipOdometerDiff) {
      this.form.get('leave_yard_odometer').setValue('0');
      this.form.get('first_stop_odometer').setValue('0');
      this.form.get('last_stop_odometer').setValue('0');
      this.form.get('return_to_yard_odometer').setValue('0');
      this.form.get('not_revenue_miles').setValue('0');
      this.form.get('revenue_miles').setValue('0');
      this.form.get('leave_yard_odometer').disable();
      this.form.get('first_stop_odometer').disable();
      this.form.get('last_stop_odometer').disable();
      this.form.get('return_to_yard_odometer').disable();
      this.form.get('not_revenue_miles').disable();
      this.form.get('revenue_miles').disable();
      this.form.get('leave_yard_odometer').setValidators([ Validators.required, Validators.min(0)]);
      this.form.get('first_stop_odometer').setValidators([ Validators.required, Validators.min(0)]);
      this.form.get('last_stop_odometer').setValidators([ Validators.required, Validators.min(0)]);
      this.form.get('return_to_yard_odometer').setValidators([ Validators.required, Validators.min(0)]);
      this.form.get('not_revenue_miles').setValidators([ Validators.required, Validators.min(0)]);
      this.form.get('revenue_miles').setValidators([ Validators.required, Validators.min(0)]);
    } else {
      this.form.get('leave_yard_odometer').enable();
      this.form.get('first_stop_odometer').enable();
      this.form.get('last_stop_odometer').enable();
      this.form.get('return_to_yard_odometer').enable();
      this.form.get('not_revenue_miles').enable();
      this.form.get('revenue_miles').enable();
      this.form.get('leave_yard_odometer').setValidators([ Validators.required, Validators.min(1)]);
      this.form.get('first_stop_odometer').setValidators([ Validators.required, Validators.min(1)]);
      this.form.get('last_stop_odometer').setValidators([ Validators.required, Validators.min(1)]);
      this.form.get('return_to_yard_odometer').setValidators([ Validators.required, Validators.min(1)]);
      this.form.get('not_revenue_miles').setValidators([ Validators.required, Validators.min(1)]);
      this.form.get('revenue_miles').setValidators([ Validators.required, Validators.min(1)]);
    }
  }

}
