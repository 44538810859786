// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  production: false,
  envName: 'dev',
  url: 'https://devapi.taschedule.com/',
  url_api: 'https://devapi.taschedule.com/api/',
  url_api_v: 'https://devapi.taschedule.com/api/v1/',
  url_tracker_api: 'https://trackingapi.taschedule.com/',
  url_tracker_socket: 'https://trackingsocket.taschedule.com/',
  grant_type: 'password',
  client_id: '1',
  client_secret: 'NgRhUfrE4ee0rNjt9TGcEEwM3YuSA6tNXw6TvzAn',
  scope: '*',
  tso: {
    serve: 'https://sbapi.tsomobile.com/rest/',
    drivers_list: 'tso/driversList',
    units_list: 'tso/unitList',
    routes: 'tso/routes',
    getUserTSO: 'tso/getUserTSO',
    routeFixedSchedules: 'tso/routeFixedSchedules',
    validateUser: 'Authentication/ValidateUser',
    assignVehicle: 'tso/assignVehicle',
    unassignSchedule: 'tso/unassignSchedule',
    driver_create: 'Drivers/CreateDriver',
    driver_update: 'Drivers/UpdateDriver',
    driver_delete: 'Drivers/DeleteDriver',
  },
  appInsights: {
    instrumentationKey: '63bb874e-629f-47b9-965c-685031297a8f'
  },
  pusherBeams: '1be802a4-428e-43b7-a8fc-9426379739a2',
  trip2: {
    key: '11AAA785-CBF2-4FE9-8558-8F0BF54A092D',
    endPoint: 'https://apiprod.trip2.com/REST/Session/',
    origin: 'http://devtenant.trip2.com',
    tenant: 'tareport',
    endPointBase: 'https://apiprod.trip2.com/REST/'
  },
  map_apiKey: 'AIzaSyA9bjubP1Kb5FO3iFgMj6SahAGQ59PH-_U'
};
