import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouteGroupService } from '../../../services/fleet/route-group.service';
import { forkJoin, Subscription } from 'rxjs';
import { RouterBlockService } from '../../../services/fleet/router-block.service';

@Component({
  selector: 'app-route-group',
  templateUrl: './route-group.component.html',
  styleUrls: ['./route-group.component.scss']
})
export class RouteGroupComponent implements OnInit, OnDestroy {

  busy: Subscription[] = [];
  listRouteGroup: any[] = [];
  listRoutes: any[] = [];
  gourpSelected: any = {};
  showModal = false;

  constructor(private routeGroupService: RouteGroupService, private routerBlockService: RouterBlockService) { }

  ngOnInit() {
    this.routeGroupService.openModal.subscribe(resp => this.showModal = resp);
    this.loadData(true);
    this.suscribeObservers();
  }

  suscribeObservers() {
    this.routeGroupService.reloadListRouteGroup$.subscribe((resp: boolean) => {
        this.loadData(resp);
    })
  }

  loadData(status) {
    this.busy.push(forkJoin([
      this.routeGroupService.list(),
      this.routerBlockService.list(1)
    ]).subscribe((resp: any) => {
      this.listRouteGroup = resp[0].data;
      this.listRoutes = resp[1];
    }));
  }

  onRecordSelected(event: any) {
    this.busy.push(this.routeGroupService.get(event.id).subscribe((resp: any) => {
      if (resp && resp.data) {
        this.gourpSelected = resp.data;
        this.gourpSelected.id = event.id;
        this.routeGroupService.openModal.next(true);
      }
    }));
  }

  onCloseModal(event: any) {
    this.gourpSelected = {};
  }

  ngOnDestroy(): void {
    this.busy.forEach(subscription => subscription.unsubscribe());
  }

}
