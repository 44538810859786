import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface IApplyDates {
  month?: number;
  year?: number;
}

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PaginatorComponent),
      multi: true,
    }
  ]
})
export class PaginatorComponent implements OnInit, ControlValueAccessor {
  currentYear: number;
  date = new Date();
  month = this.date.getMonth() + 1;
  monthValue: string;
  applyDates = {};

  onChange = (_: IApplyDates) => {};
  onTouched = () => {};
  isDisabled!: boolean;

  constructor() {
    this.currentYear = new Date().getFullYear();
  }

  ngOnInit() {
    this.monthValue = this.getMonthName(this.month);
  }

  getMonthName(month: any) {
    switch (month) {
      case 1:
        return 'January';
      case 2:
        return 'February';
      case 3:
        return 'March';
      case 4:
        return 'April';
      case 5:
        return 'May';
      case 6:
        return 'June';
      case 7:
        return 'July';
      case 8:
        return 'August';
      case 9:
        return 'September';
      case 10:
        return 'October';
      case 11:
        return 'November';
      case 12:
        return 'December';
      default:
        return '';
    }
  }

  nextMonth() {
    if (this.month <= 12) {
      if (this.month == 12) {
        this.month = 1;
        this.currentYear++;
      } else {
        this.month++;
      }
      this.monthValue = this.getMonthName(this.month);
      this.applyDates = {
        month: this.month,
        year: this.currentYear,
      };
      this.onTouched();
      this.onChange(this.applyDates);
    }
  }

  lastMonth() {
    if (this.month == 1) {
      this.month = 12;
      this.currentYear--;
    } else {
      this.month--;
    }
    this.monthValue = this.getMonthName(this.month);
    this.applyDates = {
      month: this.month,
      year: this.currentYear,
    };
    this.onTouched();
    this.onChange(this.applyDates);
  }

  writeValue(obj: IApplyDates): void {
    if (obj !== null && obj !== undefined) {
      this.applyDates = {
        month: obj.month,
        year: obj.year,
      };
      this.onChange(this.applyDates);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
