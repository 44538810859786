import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { IMyDateRangeModel, IMyDrpOptions } from 'mydaterangepicker';
import { TabsetComponent } from 'ngx-bootstrap';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { AdminDataService } from '../../pages/manifests/components/driver-manifest/driver.data.service';
import { Client, Driver, DriverManifest, RouteBlocks, Vehicle } from '../../pages/manifests/components/driver-manifest/models';
import { DriverService, VehicleService } from '../../services/services.index';
import { WorkflowService } from '../../services/billing/workflow.service';
import { StatusBillingByWorkflow } from '../../CORE/enums/BilingStatusEnum.enum';

declare let $: any;

@Component({
  selector: 'app-manifest-manager',
  templateUrl: './manifest-manager.component.html',
  styleUrls: ['./manifest-manager.component.scss'],
  providers: [
    AdminDataService,
    DriverService,
    VehicleService
  ],
})
export class ManifestManagerComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('staticTabs') staticTabs: TabsetComponent;
  @Input() manifest_id?: number;
  @Output() onCloseModal? = new EventEmitter<any>();

  public readonly StatusRejected: StatusBillingByWorkflow = StatusBillingByWorkflow.Rejected;
  public readonly StatusVerified: StatusBillingByWorkflow = StatusBillingByWorkflow.Verified;
  public readonly StatusUnverified: StatusBillingByWorkflow = StatusBillingByWorkflow.Unverified;

  public selectUndefinedOptionValue: any;
  public lockClientInput = false;
  public busy: Subscription;

  public drivers: Array<Driver> = [];
  public routeBlocks: RouteBlocks;
  public vehicles: Vehicle;
  public clients: Client;

  public driverManifestEdit = 0;
  public driver_manifest: DriverManifest = new DriverManifest();

  public apiResponse: any;
  public currentDate: any;
  public maxDate = new Date();
  public count = 0;
  countGrid = 0;
  public driver_id: any = '';
  public client_id: any = '';
  public chState = true;
  public time_format = [/[0-9]/, /\d/, ':', /\d/, /\d/];
  columnDefs;
  public lock = true;
  public errorDate;
  errorGeneral: boolean;
  statusBillingByWorkflow: string;
  destroy: Subscription;

  /**
   * Variables para manejas el show de los errores en los campos Times de (Actual Time)
   * @type {boolean}
   */
  public leave_yard_time_boolen;
  first_stop_time_boolen;
  last_stop_time_boolen;
  return_to_yard_time_boolen: boolean;

  /**
   * Variables para majar el show de los errores en los campor Odometer
   * @type {boolean}
   */
  public leave_yard_time_odometer_boolen;
  first_stop_time_odometer_boolen;
  last_stop_time_odometer_boolen;
  return_to_yard_time_odometer_boolen: boolean;

  /**
   * @description Esta variable almacena el mensaje que se muestra en la propiedad Title del icono
   * que muestra el error.
   * @type {string}
   */
  public title_error = 'Unable to add records, please review and try again.';

  /**
   * @description Variables para manejar el show de los errores en los campos Check In y Check Out
   * @type {boolean}
   */
  public check_in_boolen;
  check_out_boolen: boolean;
  public isMDT = false;
  public route_blocks_select: any = 0;
  public odometer_validation_1;
  odometer_validation_2;
  odometer_validation_3: string;

  myDateRangePickerOptions: IMyDrpOptions = {
    dateFormat: 'yyyy/mm/dd',
    width: '230px',
    disableSince: {year: moment().local().year(), month: moment().local().month() + 1, day: moment().local().date() +1}
  };

  public rangeDate: any;
  driverManifestId: number;

  schDriver: any = {};
  editing = false;
  billed_edit = false;

  constructor(
    private  _dataService: AdminDataService,
    public toastr: ToastrService,
    private _sanitizer: DomSanitizer,
    private _serviceDriver: DriverService,
    private _serviceVehicle: VehicleService,
    private _workflow: WorkflowService,
    private ngxPermissionsService: NgxPermissionsService,
  ) {

    this.leave_yard_time_boolen = false;
    this.first_stop_time_boolen = false;
    this.last_stop_time_boolen = false;
    this.return_to_yard_time_boolen = false;

    this.leave_yard_time_odometer_boolen = false;
    this.first_stop_time_odometer_boolen = false;
    this.last_stop_time_odometer_boolen = false;
    this.return_to_yard_time_odometer_boolen = false;

    this.check_in_boolen = false;
    this.check_out_boolen = false;

    this.odometer_validation_1 = 'NaN';
    this.odometer_validation_2 = 'NaN';
    this.odometer_validation_3 = 'NaN';

    this.errorDate = false;
    this.errorGeneral = false;
  }

  ngOnInit() {
    this.loadDrivers();
    this.loadVehicles();
    this.loadClients();
    this.subscribeToStatusBillingByWorkflow();

    this.currentDate = moment().local().format('YYYY-MM-DD');
    this.driver_manifest.dateOfService = this.currentDate;
  }

  ngOnDestroy(): void {
    this._workflow.clearStatusBillingByWorkflow();
    this.destroy.unsubscribe();
  }


  ngAfterViewInit() {
    (<any>$('#side-menu')).metisMenu();
    (<any>$('#driver_id')).focus();
    (<any>$('#date_filter')).val(this.getFechaActualFormaDos());
    if (this.manifest_id) {
      this.loadDataOne(this.manifest_id);
      setTimeout(() => {
        this.editing = true;
      }, 800);
    }
  }

  private subscribeToStatusBillingByWorkflow(): void {
    this.destroy = this._workflow.statusBillingByWorkflowAction$.subscribe({
      next: (statusBillingByWorkflow: string) => this.statusBillingByWorkflow = statusBillingByWorkflow
    });
  }

  myValueFormatter(data: any): string {
    const html = data.last_name + ' ' + data.first_name;
    return html;
  }

  autocompleListFormatter = (data: any) => {
    const html = `<span>${data.first_name} ${data.last_name} </span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

  autocompleListFormatterClient = (data: any) => {
    const html = `<span>${data.client_name} </span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

  dataVeh(row) {
    return row.data.vehicle.description;
  }

  dataDriver(row) {
    return row.data.driver.last_name + ' ' + row.data.driver.first_name;
  }

  dataClient(row) {
    return row.data.client.client_name;
  }

  routeBlock(row) {
    if (row.data.client.id == 43 || row.data.client.client_name == 'MDT') {
      return row.data.route_block.route.description + ' - ' + row.data.route_block.description;
    } else {
      return row.data.route_block.block_code + ' - ' + row.data.route_block.description;
    }
  }

  onCellClicked($event) {
    // this.timeSekected = $event.data.id;
    this.driverManifestId = undefined;
  }

  OnCellDoubleClicked($event) {
    const id = $event.data.id;
    this.driverManifestId = id;
    this.selectTab(0);
    this.loadDataOne(id);
  }

  /**
   * @description Este metodo se encarga de cargar los RouteBlock de un determinado cliente,
   * recibe como parametro el id del Cliente.
   * @param client_id
   */
  loadRouteBlocks(client_id: any) {
    this.busy = this._dataService.getData('blocksByClient/' + client_id).subscribe(
      (data: any) => {
        this.routeBlocks = data;
      }
    );
  }

  /**
   * @description Este metodo saca del array de Blokes los que ya esten insertados en el sistema
   * @param client_id
   * @param dateOfService
   */
  getBlockByClientAndDateOfService(client_id: any, dateOfService: any) {
    var i = 0;
    this.busy = this._dataService.getData('getBlockByClientAndDateOfService/' + client_id + '/' + dateOfService).subscribe(
      (data: any) => {
        for (let t of data) {
          if (this.driver_manifest.route_block_id == t.route_block_id) {
            i = i + 1;
          }
        }
      }
    );
    alert(i);
    return i;
  }

  deleteElementArray(id: any, list: any) {
    list.forEach(function (data, index) {
      if (data.id == id) {
        list.splice(index, 1);
      }
    });
  }

  /**
   * @description Este metodo se encarga de consumir el servicio que retorna todos los Veiculos
   */
  loadVehicles() {
    this.busy = this._dataService.getData('vehicles/active/1').subscribe(
      (data: any) => {
        this.vehicles = data;
      }
    );
  }

  /**
   * @description Este metodo se encarga de consumir el servicio que retorna todos los clientes
   */
  loadClients() {
    this.busy = this._dataService.getData('clients/active/1').subscribe(
      (response: any) => {
        this.clients = response.data;
      }
    );
  }

  onOutOdometer($event) {
    let r;
    switch ($event.target.id) {
      case 'first_stop_odometer':
        // Validar que la diferencia entre el leave yard odometer y el first stop odometer no puede ser mayor que 100
        r = this.validatedRange(this.driver_manifest.first_stop_odometer, this.driver_manifest.leave_yard_odometer, 100);
        this.odometer_validation_1 = r.difference;
        if (!r.operation || (r.difference < 0)) {
          $('#odometer_validation_1').removeClass('label-default');
          $('#odometer_validation_1').addClass('label-danger');
        } else {
          $('#odometer_validation_1').removeClass('label-danger');
          $('#odometer_validation_1').addClass('label-default');
        }
        break;
      case 'last_stop_odometer':
        // Validar que la diferencia entre el first stop odometer y el last stop odometer no puede ser mas de 400.
        r = this.validatedRange(this.driver_manifest.last_stop_odometer, this.driver_manifest.first_stop_odometer, 400);
        this.odometer_validation_2 = r.difference;
        if (!r.operation || (r.difference < 0)) {
          $('#odometer_validation_2').removeClass('label-default');
          $('#odometer_validation_2').addClass('label-danger');
        } else {
          $('#odometer_validation_2').removeClass('label-danger');
          $('#odometer_validation_2').addClass('label-default');
        }
        break;
      case 'return_to_yard_odometer':
        // Validar que la diferencia entre el last stop odometer y el return to yard odometer no puede ser mayor a 100
        r = this.validatedRange(this.driver_manifest.return_to_yard_odometer, this.driver_manifest.last_stop_odometer, 100);
        this.odometer_validation_3 = r.difference;
        if (!r.operation || (r.difference < 0)) {
          $('#odometer_validation_3').removeClass('label-default');
          $('#odometer_validation_3').addClass('label-danger');
        } else {
          $('#odometer_validation_3').removeClass('label-danger');
          $('#odometer_validation_3').addClass('label-default');
        }
        break;
      default:
    }
  }

  onSave() {
    let fecha = this.driver_manifest.dateOfService;
    let fechaActual = this.getFechaActualFormaDos();

    if (this.getValidatedRange()) {
      if (fecha <= fechaActual) {
        let odValidate = this.verificarOdometer();
        let tiValidate = this.verificarTimer();

        if (!odValidate || tiValidate) {
          this.errorGeneral = true;
        }

        if (odValidate && tiValidate) {
          let clientTemp = this.driver_manifest.client;
          let id = this.driver_manifest.id;
          delete this.driver_manifest.id;
          delete this.driver_manifest.driver;
          delete this.driver_manifest.vehicle;
          delete this.driver_manifest.client;
          delete this.driver_manifest.route_block;
          delete this.driver_manifest.changed_blocks;

          if (this.driverManifestEdit == 0) {
            this.busy = this._dataService.postData(this.driver_manifest, 'driverManifest').subscribe(
              data => {
                this.apiResponse = data;
                if (this.apiResponse.transaction) {


                  let dateRegistro = this.driver_manifest.dateOfService;

                  this.driver_manifest = new DriverManifest();

                  if (!this.lock) {
                    this.driver_manifest.dateOfService = dateRegistro;
                  }

                  (<any>$('#driver_id')).focus();
                  this.toastr.success(this.apiResponse.message);
                  this.driverManifestEdit = 0;

                  this.driver_id = '';
                  if (!this.lockClientInput) {
                    this.client_id = '';
                    this.changeClient(0);

                  } else {
                    this.changeClient(this.client_id.id);
                    this.valueChangedClient(this.client_id);
                  }

                  this.odometer_validation_1 = 'NaN';
                  this.odometer_validation_2 = 'NaN';
                  this.odometer_validation_3 = 'NaN';
                  this.onCloseModal.emit(true);
                } else {
                  alert(this.apiResponse.message);
                  alert(this.apiResponse.master.result);
                  this.onCloseModal.emit(true);
                }
              }
            );
            this.errorGeneral = false;
          } else {
            let changed_blocks: boolean = false; // Si esta variable es true es porque cambiaron
            if (this.route_blocks_select == this.driver_manifest.route_block_id) {
              changed_blocks = false;
            } else {
              changed_blocks = true;
            }
            this.driver_manifest.changed_blocks = changed_blocks;

            this.busy = this._dataService.putData(this.driver_manifest, 'driverManifest/' + id).subscribe(
              data => {
                this.apiResponse = data;
                if (this.apiResponse.transaction) {

                  this.driver_manifest = new DriverManifest();
                  (<any>$('#driver_id')).focus();
                  this.toastr.success(this.apiResponse.message);
                  this.driverManifestEdit = 0;
                  this.changeClient(0);

                  this.driver_id = '';
                  this.client_id = '';
                  this.odometer_validation_1 = 'NaN';
                  this.odometer_validation_2 = 'NaN';
                  this.odometer_validation_3 = 'NaN';
                  this.onCloseModal.emit(true);
                } else if(!this.apiResponse.transaction && this.apiResponse.message === 'An error has ocurred, please check the log') {

                  this.driver_manifest = new DriverManifest();
                  (<any>$('#driver_id')).focus();
                  this.toastr.success(this.apiResponse.message);
                  this.driverManifestEdit = 0;
                  this.changeClient(0);

                  this.driver_id = '';
                  this.client_id = '';
                  this.odometer_validation_1 = 'NaN';
                  this.odometer_validation_2 = 'NaN';
                  this.odometer_validation_3 = 'NaN';
                  this.onCloseModal.emit(true);
                }else {
                  this.driver_manifest.id = id;
                  alert(this.apiResponse.message);
                  this.onCloseModal.emit(true);
                }
              }
            );
            this.errorGeneral = false;
          }
        }

        $('#date').css('color', 'black');
        this.errorDate = false;
      } else {
        $('#date').css('color', 'red');
        this.errorDate = true;
        this.errorGeneral = true;
      }
    } else {
      alert('please validate the values ​​of the Odometer');
    }
  }

  /**
   * @description Este metodo se encarga de validar los valores de los campor Times, si se precenta algun
   * error en en la secuencia de menor a mayor en los campor de times retornamos un valor boolena, true en caso
   * de que se precenter un error y false en caso contrario.
   * @returns {boolean}
   */
  verificarTimer() {
    const minimo = this.convertTimeToInt(this.driver_manifest.actual_check_in);
    const maximo = this.convertTimeToInt(this.driver_manifest.actual_drop_off);
    const num1 = this.convertTimeToInt(this.driver_manifest.leave_yard_time);
    const num2 = this.convertTimeToInt(this.driver_manifest.first_stop_time);
    const num3 = this.convertTimeToInt(this.driver_manifest.last_stop_time);
    const num4 = this.convertTimeToInt(this.driver_manifest.return_to_yard_time);

    if (maximo <= minimo) {
      this.check_out_boolen = true;
    } else {
      this.check_out_boolen = false;
    }

    if (num1 >= minimo && num1 < maximo) {
      this.leave_yard_time_boolen = false;
    } else {
      this.leave_yard_time_boolen = true;
    }

    if (num2 >= minimo && num2 < maximo) {
      if (num2 >= num1) {
        this.first_stop_time_boolen = false;
      } else {
        this.first_stop_time_boolen = true;
      }
    } else {
      this.first_stop_time_boolen = true;
    }

    if (num3 >= minimo && num3 < maximo) {
      if (num3 > num2) {
        this.last_stop_time_boolen = false;
      } else {
        this.last_stop_time_boolen = true;
      }
    } else {
      this.last_stop_time_boolen = true;
    }

    if (num4 >= minimo && num4 < maximo) {
      if (num4 >= num3) {
        this.return_to_yard_time_boolen = false;
      } else {
        this.return_to_yard_time_boolen = true;
      }
    } else {
      this.return_to_yard_time_boolen = true;
    }

    this.changeColorTime();

    if (
      this.leave_yard_time_boolen ||
      this.first_stop_time_boolen ||
      this.last_stop_time_boolen ||
      this.return_to_yard_time_boolen ||
      this.check_out_boolen
    ) {
      return false;
    } else {
      return true;
    }
  }

  /***
   * @description
   */
  changeColorTime() {
    if (this.leave_yard_time_boolen) {
      $('#leave_yard_time').css('color', 'red');
    } else {
      $('#leave_yard_time').css('color', 'black');
    }
    if (this.first_stop_time_boolen) {
      $('#first_stop_time').css('color', 'red');
    } else {
      $('#first_stop_time').css('color', 'black');
    }
    if (this.last_stop_time_boolen) {
      $('#last_stop_time').css('color', 'red');
    } else {
      $('#last_stop_time').css('color', 'black');
    }
    if (this.return_to_yard_time_boolen) {
      $('#return_to_yard_time').css('color', 'red');
    } else {
      $('#return_to_yard_time').css('color', 'black');
    }
    if (this.check_out_boolen) {
      $('#actual_check_out').css('color', 'red');
    } else {
      $('#actual_check_out').css('color', 'black');
    }
    if (this.leave_yard_time_odometer_boolen) {
      $('#leave_yard_odometer').css('color', 'red');
    } else {
      $('#leave_yard_odometer').css('color', 'black');
    }
    if (this.first_stop_time_odometer_boolen) {
      $('#first_stop_odometer').css('color', 'red');
    } else {
      $('#first_stop_odometer').css('color', 'black');
    }
    if (this.last_stop_time_odometer_boolen) {
      $('#last_stop_odometer').css('color', 'red');
    } else {
      $('#last_stop_odometer').css('color', 'black');
    }
    if (this.return_to_yard_time_odometer_boolen) {
      $('#return_to_yard_odometer').css('color', 'red');
    } else {
      $('#return_to_yard_odometer').css('color', 'black');
    }
  }

  /**
   * @description Este metodo se encarga de convertir un time en numero
   * @param {string} value
   * @returns {string}
   */
  convertTimeToInt(value: string) {
    const t = value.split(':');
    return t[0] + t[1];
  }

  /**
   * @description Este metodo se encarga de validar los valores de los campor Odometer, si se precenta algun
   * error en en la secuencia de menor a mayor en los campor de odometer retornamos un valor boolena, true en caso
   * de que se precenter un error y false en caso contrario.
   * @returns {boolean}
   */
  verificarOdometer() {
    const num1 = this.driver_manifest.leave_yard_odometer;
    const num2 = this.driver_manifest.first_stop_odometer;
    const num3 = this.driver_manifest.last_stop_odometer;
    const num4 = this.driver_manifest.return_to_yard_odometer;

    if (num2 >= num1) {
      this.first_stop_time_odometer_boolen = false;
    } else {
      this.first_stop_time_odometer_boolen = true;
    }

    if (num3 > num1) {
      if (num3 > num2) {
        this.last_stop_time_odometer_boolen = false;
      } else {
        this.last_stop_time_odometer_boolen = true;
      }
    } else {
      this.last_stop_time_odometer_boolen = true;
    }

    if (num4 > num1) {
      if (num4 < num3) {
        this.return_to_yard_time_odometer_boolen = true;
      } else {
        this.return_to_yard_time_odometer_boolen = false;
      }
    } else {
      this.return_to_yard_time_odometer_boolen = true;
    }

    if (
      this.first_stop_time_odometer_boolen ||
      this.last_stop_time_odometer_boolen ||
      this.return_to_yard_time_odometer_boolen
    ) {
      return false;
    } else {
      return true;
    }
  }


  /**
   * @description Este metodo recibe el id de un driver-manifest y carga la informacion del mismo en el ormulario para
   * su posterior edicion y actualizacion.
   * @param {number} id
   */
  loadDataOne(id: number): void {
    this.errorGeneral = false;
    this.billed_edit = false;
    this.busy = this._dataService.getData('driverManifest/' + id).subscribe(
      async (data: any) => {
        this.driver_manifest = new DriverManifest();
        this.driver_manifest = data;
        this.driverManifestEdit = 1;
        const permission_edit_billeds = await this.ngxPermissionsService.hasPermission('update-manifest-billed');
        if (data.billing_status_id != 1 && !permission_edit_billeds) {
          this.billed_edit = true;
        }
        // this.onBilled.emit(this.billed_edit);

        this.route_blocks_select = this.driver_manifest.route_block_id;

        this.valueChangedClient(this.driver_manifest.client);
        this.loadRouteBlocks(this.driver_manifest.client_id);
        this.driver_id = this.driver_manifest.driver;
        this.client_id = this.driver_manifest.client;
        this.postLoadaSchedule();
      }
    );

  }


  /**
   * @description Este metodo retorna la fecha actual en e formato requerido en este momento
   * @returns {string}
   */
  getFechaActualFormaDos() {
    const dt = new Date();
    const month = dt.getMonth() + 1;
    const day = dt.getDate();
    const year = dt.getFullYear();
    const cadena = (year + '-' + month + '-' + day);
    return cadena;
  }

  onNoChanges() {
    this.currentDate = this.driver_manifest.dateOfService;
    this.driverManifestEdit = 0;
    this.driver_manifest = new DriverManifest();
    this.currentDate = moment().local().format('YYYY-MM-DD');
    this.driver_manifest.dateOfService = this.currentDate;
    this.driver_id = '';
    this.client_id = '';
    this.odometer_validation_1 = 'NaN';
    this.odometer_validation_2 = 'NaN';
    this.odometer_validation_3 = 'NaN';
    this.changeClient(0);
    this.onCloseModal.emit(true);

  }

  /**
   * @description Este metodo se ejecuta cuando se cambia el valor del campor Cliente, este metodo toma el id y carga la informacion
   * @param {number} value
   */
  changeClient(value: number) {
    this.loadRouteBlocks(value);
  }

  valueChangedDriver(newVal: Driver) {
    this.driver_manifest.driver_id = newVal.id.toString();
  }

  valueChangedClient(newVal: Client) {
    if (newVal.id == 43 || newVal.client_name == 'MDT') {
      this.isMDT = true;
    } else {
      this.isMDT = false;
    }
    this.driver_manifest.client_id = newVal.id.toString();
    this.changeClient(newVal.id);


  }

  /**
   * description: Esta funcion, Validar que la diferencia entre el
   * minuendo y el sustraendo no sea mayor a un validador numerico dado
   */

  validatedRange(minuendo: any, sustraendo: any, validador: any) {
    const min = parseInt(minuendo);
    const sus = parseInt(sustraendo);
    const val = parseInt(validador);
    const result = (min - sus);
    const r = (result <= val) ? true : false;
    return {operation: r, difference: result};
  }

  /**
   * description: Este metodo se invoca para validar que los 3 odometer puedan tengan los valores correctos.
   */
  getValidatedRange() {
    // Validar que la diferencia entre el leave yard odometer y el first stop odometer no puede ser mayor que 100
    const r1 = (this.validatedRange(this.driver_manifest.first_stop_odometer, this.driver_manifest.leave_yard_odometer, 100).operation);

    // Validar que la diferencia entre el first stop odometer y el last stop odometer no puede ser mas de 400.
    const r2 = (this.validatedRange(this.driver_manifest.last_stop_odometer, this.driver_manifest.first_stop_odometer, 400).operation);

    // Validar que la diferencia entre el last stop odometer y el return to yard odometer no puede ser mayor a 100
    const r3 = (this.validatedRange(this.driver_manifest.return_to_yard_odometer, this.driver_manifest.last_stop_odometer, 100).operation);

    return r1 && r2 && r3;
  }

  /***
   * @description
   */
  lockClient() {
    if (this.client_id !== '') {
      this.lockClientInput = !this.lockClientInput;
    }
  }

  /***
   * @description
   */
  changeChk() {
    this.chState = !this.chState;
  }

  /***
   * @description
   */
  changeLock() {
    this.lock = !this.lock;
  }

  /***
   * @description
   */
  loadDrivers() {
    this.busy = this._serviceDriver.getDriversStatus(false).subscribe(
      data => {
        this.drivers = this._serviceDriver.orderDriver(data);
      })
  }

  onDateRangeChanged(event: IMyDateRangeModel) {
    if(event.beginDate.day != 0) {
      this.rangeDate = {};
      this.rangeDate.beginDate = event.beginDate;
      this.rangeDate.endDate = event.endDate;
    }
  }

  getRowStyle(params) {
    if (params.data) {
      if (params.data.created_at !== params.data.updated_at) {
        return {
          'color': 'red',
          'font-weight': '600'
        };
      }
    }
  }

  selectTab(tabId: number) {
    this.staticTabs.tabs[tabId].active = true;
  }

  postLoadaSchedule() {
    const driver_id = this.driver_id.id;
    const dateOfService = this.driver_manifest.dateOfService;
    const vehicle_id = this.driver_manifest.vehicle_id;
    const route_block_id = this.driver_manifest.route_block_id;

    if (driver_id && dateOfService && vehicle_id && route_block_id) {
      this.busy = this._dataService.postLoadaSchedule(dateOfService, driver_id, vehicle_id, route_block_id) .subscribe((data: any) => {
        if (data.data.length > 0) {
          this.schDriver.leave_yard_time = moment(data.data[0].leave_yard_time).format('HH:mm'),
          this.schDriver.start_time = moment(data.data[0].start_time).format('HH:mm'),
          this.schDriver.end_time = moment(data.data[0].end_time).format('HH:mm'),
          this.schDriver.punch_out_time = moment(data.data[0].punch_out_time).format('HH:mm')
        } else {
          this.schDriver = {};
          Swal.fire('This combination of data is not on schedule');
        }
      });
    }
  }
}
