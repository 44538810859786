import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PrintManifestService } from '../print-manifest.service';
import { Subject, Subscription} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IRouteBlockSelect } from '../print-manifest.interface';
import { SweetAlertHelper } from '../../../../../../CORE/helpers/sweet-alert-helper.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-print-manifest-upload',
  templateUrl: './print-manifest-upload.component.html',
  styleUrls: ['./print-manifest-upload.component.scss']
})
export class PrintManifestUploadComponent implements OnInit, OnDestroy {
  @ViewChild('pdfViewer') pdfViewer: ElementRef;

  busy: Subscription[] = [];
  destroy: Subject<boolean> = new Subject<boolean>();
  form: FormGroup;

  base64: SafeUrl;
  readOnly = true;
  showForm = true;
  routeBlockCode: string;

  constructor(
    private fb: FormBuilder,
    private printManifestService: PrintManifestService,
    private sweetAlertHelper: SweetAlertHelper,
    private _sanitizer: DomSanitizer
  ) {
    this.initForm();
  }

  initForm(): void {
    this.form = this.fb.group({
      id: [],
      block_code: ['', Validators.required],
      attach: [null, Validators.required]
    });
  }

  onChange(event: Event): void {
    const input: HTMLInputElement = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file: File = input.files[0];
      const mime: string = file.type;

      if (!this.validMimeType(mime)) {
        this.sweetAlertHelper.createCustomAlert({
          type: 'warning',
          title: 'Format file',
          text: 'Please only upload PDF files'
        }).then();

        input.value = null;
        return;
      }

      this.form.get('attach').setValue(file);
      this.showPreview(file);
    }
  }

  showPreview(file: File): void {
    this.pdfViewer.nativeElement.src = URL.createObjectURL(file);
  }

  toggleEditBlockCode(): void {
    this.readOnly = !this.readOnly;
    if (this.readOnly) {
      this.form.get('block_code').setValue(this.routeBlockCode);
    }
  }

  ngOnInit(): void {
    this.subscribeToObservers();
  }

  subscribeToObservers(): void {
    this.printManifestService.infoRouteBlockAction$
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (data: IRouteBlockSelect): void => {
          this.form.patchValue(data);
          this.getBlobToViewPDF(data);
          this.canShowForm(data);
          this.routeBlockCode = data.block_code;
        }
      });
  }

  canShowForm(data: IRouteBlockSelect): void {
    const { block_code } = data;
    if (!block_code) {
      this.showForm = false;
    }
  }

  attachDocument(): void {
    const formData: FormData = new FormData();
    const {id, block_code, attach} = this.form.value;

    formData.append('id', id);
    formData.append('block_code', block_code);
    formData.append('attach', attach);

    this.busy.push(
      this.printManifestService.uploadAttachToRouteBlock(id, formData).subscribe({
          next: (): void => {
            this.sweetAlertHelper.createCustomAlert({
              type: 'success',
              title: 'Attach Document',
              text: 'Attach uploaded successful!'
            }).then((): void => {
                  this.updateSelectOnPrintCustomManifestModal();
                  this.printManifestService.closeUploadModal(true);
                }
            );
          },
          error: (error): void => {
            this.sweetAlertHelper.createCustomAlert({
              type: 'error',
              title: 'Error',
              text: error
            }).then();
          }
        }
      )
    );
  }

  getBlobToViewPDF(data: IRouteBlockSelect): void {
    const {id, block_code} = data;

    if (!block_code) {
      this.busy.push(
        this.printManifestService.getAttachToRouteBlockIMG(id).subscribe({
          next: ({data: base64}): void => {
            this.base64 = this._sanitizer.bypassSecurityTrustUrl(`data:image/png;base64, ${base64}`);
          },
          error: err => {
            this.sweetAlertHelper.captureException(err).then(
                () => this.printManifestService.closeUploadModal(true)
            );
          }
        })
      );
    }
  }

  private updateSelectOnPrintCustomManifestModal(): void {
    this.printManifestService.reloadSelect(true);
  }

  private validMimeType(mimeType: string): boolean {
    const validMimeType: string[] = [
      'application/pdf'
    ];
    return validMimeType.includes(mimeType);
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  get hiddenFrame(): boolean {
    return !this.form.get('attach').value;
  }

  get formIsInvalid(): boolean {
    return !this.form.valid;
  }
}
