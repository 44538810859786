import { Component, EventEmitter, Input, OnChanges, OnInit, Output, OnDestroy, SimpleChange } from '@angular/core';
import { Subscription } from 'rxjs/Rx';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BatchInvoicesStatusService } from './../../../../../services/billing/batch/batch-invoices-status.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-invoice-status',
  templateUrl: './add-invoice-status.component.html',
  styleUrls: ['./add-invoice-status.component.scss']
})
export class AddInvoiceStatusComponent implements OnInit {
  busy: Subscription;
  formInvoiceStatus: FormGroup;
  @Output() onClose? = new EventEmitter<any>();
  @Output() onReload? = new EventEmitter<any>();
  @Input() dataFilter?: any;
  @Input() clearGrid?: any;
  showStatus = 1;

  constructor(private fb: FormBuilder, private batchInvoicesStatusService: BatchInvoicesStatusService) {
    this.initForm();
  }

  ngOnInit() {
    this.stateForm();
  }

  ngOnDestroy(): void {
    this.busy ? this.busy.unsubscribe() : null;
  }

  initForm() {
    this.formInvoiceStatus = this.fb.group({
      id: [''],
      description: ['', Validators.required],
      is_active: ['', Validators.required]
    });
  }

  cancel() {
    this.formInvoiceStatus.reset();
    this.onClose.emit();
  }

  save() {
    const formData = Object.assign({}, this.formInvoiceStatus.getRawValue());
    if (formData.id) {
      this.batchInvoicesStatusService.editBatchInvoiceStatus(formData).subscribe(
        (resp: any) => {
          if (resp) {
            Swal.fire({
              type: 'success',
              title: 'Your work has been saved',
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              this.onReload.emit(formData);
              this.onClose.emit();
            });
          }
        },
        error => {
          Swal.fire({
            type: 'error',
            title: 'Error...',
            text: error.message
          });
        }
      );
    } else {
      this.batchInvoicesStatusService.addBatchInvoiceStatus(formData).subscribe(
        (resp: any) => {
          if (resp) {
            Swal.fire({
              type: 'success',
              title: 'Your work has been saved',
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              this.onReload.emit(formData);
              this.onClose.emit();
            });
          }
        },
        error => {
          Swal.fire({
            type: 'error',
            title: 'Error...',
            text: error.message
          });
        }
      );
    }
  }

  stateForm() {
    this.batchInvoicesStatusService.InvoiceStatusRows$.subscribe((resp: any) => {
      if (resp) {
        if (resp.description) {
          this.formInvoiceStatus.setValue({
            id: resp.id,
            description: resp.description,
            is_active: resp.is_active
          });
        } else {
          this.formInvoiceStatus.setValue({
            id: '',
            description: '',
            is_active: false
          });
        }
      }
    });
  }
}
