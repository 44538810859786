import { Component, ElementRef, forwardRef, OnInit, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-emails',
  templateUrl: './input-emails.component.html',
  styleUrls: ['./input-emails.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputEmailsComponent),
      multi: true
    }
  ]
})
export class InputEmailsComponent implements OnInit, ControlValueAccessor {
  public pattern: RegExp;
  public emails: string[];
  public email: string;
  public placeholder: string;
  propagateChange = (_: any) => {};
  onTouched = () => {};

  constructor(private _renderer: Renderer2, private el: ElementRef) {
    this.pattern = /^([a-z0-9]+(?:[._-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*\.[a-z]{2,})$/i;

    this.emails = [];
    this.email = '';
    this.placeholder = 'Type emails';
  }

  ngOnInit() {}

  onAddEmail(event: any) {
    if (event.keyCode !== 16) {
      if (
        event.keyCode === 13 ||
        event.keyCode === 32 ||
        event.keyCode === 188
      ) {
        event.preventDefault();
        if (this.email !== '') {
          if (this.pattern.test(this.email)) {
            this.emails.push(this.email);
            this.email = '';
            this.propagateChange(this.emails);
            this.onTouched();
          }
        }
      }
    }
  }

  onDelete(email: string): void {
    this.emails.forEach((node, index) => {
      if (node === email) {
        this.emails.splice(index, 1);
        this.propagateChange(this.emails);
      }
    });
  }

  writeValue(obj: any): void {
    if (obj !== null && obj !== undefined) {
      if (Array.isArray(obj)) {
        this.emails = obj;
      } else {
        this.emails = obj.split(';');
      }
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(this.el.nativeElement, 'disabled', isDisabled);
  }

  onFocusout() {
    if (this.email !== '') {
      if (this.pattern.test(this.email)) {
        this.emails.push(this.email);
        this.email = '';
        this.propagateChange(this.emails);
        this.onTouched();
      }
    }
  }
}
