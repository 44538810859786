import { Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { DriverClockLogService } from '../../../services/payroll/driver-clock-log.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-clock-log',
  templateUrl: './clock-log.component.html',
  styleUrls: ['./clock-log.component.scss']
})
export class ClockLogComponent implements OnInit {

  gridLog: GridOptions;
  busy: Subscription;
  countRecord: number;

  constructor(private driverClockLogService: DriverClockLogService) {
    this.setConfigGrid();
  }

  ngOnInit() {
  }

  setConfigGrid() {
    this.gridLog = <GridOptions>{
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      rowHeight: 30,
      onFilterChanged: () => {
        this.countRecord = this.gridLog.api.getDisplayedRowCount();
      },
      onGridReady: (event: any) => {
        this.gridLog.api.setRowData([]);
      }
    };
    this.gridLog.columnDefs = [
      { headerName: 'Status', field: 'log', width: 100 },
      { headerName: 'Type', field: 'type', width: 100 },
      { headerName: 'Employee ID', field: 'driver.employee_id', width: 120 },
      { headerName: 'Last name', field: 'driver.last_name', width: 140 },
      { headerName: 'First name', field: 'driver.first_name', width: 140 },
      { headerName: 'Phone', field: 'driver.phone', width: 140 },
      { headerName: 'Created at', field: 'created_at', width: 180 },
      {
        headerName: 'Sync', width: 130, colId: 'sync',
        cellRenderer: params => {
          return !params.data.done ? '<button type="button" class="btn btn-primary" style="height: 20px; margin-right: 1em; font-size: 10px; (click)="syncClockLog(params.data)"">Update</button>' : '';
        },
      }
    ];
  }

  onChangeStatus(filter: boolean) {
    const model = this.gridLog.columnApi.getColumnState();
    this.busy = this.driverClockLogService.getClockLog(filter).subscribe(
      (resp: any) => {
        this.gridLog.api.setRowData(resp);
        this.countRecord = resp.length;
        this.gridLog.columnApi.setColumnState(model);
      }
    );
  }

  onCellClicked(event: any) {
    if (event.colDef.colId === 'sync' && !event.data.done) {
      Swal.fire({
        title: 'Confirm the record update in RealTime?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
      }).then((result) => {
        if (result.value) {
          this.busy = this.driverClockLogService.syncClockLog(event.data.id).subscribe(
            (resp: any) => {
              this.onChangeStatus(false);
            }
          );
        }
      });
    }
  }

  clearFilter() {
    this.gridLog.api.setFilterModel(null);
  }

  clearSort() {
    this.gridLog.api.setSortModel(null);
  }
}
