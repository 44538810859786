import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InvoiceReportComponent } from './invoice-report.component';
import { AgGridModule } from 'ag-grid-angular';
import { NgBusyModule } from 'ng-busy';
import { InfoBillingBatchComponent } from './info-billing-batch/info-billing-batch.component';
import { TabsModule } from 'ngx-bootstrap';
import { InfoBatchByRateComponent } from './info-billing-batch/info-batch/info-batch-by-rate/info-batch-by-rate.component';
import { InfoReportComponent } from './info-billing-batch/info-report/info-report.component';
import { BatchSummaryComponent } from './batch-summary/batch-summary.component';
import { HeaderFiltersModule } from '../../../../components/header-filters/header-filters.module';
import { BatchModule } from '../../batch/batch.module';
import { BsModalModule } from 'ng2-bs3-modal';
import { FleetModule } from '../../../fleet/fleet.module';
import { MyDatePickerModule } from 'mydatepicker';
import { ChargesGridComponent } from './batch-charges/charges-grid/charges-grid.component';
import { CreditsGridComponent } from './batch-credits/credits-grid/credits-grid.component';
import { AddBatchChargeComponent } from './batch-charges/add-batch-charge/add-batch-charge.component';
import { AddBatchCreditComponent } from './batch-credits/add-batch-credit/add-batch-credit.component';
import { CurrencyMaskModule } from 'ngx-currency-mask';
import { InfoBatchByRouteComponent } from './info-billing-batch/info-batch/info-batch-by-route/info-batch-by-route.component';
import { InfoBatchComponent } from './info-billing-batch/info-batch/info-batch.component';
import { InfoBatchTotalsComponent } from './info-billing-batch/info-batch/info-batch-totals/info-batch-totals.component';
import { BatchBalanceInfoComponent } from './batch-balance/batch-balance-info/batch-balance-info.component';

@NgModule({
  imports: [
    CommonModule,
    AgGridModule.withComponents([]),
    NgBusyModule,
    TabsModule,
    HeaderFiltersModule,
    BatchModule,
    BsModalModule,
    FleetModule,
    FormsModule,
    MyDatePickerModule,
    ReactiveFormsModule,
    CurrencyMaskModule
  ],
  declarations: [
    InvoiceReportComponent,
    InfoBillingBatchComponent,
    InfoBatchByRateComponent,
    InfoReportComponent,
    BatchSummaryComponent,
    ChargesGridComponent,
    CreditsGridComponent,
    AddBatchChargeComponent,
    AddBatchCreditComponent,
    InfoBatchByRouteComponent,
    InfoBatchComponent,
    InfoBatchTotalsComponent,
    BatchBalanceInfoComponent
  ],
  exports: [InvoiceReportComponent]
})
export class InvoiceReportModule { }
