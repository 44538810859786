import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, Subscription, combineLatest } from 'rxjs';
import { AttachDocumentService, DocumentObj, DocumentsService } from '../../../services/services.index';
import { takeUntil } from 'rxjs/operators';
import { FieldIsRequired } from '../../../CORE/helpers/form-group.helper';
import { SweetAlertHelper } from '../../../CORE/helpers/sweet-alert-helper.service';
import { IMyDpOptions } from 'mydatepicker';
import * as moment from 'moment';

@Component({
  selector: 'app-add-document',
  templateUrl: './add-document.component.html',
  styleUrls: ['./add-document.component.scss']
})
export class AddDocumentComponent implements OnInit, OnDestroy {
  private destroy: Subject<boolean> = new Subject<boolean>();
  public busy: Subscription[] = [];

  documentsType: DocumentObj[];

  formData: FormGroup;

  namefile: string;
  showExpiration: boolean;
  showNumber: boolean;
  currentDate = new Date();

  datePicker: any;

  myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'mm-dd-yyyy',
    disableUntil: {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    }
  };

  constructor(
    private attachDocumentService: AttachDocumentService,
    private fb: FormBuilder,
    private sweetAlertHelper: SweetAlertHelper,
    private documentsService: DocumentsService
  ) {
    this.formData = this.createForm();
    this.subscribeFormChanges();
  }

  ngOnInit() {
    this.getDocuments();
    this.subscribeObservables();
  }

  getDocuments() {
    combineLatest([this.documentsService.getDocuments(), this.documentsService.codeDocument$])
      .pipe(takeUntil(this.destroy))
      .subscribe(
        ([doc, code]) => {
          if (doc && doc.data) {
            this.documentsType = doc.data;
          } else {
            this.documentsType = [];
            return;
          }
          if (code) {
            const document = this.documentsType.find(row => Number(row.code) === Number(code));
            if (document) {
              this.formData.get('document_id').setValue(document.id);
              this.formData.get('document_id').disable();
            }
          }
        },
        error => {
          this.sweetAlertHelper.createCustomAlert({
            title: 'Error',
            html: error.message,
            type: 'error'
          });
        }
      );
  }

  subscribeObservables() {
    this.attachDocumentService.attachData$.pipe(takeUntil(this.destroy)).subscribe(resp => {
      if (resp) {
        const { model, model_id } = resp;
        this.formData.patchValue({
          model: model ? model : null,
          model_id: model_id ? model_id : null
        });
      } else {
        this.formData = this.createForm();
      }
    });
  }

  subscribeFormChanges() {
    this.formData
      .get('document_id')
      .valueChanges.pipe(takeUntil(this.destroy))
      .subscribe((val: number) => {
        if (val) {
          this.clearForm();
          this.namefile = null;
          this.datePicker = null;
          const document = this.documentsType.find(row => row.id === val);
          if (document && document.expiration) {
            this.setRequiredField('expiration');
            this.showExpiration = true;
          } else {
            this.setOptionalField('expiration');
            this.showExpiration = false;
          }

          if (document && document.info_document) {
            this.setRequiredField('number');
            this.showNumber = true;
          } else {
            this.setOptionalField('number');
            this.showNumber = false;
          }

          if (document && document.file_optional) {
            this.setRequiredField('file');
          } else {
            this.setOptionalField('file');
          }
        }
      });
  }

  clearForm() {
    const form = Object.keys(this.formData.controls);
    form.forEach(field => {
      if (field !== 'document_id' && field !== 'model' && field !== 'model_id') {
        this.formData.get(field).setValue(null);
      }
    });
  }

  setRequiredField(field: string) {
    this.formData.get(field).setValidators(Validators.required);
    this.formData.get(field).updateValueAndValidity();
  }

  setOptionalField(field: string) {
    this.formData.get(field).setValidators([]);
    this.formData.get(field).updateValueAndValidity();
  }

  createForm(): FormGroup {
    return this.fb.group({
      model: null,
      model_id: null,
      document_id: [null, Validators.required],
      number: null,
      expiration: null,
      file: null,
      type: null
    });
  }

  readUrl(event: any) {
    if (event.target.files && event.target.files[0]) {
      const exten = event.target.files[0].name.split('.');
      this.namefile = event.target.files[0].name;
      const reader = new FileReader();

      reader.onload = (events: ProgressEvent) => {
        this.formData.get('file').setValue((<FileReader>events.target).result);
        this.formData.get('type').setValue(exten[exten.length - 1]);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  save() {
    const data = Object.assign(this.formData.getRawValue());
    data.expiration ? (data.expiration = moment(data.expiration).format('YYYY-MM-DD')) : null;
    this.busy.push(
      this.attachDocumentService.storeAttachDocument(data).subscribe(
        () => {
          this.attachDocumentService.saveAttach(true);
        },
        error => {
          let errors = ``;
          error.error.data.forEach((err: any) => {
            errors += `<p>${err}</p></br>`;
          });
          this.sweetAlertHelper.createCustomAlert({
            title: 'Error',
            html: errors,
            type: 'error'
          });
        }
      )
    );
  }

  verifyRequiredField(field: string): boolean {
    return FieldIsRequired(this.formData, field);
  }

  changeDate(event: any) {
    if (event && event.formatted) {
      this.formData.get('expiration').setValue(event.formatted);
    } else {
      this.formData.get('expiration').setValue(null);
    }
  }

  cancel() {
    this.attachDocumentService.saveAttach(false);
  }

  get disabledUpload(): boolean {
    return !this.formData.get('document_id').value;
  }

  get isLicense(): boolean {
    return !!this.formData.get('expiration').value;
  }

  get formInvalid(): boolean {
    return this.formData.invalid;
  }

  ngOnDestroy() {
    this.busy.forEach(subscription => subscription.unsubscribe());
    this.destroy.next(true);
    this.destroy.unsubscribe();
    this.documentsService.setCodeDocument(null);
  }
}
