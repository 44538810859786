import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { CONSTANT, CONSTANTS } from './../app.constants';
import { CompanyService } from './companies/company.service';
import { CompanyId } from '../helpers/local-storage-helpers';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class ReportsManageService {
  protected resource: string;
  tokenTrip2Report: string;
  private httpClient: HttpClient;

  constructor(private http: HttpClient, handler: HttpBackend,
              private companyService: CompanyService, public sanitizer: DomSanitizer) {
    this.resource = environment.url_api_v + CONSTANTS.REPORTS_MANAGE;
    this.httpClient = new HttpClient(handler);
    // this.getTokenTrip2();
  }

  post(data: any) {
    return this.http.post(this.resource, JSON.stringify(data));
  }

  put(data: any, id: number) {
    return this.http.put(this.resource + '/' + id, JSON.stringify(data));
  }

  getAll(): Observable<any> {
    return this.http.get<any>(this.resource).pipe(
      map(response => response.data.map(item => {
        item.text = item.description;
        return item;
      }))
    );
  }

  delete(id: number) {
    return this.http.delete(this.resource + `/delete/${id}`);
  }

  getReport(data: any) {
    return this.http.post(environment.url_api_v + CONSTANTS.GET_REPORT_SSDT, JSON.stringify(data), { responseType: 'blob' });
  }

  downloadDriverManifest(id) {
    return this.http.post(environment.url_api_v + 'scheduleDetail/manifest', JSON.stringify({id}),  { responseType: 'blob' });
  }

  downloadAllDriverManifestCustom(data: any): Observable<Blob> {
    const url = `${CONSTANT.MANIFEST.URL.SCHEDULE_DETAILS_PRINT_MANIFEST_CUSTOM}`;
    return this.http.post(url, JSON.stringify(data), { responseType: 'blob' });
  }
  downloadAllDriverManifest(date) {
    return this.http.post(`${environment.url_api_v}scheduleDetail/manifest/all/${date}`, JSON.stringify({}),  { responseType: 'blob' });
  }

  downloadDriverSchedule(id, start, end) {
    return this.http.get(`${environment.url_api_v}scheduleWeeByDriver/${id}/${start}/${end}`,  { responseType: 'blob' });
  }

  getTokenTrip2(tenant: string, key: string) {
    return new Promise(resolve => {
      this.http.get(`${environment.trip2.endPoint}${key}`,
        this.getHeaderTrip2Bynamic(tenant)).subscribe((resp: any) => {
          localStorage.setItem('repo_tenant_token', resp.token);
          this.tokenTrip2Report = resp.token;
          resolve(this.tokenTrip2Report);
      });
    });
  }


  getUrlJWTTrip2(url: string): any {
    return new Promise((resolve, reject) => {
      this.companyService.getCompany(Number(CompanyId())).toPromise().then((resp) => {

        if (resp.status === 'success') {
          this.httpClient.post(
            `${environment.trip2.endPoint}TokenJwt`,
            {},
            this.getHeaderTrip2Bynamic(resp.data.tenant)
          ).toPromise().then(async (tokenJWTTrip2Report) => {
            if (tokenJWTTrip2Report) {
              resolve(this.sanitizer.bypassSecurityTrustResourceUrl(`${resp.data.tenant_url}/#/login/${tokenJWTTrip2Report}/ta&${url}`));
            }
          });
        }
      });
    });
  }


  getReportTrip2(id: number, parameter: any, isDriverManifest?: boolean) {
    const data: any = {};
    data.format = 'PDF';
    data.parameters = parameter;
    if (isDriverManifest) {
      delete data.format;
    }
    return this.httpClient.post(`${environment.trip2.endPointBase}ReportManager/GetReportUrl/${id}`, data,
      this.getHeaderTrip2());
  }

  getHeaderTrip2() {
    return {
      headers:  {
        'Authorization' : `Session ${localStorage.getItem('repo_tenant_token')}`,
          'Tenant': `${localStorage.getItem('repo_tenant')}`
      }
    };
  }

  getHeaderTrip2Bynamic(tenant: string) {
    return {
      headers:  {
        'Authorization' : `Session ${localStorage.getItem('repo_tenant_token')}`,
        'Tenant': `${tenant}`
      }
    };
  }

  loadReportWindows(resp) {
    if (resp) {
      const win = window.open(`${resp}&s=${localStorage.getItem('repo_tenant_token')}`, '_blank');
      win.focus();
    }
  }

}
