import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttachDocumentComponent } from './attach-document.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AttachDocumentService } from '../../services/services.index';
import { AgGridModule } from 'ag-grid-angular';
import { NgBusyModule } from 'ng-busy';
import { TextMaskModule } from 'angular2-text-mask';
import { ThemeModule } from '../../theme/theme.module';
import { AddDocumentComponent } from './add-document/add-document.component';
import { TabsModule } from 'ngx-bootstrap';
import { BsModalModule } from 'ng2-bs3-modal';
import { MyDatePickerModule } from 'mydatepicker';
import { MissingLicenseComponent } from './missing-license/missing-license.component';

@NgModule({
  imports: [
    CommonModule,
    PopoverModule.forRoot(),
    AgGridModule.withComponents([]),
    ReactiveFormsModule,
    NgBusyModule,
    TextMaskModule,
    ThemeModule,
    FormsModule,
    TabsModule,
    BsModalModule,
    MyDatePickerModule,
  ],
  declarations: [
    AttachDocumentComponent,
    AddDocumentComponent,
    MissingLicenseComponent
  ],
  exports: [
    AttachDocumentComponent,
    AddDocumentComponent
  ],
  providers: [
    AttachDocumentService
  ]
})
export class AttachDocumentModule { }
