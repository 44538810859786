import {
  Component,
  OnInit,
  forwardRef,
  Renderer2,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const noop = () => {};

@Component({
  selector: 'app-input-hour',
  templateUrl: './input-hour.component.html',
  styleUrls: ['./input-hour.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputHourComponent),
      multi: true
    }
  ]
})
export class InputHourComponent implements OnInit, ControlValueAccessor {
  @ViewChild('inputH')
  inputH: ElementRef;

  @Input()
  stylos?: string;
  hour: string;
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;
  public pattern_time = '(0[0-9]|1[0-9]|2[0-9]|3[0-6])(:[0-5][0-9]){1}';
  public time_format = [/[0-9]/, /\d/, ':', /\d/, /\d/];

  constructor(private _renderer: Renderer2, private el: ElementRef) {}

  ngOnInit() {}

  writeValue(value: any) {
    if (value !== undefined && value !== null) {
      this.hour = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean) {
    alert(isDisabled);
    this._renderer.setProperty(
      this.inputH.nativeElement,
      'disabled',
      isDisabled
    );
  }

  changeHours() {
    this.onChangeCallback(this.hour);
  }
}
