import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';


export interface TSODriver {
  id: number;
  token: string;
  name: string;
  lastName: string;
  licenseNumber: string;
  licenseStateCode: string;
  licenseExpiration: string;
  driverStatusID: string;
  state: string;
  city: string;
  postalCode: string;
  email: string;
  phone: string;
}

interface TsoResponse {
  Code: number;
  Message: string;
  Data: any;
}


@Injectable()
export class DriverService {

  protected resource: string;

  constructor(private http: HttpClient) {
    this.resource = environment.url_api_v;
  }

  /**
   *
   * @param {TSODriver} data
   * @returns {Observable<TsoResponse>}
   */
  createDriver(data: TSODriver) {
    return this.http.post<TsoResponse>(this.resource + environment.tso.driver_create, JSON.stringify(data));
  }

  /**
   *
   * @param {string} token
   * @param {number} id
   * @returns {Observable<TsoResponse>}
   */
  deleteDriver(token: string, id: number) {
    const obj = {
      token: token,
      id: id
    };
    return this.http.post<TsoResponse>(this.resource + environment.tso.driver_delete, JSON.stringify(obj));
  }

  /**
   *
   * @param {string} token
   * @returns {Observable<TsoResponse>}
   */
  listDrivers() {
    return this.http.get<TsoResponse>(this.resource + environment.tso.drivers_list);
  }

}
