import { Component, OnDestroy, OnInit } from '@angular/core';
import { PayrollService } from '../../../../services/payroll/payroll.service';
import { GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs-compat';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-count-month',
  templateUrl: 'count-month.component.html',
  styleUrls: ['./count-month.component.scss']
})

export class CountMonthComponent implements OnInit, OnDestroy {
  dataGrid: GridOptions;
  busy: Subscription;

  constructor(private payrollService: PayrollService) {
    this.initGrid();
  }

  ngOnInit() {
    this.onLoadData();
  }

  ngOnDestroy() {
    return this.busy ? this.busy.unsubscribe() : null;
  }

  onLoadData() {
    this.busy = this.payrollService.getPayrollMonth().subscribe(
      (resp: any) => {
        this.dataGrid.api.setRowData(resp);
      },
      error => {
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: error.message
        });
      }
    );
  }

  initGrid() {
    this.dataGrid = <GridOptions> {
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      enableFilter: true,
      columnDefs: [
        { headerName: 'Employe Id', field: 'emp', width: 120 },
        { headerName: 'Employe Name', field: 'employee_name', width: 180 },
        { headerName: 'Year', field: 'year', width: 100 },
        { headerName: 'Month', field: 'month_name', width: 120 },
        { headerName: 'Work', field: 'work', width: 120 },
        { headerName: 'Dept', field: 'dept', width: 120 },
      ],
      onGridReady: () => {
        this.dataGrid.api.setRowData([]);
      }
    };
  }
}
