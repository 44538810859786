import { Injectable } from '@angular/core';
import { Configuration } from '../../app.configuration';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class LayoutDataService {
  constructor(protected consant: Configuration, protected http: HttpClient) {}

  getData(resource: string) {
    return this.http.get(environment.url_api_v + resource);
  }

  postData(data: any, resource: string) {
    let bodyString;
    bodyString = JSON.stringify(data);
    return this.http.post(environment.url_api_v + resource, bodyString);
  }

  putData(data: any, resource: string) {
    let bodyString;
    bodyString = JSON.stringify(data);
    return this.http
      .put(
        environment.url_api_v + resource,
        bodyString
      );
  }
}
