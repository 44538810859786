import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DriverService } from '../../../../../services/fleet/driver.service';
import { AdminDataService, IDriverManifest } from '../driver.data.service';
import { Subject, Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-select-driver',
  templateUrl: './select-driver.component.html',
  styleUrls: ['./select-driver.component.scss']
})
export class SelectDriverComponent implements OnInit, OnDestroy {
  private destroy: Subject<boolean> = new Subject<boolean>();
  @Output() onSelectDriver? = new EventEmitter<any>();
  @Input() driversIn?: any[];
  editDriverManifest: boolean;
  drivers: any = [];
  public busy: Subscription;
  form: FormGroup;
  time_format = [/[0-9]/, /\d/, ':', /\d/, /\d/];

  constructor( private driverService: DriverService,
               private  adminDataService: AdminDataService,
               private domSanitizer: DomSanitizer,
               private fb: FormBuilder) {
      this.initForm();
  }

  ngOnInit() {
    this.loadDrivers();
    this.suscribeObservers();
  }

  initForm() {
    this.editDriverManifest = false;
    this.form = this.fb.group({
      driver_name: [''],
      actual_check_in: [''],
      actual_drop_off: [''],
      sundial_time_id: ['', [Validators.required]],
      driver_id: ['', [Validators.required]],
    });
  }

  suscribeObservers() {
    this.adminDataService.addDriver$.pipe(takeUntil(this.destroy)).subscribe((resp:boolean) => {
        if(resp) {
          this.initForm();
          this.editDriverManifest = false;
        }else{
          this.adminDataService.setDataDriver$.pipe(takeUntil(this.destroy)).subscribe((driver_resp: IDriverManifest) => {
            if(driver_resp) {
              this.editDriverManifest = true;
              const { actual_check_in, actual_drop_off, sundial_time_id, driver_name, driver_id  } = driver_resp;
              this.form.patchValue({
                driver_name: this.myValueFormatter(driver_name),
                driver_id,
                sundial_time_id,
                actual_check_in: this.splitActualTime(actual_check_in),
                actual_drop_off: this.splitActualTime(actual_drop_off)
              })
            }
          });
        }
    })
  }

  splitActualTime(date: string) {
    const x = date.split(' ');
    const x2 = x[1].split('.');
    return x2[0];
  }

  loadDrivers() {
    this.busy = this.driverService.getDriversStatus(false).subscribe(data => {
      if(data) {
        this.drivers = this.driverService.orderDriver(data);
        this.clearData();
      }
    });
  }

  myValueFormatter(data: any ): string {
    return data.last_name + ' ' + data.first_name;
  }

  autocompleListFormatter = (data: any) => {
    if(data) {
      return this.domSanitizer.bypassSecurityTrustHtml(`<span>${data.last_name} ${data.first_name} </span>`);
    }

  }

  valueChangedDriver(event: any) {
    if(event) {
      this.form.controls['sundial_time_id'].setValue(event.sundial_time_id);
      this.form.controls['driver_id'].setValue(event.id);
    }

  }

  clearData() {
    this.driversIn.forEach((item: any) => {
      this.drivers.forEach((row: any, index) => {
        if (item.driver_id == row.id) {
          this.drivers.splice(index, 1);
        }
      });
    });
  }

  onSave() {
    if(!this.editDriverManifest) {
      if ((this.form.controls['actual_check_in'].value === '' && this.form.controls['actual_drop_off'].value === '')) {
        this.onSelectDriver.emit(this.form.value);
      } else if (this.form.controls['actual_check_in'].value !== '' && this.form.controls['actual_drop_off'].value !== '') {
        this.onSelectDriver.emit(this.form.value);
      } else {
        Swal.fire({
          type: 'error',
          title: 'Error',
          text: 'Please check the check in and check out field'
        });
      }
    }else{
      if ((this.form.controls['actual_check_in'].value === '' && this.form.controls['actual_drop_off'].value === '')) {
        this.onSelectDriver.emit(this.form.value);
      } else if (this.form.controls['actual_check_in'].value !== '' && this.form.controls['actual_drop_off'].value !== '') {
        this.onSelectDriver.emit(this.form.value);
      } else {
        Swal.fire({
          type: 'error',
          title: 'Error',
          text: 'Please check the check in and check out field'
        });
      }
    }

  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

}
