import { InfoScheduleComponent } from './dashboard-schedule/info-schedule/info-schedule.component';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule, DatePipe } from '@angular/common';
// HttpClient
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgGridModule } from 'ag-grid-angular/main';
import { AngularDraggableModule } from 'angular2-draggable';
import { TextMaskModule } from 'angular2-text-mask';
import { NgBusyModule } from 'ng-busy';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { Ng2AutoCompleteModule } from 'ng2-auto-complete';
import { BsModalModule } from 'ng2-bs3-modal';
import { AlertModule, TabsModule } from 'ngx-bootstrap';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { NgxPermissionsModule } from 'ngx-permissions';
import { DriverManagementComponent } from '../../components/driver-management/driver-management.component';
import { DriverRoutesComponent } from '../../components/driver-routes/driver-routes.component';
import { EmailsInputModule } from '../../components/emails-input/emails-input.module';
import { HistoryComponent } from '../../components/history/history.component';
import { InputHourComponent } from '../../components/input-hour/input-hour.component';
import { ManifestManagerComponent } from '../../components/manifest-manager/manifest-manager.component';
import { PusherService } from '../../components/services/pusher/pusher.service';
import { VehicleManagementComponent } from '../../components/vehicle-management/vehicle-management.component';
import { WindowModalComponent } from '../../components/window-modal/window-modal.component';
import { ScheduleDetailsService, TsoAssignVehicleService, TsoService } from '../../services/services.index';
import { AuthGuard } from '../../_guards/auth.guard';
import { routing } from '../dashboard-lsf/dashboard-lsf.routing';
import { PayrollModule } from '../payroll/payroll.module';
import { DriverManagementRoutesComponent } from './../../components/driver-management-routes/driver-management-routes.component';
import { HistoryPunchesModule } from './../../components/history-punches/history-punches.module';
import { SelectWeeksModule } from './../../components/select-weeks/select-weeks.module';
import { ThemeModule } from './../../theme/theme.module';
import { DriversPoolComponent } from './daily/component/drivers-pool/drivers-pool.component';
import { EditDailyComponent } from './daily/component/edit/edit-daily.component';
import { InterruptionComponent } from './daily/component/interruption/interruption.component';
import { NotifyMechanicsComponent } from './daily/component/notify-mechanics/notify-mechanics.component';
import { RemplacementComponent } from './daily/component/remplacement/remplacement.component';
import { RoutesInterruptionComponent } from './daily/component/routes-interruption/routes-interruption.component';
import { RoutevisibleComponent } from './daily/component/routevisible/routevisible.component';
import { TsoAssignVehicleComponent } from './daily/component/tso-assign-vehicle/tso-assign-vehicle.component';
import { DailyComponent } from './daily/daily.component';
import { DashboardLsfDataService } from './dashboard-lsf.data.service';
import { CreateScheduleComponent } from './dashboard-lsf/components/create/create.component';
import { DashboardLsfComponent } from './dashboard-lsf/dashboard-lsf.component';
import { DashboardScheduleComponent } from './dashboard-schedule/dashboard-schedule.component';
import { MapTsoComponent } from './map-tso/map-tso.component';
import { EditComponent } from './schedules/components/edit/edit.component';
import { ScheduleDriversComponent } from './schedules/components/schedule-drivers/schedule-drivers.component';
import { SchedulesComponent } from './schedules/schedules.component';
import { CurrencyMaskModule } from 'ngx-currency-mask';
import { ShowErrorsModule } from './../../tools/showErrosForm.directive';
import { AttachDocumentModule } from '../../components/attach-document/attach-document.module';
import { SharedModule } from '../../shared/shared.module';
import { DriverCurrentJobComponent } from './daily/component/drivers-current-job/drivers-current-job.component';

@NgModule({
  imports: [
    CommonModule,
    routing,
    AgGridModule.withComponents([]),
    NgBusyModule,
    BsModalModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TextMaskModule,
    Ng2AutoCompleteModule,
    AccordionModule.forRoot(),
    TabsModule.forRoot(),
    AlertModule.forRoot(),
    EmailsInputModule,
    AngularDraggableModule,
    NgMultiSelectDropDownModule.forRoot(),
    PortalModule,
    ThemeModule,
    NgxPermissionsModule,
    PayrollModule,
    SelectWeeksModule,
    HistoryPunchesModule,
    CurrencyMaskModule,
    ShowErrorsModule,
    AttachDocumentModule,
    SharedModule,
  ],
  declarations: [
    DashboardLsfComponent,
    CreateScheduleComponent,
    SchedulesComponent,
    DailyComponent,
    EditComponent,
    EditDailyComponent,
    DriverManagementComponent,
    DriverManagementRoutesComponent,
    HistoryComponent,
    VehicleManagementComponent,
    DriverRoutesComponent,
    InterruptionComponent,
    RoutevisibleComponent,
    RemplacementComponent,
    InputHourComponent,
    TsoAssignVehicleComponent,
    MapTsoComponent,
    NotifyMechanicsComponent,
    DriversPoolComponent,
    WindowModalComponent,
    RoutesInterruptionComponent,
    ManifestManagerComponent,
    ScheduleDriversComponent,
    DashboardScheduleComponent,
    InfoScheduleComponent,
    DriverCurrentJobComponent
  ],
  providers: [
    AuthGuard,
    DashboardLsfDataService,
    DatePipe,
    PusherService,
    TsoService,
    ScheduleDetailsService,
    TsoAssignVehicleService
  ],
  exports: [
    DriverManagementComponent,
    DriverManagementRoutesComponent,
    HistoryComponent,
    VehicleManagementComponent,
    DriverRoutesComponent,
    InputHourComponent,
    InterruptionComponent,
    ManifestManagerComponent,
    EditDailyComponent
  ]
})


export class DashboardLsfModule {
}
