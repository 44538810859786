import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BatchesService } from '../../../../services/billing/batches.service';
import { ClientService } from '../../../../services/fleet/client.service';
import { TabsetComponent } from 'ngx-bootstrap';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-batch-view',
  templateUrl: './batch-view.component.html',
  styleUrls: ['./batch-view.component.scss']
})
export class BatchViewComponent implements OnInit, OnDestroy {
  private destroy: Subject<boolean> = new Subject();
  @ViewChild('staticTabs') staticTabs: TabsetComponent;
  suscriptionSummary: Subscription[] = [];
  busy: Subscription;
  clearGrid: any;
  isActiveInvoiceCredit: any;
  btnAddBatch = true;
  filterBatch: boolean = false;
  clientId: number;

  dropdownSettings: any = {
    singleSelection: true,
    idField: 'id',
    textField: 'description_route',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    allowSearchFilter: true
  };

  form: FormGroup;
  clients: any = [];
  routes: any = [];

  constructor(private fb: FormBuilder, private batchesService: BatchesService, private clientService: ClientService) {
    this.initForm();
  }

  ngOnInit() {
    this.loadData();
    this.listenSendClientIdBatch();
  }

  ngOnDestroy() {
    this.form.reset();
    this.suscriptionSummary.forEach(suscription => suscription.unsubscribe());
  }

  loadData() {
    this.busy = forkJoin([
      this.clientService.getClientsBatch(),
    ]).subscribe((resp: any[]) => {
      if(resp) {
        this.clients = resp[0].data;
      }
    });
  }

  initForm() {
    this.form = this.fb.group({
      client_id: [undefined, Validators.required],
    });
  }

  onSearch() {
    const data = Object.assign({}, this.form.getRawValue());
    if (data && data.client_id) {
      this.clientId = data.client_id;;
      this.onLoadBatches(data.client_id);
    }
  }

  onChangeClient(client_id: number) {
    if (client_id) {
      this.suscriptionSummary.push(this.clientService.getRoutesByClient(client_id).subscribe((resp: any) => {
        if(resp) {
          this.routes = resp.data.map(r => ({...r, description_route: String(r.desc_billing ? r.desc_billing : r.description).toUpperCase() }));;
          this.form.get('route_id').setValue([]);
          this.isActiveInvoiceCredit = false;
          this.clearGrid = Math.random();
        }
      }));
    }
  }

  listenSendClientIdBatch() {
    this.batchesService.loadClientIdBatch.pipe(takeUntil(this.destroy)).subscribe((client_id: number) => {
      if (client_id) {
        this.form.get('client_id').setValue(client_id);
        this.staticTabs.tabs[1].active = true;
        this.filterBatch = true;
        this.isActiveInvoiceCredit = false;
        //this.onLoadBatches(client_id);
      }
    })
  }

  onLoadBatches(client_id_in: number){
    if(client_id_in){
      this.isActiveInvoiceCredit = false;
      this.batchesService.load_batches$.next(client_id_in);
      this.clearGrid = Math.random();
    }
  }

  getBlockLikeRun(id: number) {
    return this.clients.filter((item: any) => item.id == id).shift();
  }

  inactiveFilterDate(tab){
    if(tab === 0) {
      this.filterBatch = true;
      this.isActiveInvoiceCredit = false;
    }else{
      this.filterBatch = false;
      this.clearGrid = Math.random();
    }
  }

}
