import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { IMyDrpOptions } from 'mydaterangepicker';
import * as moment from 'moment';
import { BatchesService } from '../../../services/billing/batches.service';
import { merge, Subscription } from 'rxjs';
import { GetMonths } from '../../../helpers/moment-helpers';
import {WorkflowService} from '../../../services/billing/workflow.service';

@Component({
  selector: 'app-header-filter',
  templateUrl: './header-filter.component.html',
  styleUrls: ['./header-filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HeaderFilterComponent),
      multi: true
    }
  ]
})
export class HeaderFilterComponent implements OnInit, OnDestroy, ControlValueAccessor {
  form: FormGroup;
  suscriptionSummary: Subscription[] = [];
  filterType = '1';
  years = [0, 1, 2, 3, 4].map(year => (new Date).getFullYear() - year);
  actualDate = new Date();
  date = new Date();
  months = GetMonths();

  myDateRangePickerOptions: IMyDrpOptions = {
    dateFormat: 'yyyy-mm-dd',
    disableSince: { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() }
  };

  constructor(private fb: FormBuilder, private workflowService: WorkflowService, private batchesService: BatchesService) {
    this.initForm();
    this.loadDateSummary();
    this.loadDateWorkflow();
  }

  ngOnDestroy() {
    this.suscriptionSummary.forEach(suscription => suscription.unsubscribe());
  }

  ngOnInit() {
    this.form.controls['dateRange'].valueChanges.subscribe((resp: any) => {
      if (resp) {
        if (Array.isArray(resp)) {
          const start: any = moment(resp[0]).format('MM-DD-YYYY');
          const end: any = moment(resp[1]).format('MM-DD-YYYY');
          this.form.get('start_date').setValue(start);
          this.form.get('end_date').setValue(end);
          this.onDataSet();
        }
      }
    });
    merge(
      this.form.get('year').valueChanges,
      this.form.get('month').valueChanges
    ).subscribe(() => {
      const data = Object.assign({}, this.form.getRawValue());
      if (data.year && data.month) {
        const start: any = moment(`${data.month}-01-${data.year}`, 'MM-DD-YYYY').startOf('month').format('MM-DD-YYYY');
        const end: any = moment(`${data.month}-01-${data.year}`, 'MM-DD-YYYY').endOf('month').format('MM-DD-YYYY');
        this.form.get('start_date').setValue(start);
        this.form.get('end_date').setValue(end);
        this.onDataSet();
      }
    });
    this.form.get('weeks').valueChanges.subscribe(res => {
      if (res.dateStart !== this.form.get('start_date').value) {
        this.form.get('start_date').setValue(res.dateStart, { emitEvent: false });
        this.form.get('end_date').setValue(res.dateEnd, { emitEvent: false });
        this.onDataSet();
      }
    });
    this.form.get('type').valueChanges.subscribe(res => {
      this.form.get('dateRange').setValue('');
      this.form.get('year').setValue('');
      this.form.get('month').setValue('');
      this.form.get('weeks').setValue('');
      this.form.get('start_date').setValue('');
      this.form.get('end_date').setValue('');
      this.onDataSet();
    });
  }

  loadDateSummary() {
    this.suscriptionSummary.push(this.batchesService.changeFormDate.subscribe(resp => {
      if (resp) {
        this.loadDateInfo(resp);
      }
    }));
  }

  loadDateWorkflow() {
    this.suscriptionSummary.push(this.workflowService.changeFormDateHeaderFilter$.subscribe(resp => {
      if (resp) {
        this.loadDateInfo(resp);
      }
    }));
  }
  loadDateInfo(data) {
    this.form.get('dateRange').setValue([data.start_date, data.end_date]);
    this.form.get('start_date').setValue(data.start_date);
    this.form.get('end_date').setValue(data.end_date);
    this.form.get('type').setValue(data.type);
    this.form.get('year').setValue(data.year);
    this.form.get('month').setValue(data.month);
    this.form.get('weeks').setValue(data.weeks);
    this.onDataSet();
  }

  initForm() {
    this.form = this.fb.group({
      year: [null],
      month: [null],
      type: ['1', [Validators.required]],
      dateRange: ['', [Validators.required]],
      start_date: [null, [Validators.required]],
      end_date: [null, [Validators.required]],
      weeks: null,
    });
  }

  onChange = (_: any) => {};
  onTouch = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
  }

  searchRange() {
    this.form.reset();
    this.onDataSet();
  }

  onDataSet() {
    const data = {
      'type': this.form.get('type').value,
      'year': this.form.get('year').value,
      'month': this.form.get('month').value,
      'start_date': this.form.get('start_date').value,
      'end_date': this.form.get('end_date').value,
      'weeks': this.form.get('weeks').value,
    };
    this.onTouch();
    this.onChange(data);
  }

}
