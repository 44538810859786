import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular/main';
import { TextMaskModule } from 'angular2-text-mask';
import { MyDateRangePickerModule } from 'mydaterangepicker';
import { NgBusyModule } from 'ng-busy';
import { Ng2AutoCompleteModule } from 'ng2-auto-complete';
import { BsDatepickerModule, TabsModule } from 'ngx-bootstrap';
import { ManifestsMovilService } from '../../services/services.index';
import { SharedModule } from '../../shared/shared.module';
import { DashboardLsfModule } from './../dashboard-lsf/dashboard-lsf.module';
import { DriverManifestComponent } from './components/driver-manifest/driver-manifest.component';
import { ManifestDetailsComponent } from './components/manifest-details/manifest-details.component';
import { ManifestMovilComponent } from './components/manifest-movil/manifest-movil.component';
import { ManifestsRoutingModule } from './manifests-routing.module';
import { AddDriversComponent } from './components/driver-manifest/add-drivers/add-drivers.component';
import { BsModalModule } from 'ng2-bs3-modal';
import { SelectDriverComponent } from './components/driver-manifest/select-driver/select-driver.component';
import { GridListComponent } from './components/driver-manifest/grid-list/grid-list.component';
import { AddVehiclesComponent } from './components/driver-manifest/add-vehicles/add-vehicles.component';
import { SelectVehicleComponent } from './components/driver-manifest/select-vehicle/select-vehicle.component';
import { AddStopsComponent } from './components/driver-manifest/add-stops/add-stops.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ShowErrorsModule } from './../../tools/showErrosForm.directive';
import { InterruptionsListComponent } from './components/driver-manifest/interruptions-list/interruptions-list.component';
import { ManifestFormComponent } from './components/driver-manifest/manifest-form/manifest-form.component';
import { HeaderFiltersModule } from '../../components/header-filters/header-filters.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { paginatorModule } from '../../components/paginator-component/paginator.module';
import { VehicleOdometerHistoryComponent } from './components/driver-manifest/vehicle-odometer-history/vehicle-odometer-history.component'
import { gridControlsModule } from '../../components/grid-controls/grid-controls.module';
import { GridContainerComponent } from './components/driver-manifest/vehicle-odometer-history/grid-container/grid-container.component';
import { ReportsService } from '../../services/reports.service';
import { WorkdaySummaryComponent } from './components/driver-manifest/workday-summary/workday-summary.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    ManifestsRoutingModule,
    AgGridModule.withComponents([]),
    NgBusyModule,
    Ng2AutoCompleteModule,
    TextMaskModule,
    BsModalModule,
    MyDateRangePickerModule,
    TabsModule.forRoot(),
    DashboardLsfModule,
    NgxPermissionsModule,
    ShowErrorsModule,
    HeaderFiltersModule,
    FormsModule,
    BsModalModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    paginatorModule,
    gridControlsModule,
  ],
  declarations: [
    ManifestMovilComponent,
    DriverManifestComponent,
    ManifestDetailsComponent,
    AddDriversComponent,
    AddStopsComponent,
    AddVehiclesComponent,
    SelectDriverComponent,
    SelectVehicleComponent,
    GridListComponent,
    InterruptionsListComponent,
    ManifestFormComponent,
    VehicleOdometerHistoryComponent,
    GridContainerComponent,
    WorkdaySummaryComponent,
  ],
  providers: [
    ManifestsMovilService,
    ReportsService
  ],
  exports: [
    ManifestMovilComponent,
    DriverManifestComponent,
    ManifestDetailsComponent,
    AddDriversComponent,
    AddStopsComponent,
    AddVehiclesComponent,
    SelectDriverComponent,
    SelectVehicleComponent,
    GridListComponent,
    InterruptionsListComponent,
    ManifestFormComponent,
    VehicleOdometerHistoryComponent,
  ]
})

export class ManifestsModule {
}
