export enum ClientsId {
  MiamiBeach = 22,
  Mdt = 37,
  Doral = 18,
  MiamiSprings = 25,
  Homestead = 19,
  cityOfMiami = 13,
}

export enum ReportsId {
  InterruptionsReportMiamiBeach = 6162,
  InterruptionsReportDoral = 6165,
  InterruptionsReportCityOfMiami = 2133,
  InterruptionsReportMdt = 6146,
  RoutesReportMiamiBeach = 6160,
  RoutesReportMiamiSprings = 6171,
  InvoiceReportMiamiBeach = 6159,
  InvoiceReportDoral = 6166,
  InvoiceReportMiami_Springs = 6170,
  PrintBlocksMiamiBeach = 6161,
}

export enum CompanyId {
  Miami = 1,
  Fortlauderdale = 2,
}
