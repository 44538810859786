import {Component, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'app-info-billing-batch',
  templateUrl: './info-billing-batch.component.html',
  styleUrls: ['./info-billing-batch.component.scss']
})
export class InfoBillingBatchComponent implements OnInit, OnDestroy {

  constructor() { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

}
