import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CONSTANTS } from '../../app.constants';


export interface IRouteBlock {
  id: number;
  route_id: number;
  description: string;
  block_code: string;
  startLocation: string;
  active: boolean;
}

export interface IRoute {
  id: number;
  client_id: number;
  route_code: string;
  description: string;
  route_blocks: IRouteBlock[];
  active: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class RoutesService {

  resource: string;

  constructor( private http: HttpClient) {
    this.resource = environment.url_api_v + CONSTANTS.ROUTES;
  }

  getRoutesStatus(status: boolean): Observable<IRoute[]> {
    const cadena = status ? 'active/1' : '';
    return this.http.get<IRoute[]>(`${this.resource}/${cadena}/routesContracts`);
  }

  getContractsRoute(route_id) {
    return this.http.get(`${environment.url_api_v}routesContracts/byRoute/${route_id}`);
  }

  getDaysContracts() {
    return this.http.get(`${environment.url_api_v}routesContracts/day/all`);
  }

  getDayContract(id) {
    return this.http.get(`${environment.url_api_v}routesContracts/${id}`);
  }

  saveContracts(data) {
    delete data.id;
    return this.http.post(`${environment.url_api_v}routesContracts`, data);
  }

  updateContracts(data) {
    const id = data.id;
    delete data.id;
    return this.http.put(`${environment.url_api_v}routesContracts/${id}`, data);
  }

}
