import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TimecardsService {

  constructor( private http: HttpClient) { }

  schDriverSelected$ = new EventEmitter<any>();
  editDetailSelected$ = new EventEmitter<any>();
  successDetailChanged$ = new EventEmitter<any>();

  private closeModal = new BehaviorSubject<boolean>(false);
  closeModal$ = this.closeModal.asObservable();

  closeModalClear(params: boolean) {
    this.closeModal.next(params);
  }

  /**
    * @returns Listado de employees en sundial
  */
  getListDrivers(startDate: string, endDate: string, asdate: string, type: string) {
    let date = type === 'custom' ? `:${startDate},${endDate}` : '';
    date = type === 'asOf' ? `:${asdate}` : date;
    return this.http.get(`${environment.url_api_v}drivers/payroll/${type}${date}`);
  }

  /**
    * @returns Listado de employees en sundial con sus totales de horas por semana
  */
  getDashboardSchedule(startDate: string, endDate: string, asdate: string, type: string, clientsId: any) {
    let date = type === 'custom' ? `:${startDate},${endDate}` : '';
    date = type === 'asOf' ? `:${asdate}` : date;

    const data = {
      date_range: type + date,
      clients_id: clientsId
    };
    return this.http.post(`${environment.url_api_v}drivers/dashboardScheduleByClient`, data);
  }

  /**
    * @returns Listado de employees en sundial con sus payroll code
  */
  getReportExcell(startDate: string, endDate: string, asdate: string, type: string, export_: number) {
    let date = type === 'custom' ? `:${startDate},${endDate}` : '';
    date = type === 'asOf' ? `:${asdate}` : date;
    if (export_ === 0) {
      return this.http.get(`${environment.url_api_v}drivers/payrollReport/${type}${date}/${export_}`, { responseType: 'blob' });
    } else {
      return this.http.get(`${environment.url_api_v}drivers/payrollReport/${type}${date}/${export_}`);
    }
  }

  /**
    * @returns Listado de punchs de un employee
  */
  getListDriversDetails(startDate: string, endDate: string, employee: string, type: string) {
    const date = type === 'custom' ? `:${startDate},${endDate}` : '';
    return this.http.get(`${environment.url_api_v}drivers/payrollDetail/${type}${date}/${employee ? employee : '0'}`);
  }

  /**
    * @returns Agregar Punch
  */
  saveDetail(data: any) {
    return this.http.post(`${environment.url_api_v}drivers/payroll/add`, data);
  }

  /**
    * @returns Editar punch
  */
  editDetail(data: any) {
    return this.http.put(`${environment.url_api_v}drivers/payroll/update`, data);
  }

  /**
    * @returns Borrar punch
  */
  deleteDetail(transactionid: string) {
    return this.http.delete(`${environment.url_api_v}drivers/payroll/${transactionid}`);
  }

  /**
    * @returns Marcar punch como revisado para quitar excepcion
  */
  reviewedDetail(data: any) {
    return this.http.post(`${environment.url_api_v}drivers/transactionReviewed`, data);
  }

  /**
    * @returns Obtener historial de un punch
  */
  getHistoryDriver(driver: any, date: any) {
    return this.http.get(`${environment.url_api_v}drivers/sundialTimeHistory/${driver}/${date}`);
  }

  /**
    * @returns Obtener listado de tipos de horas
  */
  getHoursTypes() {
    return this.http.get(`${environment.url_api_v}drivers/discriminators/all`);
  }

}
