import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { GridOptions } from 'ag-grid-community';
import { InvoiceReportService } from '../../../invoice-report.service';
import { WorkflowService } from '../../../../../../../services/billing/workflow.service';
import { takeUntil } from 'rxjs/operators';
import { IResponse } from '../../../../../../../CORE/interfaces/response.interface';
import { SweetAlertHelper } from '../../../../../../../CORE/helpers/sweet-alert-helper.service';
import { IPayloadBatch } from '../../../../../../../CORE/interfaces/workflow.interface';
import AgGridHelpers from '../../../../../../../helpers/ag-grid-helpers';

@Component({
  selector: 'app-info-batch-by-route',
  templateUrl: './info-batch-by-route.component.html',
  styleUrls: ['./info-batch-by-route.component.scss']
})
export class InfoBatchByRouteComponent implements OnInit, OnDestroy {
  busy: Subscription[] = [];
  destroy: Subject<boolean> = new Subject();

  gridBatchRoute: GridOptions;
  domLayout = 'autoHeight';
  dataByRoute = [];

  startDate: string;
  endDate: string;
  clientId: number;

  constructor(
    private invoiceReportService: InvoiceReportService,
    private workflowService: WorkflowService,
    private sweetAlertHelper: SweetAlertHelper
  ) {
    this.initGrid();
  }

  ngOnInit(): void {
    this.makeSubscriptions();
  }

  initGrid(): void {
    this.gridBatchRoute = <GridOptions>{
      enableFilter: true,
      enableSorting: true,
      enableColResize: true,
      suppressAggFuncInHeader: true,
      rowStyle: { textAlign: 'right' },
      onGridReady: (): void => {
        this.gridBatchRoute.api.sizeColumnsToFit();
        this.gridBatchRoute.api.setRowData([]);
        this.getInfoBatchByRoute();
      },
      groupMaintainOrder : true,
      autoGroupColumnDef: {
        headerName: 'Route',
        cellRendererParams: {
          suppressCount: true
        }
      },
      columnDefs: [
        {
          headerName: 'Route',
          colId: 'route_description',
          field: 'route_description',
          width: 120,
          sort: 'asc',
          rowGroup: true,
          hide: true,
          cellRendererParams: {
            suppressCount: false,
            checkbox: true,
            suppressDoubleClickExpand: true,
            suppressEnterExpand: true,
          },
        },
        {
          headerName: 'Range',
          colId: 'range',
          field: 'range',
          width: 80,
          sort: 'asc',
          cellStyle: {textAlign: 'left'},
        },
        {
          headerName: 'Manifest',
          colId: 'total_manifest',
          field: 'total_manifest',
          width: 80,
          aggFunc: 'sum',
          cellStyle: {textAlign: 'right'},
          cellRenderer: (params) => this.formatThousandsSeparator(params.value),
        },
        {
          headerName: 'Service Hours',
          colId: 'service_hours',
          field: 'service_hours',
          width: 80,
          cellRenderer: (params) => this.formatThousandsSeparator(params.value)
        },
        {
          headerName: 'Rate',
          colId: 'rate',
          field: 'rate',
          width: 80,
          cellRenderer: (params) => AgGridHelpers.CurrencyCellRenderer(params)
        },
        {
          headerName: 'Total',
          colId: 'total_amount',
          field: 'total_amount',
          aggFunc: 'sum',
          width: 100,
          cellRenderer: (params) => AgGridHelpers.CurrencyCellRenderer(params)
        },
        {
          headerName: 'Deductions',
          children: [
            {
              headerName: 'Lunch (hours)',
              colId: 'lunch',
              field: 'lunch',
              width: 120,
              cellRenderer: (params) => this.formatThousandsSeparator(params.value)
            }
          ]
        },
        {
          headerName: 'Interruptions',
          children: [
            {
              headerName: 'Time Interruption (hours)',
              colId: 'service_interruptions',
              field: 'service_interruptions',
              width: 120,
              cellRenderer: (params) => this.formatThousandsSeparator(params.value)
            },
            {
              headerName: 'Total Interruption',
              colId: 'total_interruptions',
              field: 'total_interruptions',
              width: 120,
              aggFunc: 'sum',
              cellRenderer: (params) => AgGridHelpers.CurrencyCellRenderer(params)
            }
          ]
        },
        {
          headerName: 'Total Amount',
          colId: 'total_amount_interruptions',
          field: 'total_amount_interruptions',
          width: 140,
          aggFunc: 'sum',
          cellRenderer: (params) => AgGridHelpers.CurrencyCellRenderer(params)
        },
      ],
    };
  }

  private makeSubscriptions(): void {
    this.subscribeToChangeSearchWorkflowBar();
    this.subscribeToReloadInfoBatch();
  }

  private subscribeToReloadInfoBatch(): void {
    this.invoiceReportService.reloadInfoBatchAction$.pipe(takeUntil(this.destroy))
      .subscribe({
        next: (reload: boolean): void => {
          if (reload) {
            this.getInfoBatchByRoute();
          }
        }
      });
  }

  private subscribeToChangeSearchWorkflowBar(): void {
    this.workflowService.infoByExternalFormToInvoiceReportAction$.pipe(takeUntil(this.destroy))
      .subscribe({
        next: ({date, clients_id}): void => {
          const {start_date, end_date} = date;
          this.startDate = start_date;
          this.endDate = end_date;
          this.clientId = clients_id;
        }
      });
  }

  private getInfoBatchByRoute(): void {
    const payload: IPayloadBatch = this.invoiceReportService
      .generatePayloadToBatch({
        date_start: this.startDate,
        date_end: this.endDate,
        client_id: this.clientId
      });

    this.busy.push(
      this.invoiceReportService.getBillingInfoBatchByRoute(payload).subscribe({
        next: ({data}: IResponse<any>): void => this.gridBatchRoute.api.setRowData(data),
        error: error => this.sweetAlertHelper.captureException(error)
      })
    );
  }

  private formatThousandsSeparator(number: number): string {
    return number ? number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }) : undefined;
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
    this.busy.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
