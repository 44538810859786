import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { GridOptions, RowDragEvent } from 'ag-grid-community';
import { TsoService } from '../../../../../../services/Tso.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-route-stops',
  templateUrl: './route-stops.component.html',
  styleUrls: ['./route-stops.component.scss']
})
export class RouteStopsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('modalAddStopRoute') modalAddStopRoute: any;
  @Input('route_id') route_id ?: any;
  @Input('dataBlock') dataBlock?: any;
  @Output() onClose? = new EventEmitter<any>();
  gridRouteStops: GridOptions;
  busy: Subscription;
  dataEdit: any;
  countGrid = 0;
  showInactive = false;
  sequences:  any  = [];
  route_blocks: any = [];
  formAssign: FormGroup;
  getContextMenuItems;
  tabSet = 1;
  route_org_id = null;

  constructor(private tsoService: TsoService, private fb: FormBuilder) {
    this.setConfigGrid();
    this.createFormAssign();
  }

  ngOnInit() {
    this.route_org_id = this.dataBlock.id;
    if (this.dataBlock && this.dataBlock.route_blocks.length > 0) {
      this.route_blocks = this.dataBlock.route_blocks;
    }
  }

  ngOnDestroy() {
    this.busy.unsubscribe();
  }

  ngAfterViewInit() {}

  setConfigGrid() {
    this.gridRouteStops = <GridOptions> {
      columnDefs: [
        { headerName: '', width: 40, cellRenderer: ((params) => {
          if (params.data.segment === 1) {
            return `<i class="fa fa-chevron-circle-right" aria-hidden="true"></i>`;
          } else if (params.data.segment === 2) {
            return `<i class="fa fa-chevron-circle-left" aria-hidden="true"></i>`;
          }
        })},
        { headerName: 'Seq', field: 'sequence', width: 90, rowDrag: true, checkboxSelection: true },
        { headerName: 'Stop', field: 'stop_number', width: 70 },
        { headerName: 'Description', field: 'stop.description', width: 160 },
        { headerName: 'Street', field: 'stop.street', width: 180 },
        { headerName: 'City', field: 'stop.city', width: 70 },
        { headerName: 'St', field: 'stop.state', width: 48 },
        { headerName: 'Block', valueGetter: this.routeDescription, width: 160 },
        {headerName: '', width: 40, colId: 'edit', template: `
        <span>
        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
        </span>
      ` }
      ],
      enableFilter: true,
      rowDragManaged: true,
      animateRows: true,
      rowSelection: 'multiple',
      deltaRowDataMode: true,
      getRowNodeId: function (data) {
        return data.id;
      },
      onGridReady: (event) => {
        this.loadData(false);
      }, onFilterChanged: (event) => {
        this.countGrid = this.gridRouteStops.api.getDisplayedRowCount();
      },
      onRowDragEnd: (event: RowDragEvent) => {
        this.sequences = this.move(this.sequences, Number(event.node.id), Number(event.overIndex));
        this.getSequences(this.sequences);
        this.updateSequence(this.sequences);
      },
      getRowStyle: (params) => this.getRowStyleScheduled(params),
    };
    this.getContextMenuItems = (params) => {
      const x = this.gridRouteStops;
      const result = [
        {
          name: 'Direction Up',
          cssClasses: ['redFont', 'bold'],
          icon: '<i class="fa fa-long-arrow-up"></i>',
          action: (event) => {
            this.changeDirection(0, params);
          }
        },
        {
          name: 'Direction Down',
          cssClasses: ['redFont', 'bold'],
          icon: '<i class="fa fa-long-arrow-down"></i>',
          action: () => {
            this.changeDirection(1, params);
          }
        },
        'separator',
        'copy',
        'export',
      ];
      return result;
    };
  }

  getRowStyleScheduled(params) {
    if (params) {
      if (params.data.segment === 1) {
        return { backgroundColor: '#e4c6ff' };
      } else if (params.data.segment === 2) {
        return { backgroundColor: '#9DE6C8'};
      }
    }
  }

  routeDescription(row: any) {
    return row.data.route_block ?  `${row.data.route_block.description} ${row.data.route_block.block_code}` : '';
  }

  loadData(status: boolean) {
    this.busy = this.tsoService.getStopsByRoute(this.route_id, status).subscribe((resp: any) => {
      // this.gridRouteStops.api.setRowData(resp);
      this.getSequences(resp);
      // this.sequences = resp;
      this.countGrid = resp.length;
    });
  }

  onReload(event) {
    if (event) {
      this.loadData(this.showInactive);
    }
  }

  onCellClicked(event) {
    if (event.colDef.colId === 'edit') {
      this.dataEdit = event.data;
      this.modalAddStopRoute.open();
    }
  }

  getSequences(data: any[]) {
    data.forEach((item: any, index: number) => {
      item.sequence = index + 1;
    });
    this.sequences = data;
    this.gridRouteStops.api.refreshCells();
    this.formAssign.controls['route_block_id'].setValue(undefined);
  }

  move(arr, old_index, new_index) {
    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      let k = new_index - arr.length;
      while ((k--) + 1) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  updateSequence(data: any[]) {
    const obj = [];
    data.forEach((row: any) => {
      obj.push({
        'sequence': Number(row.sequence),
        'id': Number(row.id)
      });
    });
    this.putRoutesTsoStopsMassive(obj);
  }

  putRoutesTsoStopsMassive(data: any) {
    this.busy = this.tsoService.putRoutesTsoStopsMassive(data).subscribe((resp: any) => {
      this.loadData(this.showInactive);
    });
  }

  createFormAssign() {
    this.formAssign = this.fb.group({
      'route_block_id': ['', Validators.required],
      'ids': [null, Validators.required]
    });
  }

  onSubmit() {
    const ids = this.gridRouteStops.api.getSelectedNodes().map((row) => {
      return row.data.id;
    });
    this.formAssign.controls['ids'].setValue(ids);
    this.busy = this.tsoService.putTsoAssignBlocks(this.formAssign.value).subscribe((resp: any) => {
      console.log(resp);
      this.loadData(false);
    });
  }

  changeDirection(type: number, event: any) {
    const data = {
      id: event.node.data.id,
      direction: type
    };
    this.busy = this.tsoService.putChangeDirection(data).subscribe((resp: any) => {
      this.loadData(false);
      this.gridRouteStops.api.refreshCells();
    });
  }

}
