import { Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { DriverService } from './../../../../../services/fleet/driver.service';

@Component({
  selector: 'app-grid-drivers',
  templateUrl: './grid-drivers.component.html',
  styleUrls: ['./grid-drivers.component.scss']
})
export class GridDriversComponent implements OnInit {

  dataGrid: any[];
  busy: any;
  gridOptions: GridOptions;

  constructor( private driverService: DriverService) {
    this.setGridHeader();
    this.dataGrid = [];
  }

  ngOnInit() {
    this.loadDrivers();
  }

  setGridHeader() {
    this.gridOptions = <GridOptions> {
      columnDefs: [
        { headerName: 'Last Name', field: 'last_name', width: 140, tooltipField: 'last_name' },
        { headerName: 'First Name', field: 'first_name', width: 140 },
        { headerName: 'Phone', field: 'phone', width: 110 }
      ],
    };
  }

  loadDrivers() {
    this.busy = this.driverService.getDriversStatus(false).subscribe((resp: any) => {
      this.dataGrid = resp;
    });
  }

  onFilterTextBoxChanged(value: string) {
    this.gridOptions.api.setQuickFilter(value);
  }

  OnCellDoubleClicked(event: any) {
    this.driverService.driverid$.next(event.data);
  }
}
