import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { CONSTANT } from '../app.constants';

@Injectable()
export class InterceptorService implements HttpInterceptor {
  private _serviceAuth: AuthService;

  constructor(private injector: Injector) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this._serviceAuth = this.injector.get(AuthService);
    let tem;
    if (req.url.includes(CONSTANT.OSRM.URL.GET_ROUTE)) {
      return next.handle(req);
    }

    if (this._serviceAuth.getTokenLocalStorage() === null) {
      tem = req.clone({
        headers: req.headers
          .set('Content-Type', 'application/json')
          .append('Accept', 'application/json')
          .append('Access-Control-Allow-Origin', '*')
      });
    } else {
      tem = req.clone({
        headers: req.headers
          .set('Content-Type', 'application/json')
          .append('Accept', 'application/json')
          .append('Access-Control-Allow-Origin', '*')
          .append(
            'Authorization',
            'Bearer ' + this._serviceAuth.getTokenLocalStorage()
          )
          .append('namedb', this._serviceAuth.getNameDbLocalStorage())
      });
    }
    return next.handle(tem);
  }
}
