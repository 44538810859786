import { Directive, forwardRef } from '@angular/core';
import {
  NG_VALIDATORS,
  Validator,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';

@Directive({
  selector: '[appHour]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: HourDirective, multi: true }
  ]
})
export class HourDirective implements Validator {
  validate(c: AbstractControl): { [key: string]: any } {
    console.log('Epppaaaaa');
    return null;
  }
}
