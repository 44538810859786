import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { RouteGroupService } from '../../../../../services/fleet/route-group.service';
import AgGridHelpers from '../../../../../helpers/ag-grid-helpers';

@Component({
  selector: 'app-list-route-group',
  templateUrl: './list-route-group.component.html',
  styleUrls: ['./list-route-group.component.scss']
})
export class ListRouteGroupComponent implements OnInit, OnChanges {

  @Output() recordSelected = new EventEmitter<any>();
  @Input() listRouteGroup: any[] = [];
  grid: GridOptions;
  busy: Subscription;
  addStatus = true;

  constructor(private routeGroupService: RouteGroupService) {
    this.onInitGrid();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['listRouteGroup'] && this.listRouteGroup.length) {
      this.grid.api.setRowData(this.listRouteGroup);
    }
  }

  ngOnInit() {
  }



  onInitGrid() {
    this.grid = <GridOptions> {
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      rowSelection: 'single',
      enableFilter: true,
      onGridReady: () => {
        this.grid.api.setRowData(this.listRouteGroup);
      },
      columnDefs: [
        { headerName: 'Description', field: 'description', width: 400 },
        {
          headerName: 'Active',
          field: 'active',
          width: 100,
          cellRenderer: params => AgGridHelpers.statusRecord(params.data.active)
        }
      ]
    };
  }

  onRowDoubleclicked(event) {
    if (event.data.id) {
      this.recordSelected.emit(event.data);
    }
  }

  changeShowStatusEvent(status: boolean) {
    this.routeGroupService.reloadListRouteGroup.next(!status);
  }


  onNew() {
    this.routeGroupService.openModal.next(true);
  }

}
