import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/Rx';
import { IRouteGroup } from '../../../app/pages/fleet/clients/components/fee-schedule/fee-schedule.interface';
import { CONSTANT } from '../../app.constants';

@Injectable({
  providedIn: 'root'
})
export class RouteGroupService {
  reloadListRouteGroup = new BehaviorSubject<boolean>(true);
  reloadListRouteGroup$ = this.reloadListRouteGroup.asObservable();
  openModal: Subject<boolean> = new Subject<boolean>();

  constructor(private http: HttpClient) { }

  list() {
    const url = CONSTANT.ROUTE_GROUP.URL.BASE;
    return this.http.get(url);
  }

  getRouteGroupByClient(clientId: number) {
    const url = `${CONSTANT.ROUTE_GROUP.URL.ROUTE_BY_CLIENT}/${clientId}`;
    return this.http.get(url);
  }

  listFilter() {
    const url = CONSTANT.ROUTE_GROUP.URL.BASE;
    return this.http.get(url);
  }

  searchRouteIsAssigned(route_id: number) {
    const url = `${CONSTANT.ROUTE_GROUP.URL.ROUTE_IS_ASSIGNED}/${route_id}`;
    return this.http.get(url);
  }

  store(data: any) {
    const url = CONSTANT.ROUTE_GROUP.URL.BASE;
    return this.http.post(url, data);
  }

  update(data: any, id: number) {
    const url = `${CONSTANT.ROUTE_GROUP.URL.BASE}/${id}`;
    return this.http.put(url, data);
  }

  get(id: number) {
    const url = `${CONSTANT.ROUTE_GROUP.URL.BASE}/${id}`;
    return this.http.get(url);
  }

  postRouteGroup(data: IRouteGroup) {
    const url = CONSTANT.ROUTE_GROUP.URL.ADD_ROUTE_GROUP;
    return this.http.post(url, data);
  }

  putRouteGroup(data: IRouteGroup) {
    const id = data.id;
    delete data.id;
    const url = `${CONSTANT.ROUTE_GROUP.URL.UPDATED_ROUTE_GROUP}/${id}`;
    return this.http.put(url, data);
  }

  getRoutesGroupByFeeSchedule(feeScheduleId: number) {
    const url = `${CONSTANT.ROUTE_GROUP.URL.GET_ROUTES_BY_GROUP}/${feeScheduleId}`;
    return this.http.get(url);
  }

  routeGroupFeeScheduleDelete(fee_schedule_id: string, route_group_id: string) {
    const url = `${CONSTANT.ROUTE_GROUP.URL.DELETE_ROUTE_GROUP}/${fee_schedule_id}/${route_group_id}`;
    return this.http.delete(url);
  }

}
