import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DashboardLsfDataService } from '../../../dashboard-lsf.data.service';

@Component({
  selector: 'app-routevisible',
  templateUrl: './routevisible.component.html',
  styleUrls: ['./routevisible.component.scss']
})
export class RoutevisibleComponent implements OnInit {

  public busy: Subscription;
  @Output() onHide?: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  public data: any = {};
  private apiResponse: any = {};
  private endpoint = 'routes/users/profile';

  constructor(private  _dataService: DashboardLsfDataService, public toastr: ToastrService) {
  }

  ngOnInit() {
    this.loadInfo();
  }

  onCancel() {
    this.onHide.emit(true);
  }

  onSave() {
    this.busy = this._dataService.postData(this.data, this.endpoint).subscribe(
      data => {
        this.apiResponse = data;
        this.apiResponse.process = true;
        if (this.apiResponse.transaction) {
          this.onCancel();
          this.notify.emit(this.apiResponse);
        } else {
          this.toastr.error(this.apiResponse.message);
        }
      }
    );
  }


  loadInfo() {
    this.busy = this._dataService.getData(this.endpoint).subscribe(
      data => {
        this.data.routes_profiles = data;
      }
    );
  }

}
