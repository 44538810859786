import {
  ChargeBillingStatus,
  IBatchCharge,
  IBatchChargeStatus,
  IStatusOrTypes,
  IConceptValuePayload
} from '../../../../../../CORE/interfaces/invoice-report.interface';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { InvoiceReportService } from '../../invoice-report.service';
import { takeUntil } from 'rxjs/operators';
import { forkJoin, Subject, Subscription} from 'rxjs';
import { IResponse, ResponseStatus } from '../../../../../../CORE/interfaces/response.interface';
import { BatchInvoicesStatusService } from '../../../../../../services/billing/batch/batch-invoices-status.service';
import { ClientService } from '../../../../../../services/fleet/client.service';
import { SweetAlertHelper } from '../../../../../../CORE/helpers/sweet-alert-helper.service';
import { IRoute } from '../../../../../../CORE/interfaces/invoice-report.interface';
import { WorkflowService } from '../../../../../../services/billing/workflow.service';
import { Dictionary, isNull, omitBy } from 'lodash';
import { InvoiceStatusEnum } from '../../../../../../CORE/enums/InvoiceStatusEnum.enum';

@Component({
  selector: 'app-add-batch-charge',
  templateUrl: './add-batch-charge.component.html',
  styleUrls: ['./add-batch-charge.component.scss']
})
export class AddBatchChargeComponent implements OnInit, OnDestroy {
  formCharge: FormGroup;
  destroy: Subject<boolean> = new Subject<boolean>();
  busy: Subscription [] = [];

  chargeTypes: IStatusOrTypes[];
  chargeStatus: IBatchChargeStatus[];
  listOfRoutes: IRoute [];
  clientId: number;
  startDate: string;
  endDate: string;
  repeatCharge = 0;

  constructor(
    private fb: FormBuilder,
    private invoiceReportService: InvoiceReportService,
    private sweetAlertHelper: SweetAlertHelper,
    private batchInvoicesStatusService: BatchInvoicesStatusService,
    private clientService: ClientService,
    private workflowService: WorkflowService
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.makeSubscriptions();
    this.fillSelectForm();
  }

  initForm(): void {
    this.formCharge = this.fb.group({
      id: [''],
      batch_id: ['', Validators.required],
      invoice_num: ['', Validators.required],
      invoice_date: ['', Validators.required],
      total_amount: ['', Validators.required],
      batch_charge_type_id: ['', Validators.required],
      invoice_status_id: [InvoiceStatusEnum.INVOICES , Validators.required],
      route_id: ['']
    });
  }

  private makeSubscriptions(): void {
    this.invoiceReportService.chargeDataToEditAction$
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (chargeToEdit: IBatchCharge): void => {
          if (chargeToEdit) {
            const cleanChargeToEdit: Dictionary<any> = omitBy(chargeToEdit, isNull);
            this.formCharge.patchValue(cleanChargeToEdit);
          }
        }
      });

    this.invoiceReportService.chargeDataToAddAction$
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (batchId: number): void => {
          if (batchId) {
            this.formCharge.patchValue({batch_id: batchId});
          }
        }
      });

    this.workflowService.infoByExternalFormToInvoiceReportAction$
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: ({date, clients_id}): void => {
          const {start_date, end_date} = date;
          this.startDate = start_date;
          this.endDate = end_date;
          this.clientId = clients_id;
        }
      });

    this.invoiceReportService.validateChargeRepeat(this.formCharge.get('batch_id').value).subscribe({
      next: (repeat: number): void => {
        if (repeat) {
          this.repeatCharge = repeat;
        }
      },
      error: error => this.sweetAlertHelper.captureException(error)
    });
    this.subscribeToChangesOnRouteAndTypeCharge();
  }

  private subscribeToChangesOnRouteAndTypeCharge(): void {
    const batchChargeTypeIdControl = () => this.getControl('batch_charge_type_id');

    const routeIdControl = () => this.getControl('route_id');

    const handleControlChanges = (batch_charge_type_id, route_id): void => {
      const payload: IConceptValuePayload = {
        date_start: this.startDate,
        date_end: this.endDate,
        client_id: this.clientId,
        batch_charge_type_id,
        route_id
      };

      if (batch_charge_type_id) {
        this.getConceptValueAndPatchInTotalAmount(payload);
      }
    };

    batchChargeTypeIdControl().valueChanges
      .subscribe({
        next: (batch_charge_type_id): void => {
          const route_id = routeIdControl().value;
          handleControlChanges(batch_charge_type_id, route_id);
        }
      });

    routeIdControl().valueChanges
      .subscribe({
        next: (route_id): void => {
          const batch_charge_type_id = batchChargeTypeIdControl().value;
          handleControlChanges(batch_charge_type_id, route_id);
        }
      });
  }

  private getConceptValueAndPatchInTotalAmount(payload: IConceptValuePayload): void {
    this.busy.push(
      this.invoiceReportService.getConceptValue(payload)
        .subscribe({
          next: ({data: total_amount}: IResponse<number>): void => this.formCharge.patchValue({total_amount})
        })
    );
  }

  private fillSelectForm(): void {
    this.busy.push(
      forkJoin([
        this.invoiceReportService.getTypeCharges(),
        this.batchInvoicesStatusService.getInvoiceStatus(),
        this.clientService.getRoutesByClient(this.clientId)
      ]).subscribe({
        next: (response): void => {
          const [
            {data: typeCharges},
            {data: chargeStatus},
            {data: listOfRoutes}
          ] = response;

          this.chargeTypes = typeCharges;
          this.chargeStatus = chargeStatus.filter((el: IBatchChargeStatus): boolean => el.description !== ChargeBillingStatus.Original);
          this.listOfRoutes = listOfRoutes;
        },
        error: error => this.sweetAlertHelper.captureException(error)
      })
    );
  }

  onSave(): void {
    const { id } = this.formCharge.value;
    const payload = this.formCharge.value;
    if (this.repeatCharge > 1) {
      this.sweetAlertHelper.createCustomAlert({
        title: `Currently there are ${this.repeatCharge} charges created with all routes and of the same type. Are you sure?`,
        text: 'Do you confirm the charge creation?',
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Continue'
      }).then((result) => {
        if (result.value) {
          this.createOrEdit(payload, id);
        }
      });
    } else {
      this.createOrEdit(payload, id);
    }
  }
  private createOrEdit(payload, id) {
    const addBatchCharge = (): void => {
      const action = 'created';
      this.invoiceReportService.createBillingChargeBatch(payload).subscribe({
        next: ({ status }: IResponse<IBatchCharge>): void => {
          if (status === ResponseStatus.SUCCESS) {
            this.updateGrid(action);
          }
        },
        error: (error) => this.sweetAlertHelper.captureException(error)
      });
    };

    const editBatchCharge = (): void => {
      const action = 'updated';
      this.invoiceReportService.updateBillingChargeBatch(id, payload).subscribe({
        next: ({ status }: IResponse<IBatchCharge>): void => {
          if (status === ResponseStatus.SUCCESS) {
            this.updateGrid(action);
          }
        },
        error: (error) => this.sweetAlertHelper.captureException(error)
      });
    };

    id ? editBatchCharge() : addBatchCharge();
  }
  private updateGrid(action: string): void {
    this.sweetAlertHelper.createCustomAlert({
      type: 'success',
      title: `Charge has been ${action}.`,
      showConfirmButton: true
    }).then(
      () => this.invoiceReportService.reloadChargeGrid(true)
    );
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
    this.busy.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  get formIsInvalid(): boolean {
    return !this.formCharge.valid;
  }

  private getControl(controlName: string): AbstractControl {
    return this.formCharge.get(controlName);
  }
}
