import { Component, OnInit, OnDestroy } from '@angular/core';
import { DashboardLsfDataService } from '../../dashboard-lsf.data.service';
import { GridOptions } from 'ag-grid-community';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-info-schedule',
  templateUrl: 'info-schedule.component.html'
})

export class InfoScheduleComponent implements OnInit {
  gridSchedule: GridOptions;
  busy: Subscription;

  constructor(
    private dashboardLsfDataService: DashboardLsfDataService,
  ) {
    this.setConfigGrid();
    this.dashboardLsfDataService.viewSsheduleSelected$.subscribe(
      (res: any) => {
        console.log((res));

        this.gridSchedule.api.setRowData([]);
        this.loadData(res);
      }
    );
  }

  ngOnInit() { }

  loadData(info) {
    this.busy = this.dashboardLsfDataService.getScheduleDriver(info.data.driver_id, info.info.startDate, info.info.endDate).subscribe(
      (res: any) => {
        this.gridSchedule.api.setRowData(res.data);
      }
    );
  }

  setConfigGrid() {
    this.gridSchedule = <GridOptions>{
      enableSorting: true,
      enableColResize : true,
      sortingOrder: ['desc', 'asc', null]
    };
    this.gridSchedule.columnDefs = [
      { headerName: 'Day', width: 100, field: 'week_day_id',
        cellRenderer: (params: any) => {
          return moment(params.data.dateofservice).format('dddd');
        }
      },
      { headerName: 'Date', width: 100, cellStyle: {'text-align': 'center'}, field: 'dateofservice' },
      { headerName: 'Sch-In', width: 80, cellStyle: {'text-align': 'center'}, field: 'report_time' },
      { headerName: 'Leave Yard', width: 100, cellStyle: {'text-align': 'center'}, field: 'leave_yard_time' },
      { headerName: 'Start', width: 80, cellStyle: {'text-align': 'center'}, field: 'start_time' },
      { headerName: 'End', width: 80, cellStyle: {'text-align': 'center'}, field: 'end_time' },
      { headerName: 'Sch-Out', width: 100, cellStyle: {'text-align': 'center'}, field: 'punch_out_time' },
      { headerName: 'Route', width: 120, cellStyle: {'text-align': 'center'}, field: 'route.description',
        cellRenderer: (params: any) => {
          return params.data.route ? (params.data.route.description + (params.data.route.route_code ? ' - ' + params.data.route.route_code : '')) : '';
        }
      },
      { headerName: 'Vehicle', width: 120, cellStyle: {'text-align': 'center'}, field: 'vehicle.description',
        cellRenderer: (params: any) => {
          return params.data.vehicle ? params.data.vehicle.description : '';
        }
      },
      { headerName: 'Block Status', width: 120, cellStyle: {'text-align': 'center'}, field: 'blockstatu.description',
        cellRenderer: (params: any) => {
          return params.data.blockstatu ? params.data.blockstatu.description : '';
        }
      },
    ];
  }
}
