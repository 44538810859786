import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { VehicleService } from '../../services/services.index';
import Swal from 'sweetalert2';
import { Mask } from '../../tools/mask';
import { ToastrService } from 'ngx-toastr';
import { IDeviceVehicle, IVehicleGps } from '../../CORE/interfaces/vehicle.interface';

@Component({
  selector: 'app-vehicle-management',
  templateUrl: './vehicle-management.component.html',
  styleUrls: ['./vehicle-management.component.scss']
})
export class VehicleManagementComponent implements OnInit, OnDestroy {

  @Output() onHide?: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onTitle?: EventEmitter<string> = new EventEmitter<string>();
  @Output() notify?: EventEmitter<any> = new EventEmitter<any>();
  @Input() vehicleSekected: number;
  @Input() vehiclesGps?: IVehicleGps[];

  busy: Subscription[] = [];
  vehiclesTypes: any = [];

  vehiclesGpsApc: IVehicleGps[] = [];
  vehicleDevice: IDeviceVehicle[] = [];
  vehiclesStatus: any = [];
  apiResponse: any = {};
  mask = new Mask();
  formData: FormGroup;

  constructor(
    private fb: FormBuilder,
    private serviceVehicle: VehicleService,
    private toastr: ToastrService,
  ) {
    this.loadForm();
  }

  ngOnInit() {
    this.subscribeObservables();
    this.vehiclesGpsApc = this.vehiclesGps;
    if (this.vehicleSekected !== 0) {
      this.loadInfo(this.vehicleSekected);
    } else {
      this.onSetTitle('New Vehicle');
    }
  }

  subscribeObservables() {
    this.busy.push(
      this.serviceVehicle.getAllVehicleStatus().subscribe(resp => {
        if (resp && resp.length) {
          this.vehiclesStatus = resp;
        }
      }),
      this.serviceVehicle.getAllVehicleTypes().subscribe(resp => {
        if (resp && resp.length) {
          this.vehiclesTypes = resp;
        }
      }),
      this.serviceVehicle.getDeviceVehicle().subscribe(resp => {
        if (resp && resp.data) {
          this.vehicleDevice = resp.data;
        }
      })
    );
  }

  ngOnDestroy() {
    this.busy.forEach(subscription => subscription.unsubscribe());
  }

  loadForm() {
    this.formData = this.fb.group({
      description: [null, Validators.required],
      active: [true],
      vehicle_type_id: [null, Validators.required],
      vehicle_status_id: [null, Validators.required],
      vehicle_profiles: [null],
      rta: [null],
      reason: [null],
      vin: [null],
      tag: [null],
      desc_billing: [null],
      gps_id: [null],
      gps_apc_id: [null],
      year: [null],
      make: [null],
      registration_expire: [null],
      capacity_type: [null],
      capacity_wheelchair: [null],
      traccar_device: [null]
    });
  }

  onCancel() {
    this.onHide.emit(true);
  }

  onSetTitle(title: string) {
    this.onTitle.emit(title);
  }

  onSave(validForm) {
    if (validForm) {
      if (this.formData.controls['active'].value) {
        this.saveFull();
      } else {
        Swal.fire({
          text: 'This operation, will remove the vehicle from the master blocks, confirm the action?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes!'})
          .then((result) => {
            if (result.value) {
              this.saveFull();
            }
          });
      }
    } else {
      this.toastr.warning('Please complete the required fields');
    }
  }

  saveFull() {
    let data: any = Object.assign(this.formData.getRawValue());
    data.vehicle_profiles = data.vehicle_profiles.map((item: any) => {
      if (item.route_id) {
        return item.route;
      } else {
        return item;
      }
    });
    if (this.vehicleSekected === 0) {
      this.save(data);
    } else {
      delete data.vehicletypes;
      delete data.vehiclestatus;
      delete data.id;
      this.update(data);
    }
  }

  /***
   * @description
   * @param {number} vehicle_id
   */
  loadInfo(vehicle_id: number) {
    this.busy.push(this.serviceVehicle.getVehicle(vehicle_id).subscribe(data => {
      this.formData.patchValue({
        id: data.id,
        description: data.description,
        active: data.active,
        vehicle_type_id: data.vehicle_type_id,
        vehicle_status_id: data.vehicle_status_id,
        vehicle_profiles: data.vehicle_profiles,
        rta: data.rta,
        reason: data.reason,
        vin: data.vin,
        tag: data.tag,
        desc_billing: data.desc_billing,
        gps_id: data.gps_id === '' ? null : data.gps_id,
        gps_apc_id: data.gps_apc_id === '' ? null : data.gps_apc_id,
        year: data.year,
        make: data.make,
        registration_expire: data.registration_expire,
        capacity_type: data.capacity_type,
        capacity_wheelchair: data.capacity_wheelchair,
        traccar_device: data.traccar_device === '' ? null : data.traccar_device
      });
      this.onSetTitle('Edit Vehicle - ' + data.description);
    }));
  }

  /***
   * @description
   */
  save(data) {
    this.busy.push(this.serviceVehicle.storeVehicle(data).subscribe(res => {
      this.apiResponse = res;
      if (this.apiResponse.transaction) {
        this.loadForm();
        Swal.fire(
          'Success!',
          this.apiResponse.message,
          'success'
        ).then(() => {
          this.onCancel();
          this.notify.emit();
        });
      } else {
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: this.apiResponse.message + '.<br> Log : ' + this.apiResponse.master.result
        });
      }
    }));
  }

  /***
   * @description
   */
  update(data) {
    this.busy.push(this.serviceVehicle.updateVehicle(data, this.vehicleSekected).subscribe(res => {
      this.apiResponse = res;
      if (this.apiResponse.transaction) {
        this.loadForm();
        Swal.fire(
          'Success!',
          this.apiResponse.message,
          'success'
        ).then(() => {
          this.onCancel();
          this.notify.emit();
        });
      } else {
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: this.apiResponse.message + '.<br> Log : ' + this.apiResponse.master.result
        });
      }
    }));
  }

  onChangeDescription(event: any) {
    this.formData.controls['rta'].setValue(event.target.value);
  }
}
