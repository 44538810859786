import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs/Rx';
import { GridOptions } from 'ag-grid-community';
import { BatchesService } from '../../../../services/billing/batches.service';

@Component({
  selector: 'app-manifests',
  templateUrl: './manifests.component.html',
  styleUrls: ['./manifests.component.scss']
})
export class ManifestsComponent implements OnInit, OnDestroy, OnChanges {

  busy: Subscription;
  grid: GridOptions;
  @Input() clearGrid ?: any;
  count: number;

  constructor(private batchesService: BatchesService) {
    this.initGrid();
  }

  ngOnInit() {
    this.batchesService.load_batch$.subscribe((baych_id: any) => {
      this.busy = this.batchesService.getManifests(baych_id).subscribe((resp: any) => {
        if (this.grid.api) {
          this.grid.api.setRowData(resp);
          this.count = resp.length;
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['clearGrid']) {
      if (this.clearGrid) {
        this.grid.api.setRowData([]);
      }
    }
  }

  ngOnDestroy(): void {
    this.busy ? this.busy.unsubscribe() : null;
  }

  initGrid() {
    this.grid = <GridOptions>{
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      rowSelection: 'single',
      enableRangeSelection: true,
      enableFilter: true,
      onGridReady: () => {
        this.grid.api.setRowData([]);
      },
      columnDefs: [
        { headerName: 'Manifest ID', field: 'manifest_id', width: 100 },
        { headerName: 'Route', field: 'route', width: 100 },
        { headerName: 'Block', field: 'block', width: 100 },
        { headerName: 'Date', field: 'date', width: 100 },
        { headerName: 'Vehicle', field: 'vehicle', width: 100 },
        { headerName: 'Type', field: 'vehicle_type', width: 100 },
        { headerName: 'Run', field: 'run', width: 100 },
        { headerName: 'Start Time', field: 'start_time', width: 100 },
        { headerName: 'End Time', field: 'end_time', width: 100 },
        { headerName: 'Leave Yard Time', field: 'leave_yard_time', width: 100 },
        { headerName: 'First Stop Time', field: 'first_stop_time', width: 100 },
        { headerName: 'Last Stop Time', field: 'last_stop_time', width: 100 },
        { headerName: 'Return to Yard Time', field: 'return_to_yard_time', width: 100 },
        { headerName: 'Leave Yard Odometer', field: 'leave_yard_odometer', width: 100 },
        { headerName: 'First Stop Odometer', field: 'first_stop_odometer', width: 100 },
        { headerName: 'Last Stop Odometer', field: 'last_stop_odometer', width: 100 },
        { headerName: 'Return to Yard Odometer', field: 'return_to_yard_odometer', width: 100 },
        { headerName: 'AMB', field: 'amb', width: 100 },
        { headerName: 'wc', field: 'wc', width: 100 },
        { headerName: 'STR', field: 'str', width: 100 }
      ]
    };
  }

}
