import Swal from 'sweetalert2';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GridOptions } from 'ag-grid-community';
import * as moment from 'moment';
import { merge, Subscription } from 'rxjs';
import { TimecardsService } from './../../../services/payroll/timecards.service';
declare var $: any;

/**
  * Componente para gestionar el modulo de timecard de TA
  * Este componente corresponde a la vista donde se gestionara el modulo de TA y su diferentes partes.
  *
  * Principales funciones
  * * Cargar listado de employees.
*/
@Component({
  selector: 'app-driver-master-schedule',
  templateUrl: './driver-master-schedule.component.html',
  styleUrls: ['./driver-master-schedule.component.scss']
})
export class DriverMasterScheduleComponent implements OnInit {
  busy: Subscription[] = [];
  showAll = false;
  showExc = false;
  gridEmployee: GridOptions;
  countRecord: number;
  openedSchedule = false;
  periodSearch: string;

  cardSelected: any = {};

  filterSearch: any = {};

  constructor(
    private fb: FormBuilder,
    private timecardsService: TimecardsService,
  ) {
    this.setConfigGrid();
  }

  ngOnInit() {
    this.timecardsService.successDetailChanged$.subscribe(
      res => {
        this.loadDeails(this.cardSelected);
      }, err => {
        console.log(err);
      }
    );
  }

  changeDataGrid(data) {
    console.log(data);

    this.filterSearch = data.info;
    this.gridEmployee.api.setRowData(data.data);
    this.autoSizeAll();
  }

  myValueFormatter(data: any) {
    return `${data.name}`;
  }

  setConfigGrid() {
    this.gridEmployee = <GridOptions>{
      enableSorting: true,
      enableColResize : true,
      sortingOrder: ['desc', 'asc', null],
      onFilterChanged: () => {
        this.countRecord = this.gridEmployee.api.getDisplayedRowCount();
      }
    };
    this.gridEmployee.columnDefs = [
      { headerName: 'ID', field: 'employee_id', width: 70 },
      { headerName: 'Dri', field: 'driver', width: 60, cellRenderer: params => params.data.driver ? 'Yes' : 'No' },
      { headerName: 'Employee', width: 120, field: 'employee_name' },
      { headerName: 'Reg', width: 65, cellStyle: {'text-align': 'right'}, field: 'reg' },
      { headerName: 'OT', width: 65, cellStyle: {'text-align': 'right'}, field: 'ot' },
      { headerName: 'Total', width: 70, cellStyle: {'text-align': 'right'}, field: 'total' },
      { headerName: 'Exc', width: 60, field: 'has_exception',
      cellRenderer: params => {
        return params.data.has_exception ? '<button type="button" class="btn btn-danger btn-table-exc">&nbsp;</button>' : '';
      }, }
    ];
    this.gridEmployee.getRowClass = params => {
      return this.validateSelectColor(params);
    };
    this.gridEmployee.onRowDoubleClicked = params => {
      this.loadDeails(params.data);
    };
  }
  private validateSelectColor(params) {
    if (params.data.missing_punch) {
      return 'row_missingpunch';
    }else {
      return '';
    }
  }

  autoSizeAll() {
    const allColumnIds = [];
    this.gridEmployee.columnApi.getAllColumns().forEach(function(column: any) {
      allColumnIds.push(column.colId);
    });
    this.gridEmployee.columnApi.autoSizeColumns(allColumnIds);
  }


  loadDeails(data) {
    const dataForm = this.filterSearch;
    this.openedSchedule = true;
    this.cardSelected = data;
    this.timecardsService.schDriverSelected$.emit({data, details: {}});
    this.busy.push(this.timecardsService.getListDriversDetails(
      dataForm.startDate,
      dataForm.endDate,
      data.id,
      dataForm.filter
    ).subscribe(
      (res: any) => {
        const details = res;
        this.timecardsService.schDriverSelected$.emit({data, details});
      }, err => {
        console.log(err);
        Swal.fire('!Error', err.message, 'error');
      }
    ));
  }

  closeDeails(data?) {
    this.openedSchedule = false;
  }

}
