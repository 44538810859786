import { InterruptionService } from './../../../../../services/payroll/interruption.service';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { TsoAssignVehicleService, TsoService } from '../../../../../services/services.index';
import { DashboardLsfDataService } from '../../../dashboard-lsf.data.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { BlockStatusEnum } from '../../../../../CORE/enums/BlockStatusEnum.enum';

interface AssignVehicle {
  token: string;
  routeID: number;
  unitID: number;
  driverID: number;
  scheduleID: number;
  lan: string;
  fixedSchedule: boolean;
  schedule_detail_id: number;
  schedule_id_assigned: number;
  active: boolean;
  details?: string;
}

@Component({
  selector: 'app-interruption',
  templateUrl: './interruption.component.html',
  styleUrls: ['./interruption.component.scss']
})
export class InterruptionComponent implements OnInit, OnDestroy {

  @ViewChild('used_replacement') el: ElementRef;
  @Input() schedule_seleccionado?: any;

  public busy: Subscription[] = [];
  public gridInterruptions: GridOptions;
  public gridColumnApi;
  public gridApi;
  public data: any = {};
  supervisor_name = '';
  vehicle_name = '';
  driver_name = '';
  private apiResponse: any = {};
  public supervisors: any = [];
  private endpoint = 'categoriesIncidents';
  private endpointInterruptionService = 'servicesInterruptions';
  public vehicleRequired = false;
  public used_replacement = false;

  public categoriesIncidents: any = [];
  public incidents: any = [];
  subcategories: any = [];

  public vehicles: any = [];
  @Input() drivers?: any;
  @Output() onClose?: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() notify?: EventEmitter<any> = new EventEmitter<any>();
  public objTem: any = {};

  public pattern_time = '(0[0-9]|1[0-9]|2[0-9]|3[0-6])(:[0-5][0-9]){1}';
  public time_format = [/[0-9]/, /\d/, ':', /\d/, /\d/];
  protected lastBlockStatusInterruption: number = null;
  assignVehicle: AssignVehicle = <AssignVehicle>{};

  statusTSO = false;
  permisoEditInt = false;

  assignedDriver: boolean;

  constructor(private  _dataService: DashboardLsfDataService,
              vcr: ViewContainerRef,
              private _sanitizer: DomSanitizer,
              private _serviceTsoAssignVehicle: TsoAssignVehicleService,
              private ngxPermissionsService: NgxPermissionsService,
              private _tsoService: TsoService,
              private interruptionService: InterruptionService,) {


    this.loadGrid();


  }

  loadGrid() {
    this.gridInterruptions = <GridOptions> {
      columnDefs: [
        {headerName: 'Id', field: 'id', width: 100, sort: 'desc', hide: true},
        {headerName: 'Status', valueGetter: this.getBlockStatu, width: 100},
        {headerName: 'Time', field: 'time', valueGetter: this.getBlockTime, width: 132},
        {headerName: 'Details/Resolution', field: 'description', valueGetter: this.getDescription, width: 140},
        {headerName: 'Veh', field: 'veh', valueGetter: this.getVehicle, width: 70},
        {headerName: 'Odometer', field: 'odometer', width: 100},
        {headerName: 'Driver', field: 'driver', valueGetter: this.getDriver, width: 130},
        {headerName: 'Supervisor', field: 'supervisor', valueGetter: this.getSupervisor, width: 130},
        { headerName: '', width: 80, colId: 'btnDelete',
          cellRenderer: (params) => {
            return`
            <div>
              <button class="btn btn-sm btn-danger" style="width: 50px">Delete</button>
            </div>`;
          }
        },
      ]
    };
  }

  async oncellClicked($event) {
    const deleteInt = await this.ngxPermissionsService.hasPermission('btn-delete-interruption');
    if ($event.colDef.colId === 'btnDelete') {
      if(deleteInt) {
        Swal.fire({
          title:'Delete Interruption',
          text: "Are you sure to delete Interruption?",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then((result) => {
          if (result.value) {
            const data = $event.data;
            this.busy.push(this.interruptionService.deleteInterruption(data.id).subscribe(
              data => {
                Swal.fire({
                  title: 'Successfull',
                  text: 'Interruption successfully deleted',
                  type: 'success',
                  showCancelButton: false,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Ok'
                }).then((result) => {
                  this.Close({});
                });
              }
            ));
          }
        });
      } else {
        Swal.fire(
          'Oops...!',
        'You do not have permission to perform this action',
          'warning'
        );
      }
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  async ngOnInit() {
    this.permisoEditInt = await this.ngxPermissionsService.hasPermission('btn-edit-interruption-schedule');
    if (this.schedule_seleccionado.block_statu_id === '2' || this.schedule_seleccionado.block_statu_id === '5') {
      this.loadClient();
    }
    this.loadInfoServiceInterruption();
    this.loadDrivers();
    this.getAssignVehicle();
  }

  getDriver(row) {
    if (row.data.driver !== null) {
      return `${row.data.driver.first_name}  ${row.data.driver.last_name}`;
    } else {
      return '';
    }
  }

  getSupervisor(row) {
    if (row.data.supervisor !== null) {
      return `${row.data.supervisor.first_name}  ${row.data.supervisor.last_name}`;
    } else {
      return '';
    }
  }

  getVehicle(row) {
    if (row.data.vehicle !== null) {
      return `${row.data.vehicle.description}`;
    } else {
      return '';
    }
  }

  getDescription(row) {
    if (row.data.incident !== null) {
      return `${row.data.incident.description} - ${row.data.details}`;
    } else {
      return row.data.details;
    }
  }

  getBlockTime(row) {
    const dateNow = new Date();
    const datePipe = new DatePipe('en-US');
    /*const date = row.data.created_at;
    const dateRecord = datePipe.transform(date, 'yyyy-MM-dd');
    if (dateRecord == datePipe.transform(dateNow, 'yyyy-MM-dd')) {
      return row.data.time;
    } else {
      return datePipe.transform(row.data.created_at, 'yyyy-MM-dd hh:mm:ss');
    } */
    return datePipe.transform(row.data.dateofservice, 'yyyy-MM-dd HH:mm:ss');
  }

  getBlockStatu(row) {
    if (row.data.blockstatu !== null) {
      return `${row.data.blockstatu.description}`;
    } else {
      return '';
    }
  }

  async loadInfo() {
    const sub = this._dataService.getData(this.endpoint);
    this.busy.push(sub.subscribe());
    await sub.toPromise().then(data => {
      this.categoriesIncidents = data;
    });
  }

  loadInfoServiceInterruption() {
    this.busy.push(this._dataService.
    getData(this.endpointInterruptionService + '/scheduledetails/' + this.schedule_seleccionado.id).subscribe(
      (data: any) => {
        if (data && data.length) {
          if (this.gridInterruptions.api) {
              const [lastRegister] = data;
              this.lastBlockStatusInterruption = lastRegister.block_statu_id;
            this.gridInterruptions.api.setRowData(data);
          }
        } else {
          this.gridInterruptions.api.setRowData([]);
        }
      }
    ));
  }

  changeCategorie(_value: any) {
    let result: any = _.find(this.categoriesIncidents, {'id': parseInt(_value)});
    this.subcategories = result.subcategories;
    if (this.subcategories.length === 1) {
      this.data.subcategories_incident_id = this.subcategories[0].id;
      this.changeSubcategorie(this.subcategories[0].id);
    } else {
      this.incidents = [];
      this.data.subcategories_incident_id = undefined;
    }
    this.data.incident_id = undefined;
  }

  autocompleListFormatterSupervisor = (data: any) => {
    const html = `<span>${data.last_name} ${data.first_name}</span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }
  myValueFormatterSupervisor(data: any): string {
    const html = `${data.last_name} ${data.first_name}`;
    return html;
  }
  valueChangedSupervisor(data) {
    this.data.supervisor = data.id;
  }

  autocompleListFormatterVehicle = (data: any) => {
    const html = `<span>${data.description}</span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }
  myValueFormatterVehicle(data: any): string {
    const html = `${data.description}`;
    return html;
  }
  valueChangedVehicle(data) {
    this.data.vehicle_id = data.id;
  }

  autocompleListFormatterDriver = (data: any) => {
    const html = `<span>${data.employee_name}</span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }
  myValueFormatterDriver(data: any): string {
    const html = `${data.employee_name}`;
    return html;
  }
  valueChangedDriver(data) {
    this.data.driver_id = data.id;
  }

  changeSubcategorie(_value: any) {
    let result: any = _.find(this.subcategories, {'id': parseInt(_value)});
    this.incidents = result.incidents;
    this.data.incident_id = undefined;
  }

  close(edit: boolean = false) {
    this.onClose.emit(edit);
  }

  onSave() {
    if (this.data.id) {
      this.assignedDriver = confirm('Will the selected driver be assigned to the block?');
      this.update();
    } else {
      this.save();
    }
  }
  save() {
    this.data.schedule_detail_id = this.schedule_seleccionado.id;
    if (this.schedule_seleccionado.block_statu_id == BlockStatusEnum.OutOfService || this.lastBlockStatusInterruption === BlockStatusEnum.OutOfService) {
      this.data.type = BlockStatusEnum.InService; // En servicio
    } else {
      this.data.type = BlockStatusEnum.OutOfService; // Fuera de Servicio
    }

    if (this.data.type == BlockStatusEnum.InService) { //TSO Assignacion...
      this.managerTSO();
    }

    this.busy.push(this._dataService.postData(this.data, this.endpointInterruptionService).subscribe(
      data => {
        this.apiResponse = data;
        this.apiResponse.process = true;
        if (this.apiResponse.transaction) {
          this.apiResponse.master.route_description = this.objTem.route_description;
          this.apiResponse.master.block_description = this.objTem.block_description;
          this.apiResponse.master.block_code = this.objTem.block_code;
          this.processResponse(this.apiResponse.details);
          this.notify.emit(this.apiResponse);
          this.data.schedule_detail_id = this.schedule_seleccionado.id;
          this.used_replacement = false;
          this.data.vehicle_id = '';
          this.data.odometer = '';
          this.data.driver_id = '';
          this.driver_name = '';
          this.vehicle_name = '';
          this.supervisor_name = '';
          this.vehicleRequired = false;
          if (this.schedule_seleccionado.block_statu_id === 3 && this.statusTSO) {
            this.onUnassignVehicle();
          }
          Swal.fire({
            title: 'Successfull',
            text: 'Interruption successfully registered',
            type: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok'
          }).then((result) => {
            this.Close({});
          });
        } else {
          alert(this.apiResponse.message);
        }
      }
    ));
  }

  update() {
    const data = Object.assign({}, this.data);
    this.busy.push(this._dataService.putDataEdit(data.id, {
      schedule_detail_id: data.schedule_detail_id,
      supervisor: data.supervisor,
      time: data.time,
      categorie_incident_id: data.categorie_incident_id,
      subcategories_incident_id: data.subcategories_incident_id,
      incident_id: data.incident_id,
      odometer: data.odometer,
      details: data.details,
      driver_id: data.driver_id,
      vehicle_id: data.vehicle_id,
      assignedDriver: this.assignedDriver,
      address: data.address,
    }, this.endpointInterruptionService).subscribe(
      result => {
        this.data = {};
        this.driver_name = '';
        this.vehicle_name = '';
        this.supervisor_name = '';
        this.gridInterruptions.api.refreshView();
        Swal.fire({
          title: 'Successfull',
          text: 'Interruption successfully updated',
          type: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok'
        }).then(() => {
          this.Close({});
        });
      }, err => {
        Swal.fire(
          'Oops...!',
          err.error.message,
          'error'
        );
      }
    ));
  }

  onNotify($event) {
    this.notify.emit($event);
  }

  Close($event) {
    this.close(true);
  }


  autoSizeAll() {
    let allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds);
  }

  loadDrivers() {
    this.busy.push(this._dataService.getData('drivers/active/1').subscribe(
      data => {
        this.supervisors = data;
        this.drivers = data;

        this.clearListSupervisor();
      }
    ));
  }

  processResponse(details: any) {
    this.gridInterruptions.api.updateRowData(
      {
        add: [details],
        addIndex: 0
      }
    );
    this.data = {};
    this.driver_name = '';
    this.vehicle_name = '';
    this.supervisor_name = '';
    this.gridInterruptions.api.refreshView();
    // this.gridInterruptions.api.softRefreshView();
  }

  onChangeVehicle($value) {
    if ($value !== '0: undefined') {
      this.vehicleRequired = true;
    } else {
      this.vehicleRequired = false;
    }
  }


  onChangeUsedReplacement(value: any) {
    if (value) {
      if (this.data.time !== undefined) {
        this.loadVehicles(this.schedule_seleccionado.id, this.data.time);
      }
    }

    this.used_replacement = !this.used_replacement;
    this.data.vehicle_id = '';
    this.data.odometer = '';
    this.data.driver_id = '';
    this.driver_name = '';
    this.vehicle_name = '';
    this.vehicleRequired = false;
  }

  async viewDetails($event) {
    const data = $event.data;
    this.used_replacement = true;
    if (this.permisoEditInt) {
      this.loadVehicles(this.schedule_seleccionado.id, data.time);
      await this.loadInfo();
      if (data.categorie_incident_id) {
        this.changeCategorie(data.categorie_incident_id);
      }
      if (data.categorie_incident_id) {
        this.changeSubcategorie(data.subcategories_incident_id);
      }
      this.data = {
        id: data.id,
        schedule_detail_id: data.schedule_detail_id,
        block_statu_id: data.block_statu_id,
        driver_id: data.driver_id ? data.driver_id : null,
        vehicle_id: data.vehicle_id ? data.vehicle_id : null,
        supervisor: data.supervisor.id ? data.supervisor.id : null,
        time: data.time ? data.time : '',
        categorie_incident_id: data.categorie_incident_id ? data.categorie_incident_id : null,
        subcategories_incident_id: data.subcategories_incident_id ? data.subcategories_incident_id : null,
        incident_id: data.incident_id ? data.incident_id : null,
        odometer: data.odometer ? data.odometer : 0,
        details: data.details ? data.details : '',
        address: data.address ? data.address : '',
      };
      this.driver_name = data.driver_id ? `${data.driver.last_name} ${data.driver.first_name}` : null;
      this.vehicle_name = data.vehicle_id ? data.vehicle.description : null;
      this.supervisor_name = data.supervisor.id ? `${data.supervisor.last_name} ${data.supervisor.first_name}` : null;
    } else {
      alert(data.details);
    }
  }

  loadClient() {
    this.busy.push(this._dataService.
    getData(this.endpointInterruptionService + '/client/' + this.schedule_seleccionado.id).subscribe(
      (data: any) => {
        if (data.client.config == null) {
          alert('Can not record the interruptions, please configure the parameters related to the email and template');
        } else {
          this.data.schedule_detail_id = this.schedule_seleccionado.id;
          this.loadInfo();
          this.objTem.route_description = this.schedule_seleccionado.route_description;
          this.objTem.block_description = this.schedule_seleccionado.block_description;
          this.objTem.block_code = this.schedule_seleccionado.block_code;
        }
      }
    ));
  }

  clearListSupervisor() {
    const driver_id = this.schedule_seleccionado.driver_id;
    this.supervisors.forEach((data, index) => {
      if (data.id === driver_id) {
        this.supervisors.splice(index, 1);
      }
    });
  }

  loadVehicles(scheduledetail_id: number, start_time: number) {
    this.busy.push(this._dataService.
    getData('vehicles/available/scheduledetails/' + scheduledetail_id + '/starttime/' + start_time + '').subscribe(
      data => {
        this.vehicles = data;
      }
    ));
  }

  verifyDate() {
    const today = moment().format('YYYY-MM-DD');
    const morning = moment().add(1, 'days').format('YYYY-MM-DD');
    const actualTime = moment.utc(`${today} ${this.data.time}:00`, 'YYYY-MM-DD HH:mm:ss');
    const startTime = moment.utc(`${today} ${this.schedule_seleccionado.start_time}:00`, 'YYYY-MM-DD HH:mm:ss');
    let endTime = moment.utc(`${today} ${this.schedule_seleccionado.end_time}:00`, 'YYYY-MM-DD HH:mm:ss');
    if (startTime.isAfter(endTime)) {
      endTime = moment.utc(`${morning} ${this.schedule_seleccionado.end_time}:00`, 'YYYY-MM-DD HH:mm:ss');
    }
    const between = actualTime.isBetween(startTime, endTime, null, '[]');

    if (!between) {
      this.data.time = '';
      Swal.fire({
        title: 'Invalid time',
        text: `The date of the interruption is outside the schedule of the route`,
        type: 'warning',
        showCancelButton: false,
      }).then((result) => {});
    }
  }

  changeValueTime(value: any) {
    if (this.el.nativeElement.checked) {
      if (this.data.time !== undefined) {
        this.loadVehicles(this.schedule_seleccionado.id, this.data.time);
      }
    }
  }

  getAssignVehicle() {
    this.busy.push(this._serviceTsoAssignVehicle.getAssignVehicle(this.schedule_seleccionado.id).subscribe(
      data => {
        if (data) {
          this.assignVehicle = data;
        }
      }
    ));
  }

  onUnassignVehicle(close?: boolean, scheduleID?: number, schedule_detail_id?: number) {
    this.busy.push(this._serviceTsoAssignVehicle.unassignVehicle(scheduleID ? scheduleID: this.assignVehicle.schedule_id_assigned, schedule_detail_id ? schedule_detail_id: this.schedule_seleccionado.id).subscribe(
      data => {
        if(data.Code == 1) {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
          this.assignVehicle = <any>{};
          if (close) {
            this.onClose.emit(true);
          }
        } else {
          Swal.fire(
            'Oops...!',
            data.message,
            'error'
          )
        }
      }
    ));
  }

verificarUnidad(UniteID: number, routeID: number, callback) {
  this.busy.push(this._serviceTsoAssignVehicle.verificationUnit(UniteID, routeID).subscribe(
    data => {
      callback(data);
    }
  ));
}

managerTSO() {
  if(this.data.type == 2) {
    this.busy.push(this._tsoService.getTsoAssign(this.data.schedule_detail_id).subscribe(
      (data: any) => {
        if (data) {
          if (data.routeID && data.unitID) {

            if (this.used_replacement) {
              const tso_id = this.getTsoIdVehicle(this.data.vehicle_id);
              if(tso_id) {
                this.assignVehicle.unitID = tso_id;
              } else {
                this.assignVehicle.unitID = data.unitID;
              }
            } else {
              this.assignVehicle.unitID = data.unitID;
            }

            this.assignVehicle.routeID = data.routeID;
            this.assignVehicle.scheduleID = data.scheduleID;
            this.assignVehicle.schedule_detail_id = this.schedule_seleccionado.id;

            this.verificarUnidad(data.unitID, data.routeID, (data) => {
              if ( data == null) {
                this.busy.push(this._serviceTsoAssignVehicle.tsoAssignVehicle(this.assignVehicle).subscribe(
                  (data: any) => {
                    if (data.Code == 1) {
                      Swal.fire(
                        'Assigned!',
                        'Vehicle assigned in TSO. ID: ' + data.Data,
                        'success'
                      )
                      this.getAssignVehicle();
                      this.onClose.emit(true);
                    }
                  }));
              } else {
                Swal.fire({
                  title: data.details,
                  text: "Please verify that the unit is not assigned in TSO.\nDo you want to try to unassign this vehicle from TA?",
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes'
                }).then((result) => {
                  if (result.value) {
                    this.onUnassignVehicle(false,data.scheduleID, data.schedule_detail_id); // aqui debo desactinar en donde esta asignado... que lo retorna el servicio...
                  }
                });
              }
            });
          }
        }
      }
    ));
  }
}

  getTsoIdVehicle(vehicle_id: number) {
    return this.vehicles.filter((row) => {
      return row.vehicle_id = vehicle_id;
    })[0].tso_id;
  }

  ngOnDestroy(): void {
    this.busy.forEach((row) => {
      row.unsubscribe();
    });
  }

  cancel() {
    this.data = {};
    this.driver_name = '';
    this.vehicle_name = '';
    this.supervisor_name = '';
    this.gridInterruptions.api.refreshView();
    this.used_replacement = false;
  }

  displayIncidentCategory(): boolean {
    return (!this.data.id && this.lastBlockStatusInterruption !== BlockStatusEnum.OutOfService) ||
      (this.data.id && this.data.block_statu_id !== BlockStatusEnum.InService);
  }

  displayTime(parameter): boolean {
    return (this.lastBlockStatusInterruption === BlockStatusEnum.OutOfService && parameter);
  }

  details(): string {
    return this.schedule_seleccionado.block_statu_id == BlockStatusEnum.InService ? 'Details' : 'Resolution';
  }

  descriptionButton(): string {
    return this.data.id ? 'Update' : this.lastBlockStatusInterruption == BlockStatusEnum.OutOfService ? 'Restore Service' : 'Stop Service';
  }
}
