import { NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillingRoutingModule } from './billing-routing.module';
import { CreateNewInvoiceComponent } from './create-new-invoice/create-new-invoice.component';
import { MyDateRangePickerModule } from 'mydaterangepicker';
import { AgGridModule } from 'ag-grid-angular/main';
import { NgBusyModule } from 'ng-busy';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { ThemeModule } from '../../theme/theme.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BsModalModule } from 'ng2-bs3-modal';
import { Ng2AutoCompleteModule } from 'ng2-auto-complete';
import { TextMaskModule } from 'angular2-text-mask';
import { BsDatepickerModule, TabsModule } from 'ngx-bootstrap';
import { BillingCategoriesService } from '../../services/parameters/billing-categories.service';
import { ManifestsModule } from '../manifests/manifests.module';
import { DashboardLsfModule } from '../dashboard-lsf/dashboard-lsf.module';
import { AdminDataService } from '../manifests/components/driver-manifest/driver.data.service';
import { MyDatePickerModule } from 'mydatepicker';
import { BatchModule } from './batch/batch.module';
import { InvoicesModule } from './invoices/invoices.module';
import { HeaderFiltersModule } from '../../components/header-filters/header-filters.module';
import { ShowErrorsModule } from '../../tools/showErrosForm.directive';
import { CurrencyMaskModule } from 'ngx-currency-mask';
import { SummaryBarComponent } from './summary/summary-bar/summary-bar.component';
import { WorkflowModule } from './workflow/workflow.module';

@NgModule({
  imports: [
    SharedModule,
    ThemeModule,
    CommonModule,
    BsModalModule,
    MyDateRangePickerModule,
    AgGridModule.withComponents([]),
    BsDatepickerModule.forRoot(),
    NgBusyModule,
    FormsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    Ng2AutoCompleteModule,
    TextMaskModule,
    TabsModule.forRoot(),
    MyDatePickerModule,
    HeaderFiltersModule,
    ShowErrorsModule,
    CurrencyMaskModule,

    BatchModule,
    DashboardLsfModule,
    BillingRoutingModule,
    ManifestsModule,
    InvoicesModule,
    WorkflowModule
  ],
  declarations: [
    CreateNewInvoiceComponent,
    SummaryBarComponent
  ],
  providers: [
    BillingCategoriesService,
    AdminDataService,
  ]
})

export class BillingModule { }
