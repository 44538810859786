import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RoutesService } from './../../../../../../services/fleet/routes.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs-compat';

@Component({
  selector: 'app-contract-add',
  templateUrl: './contract-add.component.html',
  styleUrls: ['./contract-add.component.scss']
})
export class ContractAddComponent implements OnInit {
  @Input() set contract_id(id) {
    if(id) {
      this.busy.push(
        this.routesService.getDayContract(id).subscribe((res: any) => {
          this.form.setValue({
            id: res.data.id,
            day: res.data.day,
            hours: res.data.hours,
            minutes: res.data.minutes,
            active: res.data.active,
          });
        })
      );
    } else {
      this.initForm();
    }
  };
  @Input() route_id: number;
  @Output() onSave?= new EventEmitter<any>();

  busy: Subscription[] =[];
  form: FormGroup;
  days = [];
  
  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private routesService: RoutesService,
  ) {
    this.initForm();
  }

  ngOnInit() {
    this.loadDays();
  }

  loadDays() {
    this.busy.push(
      this.routesService.getDaysContracts().subscribe((res: any) => {
        this.days = res;
      })
    );
  }

  initForm() {
    this.form = this.fb.group({
      id: [''],
      day: ['', Validators.required],
      hours: ['', Validators.required],
      minutes: ['', Validators.required],
      active: [true],
    });
  }

  save() {
    const data = Object.assign({}, this.form.getRawValue());
    data.route_id = this.route_id;
    if (data.id) {
      this.busy.push(
        this.routesService.updateContracts(data).subscribe((res: any) => {
          this.toastr.success('Register saved successfully');
          this.onSave.emit(true);
        })
      );
    } else {
      this.busy.push(
        this.routesService.saveContracts(data).subscribe((res: any) => {
          this.toastr.success('Register saved successfully');
          this.onSave.emit(true);
        })
      );
    }
  }

}
