import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { GridOptions, GridReadyEvent } from 'ag-grid-community';
import { BsModalComponent } from 'ng2-bs3-modal';
import { AdminDataService } from '../driver.data.service';
import { VehicleService } from '../../../../../services/fleet/vehicle.service';
import { ToastrService } from 'ngx-toastr';
import { DriverManifestService } from '../../../../../services/manifest/driver-manifest.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-vehicles',
  templateUrl: './add-vehicles.component.html',
  styleUrls: ['./add-vehicles.component.scss']
})
export class AddVehiclesComponent implements OnInit {
  @Input() date?: any;
  @Input() driver_manifest_id?: any;
  @ViewChild('modalAddVehicle') modalAddVehicle: BsModalComponent;
  busy: Subscription;
  gridVehicles: GridOptions;
  vehicles: any = [];
  vehicle_manifest_id = null;
  getContextMenuItems: any;
  @Output() onReloadData? = new EventEmitter<any>();
  @Input() manifest_billed?: any;

  constructor(
    private vehicleService: VehicleService,
    private  adminDataService: AdminDataService,
    private toastr: ToastrService,
    private driverManifestService: DriverManifestService
  ) {
    this.setConfigGrid();
  }

  ngOnInit() {
  }

  setConfigGrid() {
    this.gridVehicles = <GridOptions>{
      columnDefs: [
        { headerName: 'Seq', field: 'seq', width: 60, cellStyle: {
          textAlign: 'center'
          } },
        { headerName: 'Vehicle', field: 'vehicle.description', width: 90 },
        {
          headerName: 'Last', field: 'last', width: 60, colId: 'changeLast',
          cellRenderer: (params) => {
            return params.data.last ?
              '<span class="label label-success" style="margin-top: 4px; position: absolute;"><i class="fa fa-check-circle" aria-hidden="true"></i></span>' : '';
          }
        },
        { headerName: 'Leave Yard', field: 'leave_yard_odometer', width: 90 },
        { headerName: 'First Stop', field: 'first_stop_odometer', width: 90 },
        { headerName: 'Last Stop', field: 'last_stop_odometer', width: 90 },
        { headerName: 'Return Yard', field: 'return_to_yard_odometer', width: 90 },
        { headerName: 'NR Miles', field: 'not_revenue_miles', width: 90 },
        { headerName: 'Rev Miles', field: 'revenue_miles', width: 90 },
      ], onGridReady: (event: GridReadyEvent) => {
        this.loadData();
      },
      rowSelection: 'single'
    };

    this.getContextMenuItems = (params) => {
      const result = [
        {
          name: 'Edit',
          cssClasses: ['redFont', 'bold'],
          icon: '<i class="fa fa-edit"></i>',
          action: () => {
            this.vehicle_manifest_id = params.node.data.id;
            this.modalAddVehicle.open('sm');
          }
        },
      ];
      if (!params.node.data.last) {
        result.push({
          name: 'Remove',
          cssClasses: ['redFont', 'bold'],
          icon: '<i class="fa fa-trash"></i>',
          action: () => {
            this.vehicle_manifest_id = params.node.data.id;
            this.busy = this.vehicleService.updateVehicleManifest(this.vehicle_manifest_id, {
              leave_yard_odometer: params.node.data.leave_yard_odometer,
              first_stop_odometer: params.node.data.first_stop_odometer,
              last_stop_odometer: params.node.data.last_stop_odometer,
              return_to_yard_odometer: params.node.data.return_to_yard_odometer,
              not_revenue_miles: params.node.data.not_revenue_miles,
              revenue_miles: params.node.data.revenue_miles,
              driver_manifest_id: this.driver_manifest_id,
              vehicle_id: params.node.data.vehicle.id,
              active : 0,
            }).subscribe((res: any) => {
              this.toastr.success('Vehicle removed saved');
              this.loadData();
            }, err => {
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: 'Error! Please try again!'+ err
              });
            });
          }
        });

        result.push({
          name: 'Set as last',
          cssClasses: ['redFont', 'bold'],
          icon: '<i class="fa fa-check-square-o"></i>',
          action: () => {
            this.busy = this.vehicleService.setAsLastVehicleManifest(params.node.data.id).subscribe((resp: any) => {
              this.driverManifestService.load_details$.next(resp);
              this.loadData();
              this.onReloadData.emit();
            });
          }
        });
      }
      return result;
    };
  }

  loadData() {
    if (this.driver_manifest_id) {
      this.busy = this.adminDataService.getData(`driverManifestVehicles/byManifest/` + this.driver_manifest_id)
        .subscribe((resp: any) => {
          let info = resp.filter(r => r.active);
          let index = 1;
          info = info.map((item: any) => {
            item['seq'] = index;
            // item.last != null ? item['last'] = item.last : item['last'] = index === info.length;
            index++;
            return item;
          });
          this.gridVehicles.api.setRowData(info);
          this.vehicles = resp;
        });
    } else {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Error: Please reload the page!'
      });
    }
  }

  onAdd() {
    this.vehicle_manifest_id = null;
    this.modalAddVehicle.open('sm');
  }

  onSave(event: any) {
    this.modalAddVehicle.close();
    this.loadData();
    this.onReloadData.emit(event.data);
  }

}
