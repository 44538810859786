import { BatchInvoicesService } from '../../../../../services/billing/batch/batch-invoices.service';
import { Component, OnInit, ViewChild, ElementRef, Input, SimpleChanges, OnDestroy, OnChanges } from '@angular/core';
import { BsModalComponent } from 'ng2-bs3-modal';
import { GridOptions } from 'ag-grid-community';
import * as moment from 'moment';
import { BatchCreditsService } from '../../../../../services/billing/batch/batch-credits.service';
import { Subject, Subscription } from 'rxjs-compat';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { InvoiceReportService } from '../../../workflow/invoice-report/invoice-report.service';
import { SweetAlertHelper } from '../../../../../CORE/helpers/sweet-alert-helper.service';
@Component({
  selector: 'app-grid-list-credits',
  templateUrl: './grid-list-credits.component.html',
  styleUrls: ['./grid-list-credits.component.scss']
})
export class GridListCreditsComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('modalDetails') modalDetails: BsModalComponent;
  @ViewChild('grid') gridViewInvoiceCredit: ElementRef;
  private destroy: Subject<boolean> = new Subject<boolean>();
  editCredit: boolean;
  subscription: Subscription[] = [];
  @Input() clearGrid ?: any;
  @Input() isActiveInvoiceCredit?: boolean;
  @Input() gridHeight?: string;
  addStatus: boolean;
  busy: Subscription[] = [];
  gridCredits: GridOptions;
  gridCreditsData = [];
  gridListInvoiceCredit: boolean;
  invoice_id: number;
  modalInvoiceReportIsOpen = false;


  constructor(
    private batchCreditsService: BatchCreditsService,
    private batchInvoiceService: BatchInvoicesService,
    private invoiceReportService: InvoiceReportService,
    private sweetAlertHelper: SweetAlertHelper
  ) {
    this.createGrid();
  }

  ngOnInit() {
   this.subscribeObservables();
   this.loadInvoiceCredit();
  }

  subscribeObservables() {
    this.batchCreditsService.save$.pipe(takeUntil(this.destroy)).subscribe((resp: boolean) => {
      this.saveInvoiceCredit(resp);
    });

    this.invoiceReportService.isOpenInInvoiceReportAction$.pipe(takeUntil(this.destroy)).subscribe({
      next: (isOpen: boolean) => this.modalInvoiceReportIsOpen = isOpen
    });

    this.batchInvoiceService.batchInvoiceRow$.pipe(takeUntil(this.destroy)).subscribe((resp => {
      if (resp) {
        this.invoice_id = Number(resp.id);
        this.loadInvoiceCredit();
      }
    }));

    this.invoiceReportService.clearGridCreditAction$.pipe(takeUntil(this.destroy)).subscribe({
      next: (clear: boolean): void => {
        if (clear) {
          this.resetGrid();
        }
      }
    });
  }

  loadInvoiceCredit(): void {
    this.modalInvoiceReportIsOpen ? this.loadInvoiceCreditInInvoiceReport() : this.loadInvoiceCreditInBatchHistory();
  }

  loadInvoiceCreditInInvoiceReport(): void {
    if (this.invoice_id) {
      this.busy.push(
        this.invoiceReportService.getCreditChargeBatch(this.invoice_id).subscribe({
            next: ({data}): void => {
              if (data.length && this.gridCredits.api) {
                this.gridListInvoiceCredit = true;
                this.gridCreditsData = data;
              }
            },
            error: (error) => this.sweetAlertHelper.captureException(error)
          }
        )
      );
    }
  }

  loadInvoiceCreditInBatchHistory(): void {
    if (this.invoice_id)  {
      this.busy.push(
        this.batchCreditsService.getBatchInvoiceCredits(this.invoice_id).pipe(takeUntil(this.destroy)).subscribe((resp: any) => {
          if (resp && this.gridCredits.api) {
            this.gridListInvoiceCredit = true;
            this.gridCreditsData = resp;
          }
        }, error => {
          Swal.fire({
            type: 'error',
            title: 'Error...',
            text: error.message,
          });
        })
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
     if (changes['clearGrid']) {
       if (!this.isActiveInvoiceCredit) {
         this.gridListInvoiceCredit = false;
       }
      if (this.clearGrid) {
        this.gridCreditsData = [];
      }
    }
  }
  formatPaidDate(row: any) {
    return moment(row.data.paid_date).format('MM-DD-YYYY');
  }

  createGrid() {
    this.gridCredits = <GridOptions> {
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      rowSelection: 'single',
      enableRangeSelection: true,
      enableFilter: true,
      onGridReady: () => {
        this.gridCredits.api.setRowData([]);
      },
      onSelectionChanged: () => {
        this.batchCreditsService.batchInvoiceCreditRow$.next(this.gridCredits.api.getSelectedRows()[0]);
      },
      columnDefs: [
        { headerName: 'ID', field: 'id', width: 50 },
        { headerName: 'Credit Num', field: 'credit_num', width: 120 },
        { headerName: 'Credit Status', field: 'credit_status_description', width: 120 },
        { headerName: 'Total Amount', field: 'amount', width: 110, cellRenderer: this.CurrencyCellRenderer, cellStyle: { textAlign: 'right' } },
        { headerName: 'Paid', field: 'paid_date', width: 90, valueGetter: this.formatPaidDate },
        { headerName: 'User', field: 'user_name', width: 100 }
      ]
    };
  }

  private resetGrid(): void {
    if (!this.isActiveInvoiceCredit) {
      this.gridListInvoiceCredit = false;
    }
    this.gridCredits.api.setRowData([]);
  }

  CurrencyCellRenderer(params: any) {
    const usdFormate = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });
    return usdFormate.format(params.value);
  }

  editCreditRow(event: any) {
    if (event.data) {
      this.editCredit = true;
      this.batchCreditsService.editRowCredit$.next(event.data);
      this.modalDetails.open();
    }
  }

  onClickNewBatchInvoiceCredit() {
    this.editCredit = false;
    this.batchCreditsService.editRowCredit$.next(null);
    this.modalDetails.open();
  }

  get titleModal(): string {
    return this.editCredit ? 'Edit Credit' : 'Create Credit';
  }

  saveInvoiceCredit(save: boolean) {
    if (save) {
      this.loadInvoiceCredit();
    }
    this.addStatus = false;
  }

  ngOnDestroy() {
    this.addStatus = false;
    this.destroy.next(true);
    this.destroy.unsubscribe();
    return this.busy = [];
  }
}
