import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

export interface TSOVehicle {
  ID: number;
  UnitID: string;
  LicencePlate: string;
  ShortName: string;
  Description: string;
  SerialNumber: string;
  IMEI: string;
  Address: string;
  City: string;
  State: string;
  CountryCode: string;
  PostalCode: string;
  LastEventCode: string;
  EventName: string;
  LastEventDate: Date;
  LastLatitude: number;
  LastLongitude: number;
  Speed: number;
  Direction: string;
  DealerID: number;
  CompanyID: number;
  ContactName: string;
  IconPath: string;
  AssignedDriver: string;
  DriverID: number;
  LocalLastEventDatetxt: string;
  HowsMyDriving: string;
  TruckNumber: string;
  VIN: string;
}

interface TsoResponse {
  Code: number;
  Message: string;
  Data: any;
}

@Injectable()
export class TSOVehicleService {

  protected resource: string;

  constructor(private http: HttpClient) {
    this.resource = environment.url_api_v;
  }

  /**
   *
   * @param {string} token
   * @returns {Observable<TsoResponse>}
   */
  listVehicles() {
    return this.http.get<TsoResponse>(this.resource + environment.tso.units_list);
  }

}
