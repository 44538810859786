import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, Subject } from 'rxjs/Rx';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  load_runs_of_unprocessed_block: any;
  progressObserver: any;
  progress: any;

  constructor(private http: HttpClient, private serviceAuth: AuthService) { }

  load_unprocessed_block$ = new Subject<any>();
  load_interruptions$ = new Subject<any>();
  new_interruptions$ = new Subject<any>();
  load_invoices$ = new Subject<any>();
  load_buses$ = new Subject<any>();
  load_remplacement_log$ = new Subject<any>();
  save_layout_runs$ = new Subject<any>();
  save_layou_invoices$ = new Subject<any>();
  verified_blocks_runs$ = new Subject<any>();
  load_buses_fueled$ = new Subject<any>();
  load_liquidated_damage$ = new Subject<any>();
  load_missed_service_events$ = new Subject<any>();
  load_summary_billing$ = new Subject<any>();

  getData(data) {
    return this.http.post<any>(`${environment.url_api_v}routeBlocks/blocksBilling`, data);
  }

  getInterruptions(data: any) {
    return this.http.post<any>(`${environment.url_api_v}servicesInterruptions/interruptionsBilling`, data);
  }

  getDataRuns({start_date, end_date, client_id, route_id}) {
    return this.http.get<any>(`${environment.url_api_v}billing/runs/manifestCounts/${start_date}/${end_date}/${client_id}/${route_id}`);
  }

  getRunsByClient(client_id: number) {
    return this.http.get<any>(`${environment.url_api_v}billing/runs/byClient/${client_id}`);
  }

  getDataBlocksRuns({date, client_id, route_id}) {
    return this.http.get<any>(`${environment.url_api_v}billing/runs/manifestRunes/${date}/${client_id}/${route_id}`);
  }

  getDataProcessRuns(data) {
    return this.http.post<any>(`${environment.url_api_v}billing/runs/blocksByRun`, data);
  }

  getBusesFueled({start_date, end_date, client_id}) {
    return this.http.get<any>(`${environment.url_api_v}driverManifest/countDistinctVehiclesByFilter/${start_date}/${end_date}/${client_id}`);
  }

  getBusesFueledWeek({start_date, end_date, route_id}) {
    return this.http.get<any>(`${environment.url_api_v}billing/buses/fueled/${route_id}/${start_date}/${end_date}`);
  }

  getBusesFueledblocksAndImport({start_date, end_date, route_id}) {
    return this.http.get<any>(`${environment.url_api_v}billing/buses/fueled/import/blocksAndImport/${start_date}/${end_date}/${route_id}`);
  }

  saveBusesFueledWeek(data) {
    return this.http.post<any>(`${environment.url_api_v}billing/buses/fueled`, data);
  }

  saveBusesBusesFueledArray(data) {
    return this.http.post<any>(`${environment.url_api_v}billing/buses/fueled/save`, data);
  }

  saveLiquidatedDamage(data) {
    return this.http.post<any>(`${environment.url_api_v}billing/liquidatedDamage`, data);
  }

  updateBusesFueledWeek(data) {
    const id = data.id;
    delete data.id;
    return this.http.put<any>(`${environment.url_api_v}billing/buses/fueled/${id}`, data);
  }

  updateLiquidatedDamage(data) {
    const id = data.id;
    delete data.id;
    return this.http.put<any>(`${environment.url_api_v}billing/liquidatedDamage/${id}`, data);
  }

  getBillingStatus() {
    return this.http.get<any>(`${environment.url_api_v}driverManifest/billingStatus/all`);
  }

  getUnproccesed(data: any) {
    return this.http.post<any>(`${environment.url_api_v}driverManifest/unproccesed`, JSON.stringify(data));
  }

  putBillingStatus(data: any) {
    return this.http.put<any>(`${environment.url_api_v}driverManifest/update/billingStatus`, JSON.stringify(data));
  }

  getImportBusesFueled(data: { start_date: string, end_date: string }) {
    return this.http.get<any>(
      `${environment.url_api_v}billing/buses/fueled/import/list/${data.start_date}/${data.end_date}`
    );
  }

  getLiquidatedDamage(data: { client_id: number, start_date: string, end_date: string }) {
    return this.http.get<any>(
      `${environment.url_api_v}billing/liquidatedDamage/filter/${data.client_id}/${data.start_date}/${data.end_date}`
    );
  }

  getLiquidatedById(id: number) {
    return this.http.get<any>(`${environment.url_api_v}billing/liquidatedDamage/${id}`);
  }

  getUploadedFueledFiles() {
    return this.http.get<any>(`${environment.url_api_v}billing/buses/fueled/import/files`);
  }

  postImportBusesFueled(data: { import_file: any, start_date: string, end_date: string }) {
    return Observable.create(observer => {
      this.progressObserver = observer;
      const formData: FormData = new FormData(),
      xhr: XMLHttpRequest = new XMLHttpRequest();
      formData.append('import_file', data.import_file);
      formData.append('start_date', data.start_date);
      formData.append('end_date', data.end_date);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            observer.next(xhr.response);
            observer.complete();
          } else {
            observer.error(xhr.response);
            observer.complete();
          }
        }
      };
      xhr.upload.onprogress = (event) => {
        this.progress = Math.round((event.loaded / event.total) * 100);
        this.progressObserver.next(this.progress);
      };
      xhr.open('POST', `${environment.url_api_v}billing/buses/fueled/importFile`, true);
      xhr.setRequestHeader('Authorization', `Bearer ${this.serviceAuth.getTokenLocalStorage()}`);
      xhr.setRequestHeader('namedb', `${this.serviceAuth.getNameDbLocalStorage()}`);
      xhr.send(formData);
    });
  }

  saveMissedServiceEvent(data) {
    return this.http.post<any>(`${environment.url_api_v}billing/missedServiceEvent`, data);
  }

  updateMissedServiceEvent(data) {
    const id = data.id;
    delete data.id;
    return this.http.put<any>(`${environment.url_api_v}billing/missedServiceEvent/${id}`, data);
  }

  getMissedServiceEvents(data: any) {
    return this.http.get<any>(
      `${environment.url_api_v}billing/missedServiceEvent/filter/${data.client_id}/${data.start_date}/${data.end_date}`
    );
  }

}
