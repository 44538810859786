import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BsModalModule } from 'ng2-bs3-modal';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { AgGridModule } from 'ag-grid-angular';
import { NgBusyModule } from 'ng-busy';

import { ExceptionsReportComponent } from './exceptions-report.component';
import { SharedModule } from './../../../shared/shared.module';
import { ExceptionsService } from './../../../services/exceptions.service';

@NgModule({
  imports: [
    SharedModule,
    BsModalModule,
    BsDatepickerModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    AgGridModule.withComponents([]),
    NgBusyModule,
    CommonModule,
  ],
  exports: [],
  declarations: [ExceptionsReportComponent],
  providers: [ExceptionsService],
})
export class ExceptionsReportModule { }
