import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AttachImageService } from '../../services/services.index';

@Component({
  selector: 'app-add-image',
  templateUrl: './add-image.component.html',
  styleUrls: ['./add-image.component.scss']
})
export class AddImageComponent implements OnInit {
  url: any;
  public base64Image: any;
  @Input() vehicle_id: number;
  @Output() onCancel = new EventEmitter<boolean>();
  busy: Subscription;
  private apiResponse: any;
  @Output() onReload = new EventEmitter<boolean>();

  formData: FormGroup;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    public domSanitizer: DomSanitizer,
    private _serviceAttachImage: AttachImageService
  ) {
    this.loadForm();
  }

  ngOnInit() {
    this.formData.get('vehicle_id').setValue(this.vehicle_id);
  }

  loadForm() {
    this.formData = this.fb.group({
      description: ['', [Validators.required]],
      vehicle_id: ['', [Validators.required]],
      type: ['', [Validators.required]],
      file: ['', [Validators.required]],
    });
  }

  readUrl(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      const exten = event.target.files[0].name.split('.');
      reader.onload = (events: ProgressEvent) => {
        this.base64Image = (<FileReader>events.target).result;
        this.formData.get('file').setValue(this.base64Image);
        this.formData.get('type').setValue(exten[exten.length - 1]);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  onSave() {
    const data = Object.assign(this.formData.getRawValue());
    this.formData.get('file').setValue(this.base64Image);
    this.busy = this._serviceAttachImage.storeAttachImage(data).subscribe(
      (res: any) => {
        this.apiResponse = res;
        if (res.transaction) {
          this.toastr.success(this.apiResponse.message);
          this.onReload.emit(true);
        } else {
          alert('Error : ' + res.message);
        }
      }
    );
  }

}
