import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Rx';
import { GridOptions } from 'ag-grid-community';
import { CompanyService } from '../../../services/companies/company.service';
import { AuthService } from '../../../services/auth.service';
import { RemplacementLogService, IData } from '../../../services/billing/remplacement-log.service';
import { InvoiceService } from '../../../services/billing/invoice.service';
import { ReportsManageService } from '../../../services/reports-manage.service';
import { BsModalComponent } from 'ng2-bs3-modal';
import Swal from 'sweetalert2';
import AgGridHelpers from '../../../helpers/ag-grid-helpers';
import { stringify } from 'querystring';

@Component({
  selector: 'app-replacement-log',
  templateUrl: './replacement-log.component.html',
  styleUrls: ['./replacement-log.component.scss']
})
export class ReplacementLogComponent implements OnInit, OnChanges, OnDestroy {

  @Input() onClearGrid?= false;
  @ViewChild('modal') modal: BsModalComponent;
  busy: Subscription[] = [];
  grid: GridOptions;
  grid_columns = [];
  dataParams: IData;
  getContextMenuItems;
  dataInterruption: any = {};

  constructor(private authService: AuthService, private companyService: CompanyService,
              private remplacementLogService: RemplacementLogService, private invoiceService: InvoiceService,
              private reportsManageService: ReportsManageService) {
    this.initGrid();
  }

  ngOnInit() {
    this.invoiceService.load_remplacement_log$.subscribe(res => {
      this.dataParams = res;
      this.onSearch();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['onClearGrid']) {
      if (this.onClearGrid && this.grid.api) {
        this.grid.api.setRowData([]);
      }
    }
  }

  ngOnDestroy() {
    return this.busy = [];
  }

  initGrid() {
    this.grid = <GridOptions>{
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      rowSelection: 'single',
      enableRangeSelection: true,
      enableFilter: true,
      columnDefs: [
        { headerName: 'N°', field: 'no', width: 50, cellRenderer: function(params) {
          return params.rowIndex + 1;
          } },
        { headerName: 'Details of Replacement', field: 'details', width: 300 },
        { headerName: 'Day', field: 'day', width: 100 },
        { headerName: 'Date', field: 'date', width: 100 },
        { headerName: 'Time In', field: 'time_in', width: 100 },
        { headerName: 'Time Out', field: 'time_out', width: 100 },
        { headerName: 'Total Hrs', field: 'total_hours', width: 100 },
      ],
      onGridReady: () => {
        this.grid.api.setRowData([]);
      }
    };
    this.grid.getRowClass = params => {
      if (params.data) {
        if (params.data.total_for_day === 'Number of buses - Grand Total') {
          return 'item_total_grid';
        }
      }
    };
    this.getContextMenuItems = (params) => {
      const x = this.grid;
      const result = [
        AgGridHelpers.itemMenu('Print', 'fa-print', () => {
          this.printLog();
        }),
        'separator',
        'copy',
        'export',
      ];
      return result;
    };
  }

  onSearch() {
    const routes_id_any = [];
    routes_id_any.push(Number(this.dataParams.routes_id));
    const info = {
      start_date: this.dataParams.start_date,
      end_date: this.dataParams.end_date,
      client_id: this.dataParams.client_id,
      routes_id: Number(routes_id_any)
    };
    if(
      info.start_date
      && info.end_date
      && info.client_id
      && info.routes_id
    ) {
      this.busy.push(
        this.remplacementLogService.getData(
          info.start_date,
          info.end_date,
          info.client_id,
          info.routes_id
        )
        .subscribe((resp: any) => {
          if (this.grid.api) {
            this.grid.api.setRowData(resp);
          }
        })
      );
    }
  }

  printLog() {
    const parameter: any = {
      'client_id': [
        `${this.dataParams.client_id}`
      ],
      'int_date': [
        `${this.dataParams.start_date}`,
        `${this.dataParams.end_date}`
      ],
      'route_id': this.dataParams.routes_id
    };
    this.companyService.getCompany(Number(this.authService.getCompanyId())).subscribe((resp) => {
      if (resp.status === 'success') {
        this.reportsManageService.getTokenTrip2(resp.data.tenant, resp.data.tenant_key).then(() => {
          if (this.reportsManageService.tokenTrip2Report) {
            this.busy.push(this.reportsManageService.getReportTrip2(3142, parameter).subscribe((resp: string) => {
              if (resp) {
                const win = window.open(`${resp}&s=${this.reportsManageService.tokenTrip2Report}`, '_blank');
                win.focus();
              }
            }));
          }
        });
      }
    });
  }

  OnCellDoubleClicked(event) {
    if (event.data.interruption_id) {
      const data = Object.assign({}, this.dataParams);
      this.dataInterruption = {
        id: event.data.interruption_id,
        details: event.data.details,
        dataForm: data,
      };
      this.modal.open();
    } else {
      Swal.fire({
        title: 'Not found interruption',
        text: `The current record cannot be managed in this view, please go to the Manifest.`,
        type: 'warning',
        showCancelButton: true,
      }).then(() => {});
    }
  }

  onCloseModal(data) {
    this.modal.close();
    this.onSearch();
  }

}
