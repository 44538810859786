import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SelectWeeksComponent } from './select-weeks.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [SelectWeeksComponent],
  declarations: [SelectWeeksComponent],
  providers: [],
})
export class SelectWeeksModule { }
