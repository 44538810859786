import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InvoiceService } from '../../../../services/billing/invoice.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { formatDate } from '@angular/common';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-liquidated-damage',
  templateUrl: './add-liquidated-damage.component.html',
  styleUrls: ['./add-liquidated-damage.component.scss']
})

export class AddLiquidatedDamageComponent implements OnInit, OnDestroy, OnChanges {
  formLiquidatedDamage: FormGroup;
  @Input() liquidatedDamage?: any;
  @Input() dataParams?: any = {};
  @Output() onAddLiquidated? = new EventEmitter<any>();
  busy: Subscription[] = [];
  runs: any[] = [];
  types: any[] = [];
  liquidatedDamageId = null;

  constructor(private fb: FormBuilder, private invoiceService: InvoiceService) {
    this.onCreateForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['liquidatedDamage'] && this.liquidatedDamage) {
      this.onLoadData();
    }
  }

  ngOnInit() {
    this.busy.push(
      this.invoiceService.getRunsByClient(this.dataParams.client_id).subscribe((resp: any) => {
        this.runs = resp;
      })
    );
    this.types.push(
      {key:'1',value :'Liquidated Damage'},
      {key:'2',value :'Inspections'}
    );
  }

  onLoadData() {
    this.busy.push(
      this.invoiceService.getLiquidatedById(this.liquidatedDamage.id).subscribe((resp: any) => {
        if (resp.data) {
          this.liquidatedDamageId = resp.data.id;
          const date = moment(resp.data.date, 'YYYY-MM-DD hh:mm:ss').toISOString();
          this.formLiquidatedDamage.patchValue({
            run_id: resp.data.run_id,
            date: formatDate(date, 'yyyy-MM-dd', 'en'),
            description: resp.data.description,
            value: resp.data.value,
            active: resp.data.active,
            credit_type: resp.data.credit_type,
            credit_value: resp.data.credit_value
          });
        }
      })
    );
  }

  ngOnDestroy() {
    return (this.busy = []);
  }

  onCreateForm() {
    this.formLiquidatedDamage = this.fb.group({
      run_id: [null, Validators.required],
      date: ['', Validators.required],
      description: ['', Validators.required],
      value: ['', Validators.required],
      credit_type: ['1'],
      credit_value: [''],
      active: [true, Validators.required],
    });
  }

  onSave() {
    const data = Object.assign({}, this.formLiquidatedDamage.getRawValue());
    if (!this.liquidatedDamageId) {
      this.busy.push(
        this.invoiceService
          .saveLiquidatedDamage({
            run_id: data.run_id,
            date: data.date,
            description: data.description,
            value: data.value,
            credit_type: data.credit_type,
            credit_value: data.credit_value,
            active: data.active,
          })
          .subscribe(
            resp => {
              if (resp) {
                Swal.fire('Success!', 'Liquidated damage registered successfully', 'success').then(() => {
                  this.onAddLiquidated.emit(true);
                });
              }
            },
            error => {
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: error.message
              }).then();
            }
          )
      );
    } else {
      this.busy.push(
        this.invoiceService
          .updateLiquidatedDamage({
            id: this.liquidatedDamage.id,
            run_id: data.run_id,
            date: data.date,
            description: data.description,
            value: data.value,
            credit_type: data.credit_type,
            credit_value: data.credit_value,
            active: data.active,
          })
          .subscribe(
            resp => {
              if (resp) {
                Swal.fire('Success!', 'Liquidated damage updated successfully', 'success').then(() => {
                  this.onAddLiquidated.emit(true);
                });
              }
            },
            error => {
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: error.message
              }).then();
            }
          )
      );
    }
  }
}
