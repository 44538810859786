import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PagesSmartComponent } from '../../../theme/components/pages-smart/pages-smart.component';
import { InvoicesBatchComponent } from './invoices-batch/invoices-batch.component';
import { AuthGuard } from '../../../_guards/auth.guard';
import { NgxPermissionsGuard } from 'ngx-permissions';

const routes: Routes = [
  {
    path: 'billing/invoices',
    component: PagesSmartComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'invoices',
        component: InvoicesBatchComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          title: 'Invoices'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InvoicesRoutingModule { }
