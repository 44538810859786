import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs/Rx';
import { BatchesService } from '../../../../services/billing/batches.service';

@Component({
  selector: 'app-interruptions',
  templateUrl: './interruptions.component.html',
  styleUrls: ['./interruptions.component.scss']
})
export class InterruptionsComponent implements OnInit, OnDestroy, OnChanges {

  @Input() clearGrid ?: any;
  busy: Subscription;
  grid: GridOptions;
  count: number;

  constructor(private batchesService: BatchesService) {
    this.initGrid();
  }

  ngOnInit() {
    this.batchesService.load_batch$.subscribe((batch_id: any) => {
      this.busy = this.batchesService.getInterruptions(batch_id).subscribe((resp: any) => {
        if (this.grid.api) {
          this.grid.api.setRowData(resp);
          this.count = resp.length;
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['clearGrid']) {
      if (this.clearGrid) {
        this.grid.api.setRowData([]);
      }
    }
  }

  ngOnDestroy(): void {
    this.busy ? this.busy.unsubscribe() : null;
  }

  initGrid() {
    this.grid = <GridOptions>{
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      rowSelection: 'single',
      enableRangeSelection: true,
      enableFilter: true,
      onGridReady: () => {
        this.grid.api.setRowData([]);
      },
      columnDefs: [
        { headerName: 'Date', field: 'date', width: 100 },
        { headerName: 'Vehicle', field: 'vehicle', width: 100 },
        { headerName: 'Type', field: 'vehicle_type', width: 100 },
        { headerName: 'Time', field: 'time', width: 100 },
        { headerName: 'Route', field: 'route', width: 150 },
        { headerName: 'Block', field: 'block', width: 150 },
        { headerName: 'Incident Description', field: 'incident_description', width: 200 },
        { headerName: 'Incident Detail', field: 'incident_detail', width: 200 }
      ]
    };
  }

}
