import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-t2-collapse',
  template: `
    <div (click)="collapsed()">
      <i *ngIf="collapse" class="fa fa-arrow-right"></i>
      <i *ngIf="!collapse" class="fa fa-arrow-left"></i>
    </div>
  `,
  styleUrls: ['./t2collapse.component.scss']
})

export class T2CollapseComponent implements OnInit {

  @Output() changeCollapse = new EventEmitter();

  collapse = false;

  constructor() { }

  ngOnInit() { }

  collapsed() {
    this.collapse = !this.collapse;
    this.changeCollapse.emit(this.collapse);
  }
}
