import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '../../../../user.model';
import { Password } from './password';
import { Subscription } from 'rxjs';
import { LayoutDataService } from './../../layout.data.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  public busy: Subscription;
  public apiResponse: any;
  public password: Password = new Password();

  @Input()
  infoUser: User;
  @Output()
  onClose = new EventEmitter<Number>();
  @Output()
  notify: EventEmitter<any> = new EventEmitter<any>();

  viewPassCurrent = false;
  viewPassNew = false;
  viewPassConfirm = false;
  validregex = false;

  constructor(private _dataService: LayoutDataService) {}

  ngOnInit() {}

  close() {
    this.onClose.emit(2);
  }

  validatedPassword(event) {
    this.validregex = event;
  }

  onSave() {
    if (this.password.newPassword === this.password.newPaswordConfirm) {
      delete this.password.newPaswordConfirm;
      this.busy = this._dataService
        .putData(this.password, 'users/changePassword')
        .subscribe(data => {
          alert('Password changed successfully');
          this.close();
        });
    } else {
      alert('The confirmation of the password is not correct');
    }
  }
}
