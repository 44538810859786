import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { VehicleService } from '../../../../../services/fleet/vehicle.service';
import { TsoAssignVehicleService } from '../../../../../services/tso-assign-vehicle.service';
import { TsoService } from '../../../../../services/Tso.service';
import { DashboardLsfDataService } from '../../../dashboard-lsf.data.service';
import * as moment from 'moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { BsModalComponent } from 'ng2-bs3-modal';
import { BlockStatusEnum } from '../../../../../CORE/enums/BlockStatusEnum.enum';
import { SweetAlertHelper } from '../../../../../CORE/helpers/sweet-alert-helper.service';
import { WorkflowService } from '../../../../../services/billing/workflow.service';

@Component({
  selector: 'app-edit-daily',
  templateUrl: './edit-daily.component.html',
  styleUrls: ['./edit-daily.component.scss']
})

export class EditDailyComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('blockOff') blockOff: ElementRef;
  @ViewChild('modalDriverCurrentJob') modalDriverCurrentJob: BsModalComponent;

  @Input() schedule_seleccionado: any;
  @Input() datestart: any;
  @Input() drivers: any = [];
  @Input() allDrivers: any = [];


  @Input() vehicles: any = [];
  @Output() onClose?: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() notify?: EventEmitter<any> = new EventEmitter<any>();

  public busy: Subscription[] = [];
  public subscriptions: Subscription[] = [];
  public time_format = [/[0-9]/, /\d/, ':', /\d/, /\d/];
  public driver_id: any = '';
  public vehicle_id: any = '';
  public driver_selecte: any = {};
  public vehicle_selecte: any = {};
  trainee_selecte: any;

  enable = true;
  permisoEditHours = false;
  private apiResponse: any;

  public objTem: any = {};

  assignVehicle: any = <any>{};
  fixedSchedule: boolean;
  statusTSO = false;
  scheduleTSOType: any;

  stops: any = [];
  isOpenByWorkFlowModule: boolean;
  constructor(
    private sweetAlertHelper: SweetAlertHelper,
    private _dataService: DashboardLsfDataService,
    private _sanitizer: DomSanitizer,
    public toastr: ToastrService,
    private _vehicleService: VehicleService,
    private _serviceTso: TsoService,
    private _serviceTsoAssignVehicle: TsoAssignVehicleService,
    private tsoService: TsoService,
    private ngxPermissionsService: NgxPermissionsService,
    private workFlowService: WorkflowService
  ) {
  }


  get shceduleSelected(): boolean {
    return Number(this.schedule_seleccionado.block_statu_id) !== BlockStatusEnum.Completed;
  }

  get shceduleSelected2(): boolean {
    return !this.permisoEditHours && this.schedule_seleccionado.block_statu_id === BlockStatusEnum.InService;
  }

  get shceduleSelected3(): boolean {
    return this.schedule_seleccionado.block_statu_id !== BlockStatusEnum.OutOfService;
  }

  async ngOnInit() {
    const previous = moment(this.datestart).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') ? true : false;
    const permisoSavePrevious = await this.ngxPermissionsService.hasPermission('btn-edit-previous-schedule');
    this.permisoEditHours = await this.ngxPermissionsService.hasPermission('btn-edit-hours-schedule');
    if (previous) {
      if (!permisoSavePrevious) {
        this.enable = false;
      } else {
        this.enable = true;
      }
    } else {
      this.enable = true;
    }

    this.driver_id = this.schedule_seleccionado.driver_id;
    this.vehicle_id = this.schedule_seleccionado.vehicle_id;
    this.driver_selecte = this.schedule_seleccionado.driver;
    this.vehicle_selecte = this.schedule_seleccionado.vehicle;

    this.objTem.route_description = this.schedule_seleccionado.route_description;
    this.objTem.block_description = this.schedule_seleccionado.block_description;
    this.objTem.block_code = this.schedule_seleccionado.block_code;

    if (this.schedule_seleccionado.block_statu_id == '6') {
      this.blockOff.nativeElement.checked = true;
    } else {
      this.blockOff.nativeElement.checked = false;
    }

    this.getAssignVehicle();

    if (this.schedule_seleccionado.tso_id) {
      this.loadDataStops(this.schedule_seleccionado.tso_id);
    }

    this.loadSubscriptions();
  }

  loadSubscriptions (): void {
    this.subscriptions.push(
      this.workFlowService.scheduleDetailModalIsOpen$.subscribe({
        next: (signal: boolean) => this.isOpenByWorkFlowModule = signal
      })
    );
  }

  ngOnChanges(change: SimpleChanges) {
    if (change['schedule_seleccionado'] && this.schedule_seleccionado) {
      if (this.schedule_seleccionado.trainee_id) {
        const resul = this.allDrivers.filter((row) => {
          return row.id == this.schedule_seleccionado.trainee_id;
        })[0];
        if (resul) {
          const trainee = `${resul.last_name} ${resul.first_name}`;
          this.trainee_selecte = resul;
        }
      }
    }
  }

  myValueFormatter(data: any): string {
    const html = data.employee_name;
    return html;
  }

  myValueFormatterVehicle(data: any): string {
    const html = data.description;
    return html;
  }

  myValueFormatterDriver(data: any): string {
    const html = `${data.last_name} ${data.first_name}`;
    return html;
  }

  autocompleListFormatter = (data: any) => {
    let color = 'label-default';
    if (data.color === 2) {
      color = 'label-warning';
    }
    if (data.color === 3) {
      color = 'label-success';
    }
    const html = `<div>
      ${data.employee_name}
      <span style="float:right" class="label ${color}">${data.total_time}</span>
    </div>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

  autocompleListFormatterVehicle = (data: any) => {
    const html = `<span>${data.description}</span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

  autocompleListFormatterDrivers = (data: any) => {
    const color = 'label-default';
    const html = `<div>
      ${data.last_name} ${data.first_name}
    </div>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

  valueChangedDriver(newVal: any) {
    if (newVal.driver_id) {
      this.driver_id = newVal.driver_id;
    }
  }

  valueChangedVehicle(newVal: any) {
    if (newVal.id) {
      this.vehicle_id = newVal.id;
      this.schedule_seleccionado.vehicle = newVal.description;
    }
  }

  close(edit: boolean = false) {
    if (!this.statusTSO) {
      this.onClose.emit(edit);
    }
  }

  onUpdate() {
    if (this.enable) {
      if (this.assignVehicle.schedule_id_assigned) {
        if (this.vehicle_id !== this.schedule_seleccionado.vehicle_id) {
          Swal.fire(
            'Warning!',
            'To assign a new vehicle, you must first designate the one that is currently.',
            'warning'
          );
          return false;
        }
      }
      if (this.shceduleSelected3) {
        if (this.statusTSO) {
          if (this.scheduleTSOType) {
            this.onMainTSOAssigned(this.vehicle_id);
          } else {
            Swal.fire(
              'Warning!',
              'Please select a Schedule TSO.',
              'warning'
            );
          }
        }
        /*delete this.schedule_seleccionado.driver;
        delete this.schedule_seleccionado.route_description;
        delete this.schedule_seleccionado.vehicle;
        delete this.schedule_seleccionado.block_description;
        delete this.schedule_seleccionado.block_code;*/

        if (this.vehicle_selecte !== '') {
          this.schedule_seleccionado.vehicle_id = this.vehicle_id;
        } else {
          this.schedule_seleccionado.vehicle_id = '';
        }

        if (this.driver_selecte == '') {
          this.schedule_seleccionado.driver_id = null;
        } else {
          this.schedule_seleccionado.driver_id = this.driver_id;
        }

        const report_time = this.schedule_seleccionado.report_time;
        const start_time = this.schedule_seleccionado.start_time;
        const end_time = this.schedule_seleccionado.end_time;
        const punch_out_time = this.schedule_seleccionado.punch_out_time;
        const leave_yard_time = this.schedule_seleccionado.leave_yard_time;

        if (this.validaTimesFormat(report_time, start_time, end_time, punch_out_time, leave_yard_time)) {
          const response: any = this.validaTimerDay(report_time, start_time, end_time, punch_out_time, leave_yard_time);
          if (!response.status) {
            alert(response.messsage);
          } else {
            if (response.confirm) {
              const r = confirm(response.messsage);
              if (r == true) {
                this.updateSet(response.report_time, response.start_time, response.end_time, response.punch_out_time);
              }
            } else {
              this.updateSet(response.report_time, response.start_time, response.end_time, response.punch_out_time);
            }
          }
        }
      } else {
        Swal.fire(
          'Warning!',
          'This route is out of service this operation is not allowed.!',
          'warning'
        );
      }
    } else {
      Swal.fire(
        'Warning!',
        'Operation not allowed',
        'warning'
      );
    }
  }

  updateSet(report_time, start_time, end_time, punch_out_time) {
    this.schedule_seleccionado.report_time = report_time;
    this.schedule_seleccionado.start_time = start_time;
    this.schedule_seleccionado.end_time = end_time;
    this.schedule_seleccionado.punch_out_time = punch_out_time;

    const blockOff = this.blockOff.nativeElement.checked;
    if (blockOff) {
      this.schedule_seleccionado.block_statu_id = '6'; // Si esta activo el checked de Off le asignamos el valor 6 que
      // corresponde al estado off en blockestatus
    } else {
      if (report_time && start_time && end_time && punch_out_time && this.schedule_seleccionado.driver_id && this.schedule_seleccionado.vehicle_id) {
        if (!this.isOpenByWorkFlowModule) {
          this.schedule_seleccionado.block_statu_id = '4';
        }
      } else {
        this.schedule_seleccionado.block_statu_id = '1';
      }
    }

    if (this.trainee_selecte) {
      if (this.trainee_selecte.id == this.schedule_seleccionado.driver_id) {
        Swal.fire(
          'Warning!',
          'Please check the trainer!',
          'warning'
        );
        return false;
      }
    }

    if (this.schedule_seleccionado.trainee) {
      if (this.trainee_selecte && this.trainee_selecte.hasOwnProperty('id')) {
        this.schedule_seleccionado.trainee_id = this.trainee_selecte.id;
      } else {
        Swal.fire(
          'Warning!',
          'Please select a tranne!',
          'warning'
        );
        return false;
      }
    } else {
      delete this.schedule_seleccionado.trainee_id;
      delete this.schedule_seleccionado.trainee;
    }
    this.putScheduleDetail();
  }

  putScheduleDetail() {
    this.busy.push(this._dataService.putData(this.schedule_seleccionado, 'schedules/details/' + this.schedule_seleccionado.id).subscribe(
      (data: any) => {
        if (data) {
          this.apiResponse = data;
          if (this.apiResponse.transaction) {
            delete this.schedule_seleccionado.confirmation;
            this.apiResponse.master.route_description = this.objTem.route_description;
            this.apiResponse.master.block_description = this.objTem.block_description;
            this.apiResponse.master.block_code = this.objTem.block_code;
            this.close(true);
            this.notify.emit(this.apiResponse);
          } else {
            if (this.apiResponse.confirmation) {
              const r = confirm(this.apiResponse.message);
              if (r == true) {
                this.schedule_seleccionado.confirmation = true;
              }
            } else {
              this.busy.push(this._dataService.putData(this.schedule_seleccionado, 'schedules/details/' + this.schedule_seleccionado.id).subscribe(
                (data: any) => {
                  if (data) {
                    this.apiResponse = data;
                    if (this.apiResponse.transaction) {
                      delete this.schedule_seleccionado.confirmation;
                      this.apiResponse.master.route_description = this.objTem.route_description;
                      this.apiResponse.master.block_description = this.objTem.block_description;
                      this.apiResponse.master.block_code = this.objTem.block_code;
                      this.close(true);
                      this.notify.emit(this.apiResponse);
                    }
                  }
                },
                (error) => {
                  this.sweetAlertHelper.createCustomAlert({
                    title: 'Driver Assignment Error',
                    html: `<h4>${error.error.message}</h4>`,
                    type: 'warning'
                  });
                }
              ));
            }
          }
        }
      },
      (error) => {
        this.sweetAlertHelper.createCustomAlert({
          title: 'Driver Assignment Error',
          html: `<h4>${error.error.message}</h4>`,
          type: 'warning'
        });
      }
    ));
  }

  validHours() {
    const formatDate = 'YYYY-MM-DD HH:mm:ss';
    const data = Object.assign({}, this.schedule_seleccionado);
    const yardTime = data.leave_yard_time;
    const endTime = data.end_time;
    if (yardTime && endTime) {
      const today = moment().format('YYYY-MM-DD');
      const morning = moment().add(1, 'days').format('YYYY-MM-DD');
      const yardDate = moment.utc(`${today} ${yardTime}:00`, formatDate);
      const dateMax = moment.utc(moment.utc(`${today} ${endTime}:00`, formatDate).add(930, 'minutes').format(formatDate), formatDate); // hora maxima permitida (13 horas)
      let endDate = moment.utc(`${today} ${endTime}:00`, formatDate);
      let endDateMax = moment.utc(moment.utc(`${today} ${endTime}:00`, formatDate).add(30, 'minutes').format(formatDate), formatDate);
      if (endDate.isBefore(yardDate)) {
        endDate = moment.utc(`${morning} ${endTime}:00`, formatDate);
        endDateMax = moment.utc(moment.utc(`${morning} ${endTime}:00`, formatDate).add(30, 'minutes').format(formatDate), formatDate);
      }
      if (endDateMax.isAfter(dateMax)) {
        Swal.fire({
          title: 'The end time is over 13 hours',
          text: `Do you want to continue?`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes!',
          cancelButtonText: 'Cancel'
        }).then((result) => {
          if (!result.value) {
            this.schedule_seleccionado.end_time = '';
          }
        });
      }
    }
  }

  validaTimesFormat(timer1, timer2, timer3, timer4, timer5) {
    const pattern = new RegExp('(0[0-9]|1[0-9]|2[0-9]|3[0-6])(:[0-5][0-9]){1}');
    const boolTimer1 = pattern.test(timer1);
    const boolTimer2 = pattern.test(timer2);
    const boolTimer3 = pattern.test(timer3);
    const boolTimer4 = pattern.test(timer4);
    const boolTimer5 = pattern.test(timer5);

    if (boolTimer1 && boolTimer2 && boolTimer3 && boolTimer4 && boolTimer5) {
      return true;
    } else {
      return false;
    }
  }

  validaTimerDay(report_time, start_time, end_time, punch_out_time, leave_yard_time) {
    const objT: any = {};
    if (report_time < start_time && start_time < end_time && end_time < punch_out_time && leave_yard_time >= report_time && leave_yard_time <= start_time) {
      objT.status = true;
      objT.messsage = 'Normal insertion';
    } else {
      if ((report_time < start_time && end_time < punch_out_time) || (report_time > start_time && end_time < punch_out_time)) {
        if ((parseInt(end_time.substr(0, 2)) < 23) || (parseInt(punch_out_time.substr(0, 2)) < 23)) {
          const hora1 = parseInt(end_time.substr(0, 2)) + 24;
          const hora2 = parseInt(punch_out_time.substr(0, 2)) + 24;

          end_time = hora1 + ':' + end_time.substr(3, 2);
          punch_out_time = hora2 + ':' + punch_out_time.substr(3, 2);
        }

        objT.status = true;
        objT.confirm = true;
        objT.messsage = 'the values ​​of *End Time and* and *Punch Out Time*  are lower than expected, is it possible that this data corresponds to the hours of the next day you want to continue?';
      } else {
        objT.status = false;
        objT.messsage = 'please check the times, the values ​​are not correct';
      }
    }
    objT.report_time = report_time;
    objT.start_time = start_time;
    objT.end_time = end_time;
    objT.punch_out_time = punch_out_time;

    return objT;
  }

  clearSearch() {
    this.driver_selecte = '';
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const x = event.keyCode;
    if (x === 27) {
      this.close();
    }
  }

  onMainTSOAssigned(vehicle_id) {
    this.busy.push(this._vehicleService.getVehicle(vehicle_id).subscribe(
      (data: any) => {
        const UniteID = parseInt(data.tso_id);
        const RouteID = this.schedule_seleccionado.tso_id;
        if (!UniteID) {
          Swal.fire(
            'Error!',
            'This vehicle has not relation with TSO...!',
            'error'
          );
        } else if (!RouteID) {
          Swal.fire(
            'Error!',
            'This route has not relation with TSO...',
            'error'
          );
        } else {

          this.assignVehicle.unitID = UniteID;
          this.assignVehicle.routeID = RouteID;
          this.assignVehicle.scheduleID = this.scheduleTSOType == 'block' ? this.schedule_seleccionado.route_block_tso_id : '-1';

          this.assignVehicle.schedule_detail_id = this.schedule_seleccionado.id;
          this.verificarUnidad(UniteID, RouteID, (data) => {
            if (data == null) {
              this.busy.push(this._serviceTsoAssignVehicle.tsoAssignVehicle(this.assignVehicle).subscribe(
                (data: any) => {
                  if (data.Code == 1) {
                    Swal.fire(
                      'Assigned!',
                      'Vehicle assigned in TSO. ID: ' + data.Data,
                      'success'
                    );
                    this.getAssignVehicle();
                    this.onClose.emit(true);
                  }
                }));
            } else {
              Swal.fire({
                title: data.details,
                text: 'Please verify that the unit is not assigned in TSO.\nDo you want to try to unassign this vehicle from TA?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
              }).then((result) => {
                if (result.value) {
                  this.onUnassignVehicle(false, data.schedule_id_assigned, data.schedule_detail_id);
                  // aqui debo desactinar en donde esta asignado... que lo retorna el servicio...
                }
              });
            }
          });
        }
      }));
  }

  getAssignVehicle() {
    this.busy.push(this._serviceTsoAssignVehicle.getAssignVehicle(this.schedule_seleccionado.id).subscribe(
      (data: any) => {
        if (data) {
          this.assignVehicle = data;
          this.fixedSchedule = this.assignVehicle.fixedSchedule;
        }
      }
    ));
  }

  onUnassignVehicle(close?: boolean, scheduleID?: number, schedule_detail_id?: number) {
    this.busy.push(this._serviceTsoAssignVehicle.unassignVehicle(scheduleID ? scheduleID : this.assignVehicle.schedule_id_assigned, schedule_detail_id ? schedule_detail_id : this.schedule_seleccionado.id).subscribe(
      (data: any) => {
        if (data.Code == 1) {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
          this.assignVehicle = <any>{};
          if (close) {
            this.onClose.emit(true);
          }
        } else {
          Swal.fire(
            'Oops...!',
            data.message,
            'error'
          );
        }
      }
    ));
  }

  verificarUnidad(UniteID: number, routeID: number, callback) {
    this.busy.push(this._serviceTsoAssignVehicle.verificationUnit(UniteID, routeID).subscribe(
      (data: any) => {
        callback(data);
      }
    ));
  }

  loadDataStops(tso_id) {
    this.busy.push(this.tsoService.getStopsByRoute(tso_id, false).subscribe((resp: any) => {
      this.stops = resp;
    }));
  }

  openDriverCurrentJobModal() {
    this.modalDriverCurrentJob.open();
  }

  onCloseDriverCurrentJobModal() {
    this.modalDriverCurrentJob.close();
  }

  ngOnDestroy(): void {
    this.busy.forEach((row: Subscription) => row.unsubscribe());
    this.subscriptions.forEach((row: Subscription) => row.unsubscribe());
  }

}
