export interface IMenuReport {
  id:                 number;
  client_id:          string;
  small_cities:       boolean;
  description:        string;
  url:                number;
  icon:               string;
  order:              number;
  form_id:            number;
  active:             boolean;
  form_params: IFormParam[];
}

export interface IFormParam {
  id:             number;
  menu_report_id: string;
  param_name:     string;
  code:           string;
  array:          string;
  default_values: string;
  type_free_text: string;
}

export interface IBatchInfoByRate {
  batch_id:           number;
  total_manifest:     number;
  service_hours:      number;
  rate:               number;
  total_amount:       number;
  unit:               string;
  fee_schedule_id:    number;
  total:              number;
  time_interruption:  string;
  total_interruption: string;
}

export interface IBatchCharge {
  id:                         number;
  batch_id:                   number;
  batch_charge_type_id:       number;
  batch_charge_type_name:     string;
  invoice_date:               string;
  total_amount:               number;
  client_name:                string;
  invoice_status_id:          string;
  invoice_num:                string;
  invoice_status_description: string;
  route_id:                   number;
  user_name?:                 string;
  user_id?:                   string;
  disabled_date?:             any;
  parent_id?:                 number;
  fee_schedule_id?:           any;
  is_active:                  number;
}

export interface IStatusOrTypes {
  id:          number;
  description: string;
}

export interface IBatchChargeStatus {
  id:          number;
  description: string;
  is_active:   boolean;
  created_at:  string;
  updated_at:  string;
}

export interface IBatchCredit {
  id: number;
  invoice_batch_charge_id?:      number;
  credit_num:                    string;
  batch_credit_type_description: string;
  batch_credit_type_id:          number;
  amount:                        number;
  credit_state_id:               number;
  is_active:                     number;
  check_number:                  string;
  check_date:                    string;
  deposit_date:                  string;
  batch_id:                      string;
  invoice_num:                   string;
  paid_date?:                    string;
  disabled_date?:                string;
  user_name?:                    string;
  user_id?:                      string;
}

export interface IBatchCreditType {
  id:            number;
  description:   string;
  charge_type:   string;
  is_active:     boolean;
}

export interface IBatchInfoByRoutes {
  route_id:                 number;
  route_description:        string;
  total_manifest:           number;
  service_hours:            number;
  rate:                     number;
  total_amount:             number;
  batch_id:                 string;
  total:                    number;
  time_interruption:        number;
  total_interruption:       number;
  total_liquidated_damages: string;
  total_buses_fueled:       number;
}

export interface IRoute {
  id:              number;
  client_id:       number;
  route_code:      string;
  description:     string;
  active:          boolean;
  tso_id:          string;
  exclude:         boolean;
  desc_billing:    string;
  exclude_reports: boolean;
  billing_number:  number;
  po_number:       number;
  route_group_id:  string;
}

export interface IConceptValuePayload {
  date_start:           string;
  date_end:             string;
  client_id:            number;
  batch_charge_type_id: number;
  route_id:             number;
}

export interface IBatchTotalInfo {
  totalManifest: number;
  totalHours:    string;
  totalAmount:   number;
}

export interface ISweetAlertFeeScheduleError {
  title: string;
  body: string [];
}

export interface ISubjectFeeScheduleErrorModal {
  open: boolean;
  listOfCar: string [];
  clientId: number;
}

export interface IBatchBalance {
  charges: number;
  credits: number;
  total:   number;
}

export enum ChargeBillingStatus {
  Original = 'Original',
  Revised = 'Revised',
    All = 'all'
}
