export const dataMenu = [
  {
    name: 'Admin',
    permission: null,
    icon: 'ico fa fa-cogs menu_icon',
    route: null,
    childrens: [
      {
        name: 'Security',
        permission: null,
        icon: 'fa fa-shield',
        route: null,
        childrens: [
          {
            name: 'Permissions',
            permission: 'route-permissions',
            route: '/admin/permissions',
          },
          {
            name: 'Roles',
            permission: 'route-roles',
            route: '/admin/roles',
          },
          {
            name: 'Users',
            permission: 'route-users',
            route: '/admin/users',
          },
          {
            name: 'Users Type',
            permission: 'route-user-type',
            route: '/admin/user-type',
          },
          {
            name: 'Biling Setting',
            permission: 'bilingSetting',
            route: '#',
          }
        ]
      },
      {
        name: 'Fleet',
        permission: null,
        icon: 'fa fa-car',
        route: null,
        childrens: [
          {
            name: 'Clients',
            permission: 'route-clients',
            route: '/fleet/clients',
          },
          {
            name: 'Drivers',
            permission: 'route-drivers',
            route: '/fleet/drivers',
          },
          {
            name: 'Routes',
            permission: 'route-routes-block',
            route: '/fleet/routes-block',
          },
          {
            name: 'Vehicles',
            permission: 'route-vehicles',
            route: '/fleet/vehicles',
          },
          {
            name: 'Drivers, Routes',
            permission: null,
            route: '/fleet/drivers-routes',
          },
          {
            name: 'Stops',
            permission: 'route-vehicles',
            route: '/stops/stops',
          },
          {
            name: 'Route Group',
            permission: 'route-group',
            route: '/fleet/route-group'
          }
        ],
      },
      {
        name: 'Billing',
        permission: null,
        icon: 'ico fa fa-dollar',
        route: null,
        childrens: [
          {
            name: 'Invoice Status',
            permission: 'route-invoice-status',
            route: '/admin/billing/invoice-status',
          },
          {
            name: 'Form',
            permission: 'route-billing-form',
            route: '/admin/billing/form',
          },
          {
            name: 'Credit Status',
            permission: 'route-credit-status',
            route: '/admin/billing/credit-status',
          }
        ]
      },
      {
        name: 'Parameters',
        permission: null,
        icon: 'fa fa-toggle-off',
        route: null,
        childrens: [
          {
            name: 'Documents',
            permission: 'route-documents',
            route: '/parametrization/documents',
          },
          {
            name: 'Inspection Categories',
            permission: 'route-inspection-categories',
            route: '/parametrization/inspection-categories',
          },
          {
            name: 'Inspection Items',
            permission: 'route-inspection-items',
            route: '/parametrization/inspection-items',
          },
          {
            name: 'Incidents Categories',
            permission: 'route-incidents-categories',
            route: '/parametrization/incidents-categories',
          },
          {
            name: 'Incidents Items',
            permission: 'route-incidents-items',
            route: '/parametrization/incidents-items',
          },
          {
            name: 'Interruption Notifi.',
            permission: 'interruption-notification-items',
            route: '/parametrization/interruption-notification',
          },
          {
            name: 'Billing Categories',
            permission: 'route-billing-categories',
            route: '/parametrization/billing-categories',
          },
          {
            name: 'SMTP Config',
            permission: 'route-smtp-config',
            route: '/parametrization/smtp-config'
          }
        ],
      },
      {
        name: 'Companies',
        permission: 'route-companies',
        icon: 'fa fa-building-o',
        route: '/companies/companies'
      },
      {
        name: 'Gtfs',
        permission: 'route-admin-gtfs-files',
        icon: 'fa fa-file-text-o',
        route: '/admin/gtfs/list'
      },
      {
        name: 'Logs Backend',
        permission: 'route-admin-logs-files',
        icon: 'fa fa-history',
        route: '/admin/logs/list'
      },
    ],
  },
  {
    name: 'Passenger',
    permission: 'route-client-dashboard',
    icon: 'fa fa-users menu_icon',
    route: '/client/dashboard',
    childrens: [],
  },
  {
    name: 'Dispatch',
    permission: null,
    route: null,
    icon: 'ico fa fa-clock-o menu_icon',
    childrens: [
      {
        name: 'Real Time',
        icon: 'fa fa-map-marker',
        permission: 'route-dispatch-realtime',
        route: '/dispatch/real-time',
      },
      {
        name: 'GPS History',
        icon: 'fa fa-history',
        permission: 'route-dispatch-history',
        route: '/dispatch/gps-history',
      },
      {
        name: 'OTP',
        icon: 'fa fa-line-chart',
        permission: 'route-dispatch-otp',
        route: '/dispatch/otp',
      },
      {
        name: 'Speed Info',
        icon: 'fa fa-tachometer',
        permission: 'route-dispatch-speedinfo',
        route: '/dispatch/speed-info',
      },
    ]
  },
  {
    name: 'Dashboard',
    permission: 'route-dashboard-schedule',
    icon: 'ico fa fa-bar-chart menu_icon',
    route: '/dashboard/schedule',
    childrens: [],
  },
  {
    name: 'Schedules',
    permission: 'route-lsf',
    icon: 'ico fa fa-calendar menu_icon',
    route: '/dashboard/lsf',
    childrens: [],
  },
  {
    name: 'Payroll',
    permission: null,
    route: null,
    icon: 'ico fa fa-money menu_icon',
    childrens: [
      {
        name: 'Interruption Details',
        permission: 'route-interruption-details',
        route: '/payroll/interruption-details',
      },
      {
        name: 'Time Cards',
        permission: 'route-driver-master-schedule',
        route: '/payroll/driver-master-schedule',
      },
      {
        name: 'Admin',
        permission: 'route-payroll-rules',
        route: '/payroll/rules',
      },
      {
        name: 'Tops',
        permission: 'route-tops-admin',
        route: '/payroll/tops',
      },
      {
        name: 'Clock Log',
        permission: 'route-payroll-clock-log',
        route: '/payroll/clock-log',
      },
      {
        name: 'Exceptions report',
        permission: 'route-exceptions-report-ta',
        route: '/payroll/exceptions-report/ta',
      }
    ],
  },
  {
    name: 'Billing',
    permission: null,
    route: null,
    icon: 'ico fa fa-dollar menu_icon',
    childrens: [
      {
        name: 'Clients',
        permission: null,
        route: null,
        icon: 'fa fa-id-card',
        childrens: [
          {
            name: 'Workflow',
            permission: 'route-unprocessed-billing',
            route: '/billing/workflow',
          },
          {
            name: 'Unprocessed',
            permission: 'route-unprocessed-billing',
            route: '/billing/unprocessed',
          },
          {
            name: 'Batch History',
            permission: 'route-unprocessed-billing',
            route: '/billing/batch/history'
          },
          {
            name: 'Invoices',
            permission: 'route-unprocessed-billing',
            route: '/billing/invoices/invoices'
          }
        ]
      }
    ],
  },
  {
    name: 'Driver Manifest',
    permission: null,
    icon: 'ico fa fa-file-text-o menu_icon',
    route: null,
    childrens: [
      {
        name: 'Manual',
        permission: 'route-driver-manifest',
        route: '/manifests/driver-manifest',
      },
      {
        name: 'Mobile',
        permission: 'route-movil',
        route: '/manifests/movil',
      }
    ],
  },
  {
    name: 'Tops',
    permission: null,
    route: null,
    img: 'assets/icons/van.svg',
    childrens: [
      {
        name: 'Dailyview',
        permission: 'route-tops-employees',
        route: '/tops/employees',
      },
      {
        name: 'Drivers',
        permission: 'route-tops-drivers',
        route: '/tops/drivers',
      },
      {
        name: 'Routes',
        permission: 'route-tops-routes',
        route: '/tops/routes',
      },
      {
        name: 'Timecards',
        permission: 'route-tops-timecards',
        route: '/tops/timecards',
      },
      {
        name: 'Exceptions report',
        permission: 'route-exceptions-report-tops',
        route: '/tops/exceptions-report/tops',
      }
    ],
  },
  {
    name: 'Reports',
    permission: 'route-reports',
    icon: 'ico fa fa-folder-open-o menu_icon',
    route: null,
    childrens: [
      {
        name: 'Old Reports TA',
        permission: 'route-reports-ta',
        route: '/reports/reports'
      },
      {
        name: 'Reports',
        permission: 'route-reports',
        route: '/reports/reportsManager'
      },
      {
        name: 'Report Settings',
        permission: 'route-reports',
        route: '/reports/reportsTrip2'
      }
    ],
  },
  {
    name: 'Real Time',
    permission: 'route-dispatch-realtime-client',
    icon: 'ico fa fa-map-marker menu_icon',
    route: '/dispatch/real-time'
  },
];
