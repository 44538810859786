import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaModalComponent } from './ta-modal/ta-modal.component';
import { BsModalModule } from 'ng2-bs3-modal';
import { InterruptionsGridComponent } from './interruptions-grid/interruptions-grid.component';
import { NgBusyModule } from 'ng-busy';
import { AgGridModule } from 'ag-grid-angular';

@NgModule({
  imports: [
    CommonModule,
    AgGridModule.withComponents([]),
    BsModalModule,
    NgBusyModule
  ],
  declarations: [TaModalComponent, InterruptionsGridComponent],
  exports: [
    TaModalComponent,
    InterruptionsGridComponent
  ]
})
export class ComponentsModule { }
