import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs/Rx';
import { BatchInvoicesService } from './../../../../../services/billing/batch/batch-invoices.service';
import { BatchInvoicesStatusService } from './../../../../../services/billing/batch/batch-invoices-status.service';
import Swal from 'sweetalert2';
import { takeUntil } from 'rxjs/operators';
import {InvoiceReportService} from '../../../workflow/invoice-report/invoice-report.service';
import {SweetAlertHelper} from '../../../../../CORE/helpers/sweet-alert-helper.service';

@Component({
  selector: 'app-add-invoice',
  templateUrl: './add-invoice.component.html',
  styleUrls: ['./add-invoice.component.scss']
})
export class AddInvoiceComponent implements OnInit, OnDestroy {
  private destroy: Subject<boolean> = new Subject<boolean>();

  formInvoice: FormGroup;
  busy: Subscription[] = [];
  @Input() total_amount: number;
  @Output() onClose? = new EventEmitter<any>();
  @Output() onReload? = new EventEmitter<any>();
  selectedInvoicesStatus: any = [];
  isOpenInInvoiceReportModal = false;

  constructor(
    private fb: FormBuilder,
    private batchInvoicesService: BatchInvoicesService,
    private batchInvoicesStatusService: BatchInvoicesStatusService,
    private invoiceReportService: InvoiceReportService,
    private sweetAlertHelper: SweetAlertHelper
  ) {
    this.initForm();
  }

  ngOnInit() {
    this.subscribeObservers();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.total_amount) {
      if(this.total_amount) {
        this.formInvoice.patchValue({
          total_amount: this.total_amount
        })
      }
    }
  }

  subscribeObservers() {
    this.batchInvoicesStatusService.InvoiceStatus$.pipe(takeUntil(this.destroy)).subscribe(
      (invoiceStatus: any) => {
        if (invoiceStatus) {
          this.selectedInvoicesStatus = invoiceStatus;
        }
      },
      error => {
        Swal.fire({
          type: 'error',
          title: 'Error...',
          text: error
        });
      }
    );
    this.batchInvoicesService.batchRow$.pipe(takeUntil(this.destroy)).subscribe(batch => {
      const { fee_schedule_id } = batch;
      this.formInvoice.patchValue({fee_schedule_id});

      if (batch && typeof batch !== 'object') {
        this.formInvoice.patchValue({ batch_id: batch });
      } else {
        this.formInvoice.patchValue({ batch_id: batch.batch_id });
      }
    });

    this.batchInvoicesService.editInvoiceData$.pipe(takeUntil(this.destroy)).subscribe((resp: any) => {
      if (resp) {
        if (resp.invoice_num) {
          this.formInvoice.patchValue({
            id: resp.id,
            batch_id: resp.batch_id,
            invoice_num: resp.invoice_num,
            invoice_date: resp.invoice_date,
            total_amount: resp.total_amount,
            invoice_status_id: resp.invoice_status_id,
            disabled_date: null
          });
        }
      }
    });

    this.invoiceReportService.isOpenInInvoiceReportAction$.pipe(takeUntil(this.destroy)).subscribe({
      next: (isOpen: boolean) => this.isOpenInInvoiceReportModal = isOpen
    });
  }

  ngOnDestroy() {
    this.destroy.next(true);
    this.destroy.unsubscribe();
    return (this.busy = []);
  }

  initForm() {
    this.formInvoice = this.fb.group({
      id: [''],
      batch_id: ['', Validators.required],
      invoice_num: ['', Validators.required],
      invoice_date: ['', Validators.required],
      total_amount: ['', Validators.required],
      invoice_status_id: ['', Validators.required],
      fee_schedule_id: ['']
    });
  }

  onCancel() {
    this.formInvoice.reset();
    this.onClose.emit();
  }

  onSave(): void {
    this.isOpenInInvoiceReportModal ? this.saveOnInvoiceReport() : this.save();
  }

  saveOnInvoiceReport(): void {
    const formData = this.formInvoice.value;
    const {id} = formData;

    if (id) {
      this.invoiceReportService.updateBillingChargeBatch(id, formData)
        .subscribe({
          next: (response: any): void => {
            if (response.status === 'success') {
              this.sweetAlertHelper.createCustomAlert({
                type: 'success',
                title: 'invoice has been updated.',
                showConfirmButton: true
              }).then(() => this.emitEvents(formData));
            }
          },
          error: (error) => this.sweetAlertHelper.captureException(error)
        });
    } else {
      this.invoiceReportService.createBillingChargeBatch(formData)
        .subscribe({
          next: (response): void => {
            if (response.status === 'success') {
              this.sweetAlertHelper.createCustomAlert({
                type: 'success',
                title: 'invoice has been updated.',
                showConfirmButton: true
              }).then(() => this.emitEvents(formData));
            }
          },
          error: (error) => this.sweetAlertHelper.captureException(error)
        });
    }
  }

  emitEvents(formData): void {
    this.onReload.emit(formData);
    this.onClose.emit();
    this.batchInvoicesService.loadGridData$.next(true);
  }

  save() {
    const formData = Object.assign({}, this.formInvoice.getRawValue());
    if (formData.id) {
      this.batchInvoicesService.editBatchinvoice(formData).subscribe(
        (resp: any) => {
          if (resp) {
            Swal.fire({
              type: 'success',
              title: 'invoice has been updated.',
              showConfirmButton: true
            }).then(() => {
              this.onReload.emit(formData);
              this.onClose.emit();
              this.batchInvoicesService.loadGridData$.next(true);
            });
          }
        },
        error => {
          Swal.fire({
            type: 'error',
            title: 'Error...',
            text: error.message
          });
        }
      );
    } else {
      this.batchInvoicesService.addBatchInvoice(formData).subscribe(
        (resp: any) => {
          if (resp) {
            Swal.fire({
              type: 'success',
              title: 'invoice has been saved.',
              showConfirmButton: true
            }).then(() => {
              this.onReload.emit(formData);
              this.onClose.emit();
              this.batchInvoicesService.loadGridData$.next(true);
            });
          }
        },
        error => {
          Swal.fire({
            type: 'error',
            title: 'Error...',
            text: error.message
          });
        }
      );
    }
  }
}
