import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CONSTANTS } from '../../app.constants';

export interface DocumentObj {
  id: number;
  user_id: number;
  description: string;
  forType: string;
  expiration: boolean;
  file_optional: boolean;
  info_document: boolean;
  status: boolean;
  code: number;
}

export interface Attach {
  id?: number;
  user_id?: number;
  model_id?: number;
  model?: string;
  document_id?: number;
  expiration?: string;
  file?: any;
  status?: boolean;
  approved?: boolean;
  addedOn?: string;
  type?: string;
}

@Injectable()
export class DocumentsService {
  protected resource: string;

  private documentIdSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public documentId$ = this.documentIdSubject.asObservable();

  private saveDocumentSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public saveDocument$ = this.saveDocumentSubject.asObservable();

  private codeDocumentSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public codeDocument$ = this.codeDocumentSubject.asObservable();

  constructor(private http: HttpClient) {
    this.resource = environment.url_api_v + CONSTANTS.PARAMETRIZATION_DOCUMENTS;
  }

  setDocumentId(id: number) {
    this.documentIdSubject.next(id);
  }

  setSaveDocument(status: boolean) {
    this.saveDocumentSubject.next(status);
  }

  setCodeDocument(code: number) {
    this.codeDocumentSubject.next(code);
  }

  /**
   * @description Return all drivers
   * @returns {Observable<any>}
   */
  getDocuments(): Observable<any> {
    return this.http.get<any>(this.resource);
  }

  /**
   * @description Save a driver
   * @param data
   * @returns {Observable<Response>}
   */
  storeDocument(data: any): Observable<Response> {
    return this.http.post<Response>(this.resource, JSON.stringify(data));
  }

  /**
   * @description Update a driver
   * @param data
   * @returns {Observable<Response>}
   */
  updateDocument(data: any, id: number): Observable<Response> {
    return this.http.put<Response>(
      this.resource + '/' + id,
      JSON.stringify(data)
    );
  }

  /**
   * @description Get a driver
   * @param {number} id
   * @returns {Observable<any>}
   */
  getDocument(id: number): Observable<any> {
    return this.http.get(this.resource + '/' + id);
  }

  /**
   * @description
   * @param {boolean} status
   * @returns {Observable<any>}
   */
  getDocumentsStatus(status: boolean): Observable<any> {
    if (status) {
      return this.http.get(this.resource);
    } else {
      return this.http.get(this.resource + '/active/1');
    }
  }
}
