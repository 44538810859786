import { CommonModule } from '@angular/common';
// show-errors.component.ts
import { Component, Input, NgModule } from '@angular/core';
import { AbstractControlDirective, AbstractControl } from '@angular/forms';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'show-errors',
    template: `
   <ul *ngIf="(shouldShowErrors() || (!shouldShowErrors() && submited)) && listOfErrors().length > 0">
     <li style="color: red" *ngFor="let error of listOfErrors()">{{error}}</li>
   </ul>
 `,
})
export class ShowErrorsComponent {

    private static readonly errorMessages = {
        'required': () => 'This field is required',
        'minlength': (params) => 'The minimum number of characters is :' + params.requiredLength,
        'maxlength': (params) => 'The maximum number of characters is : ' + params.requiredLength,
        'email': () => 'This email is invalid',
        'pattern': (params) => 'This field is required',
        'years': (params) => params.message,
        'countryCity': (params) => params.message,
        'uniqueName': (params) => params.message,
        'telephoneNumbers': (params) => params.message,
        'telephoneNumber': (params) => params.message
    };

    @Input()
    private control: AbstractControlDirective | AbstractControl;
    @Input()
    public submited: Boolean;

    shouldShowErrors(): boolean {
        return this.control &&
            this.control.errors &&
            (this.control.dirty || this.control.touched);
    }

    listOfErrors(): string[] {
        if (this.control.errors != null) {
            return Object.keys(this.control.errors)
                .map(field => this.getMessage(field, this.control.errors[field]));
        } else { return []; }
    }

    private getMessage(type: string, params: any) {
        return ShowErrorsComponent.errorMessages[type](params);
    }

}


@NgModule({
  imports: [
    CommonModule
  ],
  exports: [ShowErrorsComponent],
  declarations: [ShowErrorsComponent],
})
export class ShowErrorsModule { }
