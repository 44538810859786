import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable()
export class AuthGuard implements CanActivate { // CanActivateChild
  deniedMessage = 'Unauthorized access denied';

  constructor(
    private router: Router,
    private ngxPermissionsService: NgxPermissionsService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const url = route.url.toString();
    // console.log('parent', url);
    if (localStorage.getItem('currentUser')) { // login correcto
      return true;
    }
    // si no lo envamos al login
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }

  // canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //   const url = route.url.toString();
  //   console.log('child', url);
  //   if (this.verificarPermiso(url)) {
  //     return true;
  //   }
  //   alert(this.deniedMessage);
  //   return false;
  // }

  // verificarPermiso(url: string) {
  //   let tem: User = new User();
  //   tem = JSON.parse(localStorage.getItem('currentUser'));
  //   const permisos = tem.roles[0].permissions;
  //   for (const entry of permisos) {
  //     if (entry.name === 'schedules') {
  //       return true;
  //     } else if (entry.name === url) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }
}
