import { NgModule } from '@angular/core';
import { AuthGuard } from './../../_guards/auth.guard';
import { Routes, RouterModule } from '@angular/router';
import { ManifestMovilComponent } from './components/manifest-movil/manifest-movil.component';
import { DriverManifestComponent } from './components/driver-manifest/driver-manifest.component';
import { ManifestDetailsComponent } from './components/manifest-details/manifest-details.component';
import { PagesSmartComponent } from './../../theme/components/pages-smart/pages-smart.component';
import { NgxPermissionsGuard } from 'ngx-permissions';

const routes: Routes = [
  {
    path: 'manifests',
    component: PagesSmartComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'movil',
        component: ManifestMovilComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          title: 'Driver Manifest Mobile',
          permissions: {
            only: ['route-movil'],
            redirectTo: '/noauthorized'
          }
        }
      },
      {
        path: 'driver-manifest',
        component: DriverManifestComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          title: 'Driver Manifest',
          permissions: {
            only: ['route-driver-manifest'],
            redirectTo: '/noauthorized'
          }
        }
      },
      {
        path: 'driver-manifest-details/:id',
        component: ManifestDetailsComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          title: 'Driver Manifest Details',
          permissions: {
            only: ['route-driver-manifest-details'],
            redirectTo: '/noauthorized'
          }
        }
      }
    ]}
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class ManifestsRoutingModule { }
