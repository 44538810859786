import { Injectable } from '@angular/core';
import { CONSTANT } from '../../app.constants';
import { IResponse } from '../../CORE/interfaces/response.interface';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { generateWeek } from '../../helpers/moment-helpers';
import { IPayloadBatch, ISummaryInvoiceReport } from '../../CORE/interfaces/workflow.interface';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {

  private loadBillingValidateForSummarySubject = new BehaviorSubject<any>(null);
  loadBillingValidateForSummarySubject$ = this.loadBillingValidateForSummarySubject.asObservable();
  private loadBillingValidateForUnverifiedSubject = new BehaviorSubject<any>(null);
  loadBillingValidateForUnverifiedSubject$ = this.loadBillingValidateForUnverifiedSubject.asObservable();
  private loadBillingValidateForWorkflowSubject = new BehaviorSubject<any>(null);
  loadBillingValidateForWorkflowSubject$ = this.loadBillingValidateForWorkflowSubject.asObservable();
  /* */
  public responseReviewBlockUnverifiedSubject = new BehaviorSubject<any>([]);
  responseReviewBlockUnverifiedSubject$ = this.responseReviewBlockUnverifiedSubject.asObservable();
  public responseReviewBlockRejectedVerifiedSubject = new BehaviorSubject<any>([]);
  responseReviewBlockRejectedVerifiedSubject$ = this.responseReviewBlockRejectedVerifiedSubject.asObservable();
  /**/
  public workflowBarSubject = new BehaviorSubject<any>([]);
  workflowBarSubject$ = this.workflowBarSubject.asObservable();
  /*filter*/
  changeFormDateHeaderFilterSubject = new BehaviorSubject<any>(null);
  changeFormDateHeaderFilter$ = this.changeFormDateHeaderFilterSubject.asObservable();
  /*worflow*/
  public reloadWorkflowSubject = new BehaviorSubject<boolean>(false);
  reloadWorkflowSubject$ = this.reloadWorkflowSubject.asObservable();
  /**/
  public responseBillingStatusSubject = new BehaviorSubject<any[]>([]);
  responseBillingStatusSubject$ = this.responseBillingStatusSubject.asObservable();
  public verifyStatusSubject = new BehaviorSubject<any[]>([]);
  verifyStatusSubject$ = this.verifyStatusSubject.asObservable();
  public openTabWorkflowSubject = new BehaviorSubject<number>(null);
  openTabWorkflowSubject$ = this.openTabWorkflowSubject.asObservable();
  public sendIdsChangesVerifiedSubject = new BehaviorSubject<any>(null);
  sendIdsChangesVerified$ = this.sendIdsChangesVerifiedSubject.asObservable();

  /**/
  private scheduleDetailModalIsOpenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private unprocessedModuleIsOpenOnWorkFlowSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private loadDataToPatchOnUnprocessedTabSubject: Subject<any> = new Subject<any>();
  private clientIdWorkflowBarFormChangingSubject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  private infoByExternalFormToInvoiceReportSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  /**/
  public canOpenInvoiceModalSubject: Subject<boolean> = new Subject<boolean>();
  canOpenInvoiceModalAction$: Observable<boolean> = this.canOpenInvoiceModalSubject.asObservable();

  private statusBillingByWorkflowSubject: BehaviorSubject<string> = new BehaviorSubject(undefined);
  statusBillingByWorkflowAction$: Observable<string> = this.statusBillingByWorkflowSubject.asObservable();

  constructor(private httpClient: HttpClient) { }

  get infoByExternalFormToInvoiceReportAction$() {
    return this.infoByExternalFormToInvoiceReportSubject.asObservable();
  }

  get unprocessedModuleIsOpenOnWorkFlow$() {
    return this.unprocessedModuleIsOpenOnWorkFlowSubject.asObservable();
  }

  get scheduleDetailModalIsOpen$() {
    return this.scheduleDetailModalIsOpenSubject.asObservable();
  }

  get loadDataToPatchOnUnprocessedTabAction$() {
    return this.loadDataToPatchOnUnprocessedTabSubject.asObservable();
  }

  get clientIdWorkflowBarFormChangingSubject$() {
    return this.clientIdWorkflowBarFormChangingSubject.asObservable();
  }

  setInfoByExternalFormToInvoiceReport(data): void {
    this.infoByExternalFormToInvoiceReportSubject.next(data);
  }

  sendStatusBillingByWorkflow(statusBilling: string): void {
    this.statusBillingByWorkflowSubject.next(statusBilling);
  }

  clearStatusBillingByWorkflow(): void {
    this.statusBillingByWorkflowSubject.next(undefined);
  }

  clientIdIsChangingInWorkflowBarForm(clientId): void {
    this.clientIdWorkflowBarFormChangingSubject.next(clientId);
  }

  unprocessedModuleIsOpenedOnWorkFlow(isOpen: boolean): void {
    this.unprocessedModuleIsOpenOnWorkFlowSubject.next(isOpen);
  }

  modalDetailIsOpen(isOpen: boolean): void {
    this.scheduleDetailModalIsOpenSubject.next(isOpen);
  }

  loadScheduleManifestSummary(data) {
    this.loadBillingValidateForSummarySubject.next(data);
  }

  loadScheduleManifestUnverified(data) {
    this.loadBillingValidateForUnverifiedSubject.next(data);
  }

  loadScheduleManifestWorkflow(data) {
    this.loadBillingValidateForWorkflowSubject.next(data);
  }

  loadDataToPatchInUnprocessedTabOnWorkflow(data: any): void {
    this.loadDataToPatchOnUnprocessedTabSubject.next(data);
  }

  formatDataToScheduleModal(data) {
    const {schedule_details_id, vehicle_id_manifest, driver_name_schedule, vehicle_schedule, dateofservice} = data;
    const {dateStart, dateEnd} = this.getWeekRangeAccordingToServiceDate(dateofservice);

    return {
      id: schedule_details_id,
      vehicle_id: vehicle_id_manifest,
      driver: driver_name_schedule,
      vehicle: vehicle_schedule,
      dateStart,
      dateEnd,
      ...data
    };
  }

  private getWeekRangeAccordingToServiceDate(dateOfService: string) {
    const [year] = dateOfService.split('-');
    const numOfWeek: number = moment(dateOfService, 'YYYY-MM-DD').week();
    return generateWeek(year, numOfWeek);
  }

  findScheduleManifestUnverified(data: any) {
    const url = CONSTANT.BILLING.URL.BILLING_UNVERIFIED;
    return this.httpClient.post<IResponse<any>>(url, data);
  }

  findScheduleManifestWorkflow(data: any) {
    const url = CONSTANT.BILLING.URL.BILLING_WORKFLOW;
    return this.httpClient.post<IResponse<any>>(url, data);
  }

  getSummary(data: any) {
    const url = `${CONSTANT.BILLING.URL.BILLING_SUMMARY}`;
    return this.httpClient.post<IResponse<any>>(url, data);
  }

  getBillingStatus() {
    const url = `${CONSTANT.BILLING.URL.BILLING_STATUS}`;
    return this.httpClient.get<IResponse<any>>(url);
  }

  getBlockStatus() {
    const url = `${CONSTANT.BILLING.URL.BILLING_BLOCK_STATUS}`;
    return this.httpClient.get<IResponse<any>>(url);
  }

  reviewBlockSummary(parameters: any) {
    const url = `${CONSTANT.BILLING.URL.BILLING_REVIEW_BLOCK}`;
    return this.httpClient.post<IResponse<any>>(url, parameters);
  }

  changeVerifiedStatus(validate_data: any) {
    const ids = {'ids': validate_data};
    const url = `${CONSTANT.BILLING.URL.BILLING_CHANGE_VERIFIED_STATUS}`;
    return this.httpClient.post<IResponse<any>>(url, ids);
  }

  changeStatus(validate_data: any) {
    const url = `${CONSTANT.BILLING.URL.BILLING_CHANGE_STATUS}`;
    return this.httpClient.post<IResponse<any>>(url, validate_data);
  }

  clearSchedulesIdForSweetAlertToValidateDiscrepancies(): void {
    this.responseBillingStatusSubject.next([]);
  }

  getAllVehiclesActive(): Observable<any[]> {
    return this.httpClient.get<Array<any>>(CONSTANT.VEHICLE.URL.GET_ALL_ACTIVE_VEHICLES);
  }

  getAllDriversActive(): Observable<any[]> {
    return this.httpClient.get<Array<any>>(CONSTANT.DRIVERS.URL.GET_ALL_ACTIVE_DRIVERS);
  }

  getAvailableVehiclesBySchedule(scheduleDetailId: string): Observable<any[]> {
    return this.httpClient.get<any[]>(`${CONSTANT.VEHICLE.URL.GET_AVAILABLE_VEHICLES_BY_SCHEDULE}/${scheduleDetailId}`);
  }

  getAvailableDriversByRangeAndSchedule(scheduleDetailId, startDate, endDate): Observable<any> {
    const URL = `${CONSTANT.DRIVERS.URL.GET_AVAILABLE_DRIVERS_BY_SCHEDULE_AND_RANGE_DATE}/${scheduleDetailId}/${startDate}/${endDate}`;
    return this.httpClient.get<any>(URL);
  }
  getBillingRulesByClientId(clientId): Observable<IResponse<any>> {
    return this.httpClient.get<IResponse<any>>(`${CONSTANT.CLIENT.URL.GET_BILLING_RULES_BY_CLIENT_ID}/${clientId}`);
  }

  getSummaryUnprocessedTab(payload: IPayloadBatch): Observable<IResponse<ISummaryInvoiceReport[]>> {
    return this.httpClient.post<IResponse<ISummaryInvoiceReport[]>>(`${CONSTANT.BILLING.URL.BILLING_SUMMARY_TAB_UNPROCESSED}`, payload);
  }

  generateBatch(payload: any): Observable<any> {
    return this.httpClient.post<any>(`${CONSTANT.BILLING.URL.BILLING_CREATE_BATCH}`, payload);
  }
}
