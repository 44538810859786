import { Component, OnInit } from '@angular/core';
import { DriverService } from './../../../services/fleet/driver.service';

@Component({
  selector: 'app-drivers-routes',
  templateUrl: './drivers-routes.component.html',
  styleUrls: ['./drivers-routes.component.scss']
})
export class DriversRoutesComponent implements OnInit {


  constructor( private driverService: DriverService) { }

  ngOnInit() {
    /* this.driverService.driverid$.subscribe((driverid: number) => {
      console.log(driverid);
    }); */
  }

}
