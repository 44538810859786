import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BatchesService } from '../../../../services/billing/batches.service';
import { ClientService } from '../../../../services/fleet/client.service';
import { BatchInvoicesStatusService } from '../../../../services/billing/batch/batch-invoices-status.service';

@Component({
  selector: 'app-invoices-batch',
  templateUrl: './invoices-batch.component.html',
  styleUrls: ['./invoices-batch.component.scss']
})
export class InvoicesBatchComponent implements OnInit, OnDestroy {
  busy: Subscription;
  clearGrid: any;
  form: FormGroup;
  clients: any = [];
  invoicesStatus: any = [];

  constructor(private fb: FormBuilder, private batchesService: BatchesService,
    private clientService: ClientService, private batchInvoicesStatus: BatchInvoicesStatusService) {
      this.initForm();
     }

  ngOnInit() {
    this.loadData();
  }

  ngOnDestroy(): void {
  }

  loadData() {
    this.batchInvoicesStatus.getInvoiceStatus().subscribe((resp: any) => {
      this.invoicesStatus = resp.data;
    });
    this.busy = this.clientService.getClientsByDriverManifest()
    .subscribe((resp: any) => {
      this.clients = resp.data;
    });

  }

  initForm() {
    this.form = this.fb.group({
      client_id: [0],
      invoice_status_id: [2]
    });
  }

  onSearch() {
    const data = Object.assign({}, this.form.getRawValue());
    const info = {
      client_id: data.client_id,
      invoice_status_id: data.invoice_status_id
    };
    this.clearGrid = Math.random();
    this.batchesService.load_batchesInvoices$.next(info);
  }

}
