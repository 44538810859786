import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterEvent} from '@angular/router';
import * as moment from 'moment';
import { BsModalComponent } from 'ng2-bs3-modal';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DataService } from './../../../app.data.service';
import { User } from './../../../user.model';
import { NotificationService } from './../../../components/services/push/notification.service';
import { UserService } from './../../../services/services.index';
import { AuthService } from './../../../services/auth.service';
import { NotifyAppService } from './../../../services/notifyapp.service';
import { PusherService } from './../../../components/services/pusher/pusher.service';
import Swal from 'sweetalert2';
import { TaModalComponent } from '../../../components/ta-modal/ta-modal.component';
import { UserTypeEnum } from '../../../CORE/enums/UserTypeEnum.enum';

@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss'],
  providers: [DataService]
})
export class HorizontalMenuComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('modal_profile') modalProfile: BsModalComponent;
  @ViewChild('modal_change_password') modalChangePassword: BsModalComponent;
  @ViewChild('modal_change_picture') modalChangePicture: BsModalComponent;
  @ViewChild('modal_notify') modal_notify: BsModalComponent;

  @ViewChild('modal_interruption_notification') modal_interruption_notification: TaModalComponent;

  busy: Subscription[] = [];

  notifications: any[] = [];
  notification: any = {};
  qtyNotyDef = 10;
  qtyNoty = Number(this.qtyNotyDef);

  title = '';
  active = false;
  profile: any = {};
  subsRouting: Subscription;
  isOpen = false;

  infoUser: User = new User();
  companiesEnabled = true;
  myAvatar = 'assets/img/default.png';
  newNotifications = 0;
  activeCompany = true;

  public companies: any = {};
  public showSelectorCompanies = false;
  public companiesStore: any = {};

  constructor(
    private dataService: DataService,
    private userService: UserService,
    private authService: AuthService,
    private pusherService: PusherService,
    private router: Router,
    public toastr: ToastrService,
    private route: ActivatedRoute,
    private ngxPermissionsService: NgxPermissionsService,
    private notificationService: NotificationService,
    private notifyAppService: NotifyAppService,
  ) {
    this.subsRouting = this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .map(() => this.route)
      .map((routes) => {
        while (routes.firstChild) {
          routes = routes.firstChild;
        }
        return routes;
      })
      .filter((routes) => routes.outlet === 'primary')
      .mergeMap((routes) => routes.data)
      .subscribe((event) => { this.title = event['title']; });
    this.hideSelector();
  }
  ngAfterViewInit(): void { }

  ngOnInit() {
    if (localStorage.getItem('lastLogin')) {
      this.verfyLoginDay();
    }

    const dataUserStorage = JSON.parse(localStorage.getItem('currentUser'));
    this.infoUser = dataUserStorage ? dataUserStorage : {};
    this.pusherService.loadChanelNotify(this.infoUser.id).subscribe((res: any) => {
      const notificacion = res.data.notifications;
      this.newNotifications = res.data.total;
      notificacion.readed = false;
      this.notifications.unshift(notificacion);
      if (notificacion.popup) {
        Swal.fire({
          type: 'info',
          title: notificacion.title,
          text: '',
          html: notificacion.body,
        });
      } else {
        this.toastr.info(notificacion.title, 'Notification!', {
          closeButton: true
        });
      }
    });

    if (localStorage.getItem('companies')) {
      this.companies = JSON.parse(localStorage.getItem('companies'));
      if (this.companies.length > 0) {
        this.selectecCompanies(this.companies);
      } else {
      }
    }

    const user = JSON.parse(localStorage.getItem('currentUser'));
    this.myAvatar = user.avatar ? user.avatar : 'assets/img/default.png';

    this.loadNotifications();
  }

  loadNotifications() {
    this.notifyAppService.getNotifications(this.qtyNoty).subscribe((res: any) => {
      const notifications = [];
      res.data.notifications.forEach(element => {
        element.readed = element.readed === '1' ? true : false;
        notifications.push(element);
      });
      this.notifications = notifications;
      this.newNotifications = res.data.total;
    });
  }

  hideSelector() {
    this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .subscribe((navigationEnd: RouterEvent) => {
      const urlAvailable = ['/payroll/rules' , '/billing/batch/history', '/tops/timecards', '/tops/drivers', '/tops/employees', '/tops/routes', '/tops/exceptions-report/tops'];
      if (urlAvailable.includes(navigationEnd.url)) {
        this.activeCompany = false;
      } else {
        this.activeCompany = true;
      }
    });
  }

  viewNotify(data: any, index) {
    if (!data.readed) {
      this.notifyAppService.setViewNotify(data.id).subscribe((res: any) => {
        this.notifications[index].readed = true;
        this.notifications[index].readed_at = moment().format('YYYY-MM-DD HH:mm:ss.000');
      });
    }
  }

  openNotify(data, index) {
    // this.viewNotify(data, index);
    // this.notification = data;
    // this.modal_notify.open();
    this.modal_interruption_notification.open('lg');
  }

  verfyLoginDay() {
    const latlogin = localStorage.getItem('lastLogin');
    const date = new Date().toDateString();
    if (latlogin !== date) {
      this.onLogout();
    }
  }
  onLogout() {
    this.dataService.getData('me/logout').subscribe(
      datos => {
        this.onDestroyer();
      }, () => {
        this.onDestroyer();
      }
    );
  }

  onDestroyer() {
    this.busy.forEach(suscription => suscription.unsubscribe());
    this.notificationService.clearInterest();
    this.authService.clearLocalStore();
    this.ngxPermissionsService.loadPermissions([]);
    this.router.navigate(['/login']);
  }

  onNotify(mensaje: any) {
    this.toastr.success(mensaje);
  }

  changeImage() {
    this.modalChangePicture.open();
  }
  changePassword() {
    this.modalChangePassword.open();
  }
  changeProfile() {
    this.modalProfile.open();
  }

  changeImg(img) {
    this.myAvatar = img;
  }

  onClose(num: number) {
    switch (num) {
      case 1:
        this.modalProfile.close();
        break;
      case 2:
        this.modalChangePassword.close();
        break;
      case 3:
        this.modalChangePicture.close();
        break;
    }
  }


  openViewDay() {
    this.getScheduleID();
  }

  getScheduleID() {
    const year = moment().weekYear();
    const week = moment().week();
    const clientUser = JSON.parse(localStorage.getItem('currentUser'));
    if (clientUser && clientUser.user_types && clientUser.user_types.description &&  clientUser.user_types.description === UserTypeEnum.Admin) {
      this.busy.push(this.dataService
        .getData('schedules/year/' + year + '/week/' + week + '')
        .subscribe((data: any) => {
          if (data.length) {
            this.router.navigate([
              '/dashboard/schedules/' + data[0].id + '/' + true
            ]);
          } else {
            this.router.navigate(['/dashboard/lsf']);
          }
        }));
    } else {
      this.router.navigate(['/client/dashboard']);
    }
  }
  logout() {

  }

  selectecCompanies(companies_: any) {
    const dbname = localStorage.getItem('namedb');
    for (const comp of companies_) {
      if (dbname === comp.company.namedb) {
        this.companiesStore = comp.company_id;
      }
    }
  }

  changeCompanie(event: any) {
    if (this.companies.length > 1) {
      const company = this.companies.filter((row) => {
        return row.company_id == event.target.value;
      })[0];
      localStorage.setItem('namedb', company.company.namedb);
      localStorage.setItem('companyid', company.company_id);
      this.authService.setTenantTrip2(company.company.tenant, company.company.tenant_key);
      this.router.navigate(['/dashboard/lsf']);
      setTimeout(() => {
        location.reload();
      }, 800);
    }
  }

  ngOnDestroy() {
    this.subsRouting.unsubscribe();
    this.busy.forEach(suscription => suscription.unsubscribe());
  }

  seeMore() {
    if (this.notifications.length >= this.qtyNoty) {
      this.qtyNoty = this.qtyNoty + this.qtyNotyDef;
      this.loadNotifications();
    }
  }


}
